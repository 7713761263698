import { catalog, catalogItem } from "../models";
import { CatalogItemType, QueryType } from "../models/common";
import { CatalogState } from "../store";
import { hydrationHelpers, hydrationParsers } from "./hydrationHelpers";
import * as services from "../service";

export type HydratorParams = {
    asins: string[];
    teamId: string;
    titleSetAsinToGlobalAsinMap?: Map<string, string>;
    catalogState: CatalogState;
    locale?: string;
    catalogPublish?: (newCatalog: catalog) => void;
};

export type HydratorResponse = {
    newCatalog: catalog;
    unhydratedArtists?: Set<string>;
    unhydratedAlbums?: Set<string>;
};

export type Hydrator = (
    hydraterParams: HydratorParams
) => Promise<HydratorResponse>;

type NonTitleSetQueryParams = {
    asins: string[];
    teamId: string;
    titleSetAsinToGlobalAsinMap?: Map<string, string>;
    locale?: string;
    queryType: QueryType;
    fallbackQueryType: QueryType;
    hydrationParser: any;
    catalogPublish?: (newCatalog: catalog) => void;
};

type TitlesetFallbackParams = {
    asins: string[];
    teamId: string;
    catalog: catalog;
    catalogState: CatalogState;
    queryType: QueryType;
    hydrationParser: any;
    locale?: string;
    catalogPublish?: (newCatalog: catalog) => void;
};

const hydrateNonTitleSetQueryType = async ({
    asins,
    teamId,
    titleSetAsinToGlobalAsinMap,
    locale,
    queryType,
    fallbackQueryType,
    hydrationParser,
    catalogPublish,
}: NonTitleSetQueryParams): Promise<catalog> => {
    const response = await services.hydrateAsins(
        asins,
        teamId,
        queryType,
        locale
    );
    const newCatalog = hydrationHelpers.getCatalogFromProxyResponse(
        response.data,
        hydrationParser
    );
    const failedAsins = hydrationHelpers.getFailedAsins(
        asins,
        newCatalog,
        CatalogItemType.Albums
    );
    if (failedAsins.length && titleSetAsinToGlobalAsinMap) {
        const globalAsinsToTitleSetsToQuery =
            hydrationHelpers.getGlobalAsinsToQuery(
                titleSetAsinToGlobalAsinMap,
                failedAsins
            );
        let catalogToMerge = new Map<string, catalogItem>();
        if (globalAsinsToTitleSetsToQuery.size > 0) {
            const globalAsinsToQuery = Array.from(
                globalAsinsToTitleSetsToQuery.keys()
            );
            const globalAsinsResponse = await services.hydrateAsins(
                globalAsinsToQuery,
                teamId,
                fallbackQueryType,
                locale
            );
            const globalAsinBasedCatalog =
                hydrationHelpers.getCatalogFromProxyResponse(
                    globalAsinsResponse.data,
                    hydrationParser
                );
            catalogToMerge = hydrationHelpers.getCatalogForFailedTitleSetAsins(
                globalAsinBasedCatalog,
                globalAsinsToTitleSetsToQuery
            );
        }
        catalogToMerge.forEach((value, key) =>
            newCatalog.set(key, {
                ...newCatalog.get(key),
                ...value,
            })
        );
        titleSetAsinToGlobalAsinMap.forEach((value, key) => {
            newCatalog.set(key, {
                ...newCatalog.get(key),
                globalAsin: value,
            });
        });
    }
    catalogPublish?.(newCatalog);
    return newCatalog;
};

const hydrateFailedAsinsWithTitleSetFallback = async ({
    asins,
    teamId,
    catalog,
    catalogState,
    queryType,
    hydrationParser,
    locale,
    catalogPublish,
}: TitlesetFallbackParams): Promise<catalog | null> => {
    const failedAsins = hydrationHelpers.getFailedAsins(
        asins,
        catalog,
        CatalogItemType.Tracks
    );
    failedAsins.filter((asin) => !catalogState.catalog.get(asin));

    if (failedAsins.length) {
        const newCatalog = new Map<string, catalogItem>();
        const response = await services.hydrateAsins(
            asins,
            teamId,
            QueryType.TitleSets,
            locale
        );
        const titleSetMap: Map<string, string> =
            hydrationHelpers.getTitleSetMapFromProxyResponse(response.data);
        const globalAsins = Array.from(titleSetMap.values());
        const trackAlbumResponse = await services.hydrateAsins(
            globalAsins,
            teamId,
            queryType,
            locale
        );
        const globalAsinsCatalog = hydrationHelpers.getCatalogFromProxyResponse(
            trackAlbumResponse.data,
            hydrationParser
        );
        // value - globalAsin, key - titleSetAsin
        titleSetMap.forEach((value, key) => {
            const trackAlbum = globalAsinsCatalog.get(value);
            if (trackAlbum) {
                newCatalog.set(key, trackAlbum);
                newCatalog.set(value, trackAlbum);
            }
        });
        catalogPublish?.(newCatalog);
        return newCatalog;
    }
    return null;
};

const hydrateArtists: Hydrator = async ({
    asins,
    teamId,
    titleSetAsinToGlobalAsinMap,
    catalogState,
    locale,
    catalogPublish,
}): Promise<HydratorResponse> => {
    const response = await services.hydrateAsins(
        asins,
        teamId,
        QueryType.Artists,
        locale
    );
    const newCatalog = hydrationHelpers.getCatalogFromProxyResponse(
        response.data,
        hydrationParsers.artistParser
    );
    titleSetAsinToGlobalAsinMap?.forEach((value, key) => {
        newCatalog.set(key, {
            ...newCatalog.get(key),
            globalAsin: value,
        });
    });
    catalogPublish?.(newCatalog);
    return {
        newCatalog: newCatalog,
    };
};

const hydratePlaylists: Hydrator = async ({
    asins,
    teamId,
    titleSetAsinToGlobalAsinMap,
    catalogState,
    locale,
    catalogPublish,
}): Promise<HydratorResponse> => {
    const response = await services.hydrateAsins(
        asins,
        teamId,
        QueryType.Playlists,
        locale
    );
    const newCatalog = hydrationHelpers.getCatalogFromProxyResponse(
        response.data,
        hydrationParsers.playlistParser
    );
    titleSetAsinToGlobalAsinMap?.forEach((value, key) => {
        newCatalog.set(key, {
            ...newCatalog.get(key),
            globalAsin: value,
        });
    });
    catalogPublish?.(newCatalog);
    return {
        newCatalog: newCatalog,
    };
};

const hydrateStations: Hydrator = async ({
    asins,
    teamId,
    titleSetAsinToGlobalAsinMap,
    catalogState,
    locale,
    catalogPublish,
}): Promise<HydratorResponse> => {
    const response = await services.hydrateAsins(
        asins,
        teamId,
        QueryType.Stations,
        locale
    );
    const newCatalog = hydrationHelpers.getCatalogFromProxyResponse(
        response.data,
        hydrationParsers.stationParser
    );
    titleSetAsinToGlobalAsinMap?.forEach((value, key) => {
        newCatalog.set(key, {
            ...newCatalog.get(key),
            globalAsin: value,
        });
    });
    catalogPublish?.(newCatalog);
    return {
        newCatalog: newCatalog,
    };
};

const hydrateAlbum: Hydrator = async ({
    asins,
    teamId,
    titleSetAsinToGlobalAsinMap,
    catalogState,
    locale,
    catalogPublish,
}): Promise<HydratorResponse> => {
    const newCatalog = await hydrateNonTitleSetQueryType({
        asins: asins,
        teamId: teamId,
        titleSetAsinToGlobalAsinMap: titleSetAsinToGlobalAsinMap,
        locale: locale,
        queryType: QueryType.AlbumTitleSets,
        fallbackQueryType: QueryType.Albums,
        hydrationParser: hydrationParsers.albumParser,
    });

    const unhydratedArtists = hydrationHelpers.getUnhydratedArtists(
        newCatalog,
        catalogState
    );

    const fallbackCatalog = await hydrateFailedAsinsWithTitleSetFallback({
        asins: asins,
        teamId: teamId,
        catalog: newCatalog,
        catalogState: catalogState,
        queryType: QueryType.Albums,
        hydrationParser: hydrationParsers.albumParser,
        locale: locale,
    });

    if (fallbackCatalog) {
        fallbackCatalog.forEach((value, key) => {
            newCatalog.set(key, {
                ...newCatalog.get(key),
                ...value,
            });
        });
    }
    titleSetAsinToGlobalAsinMap?.forEach((value, key) => {
        newCatalog.set(key, {
            ...newCatalog.get(key),
            globalAsin: value,
        });
    });
    catalogPublish?.(newCatalog);
    return {
        newCatalog: newCatalog,
        unhydratedArtists: unhydratedArtists,
    };
};

const hydrateTrack: Hydrator = async ({
    asins,
    teamId,
    titleSetAsinToGlobalAsinMap,
    catalogState,
    locale,
    catalogPublish,
}): Promise<HydratorResponse> => {
    const newCatalog = await hydrateNonTitleSetQueryType({
        asins: asins,
        teamId: teamId,
        titleSetAsinToGlobalAsinMap: titleSetAsinToGlobalAsinMap,
        locale: locale,
        queryType: QueryType.TrackTitleSets,
        fallbackQueryType: QueryType.Tracks,
        hydrationParser: hydrationParsers.trackParser,
    });

    const unhydratedArtists = hydrationHelpers.getUnhydratedArtists(
        newCatalog,
        catalogState
    );
    const unhydratedAlbums = hydrationHelpers.getUnhydratedAlbums(
        newCatalog,
        catalogState
    );

    const fallbackCatalog = await hydrateFailedAsinsWithTitleSetFallback({
        asins: asins,
        teamId: teamId,
        catalog: newCatalog,
        catalogState: catalogState,
        queryType: QueryType.Tracks,
        hydrationParser: hydrationParsers.trackParser,
        locale: locale,
    });

    if (fallbackCatalog) {
        fallbackCatalog.forEach((value, key) => {
            newCatalog.set(key, {
                ...newCatalog.get(key),
                ...value,
            });
        });
    }
    titleSetAsinToGlobalAsinMap?.forEach((value, key) => {
        newCatalog.set(key, {
            ...newCatalog.get(key),
            globalAsin: value,
        });
    });
    catalogPublish?.(newCatalog);
    return {
        newCatalog: newCatalog,
        unhydratedAlbums: unhydratedAlbums,
        unhydratedArtists: unhydratedArtists,
    };
};

export const getHydration = (catalogItemType: CatalogItemType): Hydrator => {
    switch (catalogItemType) {
        case CatalogItemType.Artists:
            return hydrateArtists;
        case CatalogItemType.Playlists:
            return hydratePlaylists;
        case CatalogItemType.Stations:
            return hydrateStations;
        case CatalogItemType.Albums:
            return hydrateAlbum;
        case CatalogItemType.Tracks:
            return hydrateTrack;
    }
};

export const hydrator = {
    hydrateArtists,
    hydratePlaylists,
    hydrateStations,
    hydrateAlbum,
    hydrateTrack,
};
