import * as React from "react";
import { Icon, IconsList } from "../icons";
import * as rootStyles from "../../../styles";
import { Card, Row } from "react-bootstrap";

export type CalendarErrorProps = {
    text: string;
    id: string;
};

export class CalendarError extends React.Component<CalendarErrorProps> {
    render() {
        return (
            <Card style={rootStyles.errorStyles.errorContainer}>
                <Row style={innerErrorStyle}>
                    <Icon
                        style={iconStyle}
                        size={24}
                        icon={IconsList.error_inline}
                        id={`${this.props.id}_Icon`}
                    />
                    <span style={textStyle}>{this.props.text}</span>
                </Row>
            </Card>
        );
    }
}

const innerErrorStyle: React.CSSProperties = {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
};
const textStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "center",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    ...rootStyles.textStyles.quaternary,
};
const iconStyle: React.CSSProperties = {
    alignSelf: "center",
    flex: 0,
    paddingRight: 10,
};
