import axios from "axios";
import https from "https";
import { clientMetricsPayload, OpsMetricsRequest } from "../models";
import { getEndpoints, testHeaders } from "./common";

const baseClientMetricsUrl = getEndpoints().clientMetrics;
const sendClientMetricsUrl = `${baseClientMetricsUrl}/SendMetrics`;
const sendOpsClientMetricsUrl = `${baseClientMetricsUrl}/SendOpsMetrics`;

export async function emitClientMetric(
    payload: clientMetricsPayload
): Promise<any> {
    return axios.post(sendClientMetricsUrl, payload, {
        headers: {
            ...testHeaders(),
        },
        withCredentials: true,
    });
}

export async function emitOperationalClientMetric(
    payload: OpsMetricsRequest,
    csrfToken: string
): Promise<{}> {
    const result = await fetch(sendOpsClientMetricsUrl, {
        method: "POST",
        headers: {
            ...testHeaders(),
            "x-csrf-token": csrfToken,
        },
        body: JSON.stringify(payload),
        credentials: "include",
        keepalive: true,
    });

    return result;
}
