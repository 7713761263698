import { createSelector } from "reselect";
import {
    getSelectedArtistAsin,
    selectedArtistFromCatalogSelector,
} from "../commonSelectors";

export const modLandingScreenSelector = createSelector(
    [selectedArtistFromCatalogSelector, getSelectedArtistAsin],
    (artist, selectedArtistAsin) => {
        return {
            artist: artist,
            selectedArtistAsin,
        };
    }
);
