import * as React from "react";
import styled from "styled-components";
import { ToastBody, Row, Nav, Toast } from "react-bootstrap";
import { Icon } from ".";
import * as rootStyles from "../../styles";
import { testIDSuffixes } from "../../../utils";
import { IconsList } from "./icons";

export type ToastProps = {
    title: string;
    hiddenCallback: () => void;
    displayDuration?: number;
    showToast: boolean;
    id: string;
};

type State = {
    fadeOut: boolean;
    showToast: boolean;
};

export class FixedToast extends React.Component<ToastProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            fadeOut: false,
            showToast: this.props.showToast,
        };
    }

    render() {
        return (
            <StyledToast
                id="styled-toast"
                autohide={true}
                show={this.state.showToast}
                delay={this.props.displayDuration || 2000}
                onClose={this.onClose}
            >
                <ToastBody id={this.props.id}>
                    <Row>
                        <Nav.Item onClick={this.onClose}>
                            <Icon
                                icon={IconsList.action_cancelInline}
                                size={rootStyles.icons.small}
                                color={rootStyles.colors.primary}
                                id={`${this.props.id}_CancelIcon`}
                            />
                        </Nav.Item>
                        <div
                            style={textStyle}
                            id={`${this.props.id}${testIDSuffixes.title}`}
                        >
                            {this.props.title}
                        </div>
                    </Row>
                </ToastBody>
            </StyledToast>
        );
    }

    private onClose = () => {
        this.setState({ showToast: false });
        this.props.hiddenCallback();
    };
}

const StyledToast = styled(Toast)`
    position: fixed;
    flex: 1;
    right: ${rootStyles.spacers.base}px;
    bottom: ${rootStyles.spacers.base}px;

    max-width: 100%;
    width: calc(100% - 240px - 2 * ${rootStyles.spacers.base}px);
    @media (max-width: 992px) {
        width: calc(100% - 2 * ${rootStyles.spacers.base}px);
    }

    border: 1px solid ${rootStyles.colors.accent};
    border-radius: 0px;

    background-color: ${rootStyles.glassColors.accent2};
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);

    .toast-header,
    .toast-body {
        background-color: transparent;
    }
`;

const textStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    textTransform: "none",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
};
