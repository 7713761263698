import { Action } from "redux-ts";
import { takeEvery } from "redux-saga/effects";
import { clientMetricsPayload } from "../../models";
import { emitClientMetric } from "../../service";
import { clientMetricsActions } from "../actions";
import { isProd } from "../../service/common";

export const clientMetricsSagas = [watchSendMetricsEvent()];

function* sendClientMetrics(action: Action<clientMetricsPayload>) {
    try {
        // Only emit client side metrics when in prod environment
        if (isProd) {
            yield emitClientMetric(action.payload);
        }
        // tslint:disable-next-line: no-empty
    } catch (ex) {}
}

function* watchSendMetricsEvent() {
    yield takeEvery(
        clientMetricsActions.sendClientMetrics.type,
        sendClientMetrics
    );
}
