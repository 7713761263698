import { createAction } from "redux-ts";
import {
    hydrateMerchPayload,
    merchIdentifiers,
    hydratedMerchItem,
} from "../../models";

//hydrate merch
export const hydrateMerch = createAction<hydrateMerchPayload>(
    "MERCH::HYDRATE_MERCH"
);
export const hydrateMerchInProgress = createAction<boolean>(
    "MERCH::HYDRATE_MERCH_IN_PROGRESS"
);
export const hydrateMerchCompleted = createAction<hydratedMerchItem[]>(
    "MERCH::HYDRATE_MERCH_COMPLETED"
);
export const hydrateMerchFailedIdentifiers = createAction<merchIdentifiers[]>(
    "MERCH::HYDRATE_MERCH_FAILED_IDENTIFIERS"
);
export const hydrateMerchHydratingIdentifiers = createAction<
    merchIdentifiers[]
>("MERCH::HYDRATE_MERCH_HYDRATING_IDENTIFIERS");
