import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { Icon, IconsList, styledTitle } from "..";
import * as rootStyles from "../../../styles";
import { LargeSolidButton } from "../input/Buttons";

export type UploadModalProps = {
    id: string;
    labelText?: string;
    text: string;
    uploadButtonText: string;
    description?: string;
    uploadButtonAction: () => void;
    onDismiss?: () => void;
    isVisible: boolean;
    sticky?: boolean;
};

export class UploadModal extends React.Component<UploadModalProps> {
    render() {
        return (
            <Modal
                show={this.props.isVisible}
                style={rootStyles.containerStyles.modal}
                size="lg"
            >
                <Modal.Body style={modalContainer}>
                    <Row
                        style={cancelButtonStyle}
                        onClick={this.props.onDismiss}
                    >
                        <Icon
                            icon={IconsList.action_cancel}
                            size={rootStyles.spacers.huge}
                            color={rootStyles.colors.primary}
                            id={this.props.id + "_DismissIcon"}
                        />
                    </Row>
                    {this.props.labelText ? (
                        <Row>
                            <Col id={this.props.id + "-Label"}>
                                <span
                                    style={{
                                        ...rootStyles.textStyles.label,
                                    }}
                                >
                                    {this.props.labelText}
                                </span>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col>
                            <styledTitle.h2 style={{ margin: 0 }}>
                                {this.props.text}
                            </styledTitle.h2>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: rootStyles.spacers.small }}>
                        <Col
                            id={this.props.id + "-UploadButton"}
                            style={{ flex: 1 }}
                        >
                            <LargeSolidButton
                                containerStyle={buttonStyle}
                                title={this.props.uploadButtonText}
                                onClick={this.props.uploadButtonAction}
                                id={`${this.props.id}_Upload`}
                            />
                        </Col>
                        {this.props.description ? (
                            <Col
                                id={this.props.id + "-Description"}
                                style={{ flex: 2 }}
                            >
                                <p
                                    style={{
                                        ...rootStyles.textStyles.secondary,
                                        textAlign: "left",
                                        marginTop: rootStyles.spacers.small,
                                    }}
                                    id={`${this.props.id}_Description`}
                                >
                                    {this.props.description}
                                </p>
                            </Col>
                        ) : null}
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

const modalContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: rootStyles.colors.background,
    display: "flex",
};

const cancelButtonStyle: React.CSSProperties = {
    flex: 0,
    padding: 4,
    borderRadius: rootStyles.spacers.large,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
};

const buttonStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "stretch",
    marginTop: rootStyles.spacers.small,
};
