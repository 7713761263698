import { getBundleMap } from "./commonSelectors";
import _ from "lodash";
import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { artist, TeamType } from "../../models";
import {
    currentPathSelector,
    deeplinkMapSelector,
    deeplinkRouteSelector,
} from "./deeplinkSelector";

const getSignedIn = (state: RootState) => state.user.signedIn;
const getUserTeams = (state: RootState) => state.user.teams;
const getIsPrivileged = (state: RootState) => state.user.isPrivileged;

const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;
const getCatalog = (state: RootState) => state.catalog.catalog;
const getHydrationInProgress = (state: RootState) =>
    state.catalog.hydrationInProgress;

const getUserRefreshInProgress = (state: RootState) =>
    state.user.refreshInProgress;

const getArtistSearchInProgress = (state: RootState) =>
    state.artistSearch.inProgress;
const getArtistSearchResults = (state: RootState) => state.artistSearch.artists;

const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);

const getAcceptTeamInviteInProgress = (state: RootState) =>
    state.team.acceptTeamInviteInProgress;

const getDoRedirectToReports = (state: RootState) =>
    state.user.doRedirectToReports;

// Gets the currently selected artist from catalog
export const selectedArtistFromCatalogSelector = createSelector(
    [getSelectedArtistAsin, getCatalog],
    (selectedArtistAsin, catalog) => {
        if (!selectedArtistAsin) {
            return;
        }
        return catalog.get(selectedArtistAsin) as artist;
    }
);

const recentlyViewedArtistsSelector = createSelector(
    [
        selectedArtistFromCatalogSelector,
        getCatalog,
        (state: RootState) => state.user.recentlyViewedArtists,
    ],
    (selectedArtist, catalog, artists) => {
        return selectedArtist
            ? [
                  selectedArtist,
                  ..._.map(
                      _.filter(
                          artists,
                          (artist: artist) =>
                              artist?.asin !== selectedArtist.asin
                      ),
                      (artist) => {
                          return catalog.get(artist.asin ? artist.asin : "");
                      }
                  ),
              ]
            : artists;
    }
);

// Gets the all the artists for all the user's teams
const teamArtistsSelector = createSelector(
    [getUserTeams, getCatalog],
    (userTeams, catalog) => {
        let teamArtists: artist[] = [];
        userTeams?.forEach((team) => {
            team.artistAsins.forEach((asin: string) => {
                const artist = catalog.get(asin) as artist;
                teamArtists.push(artist);
            });
        });

        const artists: artist[] = [];
        if (teamArtists) {
            teamArtists.forEach((artist: artist) => {
                if (artist) {
                    artist.hasPermission = true;
                }

                if (!artists.includes(artist)) {
                    artists.push(artist);
                }
            });
        }

        return artists;
    }
);

// Gets whether we should do remote search or not
export const remoteSearchSelector = createSelector(
    [
        (state: RootState) => state.user.isPrivileged,
        (state: RootState) => state.user.teams,
    ],
    (isPrivileged, teams) => {
        return (
            !!isPrivileged ||
            (!!teams &&
                teams.filter(
                    (team) =>
                        team.teamType === TeamType.VENDOR ||
                        team.teamType === TeamType.LABEL
                ).length !== 0)
        );
    }
);

// Gets the search results for either local or remote search
const artistSearchResultSelector = createSelector(
    [
        remoteSearchSelector,
        getArtistSearchResults,
        getSelectedArtistAsin,
        selectedArtistFromCatalogSelector,
        teamArtistsSelector,
    ],
    (
        shouldRemoteSearch,
        artistSearchResults,
        selectedArtistAsin,
        selectedArtist,
        teamArtists
    ) => {
        if (shouldRemoteSearch) {
            return selectedArtist
                ? [
                      selectedArtist,
                      ..._.filter(
                          artistSearchResults,
                          (artist: artist) =>
                              artist?.asin !== selectedArtistAsin
                      ),
                  ]
                : artistSearchResults;
        }

        return teamArtists;
    }
);

// Combined props for the select artist page
export const selectArtistPageSelector: any = createSelector(
    [
        artistSearchResultSelector,
        remoteSearchSelector,
        recentlyViewedArtistsSelector,
        getSignedIn,
        getIsPrivileged,
        getSelectedArtistAsin,
        getUserTeams,
        getArtistSearchInProgress,
        getErrorFromStore,
        getUserRefreshInProgress,
        getHydrationInProgress,
        getDoRedirectToReports,
        currentPathSelector,
        deeplinkRouteSelector,
        deeplinkMapSelector,
    ],
    (
        artistSearchResults,
        shouldDoRemoteSearch,
        recentlyViewedArtists,
        signedIn,
        isPrivileged,
        selectedAsin,
        teams,
        artistSearchInProgress,
        errorFromStore,
        refreshInProgress,
        hydrationInProgress,
        doRedirectToReports,
        currentPath,
        deeplinkRoute,
        deeplinkMap
    ) => {
        return {
            artists: artistSearchResults,
            doRemoteSearch: shouldDoRemoteSearch,
            recentlyViewed: recentlyViewedArtists,
            signedIn: signedIn,
            isPrivileged: isPrivileged,
            selectedArtist: selectedAsin,
            teams: teams,
            inProgress: artistSearchInProgress,
            error: errorFromStore,
            refreshInProgress: refreshInProgress,
            hydrationInProgress: hydrationInProgress,
            doRedirectToReports: doRedirectToReports,
            currentPath: currentPath,
            deeplinkRoute: deeplinkRoute,
            deeplinkMap: deeplinkMap,
        };
    }
);

// Combined props for the search artist page
export const searchArtistPageSelector: any = createSelector(
    [
        getArtistSearchResults,
        getSignedIn,
        getArtistSearchInProgress,
        getErrorFromStore,
        getAcceptTeamInviteInProgress,
        getBundleMap,
    ],
    (
        artistSearchResults,
        signedIn,
        inProgress,
        errorFromStore,
        getAcceptTeamInviteInProgress,
        bundleMap
    ) => {
        return {
            artists: artistSearchResults,
            signedIn: signedIn,
            inProgress: inProgress,
            error: errorFromStore,
            acceptInviteInProgress: getAcceptTeamInviteInProgress,
            bundleMap: bundleMap,
        };
    }
);
