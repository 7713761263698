import * as React from "react";
import { testIDSuffixes } from "../../../utils";
import { Image } from "react-bootstrap";

type State = {
    source?: string;
};

export type ImageWithFallbackProps = {
    source?: any;
    fallback?: any;
    style?: any;
    id: string;
    imageDescription?: string;
    accessibilityLabel?: string;
    className?: string;
};
export class ImageWithFallback extends React.Component<
    ImageWithFallbackProps,
    State
> {
    constructor(props: ImageWithFallbackProps) {
        super(props);
        this.state = {
            source: props.source,
        };
    }

    componentDidMount() {
        this.setState({ source: this.props.source });
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.source !== prevProps.source) {
            this.setState({ source: this.props.source });
        }
    }

    render() {
        const id = this.props.id + testIDSuffixes.image;

        // If we didnt get an image source & fallback, then user did not intend to display an image. Hide image component
        if (!this.props.source && !this.props.fallback) {
            return null;
        }

        // If we got a fallback, but source is undefined, display fallback
        if (!this.props.source || !this.state.source) {
            return (
                <Image
                    style={this.props.style}
                    src={this.props.fallback}
                    className={this.props.className}
                    id={id}
                />
            );
        }

        // We got image source, display it
        return (
            <Image
                src={this.state.source}
                style={this.props.style}
                className={this.props.className}
                onError={this.onError}
                id={id}
            />
        );
    }

    // Image failed to load so clear source, defaulting to fallback
    private onError = (_error: any) => {
        this.setState({
            source: undefined,
        });
    };
}
