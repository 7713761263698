import * as React from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import {
    acceptInviteScreenSelector,
    RootState,
    telemetryActions,
    teamManagementActions,
    userActions,
    clientMetricsActions,
} from "../../../store";
import {
    telemetryPayload,
    METRIC_KEYS,
    acceptTeamInvitePayload,
    ErrorPayload,
    localStorageStrings,
    clientMetricsPayload,
    BundleMap,
} from "../../../models";
import { Dispatch, AnyAction } from "redux";
import { Container, Row, Col } from "react-bootstrap";
import { paths, getLocalizedString, buildUIClickPayload } from "../../../utils";
import {
    Icon,
    IconsList,
    styledTitle,
    LargeSolidButton,
    LargeOutlineButton,
    ErrorModal,
} from "../../../view/components";
import { stringIds, bundleIds } from "../../../assets";
import * as rootStyles from "../../styles";
import { spacers } from "../../styles";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";

const testIDPrefix = "AcceptInviteScreen";
const metricPrefix = "acceptInvitePage";

type ViewProps = {
    id?: string;
};

type StateProps = {
    signedIn: boolean;
    inviteAccepted: boolean;
    error?: ErrorPayload;
    userRefreshInProgress: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    acceptInvite: (payload: acceptTeamInvitePayload) => void;
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    inviteId?: string;
    showError: boolean;
};

class AcceptInviteScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            inviteId: "",
            showError: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.acceptInvite],
            ]),
        });
        const storedInviteId = localStorage.getItem(
            localStorageStrings.inviteId
        );
        const inviteId = storedInviteId || this.props.match.params.id;

        if (!inviteId) {
            this.props.history.replace(paths.home);
            return;
        }
        this.setState({ inviteId: inviteId });
    }

    componentDidUpdate(prevProps: Props) {
        // This redirects user AFTER invite has been accepted and user refreshed with new team info
        if (this.props.inviteAccepted && !this.props.userRefreshInProgress) {
            this.props.history.replace(paths.home);
            return;
        }

        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }
    }

    render() {
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Col style={containerStyle}>
                    <Row style={centerAlign}>
                        <Icon
                            size={48}
                            icon={IconsList.alerts_Information}
                            id={`${testIDPrefix}_Icon`}
                        />
                    </Row>
                    <Row style={centerAlign}>
                        <styledTitle.h2
                            style={{ textAlign: "center", lineHeight: 1 }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                                stringId:
                                    stringIds.ManageCurrentTeam
                                        .acceptInviteModalTitle,
                            })}
                        </styledTitle.h2>
                    </Row>
                    <Row style={centerAlign}>
                        <p
                            style={{
                                ...rootStyles.textStyles.primary,
                                textAlign: "center",
                            }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                                stringId:
                                    stringIds.ManageCurrentTeam
                                        .acceptInviteModalDescription,
                            })}
                        </p>
                    </Row>
                    <Row>
                        <Col style={buttonContainer}>
                            <LargeOutlineButton
                                containerStyle={{ width: "100%" }}
                                onClick={this.dismissInvite}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.GENERIC_STRINGS,
                                        stringId: stringIds.Generic.dismiss,
                                    }
                                )}
                                id={`${testIDPrefix}_Dismiss`}
                            />
                        </Col>
                        <Col style={buttonContainer}>
                            <LargeSolidButton
                                containerStyle={{ width: "100%" }}
                                onClick={this.acceptInvite}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.MANAGECURRENTTEAM_STRINGS,
                                        stringId:
                                            stringIds.ManageCurrentTeam
                                                .acceptInviteModalAccept,
                                    }
                                )}
                                id={`${testIDPrefix}_Accept`}
                            />
                        </Col>
                    </Row>
                </Col>
                <ErrorModal
                    isVisible={this.state.showError}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ERRORS_STRINGS,
                        stringId: stringIds.Errors.errorTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ERRORS_STRINGS,
                        stringId: stringIds.Errors.genericError,
                    })}
                    onDismiss={this.dismissError}
                />
            </Container>
        );
    }

    private acceptInvite = () => {
        if (!this.state.inviteId || !this.props.signedIn) {
            return;
        }

        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.InviteTeamMember.acceptInvite,
                pageIds.acceptInvite
            )
        );

        this.props.acceptInvite({
            inviteId: this.state.inviteId,
        });
        localStorage.removeItem(localStorageStrings.inviteId);
    };

    private dismissInvite = () => {
        if (!this.state.inviteId || !this.props.signedIn) {
            return;
        }

        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.InviteTeamMember.dismissInvite,
                pageIds.acceptInvite
            )
        );

        localStorage.removeItem(localStorageStrings.inviteId);
        this.props.history.replace(paths.home);
    };

    private dismissError = () => {
        this.setState({ showError: false });
    };
}

function mapStateToProps(state: RootState) {
    return acceptInviteScreenSelector(state, paths.acceptInvite);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        acceptInvite: (payload: acceptTeamInvitePayload) =>
            dispatch(teamManagementActions.acceptTeamInvite(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

const containerStyle: React.CSSProperties = {
    maxWidth: 600,
    margin: "auto",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: -86,
};

const centerAlign: React.CSSProperties = {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    marginBottom: spacers.medium,
};

const buttonContainer: React.CSSProperties = {
    paddingLeft: spacers.medium,
    paddingRight: spacers.medium,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInviteScreen);
