import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import React from "react";
import { useSelector } from "react-redux";
import { getLocalizedString, paths } from "../../../../utils";
import { FullScreenConfirmationModal } from "../../common";
import * as rootStyles from "../../../styles";
import { useHistory } from "react-router-dom";
import { commonSelectors } from "../../../../store/selectors";
import { BundleMap } from "../../../../models";

export type AboutMerchCurationModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
};

export const AboutMerchCurationModal: React.FC<
    AboutMerchCurationModalProps
> = ({ isVisible, onDismiss }) => {
    const history = useHistory();
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);
    const merchCurationDetails = (
        <ul
            style={{
                marginBottom: 0,
            }}
        >
            <li style={aboutMerchTextStyle}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId:
                        stringIds.Merch.Curation.AboutMerchRelatedToArtist,
                })}
            </li>
            <li style={aboutMerchTextStyle}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId:
                        stringIds.Merch.Curation.AboutMerchViewOnAmazonMusic,
                })}
            </li>
            <li style={aboutMerchTextStyle}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.AboutMerchMultipleTeams,
                })}
            </li>
            <li style={aboutMerchTextStyle}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.AboutMerchJPAccounts,
                })}
            </li>
            <li style={aboutMerchTextStyle}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.AboutMerchTermsOfUse1,
                })}
                <span
                    style={{
                        color: rootStyles.colors.accent,
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        onDismiss();
                        //give modal time to disappear before opening new window
                        setTimeout(() => {
                            history.push(paths.termsAndConditions);
                        }, 300);
                    }}
                >
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.TERMS_STRINGS,
                        stringId: stringIds.Terms.termsClickableText,
                    })}
                </span>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.AboutMerchTermsOfUse2,
                })}
            </li>
        </ul>
    );
    return (
        <FullScreenConfirmationModal
            isVisible={isVisible}
            confirmButtonAction={onDismiss}
            onDismiss={onDismiss}
            id={"AboutMerchCurationModal"}
            text={getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCHCURATION_STRINGS,
                stringId: stringIds.Merch.Curation.AboutMerch,
            })}
            body={merchCurationDetails}
            confirmButtonText={getLocalizedString(bundleMap, {
                bundleId: bundleIds.GENERIC_STRINGS,
                stringId: stringIds.Generic.ok,
            })}
            buttonOrientation={"stacked"}
        />
    );
};

const aboutMerchTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    marginBottom: rootStyles.spacers.medium,
};
