import _ from "lodash";
import { AnyAction } from "redux";
import { teamManagementMember } from "../../models";
import { teamManagementActions } from "../actions";

export enum ModifyMemberType {
    Add,
    Remove,
    SetPermissions,
    SetMemberInfo,
    PromoteToOwner,
    CancelInvite,
}

export type TeamState = Readonly<{
    teamMembers: teamManagementMember[];
    invitedMembers: teamManagementMember[];
    inviteSent: boolean;
    inviteInProgress: boolean;
    getTeamInProgress: boolean;
    getInvitedMembersInProgress: boolean;
    modifyMemberInProgress: boolean;
    modifyMemberSuccess: boolean;
    modifyMemberType: ModifyMemberType;
    acceptTeamInviteInProgress: boolean;
    acceptTeamInviteCompleted: boolean;
    error?: string;
    locale: string;
    invitePaginationToken: string;
    checkTeamExistsInProgress: boolean;
    teamExists: boolean;
}>;

export const initialTeamState: TeamState = {
    teamMembers: [],
    invitedMembers: [],
    inviteSent: false,
    inviteInProgress: false,
    getTeamInProgress: false,
    modifyMemberInProgress: false,
    getInvitedMembersInProgress: false,
    modifyMemberSuccess: false,
    acceptTeamInviteInProgress: false,
    acceptTeamInviteCompleted: false,
    locale: "en_us",
    modifyMemberType: ModifyMemberType.SetPermissions,
    invitePaginationToken: "",
    checkTeamExistsInProgress: false,
    teamExists: false,
};

export const teamReducer = (
    state: TeamState = initialTeamState,
    action: AnyAction
) => {
    switch (action.type) {
        case teamManagementActions.inviteTeamMemberCompleted.type:
            return { ...state, inviteSent: action.payload };
        case teamManagementActions.inviteTeamMemberInProgress.type:
            return { ...state, inviteInProgress: action.payload };
        case teamManagementActions.getTeamMembersInProgress.type:
            return { ...state, getTeamInProgress: action.payload };
        case teamManagementActions.getTeamMembersCompleted.type:
            return { ...state, teamMembers: action.payload.members };
        case teamManagementActions.getInvitedMembersCompleted.type:
            return { ...state, invitedMembers: action.payload.members };
        case teamManagementActions.setInvitePaginationToken.type:
            return {
                ...state,
                invitePaginationToken: action.payload.paginationToken,
            };
        case teamManagementActions.getInvitedMembersInProgress.type:
            return { ...state, getInvitedMembersInProgress: action.payload };
        case teamManagementActions.setInviteSent.type:
            return { ...state, inviteSent: action.payload };
        case teamManagementActions.setModifyMemberType.type:
            return { ...state, modifyMemberType: action.payload };
        case teamManagementActions.modifyMemberInProgress.type:
            return { ...state, modifyMemberInProgress: action.payload };
        case teamManagementActions.modifyMemberCompleted.type:
            return { ...state, modifyMemberSuccess: action.payload };
        case teamManagementActions.acceptTeamInviteInProgess.type:
            return { ...state, acceptTeamInviteInProgress: action.payload };
        case teamManagementActions.acceptTeamInviteCompleted.type:
            return { ...state, acceptTeamInviteCompleted: action.payload };
        case teamManagementActions.checkTeamExistsInProgress.type:
            return { ...state, checkTeamExistsInProgress: action.payload };
        case teamManagementActions.checkTeamExistsResponse.type:
            return { ...state, teamExists: action.payload };
        case teamManagementActions.clearState.type:
            return initialTeamState;
        default:
            return state;
    }
};
