/* tslint:disable */
import React from "react";

export const terms_enUS = (
    <div>
        <p className="p1">
            <span className="s1">
                <b>
                    Amazon Music for Artists Terms of Use
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b>Last updated February 19, 2022</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1">
                This is an agreement between you and Amazon.com Services LLC
                (with its affiliates, “Amazon”, “we,” or “us”) and governs your
                use of the Amazon Music for Artists service (“Amazon Music for
                Artists”). Before using Amazon Music for Artists, please read
                these Terms of Use, all related rules and policies (including
                any specific rules, usage restrictions, and other conditions or
                procedures that we post or update on or through Amazon Music for
                Artists or on any help or other informational page for Amazon
                Music for Artists), the{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&amp;view-type=content-only">
                    <span className="s3">Privacy Notice</span>
                </a>
                , the{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&amp;nodeId=508088">
                    <span className="s3">Conditions of Use</span>
                </a>
                , and the{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050&amp;view-type=content-only">
                    <span className="s3">Interest-Based Ads</span>
                </a>{" "}
                notices (collectively, the "Agreement"). The Agreement takes
                effect when you click an “I Accept” button or check box
                presented with these Terms of Use. You represent to us that you
                are lawfully able to enter into contracts (e.g., you are not a
                minor). If you are entering into this Agreement for an entity or
                individual, such as the company you work for or the artist you
                represent, you represent to us that you have legal authority to
                bind that entity or individual.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">1.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>GENERAL DESCRIPTION</b>. Amazon Music for Artists includes a
                variety of tools and features that allow artists and authorized
                artist representatives to gain insights into the artists’ fan
                bases and engage with their fans on services provided by Amazon
                Music (the “Services”).
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>ELIGIBILITY</b>.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                To use Amazon Music for Artists, you must have an Amazon account
                and be (a) an artist or authorized representative of an artist
                whose content is provided to Amazon for distribution on the
                Services or (b) an authorized representative of an entity that
                has an existing agreement with Amazon to provide content to
                Amazon for distribution on the Services.{" "}
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                You may not use a false e-mail address, impersonate any person
                or entity, or otherwise provide any false or misleading
                information, including any information provided in establishing
                an account or provided as to the ownership of any Artist
                Content.<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">2.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                You may not share your Amazon username and password with others
                or use anyone else’s Amazon username and password. However, you
                may allow other persons approved by you to view your Fan
                Insights in their own Amazon Music for Artists accounts.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p8">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.4.</span>
            <span className="s4"> </span>
            <span className="s1">
                You are responsible for all communications and activities that
                occur in relation to Amazon Music for Artists which come from
                your computer or computer facilities, including your e-mail
                account and password.
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.5.</span>
            <span className="s4"> </span>
            <span className="s1">
                We may share your contact information (including name, email
                address, and social media usernames) with third parties for
                verification and eligibility purposes and other individuals
                accessing your Fan Insights
            </span>
            <span className="s5">
                .<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">3.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>FAN INSIGHTS</b>.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                We may provide you with access to data related to the
                distribution of your content on the Services (“Fan Insights”).
                You can only use Fan Insights for internal assessment and
                analysis and cannot share them with a third party, unless such
                third party agrees to these Terms of Use. However, Amazon may
                provide you with certain information (e.g. milestones you’ve
                reached in the Services) via ‘share cards’ that you may make
                publicly available.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                Fan Insights are provided solely as an accommodation to you and
                we do not guarantee their accuracy. As such, they should not be
                relied upon to calculate any license fees or other amounts due
                to you as a result of the distribution of your content on the
                Services.
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                You will not use Fan Insights to attempt to derive data that is
                more detailed than the data provided (e.g. to determine personal
                information about an end user of the Services).
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">4.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>
                    RIGHTS.<span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Artist Content</b>. You may use Amazon Music for Artists to
                submit materials (e.g. images and artwork) that you own or are
                otherwise permitted to submit (“Artist Content”). You may not
                submit Artist Content that is illegal, obscene, threatening,
                defamatory, or otherwise contains any objectionable or
                inappropriate content, nor may you submit Artist Content that
                (a) injures or violates the rights of any third party, including
                privacy, publicity, copyright, trademark, patent, or other
                intellectual property rights or (b) would require us to pay
                royalties or any other fees to any third party for use of the
                Artist Content as permitted under the Agreement. You are
                responsible for and agree to pay any fees due with respect to
                Artist Content. Submission to Amazon Music for Artists does not
                change your ownership of Artist Content.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p9">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>License Grant</b>. You grant to us a non-exclusive,
                sub-licensable, worldwide, perpetual, irrevocable, royalty-free
                right and license to use, reproduce, distribute, transmit,
                perform, modify, and display all Artist Content in any media and
                technology formats in connection with the Services and Amazon
                Music for Artists.{" "}
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s5">4.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Suggestions</b>.{" "}
            </span>
            <span className="s1">
                If you provide suggested improvements to Amazon Music for
                Artists (“Suggestions”) to us, we will be entitled to use
                Suggestions without restriction. You hereby irrevocably assign
                to us all right, title, and interest in and to Suggestions and
                agree to provide us any assistance we require to document,
                perfect, and maintain our rights in Suggestions.{" "}
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">5.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>SECURITY AND DATA PRIVACY.</b>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>No Reverse Engineering or Circumvention</b>
            </span>
            <span className="s1">
                . You may not, and you will not encourage, assist, or authorize
                any other person to, modify, reverse engineer, circumvent,
                decompile, disassemble, dupe, or otherwise tamper with any part
                of Amazon Music for Artists.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>No Bulk Data Collection</b>. You may not, and you will not
                encourage, assist, or authorize any other person to “crawl”
                Amazon Music for Artists or otherwise use any data mining,
                scraping, or other data gathering tools to collect information
                from Amazon.
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">6.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>
                    CHANGES AND SUSPENSION.
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">6.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Changes</b>
            </span>
            <span className="s1">
                . We may change, suspend, or discontinue Amazon Music for
                Artists, or any part of it, at any time. From time to time, we
                may add features to or remove them from Amazon Music for
                Artists. There may be unusual circumstances where we may no
                longer be able to store or provide you access to particular
                features or Fan Insights.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">6.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b> Suspension and Termination by Us</b>
            </span>
            <span className="s1">
                . Your rights under the Agreement will automatically terminate
                without notice if you fail to comply with its terms. We may
                terminate the Agreement or restrict, suspend, or terminate your
                use of Amazon Music for Artists at any time, including if we
                determine that your use violates the Agreement, is improper,
                substantially exceeds or differs from normal use by other users,
                involves fraud or misuse of Amazon Music for Artists, or harms
                our interests or those of another user of Amazon Music for
                Artists. You may terminate the Agreement for any reason by
                closing your account.
            </span>
            <span className="s7"> </span>
            <span className="s1">
                {" "}
                If your access to Amazon Music for Artists is restricted,
                suspended, or terminated, you may be unable to access Fan
                Insights. Following any suspension or termination we will retain
                the rights to Artist Content granted to us under the Agreement.
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">7.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>DUE AUTHORITY AND INDEMNITIES.</b> You represent and warrant
                that: (a) you have all the rights necessary to grant us the
                rights granted in the Agreement; (b) the materials you submit
                and our use of them as authorized by the Agreement (i) will not
                violate or infringe the privacy rights, publicity rights,
                intellectual property rights, or other rights of any third
                party, (ii) are not defamatory, illegal, or obscene, and (iii)
                will not require us to pay royalties or any other fees to any
                third party for use of such materials; and (c) all factual
                statements that you submit are accurate and not misleading. You
                agree to indemnify, defend and hold harmless Amazon and its
                directors, officers, employees, agents and assigns from all
                claims, liabilities, damages and expenses, including reasonable
                legal fees and expenses, arising out of or in connection with
                materials you submit, your breach of the Agreement or your
                representations or warranties, or from your improper,
                unauthorized or unlawful use of Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">8.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>GENERAL</b>.
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">8.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Amendments</b>
            </span>
            <span className="s1">
                . We may amend the Agreement at any time by posting the revised
                terms on or through Amazon Music for Artists. Your continued use
                of Amazon Music for Artists after any amendment evidences your
                agreement to be bound by it.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">8.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Notices</b>.{" "}
            </span>
            <span className="s1">
                We may communicate with you in connection with your use of
                Amazon Music for Artists electronically and in other media, and
                you consent to such communications regardless of any customer
                communication or similar preferences or requests you may have
                indicated on the Amazon.com web site or by any other means.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Copyright Notices</b>
            </span>
            <span className="s1">
                . If you believe that your work has been copied in a way that
                constitutes copyright infringement, please follow the Notice and
                Procedure for Making Claims of Copyright Infringement specified
                in the{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909000&amp;view-type=content-only">
                    <span className="s8">Conditions of Use</span>
                </a>
                .
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.4.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Entire Agreement; Severability</b>
            </span>
            <span className="s1">
                . The Agreement constitutes the entire agreement between you and
                Amazon and governs your use of Amazon Music for Artists,
                superseding any prior agreements between you and Amazon. If any
                term or condition of the Agreement is deemed invalid, void, or
                for any reason unenforceable, that part will be deemed severable
                and will not affect the validity and enforceability of any
                remaining term or condition.
            </span>
        </p>
    </div>
);
