import React from "react";
import styled from "styled-components";

// Standard container with margin styling for claim header on mobile screens.
export const ClaimHeader = styled((props) => <div {...props} />)`
    height: 80px;
    @media (min-width: 992px) {
        height: 0px;
    }
`;

// The height is designed to match the
export const ClaimHeaderWithBackButton = styled(ClaimHeader)`
    @media (min-width: 992px) {
        height: 68px;
    }
`;
