import { AnyAction } from "redux";
import { baseMediaItem } from "../../models";
import { contentSearchActions } from "../actions";

export type ContentSearchState = Readonly<{
    items: baseMediaItem[];
    inProgress: boolean;
}>;

export const intialContentSearchState = {
    items: [],
    inProgress: false,
};

export const contentSearchReducer = (
    state: ContentSearchState = intialContentSearchState,
    action: AnyAction
) => {
    switch (action.type) {
        case contentSearchActions.trackAlbumSearchCompleted.type:
            return { ...state, items: action.payload };
        case contentSearchActions.trackAlbumSearchInProgress.type:
            return { ...state, inProgress: action.payload };
        case contentSearchActions.clearTrackAlbumSearch.type:
            return { ...state, items: [] };
        default:
            return state;
    }
};
