import { outageStatusEntry } from "../models";

export const OutageStatusTypes = {
    realtimeData: "REALTIME_DATA",
    claims: "CLAIMS",
    all: "ALL",
};

export const getRelevantOutage = (
    outageStatuses: outageStatusEntry[],
    path: string | undefined
) => {
    // Find the first outage status that contains any of the relevant status types or "ALL"
    const statusTypesToCheck = [
        ...getRelevantStatusTypesForPath(path),
        OutageStatusTypes.all,
    ];

    return outageStatuses.find((status) =>
        status.outageTypes?.find((statusType) =>
            statusTypesToCheck.includes(statusType)
        )
    );
};

const getRelevantStatusTypesForPath = (path: string | undefined): string[] => {
    if (path) {
        if (path.startsWith("/reports")) {
            return [OutageStatusTypes.realtimeData];
        } else if (path.startsWith("/claim")) {
            return [OutageStatusTypes.claims];
        }
    }
    return [];
};
