import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { MerchCarousel, merchItemCardProps, MODBanner } from "../../components";
import {
    merchDeviceFamily,
    drTeethMerchIdentifiers,
    hydrateMerchPayload,
    merchIdentifiers,
    hydratedMerchItem,
    BundleMap,
} from "../../../models";
import { merchHydratorActions } from "../../../store/actions";
import {
    commonSelectors,
    getHydratingIdentifiers,
    getMerchCatalog,
    getUserLocale,
} from "../../../store/selectors";
import { getLocalizedString, paths, US_MARKETPLACE } from "../../../utils";
import { pageIds, stringIds, bundleIds } from "@amzn/ziggy-asset";

const MAX_NUMBER_CAROUSEL_ITEMS = 15;

type MerchCarouselDisplayProps = {
    hasMerchIngressPermissions: boolean;
    showMod: boolean;
    merchItems: merchIdentifiers[];
    id: string;
};

export const MerchCarouselDisplay: React.FC<MerchCarouselDisplayProps> = ({
    hasMerchIngressPermissions,
    showMod,
    merchItems,
    id,
}) => {
    const merchCatalog = useSelector(getMerchCatalog);
    const hydratingIdentifiers = useSelector(getHydratingIdentifiers);
    const locale = useSelector(getUserLocale);
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const dispatch = useDispatch();

    //hydrate only the first 15 identifiers
    const slicedIdentifiers = _.slice(merchItems, 0, MAX_NUMBER_CAROUSEL_ITEMS);
    const identifiersToHydrate: drTeethMerchIdentifiers[] = _.map(
        slicedIdentifiers,
        (asin) => {
            return { itemAsin: asin };
        }
    );
    const payload: hydrateMerchPayload = {
        requestPath: paths.merch,
        request: {
            identifiers: identifiersToHydrate,
            userContext: {
                marketplaceId: US_MARKETPLACE,
                locale: locale,
                deviceFamily: merchDeviceFamily.AM4A_WEB,
            },
        },
    };
    React.useEffect(() => {
        dispatch(merchHydratorActions.hydrateMerch(payload));
    }, [merchItems]);

    const itemCards: merchItemCardProps[] = slicedIdentifiers.map(
        (itemAsin: merchIdentifiers): merchItemCardProps => {
            if (merchCatalog.has(itemAsin)) {
                const item = merchCatalog.get(itemAsin) as hydratedMerchItem;
                return {
                    image: item.metadata.productImages[0]?.lowResUrl,
                    itemAsin: item.itemAsin,
                    primaryText: item.metadata.itemName,
                    secondaryText: item.metadata.category,
                    tertiaryText: item.metadata.price,
                    id: `MerchItem_${itemAsin}`,
                    requestPath: paths.merch,
                    pageId: pageIds.artistMerchHomePage,
                };
            } else if (hydratingIdentifiers.includes(itemAsin)) {
                return {
                    isLoading: true,
                    itemAsin,
                    requestPath: paths.merch,
                    pageId: pageIds.artistMerchHomePage,
                    id: `MerchItem_${itemAsin}`,
                };
            } else {
                return {
                    itemAsin,
                    primaryText: getLocalizedString(bundleMap, {
                        bundleId: bundleIds.GENERIC_STRINGS,
                        stringId: stringIds.Generic.missingCatalogDataTitle,
                    }),
                    requestPath: paths.merch,
                    pageId: pageIds.artistMerchHomePage,
                    id: `MerchItem_${itemAsin}`,
                };
            }
        }
    );
    return (
        <>
            <MerchCarousel
                merchItemCards={itemCards}
                totalNumberMerchItems={merchItems.length}
                pageId={pageIds.artistMerchHomePage}
                id={id}
            />
            {showMod && (
                <MODBanner
                    hasMerchIngressPermissions={hasMerchIngressPermissions}
                />
            )}
        </>
    );
};
