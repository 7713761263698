import * as React from "react";
import * as rootStyles from "../../../styles";
import { Row, Col } from "react-bootstrap";
import { styledTitle } from "../Title";

export type TableHeaderProps = {
    title?: string;
    subtitle?: string;
    secondaryText?: string;
    id: string;
    headerButton?: JSX.Element;
    moreButtonDescription?: string;
};

export class TableHeader extends React.PureComponent<TableHeaderProps> {
    render() {
        if (!this.props.title && !this.props.subtitle) {
            return null;
        }

        return (
            <Row style={headerContainerStyle}>
                <Col style={textContainerStyle}>
                    {this.props.title && (
                        <Row id={`${this.props.id}-Title`}>
                            <styledTitle.h4
                                key="title"
                                style={{
                                    paddingBottom: rootStyles.spacers.micro,
                                    margin: 0,
                                }}
                            >
                                {this.props.title}
                            </styledTitle.h4>
                        </Row>
                    )}
                    {this.props.subtitle && (
                        <Row id={`${this.props.id}-Subtitle`}>
                            <span
                                key="subtitle"
                                style={rootStyles.textStyles.index}
                            >
                                {this.props.subtitle}
                            </span>
                        </Row>
                    )}

                    {this.props.secondaryText && (
                        <Row id={`${this.props.id}-SecondaryText`}>
                            <span
                                key="secondaryText"
                                style={{
                                    ...rootStyles.textStyles.secondary,
                                    marginBottom: rootStyles.spacers.small,
                                }}
                            >
                                {this.props.secondaryText}
                            </span>
                        </Row>
                    )}
                </Col>
                {this.props.headerButton ? (
                    <Col xs={"auto"} style={{ textAlign: "right", padding: 0 }}>
                        {this.props.headerButton}
                    </Col>
                ) : null}
            </Row>
        );
    }
}

const headerContainerStyle: React.CSSProperties = {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "flex-start",
};
const textContainerStyle: React.CSSProperties = {
    flex: 1,
    paddingLeft: 0,
};
const moreButtonStyle: React.CSSProperties = {
    width: 48,
    minWidth: 48,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "flex-end",
};
