import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { stringIds, bundleIds } from "../../../assets";
import { BundleMap, itemProps } from "../../../models";
import { RootState, commonSelectors } from "../../../store";
import {
    getLocalizedString,
    selectedInGroupsToItemPropsList,
} from "../../../utils";
import * as rootStyles from "../../styles";
import { BoxList, Icon, IconsList, styledTitle, StepField } from "../common";

export type PitchReviewProps = {
    onEditButtonClick: () => void;
};

export const PitchReview: React.FC<PitchReviewProps> = ({
    onEditButtonClick,
}) => {
    const testIDPrefix = "PitchReview";

    // Selectors
    const description = useSelector(
        (state: RootState) => state.pitchForm.description
    );
    const genresProps = useSelector(
        (state: RootState) => state.pitchForm.genresProps
    );

    const compositionsProps = useSelector(
        (state: RootState) => state.pitchForm.compositionsProps
    );
    const songHasVocals = useSelector(
        (state: RootState) => state.pitchForm.songHasVocals
    );
    const languagesProps = useSelector(
        (state: RootState) => state.pitchForm.languagesProps
    );

    const songMoodsProps = useSelector(
        (state: RootState) => state.pitchForm.songMoodsProps
    );
    const songStylesProps = useSelector(
        (state: RootState) => state.pitchForm.songStylesProps
    );

    const relatedArtistsProps = useSelector(
        (state: RootState) => state.pitchForm.relatedAritstProps
    );
    const locationProps = useSelector(
        (state: RootState) => state.pitchForm.locationProps
    );
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const getSelectedItemsFromItemsProps = (
        itemsProps: itemProps[]
    ): itemProps[] => {
        return itemsProps.filter((item) => item.selected);
    };

    const pitchReviewHeader = () => {
        return (
            <Row style={{ alignItems: "center" }}>
                <div style={{ flex: 1, userSelect: "none" }}>
                    <Row>
                        <styledTitle.h4>
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formReviewTitle,
                            })}
                        </styledTitle.h4>
                    </Row>
                    <Row>
                        <span style={rootStyles.textStyles.secondary}>
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formReviewSubtitle,
                            })}
                        </span>
                    </Row>
                </div>
                <div
                    style={editButtonContainerStyle}
                    onClick={onEditButtonClick}
                >
                    <Icon
                        size={20}
                        icon={IconsList.action_edit}
                        id={`${testIDPrefix}_EditIcon`}
                    />
                    <div
                        style={{
                            textAlign: "center",
                            flex: 1,
                            marginLeft: rootStyles.spacers.mini,
                        }}
                    >
                        <span style={rootStyles.textStyles.primary}>
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formEdit,
                            })}
                        </span>
                    </div>
                </div>
            </Row>
        );
    };

    const pitchReview = () => {
        const languagesItems = selectedInGroupsToItemPropsList(languagesProps);
        const songMoodsItems = getSelectedItemsFromItemsProps(songMoodsProps);
        const songStylesItems = getSelectedItemsFromItemsProps(songStylesProps);
        const relatedArtistsItems =
            selectedInGroupsToItemPropsList(relatedArtistsProps);
        const locationItems = selectedInGroupsToItemPropsList(locationProps);

        const showVocalLanguages: boolean = languagesItems.length > 0;
        const showSongMoods: boolean = songMoodsItems.length > 0;
        const showSongStyles: boolean = songStylesItems.length > 0;
        const showRelatedArtists: boolean = relatedArtistsItems.length > 0;
        const showLocations: boolean = locationItems.length > 0;

        const whyPitchThisSong = () => {
            return (
                <Row style={descriptionContainerStyle}>
                    <span
                        style={{
                            ...rootStyles.textStyles.primary,
                            wordBreak: "break-word",
                        }}
                    >
                        {description}
                    </span>
                </Row>
            );
        };

        const whatsItsGenre = () => {
            return (
                <BoxList
                    showCancelIcon={false}
                    itemsProps={selectedInGroupsToItemPropsList(genresProps)}
                    id={`${testIDPrefix}-Genre`}
                    viewOnly={true}
                />
            );
        };

        const selectedKindOfSong = () => {
            return (
                <BoxList
                    showCancelIcon={false}
                    itemsProps={getSelectedItemsFromItemsProps(
                        compositionsProps
                    )}
                    id={`${testIDPrefix}-KindOfSong`}
                    viewOnly={true}
                />
            );
        };

        const selectedVocalsLanguages = () => {
            return (
                <BoxList
                    showCancelIcon={false}
                    itemsProps={languagesItems}
                    id={`${testIDPrefix}-Languages`}
                    viewOnly={true}
                />
            );
        };

        const selectedSongMoods = () => {
            return (
                <BoxList
                    showCancelIcon={false}
                    itemsProps={songMoodsItems}
                    id={`${testIDPrefix}-SongMoods`}
                    viewOnly={true}
                />
            );
        };

        const selectedSongStyles = () => {
            return (
                <BoxList
                    showCancelIcon={false}
                    itemsProps={songStylesItems}
                    id={`${testIDPrefix}-SongStyles`}
                    viewOnly={true}
                />
            );
        };

        const selectedSimilarArtists = () => {
            return (
                <BoxList
                    showCancelIcon={false}
                    itemsProps={relatedArtistsItems}
                    id={`${testIDPrefix}-SimilarArtists`}
                    viewOnly={true}
                />
            );
        };

        const selectedLocation = () => {
            return (
                <BoxList
                    showCancelIcon={false}
                    itemsProps={locationItems}
                    id={`${testIDPrefix}-Location`}
                    viewOnly={true}
                />
            );
        };

        return (
            <>
                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formWhyThisSongTitle,
                    })}
                    id={`${testIDPrefix}-Description`}
                    bottomElement={whyPitchThisSong()}
                    bottomElementIndent={false}
                />

                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formGenreTitle,
                    })}
                    id={`${testIDPrefix}-Genre`}
                    bottomElement={whatsItsGenre()}
                    bottomElementIndent={false}
                />

                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formKindOfSongTitle,
                    })}
                    id={`${testIDPrefix}-KindOfSong`}
                    bottomElement={selectedKindOfSong()}
                    bottomElementIndent={false}
                />

                {showVocalLanguages ? (
                    <StepField
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formLanguagesTitle,
                        })}
                        id={`${testIDPrefix}-Languages`}
                        bottomElement={selectedVocalsLanguages()}
                        bottomElementIndent={false}
                    />
                ) : null}

                {showSongMoods ? (
                    <StepField
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formSongMoodTitle,
                        })}
                        id={`${testIDPrefix}-SongMoods`}
                        bottomElement={selectedSongMoods()}
                        bottomElementIndent={false}
                    />
                ) : null}

                {showSongStyles ? (
                    <StepField
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formSongStyleTitle,
                        })}
                        id={`${testIDPrefix}-SongStyles`}
                        bottomElement={selectedSongStyles()}
                        bottomElementIndent={false}
                    />
                ) : null}

                {showRelatedArtists ? (
                    <StepField
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formSimilarAritstsTitle,
                        })}
                        id={`${testIDPrefix}-SimilarArtists`}
                        bottomElement={selectedSimilarArtists()}
                        bottomElementIndent={false}
                    />
                ) : null}

                {showLocations ? (
                    <StepField
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId:
                                stringIds.Pitch.formListenersLocationTitle,
                        })}
                        id={`${testIDPrefix}-Locations`}
                        bottomElement={selectedLocation()}
                        bottomElementIndent={false}
                    />
                ) : null}
            </>
        );
    };

    return (
        <Row style={{ marginTop: rootStyles.spacers.medium }}>
            <Col>
                {pitchReviewHeader()}
                {pitchReview()}
            </Col>
        </Row>
    );
};

const editButtonContainerStyle: React.CSSProperties = {
    backgroundColor: rootStyles.glassColors.primary2,
    display: "inline-flex",
    height: 40,
    paddingLeft: rootStyles.spacers.small,
    paddingRight: rootStyles.spacers.base,
    borderRadius: 20,
    alignItems: "center",
    cursor: "pointer",
    minWidth: 120,
};

const descriptionContainerStyle: React.CSSProperties = {
    maxWidth: 450,
    backgroundColor: rootStyles.glassColors.primary2,
    padding: 15,
    borderRadius: 5,
    marginBottom: rootStyles.spacers.base,
};
