import React from "react";
import { Dropdown } from "react-bootstrap";
import { Icon, Portal } from "../..";
import { buttonStyles, breakpoints } from "../../../../styles";
import { OverflowMenuItemProps } from "./OverflowMenuItem";

export type OverflowMenuProps = {
    children:
        | React.ReactElement<OverflowMenuItemProps>
        | (React.ReactElement<OverflowMenuItemProps> | undefined)[];
    icon: string;
    buttonStyle?: React.CSSProperties;
    dropdownStyle?: React.CSSProperties;
    iconSize?: number;
    id: string;
};

export const OverflowMenu: React.FC<OverflowMenuProps> = ({
    children,
    icon,
    buttonStyle,
    iconSize,
    id,
}) => {
    return (
        <Dropdown
            style={{
                ...buttonStyles.dropDownStyles.dropdown,
                margin: 0,
                display: "flex",
            }}
            className="rightPointer"
            drop={window.innerWidth <= breakpoints.md ? "down" : "right"}
        >
            <Dropdown.Toggle
                style={{
                    ...buttonStyles.dropDownStyles.dropdownButton,
                    width: "none",
                    boxShadow: "none",
                    ...buttonStyle,
                }}
                id={id + "-DropdownToggle"}
                className={"trackAlbumMoreDropdown"}
            >
                <Icon
                    size={iconSize ? iconSize : 20}
                    icon={icon}
                    id={`${id}_Icon`}
                />
            </Dropdown.Toggle>

            <Portal>
                <Dropdown.Menu
                    style={{
                        ...buttonStyles.dropDownStyles.dropdownMenu,
                        marginLeft: 20,
                        padding: 0,
                        width: "auto",
                    }}
                >
                    {children}
                </Dropdown.Menu>
            </Portal>
        </Dropdown>
    );
};

export default OverflowMenu;
