import * as React from "react";
import { IconsList } from "../../../../assets";
import { testIDSuffixes } from "../../../../utils";

export type IconProps = {
    icon: any;
    size: number;
    color?: string;
    id: string;
    iconDescription?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
};

export class Icon extends React.Component<IconProps> {
    render() {
        const cursor = this.props.onClick ? "pointer" : "inherit";

        return (
            <img
                alt={this.props.iconDescription}
                src={this.props.icon}
                style={{
                    ...this.props.style,
                    height: this.props.size,
                    width: this.props.size,
                    fill: this.props.color,
                    cursor: cursor,
                    userSelect: "none",
                }}
                onClick={this.props.onClick}
                id={`${this.props.id}${testIDSuffixes.icon}`}
            />
        );
    }
}

export { IconsList };
