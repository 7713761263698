import * as React from "react";
import { Row, Col } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { testIDSuffixes } from "../../../utils";
import { Icon, IconsList } from "..";

export type ListItemProps = {
    primary?: string;
    primaryComponent?: JSX.Element;
    secondary?: string;
    index?: number;
    onClick?: any;
    leftIcon?: any;
    leftIndent?: boolean;
    leftIconDescription?: string;
    rightIcon?: any;
    rightIconDescription?: string;
    showBorder?: boolean;
    content?: JSX.Element;
    isSelected?: boolean;
    selectable?: boolean;
    isLast?: boolean;
    id: string;
    disabled?: boolean;
    style?: any;
};

export class ListItem extends React.PureComponent<ListItemProps> {
    render() {
        const dynamicStyles = this.getDynamicStyles(this.props.isSelected);

        let rightIcon;
        const rightIconAccessibilityProps = {
            id: this.props.id + testIDSuffixes.rightIcon,
            iconDescription: this.props.rightIconDescription,
        };
        if (this.props.rightIcon || this.props.selectable) {
            if (this.props.selectable) {
                rightIcon = (
                    <Icon
                        icon={
                            this.props.isSelected
                                ? IconsList.action_done
                                : this.props.rightIcon
                        }
                        size={rootStyles.icons.small}
                        color={
                            this.props.isSelected
                                ? rootStyles.colors.accent
                                : undefined
                        }
                        {...rightIconAccessibilityProps}
                    />
                );
            } else {
                rightIcon = (
                    <Icon
                        icon={this.props.rightIcon}
                        size={rootStyles.icons.small}
                        {...rightIconAccessibilityProps}
                    />
                );
            }
        }
        const content = (
            <Row
                id={this.props.id + "-DefaultContent"}
                style={{
                    ...(this.props.isLast
                        ? lastContainerStyle
                        : containerStyle),
                    ...this.props.style,
                }}
            >
                {(this.props.leftIcon || this.props.leftIndent) && (
                    <Col style={leftIconContainer}>
                        {this.props.leftIcon && (
                            <Icon
                                icon={this.props.leftIcon}
                                size={rootStyles.icons.small}
                                color={
                                    this.props.isSelected
                                        ? rootStyles.colors.accent
                                        : undefined
                                }
                                id={this.props.id + testIDSuffixes.leftIcon}
                                iconDescription={this.props.leftIconDescription}
                            />
                        )}
                    </Col>
                )}
                {this.props.primary ||
                this.props.primaryComponent ||
                this.props.secondary ? (
                    <Col style={textContainer}>
                        {this.props.primaryComponent ? (
                            this.props.primaryComponent
                        ) : (
                            <Row>
                                <span
                                    style={dynamicStyles.primary}
                                    id={this.props.id + testIDSuffixes.primary}
                                >
                                    {this.props.primary}
                                </span>
                            </Row>
                        )}
                        {this.props.secondary && (
                            <Row>
                                <span
                                    style={dynamicStyles.secondary}
                                    id={
                                        this.props.id + testIDSuffixes.secondary
                                    }
                                >
                                    {this.props.secondary}
                                </span>
                            </Row>
                        )}
                    </Col>
                ) : null}
                {this.props.index ? (
                    <Col
                        style={indexContainer}
                        id={this.props.id + testIDSuffixes.index}
                    >
                        <Row style={indexStyle}>
                            <span style={indexTextStyle}>
                                {this.props.index}
                            </span>
                        </Row>
                    </Col>
                ) : null}

                {rightIcon ? (
                    <Col style={iconButtonContainer}>{rightIcon}</Col>
                ) : null}
            </Row>
        );

        const innerContent = this.props.content ? this.props.content : content;

        if (this.props.disabled) {
            return (
                <Row
                    style={
                        this.props.isLast ? lastContainerStyle : containerStyle
                    }
                    onClick={this.props.onClick}
                    id={this.props.id}
                >
                    {innerContent}
                </Row>
            );
        } else {
            return (
                <Row
                    style={
                        this.props.isLast ? lastContainerStyle : containerStyle
                    }
                    onClick={this.props.onClick}
                    id={this.props.id}
                >
                    {innerContent}
                </Row>
            );
        }
    }

    private getDynamicStyles = (isSelected?: boolean) => {
        return isSelected ? selectedTextStyle : unselectedTextStyle;
    };
}

const selectedTextStyle = {
    primary: {
        ...rootStyles.textStyles.primary,
        color: rootStyles.colors.accent,
    },
    secondary: {
        ...rootStyles.textStyles.secondary,
        color: rootStyles.colors.accent,
    },
};

const unselectedTextStyle = {
    primary: rootStyles.textStyles.primary,
    secondary: rootStyles.textStyles.secondary,
};

const sharedContainerStyle: React.CSSProperties = {
    flex: 1,
    height: 64,
    flexDirection: "row",
    alignItems: "flex-start",
    alignSelf: "flex-end",
    justifyContent: "center",
    display: "flex",
};

const containerStyle: React.CSSProperties = {
    ...sharedContainerStyle,
    borderBottomColor: rootStyles.glassColors.primary3,
    borderBottomWidth: 1,
};

const lastContainerStyle: React.CSSProperties = {
    ...sharedContainerStyle,
};
const iconButtonStyle: React.CSSProperties = {
    height: rootStyles.icons.tiny,
    width: rootStyles.icons.tiny,
};
const textContainer: React.CSSProperties = {
    flex: 1,
    minHeight: 64,
    flexDirection: "column",
    justifyContent: "center",
};
const indexContainer: React.CSSProperties = {
    flex: 1,
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 40,
};
const indexStyle: React.CSSProperties = {
    backgroundColor: rootStyles.colors.accent,
    borderRadius: 32,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    flex: 1,
    padding: rootStyles.spacers.micro,
    maxHeight: 20,
};
const indexTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    color: rootStyles.colors.secondary,
};
const leftIconContainer: React.CSSProperties = {
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    height: 64,
    width: 40,
    paddingRight: rootStyles.spacers.base,
    flex: 0,
};
const iconButtonContainer: React.CSSProperties = {
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    height: 64,
    width: 40,
    flex: 0,
};
