import _ from "lodash";

const gooberyShishimaruMcFluffinJrArtistAsin: string = "B086MQ1TPF";
const claimGoobernessTitlesetAsin: string = "B086QQQ6QW";
const tempestWarlockDriverAutoArtistAsin: string = "B0BXW84GGL";

const testArtistAsins: string[] = [gooberyShishimaruMcFluffinJrArtistAsin];
const testTrackTitlesetAsin: string[] = [claimGoobernessTitlesetAsin];

const testBaseMediaItemTitlesetAsins: Set<string> = new Set<string>([
    "B0BXZ8YQX9",
    "B086QQ45GW",
    "B086QQPRGG",
    "B086QQ9MDG",
    "B08JGDTBL2",
    "B08JFT6X23",
    "B08JFWXHBP",
    "B08JFZHNF7",
    "B08JGJ5PLR",
    "B08JGB561M",
    "B08JGL13PN",
    "B08VDTVXMF",
    "B09SRTGV2Q",
    "B09SRPLKJW",
    "B09SRT7YKT",
    "B09SRXN62C",
    "B09SS53LXG",
    "B09SRNMPF9",
    "B09SSFV2SG",
    "B09SS5T9NM",
    "B09SS5ZK5M",
    "B09SS356YH",
    "B09SS7C9K3",
    "B09SS8HRLB",
    "B09SRTJRRM",
    "B09SRZNTY2",
    "B09SS2S9BK",
    "B09SRTTB9V",
    "B09SS1GTSM",
    "B09SSDWRLP",
    "B09SRV12GM",
    "B09SSFX3T4",
    "B09SSWNNMV",
    "B09SSHCSJJ",
    "B09SSTPHD5",
    "B09SSHPRD4",
    "B09SSFMH5V",
    "B09SS8XX3S",
    "B09SSF4P2Z",
    "B09T2RDWPB",
]);

export const CommonTestData = {
    gooberyShishimaruMcFluffinJrArtistAsin,
    tempestWarlockDriverAutoArtistAsin,
    claimGoobernessTitlesetAsin,
    testArtistAsins,
    testTrackTitlesetAsin,
    testBaseMediaItemTitlesetAsins,
};
