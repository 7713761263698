import * as React from "react";
import _ from "lodash";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import {
    playlist,
    RequiredReportingState,
    METRIC_KEYS,
    TimeRange,
    programmingPerformanceRequestPayload,
    telemetryPayload,
    hydrateCatalogPayload,
    trackInfo,
    timeRangePayload,
    RequiredReportingProps,
    countryInfo,
    TableRowProps,
    EntityType,
    trackStreamsInfo,
    trackListenersInfo,
    CatalogItemType,
    clientMetricsPayload,
    BundleMap,
} from "../../../models";
import {
    RootState,
    reportingActions,
    telemetryActions,
    playlistPerformanceScreenSelector,
    catalogActions,
    userActions,
    clientMetricsActions,
} from "../../../store";
import * as rootStyles from "../../styles";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import {
    Table,
    Loading,
    IncrementalLoading,
    ProfileHeadProps,
    ProfileHead,
    InlineError,
    VerticalSpacedTable,
    TableSeeAllButton,
    LineGraphProps,
    LineGraph,
} from "../../components";
import {
    getLocalizedString,
    generateQuery,
    generateSongRows,
    paths,
    HYDRATION_COUNT,
    generateTerritoryList,
    guessTimeZone,
    SUMMARY_TABLE_ITEM_COUNT,
    generateCountryRows,
    generateQueryWithoutGranularity,
    parseTrackDataPoints,
    timeRangeToTickCount,
    formatDatum,
    formatTooltipDatum,
    getNewTrackAsins,
    hydrationHelpers,
    buildUIClickPayload,
    formatNumber,
} from "../../../utils";

const testIDPrefix = "PlaylistPerformanceScreen";
const metricPrefix = "playlistPerformancePage";

type ViewProps = {
    asin: string;
};

type StateProps = RequiredReportingProps & {
    tracks: trackInfo[];
    trackStreamInfo?: trackStreamsInfo;
    trackListenerInfo?: trackListenersInfo;
    topCountries: countryInfo[];
    selectedAsin: string;
    bundleMap: BundleMap;
};

type DispatchProps = {
    getPlaylistPerformance: (
        request: programmingPerformanceRequestPayload
    ) => void;
    userAction: (payload: telemetryPayload) => void;
    clearFailedAsins: () => void;
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    unselectAsin: (asin: string) => void;
    selectAsin: (asin: string) => void;
    updateCurrentPath: (payload: string) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    playlistCatalogItem?: playlist;
    loadedCount: number;
};

class PlaylistPerformanceScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            loadedCount: 0,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        const asin = this.props.match.params.asin;
        if (!asin) {
            return;
        }

        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist],
                [METRIC_KEYS.timeRange, this.props.selectedRange],
                [METRIC_KEYS.playlistId, asin],
                [METRIC_KEYS.page, paths.playlistPerformance],
            ]),
        });

        this.props.selectAsin(asin);

        const playlistCatalogItem = this.props.catalog.get(asin) as playlist;
        if (playlistCatalogItem) {
            this.setState({ playlistCatalogItem: playlistCatalogItem });
        }
        this.getPlaylistPerformance();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedRange !== this.props.selectedRange) {
            this.getPlaylistPerformance();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getPlaylistPerformance();
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.state.loadedReports &&
            this.props.catalogBuildCompleted &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            if (
                this.props.error &&
                this.props.error.requestPath === paths.playlistPerformance
            ) {
                this.setState({ showError: true });
            } else {
                this.setState({ showError: false });
            }
        }

        if (
            prevProps.tracks.length < this.props.tracks.length ||
            this.state.loadedCount < this.props.tracks.length
        ) {
            this.loadMore();
        }
    }

    render() {
        // Loading state
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const streamCount =
            (this.props.trackStreamInfo &&
                this.props.trackStreamInfo.totalCount) ||
            0;
        const userTimeZone = guessTimeZone();

        const streamLineGraphProps: LineGraphProps | undefined = this.props
            .trackStreamInfo && {
            data: [
                parseTrackDataPoints(
                    this.props.trackStreamInfo,
                    this.props.selectedRange === TimeRange.AllTime
                ),
            ],
            title: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.totalStreamsTitle,
                },
                { "0": formatNumber(streamCount) }
            ),
            labels:
                this.props.selectedRange === TimeRange.AllTime
                    ? [
                          getLocalizedString(this.props.bundleMap, {
                              bundleId: bundleIds.REPORTS_STRINGS,
                              stringId: stringIds.Reports.allTimeSubtitle,
                          }),
                      ]
                    : undefined,
            xTickCount: timeRangeToTickCount(this.props.selectedRange),
            tickFormat: (datum: any) =>
                formatDatum(datum, this.props.selectedRange, userTimeZone),
            tooltipFormat: (datum: any) =>
                formatTooltipDatum(
                    datum,
                    this.props.selectedRange,
                    userTimeZone
                ),
            id: testIDPrefix + "_StreamLineGraph",
            bundleMap: this.props.bundleMap,
        };

        const listenersCount =
            (this.props.trackListenerInfo &&
                this.props.trackListenerInfo.totalCount) ||
            0;

        const listenerLineGraphProps: LineGraphProps | undefined = this.props
            .trackListenerInfo && {
            data: [
                parseTrackDataPoints(
                    this.props.trackListenerInfo,
                    this.props.selectedRange === TimeRange.AllTime
                ),
            ],
            title: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.totalListenersTitle,
                },
                { "0": formatNumber(listenersCount) }
            ),
            labels:
                this.props.selectedRange === TimeRange.AllTime
                    ? [
                          getLocalizedString(this.props.bundleMap, {
                              bundleId: bundleIds.REPORTS_STRINGS,
                              stringId: stringIds.Reports.allTimeSubtitle,
                          }),
                      ]
                    : undefined,
            xTickCount: timeRangeToTickCount(this.props.selectedRange),
            tickFormat: (datum: any) =>
                formatDatum(datum, this.props.selectedRange, userTimeZone),
            tooltipFormat: (datum: any) =>
                formatTooltipDatum(
                    datum,
                    this.props.selectedRange,
                    userTimeZone
                ),
            id: testIDPrefix + "_ListenerLineGraph",
            bundleMap: this.props.bundleMap,
        };

        const playlist = this.state.playlistCatalogItem;

        let profileHeadProps: ProfileHeadProps = {
            imageSource: "",
            title: "",
            subtitle: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.playlistsLabel,
            }),
            id: testIDPrefix + "_PlaylistProfileHead",
        };

        if (playlist) {
            profileHeadProps = {
                ...profileHeadProps,
                imageSource:
                    playlist.images && playlist.images.artLarge
                        ? playlist.images.artLarge
                        : "",
                fallbackImage: ImageList.placeholder_playlist,
                title: playlist.title || "",
                imageDescription: playlist.title,
            };
        }

        const territories: string =
            (playlist &&
                playlist.territories &&
                generateTerritoryList(playlist.territories)) ||
            "";

        const trackAsinSet = new Set<string>(
            _.map(this.props.tracks, (track) => track.asin)
        );
        // If a song is recently added to a playlist with no streams, we should still display it, with zero streams, per reqs
        const missingNewTracks: trackInfo[] =
            this.props.recentlyAddedToPlaylistData
                .filter(
                    (newTrack) =>
                        newTrack.playlistSeriesAsin ===
                        this.props.match.params.asin
                )
                .filter(
                    (newTrack) =>
                        newTrack.trackTitlesetAsin != null &&
                        !trackAsinSet.has(newTrack.trackTitlesetAsin)
                )
                .map((newTrack) => {
                    return {
                        // newTrack.trackTitlesetAsin will not be null because of the check above
                        asin: newTrack.trackTitlesetAsin || "",
                        delta: 0,
                        streams: 0,
                    };
                });

        const newTracks = getNewTrackAsins(
            this.props.recentlyAddedToPlaylistData,
            playlist?.asin
        );

        const songRows = generateSongRows(
            this.props.tracks.concat(missingNewTracks),
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            this.props.bundleMap,
            testIDPrefix + "_SongTableRows",
            false,
            false,
            false,
            newTracks
        );

        // Top countries props
        const topCountriesHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.countriesTitle,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const topCountries: TableRowProps[] = generateCountryRows(
            this.props.topCountries,
            testIDPrefix + "_TopCountriesRows"
        );

        const topCountriesRows = topCountries.slice(
            0,
            SUMMARY_TABLE_ITEM_COUNT
        );

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {/* <Image
                    blurRadius={rootStyles.blurAmount}
                    style={rootStyles.imageStyles.backgroundImage}
                    source={{ uri: profileHeadProps.imageSource }}
                /> */}
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getPlaylistPerformance}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {this.state.playlistCatalogItem && (
                    <ProfileHead {...profileHeadProps} />
                )}

                <Row>
                    <Col xs={12} md={6}>
                        {/* song streams */}
                        {streamLineGraphProps && (
                            <LineGraph
                                key="songStreams"
                                {...streamLineGraphProps}
                            />
                        )}
                    </Col>
                    <Col xs={12} md={6}>
                        {/* song listeners */}
                        {listenerLineGraphProps && (
                            <LineGraph
                                key="songListeners"
                                {...listenerLineGraphProps}
                            />
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Table
                            loading={this.state.refreshing}
                            key="playlistTracks"
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId:
                                    stringIds.Reports
                                        .playlistStreamDetailsTitle,
                            })}
                            labels={[
                                getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId: stringIds.Reports.songsLabel,
                                }),
                                "",
                                getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId: stringIds.Reports.streamsLabel,
                                }),
                            ]}
                            showIndex={false}
                            loadMore={this.loadMore}
                            rows={songRows}
                            scrolling={false}
                            footer={
                                this.props.tracks.length <=
                                this.state.loadedCount ? (
                                    <div
                                        style={{
                                            height: rootStyles.spacers.huge,
                                        }}
                                    />
                                ) : (
                                    <IncrementalLoading />
                                )
                            }
                            id={testIDPrefix + "_SongTable"}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {/* Top Countries */}
                        {
                            <VerticalSpacedTable
                                rows={topCountriesRows}
                                labels={topCountriesHeader}
                                showIndex={true}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports.topCountriesTitle,
                                    }
                                )}
                                id={testIDPrefix + "_TopCountriesTable"}
                                footer={
                                    <TableSeeAllButton
                                        totalRows={topCountries.length}
                                        id={testIDPrefix + "_seeAllCountries"}
                                        onClick={this.seeAllCountries}
                                    />
                                }
                                emptyMessage={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.EMPTYSTATE_STRINGS,
                                        stringId: stringIds.EmptyState.message,
                                    }
                                )}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        );
    }

    private getPlaylistPerformance = () => {
        const asin = this.props.match.params.asin;

        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId
        ) {
            return;
        }

        if (!asin) {
            return;
        }

        const playlistAsin = asin;

        this.setState({
            loadedCount: HYDRATION_COUNT,
        });

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        const payload: programmingPerformanceRequestPayload = {
            timeRange: this.props.selectedRange,
            selectionSourceId: playlistAsin,
            teamId: this.props.teamId,
            query: {
                artistAsin: this.props.selectedArtist,
                topTracksQueryV2: {
                    selectionSourceId: playlistAsin,
                    playlistId: playlistAsin,
                    ...generateQuery(timeRangePayload, guessTimeZone(), 300),
                },
                topCountriesForEntityStreamsQuery: {
                    ...generateQueryWithoutGranularity(
                        timeRangePayload,
                        guessTimeZone()
                    ),
                    entityType: EntityType.Playlist,
                    entityId: playlistAsin,
                },
                trackStreamsQueryV2: {
                    ...generateQuery(timeRangePayload, guessTimeZone()),
                    selectionSourceId: playlistAsin,
                },
                trackListenersQueryV2: {
                    ...generateQuery(timeRangePayload, guessTimeZone()),
                    selectionSourceId: playlistAsin,
                },
            },
            requestPath: paths.playlistPerformance,
        };

        this.props.getPlaylistPerformance(payload);
    };

    private loadMore = () => {
        if (!this.props.signedIn) {
            return;
        }

        const nextCount: number = hydrationHelpers.hydrateAsinsBatch(
            this.state.loadedCount,
            this.props.tracks,
            this.props.hydrateAsins,
            CatalogItemType.Tracks,
            this.props.userLocale
        );

        this.setState({
            loadedCount: nextCount,
        });
    };

    private seeAllCountries = () => {
        const asin = this.props.match.params.asin;
        if (!asin) {
            return;
        }
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllCountries,
                pageIds.playlists,
                asin,
                EntityType.Playlist
            )
        );
        this.props.history.push(`${paths.playlistCountries}/${asin}`);
    };
}

function mapStateToProps(state: RootState) {
    return playlistPerformanceScreenSelector(state, paths.playlistPerformance);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getPlaylistPerformance: (
            request: programmingPerformanceRequestPayload
        ) => dispatch(reportingActions.getPlaylistPerformance(request)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        selectAsin: (asin: string) =>
            dispatch(reportingActions.setSelectedPlaylistAsin(asin)),
        unselectAsin: (asin: string) =>
            dispatch(reportingActions.removeSelectedPlaylistAsin(asin)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PlaylistPerformanceScreen)
);
