import { createAction } from "redux-ts";
import { artist, artistSearchQueryPayload } from "../../models";

export const artistSearch = createAction<artistSearchQueryPayload>(
    "SEARCH::ARTIST_SEARCH"
);
export const artistSearchInProgress = createAction<boolean>(
    "SEARCH::ARTIST_SEARCH_INPROGRESS"
);
export const artistSearchCompleted = createAction<artist[]>(
    "SEARCH::ARTIST_SEARCH_COMPLETED"
);
export const clearArtistSearch = createAction<string>(
    "SEARCH::CLEAR_ARTIST_SEARCH"
);
