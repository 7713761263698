import { terms_deDE } from "./deDE";
import { terms_enUS } from "./enUS";
import { terms_esES } from "./esES";
import { terms_frFR } from "./frFR";
import { terms_itIT } from "./itIT";
import { terms_jaJP } from "./jaJP";
import { terms_ptBR } from "./ptBR";

export const terms = {
    deDE: terms_deDE,
    enUS: terms_enUS,
    esES: terms_esES,
    frFR: terms_frFR,
    itIT: terms_itIT,
    jaJP: terms_jaJP,
    ptBR: terms_ptBR,
};
