import * as React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import {
    Icon,
    IconsList,
    styledTitle,
    BlurredModal,
    ImageWithFallback,
} from "..";
import * as rootStyles from "../../../styles";
import { MediumSolidButton, MediumOutlineButton } from "../input/Buttons";
import { ImageList } from "../../../../assets";

export type ConfirmationModalProps = {
    id: string;
    imageSource?: string;
    imageStyle?: React.CSSProperties;
    icon?: any;
    text: string;
    description?: string;
    body?: JSX.Element;
    confirmButtonText?: string;
    cancelButtonText?: string;
    cancelButtonStyle?: React.CSSProperties;
    footer?: string;
    confirmButtonAction?: () => void;
    cancelButtonAction?: () => void;
    buttonOrientation?: "stacked" | "inline"; // default inline
    onDismiss?: () => void;
    onModalExitComplete?: () => void;
    isVisible: boolean;
    sticky?: boolean;
};

export class FullScreenConfirmationModal extends React.Component<ConfirmationModalProps> {
    render() {
        const spacer = <div style={spacerStyle} />;

        const ButtonsFlexDirection =
            this.props.buttonOrientation === "stacked"
                ? "column-reverse"
                : "row";

        const ButtonsWidth =
            this.props.buttonOrientation === "stacked" ? "100%" : 160;

        return (
            <BlurredModal
                id="blurred-modal"
                show={this.props.isVisible}
                style={rootStyles.containerStyles.modal}
                minwidth={"256px"}
                maxwidth={"512px"}
                onExited={this.props.onModalExitComplete}
                top="30%"
            >
                <Modal.Body style={modalContainer}>
                    <Row
                        style={cancelButtonStyle}
                        onClick={this.props.onDismiss}
                    >
                        <Icon
                            icon={IconsList.action_cancel}
                            size={rootStyles.spacers.huge}
                            color={rootStyles.colors.primary}
                            id={this.props.id + "_DismissIcon"}
                        />
                    </Row>
                    {this.props.icon && (
                        <Row id={this.props.id + "-Icon"}>
                            <Icon
                                size={48}
                                icon={this.props.icon}
                                id={this.props.id + "_Icon"}
                                iconDescription={this.props.text}
                            />
                            {spacer}
                        </Row>
                    )}
                    {this.props.imageSource && (
                        <ImageWithFallback
                            style={
                                this.props.imageStyle
                                    ? this.props.imageStyle
                                    : rootStyles.imageStyles.roundImage
                            }
                            source={this.props.imageSource}
                            fallback={ImageList.placeholder_artist}
                            imageDescription={this.props.text}
                            id={this.props.id + "_Image"}
                        />
                    )}
                    <Row>
                        <styledTitle.h4
                            id={`${this.props.id}_Text`}
                            style={{
                                lineHeight: 1.0,
                                textAlign: "center",
                            }}
                        >
                            {this.props.text}
                        </styledTitle.h4>
                        {spacer}
                    </Row>
                    {this.props.description && (
                        <Row
                            id={this.props.id + "-Description"}
                            style={{
                                marginBottom: rootStyles.spacers.small,
                            }}
                        >
                            <p
                                style={descriptionStyle}
                                id={`${this.props.id}_Description`}
                            >
                                {this.props.description}
                            </p>
                        </Row>
                    )}
                    {this.props.body && (
                        <Row
                            id={this.props.id + "-Body"}
                            style={{
                                marginBottom: rootStyles.spacers.small,
                            }}
                        >
                            {this.props.body}
                        </Row>
                    )}
                    <Row
                        style={{
                            ...buttonRowContainer,
                            flexDirection: ButtonsFlexDirection,
                        }}
                    >
                        {this.props.cancelButtonText &&
                            this.props.cancelButtonAction && (
                                <Row
                                    id={this.props.id + "-CancelButton"}
                                    style={{
                                        ...buttonContainer,
                                        width: ButtonsWidth,
                                    }}
                                >
                                    <MediumOutlineButton
                                        containerStyle={buttonContainerStyle}
                                        buttonStyle={buttonStyle}
                                        title={this.props.cancelButtonText}
                                        titleStyle={
                                            this.props.cancelButtonStyle
                                        }
                                        onClick={this.props.cancelButtonAction}
                                        id={this.props.id + "_CancelButton"}
                                    />
                                    {spacer}
                                </Row>
                            )}
                        {this.props.buttonOrientation === "stacked" ? null : (
                            <Row style={{ width: rootStyles.spacers.large }} />
                        )}
                        {this.props.confirmButtonText &&
                            this.props.confirmButtonAction && (
                                <Row
                                    id={this.props.id + "-ConfirmButton"}
                                    style={{
                                        ...buttonContainer,
                                        width: ButtonsWidth,
                                    }}
                                >
                                    <MediumSolidButton
                                        containerStyle={buttonContainerStyle}
                                        buttonStyle={buttonStyle}
                                        title={this.props.confirmButtonText}
                                        onClick={this.props.confirmButtonAction}
                                        id={this.props.id + "_ConfirmButton"}
                                    />
                                    {spacer}
                                </Row>
                            )}
                    </Row>
                    <Row>
                        {this.props.footer ? (
                            <Col style={{ textAlign: "center" }}>
                                <span style={rootStyles.textStyles.tertiary}>
                                    {this.props.footer}
                                </span>
                            </Col>
                        ) : null}
                    </Row>
                </Modal.Body>
            </BlurredModal>
        );
    }
}

const modalContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: rootStyles.colors.background,
    display: "flex",
    maxWidth: 448,
};

const buttonRowContainer: React.CSSProperties = {
    alignItems: "center",
    textAlign: "center",
    flexWrap: "nowrap",
    paddingRight: 0,
    width: "100%",
    justifyContent: "center",
};

const buttonContainer: React.CSSProperties = {
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
};

const cancelButtonStyle: React.CSSProperties = {
    flex: 0,
    padding: 4,
    borderRadius: rootStyles.spacers.large,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
    cursor: "pointer",
};

const buttonContainerStyle: React.CSSProperties = {
    width: "100%",
};

const buttonStyle: React.CSSProperties = {
    width: "100%",
};

const descriptionStyle: React.CSSProperties = {
    lineHeight: "20px",
    textAlign: "center",
    ...rootStyles.textStyles.primary,
};
const spacerStyle: React.CSSProperties = {
    width: "100%",
    minHeight: rootStyles.spacers.base,
};
