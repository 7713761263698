import { bundleIds, stringIds } from "@amzn/ziggy-asset";
import * as React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AnyAction } from "redux";
import styled from "styled-components";
import { IconsList } from "../../../assets";
import { BundleMap } from "../../../models";
import { RootState, oAuthActions, userActions } from "../../../store";
import { getLocalizedString, paths } from "../../../utils";
import * as rootStyles from "../../styles";
import ClaimRequesterTypeCard from "./ClaimRequesterTypeCard";

import { getBundleMap } from "../../../store/selectors/commonSelectors";
import { styledTitle } from "../../../view/components";

const testIDPrefix = "ClaimRequesterTypeScreen";

type ViewProps = {};

type StateProps = {
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    setHideIntercomLauncher: (payload: boolean) => void;
    setShowIntercomMessenger: (payload: boolean) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

class ClaimRequesterTypeScreen extends React.Component<Props> {
    constructor(props: any) {
        super(props);
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.setHideIntercomLauncher(false);
    }

    componentWillUnmount() {
        this.props.setHideIntercomLauncher(true);
    }

    render() {
        return (
            <Container style={rootContainerStyle}>
                <styledTitle.h2 style={titleStyle}>
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.CLAIMREQUESTACCESSTYPE_STRINGS,
                        stringId: stringIds.ClaimRequestAccessType.title,
                    })}
                </styledTitle.h2>
                <div style={{ height: rootStyles.spacers.huge }} />
                <CardsContainer>
                    <ClaimRequesterTypeCard
                        Icon={IconsList.ic_microphone_karaoke}
                        text={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.CLAIMREQUESTACCESSTYPE_STRINGS,
                            stringId:
                                stringIds.ClaimRequestAccessType.artistHeadline,
                        })}
                        subText={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.CLAIMREQUESTACCESSTYPE_STRINGS,
                            stringId:
                                stringIds.ClaimRequestAccessType.artistBody,
                        })}
                        id={`${testIDPrefix}_ArtistOrManager`}
                        action={() =>
                            this.props.history.push(paths.artistSearch)
                        }
                    />
                    <div
                        style={{
                            height: rootStyles.spacers.huge,
                            width: rootStyles.spacers.base,
                        }}
                    />
                    <ClaimRequesterTypeCard
                        Icon={IconsList.ic_disk}
                        text={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.CLAIMREQUESTACCESSTYPE_STRINGS,
                            stringId:
                                stringIds.ClaimRequestAccessType.labelHeadline,
                        })}
                        subText={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.CLAIMREQUESTACCESSTYPE_STRINGS,
                            stringId:
                                stringIds.ClaimRequestAccessType.labelBody,
                        })}
                        id={`${testIDPrefix}_VendorOrLabel`}
                        action={() => this.props.history.push(paths.vendorFlow)}
                    />
                </CardsContainer>
                <div style={{ height: rootStyles.spacers.epic }} />
            </Container>
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return {
        bundleMap: getBundleMap(state),
    };
}

const rootContainerStyle: React.CSSProperties = {
    ...rootStyles.containerStyles.rootViewContainer,
    margin: "auto",
    display: "flex",
    paddingTop: "10vh",
    paddingLeft: rootStyles.spacers.base,
    paddingRight: rootStyles.spacers.base,
};

const CardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${rootStyles.breakpoints.xl3}px) {
        flex-direction: row;
    }
`;

const titleStyle: React.CSSProperties = {
    textAlign: "center",
};

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        setHideIntercomLauncher: (payload: boolean) =>
            dispatch(oAuthActions.setHideIntercomLauncher(payload)),
        setShowIntercomMessenger: (payload: boolean) =>
            dispatch(oAuthActions.setShowIntercomMessenger(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ClaimRequesterTypeScreen)
);
