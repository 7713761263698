export enum CookieConsentString {
    ACCEPT = "ACCEPT-ALL",
    REJECT = "REJECT-ALL",
    // Used for customize cookie
    ACCEPT_PARTIAL = "ACCEPT-PARTIAL",
    // Stratus define the response as a string value of "UNKNOWN" to indicate
    // the user hasn't select a choice on cookie consent options
    UNKNOWN = "UNKNOWN",
}

export type cookieModalText = {
    headerText: string;
    legaleseText: string;
    acceptButtonText: string;
    denyButtonText: string;
    customizeButtonText: string;
};
