import * as React from "react";
import { Icon, IconsList } from "../icons";
import * as rootStyles from "../../../styles";
import { Card, Row } from "react-bootstrap";

export type InlineWarningProps = {
    text: string;
    id: string;
};

export class InlineWarning extends React.Component<InlineWarningProps> {
    render() {
        const icon = (
            <Icon
                size={rootStyles.icons.medium}
                icon={IconsList.alerts_warning}
                id={`${this.props.id}_WarningIcon`}
            />
        );

        return (
            <Card style={rootStyles.errorStyles.errorContainer}>
                <Row id={`${this.props.id}-ErrorText`} style={innerErrorStyle}>
                    <div style={iconStyle}>{icon}</div>
                    <span style={textStyle}>{this.props.text}</span>
                </Row>
            </Card>
        );
    }
}

const innerErrorStyle: React.CSSProperties = {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: rootStyles.spacers.medium,
};
const textStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "center",
    ...rootStyles.textStyles.primary,
};
const iconStyle: React.CSSProperties = {
    alignSelf: "center",
    flex: 0,
};
