export const colors = {
    background: "#14191a",
    accent: "#25d1da",
    primary: "#ffffff",
    secondary: "#000000",
    error: "#f43e21",
    aux1: "#FF1EA7",
    aux2: "#4400ff",
    aux3: "#e8e618",
    aux4: "#f43e21",
    aux5: "#50e3c2",
    aux6: "#1A1A1A",
    warning: "#FFDF00",
};

export const glass = {
    _1: 0.05,
    _2: 0.15,
    _3: 0.3,
    _4: 0.6,
    _5: 0.75,
    _6: 0.8,
    _7: 0.9,
    _8: 1,
};

export const generateColor = (c: string, opacity: number) => {
    const color = c.replace("#", "");
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    const result = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
    return result;
};

export const glassColors = {
    primary1: generateColor(colors.primary, glass._1),
    primary2: generateColor(colors.primary, glass._2),
    primary3: generateColor(colors.primary, glass._3),
    primary4: generateColor(colors.primary, glass._4),
    primary5: generateColor(colors.primary, glass._5),
    primary6: generateColor(colors.primary, glass._6),
    primary7: generateColor(colors.primary, glass._7),
    secondary1: generateColor(colors.secondary, glass._1),
    secondary2: generateColor(colors.secondary, glass._2),
    secondary3: generateColor(colors.secondary, glass._3),
    secondary4: generateColor(colors.secondary, glass._4),
    secondary5: generateColor(colors.secondary, glass._5),
    secondary6: generateColor(colors.secondary, glass._6),
    secondary7: generateColor(colors.secondary, glass._7),
    accent1: generateColor(colors.accent, glass._1),
    accent2: generateColor(colors.accent, glass._2),
    accent3: generateColor(colors.accent, glass._3),
    accent4: generateColor(colors.accent, glass._4),
    accent5: generateColor(colors.accent, glass._5),
    warning1: generateColor(colors.warning, glass._1),
    warning2: generateColor(colors.warning, glass._2),
    warning3: generateColor(colors.warning, glass._3),
    warning4: generateColor(colors.warning, glass._4),
    warning5: generateColor(colors.warning, glass._5),
    warning6: generateColor(colors.warning, glass._6),
    warning7: generateColor(colors.warning, glass._7),
};

// computed color codes when transparent colors render on top of other colors
// used when background differs from what is required to create a specific combination
export const combinationColors = {
    primary4onAccent: "#A8EDF0",
};

export const tierColors = {
    hd: "#ffe9a1",
    unlimited: "#4400ff",
    prime: "#00a8e1",
};
