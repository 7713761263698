import _ from "lodash";
import { AnyAction } from "redux";
import {
    merchCollection,
    merchCatalog,
    merchIdentifiers,
    hydratedMerchItem,
} from "../../models";
import { merchHydratorActions } from "../actions";

export type MerchHydratorState = Readonly<{
    merchCatalog: merchCatalog;
    hydrateMerchCompleted: boolean;
    failedIdentifiers: merchIdentifiers[];
    hydratingIdentifiers: merchIdentifiers[];
}>;

export const initialMerchHydratorState: MerchHydratorState = {
    merchCatalog: new Map<string, hydratedMerchItem>(),
    hydrateMerchCompleted: false,
    failedIdentifiers: [],
    hydratingIdentifiers: [],
};

export const merchHydratorReducer = (
    state: MerchHydratorState = initialMerchHydratorState,
    action: AnyAction
) => {
    switch (action.type) {
        case merchHydratorActions.hydrateMerchInProgress.type:
            return {
                ...state,
                hydrateMerchInProgress: action.payload,
            };
        case merchHydratorActions.hydrateMerchCompleted.type:
            const hydratedMerchItems: hydratedMerchItem[] = action.payload;
            const hydratedMerchIds: string[] = hydratedMerchItems.map(
                (i) => i.itemAsin
            );
            const newMerchCatalog = _.cloneDeep(state.merchCatalog);
            _.forEach(hydratedMerchItems, (item) => {
                const itemIdentifier: merchIdentifiers = item.itemAsin;

                newMerchCatalog.set(itemIdentifier, item);
            });
            const updatedHydratingIdentifiers = _.filter(
                state.hydratingIdentifiers,
                (id) => !hydratedMerchIds.includes(id)
            );
            return {
                ...state,
                merchCatalog: newMerchCatalog,
                hydratingIdentifiers: updatedHydratingIdentifiers,
                hydrateMerchCompleted: true,
            };
        case merchHydratorActions.hydrateMerchHydratingIdentifiers.type:
            return {
                ...state,
                hydratingIdentifiers: _.concat(
                    state.hydratingIdentifiers,
                    action.payload
                ),
            };
        case merchHydratorActions.hydrateMerchFailedIdentifiers.type:
            const failedIdentifiers: merchIdentifiers[] = action.payload;
            const newfailedIdentifiers = _.concat(
                state.failedIdentifiers,
                failedIdentifiers
            );
            const newHydratingIdentifiers = _.filter(
                state.hydratingIdentifiers,
                (identifier) => !_.includes(newfailedIdentifiers, identifier)
            );
            return {
                ...state,
                failedIdentifiers: newfailedIdentifiers || [],
                hydratingIdentifiers: newHydratingIdentifiers || [],
            };
        default:
            return state;
    }
};
