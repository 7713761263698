import { TeamType, deepLinkSchema, teamInfo } from "../models";
import { privilegedTeamId } from "../service";

/*
https://quip-amazon.com/qH7lAobKZ1Kj/Deeplinking-Dev-Notes
schema:
/link
/v1
/<artistAsin>?
action=report
 */
export const buildDeepLink = (url: URL): deepLinkSchema => {
    // path: url.pathname = /link/v1/<artistAsin>
    // params: url.search = ?action=reports

    const path = url.pathname;
    const params = url.search;
    const pathArray = path.split("/");

    let deepLink: deepLinkSchema = {
        version: pathArray[2],
    };

    if (pathArray[3]) {
        deepLink = {
            ...deepLink,
            artistAsin: pathArray[3],
        };
    }

    if (params.startsWith("?")) {
        const paramsArray = params.split("?");
        const paramMap: Map<string, string> = getParamMap(paramsArray[1]);

        if (paramMap.has("action")) {
            deepLink = {
                ...deepLink,
                action: paramMap.get("action"),
            };
        }

        if (paramMap.has("entity_type")) {
            deepLink = {
                ...deepLink,
                entityType: paramMap.get("entity_type"),
            };
        }

        if (paramMap.has("entity_asin")) {
            deepLink = {
                ...deepLink,
                entityAsin: paramMap.get("entity_asin"),
            };
        }

        if (paramMap.has("time_start")) {
            deepLink = {
                ...deepLink,
                timeStart: paramMap.get("time_start"),
            };
        }

        if (paramMap.has("time_end")) {
            deepLink = {
                ...deepLink,
                timeEnd: paramMap.get("time_end"),
            };
        }
    }
    return deepLink;
};

function getParamMap(params: string) {
    const paramArray = params.split("&");

    const paramMap = new Map<string, string>();

    paramArray.forEach((param) => {
        const paramPair = param.split("=");
        paramMap.set(paramPair[0], paramPair[1]);
    });

    return paramMap;
}

export const validateArtist = (
    teams: teamInfo[] | undefined,
    artistAsin: string
): string | undefined => {
    let team;
    if (teams !== undefined) {
        teams.forEach((item) => {
            if (item.teamId === privilegedTeamId) {
                team = privilegedTeamId;
            } else if (item.teamType === TeamType.VENDOR) {
                team = TeamType.VENDOR;
            } else if (item.teamType === TeamType.LABEL) {
                team = TeamType.LABEL;
            } else {
                item.artistAsins.forEach((asin) => {
                    if (asin === artistAsin) {
                        team = item.teamId;
                    }
                });
            }
        });
    }
    return team;
};

export const isValidArtistAsin = (asin: string) => {
    // https://w.amazon.com/index.php/ASIN#ASIN_format_.2F_validation
    return /[0-9]{9}[0-9X]|[A-Z][A-Z0-9]{9}$/.test(asin);
};
