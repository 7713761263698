import * as React from "react";
import { Modal } from "react-bootstrap";
import * as rootStyles from "../../styles";
import {
    BlurredModal,
    Icon,
    LargeOutlineButton,
    LargeSolidButton,
    styledTitle,
} from "../../components/common";
import { IconsList, ImageList, bundleIds, stringIds } from "../../../assets";
import { BundleMap } from "../../../models";
import { getLocalizedString } from "../../../utils";

type MarqueePlaylistLearnMoreModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
    bundleMap: BundleMap;
};

const testIDPrefix = "MarqueePlaylistLearnMoreModal";

export class MarqueePlaylistLearnMoreModal extends React.Component<MarqueePlaylistLearnMoreModalProps> {
    render() {
        return (
            <BlurredModal
                id="blurred-modal"
                show={this.props.isVisible}
                centered
                maxwidth={950}
                top="30%"
            >
                <Modal.Body
                    style={{
                        ...rootStyles.containerStyles.modalBody,
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "100%",
                        }}
                    >
                        <div
                            style={iconContainerStyle}
                            onClick={this.props.onDismiss}
                        >
                            <Icon
                                icon={IconsList.action_cancel}
                                size={rootStyles.spacers.huge}
                                color={rootStyles.colors.primary}
                                id={`${testIDPrefix}_DismissIcon`}
                            />
                        </div>

                        <img src={ImageList.playlist_learnMore} />
                        <styledTitle.h4
                            style={{
                                marginTop: rootStyles.spacers.huge,
                            }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PROMO_CARD_STRINGS,
                                stringId:
                                    stringIds.PromoCard.learnMoreModalTitle,
                            })}
                        </styledTitle.h4>
                        <div
                            style={{
                                marginTop: rootStyles.spacers.base,
                                ...rootStyles.textStyles.primary,
                                marginBottom: rootStyles.spacers.large,
                            }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PROMO_CARD_STRINGS,
                                stringId:
                                    stringIds.PromoCard.learnMoreModalText,
                            })}
                        </div>
                        <LargeSolidButton
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PROMO_CARD_STRINGS,
                                stringId:
                                    stringIds.PromoCard
                                        .learnMoreModalDismissText,
                            })}
                            onClick={this.props.onDismiss}
                            id={`${testIDPrefix}_Gotit`}
                        />
                    </div>
                </Modal.Body>
            </BlurredModal>
        );
    }
}

const iconContainerStyle: React.CSSProperties = {
    flex: 0,
    padding: rootStyles.spacers.micro,
    borderRadius: 24,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
    cursor: "pointer",
    marginBottom: rootStyles.spacers.large,
};
