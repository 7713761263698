import { Action } from "redux-ts";
import {
    album,
    albumRelease,
    artist,
    group,
    itemProps,
    track,
} from "../../models";
import { pitchFormActions } from "../actions";

export type PitchFormReducerState = Readonly<{
    selectedAlbumRelease?: albumRelease;
    selectedTrack?: string;
    selectedStep?: number;
    description?: string;
    genresProps: group[];
    compositionsProps: itemProps[];
    songHasVocals?: boolean;
    languagesProps: group[];
    songMoodsProps: itemProps[];
    songStylesProps: itemProps[];
    relatedAritstProps: group[];
    locationProps: group[];
}>;

export const initialPitchFormReducerState = {
    genresProps: [],
    compositionsProps: [],
    languagesProps: [],
    songMoodsProps: [],
    songStylesProps: [],
    relatedAritstProps: [],
    locationProps: [],
};

export const pitchFormReducer = (
    state: PitchFormReducerState = initialPitchFormReducerState,
    action: Action
): PitchFormReducerState => {
    let stateProps;
    switch (action.type) {
        case pitchFormActions.selectAlbumRelease.type:
            // If selecting a different album, clear the state
            if (
                action.payload.asin !== state.selectedAlbumRelease?.titlesetAsin
            ) {
                stateProps = initialPitchFormReducerState;
            } else {
                stateProps = state;
            }
            return {
                ...stateProps,
                selectedAlbumRelease: action.payload,
            };
        case pitchFormActions.selectTrack.type:
            // Id selecting a different track, clear the state and keep albumRelease
            if (action.payload !== state.selectedTrack) {
                stateProps = initialPitchFormReducerState;
            } else {
                stateProps = state;
            }
            return {
                ...stateProps,
                selectedAlbumRelease: state.selectedAlbumRelease,
                selectedTrack: action.payload,
            };
        case pitchFormActions.selectStep.type:
            return { ...state, selectedStep: action.payload };
        case pitchFormActions.setDescription.type:
            return { ...state, description: action.payload };
        case pitchFormActions.setGenresProps.type:
            return {
                ...state,
                genresProps: action.payload,
            };
        case pitchFormActions.setCompositionsProps.type:
            return {
                ...state,
                compositionsProps: action.payload,
            };

        case pitchFormActions.setSongHasVocals.type:
            return {
                ...state,
                songHasVocals: action.payload,
            };
        case pitchFormActions.setLanguagesProps.type:
            return {
                ...state,
                languagesProps: action.payload,
            };
        case pitchFormActions.setSongMoodsProps.type:
            return {
                ...state,
                songMoodsProps: action.payload,
            };
        case pitchFormActions.setSongStylesProps.type:
            return {
                ...state,
                songStylesProps: action.payload,
            };

        case pitchFormActions.setRelatedArtistProps.type:
            return {
                ...state,
                relatedAritstProps: action.payload,
            };
        case pitchFormActions.setLocationProps.type:
            return {
                ...state,
                locationProps: action.payload,
            };
        case pitchFormActions.clearAll.type:
            return initialPitchFormReducerState;
        default:
            return state;
    }
};
