import { colors, glassColors } from "../styles/colors";
import { spacers } from "./styles";
import styled from "styled-components";

export const rootViewContainer: React.CSSProperties = {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "column",
    alignSelf: "flex-end",
    margin: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: colors.background,
};
export const modal: React.CSSProperties = {
    margin: 0,
    padding: 0,
    alignItems: "stretch",
    justifyContent: "flex-end",
    color: colors.background,
};

export const modalBody: React.CSSProperties = {
    flex: 1,
    display: "flex",
};
export const tableContainerStyle: React.CSSProperties = {
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
};
export const navigationContainerStyle: React.CSSProperties = {
    paddingLeft: 0,
    boxShadow: "rgb(0, 0, 0, 0.3) 2px 0px 5px",
    background: `linear-gradient(${colors.secondary}, ${colors.background} )`,
    height: "100%",
    maxWidth: 286,
};
export const blurContainer: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
};

export const timeRangePickerContainer: React.CSSProperties = {
    paddingTop: 7,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
};

export const ShareLinkRowStyle: React.CSSProperties = {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-start",
    paddingBottom: spacers.mini,
    marginBottom: spacers.small,
    borderBottomWidth: "1px",
    borderBottomColor: glassColors.primary4,
    borderBottomStyle: "solid",
};

export const vendorLabelFlowContainerStyle: React.CSSProperties = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: 652,
};
