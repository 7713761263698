import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ImageList } from "../../../assets";
import { BundleMap, baseMediaItem } from "../../../models";
import { getSelectedContentTypeYearText } from "../../../utils";
import { ContentListItem } from "../lists";
import { commonSelectors } from "../../../store/selectors";

export type SearchResultsProps = {
    onChangeElement: (index: number) => void;
    items: baseMediaItem[];
    itemStyle?: any;
    removeIcon?: any;
    id: string;
};

export const SearchResults: React.FC<SearchResultsProps> = ({
    onChangeElement,
    items,
    itemStyle,
    removeIcon,
    id,
}) => {
    const SearchResultPrefix = "-SearchResult";
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const renderItem = (item: baseMediaItem, index: number) => {
        const secondary = getSelectedContentTypeYearText(
            item.type,
            item.year,
            bundleMap
        );
        return (
            <Row style={{ width: "100%" }}>
                <Col>
                    <ContentListItem
                        key={item.asin}
                        primary={item.title}
                        secondary={secondary}
                        image={item.images?.artMedium}
                        fallbackImage={ImageList.placeholder_artist}
                        imageDescription={item.title}
                        squareImage={true}
                        rightIcon={removeIcon}
                        id={`${id}_SearchResult_${index}`}
                    />
                </Col>
            </Row>
        );
    };

    const renderClickableElements = () => {
        const ret = [];

        for (let index = 0; index < items.length; index++) {
            ret.push(
                <Row
                    id={`${id}${SearchResultPrefix}-Row-${index}`}
                    style={{ ...itemStyle, cursor: "pointer", width: "100%" }}
                    onClick={() => onChangeElement(index)}
                >
                    {renderItem(items[index], index)}
                </Row>
            );
        }

        return ret;
    };

    return <>{renderClickableElements()}</>;
};

export default SearchResults;
