import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { RouteComponentProps } from "react-router";
import { Row, Container, Col } from "react-bootstrap";
import styled from "styled-components";

import {
    artist,
    METRIC_KEYS,
    ErrorPayload,
    artistSearchQueryPayload,
    telemetryPayload,
    localStorageStrings,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    artistSearchActions,
    artistClaimActions,
    RootState,
    telemetryActions,
    searchArtistPageSelector,
    userActions,
    oAuthActions,
} from "../../../store";
import { getLocalizedString, paths, testIDSuffixes } from "../../../utils";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import {
    Loading,
    InlineError,
    IconsList,
    Search,
    styledTitle,
    SmallGlassButton,
    GoBackButton,
    ClaimHeaderWithBackButton,
    Icon,
} from "../../components";
import { ContentListItem } from "../../components/lists";
import { HelpModal } from "../../components/common/modals";
import { breakpoints, mobileFontSizes, glassColors } from "../../styles";

const testIDPrefix = "SearchArtistScreen";
const metricPrefix = "searchArtistPage";

const QUERY_DELAY_TIME = 2000;

type ViewProps = {};

type StateProps = {
    artists?: artist[];
    signedIn: boolean;
    inProgress: boolean;
    error?: ErrorPayload;
    acceptInviteInProgress?: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    artistSearch: (payload: artistSearchQueryPayload) => void;
    clearArtistSearch: () => void;
    userAction: (payload: telemetryPayload) => void;
    selectArtist: (payload: artist) => void;
    updateCurrentPath: (payload: string) => void;
    setHideIntercomLauncher: (payload: boolean) => void;
    setShowIntercomMessenger: (payload: boolean) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    text: string;
    showError?: boolean;
    artists: artist[];
    isFirstTimeUser: boolean;
    isSearchCompleted: boolean;
    isMobile: boolean;
    showHelpModal: boolean;
};

class SearchArtistScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        let firstTimeUser;
        if (this.props.location.state) {
            const state = this.props.location.state as {
                firstTimeUser?: boolean;
            };
            firstTimeUser = state.firstTimeUser;
        }

        this.state = {
            text: "",
            artists: [],
            isFirstTimeUser: firstTimeUser || false,
            isSearchCompleted: false,
            isMobile: false,
            showHelpModal: false,
        };
        this.makeSearchQuery = this.makeSearchQuery.bind(this);
        this.makeSearchQuery = _.debounce(
            this.makeSearchQuery,
            QUERY_DELAY_TIME
        );

        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.artistSearch],
            ]),
        });

        this.props.setHideIntercomLauncher(false);

        if (localStorage.getItem(localStorageStrings.inviteId)) {
            this.props.history?.replace(paths.acceptInvite);
            return;
        }

        this.props.clearArtistSearch();
        this.setState({
            showHelpModal: false,
            artists: this.props.artists ? this.props.artists : [],
        });
        window.addEventListener("resize", this.resize.bind(this));
    }

    resize() {
        let currentIsMobile = window.innerWidth <= breakpoints.lg;
        if (currentIsMobile !== this.state.isMobile) {
            this.setState({ isMobile: currentIsMobile });
        }
    }

    componentWillUnmount() {
        this.setState({ text: "" });
        this.props.clearArtistSearch();
        this.props.setHideIntercomLauncher(true);
        window.removeEventListener("resize", this.resize.bind(this));
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (this.props.artists !== prevProps.artists) {
            this.setState({
                artists: this.props.artists ? this.props.artists : [],
            });
            this.setState({
                isSearchCompleted: !(
                    this.props.artists && this.props.artists.length > 0
                )
                    ? true
                    : false,
            });
        }
    }

    render() {
        const loading = <Loading />;
        const mediumSpacer = (
            <div style={{ flex: 1, minHeight: rootStyles.spacers.medium }} />
        );
        const helpModalBody = () => {
            return (
                <Row style={{ width: "100%" }}>
                    <span
                        style={{
                            ...rootStyles.textStyles.secondary,
                            color: rootStyles.colors.primary,
                        }}
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist
                                    .helpModalInstructionOneA,
                        }) + " "}
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist.helpModalAmazonMusic,
                        })}
                        {" " +
                            getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                                stringId:
                                    stringIds.SearchForArtist
                                        .helpModalInstructionOneB,
                            })}
                        {mediumSpacer}
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist
                                    .helpModalInstructionTwo,
                        })}
                        {mediumSpacer}
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist
                                    .helpModalInstructionThree,
                        })}
                    </span>
                </Row>
            );
        };

        const helpModalFooter = () => {
            return (
                <Row style={{ width: "100%" }}>
                    <span style={rootStyles.textStyles.tertiary}>
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                            stringId:
                                stringIds.ArtistDisambiguation
                                    .claimScreenHelpTextA,
                        }) + " "}
                        <span
                            style={clickableUnderlinedTextStyle}
                            onClick={() =>
                                this.props.history.push(
                                    paths.reportArtistProfile
                                )
                            }
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                stringId:
                                    stringIds.ArtistDisambiguation
                                        .claimScreenHelpTextB,
                            })}
                        </span>
                    </span>
                </Row>
            );
        };

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <ClaimHeaderWithBackButton />
                <GoBackButton
                    style={{ alignSelf: "flex-start" }}
                    onClick={() => this.props.history.push(paths.requesterType)}
                    id={testIDPrefix}
                />
                <Row
                    style={{
                        alignItems: "center",
                        marginBottom: rootStyles.spacers.huge,
                    }}
                >
                    {this.state.isMobile ? (
                        <styledTitle.h1
                            style={{ textAlign: "center", width: "100%" }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.CLAIMANARTIST_STRINGS,
                                stringId: stringIds.ClaimAnArtist.title,
                            })}
                        </styledTitle.h1>
                    ) : (
                        <styledTitle.h2
                            style={{ textAlign: "center", width: "100%" }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.CLAIMANARTIST_STRINGS,
                                stringId: stringIds.ClaimAnArtist.title,
                            })}
                        </styledTitle.h2>
                    )}
                </Row>
                <div
                    style={{
                        ...helpSectionStyle,
                        marginBottom: rootStyles.spacers.small,
                    }}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId:
                            stringIds.SearchForArtist
                                .helpModalSearchArtistAdvice,
                    })}

                    <Icon
                        onClick={this.onHelpIconClicked}
                        icon={IconsList.ic_help}
                        size={rootStyles.icons.tiny}
                        id={`${testIDPrefix}_HelpIcon`}
                        style={{ marginLeft: rootStyles.spacers.small }}
                    />
                </div>
                <Row
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Col>
                        <Search
                            value={this.state.text}
                            placeholder={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                                    stringId: stringIds.SearchForArtist.search,
                                }
                            )}
                            onChange={this.updateText}
                            textInputStyle={{
                                color: rootStyles.colors.secondary,
                            }}
                            containerStyle={{
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                            dark={false}
                            hasMaxWidth={true}
                            id={testIDPrefix + testIDSuffixes.searchbar}
                        />
                    </Col>
                </Row>
                <Row style={{ alignItems: "center" }}>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.artistSearchError,
                            })}
                            onDismiss={this.dismissError}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {!this.props.inProgress &&
                    !(this.state.artists && this.state.artists.length > 0) && (
                        <ValuePropsRow>
                            <span>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.CLAIMANARTIST_STRINGS,
                                    stringId: stringIds.ClaimAnArtist.getAccess,
                                })}
                            </span>
                            <span>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.CLAIMANARTIST_STRINGS,
                                    stringId:
                                        stringIds.ClaimAnArtist.claimYours,
                                })}
                            </span>
                            <SmileImg src={ImageList.smile} />
                        </ValuePropsRow>
                    )}
                {this.state.artists?.length > 0 ? (
                    <Row
                        style={{
                            width: 480,
                            marginLeft: "auto",
                            marginRight: "auto",
                            padding: 0,
                        }}
                    >
                        <div
                            style={{
                                width: 492,
                                overflowY: "auto",
                                maxHeight: "40vh",
                            }}
                        >
                            <Col style={searchResults}>
                                {(this.props.inProgress || this.state.showError
                                    ? []
                                    : this.state.artists
                                ).map((artist: artist) =>
                                    this.renderItem(artist)
                                )}
                            </Col>
                        </div>
                    </Row>
                ) : this.props.inProgress && !this.state.showError ? (
                    loading
                ) : null}
                <HelpModal
                    isVisible={this.state.showHelpModal}
                    onDismiss={this.dismissModal}
                    accept={this.dismissModal}
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId: stringIds.SearchForArtist.helpModalTitle,
                    })}
                    body={helpModalBody}
                    acceptbuttonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId: stringIds.SearchForArtist.helpModalGotIt,
                    })}
                    footer={helpModalFooter}
                    id={`${testIDPrefix}_SearchForArtistHelpModal`}
                />
            </Container>
        );
    }

    private renderItem = (item: artist) => {
        return (
            <ContentListItem
                key={item.asin}
                primary={item.title}
                image={item?.images?.artMedium}
                fallbackImage={ImageList.placeholder_artist}
                onClick={() => this.onArtistSelected(item)}
                imageDescription={item.title}
                id={testIDPrefix + testIDSuffixes.item + "_" + item.asin}
            />
        );
    };

    private updateText = (text: any) => {
        this.setState(
            { text: text.target.value ? text.target.value : "" },
            this.onTextChanged
        );
    };

    private onTextChanged = () => {
        if (!this.props.signedIn) {
            return;
        }
        this.makeSearchQuery();
    };

    private makeSearchQuery() {
        if (!this.props.signedIn) {
            return;
        }

        this.props.userAction({
            name: metricPrefix + "QueryEntered",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.artistSearch],
            ]),
        });

        const payload: artistSearchQueryPayload = {
            query: this.state.text,
            requestPath: paths.artistSearch,
            musicTerritory: "US",
        };

        this.props.artistSearch(payload);
    }

    private onArtistSelected = (artist: artist) => {
        if (!this.state.artists) {
            return;
        }
        this.props.userAction({
            name: metricPrefix + "ResultSelected",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.artistSearch],
                [
                    METRIC_KEYS.listIndex,
                    this.state.artists.indexOf(artist).toString(),
                ],
            ]),
        });
        this.setState({ text: "" });
        this.props.clearArtistSearch();
        this.props.selectArtist(artist);
        this.props.history.push(paths.claim);
    };

    private dismissError = () => {
        this.setState({ showError: false });
    };

    private onHelpIconClicked = () => {
        this.setState({ showHelpModal: true });
    };

    private dismissModal = () => {
        this.setState({ showHelpModal: false });
    };
}

const ValuePropsRow = styled(Row)`
    color: ${glassColors.primary4};
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: ${rootStyles.spacers.huge}px;
    font-family: AmazonEmber;
    font-size: ${mobileFontSizes.h4}px;
    display: flex;
    flex-direction: column;
`;

const SmileImg = styled.img`
    margin: ${rootStyles.spacers.huge}px;
    width: 40%;
    @media (min-width: ${breakpoints.lg}px) {
        width: 20%;
    }
`;

const helpIconStyle: React.CSSProperties = {
    padding: rootStyles.spacers.nano,
    height: rootStyles.icons.medium,
};

const helpSectionStyle: React.CSSProperties = {
    color: rootStyles.colors.primary,
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    marginLeft: "10px",
    fontFamily: "AmazonEmber",
    fontSize: mobileFontSizes.t1,
    flexDirection: "row",
    display: "flex",
};

const searchResults: React.CSSProperties = {
    flex: 1,
    width: "100%",
    marginTop: 10,
    padding: rootStyles.spacers.micro,
    paddingRight: 12,
    backgroundColor: rootStyles.glassColors.primary2,
    borderRadius: 24,
    maxWidth: 480, // matches the max width in the search bar
};

const clickableUnderlinedTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    color: rootStyles.colors.accent,
    marginTop: 2,
    textDecorationLine: "underline",
    cursor: "pointer",
};

function mapStateToProps(state: RootState): StateProps {
    return searchArtistPageSelector(state, paths.artistSearch);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        artistSearch: (payload: artistSearchQueryPayload) =>
            dispatch(artistSearchActions.artistSearch(payload)),
        clearArtistSearch: () =>
            dispatch(artistSearchActions.clearArtistSearch()),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        selectArtist: (payload: artist) =>
            dispatch(artistClaimActions.selectArtistToClaim(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        setHideIntercomLauncher: (payload: boolean) =>
            dispatch(oAuthActions.setHideIntercomLauncher(payload)),
        setShowIntercomMessenger: (payload: boolean) =>
            dispatch(oAuthActions.setShowIntercomMessenger(payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchArtistScreen);
