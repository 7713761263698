import _ from "lodash";
import React, { useRef } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { AnyAction } from "redux";
import {
    MobileAnnouncement,
    CommonHeader,
    FormNavigation,
    FormNavigationStep,
    ImageWithFallback,
    ImageWithFallbackProps,
    SearchSelect,
} from "../../components";
import * as rootStyles from "../../styles";
import { buttonIds, pageIds, stringIds } from "@amzn/ziggy-asset";
import {
    RootState,
    catalogActions,
    clientMetricsActions,
    contentSearchActions,
    globalNotificationsActions,
    opsMetricsActions,
    promoCardActions,
    promoCardSelector,
    reportingActions,
    userActions,
} from "../../../store";
import {
    APP_STORE_URL,
    LARGE_SCREEN_WIDTH_THRESHOLD,
    MOBILE_SCREEN_MAX_WIDTH,
    PLAY_STORE_URL,
    PromoCardConstants,
    PromoCardStep,
    buildUIClickPayload,
    buildUIPageViewPayload,
    createErrorOpsMetricsPayload,
    createSuccessOpsMetricsPayload,
    formatDate,
    generateAlbumItems,
    generateTrackItems,
    getArtistMarqueePlaylists,
    getLocalizedString,
    paths,
    playlistRow,
} from "../../../utils";
import { ImageList, bundleIds } from "../../../assets";
import { SearchCardContent } from "./SearchCardContent";
import { CustomizeCard } from "./CustomizeCard";
import { ShareCardOptions } from "./ShareCardOptions";
import {
    artist,
    EntityType,
    BundleMap,
    baseMediaItem,
    trackAlbumSearchQueryPayload,
    ContentType,
    getMarqueePlaylistsPayload,
    CatalogProps,
    CardLayout,
    albumInfo,
    trackInfo,
    generatePromoCardShareLinkPayload,
    ModalRenderFunction,
    GlobalModal,
    promoCardSelectedContent,
    ArtistMarqueePlaylists,
    clientMetricsPayload,
    reportingOverviewRequestPayload,
    TimeRange,
    BatchOpsMetricsPayload,
} from "../../../models";
import { CardGenerator } from "./CardGenerator";
import html2canvas from "html2canvas";
import { MarqueePlaylistLearnMoreModal } from "./MarqueePlaylistLearnMoreModal";
import styled from "styled-components";
import { PlaylistSearchBar } from "./PlaylistSearchBar";
import { isIOS } from "react-device-detect";

const promoCardSteps: FormNavigationStep[] = [
    // Using existing string as placeholder for now, will update the string once we have UX defined for this
    {
        stringId: stringIds.PromoCard.selectContent,
        bundleId: bundleIds.PROMO_CARD_STRINGS,
        enabled: true,
    },
    {
        stringId: stringIds.PromoCard.customizeCard,
        bundleId: bundleIds.PROMO_CARD_STRINGS,
        enabled: true,
    },
    {
        stringId: stringIds.PromoCard.shareCard,
        bundleId: bundleIds.PROMO_CARD_STRINGS,
        enabled: true,
    },
];

const pagePath = paths.promoCard;

const testIDPrefix = "PromoCardScreen_";

const PrepopulateContentImage = styled((props: ImageWithFallbackProps) => (
    <ImageWithFallback {...props} />
))`
    height: 82px;
    width: 82px;
    background-color: ${(props) =>
        !!props.source ? `${rootStyles.colors.primary}` : "default"};
    @media (min-width: 992px) {
        &:hover {
            opacity: ${rootStyles.glass._3};
        }
    }
`;

type ViewProps = {};

type StateProps = CatalogProps & {
    teamId: string;
    selectedArtistAsin?: string;
    artist: artist;
    selectedStep: number | undefined;
    selectedEntity: EntityType;
    bundleMap: BundleMap;
    trackAlbumSearchInProgress: boolean;
    trackAlbums?: baseMediaItem[];
    searchText: string;
    marqueePlaylists: ArtistMarqueePlaylists;
    selectedCardLayout: CardLayout;
    selectedCardLocale: string;
    topTracks: trackInfo[];
    topAlbums: albumInfo[];
    selectedTimeRange: TimeRange;
    promocardSharelinkRepsonse: string;
};

type State = {
    stepIndex: number | undefined;
    jumpToStep?: number;
    selectedContent?: promoCardSelectedContent;
    cardContainerRef: React.RefObject<HTMLInputElement>;
    cardAssetRef: React.RefObject<HTMLInputElement>;
    cardRef: React.RefObject<HTMLInputElement>;
    trackAlbums: baseMediaItem[];
    searchResultsInProgress: boolean;
    searchText: string;
    prepopulationInProgress: boolean;
    selectedPrepopulateTrackIndex: number | undefined;
    selectedPrepopulateAlbumIndex: number | undefined;
    downloadCardInProgress: boolean;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    selectStep: (payload: number) => void;
    trackAlbumSearch: (payload: trackAlbumSearchQueryPayload) => void;
    clearTrackAlbumSearch: () => void;
    getMarqueePlaylists: (payload: getMarqueePlaylistsPayload) => void;
    generatePromoCardShareLink: (
        payload: generatePromoCardShareLinkPayload
    ) => void;
    clearPromoCardShareLink: () => void;
    requestModal: (payload: ModalRenderFunction) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    getReports: (payload: reportingOverviewRequestPayload) => void;
    batchMetric: (payload: BatchOpsMetricsPayload) => void;
    selectEntity: (payload: EntityType) => void;
};

type RedirectState = {
    stepIndex: number | undefined;
    selectedContent: promoCardSelectedContent;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

class PromoCardScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            stepIndex:
                (this.props.location.state as RedirectState)?.stepIndex || 0,
            selectedContent:
                (this.props.location.state as RedirectState)?.selectedContent ||
                undefined,
            cardContainerRef: React.createRef(),
            cardAssetRef: React.createRef(),
            cardRef: React.createRef(),
            trackAlbums: [],
            searchResultsInProgress: false,
            searchText: "",
            downloadCardInProgress: false,
            prepopulationInProgress: false,
            selectedPrepopulateTrackIndex: undefined,
            selectedPrepopulateAlbumIndex: undefined,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.makeTrackAlbumSearchQuery =
            this.makeTrackAlbumSearchQuery.bind(this);
        this.makeTrackAlbumSearchQuery = _.debounce(
            this.makeTrackAlbumSearchQuery,
            2000
        );
        this.props.clearTrackAlbumSearch();
        this.getReports();
        this.getPlaylists();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            this.props.trackAlbumSearchInProgress === false &&
            prevProps.trackAlbumSearchInProgress === true &&
            this.state.prepopulationInProgress === true
        ) {
            this.setState({ prepopulationInProgress: false });
        }

        if (this.props.trackAlbums !== prevProps.trackAlbums) {
            this.setState({
                trackAlbums: this.props.trackAlbums
                    ? this.props.trackAlbums
                    : [],
            });
        }

        // Upon new content selection on a different content type
        // Clear the selected index for prepoluated content for track / album
        if (
            this.state.selectedContent?.type !== prevState.selectedContent?.type
        ) {
            if (prevState.selectedContent?.type === EntityType.Track) {
                this.setState({ selectedPrepopulateTrackIndex: undefined });
            }
            if (prevState.selectedContent?.type === EntityType.Album) {
                this.setState({ selectedPrepopulateAlbumIndex: undefined });
            }
        }

        if (
            this.state.jumpToStep !== undefined &&
            this.state.jumpToStep !== prevState.jumpToStep
        ) {
            this.props.selectStep(this.state.jumpToStep);
        }

        if (this.props.selectedStep !== prevProps.selectedStep) {
            this.setState({ jumpToStep: this.props.selectedStep });
        }

        if (
            this.props.selectedEntity === EntityType.ARTIST &&
            this.props.selectedEntity !== prevProps.selectedEntity
        ) {
            this.setState({ selectedContent: undefined });
        }

        if (
            this.state.stepIndex === PromoCardStep.SHARE &&
            prevState.stepIndex !== PromoCardStep.SHARE
        ) {
            this.getPromoCardShareLink();
        }

        if (
            this.state.stepIndex !== PromoCardStep.SHARE &&
            prevState.stepIndex === PromoCardStep.SHARE
        ) {
            this.props.clearPromoCardShareLink();
        }

        if (
            this.props.selectedEntity !== prevProps.selectedEntity ||
            (this.state.stepIndex !== PromoCardStep.SELECT &&
                prevState.stepIndex === PromoCardStep.SELECT)
        ) {
            this.setState({ trackAlbums: [] });
        }
    }

    render() {
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                }}
            >
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROMO_CARD_STRINGS,
                        stringId: stringIds.PromoCard.pageTitle,
                    })}
                    subtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.NAVIGATION_STRINGS,
                        stringId: stringIds.Navigation.artistToolsTitle,
                    })}
                    subsubtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PROMO_CARD_STRINGS,
                        stringId: stringIds.PromoCard.title,
                    })}
                    id={`${testIDPrefix}_Header`}
                    hideDatePicker={true}
                />

                {window.innerWidth >= MOBILE_SCREEN_MAX_WIDTH
                    ? this.promoCardForm()
                    : this.showMobileMessage()}
            </Container>
        );
    }

    private promoCardForm() {
        // ratio of the card previewer over the parent flex box
        const cardPreviewRatio =
            this.props.selectedCardLayout === CardLayout.Portrait ? 0.6 : 0.8;
        const scaleRatio = this.state.cardContainerRef.current
            ? (this.state.cardContainerRef.current.offsetWidth /
                  (this.props.selectedCardLayout === CardLayout.Landscape
                      ? PromoCardConstants.LANDSCAPE_WIDTH
                      : PromoCardConstants.PORTRAIT_WIDTH)) *
              cardPreviewRatio
            : 1;
        switch (this.state.stepIndex) {
            case PromoCardStep.SELECT:
                return (
                    <>
                        {this.FormNavigation()}
                        <div style={this.promoCardContainerstyle}>
                            <div style={this.editContainerStyle}>
                                {
                                    <SearchCardContent
                                        trackSearchBar={this.SearchTrackOrAlbum(
                                            ContentType.Track
                                        )}
                                        albumSearchBar={this.SearchTrackOrAlbum(
                                            ContentType.Album
                                        )}
                                        playlistSearchBar={
                                            <PlaylistSearchBar
                                                playlistRows={this.generatePlaylist()}
                                                selectPlaylist={
                                                    this.onSelectPlaylist
                                                }
                                                onClickLearnMore={() =>
                                                    this.props.requestModal(
                                                        this.showMarqueePlaylistLearnMoreModal()
                                                    )
                                                }
                                            ></PlaylistSearchBar>
                                        }
                                        bundleMap={this.props.bundleMap}
                                    />
                                }
                            </div>
                            <div
                                style={this.previewCardContainerStyle}
                                ref={this.state.cardContainerRef}
                            >
                                <div
                                    style={{
                                        overflow: "hidden",
                                        height:
                                            scaleRatio *
                                            (this.props.selectedCardLayout ===
                                            CardLayout.Portrait
                                                ? PromoCardConstants.PORTRAIT_HEIGHT
                                                : PromoCardConstants.LANDSCAPE_HEIGHT),
                                    }}
                                >
                                    <div
                                        style={{
                                            transform: `scale(${scaleRatio})`,
                                            transformOrigin: "top center",
                                        }}
                                    >
                                        {this.CardPreviewer()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case PromoCardStep.CUSTOMIZE:
                return (
                    <>
                        {this.FormNavigation()}
                        <div style={this.promoCardContainerstyle}>
                            <div style={this.editContainerStyle}>
                                <CustomizeCard
                                    bundleMap={this.props.bundleMap}
                                />
                            </div>
                            <div
                                style={this.previewCardContainerStyle}
                                ref={this.state.cardContainerRef}
                            >
                                <div
                                    style={{
                                        overflow: "hidden",
                                        height:
                                            scaleRatio *
                                            PromoCardConstants.PORTRAIT_HEIGHT,
                                    }}
                                >
                                    <div
                                        style={{
                                            transform: `scale(${scaleRatio})`,
                                            transformOrigin: "top center",
                                        }}
                                    >
                                        {this.CardPreviewer()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case PromoCardStep.SHARE:
                return (
                    <>
                        {this.FormNavigation()}
                        <div style={this.promoCardContainerstyle}>
                            <div style={this.editContainerStyle}>
                                <ShareCardOptions
                                    promocardShareLink={
                                        this.props.promocardSharelinkRepsonse
                                    }
                                    onClickDownload={this.handleDownloadClick}
                                    downloadInProgress={
                                        this.state.downloadCardInProgress
                                    }
                                    bundleMap={this.props.bundleMap}
                                    selectedContent={this.state.selectedContent}
                                    onDismissNonShareableContentError={
                                        this.onDismissNonShareableContentError
                                    }
                                />
                            </div>
                            <div
                                style={this.previewCardContainerStyle}
                                ref={this.state.cardContainerRef}
                            >
                                <div
                                    style={{
                                        overflow: "hidden",
                                        height:
                                            scaleRatio *
                                            (this.props.selectedCardLayout ===
                                            CardLayout.Portrait
                                                ? PromoCardConstants.PORTRAIT_HEIGHT
                                                : PromoCardConstants.LANDSCAPE_HEIGHT),
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            zIndex: -999,
                                        }}
                                    >
                                        {this.getOriginalCard()}
                                    </div>
                                    <div
                                        style={{
                                            transform: `scale(${scaleRatio})`,
                                            transformOrigin: "top center",
                                        }}
                                    >
                                        {this.CardPreviewer()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            default:
                return <></>;
        }
    }

    private FormNavigation = () => {
        return (
            <Row>
                <Col style={{ marginTop: rootStyles.spacers.mini }}>
                    <FormNavigation
                        stepList={promoCardSteps}
                        onChange={(index) =>
                            this.setState({
                                stepIndex: index,
                                jumpToStep: index,
                            })
                        }
                        defaultValue={this.state.stepIndex}
                        id={`${testIDPrefix}_formNavigation`}
                        jumpToStep={this.state.jumpToStep}
                    />
                </Col>
            </Row>
        );
    };

    private showMobileMessage = () => {
        return (
            <Row>
                <Col>
                    <MobileAnnouncement>
                        <div>
                            <span
                                style={
                                    rootStyles.textStyles.primaryClickableText
                                }
                            >
                                <a
                                    href={
                                        isIOS ? APP_STORE_URL : PLAY_STORE_URL
                                    }
                                    style={
                                        rootStyles.textStyles
                                            .primaryClickableText
                                    }
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId: bundleIds.PROMO_CARD_STRINGS,
                                        stringId:
                                            stringIds.PromoCard.downloadApp,
                                    })}
                                </a>
                            </span>
                            <span style={rootStyles.textStyles.primary}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                                    stringId:
                                        stringIds.PromoCard.downloadAppSuffix,
                                })}
                            </span>
                        </div>
                    </MobileAnnouncement>
                </Col>
            </Row>
        );
    };

    private getReports = () => {
        if (this.props.artist.asin) {
            const payload: reportingOverviewRequestPayload = {
                timeRange: this.props.selectedTimeRange,
                teamId: this.props.teamId,
                artistAsin: this.props.artist.asin,
                requestPath: pagePath,
            };
            this.props.getReports(payload);
        }
    };

    private CardPreviewer = () => {
        const originalCard = this.getOriginalCard();
        const cardCopy = React.cloneElement(originalCard, {
            // Removing the ref from the original card asset when cloning
            ref: null,
        });
        return <div style={this.promoCardContainerstyle}>{cardCopy}</div>;
    };

    private getOriginalCard = () => {
        return (
            <div ref={this.state.cardAssetRef}>
                <CardGenerator
                    artistName={this.props.artist.title!}
                    artistImage={this.props.artist.images?.artExtraLarge}
                    selectedContent={this.state.selectedContent}
                    marqueePlaylists={this.props.marqueePlaylists}
                    bundleMap={this.props.bundleMap}
                ></CardGenerator>
            </div>
        );
    };

    private SearchTrackOrAlbum = (type: ContentType) => {
        return (
            <div>
                <div>
                    <SearchSelect
                        onSelectElement={this.onTrackAlbumSelect}
                        inProgress={this.props.trackAlbumSearchInProgress}
                        displayResults={true}
                        onTextChange={this.onSearchRemainContentTextChanged}
                        items={this.state.trackAlbums
                            .filter((v) => v.type === type)
                            .sort((a, b) => {
                                if (!a.localOriginalReleaseDate) {
                                    return 1;
                                }
                                if (!b.localOriginalReleaseDate) {
                                    return -1;
                                }
                                const dateA = new Date(
                                    a.localOriginalReleaseDate
                                );
                                const dateB = new Date(
                                    b.localOriginalReleaseDate
                                );
                                return dateB.getTime() - dateA.getTime();
                            })}
                        showAddIcon={false}
                        squareImage={true}
                        showReleaseDate={true}
                        placeholderText={this.getSearchPlaceHolderText(type)}
                        id={`search${type}`}
                    />
                </div>

                <div
                    style={{
                        marginTop: rootStyles.spacers.small,
                        height: 350,
                        overflow: "auto",
                    }}
                >
                    {this.getPrepopulateContent(type)}
                </div>
            </div>
        );
    };

    private getPrepopulateContent = (type: ContentType) => {
        const contentRows: baseMediaItem[] =
            type === ContentType.Track
                ? generateTrackItems(this.props.topTracks, this.props.catalog)
                : generateAlbumItems(this.props.topAlbums, this.props.catalog);
        return contentRows.map(this.renderPrepopulateContent);
    };

    private renderPrepopulateContent = (
        item: baseMediaItem,
        index: number,
        contentRows: baseMediaItem[]
    ) => {
        const selectedIndex =
            item.type === EntityType.Album
                ? this.state.selectedPrepopulateAlbumIndex
                : this.state.selectedPrepopulateTrackIndex;
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: rootStyles.spacers.small,
                    backgroundColor:
                        selectedIndex === index
                            ? rootStyles.glassColors.primary2
                            : "transparent",
                    borderRadius: selectedIndex === index ? "7px" : "0px",
                }}
                onClick={() => {
                    this.setState({
                        selectedContent: {
                            title: item.title,
                            type: item.type,
                            images: item.images,
                            asin: item.asin,
                        },
                    });
                    item.type === EntityType.Album
                        ? this.setState({
                              selectedPrepopulateAlbumIndex: index,
                          })
                        : this.setState({
                              selectedPrepopulateTrackIndex: index,
                          });
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            position: "relative",
                            justifyContent: "start",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                    >
                        <PrepopulateContentImage
                            source={contentRows[index]?.images?.artLarge || ""}
                            fallback={
                                item.type === EntityType.Album
                                    ? ImageList.placeholder_album
                                    : ImageList.placeholder_track
                            }
                            id={`${testIDPrefix}-${index}-Promo${item.type}Image`}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        justifyContent: "center",
                        alignItems: "start",
                        cursor: "pointer",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            justifyContent: "center",
                            alignItems: "start",
                            cursor: "pointer",
                            marginLeft: rootStyles.spacers.medium,
                            marginRight: rootStyles.spacers.medium,
                        }}
                    >
                        <div style={rootStyles.textStyles.primary}>
                            {`${contentRows[index].title}`}
                        </div>
                        <div style={rootStyles.textStyles.tertiary}></div>
                        <div
                            style={{
                                ...rootStyles.textStyles.secondary,
                                marginTop: rootStyles.spacers.micro,
                            }}
                        >
                            {getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                                    stringId: stringIds.PromoCard.dateReleased,
                                },
                                {
                                    "0": formatDate(
                                        new Date(
                                            contentRows[index]
                                                .localOriginalReleaseDate || ""
                                        )
                                    ),
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private handleDownloadClick = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.ShareMenu.shareOnDownload,
                pageIds.promoCard
            )
        );
        this.props.batchMetric(
            createSuccessOpsMetricsPayload(
                `HypeCard-LayoutSelected-${this.props.selectedCardLayout}`
            )
        );
        this.props.batchMetric(
            createSuccessOpsMetricsPayload(
                `HypeCard-LanguageSelected-${this.props.selectedCardLocale}`
            )
        );
        this.props.batchMetric(
            createSuccessOpsMetricsPayload(
                `HypeCard-ContentSelected-${this.props.selectedEntity}`
            )
        );
        this.setState({ downloadCardInProgress: true });
        if (this.state.cardAssetRef.current) {
            html2canvas(this.state.cardAssetRef.current, {
                allowTaint: true,
                useCORS: true,
                scale: 1,
            })
                .then((canvas) => {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = canvas.toDataURL();
                    var pngUrl = canvas.toDataURL(); // PNG is the default
                    downloadLink.download = "card.png";
                    downloadLink.click();
                    this.setState({ downloadCardInProgress: false });

                    this.props.batchMetric(
                        createSuccessOpsMetricsPayload(
                            "HypeCard-TransformPreviewToImage"
                        )
                    );
                })
                .catch((error) => {
                    this.props.batchMetric(
                        createErrorOpsMetricsPayload(
                            "HypeCard-TransformPreviewToImage",
                            JSON.stringify(error)
                        )
                    );
                });
        }
    };

    private onDismissNonShareableContentError = () => {
        this.props.selectStep(PromoCardStep.SELECT);
        this.props.selectEntity(EntityType.ARTIST);
    };

    private generatePlaylist() {
        let result: playlistRow[] = [];
        if (this.props.catalog && this.props.marqueePlaylists) {
            result = getArtistMarqueePlaylists(
                this.props.marqueePlaylists,
                this.props.catalog
            );
        }
        return result;
    }

    private onSelectPlaylist = (selectedContent: promoCardSelectedContent) => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.HypeCard.selectContent,
                pageIds.promoCard,
                selectedContent.asin,
                EntityType.Playlist
            )
        );
        this.setState({
            selectedContent: selectedContent,
        });
    };

    private onSearchRemainContentTextChanged = (text: string) => {
        this.makeTrackAlbumSearchQuery(text);
    };

    private makeTrackAlbumSearchQuery(trackAlbumSearch: string) {
        this.trackAlbumSearchQuery(trackAlbumSearch);
    }

    private trackAlbumSearchQuery(trackAlbumSearch: string) {
        const payload: trackAlbumSearchQueryPayload = {
            query: trackAlbumSearch,
            requestPath: pagePath,
            artistAsin: this.props.selectedArtistAsin,
        };
        this.props.trackAlbumSearch(payload);
    }

    private onTrackAlbumSelect = (trackAlbum: baseMediaItem) => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.HypeCard.selectContent,
                pageIds.promoCard,
                trackAlbum.asin,
                trackAlbum.type == EntityType.Track
                    ? EntityType.Track
                    : EntityType.Album
            )
        );

        this.setState({
            selectedContent: trackAlbum,
            selectedPrepopulateAlbumIndex: undefined,
            selectedPrepopulateTrackIndex: undefined,
        });
        this.props.clearTrackAlbumSearch();
    };

    private getSearchPlaceHolderText = (type: ContentType) => {
        switch (type) {
            case ContentType.Album:
                return getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.searchAlbumText,
                });
            case ContentType.Track:
                return getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.searchTrackText,
                });
            default:
                return "";
        }
    };

    private getPlaylists = () => {
        if (this.props.artist.asin) {
            const payload: getMarqueePlaylistsPayload = {
                artistAsin: this.props.artist.asin,
                teamId: this.props.teamId,
                requestPath: pagePath,
            };
            this.props.getMarqueePlaylists(payload);
        }
    };

    private getPromoCardShareLink = () => {
        if (this.props.artist.asin) {
            let payload: generatePromoCardShareLinkPayload;
            switch (this.state.selectedContent?.type) {
                case EntityType.ARTIST:
                    payload = {
                        artistAsin: this.props.artist.asin,
                        language: this.props.selectedCardLocale,
                        contentType: EntityType.ARTIST,
                        landscapeType: this.props.selectedCardLayout,
                        teamId: this.props.teamId,
                        contentAsin: this.state.selectedContent?.asin,
                    };
                    break;
                case EntityType.Playlist:
                    payload = {
                        artistAsin: this.props.artist.asin,
                        language: this.props.selectedCardLocale,
                        contentType: EntityType.Playlist,
                        landscapeType: this.props.selectedCardLayout,
                        teamId: this.props.teamId,
                        contentAsin: this.state.selectedContent?.asin,
                        contentContainerAsin:
                            this.state.selectedContent?.contentContainerAsin,
                    };
                    break;
                case EntityType.Track:
                    payload = {
                        artistAsin: this.props.artist.asin,
                        language: this.props.selectedCardLocale,
                        contentType: EntityType.Track,
                        landscapeType: this.props.selectedCardLayout,
                        teamId: this.props.teamId,
                        contentAsin:
                            this.state.selectedContent?.titlesetAsin ||
                            this.state.selectedContent?.asin,
                    };
                    break;
                case EntityType.Album:
                    payload = {
                        artistAsin: this.props.artist.asin,
                        language: this.props.selectedCardLocale,
                        contentType: EntityType.Album,
                        landscapeType: this.props.selectedCardLayout,
                        teamId: this.props.teamId,
                        contentAsin:
                            this.state.selectedContent?.titlesetAsin ||
                            this.state.selectedContent?.asin,
                    };
                    break;
                default:
                    payload = {
                        artistAsin: this.props.artist.asin,
                        language: this.props.selectedCardLocale,
                        contentType: EntityType.ARTIST,
                        landscapeType: this.props.selectedCardLayout,
                        teamId: this.props.teamId,
                        contentAsin: this.state.selectedContent?.asin,
                    };
            }
            this.props.generatePromoCardShareLink(payload);
        }
    };

    private showMarqueePlaylistLearnMoreModal =
        () =>
        (dismiss: () => void, isVisible: boolean): GlobalModal => {
            return (
                <MarqueePlaylistLearnMoreModal
                    onDismiss={dismiss}
                    isVisible={isVisible}
                    bundleMap={this.props.bundleMap}
                />
            );
        };

    promoCardContainerstyle: React.CSSProperties = {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "center",
    };

    editContainerStyle: React.CSSProperties = {
        backgroundColor: rootStyles.glassColors.primary1,
        borderRadius: 16,
        display: "flex",
        flex: 1,
        marginTop: rootStyles.spacers.mini,
    };

    previewCardContainerStyle: React.CSSProperties = {
        display: "flex",
        flex: 1,
        alignContent: "flex-start",
        overflow: "hidden",
        marginTop: rootStyles.spacers.mini,
    };

    previewCardWrapperStyle: React.CSSProperties = {
        display: "flex",
        flex: 1,
        alignContent: "flex-start",
        overflow: "hidden",
        marginTop: rootStyles.spacers.mini,
    };
}

function mapStateToProps(state: RootState): StateProps {
    return {
        ...promoCardSelector(state),
        bundleMap: state.localization.bundleMap,
    };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        getReports: (payload: reportingOverviewRequestPayload) =>
            dispatch(reportingActions.getReportsOverview(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        selectStep: (payload: number) =>
            dispatch(promoCardActions.selectStep(payload)),
        trackAlbumSearch: (payload: trackAlbumSearchQueryPayload) =>
            dispatch(contentSearchActions.trackAlbumSearch(payload)),
        clearTrackAlbumSearch: () =>
            dispatch(contentSearchActions.clearTrackAlbumSearch()),
        getMarqueePlaylists: (payload: getMarqueePlaylistsPayload) =>
            dispatch(catalogActions.getMarqueePlaylists(payload)),
        generatePromoCardShareLink: (
            payload: generatePromoCardShareLinkPayload
        ) => dispatch(catalogActions.generatePromoCardShareLink(payload)),
        clearPromoCardShareLink: () =>
            dispatch(catalogActions.clearPromoCardShareLink()),
        requestModal: (payload: ModalRenderFunction) =>
            dispatch(globalNotificationsActions.requestModalDisplay(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        batchMetric: (payload: BatchOpsMetricsPayload) =>
            dispatch(opsMetricsActions.batchMetric(payload)),
        selectEntity: (payload: EntityType) =>
            dispatch(promoCardActions.selectEntity(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PromoCardScreen)
);
