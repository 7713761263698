import * as React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Dispatch, AnyAction } from "redux";
import { Container, Row, Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import {} from "@amzn/arb-tools";
import {
    Table,
    Loading,
    IncrementalLoading,
    InlineError,
} from "../../components";
import {
    RequiredReportingProps,
    RequiredReportingDispatchProps,
    RequiredReportingState,
    METRIC_KEYS,
    TableRowProps,
    hydrateCatalogPayload,
    telemetryPayload,
    recentlyAddedToPlaylistPayload,
    recentlyAddedToPlaylistTrack,
    CatalogItemType,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    RootState,
    telemetryActions,
    catalogActions,
    userActions,
    newAddsToPlaylistsScreenSelector,
} from "../../../store";
import {
    getLocalizedString,
    paths,
    HYDRATION_COUNT,
    getUniqueNewPlaylists,
    generateNewAddsToPlaylistsRows,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import styled from "styled-components";

const testIDPrefix = "NewAddsToPlaylistsScreen";
const metricPrefix = "NewAddsToPlaylistsPage";

type ViewProps = {};

type StateProps = RequiredReportingProps & {
    bundleMap: BundleMap;
};

type DispatchProps = RequiredReportingDispatchProps & {
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    updateCurrentPath: (payload: string) => void;
    getRecentlyAddedToPlaylist: (
        payload: recentlyAddedToPlaylistPayload
    ) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    loadedCount: number;
};

// Displays a list of recenlty added playlists. Entry point is "See All" on recently added playlists table
class NewAddsToPlaylistsScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            loadedCount: 0,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();
        this.getReports();

        this.loadMore(
            getUniqueNewPlaylists(this.props.recentlyAddedToPlaylistData)
        );

        this.props.userAction({
            name: "newAddsToPlaylistsPageView",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist],
                [METRIC_KEYS.timeRange, this.props.selectedRange],
                [METRIC_KEYS.page, paths.newAddsToPlaylists],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.state.loadedReports &&
            this.props.catalogBuildCompleted &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            this.setState({
                showError:
                    this.props.error !== undefined &&
                    this.props.error.requestPath === paths.newAddsToPlaylists,
            });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
            this.loadMore(
                getUniqueNewPlaylists(this.props.recentlyAddedToPlaylistData)
            );
        }
    }

    render() {
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const uniquePlaylists = getUniqueNewPlaylists(
            this.props.recentlyAddedToPlaylistData
        );

        const newAddsToPlaylistsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.playlistsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.dateAdded,
            }),
        ];
        const newAddsToPlaylists: TableRowProps[] =
            generateNewAddsToPlaylistsRows(
                uniquePlaylists.slice(0, this.state.loadedCount),
                this.props.catalog,
                this.props.hydratingAsins,
                this.props.history?.push,
                testIDPrefix + "_NewAddsToPlaylistsRows",
                this.props.bundleMap
            );

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    ...rootStyles.containerStyles.tableContainerStyle,
                }}
            >
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                <CommonHeaderContainer>
                    <Col>
                        <Table
                            loading={this.state.refreshing}
                            loadMore={() => this.loadMore(uniquePlaylists)}
                            rows={newAddsToPlaylists}
                            labels={newAddsToPlaylistsHeader}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId:
                                    stringIds.Reports.newAddsToPlaylistsTitle,
                            })}
                            secondaryText={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId:
                                        stringIds.Reports
                                            .newAddsToPlaylistsSecondary,
                                }
                            )}
                            id={testIDPrefix + "_NewAddsToPlaylistsTable"}
                            footer={
                                uniquePlaylists.length <=
                                this.state.loadedCount ? (
                                    <div
                                        style={{
                                            height: rootStyles.spacers.huge,
                                        }}
                                    />
                                ) : (
                                    <IncrementalLoading />
                                )
                            }
                        />
                    </Col>
                </CommonHeaderContainer>
            </Container>
        );
    }

    private loadMore = (uniquePlaylists: recentlyAddedToPlaylistTrack[]) => {
        if (!this.props.signedIn) {
            return;
        }

        const currentCount = this.state.loadedCount;

        if (currentCount >= uniquePlaylists.length) {
            return;
        }

        const nextCount = currentCount + HYDRATION_COUNT;
        const nextBatch = uniquePlaylists.slice(currentCount, nextCount);

        this.props.hydrateAsins({
            asins: _.map(nextBatch, (info) => info.playlistSeriesAsin),
            type: CatalogItemType.Playlists,
            locale: this.props.userLocale,
        });

        this.setState({
            loadedCount: nextCount,
        });
    };

    private getReports = (isRefresh?: boolean) => {
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId
        ) {
            return;
        }

        this.props.getRecentlyAddedToPlaylist({
            artistAsin: this.props.selectedArtist,
            teamId: this.props.teamId,
            requestPath: paths.newAddsToPlaylists,
        });
    };
}

const CommonHeaderContainer = styled(Row)`
    margin-top: ${rootStyles.spacers.huge}px;

    @media (max-width: 992px) {
        margin-top: 80px;
    }
`;

function mapStateToProps(state: RootState) {
    return newAddsToPlaylistsScreenSelector(state, paths.newAddsToPlaylists);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getRecentlyAddedToPlaylist: (payload: recentlyAddedToPlaylistPayload) =>
            dispatch(catalogActions.getRecentlyAddedToPlaylists(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewAddsToPlaylistsScreen);
