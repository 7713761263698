import { getBundleMap } from "./../commonSelectors";
import { createSelector } from "reselect";
import _ from "lodash";
import {
    getUserLocale,
    selectedArtistFromCatalogSelector,
} from "../commonSelectors";
import { getSelectedTeamSelector } from "../teamManagementSelectors";
import {
    getCurateMerchInProgress,
    getMerchCollection,
    getMerchSearchInProgressSelector,
    getMerchSearchResultsSelector,
    showModSelector,
} from "./merchCurateCommonSelectors";

export const manualSearchScreenSelector = createSelector(
    [
        getMerchSearchInProgressSelector,
        getMerchSearchResultsSelector,
        getCurateMerchInProgress,
        selectedArtistFromCatalogSelector,
        getSelectedTeamSelector,
        getUserLocale,
        getMerchCollection,
        showModSelector,
        getBundleMap,
    ],
    (
        searchInProgress,
        manualSearchResultItems,
        curateInProgress,
        artist,
        teamInfo,
        locale,
        collection,
        showMod,
        bundleMap
    ) => {
        return {
            searchInProgress: searchInProgress,
            searchResultItems: manualSearchResultItems,
            curateInProgress,
            artist: artist,
            teamId: teamInfo.teamId,
            locale: locale,
            collection: collection,
            showMod,
            bundleMap: bundleMap,
        };
    }
);
