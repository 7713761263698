import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row } from "react-bootstrap";
import { AnyAction } from "redux";
import {} from "@amzn/arb-tools";
import { BundleMap, updateSettingsPayload } from "../../../models";
import {
    getLocalizedString,
    paths,
    booleanToOpt,
    testIDSuffixes,
    getWebLocale,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import {
    LargeSolidButton,
    Checklist,
    styledTitle,
    CheckItemData,
} from "../../components";
import * as rootStyles from "../../../view/styles";
import { colors, spacers } from "../../../view/styles";
import { userActions, RootState } from "../../../store";
import { getBundleMap } from "../../../store/selectors/commonSelectors";

const testIDPrefix = "FirstLoginScreen";

const TERMS_CHECKBOX_INDEX = 0;
const MARKETING_CHECKBOX_INDEX = 1;
const BETA_CHECKBOX_INDEX = 2;

type ViewProps = {};

type StateProps = {
    updateSettingsInProgress?: boolean;
    userLocaleSetting: string;
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateSettings: (payload: updateSettingsPayload) => void;
    updateCurrentPath: (payload: string) => void;
};

type State = {
    termsBoxChecked: boolean;
    marketingEmailBoxChecked: boolean;
    betaEmailBoxChecked: boolean;
    userLocaleSetting: string;
};

type Props = StateProps & DispatchProps & RouteComponentProps<ViewProps>;

class FirstLoginScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            termsBoxChecked: false,
            marketingEmailBoxChecked: false,
            betaEmailBoxChecked: false,
            userLocaleSetting: getWebLocale(),
        };

        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.updateSettingsInProgress &&
            !this.props.updateSettingsInProgress
        ) {
            this.props.history.push(paths.home);
        }
    }

    render() {
        const constructedTermsView = (
            <div style={{ flex: 1 }}>
                <span style={textStyle}>
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.TERMS_STRINGS,
                        stringId: stringIds.Terms.acceptTermsText,
                    })}
                </span>
                <span
                    style={clickableTextStyle}
                    onClick={() =>
                        window.open(paths.termsAndConditions, "_blank")
                    }
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.TERMS_STRINGS,
                        stringId: stringIds.Terms.termsClickableText,
                    })}
                </span>
            </div>
        );

        const constructedMarketingEmails = (
            <span style={textStyle}>
                {getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.FIRSTLOGIN_STRINGS,
                    stringId: stringIds.FirstLogin.marketingEmailText,
                })}
            </span>
        );

        const constructedBetaEmails = (
            <span style={textStyle}>
                {getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.FIRSTLOGIN_STRINGS,
                    stringId: stringIds.FirstLogin.betaEmailText,
                })}
            </span>
        );

        const checkboxLabels: CheckItemData[] = [
            {
                label: constructedTermsView,
                accessibilityLabel: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.TERMS_STRINGS,
                    stringId: stringIds.Terms.accessibilityLabel,
                }),
            },
            {
                label: constructedMarketingEmails,
                accessibilityLabel: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.FIRSTLOGIN_STRINGS,
                    stringId: stringIds.FirstLogin.marketingEmailText,
                }),
            },
            {
                label: constructedBetaEmails,
                accessibilityLabel: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.FIRSTLOGIN_STRINGS,
                    stringId: stringIds.FirstLogin.betaEmailText,
                }),
            },
        ];

        return (
            <Container
                fluid={true}
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    maxWidth: 500,
                    margin: "auto",
                    paddingTop: "10vh",
                }}
            >
                <Row
                    style={{
                        justifyContent: "center",
                        marginBottom: rootStyles.spacers.medium,
                    }}
                >
                    <styledTitle.h2>
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.FIRSTLOGIN_STRINGS,
                            stringId: stringIds.FirstLogin.headerText,
                        })}
                    </styledTitle.h2>
                </Row>
                <Row>
                    <Checklist
                        style={checklistStyle}
                        elements={checkboxLabels}
                        onChangeState={this.onCheckboxChange}
                        hideBorder={true}
                        id={testIDPrefix + "_FirstLoginChecklist"}
                    />
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <LargeSolidButton
                        onClick={this.onPressContinue}
                        containerStyle={continueButton}
                        title={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.TERMS_STRINGS,
                            stringId: stringIds.Terms.continue,
                        })}
                        disabled={!this.state.termsBoxChecked}
                        id={testIDPrefix + "_Continue" + testIDSuffixes.button}
                    />
                </Row>
            </Container>
        );
    }

    private onPressContinue = () => {
        this.props.updateSettings({
            settings: {
                marketingEmailSetting: booleanToOpt(
                    this.state.marketingEmailBoxChecked
                ),
                userLocaleSetting: this.state.userLocaleSetting,
                betaEmailSetting: booleanToOpt(this.state.betaEmailBoxChecked),
                acceptedTermsSetting: this.state.termsBoxChecked,
            },
            requestPath: paths.oobe,
        });
    };

    private onCheckboxChange = (indices: number[]) => {
        this.setState({
            termsBoxChecked: indices.includes(TERMS_CHECKBOX_INDEX),
            marketingEmailBoxChecked: indices.includes(
                MARKETING_CHECKBOX_INDEX
            ),
            betaEmailBoxChecked: indices.includes(BETA_CHECKBOX_INDEX),
        });
    };
}

function mapStateToProps(state: RootState): StateProps {
    return {
        updateSettingsInProgress: state.user.updateSettingsInProgress,
        userLocaleSetting: state.user.locale,
        bundleMap: getBundleMap(state),
    };
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateSettings: (settings: updateSettingsPayload) =>
            dispatch(userActions.updateSettings(settings)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
    };
}

const continueButton: React.CSSProperties = {
    alignSelf: "stretch",
    marginTop: spacers.large,
};
const clickableTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    color: colors.accent,
};
const checklistStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "stretch",
};
const textStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    textTransform: "none",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
    paddingTop: rootStyles.spacers.mini,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FirstLoginScreen)
);
