import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import {} from "@amzn/arb-tools";
import * as rootStyles from "../../styles";
import {
    VerticalSpacedTable,
    SmallGlassButton,
    Loading,
    InlineError,
    CommonHeader,
    DownloadButton,
} from "../../components";
import {
    RequiredReportingProps,
    RequiredReportingDispatchProps,
    RequiredReportingState,
    METRIC_KEYS,
    TableRowProps,
    programmingInfo,
    TimeRange,
    reportingOverviewRequestPayload,
    telemetryPayload,
    recentlyAddedToPlaylistPayload,
    createCsvFileRequestPayload,
    EntityType,
    clientMetricsPayload,
    BundleMap,
} from "../../../models";
import {
    getLocalizedString,
    generatePlaylistRows,
    generateStationRows,
    paths,
    SUMMARY_TABLE_ITEM_COUNT,
    getReportsHelper,
    getUniqueNewPlaylists,
    generateNewAddsToPlaylistsRows,
    ADD_TO_PLAYLIST_TABLE_ITEM_COUNT,
    buildUIClickPayload,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import {
    RootState,
    reportingActions,
    programmingScreenSelector,
    telemetryActions,
    catalogActions,
    userActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import { CsvButtonId, csvButtonLoadingStatusData } from "../../../export";

const testIDPrefix = "ProgrammingScreen";
const metricPrefix = "programmingPage";

type ViewProps = {};

type StateProps = RequiredReportingProps & {
    playlists: programmingInfo[];
    stations: programmingInfo[];
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = RequiredReportingDispatchProps & {
    getReports: (payload: reportingOverviewRequestPayload) => void;
    updateCurrentPath: (payload: string) => void;
    getRecentlyAddedToPlaylist: (
        payload: recentlyAddedToPlaylistPayload
    ) => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {};

class ProgrammingScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();
        this.getReports();
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist],
                [METRIC_KEYS.timeRange, this.props.selectedRange],
                [METRIC_KEYS.page, paths.programming],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports();
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.state.loadedReports &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            if (
                this.props.error &&
                this.props.error.requestPath === paths.programming
            ) {
                this.setState({ showError: true });
            } else {
                this.setState({ showError: false });
            }
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
        }
    }

    render() {
        // Loading state
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const uniquePlaylists = getUniqueNewPlaylists(
            this.props.recentlyAddedToPlaylistData
        );

        const newAddsToPlaylistsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.playlistsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.dateAdded,
            }),
        ];
        const newAddsToPlaylists: TableRowProps[] =
            generateNewAddsToPlaylistsRows(
                uniquePlaylists.slice(0, ADD_TO_PLAYLIST_TABLE_ITEM_COUNT),
                this.props.catalog,
                this.props.hydratingAsins,
                this.props.history?.push,
                testIDPrefix + "_NewAddsToPlaylistsRows",
                this.props.bundleMap
            );

        const featuredPlaylistsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.playlistsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const featuredPlaylists: TableRowProps[] = generatePlaylistRows(
            this.props.playlists.slice(0, SUMMARY_TABLE_ITEM_COUNT),
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            testIDPrefix + "_FeaturedPlaylistsRows",
            this.props.bundleMap
        );

        const featuredStationsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.stationsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const featuredStations: TableRowProps[] = generateStationRows(
            this.props.stations.slice(0, SUMMARY_TABLE_ITEM_COUNT),
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            testIDPrefix + "_FeaturedStationsRows",
            this.props.bundleMap
        );

        const csvBasePayload = {
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: paths.programming,
        };

        const playlistCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "featuredInPlaylists",
            csvButtonId: CsvButtonId.ProgrammingFeaturedPlaylists,
            playlistData: this.props.playlists,
        };

        const stationCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "featuredInStations",
            csvButtonId: CsvButtonId.ProgrammingFeaturedStations,
            stationData: this.props.stations,
        };
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {/* {this.props.artist &&
                    <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: this.props.artist?.images?.artSmall }}
                    />
                } */}
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.programmingTitle,
                    })}
                    subtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.programmingSubtitle,
                    })}
                    id={`${testIDPrefix}_Header`}
                />
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {newAddsToPlaylists && newAddsToPlaylists.length > 0 && (
                    <Row
                        style={{
                            backgroundColor: rootStyles.glassColors.secondary3,
                            marginTop: rootStyles.spacers.huge,
                        }}
                    >
                        <Col>
                            <VerticalSpacedTable
                                rows={newAddsToPlaylists}
                                labels={newAddsToPlaylistsHeader}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports
                                                .newAddsToPlaylistsTitle,
                                    }
                                )}
                                secondaryText={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports
                                                .newAddsToPlaylistsSecondary,
                                    }
                                )}
                                id={testIDPrefix + "_NewAddsToPlaylists"}
                                footer={
                                    this.props.recentlyAddedToPlaylistData
                                        .length > SUMMARY_TABLE_ITEM_COUNT ? (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={
                                                this.seeAllNewAddsToPlaylists
                                            }
                                            id={
                                                testIDPrefix +
                                                "_SeeAllNewAddsToPlaylists"
                                            }
                                        />
                                    ) : null
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <VerticalSpacedTable
                            rows={featuredPlaylists}
                            labels={featuredPlaylistsHeader}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId:
                                    stringIds.Reports.featuredPlaylistsTitle,
                            })}
                            id={testIDPrefix + "_FeaturedPlaylistsTable"}
                            footer={
                                <Row style={{ alignItems: "center" }}>
                                    {this.props.hasCsvExportCapabilities &&
                                        this.props.playlists.length && (
                                            <Col>
                                                <DownloadButton
                                                    loading={
                                                        !!this.props
                                                            .csvButtonLoadingStatus[
                                                            "Programming-FeaturedPlaylists"
                                                        ]
                                                    }
                                                    size={
                                                        rootStyles.ButtonSize
                                                            .small
                                                    }
                                                    id={
                                                        "CsvButton-Programming-FeaturedPlaylists"
                                                    }
                                                    tooltipMessage={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.CSV_STRINGS,
                                                            stringId:
                                                                stringIds.Csv
                                                                    .TooltipMessage
                                                                    .FeaturedInPlaylists,
                                                        }
                                                    )}
                                                    onClick={() => {
                                                        this.props.sendClientMetrics(
                                                            buildUIClickPayload(
                                                                buttonIds
                                                                    .CsvExport
                                                                    .downloadPlaylists,
                                                                pageIds.programming,
                                                                this.props
                                                                    .selectedArtist,
                                                                EntityType.ARTIST
                                                            )
                                                        );
                                                        this.props.getCsvFile(
                                                            playlistCsvPayload
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                    {this.props.playlists.length >
                                    SUMMARY_TABLE_ITEM_COUNT ? (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={this.seeAllPlayLists}
                                            id={
                                                testIDPrefix +
                                                "_SeeAllPlaylists"
                                            }
                                        />
                                    ) : null}
                                </Row>
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <VerticalSpacedTable
                            rows={featuredStations}
                            labels={featuredStationsHeader}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId:
                                    stringIds.Reports.featuredStationsTitle,
                            })}
                            id={testIDPrefix + "_FeaturedStationsTable"}
                            footer={
                                <Row style={{ alignItems: "center" }}>
                                    {this.props.hasCsvExportCapabilities &&
                                        this.props.stations.length && (
                                            <Col>
                                                <DownloadButton
                                                    loading={
                                                        !!this.props
                                                            .csvButtonLoadingStatus[
                                                            "Programming-FeaturedStations"
                                                        ]
                                                    }
                                                    size={
                                                        rootStyles.ButtonSize
                                                            .small
                                                    }
                                                    id={
                                                        "CsvButton-Programming-FeaturedStations"
                                                    }
                                                    tooltipMessage={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.CSV_STRINGS,
                                                            stringId:
                                                                stringIds.Csv
                                                                    .TooltipMessage
                                                                    .FeaturedInStations,
                                                        }
                                                    )}
                                                    onClick={() => {
                                                        this.props.sendClientMetrics(
                                                            buildUIClickPayload(
                                                                buttonIds
                                                                    .CsvExport
                                                                    .downloadStations,
                                                                pageIds.programming,
                                                                this.props
                                                                    .selectedArtist,
                                                                EntityType.ARTIST
                                                            )
                                                        );
                                                        this.props.getCsvFile(
                                                            stationCsvPayload
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                    {this.props.stations.length >
                                    SUMMARY_TABLE_ITEM_COUNT ? (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={this.seeAllStations}
                                            id={
                                                testIDPrefix + "_SeeAllStations"
                                            }
                                        />
                                    ) : null}
                                </Row>
                            }
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

    private seeAllNewAddsToPlaylists = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllNewAddsToPlaylists,
                pageIds.programming,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );
        this.props.history?.push(paths.newAddsToPlaylists);
    };

    private seeAllPlayLists = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllPlaylists,
                pageIds.programming,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );
        this.props.history?.push(paths.playlists);
    };

    private seeAllStations = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllStations,
                pageIds.programming,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );
        this.props.history?.push(paths.stations);
    };

    private getReports = () => {
        getReportsHelper(
            this.props.getReports,
            this.props.getRecentlyAddedToPlaylist,
            this.props.selectedRange,
            paths.programming,
            this.props.selectedArtist,
            this.props.teamId,
            this.props.startDate,
            this.props.endDate
        );
    };
}

function mapStateToProps(state: RootState) {
    return programmingScreenSelector(state, paths.programming);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReports: (payload: reportingOverviewRequestPayload) =>
            dispatch(reportingActions.getProgrammingReports(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        getRecentlyAddedToPlaylist: (payload: recentlyAddedToPlaylistPayload) =>
            dispatch(catalogActions.getRecentlyAddedToPlaylists(payload)),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProgrammingScreen)
);
