import { combineReducers } from "redux";
import { Action } from "redux-ts";
import {
    ArtistIssueState,
    artistIssueReducer as artistIssue,
    ArtistSearchState,
    artistSearchReducer as artistSearch,
    ReportingState,
    reportingReducer as reporting,
    UserState,
    userReducer as user,
    CatalogState,
    catalogReducer as catalog,
    TeamState,
    teamReducer as team,
    ErrorState,
    errorReducer as error,
    ImageUploadState,
    imageUploadReducer as imageUpload,
    OutageStatusState,
    outageStatusReducer as outageStatus,
    ContentSearchState,
    contentSearchReducer as contentSearch,
    FeatureAccessState,
    featureAccessReducer as featureAccess,
    PitchFormReducerState,
    pitchFormReducer as pitchForm,
    PitchState,
    pitchReducer as pitch,
    PromoCardState,
    promoCardReducer as promoCard,
    merchCurateReducer as merchCurate,
    MerchCurateState,
    merchHydratorReducer as merchHydrator,
    MerchHydratorState,
    globalNotificationsReducer as globalNotifications,
    GlobalNotificationsState,
    LocalizationState,
    localizationReducer as localization,
    oAuthState,
    oAuthReducer as oAuth,
    opsMetricsReducer as opsMetrics,
    OpsMetricsState,
} from ".";
import { storeActions } from "../actions";

export type RootState = Readonly<{
    artistIssue: ArtistIssueState;
    imageUpload: ImageUploadState;
    artistSearch: ArtistSearchState;
    reporting: ReportingState;
    user: UserState;
    catalog: CatalogState;
    team: TeamState;
    error: ErrorState;
    outageStatus: OutageStatusState;
    contentSearch: ContentSearchState;
    featureAccess: FeatureAccessState;
    pitchForm: PitchFormReducerState;
    pitch: PitchState;
    promoCard: PromoCardState;
    merchCurate: MerchCurateState;
    merchHydrator: MerchHydratorState;
    globalNotifications: GlobalNotificationsState;
    localization: LocalizationState;
    oAuth: oAuthState;
    opsMetrics: OpsMetricsState;
}>;

// Combine all of the reducers here
const appReducers = combineReducers({
    artistIssue,
    imageUpload,
    artistSearch,
    reporting,
    user,
    catalog,
    team,
    error,
    outageStatus,
    contentSearch,
    featureAccess,
    pitchForm,
    pitch,
    promoCard,
    merchCurate,
    merchHydrator,
    globalNotifications,
    localization,
    oAuth,
    opsMetrics,
});

const rootReducer = (state: any, action: Action) => {
    if (action.type === storeActions.clearStore.type) {
        return appReducers(undefined, action);
    }

    return appReducers(state, action);
};

export default rootReducer;
