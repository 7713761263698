import * as React from "react";
import { AnyAction, Dispatch } from "redux";
import { connect } from "react-redux";
import * as rootStyles from "../../styles";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import {
    getLocalizedString,
    paths,
    LEARN_MORE_ABOUT_CLAIMS_URL,
    onArtistSelected,
    SelectArtistProps,
} from "../../../utils";
import {
    ImageWithFallback,
    styledTitle,
    LargeSolidButton,
    Loading,
    IconsList,
    Icon,
    SmallOutlineButton,
    LargeOutlineButton,
    GoBackButton,
    ClaimHeaderWithBackButton,
} from "../../components";
import {
    RootState,
    telemetryActions,
    userActions,
    artistClaimActions,
    teamManagementActions,
    oAuthActions,
} from "../../../store";
import {
    artist,
    checkTeamExistsPayload,
    METRIC_KEYS,
    teamInfo,
    telemetryPayload,
    selectArtistPayload,
    checkPendingClaimPayload,
    BundleMap,
} from "../../../models";
import { dropDownStyles } from "../../styles/buttonStyles";
import styled from "styled-components";
import { getBundleMap } from "../../../store/selectors/commonSelectors";

const testIDPrefix = "ClaimThisArtistScreen";
const metricPrefix = "ClaimThisArtistPage";

type ViewProps = {};

type StateProps = {
    teams?: teamInfo[];
    selectedArtist?: artist;
    checkTeamExistsInProgress: boolean;
    checkTeamExistsResponse: boolean;
    isPrivileged?: boolean;
    isPending?: boolean;
    claimPendingCheckInProgress: boolean;
    bundleMap: BundleMap;
};

type State = {
    artistTeamInfo?: teamInfo;
};

type DispatchProps = {
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
    clearSelectedArtistToClaim: () => void;
    checkTeamExists: (payload: checkTeamExistsPayload) => void;
    artistSelect: (payload: selectArtistPayload) => void;
    checkPendingClaim: (payload: checkPendingClaimPayload) => void;
    setHideIntercomLauncher: (payload: boolean) => void;
    setShowIntercomMessenger: (payload: boolean) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

class ClaimThisArtistScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.props.updateCurrentPath(window.location.pathname);
        this.state = {
            artistTeamInfo: this.artistTeam(this.props.selectedArtist),
        };
    }
    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.claim],
            ]),
        });
        if (!this.props.selectedArtist) {
            this.props.history.push(paths.artistSearch);
        }

        if (!this.state.artistTeamInfo) {
            if (this.props?.selectedArtist?.asin) {
                const payload: checkTeamExistsPayload = {
                    artistAsin: this.props?.selectedArtist?.asin,
                    requestPath: paths.checkTeamExists,
                };

                this.props.checkTeamExists(payload);
                this.props.checkPendingClaim({
                    artistAsin: this.props?.selectedArtist?.asin,
                    requestPath: paths.claim,
                });
            }
        }

        this.props.setHideIntercomLauncher(false);
    }

    componentWillUnmount() {
        this.props.setHideIntercomLauncher(true);
    }

    render() {
        const isLoading =
            this.props.checkTeamExistsInProgress ||
            this.props.claimPendingCheckInProgress;
        const alreadyHasAccess = this.state.artistTeamInfo;
        const isPending = !alreadyHasAccess && this.props.isPending === true;
        const teamExists =
            !alreadyHasAccess && this.props.checkTeamExistsResponse;
        const teamDoesNotExist =
            !alreadyHasAccess && !this.props.checkTeamExistsResponse;

        const artistName = this.props.selectedArtist?.title;
        const artistImage = this.props.selectedArtist?.images?.artLarge;

        let display;

        if (isLoading) {
            display = (
                <Row style={centerAlign}>
                    <Loading />
                </Row>
            );
        } else {
            const hasAcessView = (
                <p style={infoText} id={`${testIDPrefix}_InfoText`}>
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                        stringId: stringIds.ClaimThisArtist.alreadyHasAccess,
                    })}
                </p>
            );

            const teamDoesNotExistView = (
                <p style={infoText} id={`${testIDPrefix}_InfoText`}>
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                        stringId: stringIds.ClaimThisArtist.verify,
                    })}
                </p>
            );

            const teamExistsView = (
                <p
                    style={{ ...infoText, maxWidth: 400 }}
                    id={`${testIDPrefix}_InfoText`}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                        stringId: stringIds.ClaimThisArtist.teamExists,
                    })}
                </p>
            );

            const isPendingView = this.getClaimPendingView();

            display = (
                <div>
                    <Row style={{ ...centerAlign, marginBottom: 20 }}>
                        {isPending && isPendingView}
                        {alreadyHasAccess && hasAcessView}
                        {teamDoesNotExist && !isPending && teamDoesNotExistView}
                        {teamExists && teamExistsView}
                    </Row>
                    <Row style={centerAlign}>
                        <Col style={{ textAlign: "center", width: "100%" }}>
                            {alreadyHasAccess && (
                                <LargeSolidButton
                                    containerStyle={buttonStyle}
                                    onClick={this.artistSelected}
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.CLAIMTHISARTIST_STRINGS,
                                            stringId:
                                                stringIds.ClaimThisArtist
                                                    .artistSelect,
                                        }
                                    )}
                                    id={`${testIDPrefix}_ArtistSelect`}
                                />
                            )}
                            {teamDoesNotExist && !isPending && (
                                <LargeSolidButton
                                    containerStyle={buttonStyle}
                                    onClick={this.onForwardPress}
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.CLAIMTHISARTIST_STRINGS,
                                            stringId:
                                                stringIds.ClaimThisArtist.claim,
                                        }
                                    )}
                                    id={`${testIDPrefix}_Next`}
                                />
                            )}
                            {teamExists ? (
                                <LargeSolidButton
                                    containerStyle={buttonStyle}
                                    onClick={() =>
                                        window.open(
                                            LEARN_MORE_ABOUT_CLAIMS_URL,
                                            "_blank"
                                        )
                                    }
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.CLAIMTHISARTIST_STRINGS,
                                            stringId:
                                                stringIds.ClaimThisArtist
                                                    .learnMore,
                                        }
                                    )}
                                    id={`${testIDPrefix}_learnMore`}
                                />
                            ) : null}
                        </Col>
                    </Row>
                    {teamExists && this.getTeamExistsView()}
                </div>
            );
        }

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <ClaimHeaderWithBackButton />
                <GoBackButton
                    style={{ alignSelf: "flex-start" }}
                    onClick={() => this.props.history.push(paths.artistSearch)}
                    id={testIDPrefix}
                />
                <Col style={containerStyle}>
                    <Row style={centerAlign}>
                        <ImageWithFallback
                            style={rootStyles.imageStyles.mediumRoundImage}
                            source={artistImage || ""}
                            id={`${testIDPrefix}_ProfileImage`}
                            fallback={ImageList.placeholder_artist}
                        />
                    </Row>
                    <Row style={centerAlign}>
                        <styledTitle.h2
                            style={artistNameStyle}
                            id={`${testIDPrefix}_ArtistName`}
                        >
                            {artistName}
                        </styledTitle.h2>
                    </Row>
                    {display}
                    <Row style={centerAlign}>
                        <Col
                            style={{
                                textAlign: "center",
                                marginTop: rootStyles.spacers.large,
                                marginBottom: rootStyles.spacers.epic,
                            }}
                        >
                            <a
                                onClick={this.goBack}
                                style={{
                                    ...rootStyles.textStyles.tertiary,
                                    cursor: "pointer",
                                }}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.GENERIC_STRINGS,
                                    stringId: stringIds.Generic.cancel,
                                })}
                            </a>
                        </Col>
                    </Row>
                    <Row style={centerAlign}>
                        <span style={rootStyles.textStyles.tertiary}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                stringId:
                                    stringIds.ArtistDisambiguation
                                        .claimScreenHelpTextA,
                            }) + " "}
                            <span
                                style={clickableTextStyle}
                                onClick={() =>
                                    this.props.history.push(
                                        paths.reportArtistProfile
                                    )
                                }
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                    stringId:
                                        stringIds.ArtistDisambiguation
                                            .claimScreenHelpTextB,
                                })}
                            </span>
                        </span>
                    </Row>
                </Col>
            </Container>
        );
    }

    private getClaimPendingView = () => (
        <Row style={centerAlign}>
            <Row
                style={{
                    marginTop: rootStyles.spacers.large,
                }}
            >
                <Col style={{ width: "100%" }}>
                    <Icon
                        size={24}
                        icon={IconsList.ic_time_played}
                        id={`${testIDPrefix}_PendingIcon`}
                    />
                    <styledTitle.h4
                        style={{ marginLeft: 5, verticalAlign: "middle" }}
                        id={`${testIDPrefix}_TeamPendingTitleInfoText`}
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                            stringId:
                                stringIds.ClaimThisArtist.claimPendingTitle,
                        })}
                    </styledTitle.h4>
                </Col>
            </Row>
            <Row>
                <Col
                    style={{
                        ...centerAlign,
                        marginTop: rootStyles.spacers.large,
                        textAlign: "center",
                    }}
                >
                    <span
                        style={rootStyles.textStyles.primary}
                        id={`${testIDPrefix}_TeamPendingLabelInfoText`}
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                            stringId:
                                stringIds.ClaimThisArtist.claimPendingLabel,
                        })}
                    </span>
                </Col>
            </Row>
            <Row
                style={{
                    ...centerAlign,
                    marginTop: rootStyles.spacers.large,
                    textAlign: "center",
                    width: "100%",
                }}
            >
                <LargeSolidButton
                    containerStyle={buttonStyle}
                    onClick={() =>
                        window.open(LEARN_MORE_ABOUT_CLAIMS_URL, "_blank")
                    }
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                        stringId: stringIds.ClaimThisArtist.learnMore,
                    })}
                    id={`${testIDPrefix}_learnMore`}
                />
            </Row>
            {this.props.teams && this.props.teams.length > 0 ? (
                <Row
                    style={{
                        ...centerAlign,
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    <LargeOutlineButton
                        containerStyle={buttonStyle}
                        onClick={() => this.goBack()}
                        title={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                            stringId:
                                stringIds.ClaimThisArtist.claimAnotherArtist,
                        })}
                        id={`${testIDPrefix}_claimAnotherArtist`}
                    />
                </Row>
            ) : null}
        </Row>
    );

    private getTeamExistsView = () => (
        <Row style={centerAlign}>
            <Col
                style={{
                    textAlign: "center",
                    display: "inline-block",
                }}
            >
                <Dropdown
                    className="rightPointer"
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                        stringId: stringIds.ClaimThisArtist.needHelp,
                    })}
                >
                    <Dropdown.Toggle
                        id="needhelpdropdown"
                        variant="secondary"
                        style={{
                            ...dropDownToggleStyle,
                            width: "100%",
                            backgroundColor: "transparent",
                            ...infoText,
                        }}
                    >
                        <Row>
                            <Col style={{ padding: 0 }}>
                                <span
                                    style={{
                                        paddingRight: rootStyles.spacers.mini,
                                    }}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.CLAIMTHISARTIST_STRINGS,
                                        stringId:
                                            stringIds.ClaimThisArtist.needHelp,
                                    })}
                                    <Icon
                                        size={rootStyles.spacers.medium}
                                        icon={IconsList.chevron_caretdown}
                                        id={`${testIDPrefix}_NeedHelpDropdown`}
                                    />
                                </span>
                            </Col>
                        </Row>
                    </Dropdown.Toggle>

                    <StyledDropdownMenu>
                        <div style={centerAlign}>
                            <p
                                style={{
                                    ...infoText,
                                    marginTop: 0,
                                }}
                                id={`${testIDPrefix}_NeedHelpText`}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.CLAIMTHISARTIST_STRINGS,
                                    stringId:
                                        stringIds.ClaimThisArtist
                                            .withoutInvitation,
                                })}
                            </p>
                            <SmallOutlineButton
                                containerStyle={{
                                    margin: "0 auto",
                                    display: "flex",
                                    fontFamily: "AmazonEmber",
                                }}
                                onClick={this.onForwardPress}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.CLAIMTHISARTIST_STRINGS,
                                        stringId:
                                            stringIds.ClaimThisArtist
                                                .verifyAndClaim,
                                    }
                                ).toUpperCase()}
                                id={`${testIDPrefix}_VerifyAndClaim`}
                            />
                        </div>
                    </StyledDropdownMenu>
                </Dropdown>
            </Col>
        </Row>
    );

    private onForwardPress = () => {
        this.props.userAction({
            name: metricPrefix + "NextButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist?.asin],
                [METRIC_KEYS.page, paths.claim],
            ]),
        });
        this.props.history.push(paths.provideInformation);
    };

    private goBack = () => {
        this.props.userAction({
            name: metricPrefix + "CancelButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist?.asin],
                [METRIC_KEYS.page, paths.claim],
            ]),
        });
        this.props.clearSelectedArtistToClaim();
        this.props.history.replace(paths.artistSearch);
    };

    private artistTeam(artist: artist | undefined) {
        const team: teamInfo | undefined = this.props.teams?.find(
            (team) => artist?.asin && team.artistAsins.includes(artist.asin)
        );
        return team;
    }

    private artistSelected = () => {
        const request: SelectArtistProps = {
            selectedArtist: this.props.selectedArtist,
            teams: this.props.teams,
            path: paths.claim,
            metricPrefix: metricPrefix,
            artistTeamInfo: this.state.artistTeamInfo,
            isPrivileged: this.props.isPrivileged,
            historyProps: this.props,
            userAction: this.props.userAction,
            artistSelect: this.props.artistSelect,
        };
        onArtistSelected(request);
    };
}

function mapStateToProps(state: RootState): StateProps {
    return {
        selectedArtist: state.artistSearch.selectedArtist,
        teams: state.user.teams,
        checkTeamExistsInProgress: state.team.checkTeamExistsInProgress,
        checkTeamExistsResponse: state.team.teamExists,
        isPrivileged: state.user.isPrivileged,
        claimPendingCheckInProgress:
            state.artistSearch.claimPendingCheckInProgress,
        isPending: state.artistSearch.claimPending,
        bundleMap: getBundleMap(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        clearSelectedArtistToClaim: () =>
            dispatch(artistClaimActions.clearSelectedArtistToClaim()),
        checkTeamExists: (payload: checkTeamExistsPayload) =>
            dispatch(teamManagementActions.checkTeamExists(payload)),
        artistSelect: (payload: selectArtistPayload) =>
            dispatch(userActions.selectArtist(payload)),
        checkPendingClaim: (payload: checkPendingClaimPayload) =>
            dispatch(artistClaimActions.checkPendingClaim(payload)),
        setHideIntercomLauncher: (payload: boolean) =>
            dispatch(oAuthActions.setHideIntercomLauncher(payload)),
        setShowIntercomMessenger: (payload: boolean) =>
            dispatch(oAuthActions.setShowIntercomMessenger(payload)),
    };
}

const containerStyle: React.CSSProperties = {
    maxWidth: 600,
    margin: "auto",
    marginTop: rootStyles.spacers.large,
    alignItems: "center",
    marginBottom: "64px",
};
const artistNameStyle: React.CSSProperties = {
    textAlign: "center",
    lineHeight: 1,
};
const infoText: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    marginTop: rootStyles.spacers.large,
    textAlign: "center",
};
const centerAlign: React.CSSProperties = {
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
};

const dropDownToggleStyle: React.CSSProperties = {
    ...dropDownStyles.dropdownButton,
    paddingRight: rootStyles.spacers.small,
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: "center",
    alignSelf: "center",
};

const buttonStyle: React.CSSProperties = {
    width: "80%",
    marginTop: rootStyles.spacers.medium,
    alignSelf: "center",
};

const StyledDropdownMenu = styled(Dropdown.Menu)`
    width: 300px;
    padding: 20px;
    margin-top: 25px;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 10px 34px 0 rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    right: auto !important;
    left: 50% !important;
    transform: translate(-50%, 20%) !important;
`;

const clickableTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    color: rootStyles.colors.accent,
    marginTop: 2,
    textDecorationLine: "underline",
    cursor: "pointer",
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ClaimThisArtistScreen)
);
