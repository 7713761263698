import * as React from "react";
import { Row, Modal } from "react-bootstrap";
import { Icon, IconsList } from "../icons";
import * as rootStyles from "../../../styles";
import { SmallSolidButton } from "../input/Buttons";
import { BlurredModal } from "..";

export type ErrorModalProps = {
    text: string;
    description?: string;
    retryText?: string;
    retryAction?: () => void;
    onDismiss?: () => void;
    isVisible: boolean;
    sticky?: boolean;
};

export const ErrorModalIdPrefix = "ErrorModal";

export class ErrorModal extends React.Component<ErrorModalProps> {
    render() {
        return (
            <BlurredModal
                id="blurred-modal"
                onHide={this.props.onDismiss}
                show={this.props.isVisible}
                top="40%"
                maxwidth="256px"
            >
                <Modal.Body
                    id={ErrorModalIdPrefix + "-Body"}
                    style={modalContentStyle}
                >
                    <Row
                        style={{
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "flex-end",
                        }}
                    >
                        <div
                            onClick={this.props.onDismiss}
                            style={dismissIconContainerStyle}
                        >
                            <Icon
                                icon={IconsList.action_cancel}
                                size={rootStyles.icons.medium}
                                id={`${ErrorModalIdPrefix}_CancelButton`}
                            />
                        </div>
                    </Row>
                    <Row style={{ justifyContent: "center", marginBottom: 30 }}>
                        <Icon
                            size={48}
                            icon={IconsList.alerts_warning}
                            id={`${ErrorModalIdPrefix}_WarningIcon`}
                        />
                    </Row>
                    <Row style={centerAlign}>
                        <span style={{ ...rootStyles.textStyles.primary }}>
                            {this.props.description}
                        </span>
                    </Row>
                    <Row style={{ marginTop: rootStyles.spacers.large }}>
                        {this.props.retryAction && this.props.retryText && (
                            <SmallSolidButton
                                title={this.props.retryText}
                                onClick={this.props.retryAction}
                                id={`${ErrorModalIdPrefix}_Retry`}
                            />
                        )}
                    </Row>
                </Modal.Body>
            </BlurredModal>
        );
    }
}

const modalContentStyle: React.CSSProperties = {
    textAlign: "center",
    border: 0,
    backgroundColor: rootStyles.colors.background,
};

const centerAlign: React.CSSProperties = {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
};

const dismissIconContainerStyle: React.CSSProperties = {
    height: 36,
    width: 36,
    paddingTop: 4,
    cursor: "pointer",
    textAlign: "center",
    borderRadius: 18,
    backgroundColor: rootStyles.glassColors.primary2,
};
