import _ from "lodash";
import moment from "moment-timezone";
import {
    FanTiersType,
    StreamSourceType,
    fanTiersInfo,
    trackStreamsInfo,
    totalVoiceRequestsInfo,
    dataPoint,
    TimeRange,
    trackListenersInfo,
    streamSourceInfo,
    BundleMap,
} from "../models";
import { getLocalizedString, getQueryTimeZone } from "../utils";
import { formatNumber } from "./numberFormatter";
import { stringIds, bundleIds } from "../assets";
import { webLocale } from "./localeType";

export type linegraphPoint = {
    x: Date | string | number;
    y: number;
    xLabel?: string;
    yLabel?: string;
};

export type barRow = {
    x: string;
    y: number;
};

export const formatDatum = (
    datum: string,
    range: TimeRange,
    userTimeZone?: string
) => {
    const queryTimeZone = getQueryTimeZone(range, userTimeZone);
    const formattedString = moment(datum)
        .tz(queryTimeZone)
        .locale(webLocale[0])
        .format(timeRangeToDateFormatter(range));
    if (range === TimeRange.AllTime) {
        const labels = formattedString.split(" ");
        return `${labels[0]} ${labels[1]}\n${labels[2]}`;
    }

    return formattedString;
};

export const formatTooltipDatum = (
    datum: string,
    range: TimeRange,
    userTimeZone?: string
) => {
    const queryTimeZone = getQueryTimeZone(range, userTimeZone);
    if (range !== TimeRange.AllTime) {
        return moment(datum)
            .tz(queryTimeZone)
            .locale(webLocale[0])
            .format(tooltipTimeRangeToDateFormatter(range));
    } else {
        const date2 = new Date(datum);
        date2.setDate(date2.getDate() + 6);
        return `${moment(datum)
            .tz(queryTimeZone)
            .locale(webLocale[0])
            .format("MMM D")} - ${moment(date2)
            .tz(queryTimeZone)
            .locale(webLocale[0])
            .format("MMM D")}`;
    }
};

export const tooltipTimeRangeToDateFormatter = (range: TimeRange) => {
    switch (range) {
        case TimeRange.Today:
            return "hA";
        default:
            return "MMM D";
    }
};

export const timeRangeToDateFormatter = (range: TimeRange) => {
    switch (range) {
        case TimeRange.Today:
            return "hA";
        case TimeRange.SevenDays:
            return "dd";
        case TimeRange.TwentyEightDays:
            return "MMM D";
        default:
            return "MMM D YYYY";
    }
};

export const timeRangeToTickCount = (range: TimeRange) => {
    switch (range) {
        case TimeRange.Today:
            return 6;
        case TimeRange.Custom:
            return 2;
        case TimeRange.SevenDays:
            return 7;
        case TimeRange.TwentyEightDays:
            return 3;
        default:
            return 4;
    }
};

const trimZeros = (parsedData: Array<{ x: string; y: number }>) => {
    const firstNonZero = parsedData.findIndex((i) => i.y > 0);
    let trimmedData = parsedData.slice(firstNonZero);

    // if we have less than 2 points, display one of the zero points
    if (trimmedData.length < 2 && parsedData.length >= 2 && firstNonZero > 0) {
        trimmedData = [parsedData[firstNonZero - 1], trimmedData[0]];
    }
    return trimmedData;
};

export const parseTrackDataPoints = (
    lineGraphData:
        | trackStreamsInfo
        | trackListenersInfo
        | totalVoiceRequestsInfo,
    shouldTrim?: boolean
) => {
    if (!lineGraphData || !lineGraphData.dataPointList) {
        return [];
    }
    const parsedData = _.map(
        lineGraphData.dataPointList,
        (dataPoint: dataPoint) => {
            return {
                x: dataPoint.timestamp,
                y: dataPoint.value,
            };
        }
    );

    if (shouldTrim) {
        return trimZeros(parsedData);
    }

    return parsedData;
};

export const parseFanTierDataPoints = (
    lineGraphData: fanTiersInfo,
    shouldTrim?: boolean
) => {
    if (!lineGraphData || !lineGraphData.fansInfoList) {
        return [];
    }
    const fans = lineGraphData.fansInfoList.find(
        (x) => x.type === FanTiersType.Fan
    );
    const fansDataPointList = fans ? fans.dataPointList : [];
    const parsedData = _.map(fansDataPointList, (dataPoint: dataPoint) => {
        return {
            x: dataPoint.timestamp,
            y: dataPoint.value,
        };
    });

    if (shouldTrim) {
        return trimZeros(parsedData);
    }

    return parsedData;
};

export const getSuperFansCount = (lineGraphData: fanTiersInfo) => {
    if (!lineGraphData || !lineGraphData.fansInfoList) {
        return 0;
    }

    const fans = lineGraphData.fansInfoList.find(
        (x) => x.type === FanTiersType.SuperFan
    );

    const fansDataPointList = fans ? fans.dataPointList : [];
    const first = _.last(fansDataPointList);
    return first ? first.value : 0;
};

export const parseSuperFanTierDataPoints = (
    lineGraphData: fanTiersInfo,
    shouldTrim?: boolean
) => {
    if (!lineGraphData || !lineGraphData.fansInfoList) {
        return [];
    }
    const fans = lineGraphData.fansInfoList.find(
        (x) => x.type === FanTiersType.SuperFan
    );
    const fansDataPointList = fans ? fans.dataPointList : [];
    const parsedData = _.map(fansDataPointList, (dataPoint: dataPoint) => {
        return {
            x: dataPoint.timestamp,
            y: dataPoint.value,
        };
    });

    if (shouldTrim) {
        return trimZeros(parsedData);
    }

    return parsedData;
};

export const parseStreamSourceBar = (
    streamSources: streamSourceInfo[],
    bundleMap: BundleMap
) => {
    if (!streamSources) {
        return [];
    }

    const browseDetails = streamSources.find(
        (element) => element.source === StreamSourceType.Browse
    );
    const libraryDetails = streamSources.find(
        (element) => element.source === StreamSourceType.Library
    );
    const playlistDetails = streamSources.find(
        (element) => element.source === StreamSourceType.Playlist
    );
    const stationDetails = streamSources.find(
        (element) => element.source === StreamSourceType.Station
    );

    return [
        {
            x: getLocalizedString(bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.browseLabel,
            }),
            y: browseDetails ? browseDetails.percentage : 0,
        },
        {
            x: getLocalizedString(bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.libraryLabel,
            }),
            y: libraryDetails ? libraryDetails.percentage : 0,
        },
        {
            x: getLocalizedString(bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.playlistLabel,
            }),
            y: playlistDetails ? playlistDetails.percentage : 0,
        },
        {
            x: getLocalizedString(bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.stationLabel,
            }),
            y: stationDetails ? stationDetails.percentage : 0,
        },
    ];
};

export const formatYAxisDiffView = (dataum: number) => {
    return formatNumber(dataum);
};
