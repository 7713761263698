import _ from "lodash";
import React from "react";
import { Container, Image } from "react-bootstrap";
import { artist } from "../../../../models";

type Props = {
    artist?: artist;
};

export class ArtistGradientBackgroundImage extends React.Component<Props> {
    render() {
        return (
            <div>
                <Image
                    style={backgroundGradientImage}
                    src={this.props.artist?.backgroundImages?.artExtraLarge}
                />

                <div style={backgroundImageDimmer}></div>
            </div>
        );
    }
}

//styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101
const backgroundGradientImage: React.CSSProperties = {
    pointerEvents: "none",
    flex: 1,
    objectFit: "cover",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    opacity: 0.25,
};

const backgroundImageDimmer: React.CSSProperties = {
    pointerEvents: "none",
    flex: 1,
    objectFit: "cover",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: `linear-gradient(180deg, rgba(30, 34, 34, 0) 0%, #1E2222 100%)`,
};
