import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Icon, ImageWithFallback } from "..";
import * as rootStyles from "../../styles";
import styled, { StyledComponent } from "styled-components";
import { buttonTitleSharedStyles } from "../../styles/buttonStyles";

type Props = {
    cardStyle?: React.CSSProperties;
    image?: string;
    imageFallback?: string;
    imageStyle?: React.CSSProperties;
    imagePrimaryText?: string;
    imageSecondaryText?: string;
    rightIcon?: string;
    rightIconStyle?: React.CSSProperties;
    testIDPrefix: string;
    onClick?: () => void;
};

export class ImageCard extends React.Component<Props> {
    render() {
        return (
            <Card
                style={{ ...this.props.cardStyle, cursor: !!this.props.onClick ? "pointer" : "default" }}
                onClick={this.props.onClick}
            >
                <Col xs={"auto"}>
                    <ImageWithFallback
                        style={{
                            ...rootStyles.imageStyles.miniRoundImage,
                            margin: 0,
                            ...this.props.imageStyle,
                        }}
                        source={this.props.image}
                        id={this.props.testIDPrefix}
                        fallback={this.props.imageFallback}
                    />
                </Col>
                <Col style={{ padding: 0 }}>
                    <Row>
                        <span style={primaryTextStyle}>
                            {this.props.imagePrimaryText}
                        </span>
                    </Row>
                    <Row>
                        <span
                            style={{
                                ...rootStyles.textStyles.secondary,
                                marginTop: rootStyles.spacers.micro,
                            }}
                        >
                            {this.props.imageSecondaryText}
                        </span>
                    </Row>
                </Col>
                {this.props.rightIcon ? (
                    <StyledColCaret xs={2}>
                        <Icon
                            style={{
                                ...this.props.rightIconStyle,
                            }}
                            size={rootStyles.icons.medium}
                            icon={this.props.rightIcon}
                            id={`${this.props.testIDPrefix}_RightIcon`}
                        />
                    </StyledColCaret>
                ) : null}
            </Card>
        );
    }
}

const StyledColCaret = styled(Col)`
    display: flex;
    justify-content: right;
`;

const Card = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: ${rootStyles.glassColors.primary2};
    &:hover {
        background: ${rootStyles.glassColors.primary1};
    }
    margin-right: 0px;
    padding: ${rootStyles.spacers.small}px;
    border-radius: 8px;
`;

const primaryTextStyle: React.CSSProperties = {
    ...buttonTitleSharedStyles.large,
    margin: 0,
    padding: 0,
    color: rootStyles.colors.primary,
};
