import backgroundGradient from "./background_gradient.png";
import amazonMODBanner from "./amazon-merch-on-demand-logo.svg";
import amazonMODBannerImage from "./amazon-merch-on-demand-logo.png";
import createMerch from "./am4a_mod_shirt.png";
import getPaidOnEverySail from "./get_paid_on_every_sale.png";
import noInventoryRisk from "./no_inventory_risk.png";
import primeShipping from "./prime_shipping.png";
import earnRoyalties from "./mod_revenue.png";
import uploadArtwork from "./mod_upload_your_artwork.png";
import setYourPrice from "./mod_price_tag.png";
import primeShippingTruck from "./mod_prime_shipping.png";
import wePrint from "./mod_we_print.png";
import alreadyHaveMerch from "./alreadyHaveMerch.png";
import amazonSmile from "./amazonSmile.png";
import placeholderMerch from "./placeholderMerch.svg";
import merchRibbonShirt from "./merch_ribbon_shirt.png";

export const merchImages = {
    backgroundGradient,
    amazonMODBanner,
    amazonMODBannerImage,
    createMerch,
    getPaidOnEverySail,
    noInventoryRisk,
    primeShipping,
    earnRoyalties,
    uploadArtwork,
    setYourPrice,
    primeShippingTruck,
    wePrint,
    alreadyHaveMerch,
    amazonSmile,
    placeholderMerch,
    merchRibbonShirt,
};
