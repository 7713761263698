import { createSelector } from "reselect";

import { RootState } from "../reducers";
import { selectedTeamIdSelector } from "./userSelectors";
import {
    BundleMap,
    CardLayout,
    EntityType,
    TimeRange,
    Feature,
} from "../../models";
import { catalogSelector, getBundleMap } from "./commonSelectors";
import _ from "lodash";

const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;

const getSelectedStep = (state: RootState) => state.promoCard.selectedStep;

const getSelectedEntity = (state: RootState) => state.promoCard.selectedEntity;

const getSelectedCardLayout = (state: RootState) =>
    state.promoCard.selectedCardLayout;

const getSelectedCardLocale = (state: RootState) =>
    state.promoCard.selectedCardLocale;

const getTrackAlbumSearchInProgress = (state: RootState) =>
    state.contentSearch.inProgress;

const getTrackAlbumSearchResults = (state: RootState) =>
    state.contentSearch.items;

const getReports = (state: RootState) => state.reporting.reports;

const getSelectedTimeRange = (state: RootState) =>
    state.reporting.selectedRange;

const getPromocardSharelinkRepsonse = (state: RootState) =>
    state.catalog.promoCardShareLink;

// Gets the selected time range
const selectedTimeRangeReportsSelector = createSelector(
    [getReports, getSelectedTimeRange],
    (reports, selectedRange) => {
        return reports.get(selectedRange);
    }
);

export const getHasPromoCardFeaturePermission = (state: RootState) => {
    return (
        state.featureAccess.featurePermissionsList.length !== 0 &&
        !!_.find(state.featureAccess.featurePermissionsList, (permission) => {
            return permission.feature === Feature.PROMO_SHARE_CARD;
        })
    );
};

export const showHypeDeckSelector = createSelector(
    [getHasPromoCardFeaturePermission],
    (hasPromoCardFeaturePermission) => {
        return {
            showHypeDeck: hasPromoCardFeaturePermission,
        };
    }
);

const selectedCardPropsSelector = createSelector(
    [
        getSelectedCardLayout,
        getSelectedCardLocale,
        getSelectedStep,
        getSelectedEntity,
    ],
    (
        selectedCardLayout: CardLayout,
        selectedCardLocale: string,
        selectedStep: number | undefined,
        selectedEntity: EntityType
    ) => {
        return {
            selectedCardLayout,
            selectedCardLocale,
            selectedStep,
            selectedEntity,
        };
    }
);

export const promoCardSelector = createSelector(
    [
        selectedTimeRangeReportsSelector,
        getSelectedTimeRange,
        selectedTeamIdSelector,
        getSelectedArtistAsin,
        selectedCardPropsSelector,
        catalogSelector,
        getBundleMap,
        getTrackAlbumSearchInProgress,
        getTrackAlbumSearchResults,
        getPromocardSharelinkRepsonse,
    ],
    (
        result,
        selectedTimeRange: TimeRange,
        teamId: string | undefined,
        selectedArtistAsin: string | undefined,
        selectedCardProps,
        catalogSelector: any,
        bundleMap: BundleMap,
        trackAlbumSearchInProgress,
        trackAlbumSearchResults,
        promocardSharelinkRepsonse
    ) => {
        return {
            topTracks: result?.topTracksInfo?.trackInfoList || [],
            topAlbums: result?.topAlbumsInfo?.albumInfoList || [],
            selectedTimeRange,
            teamId,
            selectedArtistAsin,
            selectedCardLayout: selectedCardProps.selectedCardLayout,
            selectedCardLocale: selectedCardProps.selectedCardLocale,
            selectedStep: selectedCardProps.selectedStep,
            selectedEntity: selectedCardProps.selectedEntity,
            ...catalogSelector,
            bundleMap,
            artist: selectedArtistAsin
                ? catalogSelector.catalog.get(selectedArtistAsin)
                : undefined,
            trackAlbumSearchInProgress: trackAlbumSearchInProgress,
            trackAlbums: trackAlbumSearchResults,
            marqueePlaylists: selectedArtistAsin
                ? catalogSelector.catalog.get(selectedArtistAsin)
                      .marqueePlaylistInfo
                : [],
            promocardSharelinkRepsonse: promocardSharelinkRepsonse,
        };
    }
);
