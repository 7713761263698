import { connect } from "react-redux";
import React from "react";
import { AnyAction, Dispatch } from "redux";
import { ModalRenderFunction } from "../../../models";
import { globalNotificationsActions } from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { globalModalControllerSelector } from "../../../store/selectors/globalNotificationSelectors";
import { LimitReachedModal, ProductAddedConfirmationModal } from "../merch";

//Time between one modal closing and the next modal in the queue opening.
const TIME_DELAY_BETWEEN_MODAL_DISPLAYS = 500;

type StateProps = {
    modalRenderFunction?: ModalRenderFunction;
};

type DispatchProps = {
    closeActiveModal: () => void;
};

type Props = StateProps & DispatchProps;

type State = {
    isDisplayingModal: boolean;
    closeActiveModalTimeoutRunning: boolean;
};

class GlobalModalController extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isDisplayingModal: false,
            closeActiveModalTimeoutRunning: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            this.props.modalRenderFunction &&
            !this.state.isDisplayingModal &&
            !this.state.closeActiveModalTimeoutRunning
        ) {
            this.setState({ isDisplayingModal: true });
        }
    }

    render() {
        if (!this.props.modalRenderFunction) {
            return null;
        }

        return this.props.modalRenderFunction(
            this.onDismiss,
            this.state.isDisplayingModal
        );
    }

    private onDismiss = () => {
        setTimeout(() => {
            this.props.closeActiveModal();
            this.setState({ closeActiveModalTimeoutRunning: false });
        }, TIME_DELAY_BETWEEN_MODAL_DISPLAYS);
        this.setState({
            isDisplayingModal: false,
            closeActiveModalTimeoutRunning: true,
        });
    };
}

//Creating an export for these render functions as they are used in curateMerchSaga, which is a
//.ts file and cannot interpret JSX elements
export const limitReachedModalRenderFunction: ModalRenderFunction = (
    dismiss: () => void,
    isVisible: boolean
) => {
    return <LimitReachedModal onDismiss={dismiss} isVisible={isVisible} />;
};
export const productAddedConfirmationModalRenderFunction: ModalRenderFunction = (
    dismiss: () => void,
    isVisible: boolean
) => {
    return (
        <ProductAddedConfirmationModal
            onDismiss={dismiss}
            isVisible={isVisible}
        />
    );
};

function mapStateToProps(state: RootState): StateProps {
    return globalModalControllerSelector(state);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        closeActiveModal: () =>
            dispatch(globalNotificationsActions.closeActiveModal()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalModalController);
