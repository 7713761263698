import React from "react";
import _ from "lodash";
import { VictoryPie } from "victory";
import { generateLabel, chartStyles } from "./styles";
import { containerStyles } from "./";
import * as rootStyles from "../../../view/styles";
import { ChartProps, DataSet } from "./common";
import { ChartHeader } from "../charts";
import { CSSProperties } from "styled-components";

const chartColors = [
    rootStyles.colors.accent,
    rootStyles.colors.primary,
    rootStyles.colors.aux1,
    rootStyles.colors.aux2,
    rootStyles.colors.aux3,
    rootStyles.colors.aux4,
    rootStyles.colors.aux5,
];

export type PieChartProps = ChartProps & {
    labels: string[];
    data: DataSet;
};

export class PieChart extends React.PureComponent<PieChartProps> {
    render() {
        return (
            <div key="chart" style={containerStyles.chartContainer}>
                <ChartHeader {...this.props} />
                <div key="charContainer" style={pieChartContainer}>
                    <VictoryPie
                        padAngle={0}
                        innerRadius={chartStyles.pieChart.innerRadius}
                        colorScale={chartColors}
                        height={chartStyles.pieChart.height}
                        padding={{ top: 0, bottom: 0 }}
                        data={this.props.data}
                        standalone={true}
                        labelComponent={<div />}
                    />
                    <div key="labels" style={labelContainer}>
                        {this.props.labels.map((l, i) =>
                            generateLabel(l, chartColors[i])
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const pieChartContainer: CSSProperties = {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-start",
    padding: 0,
    paddingBottom: 10,
    alignSelf: "center",
};
const labelContainer: CSSProperties = {
    position: "absolute",
    top: 80,
    alignItems: "flex-start",
};
