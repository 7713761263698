import countryTranslator from "i18n-iso-countries";

// Adding all languages, so we could support translating country codes to other languages easily
export const initializeCountryCodeTranslater = () => {
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ur.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/vi.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ar.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/az.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/be.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/bg.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/bs.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ca.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/cs.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/da.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/de.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/el.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/en.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/es.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/et.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/fa.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/fi.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/fr.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/el.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/he.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/hr.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/hu.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/hy.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/id.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/it.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ja.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ka.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/kk.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ko.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ky.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/lt.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/lv.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/mk.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/mn.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/nb.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/nl.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/nn.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/pl.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/pt.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ro.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/ru.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/sk.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/sl.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/sr.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/sv.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/th.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/tr.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/uk.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/uz.json")
    );
    countryTranslator.registerLocale(
        require("i18n-iso-countries/langs/zh.json")
    );
};
