import { createAction } from "redux-ts";
import {
    FailedToResolveStringIdPayload,
    AddFailedToResolveStringPayload,
    UpdateBundlePayload,
    AddBundlePayload,
} from "../../models";

export const failedToResolveStringId = createAction<FailedToResolveStringIdPayload>("LOCALIZATION::RESOLVE_FAIL");

export const addFailedToResolveString = createAction<AddFailedToResolveStringPayload>("LOCALIZATION::ADD_DATA");

export const clearBundleMap = createAction("LOCALIZATION::CLEAR_BUNDLES");

export const updateBundle = createAction<UpdateBundlePayload>("LOCALIZATION::UPDATE_BUNDLE");

export const addBundle = createAction<AddBundlePayload>("LOCALIZATION::ADD_BUNDLE");
