import _ from "lodash";
import { hydratedMerchItem } from "../../../models";
import { MerchListItemProps } from "./MerchListItem";

export const MERCH_HYDRATION_BATCH_SIZE = 50;

export const MAX_NUMBER_CAROUSEL_ITEMS = 16;

export const generateListItemFromMerchItem = ({
    result,
    id,
    selected,
    showCuratedItemFeatures,
    onSelect,
    hideCheckbox,
    disabled,
    requestPath,
    pageId,
}: {
    result: hydratedMerchItem;
    id: string;
    selected?: boolean;
    showCuratedItemFeatures: boolean;
    onSelect?: () => void;
    hideCheckbox?: boolean;
    disabled?: boolean;
    requestPath: string;
    pageId: string;
}): MerchListItemProps => {
    return {
        primaryText: result.metadata.itemName,
        secondaryText: result.metadata.category,
        tertiaryText: result.metadata.price,
        itemAsin: result.itemAsin,
        image: result.metadata.productImages[0]?.lowResUrl,
        selected: selected,
        onSelect: onSelect,
        hideCheckbox: hideCheckbox,
        id: `${id}-${result.itemAsin}`,
        disabled: disabled,
        showCuratedItemFeatures: showCuratedItemFeatures,
        requestPath: requestPath,
        pageId: pageId,
    };
};
