export type settings = {
    marketingEmailSetting?: OptValue;
    userLocaleSetting?: string;
    betaEmailSetting?: OptValue;
    acceptedTermsSetting?: boolean;
};

export enum OptValue {
    OPT_IN = "OPT_IN",
    OPT_OUT = "OPT_OUT",
}
