import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { IconsList, Icon } from "../icons";
import { LanguageRadioList } from "../input";
import {
    userLanguages,
    translateUserLanguageToLocaleString,
    getLocalizedString,
} from "../../../../utils";
import { styledTitle } from "../../../../view/components";
import * as rootStyles from "../../../styles";
import { stringIds, bundleIds } from "../../../../assets";
import { BundleMap } from "../../../../models";

export type LanguageModalProps = {
    isVisible: any;
    initialUserLocale: string;
    onDismiss: (userLocale: string) => void;
    bundleMap: BundleMap;
};

type State = {
    userSelectedLocale: string;
};

export const testIDPrefix = "LanguageModalId";

export class LanguageModal extends React.Component<LanguageModalProps, State> {
    constructor(props: LanguageModalProps) {
        super(props);
        this.state = {
            userSelectedLocale: props.initialUserLocale,
        };
    }

    render() {
        return (
            <Modal
                id="LanguageModal"
                show={true}
                style={rootStyles.containerStyles.modal}
            >
                <Modal.Body style={modalContainer}>
                    <Row
                        style={cancelButtonStyle}
                        onClick={() => this.onClickDismiss()}
                    >
                        <Icon
                            icon={IconsList.action_cancel}
                            size={rootStyles.spacers.huge}
                            color={rootStyles.colors.primary}
                            id={testIDPrefix + "_DismissIcon"}
                        />
                    </Row>
                    <Row style={{ alignSelf: "center" }}>
                        <Col>
                            <styledTitle.h3>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.SETTINGS_STRINGS,
                                    stringId:
                                        stringIds.Settings.languageModalTitle,
                                })}
                            </styledTitle.h3>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: rootStyles.spacers.large }}>
                        <LanguageRadioList
                            onChangeElement={(userLanguage) =>
                                this.onChangeLanguage(userLanguage)
                            }
                            initialSelectedIndex={this.getIndexFromLocale(
                                this.props.initialUserLocale
                            )}
                            id={`${testIDPrefix}_LanguageRadioList`}
                        />
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }

    private onClickDismiss = () => {
        this.props.onDismiss(this.state.userSelectedLocale);
    };

    private onChangeLanguage = (locale: string) => {
        this.setState({ userSelectedLocale: locale });
    };

    private getIndexFromLocale = (locale: string) => {
        for (let i = 0; i < userLanguages.length; i++) {
            if (
                translateUserLanguageToLocaleString(userLanguages[i]) === locale
            ) {
                return i;
            }
        }
        return 0;
    };
}

const modalContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: rootStyles.colors.background,
    display: "flex",
};

const cancelButtonStyle: React.CSSProperties = {
    flex: 0,
    padding: 4,
    borderRadius: rootStyles.spacers.large,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
};
