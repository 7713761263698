import React, { useEffect } from "react";
import { buildUIPageViewPayload, getLocalizedString } from "../../../../utils";
import { artist, BundleMap } from "../../../../models";
import { FullScreenConfirmationModal, IconsList } from "../../common";
import { bundleIds, pageIds, stringIds } from "@amzn/ziggy-asset";
import { useDispatch } from "react-redux";
import { clientMetricsActions } from "../../../../store/actions";

export type CustomerSupportUnavailableModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
    onBrowseFaqs: () => void;
    bundleMap: BundleMap;
    selectedArtist?: artist;
};

export const CustomerSupportUnavailableModal: React.FC<
    CustomerSupportUnavailableModalProps
> = ({ isVisible, onDismiss, onBrowseFaqs, bundleMap, selectedArtist }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIPageViewPayload(
                    pageIds.customerSupportUnavailable,
                    selectedArtist?.asin
                )
            )
        );
    }, []);

    return (
        <FullScreenConfirmationModal
            id={"CustomerSupportErrorModal"}
            text={getLocalizedString(bundleMap, {
                bundleId: bundleIds.CUSTOMER_SUPPORT_STRINGS,
                stringId: stringIds.CustomerSupport.unavailable_modal_title,
            })}
            icon={IconsList.alerts_Information}
            confirmButtonText={getLocalizedString(bundleMap, {
                bundleId: bundleIds.CUSTOMER_SUPPORT_STRINGS,
                stringId:
                    stringIds.CustomerSupport.unavailable_modal_brwose_button,
            })}
            description={getLocalizedString(bundleMap, {
                bundleId: bundleIds.CUSTOMER_SUPPORT_STRINGS,
                stringId: stringIds.CustomerSupport.unavailable_modal_text,
            })}
            confirmButtonAction={onBrowseFaqs}
            cancelButtonAction={onDismiss}
            cancelButtonStyle={{
                textTransform: "none",
            }}
            cancelButtonText={getLocalizedString(bundleMap, {
                bundleId: bundleIds.CUSTOMER_SUPPORT_STRINGS,
                stringId:
                    stringIds.CustomerSupport.unavailable_modal_back_button,
            })}
            onDismiss={onDismiss}
            isVisible={isVisible}
            buttonOrientation={"inline"}
        />
    );
};
