import * as React from "react";
import * as rootStyles from "../../../styles";
import { Icon } from "../icons";

export type BoxProps = {
    title: string;
    onClick?: (selected: boolean) => void;
    rightIconName?: string;
    value?: boolean;
    viewOnly?: boolean;
    id: string;
};

const BoxPrefix = "Box";

export const Box: React.FC<BoxProps> = ({
    title,
    onClick,
    rightIconName,
    value = false,
    viewOnly = false,
    id,
}) => {
    const boxContainerStyle = value
        ? selectedBoxContainerStyle
        : unselectedBoxContainerStyle;
    return (
        <div
            style={viewOnly ? viewOnlyBoxContainerStyle : boxContainerStyle}
            onClick={() => (viewOnly ? null : onClick && onClick(!value))}
            id={`${id}-${BoxPrefix}-Container`}
        >
            <span
                style={
                    viewOnly
                        ? viewOnlyBoxTextStyle
                        : value
                        ? selectedBoxTextStyle
                        : unselectedBoxTextStyle
                }
                id={`${id}-${BoxPrefix}-Title`}
            >
                {title}
            </span>
            {rightIconName ? (
                <Icon
                    icon={rightIconName}
                    size={20}
                    style={{ marginLeft: rootStyles.spacers.mini }}
                    id={`${id}_RightIcon`}
                />
            ) : null}
        </div>
    );
};

const boxContainerCommonStyle: React.CSSProperties = {
    height: 40,
    display: "inline-flex",
    alignItems: "center",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 5,
    paddingRight: rootStyles.spacers.base,
    paddingLeft: rootStyles.spacers.base,
};

const selectedBoxContainerStyle: React.CSSProperties = {
    ...boxContainerCommonStyle,
    backgroundColor: rootStyles.colors.accent,
    borderColor: rootStyles.colors.accent,
    cursor: "pointer",
};

const unselectedBoxContainerStyle: React.CSSProperties = {
    ...boxContainerCommonStyle,
    borderColor: rootStyles.glassColors.primary4,
    cursor: "pointer",
};

const viewOnlyBoxContainerStyle: React.CSSProperties = {
    ...boxContainerCommonStyle,
    backgroundColor: rootStyles.glassColors.primary2,
};

const selectedBoxTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    userSelect: "none",
    color: rootStyles.glassColors.secondary5,
};

const unselectedBoxTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    userSelect: "none",
    color: rootStyles.glassColors.primary4,
};

const viewOnlyBoxTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    color: rootStyles.colors.primary,
};

export default Box;
