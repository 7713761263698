import {
    landscapeGradients,
    landscapeLogos,
} from "../../assets/promoCard/landscape";
import {
    portraitGradients,
    portraitLogos,
} from "../../assets/promoCard/portrait";
import { squareGradients, squareLogos } from "../../assets/promoCard/square";
import { CardLayout, EntityType, PromoCardBackgroundColor } from "../../models";
import { locale } from "../localeType";

const PORTRAIT_HEIGHT = 1920;
const PORTRAIT_WIDTH = 1080;
const SQUARE_HEIGHT = 1080;
const SQUARE_WIDTH = 1080;
const LANDSCAPE_HEIGHT = 1080;
const LANDSCAPE_WIDTH = 1920;

export enum PromoCardStep {
    SELECT = 0,
    CUSTOMIZE = 1,
    SHARE = 2,
}

/**
 * Define the Promo card shades color for logo
 */
export enum PromoCardLogoColor {
    WHITE = "WHITE",
    BLACK = "BLACK",
}

export const PromoCardConstants = {
    PORTRAIT_HEIGHT,
    PORTRAIT_WIDTH,
    SQUARE_HEIGHT,
    SQUARE_WIDTH,
    LANDSCAPE_HEIGHT,
    LANDSCAPE_WIDTH,
};

export const PromoCardBackgroundColorList = Object.values(
    PromoCardBackgroundColor
);

export interface PromoCardGrandientSource {
    portraitGradient: string;
    landscapeGradient: string;
    sqaureGradient: string;
    isTextDark: boolean;
}

export const backgroundColorMapping = new Map<string, PromoCardGrandientSource>(
    [
        [
            PromoCardBackgroundColor.CYAN,
            {
                portraitGradient: portraitGradients.cyan,
                landscapeGradient: landscapeGradients.cyan,
                sqaureGradient: squareGradients.cyan,
                isTextDark: true,
            },
        ],
        [
            PromoCardBackgroundColor.CYAN_PURPLE,
            {
                portraitGradient: portraitGradients.cyanPurple,
                landscapeGradient: landscapeGradients.cyanPurple,
                sqaureGradient: squareGradients.cyanPurple,
                isTextDark: false,
            },
        ],
        [
            PromoCardBackgroundColor.PURPLE,
            {
                portraitGradient: portraitGradients.purple,
                landscapeGradient: landscapeGradients.purple,
                sqaureGradient: squareGradients.purple,
                isTextDark: false,
            },
        ],
        [
            PromoCardBackgroundColor.RED_PURPLE,
            {
                portraitGradient: portraitGradients.redPurple,
                landscapeGradient: landscapeGradients.redPurple,
                sqaureGradient: squareGradients.redPurple,
                isTextDark: true,
            },
        ],
        [
            PromoCardBackgroundColor.VIOLET_CHARCOAL,
            {
                portraitGradient: portraitGradients.violetCharcoal,
                landscapeGradient: landscapeGradients.violetCharcoal,
                sqaureGradient: squareGradients.violetCharcoal,
                isTextDark: false,
            },
        ],
        [
            PromoCardBackgroundColor.YELLOW,
            {
                portraitGradient: portraitGradients.yellow,
                landscapeGradient: landscapeGradients.yellow,
                sqaureGradient: squareGradients.yellow,
                isTextDark: true,
            },
        ],
        [
            PromoCardBackgroundColor.CHARCOAL,
            {
                portraitGradient: portraitGradients.charcoal,
                landscapeGradient: landscapeGradients.charcoal,
                sqaureGradient: squareGradients.charcoal,
                isTextDark: false,
            },
        ],
    ]
);

export const borderGradientSourceList = [PromoCardBackgroundColor.CHARCOAL];

export enum LogoType {
    FOLLOW = "FOLLOW",
    LISTEN = "LISTEN",
}

export interface cardLogoProps {
    locale: string;
    logoType: LogoType;
    isDark: boolean;
    cardLayout: CardLayout;
}

export const getCardLogo = (logoProps: cardLogoProps) => {
    return cardLogoMapping.get(JSON.stringify(logoProps));
};

export const cardLogoMapping = new Map<string, string>([
    // portrait logos
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_deDE_white,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_deDE_black,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_deDE_white,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_deDE_black,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_enUS_white,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_enUS_black,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_enUS_white,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_enUS_black,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_esES_white,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_esES_black,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_esES_white,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_esES_black,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_frFR_white,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_frFR_black,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_frFR_white,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_frFR_black,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_itIT_white,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_itIT_black,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_itIT_white,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_itIT_black,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_jaJP_white,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_jaJP_black,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_jaJP_white,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_jaJP_black,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_ptBR_white,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_follow_ptBR_black,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_ptBR_white,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Portrait,
        }),
        portraitLogos.am_listen_ptBR_black,
    ],
    // square logos
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_deDE_white,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_deDE_black,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_deDE_white,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_deDE_black,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_enUS_white,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_enUS_black,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_enUS_white,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_enUS_black,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_esES_white,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_esES_black,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_esES_white,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_esES_black,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_frFR_white,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_frFR_black,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_frFR_white,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_frFR_black,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_itIT_white,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_itIT_black,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_itIT_white,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_itIT_black,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_jaJP_white,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_jaJP_black,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_jaJP_white,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_jaJP_black,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_ptBR_white,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_follow_ptBR_black,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_ptBR_white,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Square,
        }),
        squareLogos.am_listen_ptBR_black,
    ],
    // landscape logos
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_deDE_white,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_deDE_black,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_deDE_white,
    ],
    [
        JSON.stringify({
            locale: locale.deDE,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_deDE_black,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_enUS_white,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_enUS_black,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_enUS_white,
    ],
    [
        JSON.stringify({
            locale: locale.enUS,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_enUS_black,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_esES_white,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_esES_black,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_esES_white,
    ],
    [
        JSON.stringify({
            locale: locale.esES,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_esES_black,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_frFR_white,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_frFR_black,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_frFR_white,
    ],
    [
        JSON.stringify({
            locale: locale.frFR,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_frFR_black,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_itIT_white,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_itIT_black,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_itIT_white,
    ],
    [
        JSON.stringify({
            locale: locale.itIT,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_itIT_black,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_jaJP_white,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_jaJP_black,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_jaJP_white,
    ],
    [
        JSON.stringify({
            locale: locale.jaJP,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_jaJP_black,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.FOLLOW,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_ptBR_white,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.FOLLOW,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_follow_ptBR_black,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.LISTEN,
            isDark: false,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_ptBR_white,
    ],
    [
        JSON.stringify({
            locale: locale.ptBR,
            logoType: LogoType.LISTEN,
            isDark: true,
            cardLayout: CardLayout.Landscape,
        }),
        landscapeLogos.am_listen_ptBR_black,
    ],
]);
