import { put, select, takeEvery } from "redux-saga/effects";
import { Action } from "redux-ts";
import { METRIC_KEYS, outageStatusEntry } from "../../models";
import {
    outageStatusInProgressSelector,
    lastOutageStatusFetchSelector,
} from "../selectors";
import * as service from "../../service";
import { publishTimer, startTimer } from "../../service";
import {
    errorActions,
    opsMetricsActions,
    outageStatusActions,
    telemetryActions,
} from "../actions";
import { createSuccessOpsMetricsPayload } from "../../utils";

const OUTAGE_STATUS_FETCH_BACKOFF_MS = 30 * 1000;

export const outageStatusSagas = [watchGetOutageStatus()];

function* getOutageStatus(action: Action) {
    const functionName = "getOutageStatus";
    try {
        const isOutageStatusCallInProgress: boolean = yield select(
            outageStatusInProgressSelector
        );
        const lastOutageStatusCall: number = yield select(
            lastOutageStatusFetchSelector
        );

        if (
            !isOutageStatusCallInProgress &&
            timeSinceLastOutageStatusCall(lastOutageStatusCall) >
                OUTAGE_STATUS_FETCH_BACKOFF_MS
        ) {
            yield put(outageStatusActions.getOutageStatusInProgress(true));

            const start = Date.now();
            const timerMetric = startTimer("getOutageStatusDuration");

            yield put(
                telemetryActions.appEvent({
                    name: "getOutageStatusStart",
                    dataPoints: new Map<string, string | undefined>([]),
                })
            );

            const response: outageStatusEntry[] =
                yield service.getOutageStatus();

            publishTimer(timerMetric);

            yield put(
                telemetryActions.appEvent({
                    name: "getOutageStatusEnd",
                    dataPoints: new Map<string, string | undefined>([
                        [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    ]),
                })
            );

            yield put(outageStatusActions.getOutageStatusCompleted(response));
            yield put(outageStatusActions.getOutageStatusInProgress(false));
        }

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        // If this fails for whatever, we will silently fail as a best-effort call and wipe out the old status (act as if there is no outage)
        yield put(outageStatusActions.getOutageStatusCompleted([]));
        yield put(outageStatusActions.getOutageStatusInProgress(false));

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                silent: true,
                shouldRetry: false,
                dontRefreshOutageStatus: true,
            })
        );
    }
}

function timeSinceLastOutageStatusCall(lastCallDate: number) {
    return Date.now() - lastCallDate;
}

function* watchGetOutageStatus() {
    yield takeEvery(outageStatusActions.getOutageStatus.type, getOutageStatus);
}
