import * as React from "react";
import * as rootStyles from "../../../styles";
import { Icon, IconsList } from "../icons/Icon";
import { Col, Row } from "react-bootstrap";
import { SmallOutlineButton } from "./Buttons";

export type LanguageRadioElementProps = {
    primary: string;
    secondary?: string;
    selected: boolean;
    onButtonSelected: () => void;
    key: number;
    id: string;
};

type State = {
    selected: boolean;
    onMouseOverElement: boolean;
    onMouseOverCircle: boolean;
};

export class LanguageRadioElement extends React.Component<
    LanguageRadioElementProps,
    State
> {
    constructor(props: LanguageRadioElementProps) {
        super(props);
        this.state = {
            selected: this.props.selected,
            onMouseOverElement: false,
            onMouseOverCircle: false,
        };
    }

    componentDidUpdate(prevProps: LanguageRadioElementProps) {
        if (this.props.selected !== prevProps.selected) {
            this.setState({ selected: this.props.selected });
        }
    }

    render() {
        return (
            <Row
                style={
                    this.state.onMouseOverElement
                        ? hoveredRadioContainer
                        : radioContainer
                }
                onMouseEnter={this.onMouseEnterElement}
                onMouseLeave={this.onMouseLeaveElement}
            >
                <Col style={textContainer} xs={"auto"}>
                    <Row>
                        <span style={rootStyles.textStyles.primary}>
                            {this.props.primary}
                        </span>
                    </Row>
                    <Row style={{ paddingTop: rootStyles.spacers.small }}>
                        {this.props.secondary && (
                            <span style={rootStyles.textStyles.secondary}>
                                {this.props.secondary}
                            </span>
                        )}
                    </Row>
                </Col>
                <Col
                    style={iconContainer}
                    xs={"auto"}
                    onMouseEnter={this.onMouseEnterCircle}
                    onMouseLeave={this.onMouseLeaveCircle}
                >
                    {this.getIcon()}
                </Col>
            </Row>
        );
    }

    private getIcon = () => {
        if (this.state.selected) {
            return (
                <Icon
                    icon={IconsList.list_check_accent}
                    size={rootStyles.icons.small}
                    color={rootStyles.colors.accent}
                    id={`${this.props.id}_RadioButton`}
                />
            );
        } else {
            return (
                <SmallOutlineButton
                    buttonStyle={
                        this.state.onMouseOverCircle
                            ? hoveredCircle
                            : unselectedCircle
                    }
                    onClick={this.props.onButtonSelected}
                    id={`${this.props.id}_RadioButton`}
                />
            );
        }
    };

    private onMouseEnterElement = () => {
        this.setState({ onMouseOverElement: true });
    };

    private onMouseLeaveElement = () => {
        this.setState({ onMouseOverElement: false });
    };

    private onMouseEnterCircle = () => {
        this.setState({ onMouseOverCircle: true });
    };

    private onMouseLeaveCircle = () => {
        this.setState({ onMouseOverCircle: false });
    };
}

const radioContainer: React.CSSProperties = {
    padding: rootStyles.spacers.mini,
    borderBottomStyle: "solid",
    paddingBottom: 0,
    borderBottomColor: rootStyles.glassColors.primary3,
    borderBottomWidth: 1,
    flex: 1,
    justifyContent: "space-between",
};

const hoveredRadioContainer: React.CSSProperties = {
    padding: rootStyles.spacers.mini,
    borderBottomStyle: "solid",
    paddingBottom: 0,
    borderBottomColor: rootStyles.glassColors.primary3,
    borderBottomWidth: 1,
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: rootStyles.glassColors.primary2,
};

const iconContainer: React.CSSProperties = {
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: rootStyles.spacers.base,
    height: 36,
    paddingLeft: 0,
};

const baseCircle: React.CSSProperties = {
    borderWidth: 2,
    padding: 0,
    borderRadius: rootStyles.icons.small / 2,
    height: rootStyles.icons.small,
    width: rootStyles.icons.small,
};

const unselectedCircle: React.CSSProperties = {
    ...baseCircle,
    borderColor: rootStyles.glassColors.primary3,
};

const hoveredCircle: React.CSSProperties = {
    ...baseCircle,
    borderColor: rootStyles.colors.accent,
};

const textContainer: React.CSSProperties = {
    alignSelf: "flex-start",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 3,
    paddingBottom: 12,
};
