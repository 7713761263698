import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { AnyAction } from "redux";
import {
    artistIssueActions,
    catalogActions,
    clientMetricsActions,
    pitchActions,
    pitchFormActions,
    RootState,
    telemetryActions,
    userActions,
} from "../../../store";
import {
    buildUIClickPayload,
    concatenateArtistNames,
    formatDate,
    generateNewReleaseRow,
    generateSongRowsForPitch,
    getLocalizedString,
    getLocalizedUrl,
    hydrationHelpers,
    paths,
    selectedGroupItemsToTags,
    selectedItemsPropsToTags,
    sortTagsByName,
    tagListToCodeList,
    testIDSuffixes,
} from "../../../utils";
import {
    CommonHeader,
    Footer,
    FormNavigation,
    FormNavigationStep,
    FullScreenConfirmationModal,
    Icon,
    IncrementalLoading,
    InlineError,
    Loading,
    pitchHelpModal,
    pitchTrackRightElement,
    ProfileHead,
    ProfileHeadProps,
    styledTitle,
    Table,
    TableRow,
} from "../../components";
import * as rootStyles from "../../styles";
import { IconsList, ImageList, stringIds, bundleIds } from "../../../assets";
import {
    album,
    albumRelease,
    baseMediaItem,
    CatalogProps,
    createPitchPayload,
    createPitchRequest,
    ErrorPayload,
    getPitchFormDataPayload,
    group,
    hydrateCatalogPayload,
    itemProps,
    listRelatedArtistsPayload,
    listTracksToPitchPayload,
    METRIC_KEYS,
    newPlaylistPitchInfo,
    PitchType,
    ProfileImageSize,
    TableRowProps,
    telemetryPayload,
    track,
    trackInfoForPitch,
    pitchFormData,
    artist,
    listAlbumsToPitchPayload,
    CatalogItemType,
    clientMetricsPayload,
    EntityType,
    BundleMap,
} from "../../../models";
import { pitchSelector } from "../../../store/selectors/pitchSelectors";
import _ from "lodash";
import { PitchBasics } from "../../components/pitch";
import PitchMoreInfo from "../../components/pitch/PitchMoreInfo";
import { PitchReview } from "../../components/pitch/PitchReview";
import styled from "styled-components";
import { LocalizedUrls, buttonIds, pageIds } from "@amzn/ziggy-asset";

const testIDPrefix = "PitchScreen";
const metricPrefix = "pitchScreen";
const pagePath = paths.pitch;
const pitchFormSteps: FormNavigationStep[] = [
    {
        stringId: stringIds.Pitch.stepChooseSong,
        enabled: true,
        bundleId: bundleIds.PITCH_STRINGS,
    },
    {
        stringId: stringIds.Pitch.stepTheBasics,
        enabled: false,
        bundleId: bundleIds.PITCH_STRINGS,
    },
    {
        stringId: stringIds.Pitch.stepMoreInfo,
        enabled: false,
        bundleId: bundleIds.PITCH_STRINGS,
    },
    {
        stringId: stringIds.Pitch.stepReview,
        enabled: false,
        bundleId: bundleIds.PITCH_STRINGS,
    },
];

type ViewProps = {};

type StateProps = CatalogProps & {
    selectedAlbumRelease: albumRelease;
    selectedArtistAsin: string;
    userLocale: string;
    error?: ErrorPayload;
    description: string;
    genresProps: group[];
    compositionsProps: itemProps[];
    songHasVocals: boolean;
    languagesProps: group[];
    selectedTrack?: track;
    songMoodsProps: itemProps[];
    songStylesProps: itemProps[];
    relatedArtistsProps: group[];
    locationProps: group[];
    teamId: string;
    albumRelease?: albumRelease[];
    selectedStep?: number;
    inProgress: boolean;
    tracksToPitch?: trackInfoForPitch[];
    relatedArtists?: artist[];
    pitchFormData?: pitchFormData;
    createPitchInProgress: boolean;
    createdPitchSuccess?: boolean;
    isPrivileged?: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    userAction: (payload: telemetryPayload) => void;
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    selectReportIssueItem: (payload: baseMediaItem) => void;
    selectTrack: (payload: string) => void;
    selectStep: (payload: number) => void;
    getTracksToPitch: (payload: listTracksToPitchPayload) => void;
    clearPitchForm: () => void;
    getRelatedArtists: (payload: listRelatedArtistsPayload) => void;
    getPitchFormData: (payload: getPitchFormDataPayload) => void;
    createPitch: (payload: createPitchPayload) => void;
    getAlbumReleases: (payload: listAlbumsToPitchPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    stepIndex: number | undefined;
    jumpToStep?: number;
    albumCatalogItem?: album;
    trackCatalogItem?: track;
    showError: boolean;
    showSuccessModal: boolean;
    showFailureModal: boolean;
    showHelpModal: boolean;
    finishedInitialLoad: boolean;
    loadedCount: number;
};

enum PitchFormStep {
    CHOOSE_TRACK = 0,
    THE_BASICS = 1,
    MORE_INFO = 2,
    REVIEW = 3,
}

class PitchScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            stepIndex: this.props.selectedStep || 0,
            showError: false,
            showSuccessModal: false,
            showFailureModal: false,
            showHelpModal: false,
            finishedInitialLoad: false,
            loadedCount: 0,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        if (!this.props.selectedAlbumRelease) {
            this.props.history.push(paths.newReleases);
            return;
        } else {
            const albumCatalogItem = this.props.catalog.get(
                this.props.selectedAlbumRelease.titlesetAsin
            ) as album;
            if (albumCatalogItem) {
                this.setState({ albumCatalogItem: albumCatalogItem });
            }
        }

        this.getPitchData();

        this.props.userAction({
            name: `${metricPrefix}View`,
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtistAsin],
                [METRIC_KEYS.page, pagePath],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            this.state.finishedInitialLoad === false &&
            !this.props.inProgress &&
            !this.props.hydrationInProgress &&
            (prevProps.inProgress || prevProps.hydrationInProgress)
        ) {
            this.setState({
                finishedInitialLoad: true,
            });
        }

        if (this.props.error !== prevProps.error) {
            this.setState({
                showError: this.props.error !== undefined,
            });
        }
        if (
            this.state.jumpToStep !== undefined &&
            this.state.jumpToStep !== prevState.jumpToStep
        ) {
            this.props.selectStep(this.state.jumpToStep);
        }

        if (this.state.stepIndex !== prevState.stepIndex) {
            window.scrollTo(0, 0);
        }

        if (
            !this.props.createPitchInProgress &&
            prevProps.createPitchInProgress
        ) {
            const createPitchSucess =
                this.props.createdPitchSuccess !== undefined;
            this.setState({
                showSuccessModal: createPitchSucess,
                showFailureModal: !createPitchSucess,
            });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.loadMore();
        }
    }

    render() {
        this.updateStepFormEnable();
        if (!this.state.finishedInitialLoad) return <Loading />;
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.genericError,
                            })}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {this.pitchForm()}

                <FullScreenConfirmationModal
                    id={`${testIDPrefix}-SuccessModal`}
                    icon={IconsList.action_doneInline}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.createPitchSuccessTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.createPitchSuccessMessage,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId:
                                stringIds.Pitch.createPitchSuccessDoneButton,
                        }
                    )}
                    confirmButtonAction={this.onSuccess}
                    onDismiss={this.onSuccess}
                    isVisible={this.state.showSuccessModal}
                    buttonOrientation={"stacked"}
                    footer={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.createPitchSucesssFooter,
                    })}
                />

                <FullScreenConfirmationModal
                    id={`${testIDPrefix}-FailureModal`}
                    icon={IconsList.ic_important}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.createPitchFailureTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.createPitchFailureMessage,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId:
                                stringIds.Pitch
                                    .createPitchFailureTryAgainButton,
                        }
                    )}
                    confirmButtonAction={this.submitPitch}
                    cancelButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId:
                            stringIds.Pitch.createPitchFailureGoBackButton,
                    })}
                    cancelButtonAction={() =>
                        this.setState({
                            showFailureModal: false,
                            showError: false,
                        })
                    }
                    onDismiss={() =>
                        this.setState({
                            showFailureModal: false,
                            showError: false,
                        })
                    }
                    isVisible={this.state.showFailureModal}
                    buttonOrientation={"inline"}
                />

                {pitchHelpModal(
                    this.state.showHelpModal,
                    `${testIDPrefix}_HelpModal`,
                    this.dismissHelpModal,
                    this.dismissHelpModal,
                    this.props.bundleMap,
                    () =>
                        window.open(
                            getLocalizedUrl(LocalizedUrls.PitchLearnMoreURL),
                            "_blank"
                        )
                )}
            </Container>
        );
    }

    private pitchAlbumHeader() {
        if (!this.props.selectedAlbumRelease) {
            return;
        }
        let profileHeadProps: ProfileHeadProps = {
            imageSource: "",
            imageSize: ProfileImageSize.Large,
            title: "",
            subtitle: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.albumsLabel,
            }),
            id: `${testIDPrefix}-${testIDSuffixes.profileImage}`,
            hideDatePicker: true,
        };

        const album = this.state.albumCatalogItem;

        if (album) {
            const artistNames = concatenateArtistNames(
                album.artistAsins,
                this.props.catalog,
                true
            );

            const releaseDate = formatDate(
                new Date(this.props.selectedAlbumRelease.releaseDate)
            );

            profileHeadProps = {
                ...profileHeadProps,
                imageSource:
                    album.images && album.images.artLarge
                        ? album.images.artLarge
                        : "",
                fallbackImage: ImageList.placeholder_album,
                title: album.title || "",
                imageDescription: album.title,
                artistName: artistNames,
                note: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.pitchAlbumReleaseDate,
                    },
                    { "0": releaseDate }
                ),
                callback: this.onReportIssue,
                callbackString: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                    stringId:
                        stringIds.ArtistDisambiguation
                            .reportArtistProfileIssueTitle,
                }),
            };
        }

        return (
            <AlbumHeaderContainer>
                {album && <ProfileHead {...profileHeadProps} />}
            </AlbumHeaderContainer>
        );
    }

    private pitchForm() {
        switch (this.state.stepIndex) {
            case PitchFormStep.CHOOSE_TRACK:
                return (
                    <>
                        {this.pitchAlbumHeader()}
                        {this.FormNavigation()}
                        {this.chooseTrackTable()}
                    </>
                );
            case PitchFormStep.THE_BASICS:
                return (
                    <>
                        {this.PitchASongHeader()}
                        {this.SelectedSongHeader()}
                        {this.FormNavigation()}
                        <PitchBasics
                            genres={this.props.pitchFormData?.genres || []}
                            kindOfSong={
                                this.props.pitchFormData?.compositions || []
                            }
                            languages={
                                (this.props.pitchFormData &&
                                    sortTagsByName(
                                        this.props.pitchFormData?.languages
                                    )) ||
                                []
                            }
                        />
                        {this.footer(
                            this.footerBackButton,
                            this.footerNextButton,
                            getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formNext,
                            }),
                            !pitchFormSteps[PitchFormStep.THE_BASICS + 1]
                                .enabled,
                            false
                        )}
                    </>
                );
            case PitchFormStep.MORE_INFO:
                return (
                    <>
                        {this.PitchASongHeader()}
                        {this.SelectedSongHeader()}
                        {this.FormNavigation()}
                        <PitchMoreInfo
                            songMoods={this.props.pitchFormData?.moods || []}
                            songStyles={this.props.pitchFormData?.themes || []}
                            relatedArtists={this.props.relatedArtists || []}
                            locations={
                                (this.props.pitchFormData &&
                                    sortTagsByName(
                                        this.props.pitchFormData?.countries
                                    )) ||
                                []
                            }
                        />
                        {this.footer(
                            this.footerBackButton,
                            this.footerNextButton,
                            getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formNext,
                            }),
                            !pitchFormSteps[PitchFormStep.MORE_INFO + 1]
                                .enabled,
                            false
                        )}
                    </>
                );
            case PitchFormStep.REVIEW:
                return (
                    <>
                        {this.PitchASongHeader()}
                        {this.SelectedSongHeader()}
                        {this.FormNavigation()}
                        <PitchReview
                            onEditButtonClick={() =>
                                this.setState({
                                    jumpToStep: PitchFormStep.THE_BASICS,
                                })
                            }
                        />
                        {this.footer(
                            this.footerBackButton,
                            this.submitPitch,
                            getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formSubmit,
                            }),
                            this.props.isPrivileged ||
                                this.props.createPitchInProgress,
                            this.props.createPitchInProgress
                        )}
                    </>
                );
            default:
                return null;
        }
    }

    private chooseTrackTable = () => {
        if (!this.props.selectedAlbumRelease || !this.props.tracksToPitch) {
            return;
        }

        const tracks: TableRowProps[] = generateSongRowsForPitch(
            this.props.tracksToPitch,
            this.props.catalog,
            this.props.teamId,
            this.props.hydratingAsins,
            this.onSelectTrack,
            pitchTrackRightElement,
            this.props.isPrivileged,
            `${testIDPrefix}-chooseTrack`,
            this.props.bundleMap,
            false,
            true,
            true
        );

        const chooseTrackHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.songsLabel,
            }),
            "",
            "",
        ];

        return (
            <>
                <Row style={{ marginTop: rootStyles.spacers.large }}>
                    <Col>
                        <Row style={{ alignItems: "center" }}>
                            <styledTitle.h4>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.PITCH_STRINGS,
                                    stringId: stringIds.Pitch.pitchTrackTitle,
                                })}
                            </styledTitle.h4>
                            <Icon
                                icon={IconsList.ic_help}
                                size={16}
                                style={{
                                    opacity: rootStyles.glass._4,
                                    marginLeft: rootStyles.spacers.mini,
                                }}
                                onClick={() =>
                                    this.setState({ showHelpModal: true })
                                }
                                id={`${testIDPrefix}_HelpIcon`}
                            />
                        </Row>
                        <Row>
                            <span style={rootStyles.textStyles.secondary}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.PITCH_STRINGS,
                                    stringId:
                                        stringIds.Pitch.pitchTrackSubtitle,
                                })}
                            </span>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table
                            rows={tracks}
                            labels={chooseTrackHeader}
                            loadMore={this.loadMore}
                            scrolling={true}
                            footer={
                                this.props.tracksToPitch.length <=
                                this.state.loadedCount ? (
                                    <div
                                        style={{
                                            height: rootStyles.spacers.huge,
                                        }}
                                    />
                                ) : (
                                    <IncrementalLoading />
                                )
                            }
                            id={`${testIDPrefix}_TrackToPitchTable`}
                            showIndex={true}
                        />
                    </Col>
                </Row>
            </>
        );
    };

    private PitchASongHeader = () => {
        return (
            <CommonHeader
                title={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.PITCH_STRINGS,
                    stringId: stringIds.Pitch.formPitchASongHeaderTitle,
                })}
                subtitle={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.PITCH_STRINGS,
                    stringId: stringIds.Pitch.formPitchAsongHeaderSubtitle,
                })}
                id={`${testIDPrefix}_Header`}
                hideDatePicker={true}
            />
        );
    };

    private SelectedSongHeader = () => {
        const track = this.props.selectedTrack;

        if (track) {
            const artistNames = concatenateArtistNames(
                track.artistAsins,
                this.props.catalog,
                true
            );

            const TableRowProps = generateNewReleaseRow(
                track,
                undefined,
                undefined,
                artistNames,
                undefined,
                `${testIDPrefix}-SelectedSongHeader`,
                false
            );
            return <TableRow {...TableRowProps} />;
        }

        return undefined;
    };

    private FormNavigation = () => {
        return (
            <Row>
                <Col style={{ marginTop: rootStyles.spacers.mini }}>
                    <FormNavigation
                        stepList={pitchFormSteps}
                        onChange={(index) =>
                            this.setState({
                                stepIndex: index,
                                jumpToStep: index,
                            })
                        }
                        defaultValue={this.state.stepIndex}
                        id={testIDPrefix}
                        jumpToStep={this.state.jumpToStep}
                    />
                </Col>
            </Row>
        );
    };

    private onSelectTrack = (asin: string) => {
        this.setState({
            jumpToStep: PitchFormStep.THE_BASICS,
        });
        this.props.selectTrack(asin);
    };

    private onReportIssue = () => {
        this.props.selectReportIssueItem(this.state.albumCatalogItem || {});
        this.props.history.push(paths.reportArtistProfile);
    };

    private footer = (
        leftButtonClick: () => void,
        rightButtonClick: () => void,
        rightButtonText: string,
        rightButtonDisabled: boolean,
        leftButtonDisabled: boolean
    ) => {
        return (
            <Footer
                show={true}
                rightButtonDisabled={rightButtonDisabled}
                leftButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.PITCH_STRINGS,
                    stringId: stringIds.Pitch.formBack,
                })}
                rightButtonText={rightButtonText}
                onLeftButtonClick={leftButtonClick}
                onRightButtonClick={rightButtonClick}
                leftButtonDisabled={leftButtonDisabled}
                id={`${testIDPrefix}-NavigationFooter`}
            />
        );
    };

    private footerBackButton = () => {
        if (this.state.stepIndex && this.state.stepIndex > 0) {
            this.setState({ jumpToStep: this.state.stepIndex - 1 });
        }
    };

    private footerNextButton = () => {
        if (
            this.state.stepIndex &&
            this.state.stepIndex < pitchFormSteps.length - 1
        ) {
            this.setState({ jumpToStep: this.state.stepIndex + 1 });
        }
    };

    private updateStepFormEnable = () => {
        if (this.props.selectedTrack !== undefined) {
            pitchFormSteps[PitchFormStep.THE_BASICS].enabled = true;
        } else {
            pitchFormSteps[PitchFormStep.THE_BASICS].enabled = false;
        }

        const basicsConditions: boolean[] = [];
        basicsConditions.push(
            this.props.description !== undefined &&
                this.props.description.trim() !== ""
        );

        basicsConditions.push(
            this.props.genresProps &&
                selectedGroupItemsToTags(this.props.genresProps).length > 0
        );

        basicsConditions.push(
            this.props.compositionsProps &&
                selectedItemsPropsToTags(this.props.compositionsProps).length >
                    0
        );

        basicsConditions.push(this.props.songHasVocals !== undefined);

        basicsConditions.push(
            (this.props.languagesProps &&
                selectedGroupItemsToTags(this.props.languagesProps).length >
                    0) ||
                this.props.songHasVocals === false
        );

        if (
            _.reduce(
                basicsConditions,
                (prev: boolean, curr: boolean) => prev && curr,
                true
            )
        ) {
            pitchFormSteps[PitchFormStep.MORE_INFO].enabled = true;
            pitchFormSteps[PitchFormStep.REVIEW].enabled = true;
        } else {
            pitchFormSteps[PitchFormStep.MORE_INFO].enabled = false;
            pitchFormSteps[PitchFormStep.REVIEW].enabled = false;
        }
    };

    private submitPitch = () => {
        if (
            !this.props.selectedTrack ||
            !this.props.selectedTrack.asin ||
            !this.props.teamId ||
            !this.props.selectedAlbumRelease
        ) {
            return;
        }

        const locationsCodeList = tagListToCodeList(
            selectedGroupItemsToTags(this.props.locationProps)
        );

        const newPlaylistPitchInfo: newPlaylistPitchInfo = {
            genres: tagListToCodeList(
                selectedGroupItemsToTags(this.props.genresProps)
            ),
            moods: tagListToCodeList(
                selectedItemsPropsToTags(this.props.songMoodsProps)
            ),
            themes: tagListToCodeList(
                selectedItemsPropsToTags(this.props.songStylesProps)
            ),
            compositions: tagListToCodeList(
                selectedItemsPropsToTags(this.props.compositionsProps)
            ),
            relatedArtistAsins: tagListToCodeList(
                selectedGroupItemsToTags(this.props.relatedArtistsProps)
            ),
            languages: tagListToCodeList(
                selectedGroupItemsToTags(this.props.languagesProps)
            ),
            artistRootCountry:
                locationsCodeList.length > 0 ? locationsCodeList[0] : undefined,
            description: this.props.description.trim(),
        };

        const createPitchRequest: createPitchRequest = {
            artistAsin: this.props.selectedArtistAsin,
            albumTitleSetAsin: this.props.selectedAlbumRelease.titlesetAsin,
            albumGlobalAsin: this.props.selectedAlbumRelease.globalAsin,
            trackTitleSetAsin: this.props.selectedTrack.asin,
            trackGlobalAsin: this.props.selectedTrack.globalAsin,
            pitchType: PitchType.NewPlaylistPitch,
            newPlaylistPitchInfo: newPlaylistPitchInfo,
        };

        const createPitchPayload: createPitchPayload = {
            request: createPitchRequest,
            teamId: this.props.teamId,
            requestPath: pagePath,
        };

        this.props.createPitch(createPitchPayload);

        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Pitch.submitPitch,
                pageIds.pitch,
                this.props.selectedTrack.asin,
                EntityType.Track
            )
        );
    };

    private onSuccess = () => {
        this.props.clearPitchForm();
        this.props.history.push(paths.newReleases);
        this.updateAlbumReleases();
    };

    private dismissHelpModal = () => {
        this.setState({ showHelpModal: false });
    };

    private updateAlbumReleases = () => {
        const getAlbumsToPitchPayload: listAlbumsToPitchPayload = {
            artistAsin: this.props.selectedArtistAsin,
            teamId: this.props.teamId,
            requestPath: pagePath,
            locale: this.props.userLocale,
        };
        this.props.getAlbumReleases(getAlbumsToPitchPayload);
    };

    private getPitchData = () => {
        const getTracksToPitchPayload: listTracksToPitchPayload = {
            artistAsin: this.props.selectedArtistAsin,
            albumTitlesetAsin: this.props.selectedAlbumRelease.titlesetAsin,
            albumGlobalAsin: this.props.selectedAlbumRelease.globalAsin,
            teamId: this.props.teamId,
            requestPath: pagePath,
        };
        this.props.getTracksToPitch(getTracksToPitchPayload);

        const getRelatedArtistsPayload: listRelatedArtistsPayload = {
            artistAsin: this.props.selectedArtistAsin,
            teamId: this.props.teamId,
            requestPath: pagePath,
        };
        this.props.getRelatedArtists(getRelatedArtistsPayload);

        const getPitchFormDataPayload: getPitchFormDataPayload = {
            pitchType: PitchType.NewPlaylistPitch,
            locale: this.props.userLocale,
            requestPath: pagePath,
        };
        this.props.getPitchFormData(getPitchFormDataPayload);
    };

    private loadMore = () => {
        const nextCount: number = hydrationHelpers.hydrateAsinsBatch(
            this.state.loadedCount,
            _.map(this.props.tracksToPitch, (trackToPitch) => {
                return {
                    asin: trackToPitch.titlesetAsin,
                    globalAsin: trackToPitch.globalAsin,
                };
            }),
            this.props.hydrateAsins,
            CatalogItemType.Tracks,
            this.props.userLocale
        );

        this.setState({ loadedCount: nextCount });
    };
}

const AlbumHeaderContainer = styled.div`
    @media (max-width: 992px) {
        margin-top: 80px;
    }
`;

function mapStateToProps(state: RootState): StateProps {
    return pitchSelector(state, pagePath);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        selectReportIssueItem: (payload: baseMediaItem) =>
            dispatch(artistIssueActions.selectReportIssueItem(payload)),
        selectTrack: (payload: string) =>
            dispatch(pitchFormActions.selectTrack(payload)),
        selectStep: (payload: number) =>
            dispatch(pitchFormActions.selectStep(payload)),
        getTracksToPitch: (payload: listTracksToPitchPayload) =>
            dispatch(pitchActions.listTracksToPitch(payload)),
        clearPitchForm: () => dispatch(pitchFormActions.clearAll()),
        getRelatedArtists: (payload: listRelatedArtistsPayload) =>
            dispatch(pitchActions.listRelatedArtists(payload)),
        getPitchFormData: (payload: getPitchFormDataPayload) =>
            dispatch(pitchActions.getPitchFormData(payload)),
        createPitch: (payload: createPitchPayload) =>
            dispatch(pitchActions.createPitch(payload)),
        getAlbumReleases: (payload: listAlbumsToPitchPayload) =>
            dispatch(pitchActions.listAlbumsToPitch(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PitchScreen)
);
