import React, { Dispatch, useCallback, useEffect, useState } from "react";
import {
    ArtistMarqueePlaylists,
    BundleMap,
    CardLayout,
    EntityType,
    baseMediaItem,
    promoCardSelectedContent,
} from "../../../models";
import { useSelector } from "react-redux";

import { RootState } from "../../../store";
import * as rootStyles from "../../../view/styles";
import {
    LogoType,
    PromoCardConstants,
    PromoCardLogoColor,
    backgroundColorMapping,
    borderGradientSourceList,
    getCardLogo,
    getLocalizedString,
    getLocalizedStringFromLocale,
} from "../../../utils";
import { bundleIds, stringIds } from "@amzn/ziggy-asset";

type CardGeneratorProps = {
    artistName: string;
    artistImage?: string;
    selectedContent?: promoCardSelectedContent;
    marqueePlaylists: ArtistMarqueePlaylists;
    bundleMap: BundleMap;
};

const testIDPrefix = "CardGenerator";

// Card is generated on the exact sizing based on spec
// https://amazonmusic.app.box.com/s/2f1nfi1bzn5p2mid8g4bagxkt85s1fqv
// Playlist card is generated on th exact sizing & spacing based on spec
// https://amazonmusic.app.box.com/s/4qgwphasd8ms1hoc0uu2tghaxc89dxhd
export const CardGenerator: React.FC<CardGeneratorProps> = ({
    artistName,
    artistImage,
    selectedContent,
    marqueePlaylists,
    bundleMap,
}) => {
    const selectedEntity = useSelector(
        (state: RootState) => state.promoCard.selectedEntity
    );
    const cardLayout = useSelector(
        (state: RootState) => state.promoCard.selectedCardLayout
    );
    const cardColor = useSelector(
        (state: RootState) => state.promoCard.selectedCardColor
    );

    const isDark: boolean =
        backgroundColorMapping.get(cardColor)?.isTextDark || false;

    const textColor = isDark
        ? rootStyles.colors.background
        : rootStyles.colors.primary;

    const cardLocale = useSelector(
        (state: RootState) => state.promoCard.selectedCardLocale
    );

    const [artistTypeString, setArtistTypeString] = useState<string>(
        getLocalizedString(bundleMap, {
            bundleId: bundleIds.PROMO_CARD_STRINGS,
            stringId: stringIds.PromoCard.typeArtist,
        })
    );
    const [playlistTypeString, setPlaylistTypeString] = useState<string>(
        getLocalizedString(bundleMap, {
            bundleId: bundleIds.PROMO_CARD_STRINGS,
            stringId: stringIds.PromoCard.typePlaylist,
        })
    );
    const [songTypeString, setSongTypeString] = useState<string>(
        getLocalizedString(bundleMap, {
            bundleId: bundleIds.PROMO_CARD_STRINGS,
            stringId: stringIds.PromoCard.typeSong,
        })
    );
    const [albumTypeString, setAlbumTypeString] = useState<string>(
        getLocalizedString(bundleMap, {
            bundleId: bundleIds.PROMO_CARD_STRINGS,
            stringId: stringIds.PromoCard.typeAlbum,
        })
    );

    useEffect(() => {
        getLocalizedStringFromLocale(cardLocale, {
            bundleId: bundleIds.PROMO_CARD_STRINGS,
            stringId: stringIds.PromoCard.typeArtist,
        }).then((stringValue) => {
            setArtistTypeString(stringValue);
        });

        getLocalizedStringFromLocale(cardLocale, {
            bundleId: bundleIds.PROMO_CARD_STRINGS,
            stringId: stringIds.PromoCard.typePlaylist,
        }).then((stringValue) => {
            setPlaylistTypeString(stringValue);
        });

        getLocalizedStringFromLocale(cardLocale, {
            bundleId: bundleIds.PROMO_CARD_STRINGS,
            stringId: stringIds.PromoCard.typeSong,
        }).then((stringValue) => {
            setSongTypeString(stringValue);
        });

        getLocalizedStringFromLocale(cardLocale, {
            bundleId: bundleIds.PROMO_CARD_STRINGS,
            stringId: stringIds.PromoCard.typeAlbum,
        }).then((stringValue) => {
            setAlbumTypeString(stringValue);
        });
    }, [cardLocale]);

    const getPromoCardTitle = (entityType: string | undefined): string => {
        switch (entityType) {
            case EntityType.ARTIST:
                return artistTypeString;
            case EntityType.Track:
                return songTypeString;
            case EntityType.Album:
                return albumTypeString;
            case EntityType.Playlist:
                return playlistTypeString;
            default:
                return artistTypeString;
        }
    };

    return (
        <>
            {/* Portrait Layout */}
            {cardLayout === CardLayout.Portrait ? (
                <div
                    id={`${testIDPrefix}_PortraitContainer`}
                    style={{
                        ...parentContainerStyle,
                        width: `${PromoCardConstants.PORTRAIT_WIDTH}px`,
                        height: `${PromoCardConstants.PORTRAIT_HEIGHT}px`,
                        backgroundImage: `url(${
                            selectedContent?.type !== EntityType.Playlist
                                ? backgroundColorMapping.get(cardColor)
                                      ?.portraitGradient
                                : ""
                        })`,
                        border: 1,
                        borderStyle:
                            borderGradientSourceList.includes(cardColor) &&
                            selectedContent?.type !== EntityType.Playlist
                                ? "solid"
                                : "none",
                        borderColor: rootStyles.glassColors.primary4,
                    }}
                >
                    {selectedContent?.type === EntityType.Playlist &&
                    selectedContent.contentContainerAsin ? (
                        <div
                            id={`${testIDPrefix}_PortraitContainer_PlaylistType`}
                            style={promoCardContainerStyle}
                        >
                            <img
                                id={`${testIDPrefix}_PortraitContainer_PlaylistType_ArtistImage`}
                                src={artistImage}
                                style={playlistImageStyle}
                            />
                            <img
                                id={`${testIDPrefix}_PortraitContainer_PlaylistType_PlaylistImage`}
                                src={`${marqueePlaylists.playlistFaceoutBaseUrl}${marqueePlaylists.portraitUrlSuffix}${selectedContent.playlistImageName}`}
                                style={playlistImageStyle}
                            />
                            <div>
                                <img
                                    id={`${testIDPrefix}_PortraitContainer_PlaylistType_AMLogo`}
                                    src={getCardLogo({
                                        locale: cardLocale,
                                        logoType: LogoType.LISTEN,
                                        isDark:
                                            selectedContent.playlistLogoColor ===
                                            PromoCardLogoColor.BLACK,
                                        cardLayout: CardLayout.Portrait,
                                    })}
                                    style={{
                                        ...playlistLogoStyle,
                                        bottom: "117px",
                                        left: "355px",
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                    {!selectedContent ||
                    selectedContent.type === EntityType.ARTIST ? (
                        <div
                            id={`${testIDPrefix}_PortraitContainer_ArtistType`}
                            style={promoCardContainerStyle}
                        >
                            <div
                                style={{
                                    ...portraitCardContentStyle,
                                    color: textColor,
                                }}
                            >
                                <div
                                    id={`${testIDPrefix}_PortraitContainer_ArtistType_Title`}
                                    style={portraitCardEntityStyle}
                                >
                                    {getPromoCardTitle(selectedContent?.type)}
                                </div>
                                <div
                                    id={`${testIDPrefix}_PortraitContainer_ArtistType_ArtistName`}
                                    style={portraitArtistCardTitleStyle}
                                >
                                    {artistName}
                                </div>
                                <div
                                    id={`${testIDPrefix}_PortraitContainer_ArtistType_ArtistImage`}
                                    style={portraitImageContainerStyle}
                                >
                                    <img
                                        style={{
                                            ...promoCardImageStyle,
                                            borderRadius: "421px",
                                        }}
                                        src={artistImage}
                                    />
                                </div>
                            </div>

                            <div style={portraitCardLogoStyle}>
                                <img
                                    id={`${testIDPrefix}_PortraitContainer_ArtistType_AMLogo`}
                                    src={getCardLogo({
                                        locale: cardLocale,
                                        logoType: LogoType.FOLLOW,
                                        isDark: isDark,
                                        cardLayout: CardLayout.Portrait,
                                    })}
                                />
                            </div>
                        </div>
                    ) : null}
                    {selectedContent?.type === EntityType.Track ||
                    selectedContent?.type === EntityType.Album ? (
                        <div
                            id={`${testIDPrefix}_PortraitContainer_CatalogType`}
                            style={promoCardContainerStyle}
                        >
                            <div
                                style={{
                                    ...portraitCardContentStyle,
                                    color: textColor,
                                }}
                            >
                                <div
                                    id={`${testIDPrefix}_PortraitContainer_CatalogType_CardTitle`}
                                    style={portraitCardEntityStyle}
                                >
                                    {getPromoCardTitle(selectedContent?.type)}
                                </div>
                                <div
                                    id={`${testIDPrefix}_PortraitContainer_CatalogType_ContentTitle`}
                                    style={portraitAlbumTrackCardTitleStyle}
                                >
                                    {selectedContent.title}
                                </div>
                                <div
                                    id={`${testIDPrefix}_PortraitContainer_CatalogType_ArtistName`}
                                    style={portraitAlbumTrackCardSubtTitleStyle}
                                >
                                    {artistName}
                                </div>
                                <div
                                    style={{
                                        ...portraitImageContainerStyle,
                                        marginTop: "90px",
                                    }}
                                >
                                    <img
                                        id={`${testIDPrefix}_PortraitContainer_CatalogType_ContentImage`}
                                        style={{
                                            ...promoCardImageStyle,
                                            borderRadius: "48px",
                                        }}
                                        src={
                                            selectedContent.images
                                                ?.artExtraLarge
                                        }
                                    />
                                </div>
                            </div>

                            <div style={portraitCardLogoStyle}>
                                <img
                                    id={`${testIDPrefix}_PortraitContainer_CatalogType_AMLogo`}
                                    src={getCardLogo({
                                        locale: cardLocale,
                                        logoType: LogoType.LISTEN,
                                        isDark: isDark,
                                        cardLayout: CardLayout.Portrait,
                                    })}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}

            {/* Square Layout */}
            {cardLayout === CardLayout.Square ? (
                <div
                    id={`${testIDPrefix}_SquareContainer`}
                    style={{
                        ...parentContainerStyle,
                        width: `${PromoCardConstants.SQUARE_WIDTH}px`,
                        height: `${PromoCardConstants.SQUARE_HEIGHT}px`,
                        backgroundImage: `url(${
                            selectedContent?.type !== EntityType.Playlist
                                ? backgroundColorMapping.get(cardColor)
                                      ?.sqaureGradient
                                : ""
                        })`,
                        border: 1,
                        borderStyle:
                            borderGradientSourceList.includes(cardColor) &&
                            selectedContent?.type !== EntityType.Playlist
                                ? "solid"
                                : "none",
                        borderColor: rootStyles.glassColors.primary4,
                    }}
                >
                    {selectedContent?.type === EntityType.Playlist &&
                    selectedContent.contentContainerAsin ? (
                        <div
                            id={`${testIDPrefix}_SquareContainer_PlaylistType`}
                            style={promoCardContainerStyle}
                        >
                            <img
                                id={`${testIDPrefix}_SquareContainer_PlaylistType_ArtistImage`}
                                src={artistImage}
                                style={playlistImageStyle}
                            />
                            <img
                                id={`${testIDPrefix}_SquareContainer_PlaylistType_PlaylistImage`}
                                src={`${marqueePlaylists.playlistFaceoutBaseUrl}${marqueePlaylists.squareUrlSuffix}${selectedContent.playlistImageName}`}
                                style={playlistImageStyle}
                            />
                            <div>
                                <img
                                    id={`${testIDPrefix}_SquareContainer_PlaylistType_AMLogo`}
                                    src={getCardLogo({
                                        locale: cardLocale,
                                        logoType: LogoType.LISTEN,
                                        isDark:
                                            selectedContent.playlistLogoColor ===
                                            PromoCardLogoColor.BLACK,
                                        cardLayout: CardLayout.Square,
                                    })}
                                    style={{
                                        ...playlistLogoStyle,
                                        bottom: "11px",
                                        left: "355px",
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                    {!selectedContent ||
                    selectedContent.type === EntityType.ARTIST ? (
                        <div
                            id={`${testIDPrefix}_SquareContainer_ArtistType`}
                            style={promoCardContainerStyle}
                        >
                            <div
                                style={{
                                    ...squareCardContentStyle,
                                    color: textColor,
                                }}
                            >
                                <div
                                    id={`${testIDPrefix}_SquareContainer_ArtistType_Title`}
                                    style={squareCardEntityStyle}
                                >
                                    {getPromoCardTitle(selectedContent?.type)}
                                </div>
                                <div
                                    id={`${testIDPrefix}_SquareContainer_ArtistType_ArtistName`}
                                    style={squareArtistCardTitleStyle}
                                >
                                    {artistName}
                                </div>
                                <div
                                    id={`${testIDPrefix}_SquareContainer_ArtistImage`}
                                    style={squareArtistImageContainerStyle}
                                >
                                    <img
                                        style={{
                                            ...promoCardImageStyle,
                                            borderRadius: "274px",
                                        }}
                                        src={artistImage}
                                    />
                                </div>
                            </div>

                            <div style={squareCardLogoStyle}>
                                <img
                                    id={`${testIDPrefix}_SquareContainer_ArtistType_AMLogo`}
                                    src={getCardLogo({
                                        locale: cardLocale,
                                        logoType: LogoType.FOLLOW,
                                        isDark: isDark,
                                        cardLayout: CardLayout.Square,
                                    })}
                                />
                            </div>
                        </div>
                    ) : null}
                    {selectedContent?.type === EntityType.Track ||
                    selectedContent?.type === EntityType.Album ? (
                        <div
                            id={`${testIDPrefix}_SquareContainer_CatalogType`}
                            style={promoCardContainerStyle}
                        >
                            <div
                                style={{
                                    ...squareCardContentStyle,
                                    color: textColor,
                                }}
                            >
                                <div
                                    id={`${testIDPrefix}_SquareContainer_CatalogType_Title`}
                                    style={squareCardEntityStyle}
                                >
                                    {getPromoCardTitle(selectedContent?.type)}
                                </div>
                                <div
                                    id={`${testIDPrefix}_SquareContainer_CatalogType_ContentTitle`}
                                    style={squareAlbumTrackCardTitleStyle}
                                >
                                    {selectedContent.title}
                                </div>
                                <div
                                    id={`${testIDPrefix}_SquareContainer_CatalogType_ArtistName`}
                                    style={squareAlbumTrackCardSubtTitleStyle}
                                >
                                    {artistName}
                                </div>
                                <div
                                    style={squareTrakcAlbumImageContainerStyle}
                                >
                                    <img
                                        id={`${testIDPrefix}_SquareContainer_CatalogType_ContentImage`}
                                        style={{
                                            ...promoCardImageStyle,
                                            borderRadius: "48px",
                                        }}
                                        src={
                                            selectedContent.images
                                                ?.artExtraLarge
                                        }
                                    />
                                </div>
                            </div>

                            <div style={squareCardLogoStyle}>
                                <img
                                    id={`${testIDPrefix}_SquareContainer_CatalogType_AMLogo`}
                                    src={getCardLogo({
                                        locale: cardLocale,
                                        logoType: LogoType.LISTEN,
                                        isDark: isDark,
                                        cardLayout: CardLayout.Square,
                                    })}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}

            {/* Landscape Layout */}
            {cardLayout === CardLayout.Landscape ? (
                <div
                    id={`${testIDPrefix}_LandscapeContainer`}
                    style={{
                        ...parentContainerStyle,
                        width: `${PromoCardConstants.LANDSCAPE_WIDTH}px`,
                        height: `${PromoCardConstants.LANDSCAPE_HEIGHT}px`,
                        backgroundImage: `url(${
                            selectedContent?.type !== EntityType.Playlist
                                ? backgroundColorMapping.get(cardColor)
                                      ?.landscapeGradient
                                : ""
                        })`,
                        border: 1,
                        borderStyle:
                            borderGradientSourceList.includes(cardColor) &&
                            selectedContent?.type !== EntityType.Playlist
                                ? "solid"
                                : "none",
                        borderColor: rootStyles.glassColors.primary4,
                    }}
                >
                    {selectedContent?.type === EntityType.Playlist &&
                    selectedContent.contentContainerAsin ? (
                        <div
                            id={`${testIDPrefix}_LandscapeContainer_PlaylistType`}
                        >
                            <img
                                id={`${testIDPrefix}_LandscapeContainer_PlaylistType_ArtistImage`}
                                src={artistImage}
                                style={
                                    selectedContent.landscapeReversed
                                        ? landscapePlaylistRightImageStyle
                                        : landscapePlaylistImageStyle
                                }
                            />
                            <img
                                id={`${testIDPrefix}_LandscapeContainer_PlaylistType_PlaylistImage`}
                                src={`${marqueePlaylists.playlistFaceoutBaseUrl}${marqueePlaylists.landscapeUrlSuffix}${selectedContent.playlistImageName}`}
                                style={landscapePlaylistImageStyle}
                            />
                            <div>
                                <img
                                    id={`${testIDPrefix}_LandscapeContainer_PlaylistType_AMLogo`}
                                    src={getCardLogo({
                                        locale: cardLocale,
                                        logoType: LogoType.LISTEN,
                                        isDark:
                                            selectedContent.playlistLogoColor ===
                                            PromoCardLogoColor.BLACK,
                                        // All playlist landscape logo still used the center logo same as Portrait and Square
                                        // Thus using the portrait listen logo asset here
                                        cardLayout: CardLayout.Portrait,
                                    })}
                                    style={{
                                        ...playlistLogoStyle,
                                        left: selectedContent.landscapeReversed
                                            ? 235
                                            : "auto",
                                        right: selectedContent.landscapeReversed
                                            ? "auto"
                                            : 235,
                                        bottom: "117px",
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                    {!selectedContent ||
                    selectedContent.type === EntityType.ARTIST ? (
                        <div
                        id={`${testIDPrefix}_LandscapeContainer_ArtistType`}
                            style={{
                                ...promoCardContainerStyle,
                                flexDirection: "row",
                            }}
                        >
                            <div style={landscapeArtistImageContainerStyle}>
                                <img
                                    id={`${testIDPrefix}_LandscapeContainer_ArtistType_ArtistImage`}
                                    style={{
                                        ...promoCardImageStyle,
                                        borderRadius: "394px",
                                    }}
                                    src={artistImage}
                                />
                            </div>
                            <div
                                style={{
                                    ...landscapeCardContentStyle,
                                    color: textColor,
                                }}
                            >
                                <div
                                    id={`${testIDPrefix}_LandscapeContainer_ArtistType_Title`}
                                     style={landscapeCardEntityStyle}>
                                    {getPromoCardTitle(selectedContent?.type)}
                                </div>
                                <div
                                    id={`${testIDPrefix}_LandscapeContainer_ArtistType_ArtistName`} 
                                    style={landscapeArtistCardTitleStyle}>
                                    {artistName}
                                </div>
                                <div
                                    style={{
                                        bottom: "115px",
                                        left: "978px",
                                        position: "absolute",
                                    }}
                                >
                                    <img
                                        id={`${testIDPrefix}_LandscapeContainer_ArtistType_AMLogo`}
                                        src={getCardLogo({
                                            locale: cardLocale,
                                            logoType: LogoType.FOLLOW,
                                            isDark: isDark,
                                            cardLayout: CardLayout.Landscape,
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {selectedContent?.type === EntityType.Track ||
                    selectedContent?.type === EntityType.Album ? (
                        <div
                            id={`${testIDPrefix}_LandscapeContainer_CatalogType`}
                            style={{
                                ...promoCardContainerStyle,
                                flexDirection: "row",
                            }}
                        >
                            <div
                                id={`${testIDPrefix}_LandscapeContainer_CatalogType_ContentImage`}
                                style={landscapeArtistImageContainerStyle}>
                                <img
                                    style={{
                                        ...promoCardImageStyle,
                                        borderRadius: 48,
                                    }}
                                    src={selectedContent.images?.artExtraLarge}
                                />
                            </div>
                            <div
                                style={{
                                    ...landscapeCardContentStyle,
                                    color: textColor,
                                }}
                            >
                                <div
                                    id={`${testIDPrefix}_LandscapeContainer_CatalogType_Title`} 
                                    style={landscapeCardEntityStyle}>
                                    {getPromoCardTitle(selectedContent?.type)}
                                </div>
                                <div
                                    id={`${testIDPrefix}_LandscapeContainer_CatalogType_ContentTitle`} 
                                    style={landscapeAlbumTrackCardTitleStyle}>
                                    {selectedContent.title}
                                </div>
                                <div
                                    id={`${testIDPrefix}_LandscapeContainer_CatalogType_ArtistName`}
                                    style={landscapeAlbumTrackCardSubtitleStyle}
                                >
                                    {artistName}
                                </div>
                                <div
                                    style={{
                                        bottom: "115px",
                                        left: "978px",
                                        position: "absolute",
                                    }}
                                >
                                    <img
                                        id={`${testIDPrefix}_LandscapeContainer_CatalogType_AMLogo`}
                                        src={getCardLogo({
                                            locale: cardLocale,
                                            logoType: LogoType.LISTEN,
                                            isDark: isDark,
                                            cardLayout: CardLayout.Landscape,
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

const parentContainerStyle: React.CSSProperties = {
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 0,
};

const playlistImageStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
};

const landscapePlaylistImageStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
};

const landscapePlaylistRightImageStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
};

const playlistLogoStyle: React.CSSProperties = {
    width: "371px",
    objectFit: "cover",
    position: "absolute",
};

const promoCardContainerStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: 3,
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
};

const promoCardImageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
};

const portraitCardContentStyle: React.CSSProperties = {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

const portraitCardEntityStyle: React.CSSProperties = {
    width: "100%",
    marginTop: "280px",
    fontFamily: "AmazonEmber",
    fontSize: 32,
    textTransform: "uppercase",
};

const portraitArtistCardTitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    maxWidth: 935,
    fontFamily: "SharpGroteskBold-20",
    fontSize: 60,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const portraitAlbumTrackCardTitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    maxWidth: 935,
    fontFamily: "SharpGroteskBold-20",
    fontSize: 60,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const portraitAlbumTrackCardSubtTitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    maxWidth: 935,
    fontFamily: "SharpGroteskMedium-16",
    fontSize: 60,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const portraitImageContainerStyle: React.CSSProperties = {
    position: "relative",
    display: "flex",
    width: "842px",
    height: "842px",
    marginTop: "70px",
};

const portraitCardLogoStyle: React.CSSProperties = {
    marginTop: "auto",
    marginBottom: 116,
};

const squareCardContentStyle: React.CSSProperties = {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

const squareCardEntityStyle: React.CSSProperties = {
    width: "100%",
    marginTop: "90px",
    fontFamily: "AmazonEmber",
    fontSize: 32,
    textTransform: "uppercase",
};

const squareArtistCardTitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    maxWidth: 935,
    fontFamily: "SharpGroteskBold-20",
    fontSize: 60,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const squareAlbumTrackCardTitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    maxWidth: 935,
    fontFamily: "SharpGroteskBold-20",
    fontSize: 48,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const squareAlbumTrackCardSubtTitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    maxWidth: 935,
    fontFamily: "SharpGroteskMedium-16",
    fontSize: 48,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const squareArtistImageContainerStyle: React.CSSProperties = {
    position: "relative",
    display: "flex",
    width: "549px",
    height: "549px",
    marginTop: "70px",
};

const squareTrakcAlbumImageContainerStyle: React.CSSProperties = {
    position: "relative",
    display: "flex",
    width: "550px",
    height: "550px",
    marginTop: "50px",
};

const squareCardLogoStyle: React.CSSProperties = {
    marginTop: "auto",
    marginBottom: 12,
};

const landscapeArtistImageContainerStyle: React.CSSProperties = {
    position: "relative",
    display: "flex",
    width: "789px",
    height: "789px",
    marginLeft: "116px",
};

const landscapeCardContentStyle: React.CSSProperties = {
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginLeft: "100px",
};

const landscapeCardEntityStyle: React.CSSProperties = {
    width: "100%",
    marginTop: "90px",
    fontFamily: "AmazonEmber",
    fontSize: 45,
    textTransform: "uppercase",
};

const landscapeArtistCardTitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    maxWidth: 848,
    fontFamily: "SharpGroteskBold-20",
    fontSize: 90,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const landscapeAlbumTrackCardTitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    maxWidth: 848,
    fontFamily: "SharpGroteskBold-20",
    fontSize: 70,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const landscapeAlbumTrackCardSubtitleStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    maxWidth: 848,
    fontFamily: "SharpGroteskMedium-16",
    fontSize: 70,
    wordWrap: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
};
