import { Action } from "redux-ts";
import { put, takeEvery } from "redux-saga/effects";
import {
    errorActions,
    merchCurateActions,
    opsMetricsActions,
} from "../../actions";
import {
    METRIC_KEYS,
    searchMerchCollectionsPayload,
    searchMerchCollectionsResponse,
} from "../../../models";
import * as services from "../../../service";
import { createSuccessOpsMetricsPayload } from "../../../utils";

export const MERCH_COLLECTION_INDEX = 0;
export const searchCollectionsSagas = [watchSearchCollections()];

function* searchCollections(action: Action<searchMerchCollectionsPayload>) {
    const start = Date.now();
    const functionName = "searchCollections";
    const timerMetric = services.startTimer("searchCollectionsDuration");

    try {
        yield put(merchCurateActions.getMerchCollectionsInProgress(true));

        const response: searchMerchCollectionsResponse =
            yield services.searchMerchCollections(
                action.payload.request,
                action.payload.teamId
            );

        yield put(
            merchCurateActions.getMerchCollectionsCompleted(
                response.collections[MERCH_COLLECTION_INDEX]
            )
        );

        yield put(merchCurateActions.getMerchCollectionsInProgress(false));

        services.publishTimer(timerMetric);

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(merchCurateActions.getMerchCollectionsInProgress(false));

        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.page, action.payload.requestPath],
        ]);
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
                silent: false,
            })
        );
    }
}

function* watchSearchCollections() {
    yield takeEvery(
        merchCurateActions.getMerchCollections.type,
        searchCollections
    );
}
