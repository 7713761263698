import * as React from "react";
import styled from "styled-components";
import { CommonHeader } from ".";
import { ProfileImageSize } from "../../../models";

export type ProfileHeadProps = {
    imageSource: string;
    fallbackImage?: any;
    imageDescription?: string;
    imageSize?: ProfileImageSize;
    title: string;
    subtitle?: string;
    artistName?: string;
    note?: string;
    callback?: any;
    callbackString?: string;
    id: string;
    showShareContentButton?: boolean;
    shareContentAction?: () => void;
    hideDatePicker?: boolean;
    showCsvButton?: boolean;
    csvDownload?: () => void;
    csvTooltipMessage?: string;
    csvButtonLoading?: boolean;
};

export class ProfileHead extends React.Component<ProfileHeadProps> {
    render() {
        return <CommonHeader {...this.props} mobileAlignment={"center"} />;
    }
}

export const AlbumHeaderContainer = styled.div`
    @media (max-width: 992px) {
        margin-top: 80px;
    }
`;