import { Action } from "redux-ts";
import { takeEvery, put, select } from "redux-saga/effects";
import {
    METRIC_KEYS,
    FailedToResolveStringIdPayload,
    UpdateBundlePayload,
    BundleMap,
    Bundle,
} from "../../models";
import {
    telemetryActions,
    errorActions,
    localizationActions,
    opsMetricsActions,
} from "../actions";
import { RootState } from "../reducers";
import { messageBundleManager } from "../../utils/pantherConfiguration";
import {
    createErrorOpsMetricsPayload,
    createSuccessOpsMetricsPayload,
} from "../../utils";

export const localizationSagas = [watchFailedToResolved(), watchAddBundle()];

function* failedToResolve(action: Action<FailedToResolveStringIdPayload>) {
    const functionName = "failedToResolve";
    const stringKey = `${action.payload.bundleId}_${action.payload.stringId}`;
    try {
        const failedToResolveMap: Set<string> = yield select(
            (state: RootState) => state.localization.failedToResolveStringSet
        );
        if (!failedToResolveMap.has(stringKey)) {
            console.log(`WARNING: Failed to resolve: ${stringKey}`);

            yield put(
                localizationActions.addFailedToResolveString({
                    stringKey,
                })
            );

            const dataPoints = new Map<string, string | undefined>([
                [METRIC_KEYS.stringId, stringKey],
            ]);

            yield put(
                telemetryActions.appEvent({
                    name: `stringIdResolveFailure`,
                    dataPoints: dataPoints,
                })
            );
            yield put(
                opsMetricsActions.batchMetric(
                    createErrorOpsMetricsPayload("stringIdResolveFailure")
                )
            );
        }
        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.stringId, stringKey],
        ]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* updateBundle(action: Action<UpdateBundlePayload>) {
    const start = Date.now();
    const functionName = "updateBundle";
    const bundlePrefix = "com.amazon.amazon-music.amazon-music-for-artists";
    try {
        const bundleMap: BundleMap = yield select(
            (state: RootState) => state.localization.bundleMap
        );
        if (!bundleMap.has(action.payload.bundleId)) {
            yield put(
                telemetryActions.appEvent({
                    name: "loadNewBundleStart",
                    dataPoints: new Map<string, string | undefined>([]),
                })
            );

            const bundle: Bundle = yield messageBundleManager.getBundle(
                `${bundlePrefix}.${action.payload.bundleId}`
            );

            yield put(
                localizationActions.addBundle({
                    bundleId: action.payload.bundleId,
                    bundle: bundle,
                })
            );

            yield put(
                telemetryActions.appEvent({
                    name: "loadNewBundleEnd",
                    dataPoints: new Map<string, string | undefined>([
                        [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    ]),
                })
            );
        }
        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.stringBundle, action.type],
        ]);

        yield put(
            errorActions.handleError({
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* watchFailedToResolved() {
    yield takeEvery(
        localizationActions.failedToResolveStringId.type,
        failedToResolve
    );
}

function* watchAddBundle() {
    yield takeEvery(localizationActions.updateBundle.type, updateBundle);
}
