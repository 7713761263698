import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import React from "react";
import { getLocalizedString } from "../../../../utils";
import { FullScreenConfirmationModal, IconsList } from "../../common";
import { BundleMap } from "../../../../models";

export type TwitterClaimWarningModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
    onConfirm: () => void;
    bundleMap: BundleMap;
};

export class TwitterClaimWarningModal extends React.Component<TwitterClaimWarningModalProps> {
    render() {
        return (
            <FullScreenConfirmationModal
                isVisible={this.props.isVisible}
                onDismiss={this.props.onDismiss}
                id={"TwitterLoginWarningModal"}
                icon={IconsList.alerts_Information}
                text={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                    stringId:
                        stringIds.ProvideYourInformation
                            .twitterLoginWarningHeader,
                })}
                description={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                    stringId:
                        stringIds.ProvideYourInformation
                            .twitterLoginWarningBody,
                })}
                confirmButtonAction={this.props.onConfirm}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.okay,
                })}
            />
        );
    }
}
