import * as React from "react";
import * as rootStyles from "../../../view/styles";
import { ImageList } from "../../../assets";
import { Image, Spinner } from "react-bootstrap";

export type LoadingProps = {
    showLogo?: boolean;
};

export const Loading = (props: LoadingProps) => {
    return (
        <div style={loadingStyle}>
            {props.showLogo === true && (
                <Image
                    src={ImageList.am4a_startup_logo}
                    style={{
                        ...rootStyles.imageStyles.logoImage,
                        marginBottom: -8,
                    }}
                />
            )}
            <Spinner
                animation="border"
                variant="light"
                style={{ marginTop: "20%" }}
            />
        </div>
    );
};

export const IncrementalLoading = () => {
    return <div style={incrementalLoadingStyle}>...</div>;
};

const loadingStyle: React.CSSProperties = {
    flex: 1,
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
};

const incrementalLoadingStyle: React.CSSProperties = {
    height: rootStyles.spacers.huge,
    alignContent: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    marginBottom: rootStyles.spacers.huge,
};
