import * as React from "react";
import { Row } from "react-bootstrap";
import { ImageWithFallback, ImageWithFallbackProps } from ".";

export class ImageWithGradient extends React.PureComponent<ImageWithFallbackProps> {
    render() {
        return (
            <Row className="image-gradient-filter">
                <ImageWithFallback
                    source={this.props.source}
                    fallback={this.props.fallback}
                    style={this.props.style}
                    id={this.props.id}
                    imageDescription={this.props.imageDescription}
                    accessibilityLabel={this.props.accessibilityLabel}
                    className={this.props.className}
                />
            </Row>
        );
    }
}
