import styled from "styled-components";

import * as colorStyles from "./colors";
import { webFontSizes, spacers } from "./styles";

export const primary: React.CSSProperties = {
    fontFamily: "AmazonEmber",
    fontSize: webFontSizes.t1,
    color: colorStyles.colors.primary,
};
export const primaryBold: React.CSSProperties = {
    fontFamily: "AmazonEmber-Bold",
    fontSize: webFontSizes.t1,
    color: colorStyles.colors.primary,
};
export const primaryClickableText: React.CSSProperties = {
    ...primary,
    color: colorStyles.colors.accent,
};
export const secondary: React.CSSProperties = {
    fontFamily: "AmazonEmber",
    fontSize: webFontSizes.t2,
    color: colorStyles.glassColors.primary4,
};
export const secondaryClickableText: React.CSSProperties = {
    ...secondary,
    color: colorStyles.colors.accent,
};
export const tertiary: React.CSSProperties = {
    fontFamily: "AmazonEmber",
    fontSize: webFontSizes.t3,
    color: colorStyles.glassColors.primary4,
};
export const tertiaryClickableText: React.CSSProperties = {
    ...tertiary,
    color: colorStyles.colors.accent,
};
export const linkText: React.CSSProperties = {
    fontFamily: "AmazonEmber",
    fontSize: webFontSizes.t1,
    color: colorStyles.colors.accent,
};
export const quaternary: React.CSSProperties = {
    fontFamily: "AmazonEmber",
    fontSize: webFontSizes.t4,
    color: colorStyles.glassColors.primary4,
};
export const quaternaryClickableText: React.CSSProperties = {
    ...quaternary,
    color: colorStyles.colors.accent,
};
export const label: React.CSSProperties = {
    fontFamily: "AmazonEmber-Bold",
    fontWeight: 400,
    fontSize: webFontSizes.t4,
    textTransform: "capitalize",
    color: colorStyles.colors.accent,
};
export const index: React.CSSProperties = {
    fontFamily: "AmazonEmber-Bold",
    fontWeight: 400,
    fontSize: webFontSizes.t4,
    textTransform: "uppercase",
    color: colorStyles.glassColors.primary4,
};
export const key: React.CSSProperties = {
    fontFamily: "AmazonEmber-Bold",
    fontWeight: 400,
    fontSize: webFontSizes.t4,
    textTransform: "capitalize",
    color: colorStyles.colors.primary,
};
export const navigationLinkStyle: React.CSSProperties = {
    marginLeft: spacers.small,
    marginBottom: spacers.base,
};
export const navigationLinkTextStyle: React.CSSProperties = {
    ...secondary,
    fontWeight: 400,
    textTransform: "capitalize",
    color: colorStyles.colors.primary,
};
export const navigationLabelStyle: React.CSSProperties = {
    ...index,
    marginBottom: spacers.base,
    fontWeight: 400,
    marginTop: spacers.huge,
    color: colorStyles.glassColors.primary4,
};
export const italicStyle: React.CSSProperties = {
    fontFamily: "AmazonEmber",
    fontSize: webFontSizes.t1,
    color: colorStyles.colors.primary,
    fontStyle: "italic",
};

export const IndexSpan = styled.span`
    font-family: AmazonEmber-Bold;
    font-weight: 400;
    font-size: ${webFontSizes.t4}px;
    text-transform: uppercase;
    color: ${colorStyles.glassColors.primary4};
    white-space: nowrap;
`;
