import loginSplash_devices from "./login_splash_devices.png";
import loginSplash_wavy_pattern from "./login_splash_wavy_pattern.png";
import placeholder_artist from "./placeholder_artist.png";
import placeholder_album from "./placeholder_album.png";
import placeholder_track from "./placeholder_track.png";
import placeholder_playlist from "./placeholder_playlist.png";
import placeholder_station from "./placeholder_station.png";
import am4a_startup_logo from "./am4a_h_wht.png";
import cdbaby from "./cdbaby.png";
import logo_sidebar from "./logo_sidebar.png";
import logo_sidebar_svg from "./logo_sidebar.svg";
import cdbabyWeb from "./cdbabyWeb.png";
import distrokidWeb from "./distrokidWeb.png";
import tunecoreWeb from "./tunecoreWeb.png";
import tunecoreJP from "./tunecoreJP.png";
import fbWeb from "./fbWeb.png";
import xWeb from "./xWeb.svg";
import igWeb from "./igWeb.svg";
import smile from "./smile.svg";
import flag_de from "./flag-de.svg";
import flag_es from "./flag-es.svg";
import flag_fr from "./flag-fr.svg";
import flag_it from "./flag-it.svg";
import flag_jp from "./flag-jp.svg";
import flag_br from "./flag-br.svg";
import flag_us from "./flag-us.svg";
import addToPlaylist_ftue_background from "./addToPlaylist_ftue_background.jpg";
import audio_wave from "./Path.svg";
import playlist_learnMore from "./playlistLearnMore.png";

export const ImageList = {
    loginSplash_devices,
    loginSplash_wavy_pattern,
    placeholder_artist,
    placeholder_album,
    placeholder_track,
    placeholder_playlist,
    placeholder_station,
    am4a_startup_logo,
    cdbaby,
    logo_sidebar,
    logo_sidebar_svg,
    cdbabyWeb,
    distrokidWeb,
    tunecoreWeb,
    tunecoreJP,
    fbWeb,
    xWeb,
    igWeb,
    smile,
    flag_de,
    flag_es,
    flag_fr,
    flag_it,
    flag_jp,
    flag_br,
    flag_us,
    addToPlaylist_ftue_background,
    audio_wave,
    playlist_learnMore,
};
