import _ from "lodash";
import React from "react";
import * as rootStyles from "../../../styles";
import { Button, Image } from "react-bootstrap";

type Props = {
    image: string;
    size: number;
    isCircle?: boolean;
    onClick?: () => void;
    imageStyle?: React.CSSProperties;
};

export class ImageTile extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const noOnClick = () => {
            return (
                <Image
                    src={this.props.image}
                    height={this.props.size}
                    width={this.props.size}
                    style={{
                        background: rootStyles.colors.background,
                        cursor: this.props.onClick ? "pointer" : "inherit",
                        borderRadius: this.props.isCircle
                            ? this.props.size / 2
                            : 4,
                        boxShadow: `0 3px 10px rgb(0 0 0 / 0.2)`,
                        objectFit: "cover",
                    }}
                />
            );
        };

        const withOnClick = () => {
            return (
                <Button
                    style={{
                        display: "flex",
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        height: this.props.size,
                        width: this.props.size,
                        borderRadius: this.props.isCircle
                            ? this.props.size / 2
                            : 4,
                        padding: 0,
                        overflow: "hidden",
                    }}
                >
                    <Image
                        src={this.props.image}
                        height={this.props.size}
                        width={this.props.size}
                        style={{
                            borderRadius: this.props.isCircle
                                ? this.props.size / 2
                                : 4,
                            objectFit: "fill",
                        }}
                        onClick={this.props.onClick}
                    />
                </Button>
            );
        };

        const componentToRender = this.props.onClick ? withOnClick : noOnClick;

        return componentToRender();
    }
}
