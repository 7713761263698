import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import * as rootStyles from "../../../styles";
import { getLocalizedString, testIDSuffixes } from "../../../../utils";
import { stringIds, bundleIds } from "../../../../assets";
import { colors } from "../../../styles";
import { Row } from "react-bootstrap";
import { BundleMap } from "../../../../models";

export type TextFieldProps = {
    value?: string;
    label?: string;
    placeholder?: string;
    onChangeText?: any;
    id: string;
    accessibilityLabel?: string;
    maxLength: number;
    onChange?: any;
    multiLine?: boolean;
    visibleRows?: number;
    defaultValue?: string;
    showCharacterCount?: boolean;
    requiredFieldMsgText?: string;
    requiredFieldErrorText?: string;
    showRequiredFieldError?: boolean;
    handleClickedOffInputBox?: () => void;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = StateProps & TextFieldProps;

type State = {
    isFocused: boolean;
    charactersLeft: number;
};

export class TextFieldClass extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isFocused: false,
            charactersLeft:
                this.props.maxLength - (this.props.defaultValue?.length || 0),
        };
    }

    render() {
        const emptyInputBox =
            this.state.charactersLeft === this.props.maxLength;

        return (
            <>
                <div
                    style={
                        this.props.showRequiredFieldError && emptyInputBox
                            ? requiredTextInputContainer
                            : this.state.isFocused
                            ? selectedContainer
                            : textInputContainer
                    }
                >
                    {this.props.multiLine ? (
                        <>
                            <textarea
                                id={this.props.id + "-TextArea"}
                                style={{
                                    ...textInput,
                                    resize: "none",
                                    paddingRight: rootStyles.spacers.small,
                                }}
                                onFocus={this.onFocus}
                                maxLength={this.props.maxLength}
                                onChange={(val) => {
                                    this.props.onChange(val.target.value);
                                    if (val.target.value !== undefined) {
                                        this.setState({
                                            charactersLeft:
                                                this.props.maxLength -
                                                val.target.value.length,
                                        });
                                    }
                                }}
                                // Prevent line breaks
                                onKeyPress={(event) => {
                                    event.key === "Enter" &&
                                        event.preventDefault();
                                }}
                                onBlur={this.onBlur}
                                value={this.props.value}
                                defaultValue={this.props.defaultValue}
                                placeholder={this.props.placeholder}
                                rows={this.props.visibleRows || 3}
                            ></textarea>
                            {this.props.showCharacterCount && (
                                <Row style={{ justifyContent: "flex-end" }}>
                                    <span
                                        style={rootStyles.textStyles.tertiary}
                                    >
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.GENERIC_STRINGS,
                                                stringId:
                                                    stringIds.Generic
                                                        .charactersLeft,
                                            },
                                            {
                                                "0": this.state.charactersLeft.toString(),
                                            }
                                        )}
                                    </span>
                                </Row>
                            )}
                        </>
                    ) : (
                        <input
                            id={`${this.props.id}${testIDSuffixes.textField}`}
                            type="text"
                            style={textInput}
                            onFocus={this.onFocus}
                            maxLength={this.props.maxLength}
                            onChange={(val) =>
                                this.props.onChange(val.target.value)
                            }
                            onBlur={this.onBlur}
                            placeholder={this.props.label || ""}
                            value={this.props.value}
                            defaultValue={this.props.defaultValue}
                        />
                    )}
                </div>
                {/* required field error text */}
                {!!this.props.requiredFieldErrorText &&
                    this.props.showRequiredFieldError &&
                    emptyInputBox && (
                        <Row>
                            <span
                                style={{
                                    ...rootStyles.textStyles.secondary,
                                    color: rootStyles.colors.error,
                                }}
                            >
                                {this.props.requiredFieldErrorText}
                            </span>
                        </Row>
                    )}
                {/* required field message text */}
                {!!this.props.requiredFieldMsgText && (
                    <Row>
                        <span
                            style={{
                                ...rootStyles.textStyles.secondary,
                            }}
                        >
                            {this.props.requiredFieldMsgText}
                        </span>
                    </Row>
                )}
            </>
        );
    }

    private onFocus = () => {
        this.setState({ isFocused: true });
    };

    private onBlur = () => {
        this.setState({ isFocused: false });
        if (
            !!this.props.handleClickedOffInputBox &&
            this.state.charactersLeft === this.props.maxLength
        ) {
            this.props.handleClickedOffInputBox();
        }
    };
}

const sharedContainerStyle: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "flex-start",
    alignItems: "flex-start",
    borderRadius: 9,
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    padding: rootStyles.spacers.base,
    borderColor: rootStyles.glassColors.primary3,
};
const textInputContainer: React.CSSProperties = {
    ...sharedContainerStyle,
};
const selectedContainer: React.CSSProperties = {
    ...sharedContainerStyle,
    borderColor: rootStyles.colors.accent,
};
const requiredTextInputContainer: React.CSSProperties = {
    ...sharedContainerStyle,
    borderColor: rootStyles.colors.error,
};
const textInput: React.CSSProperties = {
    padding: 0,
    outline: 0,
    border: 0,
    textAlign: "left",
    width: "100%",
    margin: 0,
    ...rootStyles.textStyles.secondary,
    color: colors.primary,
    backgroundColor: "transparent",
};
const labelStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
};

function mapStateToProps(state: RootState): StateProps {
    return {
        bundleMap: state.localization.bundleMap,
    };
}
export const TextField = connect(mapStateToProps)(TextFieldClass);
