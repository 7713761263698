import {
    searchResults,
    artistResponse,
    artist,
    trackAlbumSearchResults,
    trackAlbumResponse,
    baseMediaItem,
} from "../models";
import { formatDate } from "./dataHelper";

export const getArtistsFromResponse = (response: searchResults): artist[] => {
    const artists: artist[] = [];

    if (
        response &&
        response.artistResponseData &&
        response.artistResponseData.length > 0
    ) {
        response.artistResponseData.forEach(
            (artistResponse: artistResponse) => {
                artists.push({
                    asin: artistResponse.asin,
                    title: artistResponse.title,
                    images: {
                        artSmall: artistResponse?.images?.artSmall,
                        artExtraLarge: artistResponse?.images?.artExtraLarge,
                        artMedium: artistResponse?.images?.artMedium,
                        artLarge: artistResponse?.images?.artLarge,
                    },
                    imageUrl: artistResponse?.images?.artExtraLarge,
                    hasPermission: artistResponse.hasPermission ? true : false,
                });
            }
        );
    }

    return artists;
};

export const getTrackAlbumsFromResponse = (
    response: trackAlbumSearchResults
): baseMediaItem[] => {
    const items: baseMediaItem[] = [];

    if (response && response.responseData && response.responseData.length > 0) {
        response.responseData.forEach(
            (trackAlbumResponse: trackAlbumResponse) => {
                items.push({
                    asin: trackAlbumResponse.asin,
                    title: trackAlbumResponse.title,
                    images: {
                        artSmall: trackAlbumResponse?.images?.artSmall,
                        artExtraLarge:
                            trackAlbumResponse?.images?.artExtraLarge,
                        artMedium: trackAlbumResponse?.images?.artMedium,
                        artLarge: trackAlbumResponse?.images?.artLarge,
                    },
                    type: trackAlbumResponse.type,
                    year: trackAlbumResponse.year,
                    localOriginalReleaseDate:
                        trackAlbumResponse.originalReleaseDate
                            ? formatDate(
                                  new Date(
                                      trackAlbumResponse.originalReleaseDate
                                  )
                              )
                            : undefined,
                    titlesetAsin: trackAlbumResponse.titleSetAsin,
                });
            }
        );
    }

    return items;
};
