import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import React from "react";
import { useSelector } from "react-redux";
import { getLocalizedString } from "../../../../utils";
import {
    FullScreenConfirmationModal,
    IconsList,
} from "../../../components/common";
import { BundleMap } from "../../../../models";
import { getBundleMap } from "../../../../store/selectors/commonSelectors";

const testIDPrefix = "NoSocialMediaModal";

export const ConfirmNoSocialMediaModal: React.FC<{
    isVisible: boolean;
    onCancel: () => void;
    onContinue: () => void;
}> = ({ isVisible, onCancel, onContinue }) => {
    const bundleMap: BundleMap = useSelector(getBundleMap);

    return (
        <FullScreenConfirmationModal
            icon={IconsList.alerts_Information}
            text={getLocalizedString(bundleMap, {
                bundleId: bundleIds.GENERIC_STRINGS,
                stringId: stringIds.Generic.areYouSure,
            })}
            description={getLocalizedString(bundleMap, {
                bundleId: bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                stringId: stringIds.ProvideYourInformation.noSocialError,
            })}
            confirmButtonText={getLocalizedString(bundleMap, {
                bundleId: bundleIds.GENERIC_STRINGS,
                stringId: stringIds.Generic.yesSubmit,
            })}
            cancelButtonText={getLocalizedString(bundleMap, {
                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                stringId: stringIds.ManageCurrentTeam.cancelOwnershipTransfer,
            })}
            confirmButtonAction={onContinue}
            cancelButtonAction={onCancel}
            onDismiss={onCancel}
            isVisible={isVisible}
            id={`${testIDPrefix}_ConfirmSubmitModal`}
        />
    );
};
