import { catalogItem, track, album, station, playlist } from "../models";

const isValidTrack = (track: track): boolean => {
    return (
        isValidCatalogItem(track) &&
        !!track.albumAsin &&
        !!track.artistAsins &&
        track.artistAsins.length > 0
    );
};

const isValidAlbum = (album: album): boolean => {
    return (
        isValidCatalogItem(album) &&
        !!album.artistAsins &&
        album.artistAsins.length > 0
    );
};

const isValidPlaylist = (playlist: playlist): boolean => {
    return isValidCatalogItem(playlist);
};

const isValidStation = (station: station): boolean => {
    return isValidCatalogItem(station);
};

const isValidCatalogItem = (catalogItem: catalogItem): boolean => {
    return !!catalogItem && !!catalogItem.asin && !!catalogItem.title;
};

export const catalogHelper = {
    isValidTrack,
    isValidAlbum,
    isValidPlaylist,
    isValidStation,
};
