import * as React from "react";
import {
    Button,
    Col,
    OverlayTrigger,
    Row,
    Tooltip as BootstrapTooltip,
} from "react-bootstrap";
import { Icon } from "./icons";
import * as rootStyles from "../../styles";
import { SmallSolidButton } from ".";
import { isMobile, isTablet } from "react-device-detect";
import { glass } from "../../styles";

export type TooltipProps = {
    icon: any;
    message: string;
    buttonText?: string;
    buttonAction?: any;
    placement?: rootStyles.popupStyles.tooltipPlacement;
    id: string;
};

export const Tooltip: React.FC<TooltipProps> = ({
    icon,
    message,
    buttonText,
    buttonAction,
    placement = "top",
    id,
}) => {
    const [show, setShow] = React.useState<boolean | undefined>(undefined);

    const renderTooltip = (props: any) => (
        <rootStyles.popupStyles.StyledTooltip
            {...props}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
        >
            <Row>
                <span style={rootStyles.textStyles.primary}>{message}</span>
            </Row>
            {buttonText ? (
                <Row>
                    <Col
                        style={{
                            padding: 0,
                            marginTop: rootStyles.spacers.small,
                        }}
                    >
                        <SmallSolidButton
                            onClick={buttonAction ? buttonAction : () => null}
                            title={buttonText}
                            containerStyle={{ width: "100%" }}
                            id={`${props.id}_ActionButton`}
                        />
                    </Col>
                </Row>
            ) : null}
        </rootStyles.popupStyles.StyledTooltip>
    );

    const showTooltip = () => {
        setTimeout(() => {
            setShow(true);
        }, 300);
    };

    const hideTooltip = () => {
        setTimeout(() => {
            setShow(false);
        }, 300);
    };

    return (
        <OverlayTrigger
            placement={placement}
            overlay={renderTooltip(placement)}
            trigger={isMobile || isTablet ? "click" : ["hover", "focus"]}
            show={show}
        >
            <Button
                style={{
                    background: "none",
                    padding: 0,
                    border: "none",
                    opacity: 1,
                    display: "flex",
                }}
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
            >
                <Icon
                    size={16}
                    icon={icon}
                    style={{ opacity: glass._4 }}
                    id={`${id}_TooltipButton`}
                />
            </Button>
        </OverlayTrigger>
    );
};

export default Tooltip;
