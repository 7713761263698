import * as React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch, AnyAction } from "redux";
import { Container, Row, Col } from "react-bootstrap";
import {
    RequiredReportingProps,
    RequiredReportingState,
    RequiredReportingDispatchProps,
    TableRowProps,
    METRIC_KEYS,
    queryRequest,
    albumInfo,
    TimeRange,
    hydrateCatalogPayload,
    overviewRequestPayload,
    telemetryPayload,
    timeRangePayload,
    CatalogItemType,
    createCsvFileRequestPayload,
    clientMetricsPayload,
    EntityType,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    RootState,
    reportingActions,
    telemetryActions,
    catalogActions,
    albumsScreenSelector,
    userActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import {
    getLocalizedString,
    generateAlbumRows,
    paths,
    HYDRATION_COUNT,
    generateQueryWithoutGranularity,
    guessTimeZone,
    hydrationHelpers,
    buildUIClickPayload,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import {
    Loading,
    IncrementalLoading,
    Table,
    InlineError,
    styledTitle,
    CommonHeader,
} from "../../components";
import { CsvButtonId, csvButtonLoadingStatusData } from "../../../export";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

const testIDPrefix = "AlbumsScreen";
const metricPrefix = "albumsPage";
const pagePath = paths.albums;

type ViewProps = {};

type StateProps = RequiredReportingProps & {
    albums: albumInfo[];
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = RequiredReportingDispatchProps & {
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    updateCurrentPath: (payload: string) => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    loadedCount: number;
};

class AlbumsScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            loadedCount: 0,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();
        this.getReports(true);
        this.loadMore();
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist],
                [METRIC_KEYS.timeRange, this.props.selectedRange],
                [METRIC_KEYS.page, paths.albums],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports();
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.state.loadedReports &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
            this.loadMore();
        }
    }

    render() {
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const topAlbumsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.albumsTitle,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const topAlbums: TableRowProps[] = generateAlbumRows(
            this.props.albums.slice(0, this.state.loadedCount),
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            this.props.bundleMap,
            this.props.selectedArtist,
            testIDPrefix + "_AlbumTableRow"
        );

        const csvPayload: createCsvFileRequestPayload = {
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: pagePath,
            csvExportType: "topAlbums",
            csvButtonId: CsvButtonId.TopAlbumsTopAlbums,
            albumData: this.props.albums,
        };

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    ...rootStyles.containerStyles.tableContainerStyle,
                }}
            >
                {/* {this.props.artist &&
                    <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: this.props.artist?.images?.artSmall }}
                    />
                } */}
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.albumTitle,
                    })}
                    id={`${testIDPrefix}_Header`}
                    showCsvButton={
                        this.props.hasCsvExportCapabilities &&
                        !!this.props.albums.length
                    }
                    csvDownload={() => {
                        this.props.sendClientMetrics(
                            buildUIClickPayload(
                                buttonIds.CsvExport.downloadAlbums,
                                pageIds.albums,
                                this.props.selectedArtist,
                                EntityType.ARTIST
                            )
                        );
                        this.props.getCsvFile(csvPayload);
                    }}
                    csvTooltipMessage={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.CSV_STRINGS,
                            stringId: stringIds.Csv.TooltipMessage.TopAlbums,
                        }
                    )}
                    csvButtonLoading={
                        this.props.csvButtonLoadingStatus["TopAlbums-TopAlbums"]
                    }
                />
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                <Row>
                    <Col>
                        <Table
                            loading={this.state.refreshing}
                            showIndex={true}
                            rows={topAlbums}
                            loadMore={this.loadMore}
                            labels={topAlbumsHeader}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId: stringIds.Reports.topAlbumsTitle,
                            })}
                            scrolling={true}
                            footer={
                                this.props.albums.length <=
                                this.state.loadedCount ? (
                                    <div
                                        style={{
                                            height: rootStyles.spacers.huge,
                                        }}
                                    />
                                ) : (
                                    <IncrementalLoading />
                                )
                            }
                            id={testIDPrefix + "_AlbumTable"}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

    private loadMore = () => {
        if (!this.props.signedIn) {
            return;
        }

        const nextCount: number = hydrationHelpers.hydrateAsinsBatch(
            this.state.loadedCount,
            this.props.albums,
            this.props.hydrateAsins,
            CatalogItemType.Albums,
            this.props.userLocale
        );

        this.setState({
            loadedCount: nextCount,
        });
    };

    private getReports = (isRefresh?: boolean) => {
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId
        ) {
            return;
        }

        this.setState({
            loadedCount: HYDRATION_COUNT,
        });

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        const query: queryRequest = {
            artistAsin: this.props.selectedArtist,
            topAlbumsQueryV2: generateQueryWithoutGranularity(
                timeRangePayload,
                guessTimeZone(),
                300
            ),
        };

        const payload: overviewRequestPayload = {
            query: query,
            requestId: `seeAll_topAlbums_${this.props.selectedRange.toString()}`,
            requestPath: paths.albums,
            isRefresh: isRefresh,
            teamId: this.props.teamId,
        };

        this.props.getReport(payload);
    };
}

function mapStateToProps(state: RootState) {
    return {
        ...albumsScreenSelector(state, paths.albums),
        ...bundleMapSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReport: (payload: overviewRequestPayload) =>
            dispatch(reportingActions.getReports(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AlbumsScreen)
);
