export const MAX_ITEMS_IN_COLLECTION = 150;

export type hydratedMerchItem = {
    itemAsin: string;
    metadata: merchItemMetadata;
};

export type merchItemMetadata = {
    itemName: string;
    productImages: merchItemImage[];
    price?: string;
    category?: string;
};

export type merchItemImage = {
    lowResUrl: string;
    highResUrl?: string;
};

//below models are defined from DrTeethMerchInterface
//https://code.amazon.com/packages/DrTeethMerchInterface/trees/mainline
//string will be equal to itemAsin
export type merchIdentifiers = string;

//used to handle types from hydrateMerch calls
export type drTeethMerchIdentifiers = {
    itemAsin: string;
};

// TEMPORARY will go back and make the base collection model
// to this model later on
export type DrTeethCollection = {
    collectionId: string;
    collectionName: string;
    items: drTeethMerchIdentifiers[];
};

export type merchCollection = {
    collectionId: string;
    collectionName: string;
    items: merchIdentifiers[];
};

export type merchEntity = {
    entityId: string;
    entityType: merchEntityType;
};

export type merchListingKey = {
    entity: merchEntity;
    listingType: merchListingType;
};

export type merchUserContext = {
    marketplaceId: string;
    locale: string;
    deviceFamily: merchDeviceFamily;
};

export enum merchEntityType {
    ARTIST = "ARTIST",
}

export enum merchListingType {
    listingType = "DEFAULT",
}

export enum merchDeviceFamily {
    AM4A_IOS = "AM4A_IOS",
    AM4A_ANDROID = "AM4A_ANDROID",
    AM4A_WEB = "AM4A_WEB",
    ROBOT = "ROBOT",
}

//key is a stringified object of merchIdentifiers
export type merchCatalog = Map<string, hydratedMerchItem>;
