import * as React from "react";
import { Image, Container } from "react-bootstrap";
import _ from "lodash";
import * as rootStyles from "../../styles";
import { IconsList } from "../common";
import { merchImages } from "../../../assets";
import { RouteComponentProps } from "react-router-dom";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";

const testIDPrefix = "MoDLandingHeader";

type ViewProps = {};

type MODLandingHeaderProps = {
    props: RouteComponentProps<ViewProps>;
    emitMetrics: (key: string, pageId: string) => void;
};

export const MoDLandingScreenHeader: React.FC<MODLandingHeaderProps> = ({
    props,
    emitMetrics,
}) => {
    const goBack = () => {
        emitMetrics(
            buttonIds.Merch.dismissMoDLanding,
            pageIds.artistMerchMoDLandingScreen
        );
        props.history.goBack();
    };

    return (
        <Container>
            <Image
                style={{
                    ...rootStyles.imageStyles.backgroundImage,
                    height: "12px",
                    opacity: "1",
                }}
                src={merchImages.backgroundGradient}
            />

            <img
                style={caretStyle}
                src={IconsList.chevron_caretleft}
                onClick={goBack}
                id={`${testIDPrefix}_BackButton`}
            />
        </Container>
    );
};

const caretStyle: React.CSSProperties = {
    height: rootStyles.icons.medium,
    width: rootStyles.icons.medium,
    position: "absolute",
    marginLeft: rootStyles.spacers.medium,
    left: 0,
};
