import { call, select, takeEvery } from "redux-saga/effects";
import { Action } from "redux-ts";
import {
    EmitOpsMetricsPayload,
    OpsMetricDeviceInfo,
    OpsMetricsRequest,
} from "../../../models";
import { emitOperationalClientMetric } from "../../../service";
import { getDeviceInformation } from "../../../utils";
import { opsMetricsActions } from "../../actions";
import { getDeviceInfo } from "../../selectors";
import { getCsrfToken } from "../../selectors/commonSelectors";
import _ from "lodash";

export const opsMetricSagas = [watchOpsMetrics()];

const NUM_RETRIES = 3;

function* emitOpsMetrics(action: Action<EmitOpsMetricsPayload>) {
    try {
        const metricsQueue = action.payload;
        if (!metricsQueue.length) {
            return;
        }
        // collect device info and user id, create payload
        let deviceInfo: OpsMetricDeviceInfo = yield select(getDeviceInfo);
        // on startup, metrics can be emitted before redux has had time to set the deviceInfoObject.
        // if its empty, then calcuate the deviceInfo here
        if (!deviceInfo) {
            deviceInfo = yield call(getDeviceInformation);
        }

        const csrfToken: string = yield select(getCsrfToken);

        const opsMetricsRequest: OpsMetricsRequest = {
            deviceInfo: deviceInfo,
            batchedOpsMetricInfo: _.map(metricsQueue, (opsMetricInfo) => {
                return {
                    ...opsMetricInfo,
                    context: opsMetricInfo.context
                        ? _.fromPairs([...opsMetricInfo.context.entries()])
                        : undefined,
                };
            }),
        };
        // call API
        for (let i = 0; i < NUM_RETRIES; i++) {
            try {
                yield emitOperationalClientMetric(opsMetricsRequest, csrfToken);
                break;
                // tslint:disable-next-line: no-empty
            } catch (ex) {}
        }

        // tslint:disable-next-line: no-empty
    } catch (ex) {}
}

function* watchOpsMetrics() {
    yield takeEvery(opsMetricsActions.emitOpsMetrics.type, emitOpsMetrics);
}
