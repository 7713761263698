import _ from "lodash";
import { AnyAction } from "redux";
import {
    ModalRenderFunction,
    ToastViewData,
} from "../../models/globalNotifications";
import { globalNotificationsActions } from "../actions";

export type GlobalNotificationsState = Readonly<{
    toast?: ToastViewData;
    toastQueue: ToastViewData[]; // implemented as a FIFO queue
    modal?: ModalRenderFunction;
    modalQueue: ModalRenderFunction[]; // implemented as a FIFO queue
}>;

export const initialGlobalNotificationsState: GlobalNotificationsState = {
    toastQueue: [],
    modalQueue: [],
};

export const globalNotificationsReducer = (
    state: GlobalNotificationsState = initialGlobalNotificationsState,
    action: AnyAction
): GlobalNotificationsState => {
    switch (action.type) {
        case globalNotificationsActions.requestModalDisplay.type: {
            if (!state.modal && !state.modalQueue.length) {
                return {
                    ...state,
                    modal: action.payload,
                };
            }

            return {
                ...state,
                modalQueue: [...state.modalQueue, action.payload],
            };
        }
        case globalNotificationsActions.closeActiveModal.type: {
            if (!state.modalQueue.length) {
                return {
                    ...state,
                    modal: undefined,
                };
            }

            const nextModal = state.modalQueue[0];
            return {
                ...state,
                modal: nextModal,
                modalQueue: _.slice(
                    state.modalQueue,
                    1,
                    state.modalQueue.length
                ),
            };
        }
        case globalNotificationsActions.requestToastView.type: {
            if (!state.toast && !state.toastQueue.length) {
                return {
                    ...state,
                    toast: action.payload,
                };
            }

            return {
                ...state,
                toastQueue: [...state.toastQueue, action.payload],
            };
        }
        case globalNotificationsActions.closeActiveToast.type: {
            if (!state.toastQueue.length) {
                return {
                    ...state,
                    toast: undefined,
                };
            }

            const nextToast = state.toastQueue[0];
            return {
                ...state,
                toast: nextToast,
                toastQueue: _.slice(
                    state.toastQueue,
                    1,
                    state.toastQueue.length
                ),
            };
        }
        default:
            return state;
    }
};
