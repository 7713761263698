import _ from "lodash";
import React from "react";
import { Card, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { bundleMapSelector } from "../../../../store/selectors/commonSelectors";
import {
    AutoSizer,
    Index,
    List,
    ListRowProps,
    OnScrollParams,
} from "react-virtualized";
import { stringIds, bundleIds } from "../../../../assets";
import { BundleMap, TableRowProps } from "../../../../models";
import { getLocalizedString } from "../../../../utils";
import * as rootStyles from "../../../styles";
import { TableHeader, TableHeaderRow, TableRow } from "./";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export type TableProps = {
    headerButton?: any;
    rows: TableRowProps[];
    labels?: string[];
    stat2labelRightElement?: JSX.Element;
    title?: string;
    subtitle?: string;
    secondaryText?: string;
    showIndex?: boolean;
    footer?: any;
    id: string;
    loading?: boolean;
    refreshControl?: any;
    loadMore?: any;
    emptyMessage?: string;
    scrolling?: boolean;
    className?: string;
    embeddedFooter?: JSX.Element;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = StateProps & TableProps;

const SCROLL_LOAD_MORE_THRESHOLD = 0.8;
const ROW_HEIGHT = 75;
const MAX_VISIBLE_ROWS_SCROLL_TABLE = 10;
const EMBEDDED_FOOTER_HEIGHT = ROW_HEIGHT * 2;

export class TableClass extends React.Component<Props> {
    constructor(props: any) {
        super(props);
        this.state = {
            text: "",
            artists: [],
        };
        this.loadMore = this.loadMore.bind(this);
        this.loadMore = _.debounce(this.loadMore, 500);
    }

    componentDidMount() {
        window.addEventListener("resize", _.debounce(this.onWindowResize, 300));
        this.resizeTable();
    }

    componentWillUnmount() {
        window.removeEventListener(
            "resize",
            _.debounce(this.onWindowResize, 300)
        );
    }

    private listRef = React.createRef<List>();

    onWindowResize = () => {
        this.resizeTable();
        this.listRef.current?.recomputeRowHeights(this.props.rows.length);
    };

    resizeTable = () => {
        const tableBody = document.getElementById("TableScrollingBody");
        if (tableBody) {
            const boundingRect = tableBody.getBoundingClientRect();
            const screenHeight = window.innerHeight;
            if (screenHeight > boundingRect.y + rootStyles.spacers.epic) {
                const heightToRemove = boundingRect.y + rootStyles.spacers.epic;
                tableBody.style.maxHeight = `calc(100vh - ${heightToRemove}px)`;
            } else {
                tableBody.style.maxHeight = "none";
            }
        }
    };

    render() {
        let emptyState;
        const emptyMessage =
            this.props.emptyMessage ||
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.EMPTYSTATE_STRINGS,
                stringId: stringIds.EmptyState.message,
            });
        const headerComponent = this.props.labels ? (
            <TableHeaderRow
                showIndex={this.props.showIndex}
                primary={this.props.labels[0]}
                stat1={this.props.labels[1]}
                stat2={this.props.labels[2]}
                stat2RightIcon={this.props.stat2labelRightElement}
                id={this.props.id + "_HeaderRow"}
            />
        ) : undefined;

        if (
            (!this.props.rows || this.props.rows.length === 0) &&
            !this.props.loading
        ) {
            emptyState = (
                <div style={emptyContainerStyle}>
                    <div style={emptyTableStyle}>
                        <p style={emptyTableTextStyle}>{emptyMessage}</p>
                    </div>
                </div>
            );
        }

        const tableComponent = this.props.scrolling
            ? this.scrollingTable()
            : this.nonScrollingTable();
        return (
            <Card className={this.props.className} style={tableContainerStyle}>
                {this.props.headerButton}
                <TableHeader {...this.props} headerButton={this.props.footer} />
                <div style={{ flex: 1 }}>{headerComponent}</div>
                {this.props.rows.length > 0 ? tableComponent : emptyState}
            </Card>
        );
    }

    scrollingTable = () => {
        const tableHeight = this.calculateTableHeight();

        return (
            <Container
                id="TableScrollingBody"
                fluid={true}
                style={{ display: "flex", height: tableHeight, padding: 0 }}
            >
                <div id={this.props.id + "-Scrolling"} style={autoSizerStyle}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                dataUpdateListener={this.props.rows}
                                height={height}
                                width={width}
                                onScroll={this.onScroll}
                                rowCount={
                                    this.props.embeddedFooter
                                        ? this.props.rows.length + 1
                                        : this.props.rows.length
                                }
                                rowHeight={
                                    this.props.embeddedFooter
                                        ? this
                                              .calculateRowHeightForEmbeddedFooter
                                        : ROW_HEIGHT
                                }
                                rowRenderer={this.rowRenderer}
                                ref={this.listRef}
                            />
                        )}
                    </AutoSizer>
                </div>
            </Container>
        );
    };

    calculateTableHeight = () => {
        const numRows = this.props.rows.length;
        if (this.props.embeddedFooter) {
            return numRows + 1 > MAX_VISIBLE_ROWS_SCROLL_TABLE
                ? MAX_VISIBLE_ROWS_SCROLL_TABLE * ROW_HEIGHT
                : numRows * ROW_HEIGHT + EMBEDDED_FOOTER_HEIGHT;
        } else {
            return numRows > MAX_VISIBLE_ROWS_SCROLL_TABLE
                ? MAX_VISIBLE_ROWS_SCROLL_TABLE * ROW_HEIGHT
                : numRows * ROW_HEIGHT;
        }
    };

    calculateRowHeightForEmbeddedFooter = (index: Index) => {
        if (index.index < this.props.rows.length) {
            return ROW_HEIGHT;
        }
        return window.self.innerWidth < rootStyles.breakpoints.xl
            ? EMBEDDED_FOOTER_HEIGHT
            : 0;
    };

    nonScrollingTable = () => {
        return (
            <div id={this.props.id + "-NonScrolling"} style={{ flex: 1 }}>
                {this.props.rows.map((item, index) => {
                    return this.props.showIndex ? (
                        <TableRow
                            key={index.toString()}
                            {...item}
                            index={(index + 1).toString()}
                        />
                    ) : (
                        <TableRow {...item} />
                    );
                })}
            </div>
        );
    };

    rowRenderer = (props: ListRowProps) => {
        //Add an extra row to the autosizer for "embedded footer" after list has completely rendered
        if (props.index === this.props.rows.length) {
            return (
                <Row
                    style={{
                        ...rootStyles.tableStyles.containerStyle,
                        ...props.style,
                        height: "auto",
                        minHeight: ROW_HEIGHT,
                    }}
                >
                    <Col xs="12" style={rootStyles.tableStyles.textContainer}>
                        {this.props.embeddedFooter}
                    </Col>
                </Row>
            );
        }
        const item: TableRowProps = this.props.rows[props.index];

        return this.props.showIndex ? (
            <TableRow
                additionalRowStyle={props.style}
                key={props.index.toString()}
                {...item}
                index={(props.index + 1).toString()}
            />
        ) : (
            <TableRow
                additionalRowStyle={props.style}
                key={props.index.toString()}
                {...item}
            />
        );
    };

    onScroll = (params: OnScrollParams) => {
        if (!this.props.loadMore) {
            return;
        }

        if (
            SCROLL_LOAD_MORE_THRESHOLD <
            (params.clientHeight + params.scrollTop) / params.scrollHeight
        ) {
            this.props.loadMore();
        }
    };

    private loadMore() {
        if (this.props.loadMore) {
            this.props.loadMore();
        }
    }
}

const emptyContainerStyle: React.CSSProperties = {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "column",
    overflow: "hidden",
};
const emptyTableStyle: React.CSSProperties = {
    paddingLeft: rootStyles.spacers.large,
    paddingRight: rootStyles.spacers.large,
    paddingTop: rootStyles.spacers.large,
    flex: 1,
    paddingBottom: rootStyles.spacers.huge,
    flexDirection: "row",
    alignItems: "stretch",
    alignContent: "stretch",
    justifyContent: "center",
};
const emptyTableTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    textAlign: "center",
};
const tableContainerStyle: React.CSSProperties = {
    paddingBottom: rootStyles.spacers.medium,
    background: "transparent",
    borderWidth: 0,
};
const tableStyle: React.CSSProperties = {
    paddingTop: rootStyles.spacers.medium,
    paddingBottom: rootStyles.spacers.huge,
};
const autoSizerStyle: React.CSSProperties = {
    flex: "1 1 auto",
};

const VisibleListOverflow = styled.div`
    .ReactVirtualized__Grid__innerScrollContainer {
        overflow: visible !important;
    }

    .ReactVirtualized__Grid {
        overflow: visible !important;
    }
`;

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

export const Table = connect(mapStateToProps)(TableClass);

export const VerticalSpacedTable = styled(Table)`
    margin-top: ${rootStyles.spacers.huge}px;
`;
