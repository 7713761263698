import { AnyAction } from "redux";
import _ from "lodash";
import { reportingActions, userActions } from "../actions";
import {
    reportData,
    TimeRange,
    queryResult,
    songPerformanceData,
    albumPerformanceData,
    songPerformanceResult,
    albumPerformanceResult,
    playlistPerformanceResult,
    playlistPerformanceData,
    stationPerformanceResult,
    stationPerformanceData,
    inProgressData,
} from "../../models";
import { DEFAULT_TIME_RANGE, paths } from "../../utils";
import {
    csvButtonLoadingStatusData,
    csvButtonLoadingStatusInitialState,
} from "../../export";

export type ReportingState = Readonly<{
    reports: reportData; // key to queryResult map, where key is a hash of timerange
    songPerformanceReports: songPerformanceData; // key to songPerformanceQueryResult map, where key is song asin
    albumPerformanceReports: albumPerformanceData; // key to albumPerformanceQueryResult map, where key is album asin
    playlistPerformanceReports: playlistPerformanceData;
    stationPerformanceReports: stationPerformanceData;
    inProgress: inProgressData;
    selectedRange: TimeRange;
    selectedAlbumAsin?: string;
    selectedStationAsin?: string;
    selectedPlaylistAsin?: string;
    selectedSongAsin?: string;
    startDate?: Date;
    endDate?: Date;
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
}>;

export const initialReportingState: ReportingState = {
    reports: new Map<string, queryResult>(),
    songPerformanceReports: new Map<string, songPerformanceResult>(),
    albumPerformanceReports: new Map<string, albumPerformanceResult>(),
    playlistPerformanceReports: new Map<string, playlistPerformanceResult>(),
    stationPerformanceReports: new Map<string, stationPerformanceResult>(),
    selectedRange: DEFAULT_TIME_RANGE,
    inProgress: new Map<string, boolean>(),
    csvButtonLoadingStatus: csvButtonLoadingStatusInitialState,
};

export const reportingReducer = (
    state: ReportingState = initialReportingState,
    action: AnyAction
) => {
    switch (action.type) {
        case reportingActions.getReportsCompleted.type: {
            const result: queryResult = action.payload.result;

            const existingReport = state.reports.get(result.responseId);
            if (existingReport) {
                const newReports = _.cloneDeep(state.reports);
                newReports.set(result.responseId, {
                    ...existingReport,
                    ...result,
                });

                return {
                    ...state,
                    reports: newReports,
                    inProgress: state.inProgress.set(
                        action.payload.requestPath,
                        false
                    ),
                };
            }

            const newReports = _.cloneDeep(state.reports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                reports: newReports,
                inProgress: state.inProgress.set(
                    action.payload.requestPath,
                    false
                ),
            };
        }
        case reportingActions.getReportsOverviewCompleted.type: {
            const result: queryResult = action.payload;

            const existingReport = state.reports.get(result.responseId);
            if (existingReport) {
                const newReports = _.cloneDeep(state.reports);
                newReports.set(result.responseId, {
                    ...existingReport,
                    ...result,
                });

                return {
                    ...state,
                    reports: newReports,
                    inProgress: state.inProgress.set(paths.reports, false),
                };
            }

            const newReports = _.cloneDeep(state.reports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                reports: newReports,
                inProgress: state.inProgress.set(paths.reports, false),
            };
        }
        case reportingActions.getVoiceReportsCompleted.type: {
            const result: queryResult = action.payload;

            const existingReport = state.reports.get(result.responseId);
            if (existingReport) {
                const newReports = _.cloneDeep(state.reports);
                newReports.set(result.responseId, {
                    ...existingReport,
                    ...result,
                });

                return {
                    ...state,
                    reports: newReports,
                    inProgress: state.inProgress.set(paths.voice, false),
                };
            }

            const newReports = _.cloneDeep(state.reports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                reports: newReports,
                inProgress: state.inProgress.set(paths.voice, false),
            };
        }
        case reportingActions.getFanTiersReportsCompleted.type: {
            const result: queryResult = action.payload;

            const existingReport = state.reports.get(result.responseId);
            if (existingReport) {
                const newReports = _.cloneDeep(state.reports);
                newReports.set(result.responseId, {
                    ...existingReport,
                    ...result,
                });

                return {
                    ...state,
                    reports: newReports,
                    inProgress: state.inProgress.set(paths.fans, false),
                };
            }

            const newReports = _.cloneDeep(state.reports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                reports: newReports,
                inProgress: state.inProgress.set(paths.fans, false),
            };
        }
        case reportingActions.getProgrammingReportsCompleted.type: {
            const result: queryResult = action.payload;

            const existingReport = state.reports.get(result.responseId);
            if (existingReport) {
                const newReports = _.cloneDeep(state.reports);
                newReports.set(result.responseId, {
                    ...existingReport,
                    ...result,
                });

                return {
                    ...state,
                    reports: newReports,
                    inProgress: state.inProgress.set(paths.programming, false),
                };
            }

            const newReports = _.cloneDeep(state.reports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                reports: newReports,
                inProgress: state.inProgress.set(paths.programming, false),
            };
        }
        case reportingActions.getSongPerformanceCompleted.type: {
            const result: songPerformanceResult = action.payload;
            const newReports = _.cloneDeep(state.songPerformanceReports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                songPerformanceReports: newReports,
                inProgress: state.inProgress.set(paths.songPerformance, false),
            };
        }
        case reportingActions.getAlbumPerformanceCompleted.type: {
            const result: albumPerformanceResult = action.payload;
            const newReports = _.cloneDeep(state.albumPerformanceReports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                albumPerformanceReports: newReports,
                inProgress: state.inProgress.set(paths.albumPerformance, false),
            };
        }
        case reportingActions.getPlaylistPerformanceCompleted.type: {
            const result: playlistPerformanceResult = action.payload;
            const newReports = _.cloneDeep(state.playlistPerformanceReports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                playlistPerformanceReports: newReports,
                inProgress: state.inProgress.set(
                    paths.playlistPerformance,
                    false
                ),
            };
        }
        case reportingActions.getStationPerformanceCompleted.type: {
            const result: stationPerformanceResult = action.payload;
            const newReports = _.cloneDeep(state.playlistPerformanceReports);
            newReports.set(result.responseId, result);

            return {
                ...state,
                stationPerformanceReports: newReports,
                inProgress: state.inProgress.set(
                    paths.stationPerformance,
                    false
                ),
            };
        }
        case reportingActions.getReportsOverviewInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(paths.reports, action.payload),
            };
        }
        case reportingActions.getReportsInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(
                    action.payload.requestPath,
                    action.payload.inProgress
                ),
            };
        }
        case reportingActions.getVoiceReportsInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(paths.voice, action.payload),
            };
        }
        case reportingActions.getFanTiersReportsInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(paths.fans, action.payload),
            };
        }
        case reportingActions.getProgrammingReportsInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(
                    paths.programming,
                    action.payload
                ),
            };
        }
        case reportingActions.getAlbumPerformanceInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(
                    paths.albumPerformance,
                    action.payload
                ),
            };
        }
        case reportingActions.getSongPerformanceInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(
                    paths.songPerformance,
                    action.payload
                ),
            };
        }
        case reportingActions.getPlaylistPerformanceInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(
                    paths.playlistPerformance,
                    action.payload
                ),
            };
        }
        case reportingActions.getStationPerformanceInProgress.type: {
            return {
                ...state,
                inProgress: state.inProgress.set(
                    paths.stationPerformance,
                    action.payload
                ),
            };
        }
        case reportingActions.setSelectedAlbumAsin.type: {
            return {
                ...state,
                selectedAlbumAsin: action.payload,
            };
        }
        case reportingActions.setSelectedPlaylistAsin.type: {
            return {
                ...state,
                selectedPlaylistAsin: action.payload,
            };
        }
        case reportingActions.setSelectedSongAsin.type: {
            return {
                ...state,
                selectedSongAsin: action.payload,
            };
        }
        case reportingActions.setSelectedStationAsin.type: {
            return {
                ...state,
                selectedStationAsin: action.payload,
            };
        }
        case reportingActions.removeSelectedAlbumAsin.type: {
            return {
                ...state,
                selectedAlbumAsin: undefined,
            };
        }
        case reportingActions.removeSelectedPlaylistAsin.type: {
            return {
                ...state,
                selectedPlaylistAsin: undefined,
            };
        }
        case reportingActions.removeSelectedSongAsin.type: {
            return {
                ...state,
                selectedSongAsin: undefined,
            };
        }
        case reportingActions.removeSelectedStationAsin.type: {
            return {
                ...state,
                selectedStationAsin: undefined,
            };
        }
        case userActions.selectArtistCompleted.type: {
            return {
                ...state,
                reports: new Map<string, queryResult>(),
                playlistPerformanceReports: new Map<string, queryResult>(),
                albumPerformanceReports: new Map<string, queryResult>(),
                stationPerformanceReports: new Map<string, queryResult>(),
                songPerformanceReports: new Map<string, queryResult>(),
            };
        }
        case reportingActions.updateTimeRange.type: {
            if (action.payload.timeRange === TimeRange.Custom) {
                return {
                    ...state,
                    selectedRange: action.payload.timeRange,
                    startDate: action.payload.startDate,
                    endDate: action.payload.endDate,
                };
            } else {
                return {
                    ...state,
                    selectedRange: action.payload.timeRange,
                    startDate: undefined,
                    endDate: undefined,
                };
            }
        }
        case reportingActions.clearState.type: {
            return {
                ...state,
                reports: new Map<string, queryResult>(),
                playlistPerformanceReports: new Map<string, queryResult>(),
                albumPerformanceReports: new Map<string, queryResult>(),
                stationPerformanceReports: new Map<string, queryResult>(),
                songPerformanceReports: new Map<string, queryResult>(),
            };
        }
        case reportingActions.csvDownloadInProgress.type: {
            return {
                ...state,
                csvButtonLoadingStatus: {
                    ...state.csvButtonLoadingStatus,
                    [action.payload]: true,
                },
            };
        }
        case reportingActions.csvDownloadCompleted.type: {
            return {
                ...state,
                csvButtonLoadingStatus: {
                    ...state.csvButtonLoadingStatus,
                    [action.payload]: false,
                },
            };
        }
        default:
            return state;
    }
};
