import { createSelector } from "reselect";
import { RootState } from "../reducers";
import { selectedArtistFromCatalogSelector } from "./artistSearchSelectors";

const getSignedIn = (state: RootState) => state.user.signedIn;

const getArtistSearchInProgress = (state: RootState) =>
    state.artistSearch.inProgress;
const getArtistSearchResults = (state: RootState) => state.artistSearch.artists;

const getHydrationInProgress = (state: RootState) =>
    state.catalog.hydrationInProgress;

const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);

const getTrackAlbumSearchInProgress = (state: RootState) =>
    state.contentSearch.inProgress;

const getTrackAlbumSearchResults = (state: RootState) =>
    state.contentSearch.items;

const getSelectedMediaItem = (state: RootState) =>
    state.artistIssue.reportIssueSelectedItem;

const getNewDisambiguationInProgress = (state: RootState) =>
    state.artistIssue.inProgress;

const getNewDisambiguationSuccess = (state: RootState) =>
    state.artistIssue.success;

const getPrevPath = (state: RootState) => state.user.prevPath;

export const reportArtistIssuePageSelectors = createSelector(
    [
        getSignedIn,
        getArtistSearchInProgress,
        getArtistSearchResults,
        getHydrationInProgress,
        getErrorFromStore,
        getTrackAlbumSearchInProgress,
        getTrackAlbumSearchResults,
        selectedArtistFromCatalogSelector,
        getSelectedMediaItem,
        getNewDisambiguationInProgress,
        getNewDisambiguationSuccess,
        getPrevPath,
    ],
    (
        signedIn,
        artistSearchInProgress,
        artistSearchResults,
        hydrationInProgress,
        errorFromStore,
        trackAlbumSearchInProgress,
        trackAlbumSearchResults,
        selectedArtist,
        selectedMediaItem,
        newDisambiguationInProgress,
        newDisambiguationSuccess,
        prevPath
    ) => {
        return {
            signedIn: signedIn,
            artistSearchInProgress: artistSearchInProgress,
            artists: artistSearchResults,
            hydrationInProgress,
            error: errorFromStore,
            trackAlbumSearchInProgress: trackAlbumSearchInProgress,
            trackAlbums: trackAlbumSearchResults,
            selectedArtist: selectedArtist,
            selectedMediaItem: selectedMediaItem,
            newDisambiguationInProgress: newDisambiguationInProgress,
            newDisambiguationSuccess: newDisambiguationSuccess,
            prevPath: prevPath,
        };
    }
);
