import { createSelector } from "reselect";
import { remoteSearchSelector } from "./artistSearchSelectors";
import { currentPathOutageStatusSelector } from "./outageStatusSelectors";
import { showModSelector, showCurationSelector } from "./merchCurateSelectors";
import {
    getBundleMap,
    getCurrentPath,
    getIsPrivileged,
    getRecentlyAddedToPlaylistsData,
    getSelectedArtistAsin,
    getTokenRefreshInProgress,
    getUserLocale,
    getUserSetting,
} from "./commonSelectors";

export const rootNavigationSelector = createSelector(
    [
        getIsPrivileged,
        getSelectedArtistAsin,
        getCurrentPath,
        remoteSearchSelector,
        getUserSetting,
        getTokenRefreshInProgress,
        getUserLocale,
        getRecentlyAddedToPlaylistsData,
        currentPathOutageStatusSelector,
        showModSelector,
        showCurationSelector,
        getBundleMap,
    ],
    (
        isPrivileged,
        selectedAsin,
        currentPath,
        doRemoteSearch,
        settings,
        refreshInProgress,
        userLocale,
        recentlyAddedToPlaylistData,
        outageStatus,
        showMod,
        showCuration,
        bundleMap
    ) => {
        return {
            isPrivileged: isPrivileged,
            selectedArtist: selectedAsin,
            currentPath: currentPath,
            doRemoteSearch: doRemoteSearch,
            settings: settings,
            refreshInProgress: refreshInProgress,
            userLocale: userLocale,
            recentlyAddedToPlaylistData: recentlyAddedToPlaylistData || [],
            outageStatus: outageStatus,
            showMerchTab: !!showMod || !!showCuration,
            bundleMap: bundleMap,
        };
    }
);
