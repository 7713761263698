import { createAction } from "redux-ts";
import {
    hydratedMerchItem,
    searchMerchCollectionsPayload,
    searchMerchPayload,
    curateMerchPayload,
    DrTeethCollection,
} from "../../models";

//search merch
export const merchSearch = createAction<searchMerchPayload>(
    "MERCH::MERCH_SEARCH"
);
export const merchSearchInProgress = createAction<boolean>(
    "MERCH::MERCH_SEARCH_IN_PROGRESS"
);
export const merchSearchCompleted = createAction<hydratedMerchItem[]>(
    "MERCH::MERCH_SEARCH_COMPLETED"
);
export const clearMerchSearchResults = createAction(
    "MERCH::CLEAR_MERCH_SEARCH_RESULTS"
);

//search collections
export const getMerchCollections = createAction<searchMerchCollectionsPayload>(
    "MERCH:GET_MERCH_COLLECTIONS"
);
export const getMerchCollectionsInProgress = createAction<boolean>(
    "MERCH::GET_MERCH_COLLECTIONS_IN_PROGRESS"
);
export const getMerchCollectionsCompleted = createAction<DrTeethCollection>(
    "MERCH::GET_MERCH_COLLECTIONS_COMPLETED"
);

//curateMerch
export const curateMerch = createAction<curateMerchPayload>(
    "MERCH::CURATE_MERCH"
);
export const curateMerchInProgress = createAction<boolean>(
    "MERCH::CURATE_MERCH_IN_PROGRESS"
);
export const curateMerchCompleted = createAction(
    "MERCH::CURATE_MERCH_COMPLETED"
);
