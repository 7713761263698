import * as React from "react";
import * as rootStyles from "../../../styles";
import { RadioElement } from ".";
import { testIDSuffixes } from "../../../../utils";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

export type RadioListProps = {
    label?: string;
    onChangeElement: (index: number) => void;
    elements: RadioData[];
    initialSelectedIndex?: number;
    id: string;
    horizontal?: boolean;
    centeredElements?: boolean;
    textNoWrap?: boolean;
    elementSpacing?: number;
    containerStyling?: React.CSSProperties;
};

type State = {
    selectedIndex?: number;
};

export type RadioData = {
    primary: string;
    secondary?: string;
};

export class RadioList extends React.Component<RadioListProps, State> {
    constructor(props: RadioListProps) {
        super(props);
        this.state = {
            selectedIndex: props.initialSelectedIndex,
        };
    }

    componentDidUpdate(prevProps: RadioListProps) {
        if (
            prevProps.initialSelectedIndex !== this.props.initialSelectedIndex
        ) {
            this.setState({ selectedIndex: this.props.initialSelectedIndex });
        }
    }

    render() {
        return (
            <div
                style={{
                    ...(this.props.containerStyling
                        ? this.props.containerStyling
                        : radioContainer),
                }}
            >
                {this.props.label && (
                    <p
                        style={labelStyle}
                        id={`${this.props.id}${testIDSuffixes.label}`}
                    >
                        {this.props.label}
                    </p>
                )}
                {this.props.horizontal ? (
                    this.props.centeredElements ? (
                        <CenteredRow
                            style={{
                                gap: this.props.elementSpacing,
                            }}
                        >
                            {this.renderButtonList(this.props.elements)}
                        </CenteredRow>
                    ) : (
                        <Row
                            style={{
                                gap: this.props.elementSpacing,
                            }}
                        >
                            {this.renderButtonList(this.props.elements)}
                        </Row>
                    )
                ) : (
                    this.renderButtonList(this.props.elements)
                )}
            </div>
        );
    }

    private renderButtonList = (options: RadioData[]) => {
        const ret = [];

        for (let i = 0; i < options.length; i++) {
            this.props.horizontal
                ? ret.push(
                      <Col style={{ padding: 0 }}>
                          <RadioElement
                              primary={options[i].primary}
                              secondary={options[i].secondary}
                              onButtonSelected={() => this.onButtonSelected(i)}
                              selected={
                                  i ===
                                  (this.state ? this.state.selectedIndex : -1)
                              }
                              key={i}
                              id={this.props.id + testIDSuffixes.item + i}
                              primaryTextNoWrap={this.props.textNoWrap}
                          />
                      </Col>
                  )
                : ret.push(
                      <RadioElement
                          primary={options[i].primary}
                          secondary={options[i].secondary}
                          onButtonSelected={() => this.onButtonSelected(i)}
                          selected={
                              i === (this.state ? this.state.selectedIndex : -1)
                          }
                          key={i}
                          id={this.props.id + testIDSuffixes.item + i}
                      />
                  );
        }

        return ret;
    };

    private onButtonSelected = (index: number) => {
        this.setState({ selectedIndex: index });
        this.props.onChangeElement(index);
    };
}

const radioContainer: React.CSSProperties = {
    flex: 1,
};

const labelStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    marginBottom: rootStyles.spacers.small,
};

/**
 * Specifically for landing pages to style RadioElement alignment
 */
const CenteredRow = styled(Row)`
    .row {
        justify-content: center;
    }
`;
