import { AnyAction } from "redux";
import { artistImageActions } from "../actions";

export type ImageUploadState = Readonly<{
    inProgress: boolean;
    uploadComplete: boolean;
}>;

const initialState = {
    inProgress: false,
    uploadComplete: false,
};

export const imageUploadReducer = (
    state: ImageUploadState = initialState,
    action: AnyAction
) => {
    switch (action.type) {
        case artistImageActions.artistImageUploadInProgress.type:
            return { ...state, inProgress: action.payload };
        case artistImageActions.artistImageUploadComplete.type:
            return { ...state, uploadComplete: action.payload };
        default:
            return state;
    }
};
