import moment from "moment";
import { getCookieByKey } from "../service/common";
import {
    uiPageViewMetricsPayload,
    ClientMetricsType,
    EntityType,
    uiClickMetricsPayload,
    cookieStrings,
    PathToPageIdMap,
    OpsMetricDeviceInfo,
    BatchOpsMetricsPayload,
    OpsMetricsType,
    metricDataPoints,
} from "../models";
import { ASIN_FORMAT, paths } from "./constant";
import { DeviceUtil } from "./deviceUtil";

// Constant to define the device type family for ZiggyWeb
export const DEVICE_TYPE = "A16P06TZYJE5VL";

export const OPS_METRIC_BATCH_LIMIT = 25;
export const MAX_OPS_METRIC_LOG_SIZE = 10240;
export const AM4A_WEB_PRODUCT_ID = "811"; // Background: https://jira.music.amazon.dev/browse/MP-238

// DeviceId is stored as UBID cookie on web
// https://w.amazon.com/bin/view/Amazon_Music/Data/Device_ID_Stability/
export function getDeviceId(): string {
    return (
        getCookieByKey(cookieStrings.ubidJP) ||
        getCookieByKey(cookieStrings.ubidUS) ||
        ""
    );
}

// Return current timestamp in UTC in ISO format
export function getMetricEventTime() {
    return moment().utc().format();
}

export const buildUIPageViewPayload = (
    pageType: string,
    detailPageItemId?: string
): uiPageViewMetricsPayload => {
    return {
        clientMetricsType: ClientMetricsType.UiPageView,
        deviceType: DEVICE_TYPE,
        deviceId: getDeviceId(),
        eventTime: getMetricEventTime(),
        pageType: pageType,
        detailPageItemId: detailPageItemId,
        productId: AM4A_WEB_PRODUCT_ID,
    };
};

export const buildUIClickPayload = (
    actionType: string,
    pageType?: string,
    entityId?: string,
    entityType?: EntityType
): uiClickMetricsPayload => {
    return {
        clientMetricsType: ClientMetricsType.UiClick,
        deviceType: DEVICE_TYPE,
        deviceId: getDeviceId(),
        eventTime: getMetricEventTime(),
        actionType: actionType,
        pageType: pageType,
        entityId: entityId,
        entityType: entityType,
        productId: AM4A_WEB_PRODUCT_ID,
    };
};

export const getDeviceInformation = (): OpsMetricDeviceInfo => {
    return {
        userAgent: DeviceUtil.getUserAgent(),
        browserName: DeviceUtil.getBrowserName(),
        browserVersion: DeviceUtil.getBrowserFullVersion(),
        deviceType: DEVICE_TYPE,
        osName: DeviceUtil.getOSName(),
        osVersion: DeviceUtil.getOSVersion(),
        mobileVendor: DeviceUtil.getMobileVendor(),
        mobileModel: DeviceUtil.getMobileModel(),
        engineName: DeviceUtil.getEngineName(),
        engineVersion: DeviceUtil.getEngineVersion(),
    };
};

export const createSuccessOpsMetricsPayload = (
    functionName: string
): BatchOpsMetricsPayload => {
    return {
        functionName: functionName,
        timeStamp: new Date().toISOString(),
        opsMetricsType: OpsMetricsType.SUCCESS,
    };
};

export const createErrorOpsMetricsPayload = (
    functionName: string,
    stackTrace?: string,
    context?: metricDataPoints
): BatchOpsMetricsPayload => {
    return {
        functionName: functionName,
        timeStamp: new Date().toISOString(),
        opsMetricsType: OpsMetricsType.ERROR,
        stackTrace: stackTrace,
        context: context,
    };
};

// Based on the input web path string that may contain parameters input,
// check all the subStrings that exist in the paths constant to get all the possible path strings
// Get the subPath string with the longest string length as this is the current page path
// Return the pageId string based on the path-pageId mapping
export function getPageIdFromPath(inputPath: string): string {
    const subPathArray = new Array();
    const pathArray = Object.values(paths);
    pathArray.forEach((pathStr) => {
        if (inputPath.includes(pathStr)) {
            subPathArray.push(pathStr);
        }
    });

    const subPath = getLongestSubPathFromArray(subPathArray);

    return PathToPageIdMap.get(subPath) || "";
}

// Asin can only be passed in the last string split by "/"
// Get the possible asin string from the last split path and match with the defined Asin format
// Return the asin when there is a match
export function getAsinFromPath(inputPath: string): string | undefined {
    const splitPath = inputPath.split("/");
    const possibleAsinString = splitPath[splitPath.length - 1];
    if (possibleAsinString.match(ASIN_FORMAT)) {
        return possibleAsinString;
    } else {
        return undefined;
    }
}

function getLongestSubPathFromArray(arr: string[]): string {
    return arr.sort(function (a, b) {
        return b.length - a.length;
    })[0];
}
