import axios from "axios";
import {
    createMerchCollectionRequest,
    createMerchCollectionResponse,
    deleteMerchCollectionRequest,
    deleteMerchCollectionResponse,
    hydrateMerchRequest,
    hydrateMerchResponse,
    searchMerchCollectionsRequest,
    searchMerchCollectionsResponse,
    searchMerchRequest,
    searchMerchResponse,
    updateMerchCollectionRequest,
    updateMerchCollectionResponse,
} from "../../models";
import { getEndpoints, testHeaders } from "../common";

const baseUrls = getEndpoints();

const baseCollectionsUrl = `${baseUrls.merch}/collections`;
const createCollection = `${baseCollectionsUrl}/create`;
const updateCollection = `${baseCollectionsUrl}/update`;
const searchCollection = `${baseCollectionsUrl}/search`;
const deleteCollection = `${baseCollectionsUrl}/delete`;

const baseMerchUrl = `${baseUrls.merch}/merch`;
const searchMerch = `${baseMerchUrl}/search`;
const hydrateMerch = `${baseMerchUrl}/hydrate`;

export async function getMerchSearchResults(
    request: searchMerchRequest
): Promise<searchMerchResponse> {
    const result = await axios.post(searchMerch, request, {
        headers: {
            ...testHeaders(),
        },
        withCredentials: true,
    });
    return result.data;
}

export async function getHydratedMerch(
    request: hydrateMerchRequest
): Promise<hydrateMerchResponse> {
    const result = await axios.post(hydrateMerch, request, {
        headers: {
            ...testHeaders(),
        },
        withCredentials: true,
    });
    return result.data;
}

export async function searchMerchCollections(
    request: searchMerchCollectionsRequest,
    teamId?: string
): Promise<searchMerchCollectionsResponse> {
    const result = await axios.post(searchCollection, request, {
        headers: {
            "x-team-id": teamId,
            ...testHeaders(),
        },
        withCredentials: true,
    });
    return result.data;
}

export async function createMerchCollection(
    request: createMerchCollectionRequest,
    teamId?: string
): Promise<createMerchCollectionResponse> {
    const result = await axios.post(createCollection, request, {
        headers: {
            "x-team-id": teamId,
            ...testHeaders(),
        },
        withCredentials: true,
    });
    return result.data;
}

export async function updateMerchCollection(
    request: updateMerchCollectionRequest,
    teamId?: string
): Promise<updateMerchCollectionResponse> {
    const result = await axios.post(updateCollection, request, {
        headers: {
            "x-team-id": teamId,
            ...testHeaders(),
        },
        withCredentials: true,
    });
    return result.data;
}

export async function deleteMerchCollection(
    request: deleteMerchCollectionRequest,
    teamId?: string
): Promise<deleteMerchCollectionResponse> {
    const result = await axios.post(deleteCollection, request, {
        headers: {
            "x-team-id": teamId,
            ...testHeaders(),
        },
        withCredentials: true,
    });
    return result.data;
}
