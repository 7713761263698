import { generateColor, glass, colors } from "./colors";
/***
 *
 * Core styles and sizes
 *
 * ***/
export const spacers = {
    nano: 2,
    micro: 4, // yellow
    mini: 8, // orange
    small: 12, // pink
    base: 16, // red
    medium: 20, // purple
    large: 24, // blue
    huge: 32, // light blue
    giant: 36, // light green
    epic: 48, // dark green
};

export const borderRadius = {
    card: 8,
};

export const bootstrapColPadding = 15;

export const breakpoints = {
    xs: 0,
    sm: 360,
    md: 480,
    lg: 600,
    xl: 840,
    xl2: 1024,
    xl3: 1280,
    xl4: 1440,
    tv: 2660,
};

export const icons = {
    tiny: 16,
    small: 20,
    medium: 24,
    large: 32,
    large2: 36,
    extraLarge: 40,
    huge: 48,
};

export enum ButtonSize {
    small = 40,
    medium = 48,
    large = 64,
}

export enum ImageSize {
    Large = 170,
    Medium = 100,
    Mini = 56,
    Micro = 32,
}

export const blurAmount = 5;

export const webFontSizes = {
    h1: 100,
    h2: 60,
    h2_xl: 35, // UX will use this id to refer to `extra long` title strings
    h3: 28,
    h4: 20,
    t1: 16,
    t2: 14,
    t3: 13,
    t4: 12,
};

export const mobileFontSizes = {
    h1: 38,
    h2: 32,
    h2_xl: 25, // UX will use this id to refer to `extra long` title strings
    h3: 28,
    h4: 18,
    t1: 15,
    t2: 13,
    t3: 12,
    t4: 11,
};

export const errorStyles = {
    warningContainer: {
        backgroundColor: generateColor("#EF9703", glass._2),
        borderWidth: 1,
        borderColor: "#EF9703",
        alignSelf: "stretch",
        flex: 1,
    },
    errorContainer: {
        backgroundColor: generateColor("#FF0000", glass._2),
        borderWidth: 1,
        borderColor: "#F43E21",
        alignSelf: "stretch",
        flex: 1,
        borderRadius: 8,
        margin: spacers.small,
        padding: spacers.small,
    },
};

export const successStyles = {
    successContainer: {
        backgroundColor: generateColor("#00FF00", glass._2),
        borderWidth: 1,
        borderColor: "#00FF00",
        alignSelf: "stretch",
        flex: 1,
        borderRadius: 8,
        margin: spacers.small,
        padding: spacers.small,
    },
};

export const responsiveViewWidthBreakpoints = {
    large: 992,
    medium: 640,
    small: 374,
};
