import * as React from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import { Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { commonSelectors } from "../../../store/selectors";
import "./override.css";
import {
    breakpoints,
    colors,
    glassColors,
    spacers,
    textStyles,
} from "../../styles";
import {
    MediumOutlineButton,
    MediumSolidButton,
    BlurredModal,
} from "../common";
import {
    getLocalizedString,
    webLocale,
    getWebLocale,
    dateRangeLocaleMap,
    defaultLocale,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import { CalendarError } from "../../components";
import { BundleMap } from "../../../models";

export type CustomDatePickerProps = {
    initialStartDate: Date;
    initialEndDate: Date;
    hideModal: any;
    saveDates: any;
    minDate: Date;
    maxDate: Date;
    confirmDisabled?: boolean;
    withinRange?: (startDate: Date, endDate: Date) => void;
    rangeMessage?: string;
    rangeError?: string;
    id: string;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
    initialStartDate,
    initialEndDate,
    minDate,
    maxDate,
    saveDates,
    hideModal,
    confirmDisabled,
    withinRange,
    rangeMessage,
    rangeError,
    id,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const [startDate, setStartDate] = React.useState(
        new Date(initialStartDate)
    );
    const [endDate, setEndDate] = React.useState(new Date(initialEndDate));
    const [startSelected, setStartSelected] = React.useState(true);
    const [endSelected, setEndSelected] = React.useState(false);
    const [focusedRange, setFocusedRange] = React.useState([0, 0]);

    const selectedRange = {
        startDate,
        endDate,
        key: "selection",
    };

    const onConfirm = () => {
        saveDates(new Date(startDate), new Date(endDate));
    };
    const onChange = (range: any) => {
        if (startSelected && range.selection.startDate < endDate) {
            if (withinRange) {
                withinRange(range.selection.startDate, endDate);
            }
            setStartDate(range.selection.startDate);
        } else if (endSelected && range.selection.endDate > startDate) {
            if (withinRange) {
                withinRange(startDate, range.selection.endDate);
            }
            setEndDate(range.selection.endDate);
        } else {
            if (withinRange) {
                withinRange(range.selection.startDate, range.selection.endDate);
            }
            setStartDate(range.selection.startDate);
            setEndDate(range.selection.endDate);
        }
    };

    const onRangeFocusChange = (focusedRange: any) => {
        setStartSelected(focusedRange[1] === 0);
        setEndSelected(focusedRange[1] === 1);
        setFocusedRange(focusedRange);
    };

    const getDateRangeLocale = () => {
        const locale = getWebLocale();
        return dateRangeLocaleMap.has(locale)
            ? dateRangeLocaleMap.get(locale)
            : dateRangeLocaleMap.get(defaultLocale);
    };

    return (
        <ModalContainer>
            <BlurredModal
                id="blurred-modal"
                show={true}
                centered
                top="40%"
                minwidth="256px"
                maxwidth="332px"
            >
                <Modal.Body>
                    {rangeMessage && (
                        <Row style={calendarHeaderStyle}>
                            <span
                                style={{
                                    ...textStyles.secondary,
                                    color: colors.primary,
                                }}
                            >
                                {rangeMessage}
                            </span>
                        </Row>
                    )}
                    <DateLabelContainer>
                        <StartContainer>
                            <IndexSpan>
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId: stringIds.Reports.timeRangeStart,
                                })}
                            </IndexSpan>
                        </StartContainer>
                        <EndContainer>
                            <IndexSpan>
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.REPORTS_STRINGS,
                                    stringId: stringIds.Reports.timeRangeEnd,
                                })}
                            </IndexSpan>
                        </EndContainer>
                    </DateLabelContainer>

                    <Row>
                        <DateRange
                            locale={getDateRangeLocale()}
                            minDate={minDate}
                            maxDate={maxDate}
                            rangeColors={[glassColors.accent5]}
                            onChange={onChange}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            ranges={[selectedRange]}
                            focusedRange={focusedRange}
                            onRangeFocusChange={onRangeFocusChange}
                        />
                    </Row>
                    {confirmDisabled && rangeError && (
                        <Row>
                            <CalendarError
                                text={rangeError}
                                id={`${id}_Error`}
                            />
                        </Row>
                    )}
                    <Row style={{ alignItems: "center" }}>
                        <StyledCol md="6">
                            <MediumOutlineButton
                                onClick={hideModal}
                                title={getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.GENERIC_STRINGS,
                                    stringId: stringIds.Generic.cancel,
                                })}
                                containerStyle={{ width: "100%" }}
                                id={`${id}_CancelButton`}
                            />
                        </StyledCol>
                        <StyledCol md="6">
                            <MediumSolidButton
                                onClick={onConfirm}
                                title={getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.GENERIC_STRINGS,
                                    stringId: stringIds.Generic.confirm,
                                })}
                                containerStyle={{ width: "100%" }}
                                disabled={confirmDisabled}
                                id={`${id}_ConfirmButton`}
                            />
                        </StyledCol>
                    </Row>
                </Modal.Body>
            </BlurredModal>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
    .modal-dialog-centered {
        @media (max-width: ${breakpoints.lg}px) {
            justify-content: center;
        }
    }
`;

const DateLabelContainer = styled.div`
    width: 100%;
    display: flex;
`;

const StartContainer = styled.div`
    margin-left: ${spacers.base}px;
`;

const EndContainer = styled.div`
    margin-left: 130px;
`;

const StyledCol = styled(Col)`
    margin-top: 20px;
`;

const IndexSpan = textStyles.IndexSpan;

const calendarHeaderStyle: React.CSSProperties = {
    justifyContent: "center",
    paddingBottom: spacers.small,
};

export default CustomDatePicker;
