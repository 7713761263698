import { createAction } from "redux-ts";
import {
    album,
    albumRelease,
    artist,
    group,
    itemProps,
    track,
} from "../../models";

export const selectAlbumRelease = createAction<albumRelease>(
    "PITCH::SELECT_ALBUM_RELEASE"
);
export const selectTrack = createAction<string>("PITCH::SELECT_TRACK_ASIN");
export const selectStep = createAction<number>("PITCH::SELECT_STEP");

export const setDescription = createAction<string>("PITCH::SET_DESCRIPTION");

export const setGenresProps = createAction<group[]>("PITCH::SET_GENRES_PROPS");

export const setCompositionsProps = createAction<itemProps[]>(
    "PITCH::SET_COMPOSITINS_PROPS"
);

export const setSongHasVocals = createAction<boolean>(
    "PITCH::SET_SONG_HAS_VOCALS"
);

export const setLanguagesProps = createAction<group[]>(
    "PITCH::SET_LANGUAGES_PROPS"
);

export const setSongMoodsProps = createAction<itemProps[]>(
    "PITCH::SET_SONG_MOODS_PROPS"
);

export const setSongStylesProps = createAction<itemProps[]>(
    "PITCH::SET_SONG_STYLES_PROPS"
);

export const setRelatedArtistProps = createAction<group[]>(
    "PITCH::SET_RELATED_ARITSTS_PROPS"
);

export const setLocationProps = createAction<group[]>(
    "PITCH::SET_LOCATION_PROPS"
);

export const clearAll = createAction("PITCH::CLEAR_ALL");
