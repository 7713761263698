import * as React from "react";
import { Row, Col } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { testIDSuffixes } from "../../../utils";
import { ImageWithFallback, Icon, IconsList } from "../common";

export type ContentListItemProps = {
    label?: string;
    primary?: string;
    secondary?: string;
    tertiary?: string;
    index?: number;
    onClick?: any;
    image?: string;
    fallbackImage?: any;
    squareImage?: boolean;
    imageDescription?: string;
    leftIcon?: any;
    leftIconDescription?: string;
    rightIcon?: any;
    rightIconDescription?: string;
    isSelected?: boolean;
    id: string;
    showBottomBorder?: boolean;
};

export class ContentListItem extends React.PureComponent<ContentListItemProps> {
    render() {
        const style = this.props.showBottomBorder
            ? lastItemContainerStyle
            : containerStyle;
        const styleWithSelection = this.props.isSelected
            ? { ...selectedRow, ...style }
            : style;

        const cursor = this.props.onClick ? "pointer" : "inherit";

        return (
            <Row
                onClick={this.props.onClick}
                id={this.props.id}
                style={{ ...styleWithSelection, cursor: cursor }}
            >
                {this.props.image || this.props.fallbackImage ? (
                    <div
                        id={this.props.id + "-Image"}
                        style={{ alignSelf: "center" }}
                    >
                        <ImageWithFallback
                            style={
                                this.props.squareImage
                                    ? rootStyles.imageStyles.miniSquareImage
                                    : artistImage
                            }
                            source={this.props.image}
                            fallback={this.props.fallbackImage}
                            id={`${this.props.id}_Image`}
                        />
                    </div>
                ) : null}
                {this.props.index && (
                    <Col id={this.props.id + "-Index"} style={indexContainer}>
                        <Row style={indexStyle}>
                            <span style={indexTextStyle}>
                                {this.props.index}
                            </span>
                        </Row>
                    </Col>
                )}
                <Col>
                    {this.props.primary && (
                        <Row
                            id={this.props.id + "-Primary"}
                            style={textContainer}
                        >
                            {this.props.label && (
                                <span style={rootStyles.textStyles.label}>
                                    {this.props.label}
                                </span>
                            )}
                            <span
                                style={
                                    this.props.isSelected
                                        ? {
                                              ...textStyle,
                                              color: rootStyles.colors.accent,
                                          }
                                        : textStyle
                                }
                            >
                                {this.props.primary}
                            </span>
                            {this.props.secondary && (
                                <Row id={this.props.id + "-Secondary"}>
                                    <span
                                        style={rootStyles.textStyles.secondary}
                                    >
                                        {this.props.secondary}
                                    </span>
                                </Row>
                            )}
                            {this.props.tertiary && (
                                <Row id={this.props.id + "-Tertiary"}>
                                    <span
                                        style={rootStyles.textStyles.secondary}
                                    >
                                        {this.props.tertiary}
                                    </span>
                                </Row>
                            )}
                        </Row>
                    )}
                </Col>
                {this.props.leftIcon && (
                    <Col
                        id={this.props.id + "-LeftIcon"}
                        style={iconButtonContainer}
                    >
                        <Icon
                            icon={this.props.leftIcon}
                            size={rootStyles.icons.small}
                            id={this.props.id + testIDSuffixes.leftIcon}
                            iconDescription={this.props.leftIconDescription}
                        />
                    </Col>
                )}
                {this.props.rightIcon && (
                    <Col
                        id={this.props.id + "-RightIcon"}
                        style={iconButtonContainer}
                    >
                        <Row style={{ height: "100%", alignItems: "center" }}>
                            <Icon
                                icon={this.props.rightIcon}
                                size={rootStyles.icons.small}
                                id={this.props.id + testIDSuffixes.rightIcon}
                                iconDescription={
                                    this.props.rightIconDescription
                                }
                            />
                        </Row>
                    </Col>
                )}
                {!this.props.rightIcon && this.props.isSelected && (
                    <Col style={iconButtonContainer}>
                        <Icon
                            icon={IconsList.action_done}
                            size={rootStyles.icons.small}
                            color={rootStyles.colors.accent}
                            id={this.props.id + testIDSuffixes.rightIcon}
                            iconDescription={this.props.rightIconDescription}
                        />
                    </Col>
                )}
            </Row>
        );
    }
}

const sharedContainerStyle: React.CSSProperties = {
    flex: 1,
    marginTop: rootStyles.spacers.mini,
    paddingBottom: rootStyles.spacers.micro,
    marginBottom: rootStyles.spacers.micro,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
};

const containerStyle: React.CSSProperties = {
    ...sharedContainerStyle,
};

const lastItemContainerStyle: React.CSSProperties = {
    ...sharedContainerStyle,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: rootStyles.glassColors.primary3,
};

const iconButtonStyle: React.CSSProperties = {
    height: rootStyles.icons.tiny,
    width: rootStyles.icons.tiny,
};
const textContainer: React.CSSProperties = {
    flex: 1,
    minHeight: 64,
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
};
const indexContainer: React.CSSProperties = {
    flex: 1,
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 40,
};
const indexStyle: React.CSSProperties = {
    backgroundColor: rootStyles.colors.accent,
    borderRadius: 32,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    flex: 1,
    padding: rootStyles.spacers.micro,
    maxHeight: 20,
};
const indexTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    color: rootStyles.colors.secondary,
};
const textStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
};
const leftIconContainer: React.CSSProperties = {
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    height: 64,
    width: 40,
    marginRight: rootStyles.spacers.base,
    flex: 0,
};
const iconButtonContainer: React.CSSProperties = {
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    height: 64,
    width: 40,
    flex: 0,
    paddingRight: 0,
};
const artistImage: React.CSSProperties = {
    height: 56,
    width: 56,
    maxHeight: 56,
    maxWidth: 56,
    borderRadius: 28,
    flex: 1,
    alignSelf: "center",
};
const selectedRow: React.CSSProperties = {
    borderBottomWidth: 1,
    borderBottomColor: rootStyles.glassColors.primary3,
};
