import { metricDataPoints } from "./telemetry";

export enum OpsMetricsType {
    INFO = "INFO",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export type SingleOpsMetricInfo = {
    functionName: string;
    timeStamp: string;
    opsMetricsType: OpsMetricsType;
    stackTrace?: string;
    context?: metricDataPoints;
};

export type OpsMetricDeviceInfo = {
    userAgent: string;
    browserName: string;
    browserVersion: string;
    deviceType: string;
    osName?: string;
    osVersion?: string;
    mobileVendor?: string;
    mobileModel?: string;
    engineName: string;
    engineVersion: string;
};
