import React, { useCallback, useEffect } from "react";
import { AnyAction, Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import * as rootStyles from "../../styles";
import { BundleMap } from "../../../models";
import { userActions } from "../../../store";
import {
    ClaimHeaderWithBackButton,
    GoBackButton,
    HeightSpacer,
    IconsList,
    MediumSolidButton,
    SvgIcon,
} from "../../components";
import { getLocalizedString, paths } from "../../../utils";
import { styledTitle } from "../../components/common/Title";
import { getBundleMap } from "../../../store/selectors/commonSelectors";
import { vendorLabelFlowContainerStyle } from "../../styles/containerStyles";

const testIDPrefix = "ClaimRequestInviteScreen";

const SvgEllipseWrappedIcon = () => {
    return (
        <div style={SvgEllipseWrappedIconContainer}>
            <SvgIcon
                Icon={IconsList.ic_ellipse}
                size={rootStyles.icons.huge * 2}
                id={`${testIDPrefix}_Ellipse`}
            />
            <SvgIcon
                Icon={IconsList.ic_disk}
                size={rootStyles.icons.huge}
                fill={rootStyles.glassColors.primary4}
                id={`${testIDPrefix}_Disk`}
                style={{ position: "absolute" }}
            />
        </div>
    );
};

const ClaimRequestInviteScreen: React.FC<{}> = () => {
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const history = useHistory();
    const location = useLocation() as { state: { goBackPath: string } };
    const bundleMap: BundleMap = useSelector(getBundleMap);

    const updateCurrentPath = useCallback(
        (payload: string) => dispatch(userActions.updateCurrentPath(payload)),
        [dispatch]
    );

    useEffect(() => {
        updateCurrentPath(window.location.pathname);
    }, []);

    const goBackPath: string =
        location.state?.goBackPath ?? paths.requesterType;

    return (
        <Container style={rootContainerStyle}>
            <ClaimHeaderWithBackButton />
            <GoBackButton
                style={{ alignSelf: "flex-start" }}
                onClick={() => history.push(goBackPath)}
                id={testIDPrefix}
            />
            <div style={vendorLabelFlowContainerStyle}>
                <HeightSpacer
                    smallScreenSize={rootStyles.spacers.small}
                    largeScreenSize={rootStyles.spacers.huge}
                />
                <SvgEllipseWrappedIcon />
                <div style={{ height: rootStyles.spacers.base }} />
                <styledTitle.h2 style={titleStyle}>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CLAIMREQUESTINVITE_STRINGS,
                        stringId: stringIds.ClaimRequestInvite.title,
                    })}
                </styledTitle.h2>
                <div style={{ height: rootStyles.spacers.base }} />
                <span style={primaryTextStyle}>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CLAIMREQUESTINVITE_STRINGS,
                        stringId: stringIds.ClaimRequestInvite.body,
                    })}
                </span>
                <div style={{ height: rootStyles.spacers.huge }} />
                <span style={secondaryTextStyle}>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CLAIMREQUESTINVITE_STRINGS,
                        stringId: stringIds.ClaimRequestInvite.dontHaveATeamYet,
                    })}
                </span>
                <div style={{ height: rootStyles.spacers.base }} />
                <div>
                    <MediumSolidButton
                        id={`${testIDPrefix}-LearnMoreButton`}
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.CLAIMREQUESTINVITE_STRINGS,
                            stringId: stringIds.ClaimRequestInvite.button,
                        })}
                        containerStyle={{ flex: 1 }}
                        onClick={() => history.push(paths.vendorFlowLearnMore)}
                    />
                </div>
            </div>
        </Container>
    );
};

const rootContainerStyle: React.CSSProperties = {
    ...rootStyles.containerStyles.rootViewContainer,
    margin: "auto",
    display: "flex",
    paddingLeft: rootStyles.spacers.base,
    paddingRight: rootStyles.spacers.base,
    alignItems: "center",
};

const titleStyle: React.CSSProperties = {
    textAlign: "center",
};

const primaryTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    textAlign: "center",
};

const secondaryTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    textAlign: "center",
};

const SvgEllipseWrappedIconContainer: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export default ClaimRequestInviteScreen;
