import * as React from "react";
import * as rootStyles from "../../../styles";
import { testIDSuffixes } from "../../../../utils";
import { TitleDescriptionElement } from "./TitleDescriptionElement";

export type TitleDescriptionListProps = {
    label?: string;
    id: string;
    titleDescriptionPairs: string[][];
};

export class TitleDescriptionList extends React.Component<TitleDescriptionListProps> {
    render() {
        return (
            <div style={listContainer}>
                {this.props.label && (
                    <p
                        style={labelStyle}
                        id={`${this.props.id}${testIDSuffixes.label}`}
                    >
                        {this.props.label}
                    </p>
                )}
                {this.renderButtonList()}
            </div>
        );
    }

    private renderButtonList = () => {
        const ret = [];
        for (let i = 0; i < this.props.titleDescriptionPairs.length; i++) {
            ret.push(
                <TitleDescriptionElement
                    primary={this.props.titleDescriptionPairs[i][0]}
                    secondary={this.props.titleDescriptionPairs[i][1]}
                    elementNumber={i}
                    key={i}
                    id={`${this.props.id}${testIDSuffixes.item}${i}`}
                />
            );
        }

        return ret;
    };
}

const listContainer: React.CSSProperties = {
    flexDirection: "column",
};

const labelStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    marginBottom: rootStyles.spacers.small,
};
