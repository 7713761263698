import React, { Dispatch } from "react";
import { connect } from "react-redux";
import { IntercomProvider } from "react-use-intercom";
import {
    customerSupportSelector,
    selectedArtistFromCatalogSelector,
} from "../../../store/selectors";
import { RootState } from "../../../store/reducers";

import CustomerSupportHome from "./CustomerSupportHome";
import {
    artist,
    clientMetricsPayload,
    EntityType,
    BundleMap,
    CustomerSupportData,
    GlobalModal,
    IntercomEvent,
    ModalRenderFunction,
    PathToPageIdMap,
    userData,
} from "../../../models";
import { CustomerSupportUnavailableModal } from "./modals";
import {
    globalNotificationsActions,
    oAuthActions,
    clientMetricsActions,
} from "../../../store/actions";
import { AnyAction } from "redux";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { buildUIClickPayload, buildUIPageViewPayload } from "../../../utils";

type StateProps = {
    customerSupport?: CustomerSupportData;
    intercomEvent?: IntercomEvent;
    showIntercomMessenger: boolean;
    hasIntercomFeaturePermission: boolean;
    hideIntercomLauncher: boolean;
    bundleMap: BundleMap;
    currentPath?: string;
    selectedArtist?: artist;
    locale: string;
    userData: userData;
};

type DispatchProps = {
    requestModal: (payload: ModalRenderFunction) => void;
    setIntercomEvent: (payload: IntercomEvent) => void;
    setShowIntercomMessenger: (payload: boolean) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};
type Props = StateProps & DispatchProps;

class CustomerSupport extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.showIntercomMessenger &&
            (this.props.customerSupport === undefined ||
                this.props.customerSupport?.intercomData === undefined ||
                this.props.customerSupport?.intercomData?.appId === undefined ||
                this.props.hasIntercomFeaturePermission === false)
        ) {
            this.props.requestModal(this.showCustomerSupportUnavailableModal);
            this.props.setShowIntercomMessenger(false);
        }

        if (
            this.props.intercomEvent !== prevProps.intercomEvent &&
            this.props.intercomEvent !== IntercomEvent.NONE &&
            (this.props.customerSupport === undefined ||
                this.props.customerSupport?.intercomData === undefined ||
                this.props.customerSupport?.intercomData?.appId === undefined ||
                this.props.hasIntercomFeaturePermission === false)
        ) {
            this.props.requestModal(this.showCustomerSupportUnavailableModal);
        }
    }
    private openFaqs = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.CustomerSupport
                    .customerSupportUnavailableBrowseButton,
                pageIds.customerSupportUnavailable,
                this.props.selectedArtist?.asin,
                EntityType.ARTIST
            )
        );

        window.open(
            "https://artists.amazonmusic.com/frequently-asked-questions",
            "_blank",
            "noreferrer, noopener"
        );
    };

    private showCustomerSupportUnavailableModal = (
        onDismiss: () => void
    ): GlobalModal => {
        return (
            <CustomerSupportUnavailableModal
                isVisible={true}
                onDismiss={() => {
                    onDismiss();
                }}
                onBrowseFaqs={this.openFaqs}
                bundleMap={this.props.bundleMap}
                selectedArtist={this.props.selectedArtist}
            />
        );
    };

    render() {
        const intercom =
            this.props.customerSupport &&
            this.props.customerSupport.intercomData &&
            this.props.customerSupport.intercomData.appId &&
            this.props.hasIntercomFeaturePermission ? (
                <IntercomProvider
                    appId={this.props.customerSupport?.intercomData?.appId}
                    autoBoot={false}
                >
                    <CustomerSupportHome
                        showIntercomMessenger={this.props.showIntercomMessenger}
                        customerSupport={this.props.customerSupport}
                        intercomEvent={this.props.intercomEvent}
                        hideIntercomLauncher={this.props.hideIntercomLauncher}
                        locale={this.props.locale}
                        userData={this.props.userData}
                    />
                </IntercomProvider>
            ) : null;
        return <>{intercom}</>;
    }
}

function mapStateToProps(state: RootState): StateProps {
    return {
        ...customerSupportSelector(state),
        ...bundleMapSelector(state),
        ...selectedArtistFromCatalogSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        requestModal: (payload: ModalRenderFunction) =>
            dispatch(globalNotificationsActions.requestModalDisplay(payload)),
        setIntercomEvent: (payload: IntercomEvent) =>
            dispatch(oAuthActions.setIntercomEvent(payload)),
        setShowIntercomMessenger: (payload: boolean) =>
            dispatch(oAuthActions.setShowIntercomMessenger(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupport);
