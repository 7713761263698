import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import React from "react";
import { useSelector } from "react-redux";
import { merchImages } from "../../../assets";
import { getSelectedArtistAsin } from "../../../store/selectors/commonSelectors";
import { getLocalizedString, MerchLinkBuilders } from "../../../utils";
import { BaseShareModal } from "../common";
import { commonSelectors } from "../../../store/selectors";
import { BundleMap } from "../../../models";

export type MerchShareProps = {
    itemAsin: string;
    itemName: string;
    productImage?: string;
    id: string;
    isVisible: boolean;
    onHide: () => void;
};

export const MerchShare: React.FC<MerchShareProps> = ({
    itemAsin,
    itemName,
    productImage,
    id,
    isVisible,
    onHide,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);
    const artistAsin = useSelector(getSelectedArtistAsin);
    if (!artistAsin) {
        return null;
    }

    const sendShareClickMetric = (buttonName: string) => {
        // Implementation via: https://jira.music.amazon.dev/browse/AEM-536
    };

    return (
        <BaseShareModal
            isVisible={isVisible}
            onHide={onHide}
            loadingInProgress={false}
            modalTitle={getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCHCURATION_STRINGS,
                stringId: stringIds.Merch.Curation.ShareModalTitle,
            })}
            modalSubtitle={getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCHCURATION_STRINGS,
                stringId: stringIds.Merch.Curation.ShareModalSubtitle,
            })}
            id={id}
            contentType={getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCHCURATION_STRINGS,
                stringId: stringIds.Merch.Curation.Merch,
            })}
            contentTitle={itemName}
            contentImage={productImage}
            fallbackImageSource={merchImages.placeholderMerch}
            shareLink={MerchLinkBuilders.shareItem(itemAsin, artistAsin)}
            messageText={getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCHCURATION_STRINGS,
                stringId: stringIds.Merch.Curation.ShareProductMessage,
            })}
            sendShareClickMetric={sendShareClickMetric}
            bundleMap={bundleMap}
        />
    );
};
