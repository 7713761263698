import { AnyAction } from "redux";
import * as _ from "lodash";
import { errorActions } from "../actions";
import { ErrorPayload } from "../../models";

export type ErrorState = Readonly<{
    errorStore: Map<string, ErrorPayload>;
}>;

const initialErrorState: ErrorState = {
    errorStore: new Map<string, ErrorPayload>(),
};

export const errorReducer = (
    state: ErrorState = initialErrorState,
    action: AnyAction
) => {
    switch (action.type) {
        case errorActions.displayError.type: {
            const errorPayload: ErrorPayload = action.payload;
            const errorStore = state.errorStore;
            if (errorPayload.requestPath) {
                errorStore.set(errorPayload.requestPath, errorPayload);
            }

            if (errorPayload.requestPath) {
                return { ...state, errorStore: errorStore };
            }

            return state;
        }
        case errorActions.clearError.type: {
            const errorStore = state.errorStore;
            errorStore.delete(action.payload);
            return { ...state, errorStore: errorStore };
        }
        case errorActions.clearAllErrors.type: {
            return { ...state, errorStore: new Map<string, ErrorPayload>() };
        }
        default:
            return state;
    }
};
