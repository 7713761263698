/* tslint:disable */
import React from "react";

export const terms_esES = (
    <div>
        <p className="p1">
            <span className="s1">
                <b>
                    Términos de uso de Amazon Music for Artists
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b>Última actualización: 19 de febrero de 2022</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1">
                Este es un acuerdo entre usted y Amazon.com Services LLC (con
                sus afiliados, "Amazon", "nosotros" o "nos"), el cual rige su
                uso del servicio Amazon Music for Artists ("Amazon Music for
                Artists"). Antes de usar Amazon Music for Artists, lea estos
                Términos de uso, todas las reglas y políticas relacionadas
                (incluidas las reglas específicas, las restricciones de uso y
                otras condiciones o procedimientos que publicamos o actualizamos
                en o a través de Amazon Music for Artists o en cualquier otra
                página informativa o página de ayuda de Amazon Music for
                Artists), el{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&amp;view-type=content-only">
                    <span className="s3">Aviso de privacidad</span>
                </a>
                , las{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&amp;nodeId=508088">
                    <span className="s3">Condiciones de uso</span>
                </a>{" "}
                y los avisos de{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050&amp;view-type=content-only">
                    <span className="s3">anuncios basados en intereses</span>
                </a>{" "}
                (en conjunto, el "Acuerdo"). El Acuerdo entrará en vigor cuando
                haga clic en el botón "Acepto" o en la casilla de verificación
                que se presenta con estos Términos de uso. Usted declara que
                está legalmente capacitado para suscribir contratos (por
                ejemplo, no es menor de edad). Si está suscribiendo este Acuerdo
                en nombre de una entidad o individuo, como la empresa para la
                que trabaja o el artista al que representa, declara que tiene
                autoridad legal para vincular a esa entidad o individuo.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>{" "}
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">1.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Descripción general </b>Amazon Music for Artists incluye una
                variedad de herramientas y características que permiten a los
                artistas y representantes autorizados de artistas obtener
                información sobre las bases de fans de los artistas e
                interactuar con sus fans en los servicios ofrecidos por Amazon
                Music (los "Servicios").
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">2.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Criterios de selección</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.1.</span>
            <span className="s4">  </span>
            <span className="s1">
                Para utilizar Amazon Music for Artists, debe tener una cuenta de
                Amazon y ser (a) un artista o representante autorizado de un
                artista cuyo contenido se proporciona a Amazon para su
                distribución en los Servicios o (b) un representante autorizado
                de una entidad que tiene un acuerdo existente con Amazon para
                proporcionar contenido a Amazon para su distribución en los
                Servicios.<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                No puede utilizar una dirección de correo electrónico falsa,
                hacerse pasar por otra persona o entidad, ni proporcionar
                información falsa o engañosa, incluida la información
                proporcionada al crear una cuenta o relacionada con la propiedad
                de cualquier Contenido del artista.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">2.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                No puede compartir su nombre de usuario y contraseña de Amazon
                con otros ni utilizar el nombre de usuario y contraseña de
                Amazon de otra persona. Sin embargo, puede permitir que otras
                personas autorizadas por usted vean su Información sobre fans en
                sus propias cuentas de Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p8">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.4.</span>
            <span className="s4"> </span>
            <span className="s1">
                Usted es responsable de todas las comunicaciones y actividades
                relacionadas con Amazon Music for Artists que se lleven a cabo
                desde su ordenador o instalaciones informáticas, incluida su
                cuenta de correo electrónico y contraseña.
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.5.</span>
            <span className="s4"> </span>
            <span className="s1">
                Podemos compartir su información de contacto (incluido el
                nombre, la dirección de correo electrónico y los nombres de
                usuario de las redes sociales) con terceros para fines de
                verificación y criterios de selección, así como con otras
                personas que acceden a su Información sobre fans.
            </span>
            <span className="s5">
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">3.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Información sobre fans</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                Podemos proporcionarle acceso a datos relacionados con la
                distribución de su contenido en los Servicios ("Información
                sobre fans"). Solo puede usar Información sobre fans para
                evaluaciones y análisis internos y no puede compartirlos con
                ningún tercero, a menos que dicho tercero esté de acuerdo con
                estos Términos de uso. Sin embargo, Amazon puede proporcionarle
                cierta información (por ejemplo, hitos que haya alcanzado en los
                Servicios) a través de "tarjetas para compartir" que puede poner
                a disposición del público.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.2.</span>
            <span className="s4">  </span>
            <span className="s1">
                La Información sobre fans se proporciona únicamente para su
                conveniencia, y no garantizamos su exactitud. Por lo tanto, no
                se debe confiar en ella para calcular las tarifas de licencia u
                otras cantidades que se le adeuden como resultado de la
                distribución de su contenido en los Servicios.{" "}
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                No utilizará la Información sobre fans para intentar obtener
                datos más detallados que los datos proporcionados (por ejemplo,
                para determinar información personal sobre un usuario final de
                los Servicios).
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">4.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    Derechos<span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Contenido del artista. </b>Puede utilizar Amazon Music for
                Artists para enviar materiales (por ejemplo, imágenes y material
                gráfico) de su propiedad o que esté autorizado a enviar
                ("Contenido del artista"). No puede enviar Contenido del artista
                que sea ilegal, obsceno, amenazante, difamatorio o que contenga
                contenido inaceptable o inapropiado, ni puede enviar Contenido
                del artista que (a) dañe o infrinja los derechos de terceros,
                incluida la privacidad, publicidad, derechos de autor, marcas
                registradas, patentes u otros derechos de propiedad intelectual;
                o (b) nos exigiría pagar regalías o cualquier otra tarifa a
                cualquier tercero por el uso del Contenido del artista según lo
                permitido en el Acuerdo. Usted es responsable y acepta pagar las
                tarifas adeudadas con respecto al Contenido del artista. El
                envío a Amazon Music for Artists no conlleva cambio alguno en su
                titularidad sobre el Contenido del artista.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p9">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Concesión de licencia. </b>Usted nos otorga un derecho y
                licencia de carácter no exclusivo, sublicenciable, mundial,
                perpetuo, irrevocable y libre de regalías para usar, reproducir,
                distribuir, transmitir, interpretar, modificar y mostrar todo el
                Contenido del artista en cualquier medio y formato de tecnología
                en conexión con los Servicios y Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s5">4.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Sugerencias. </b>
            </span>
            <span className="s1">
                Si sugiere alguna mejora a Amazon Music for Artists
                ("Sugerencias"), tendremos derecho a utilizar las Sugerencias
                sin restricciones. Por la presente, usted nos asigna
                irrevocablemente todos los derechos, títulos e intereses sobre
                las Sugerencias y acepta brindarnos cualquier ayuda que
                necesitemos para documentar, perfeccionar y mantener nuestros
                derechos sobre las Sugerencias.
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">5.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Seguridad y privacidad de los datos</b>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Ingeniería inversa o elusión. </b>
            </span>
            <span className="s1">
                No puede, y no alentará, ayudará ni autorizará a ninguna otra
                persona a modificar, realizar ingeniería inversa, eludir,
                descompilar, desensamblar, engañar o alterar de ninguna otra
                forma cualquier parte de Amazon Music for Artists.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Obtención de datos en masa. </b>No puede, y no alentará,
                ayudará ni autorizará a ninguna otra persona a "rastrear" Amazon
                Music for Artists ni a utilizar ninguna herramienta de
                extracción de datos, screen scraping (raspado web) u otras
                herramientas de obtención de datos para recopilar información de
                Amazon.
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">6.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    Cambios y suspensión
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">6.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Cambios. </b>
            </span>
            <span className="s1">
                Podemos cambiar, suspender o interrumpir el servicio Amazon
                Music for Artists, o cualquier parte de él, en cualquier
                momento. De vez en cuando, podemos agregar funciones o
                eliminarlas de Amazon Music for Artists. Puede haber
                circunstancias inusuales en las que ya no podamos almacenar o
                brindarle acceso a funciones particulares o Información sobre
                fans.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">6.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Suspensión y rescisión por nuestra parte. </b>
            </span>
            <span className="s1">
                Sus derechos en virtud del Acuerdo terminarán automáticamente
                sin previo aviso si no cumple con sus términos. Podemos
                rescindir el Acuerdo o restringir, suspender o cancelar su uso
                de Amazon Music for Artists en cualquier momento, incluso si
                determinamos que su uso infringe el Acuerdo, es inadecuado,
                excede sustancialmente o difiere del uso normal por otros
                usuarios, implica fraude o el uso indebido de Amazon Music for
                Artists, o daña nuestros intereses o los de otro usuario de
                Amazon Music for Artists. Puede rescindir el Acuerdo por
                cualquier motivo cerrando su cuenta. Si su acceso a Amazon Music
                for Artists está restringido, suspendido o cancelado, es posible
                que no pueda acceder a la Información sobre fans. Después de
                cualquier suspensión o rescisión, conservaremos los derechos
                sobre el Contenido del artista que se nos otorgan en virtud del
                Acuerdo.
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">7.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Debida autoridad e indemnizaciones. </b>Usted declara y
                garantiza que: (a) tiene todos los derechos necesarios para
                concedernos los derechos otorgados en el Acuerdo; (b) los
                materiales que envíe y nuestro uso de ellos según lo autorizado
                por el Acuerdo (i) no vulnerarán ni infringirán los derechos de
                privacidad, derechos de publicidad, derechos de propiedad
                intelectual u otros derechos de terceros, (ii) no serán
                difamatorios, ilegales u obscenos, y (iii) no requerirán que
                paguemos regalías o cualquier otra tarifa a terceros por el uso
                de dichos materiales; y (c) todas las declaraciones objetivas
                que envíe son precisas y no engañosas. Usted acepta indemnizar,
                defender y eximir de responsabilidad a Amazon y sus
                administradores, directivos, empleados, agentes y cesionarios de
                todas las reclamaciones, responsabilidades, daños y gastos,
                incluidos los honorarios y gastos legales razonables, que surjan
                de o estén relacionados con los materiales que envíe, su
                incumplimiento del Acuerdo o de sus declaraciones o garantías, o
                del uso indebido, no autorizado o ilegal de Amazon Music for
                Artists.<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">8.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>General</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">8.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Modificaciones. </b>
            </span>
            <span className="s1">
                Podemos modificar el Acuerdo en cualquier momento mediante la
                publicación de los términos revisados en o a través de Amazon
                Music for Artists. El uso continuado de Amazon Music for Artists
                después de cualquier modificación evidencia su aceptación de
                quedar vinculado a ella.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">8.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Avisos. </b>
            </span>
            <span className="s1">
                Podemos comunicarnos con usted en relación con su uso de Amazon
                Music for Artists de forma electrónica y a través de otros
                medios, y usted da su consentimiento a tales comunicaciones
                independientemente de cualquier comunicación con el cliente o
                preferencias o solicitudes similares que haya indicado en el
                sitio web de Amazon.com o por cualquier otro medio.{" "}
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Avisos de derechos de autor. </b>
            </span>
            <span className="s1">
                Si cree que su trabajo ha sido copiado de una manera que
                constituye una infracción de los derechos de autor, siga el
                Aviso y el Procedimiento para realizar reclamaciones por
                infracción de los derechos de autor que se especifican en las{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909000&amp;view-type=content-only">
                    <span className="s8">Condiciones de uso</span>
                </a>
                .
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.4.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Totalidad del acuerdo, cláusula de divisibilidad. </b>
            </span>
            <span className="s1">
                El Acuerdo constituye el acuerdo completo entre usted y Amazon,
                y rige su uso de Amazon Music for Artists, reemplazando
                cualquier acuerdo anterior suscrito entre usted y Amazon. Si se
                determina que alguna modificación a este Acuerdo es inválida,
                nula, ilegal o por cualquier razón inaplicable, esta disposición
                quedará separada de estos términos y condiciones, y no afectará
                la validez y aplicabilidad de los demás términos o condiciones.
            </span>
        </p>
    </div>
);
