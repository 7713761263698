import { createAction } from "redux-ts";
import { baseCatalogItem, trackAlbumSearchQueryPayload } from "../../models";

export const trackAlbumSearch = createAction<trackAlbumSearchQueryPayload>(
    "SEARCH::TRACK_ALBUM_SEARCH"
);

export const trackAlbumSearchInProgress = createAction<boolean>(
    "SEARCH::TRACK_ALBUM_SEARCH_INPROGRESS"
);
export const trackAlbumSearchCompleted = createAction<baseCatalogItem[]>(
    "SEARCH::TRACK_ALBUM_SEARCH_COMPLETED"
);
export const clearTrackAlbumSearch = createAction<string>(
    "SEARCH::CLEAR_TRACK_ALBUM_SEARCH"
);
