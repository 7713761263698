import _ from "lodash";
import { AnyAction } from "redux";
import {
    merchCollection,
    merchCatalog,
    merchIdentifiers,
    hydratedMerchItem,
} from "../../models";
import { merchCurateActions } from "../actions";

export type MerchCurateState = Readonly<{
    merchSearchInProgress: boolean;
    merchSearchResultItems: hydratedMerchItem[];
    getMerchCollectionsInProgress: boolean;
    getMerchCollectionsCompleted: boolean;
    merchCollection?: merchCollection;
    curateMerchInProgress: boolean;
}>;

export const initialMerchCurateState: MerchCurateState = {
    merchSearchInProgress: false,
    merchSearchResultItems: [],
    getMerchCollectionsInProgress: false,
    getMerchCollectionsCompleted: false,
    curateMerchInProgress: false,
};

export const merchCurateReducer = (
    state: MerchCurateState = initialMerchCurateState,
    action: AnyAction
) => {
    switch (action.type) {
        case merchCurateActions.merchSearchInProgress.type:
            return {
                ...state,
                merchSearchInProgress: action.payload,
            };
        case merchCurateActions.merchSearchCompleted.type:
            return {
                ...state,
                merchSearchResultItems: _.cloneDeep(action.payload),
            };
        case merchCurateActions.clearMerchSearchResults.type:
            return {
                ...state,
                merchSearchResultItems: [],
            };
        case merchCurateActions.getMerchCollectionsInProgress.type:
            return {
                ...state,
                getMerchCollectionsInProgress: action.payload,
            };
        case merchCurateActions.getMerchCollectionsCompleted.type:
            //transforming identifier object from drTeeth into string value
            const givenCollection = _.cloneDeep(action.payload);
            if (givenCollection) {
                const formattedCollectionItems = givenCollection.items.map(
                    (item: hydratedMerchItem) => item.itemAsin
                );
                givenCollection.items = formattedCollectionItems;
            }
            return {
                ...state,
                merchCollection: givenCollection,
                getMerchCollectionsCompleted: true,
            };
        case merchCurateActions.curateMerchInProgress.type:
            return {
                ...state,
                curateMerchInProgress: action.payload,
            };
        default:
            return state;
    }
};
