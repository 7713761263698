import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { getLocalizedString } from "../../../../utils";
import { FullScreenConfirmationModal, IconsList } from "../../common";
import * as rootStyles from "../../../styles";
import { bundleMapSelector } from "../../../../store/selectors/commonSelectors";
import { BundleMap } from "../../../../models";

export type ProductAddedConfirmationModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = ProductAddedConfirmationModalProps & StateProps;
export class ProductAddedConfirmationModalClass extends React.Component<Props> {
    render() {
        return (
            <FullScreenConfirmationModal
                icon={IconsList.ic_action_checkcircle}
                isVisible={this.props.isVisible}
                confirmButtonAction={this.props.onDismiss}
                onDismiss={this.props.onDismiss}
                id={"ProductAddedConfirmationModal"}
                text={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId:
                        stringIds.Merch.Curation.ProductAddedConfirmationTitle,
                })}
                description={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.ProductAddedConfirmationSubtitle
                })}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.ok
                })}
                buttonOrientation={"stacked"}
            />
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

export const ProductAddedConfirmationModal = connect(mapStateToProps)(
    ProductAddedConfirmationModalClass
);
