export enum PitchType {
    NewPlaylistPitch = "NEW_PLAYLIST_PITCH",
}

export type newPlaylistPitchInfo = {
    genres: string[];
    moods: string[];
    themes: string[]; // song styles
    compositions: string[];
    musicCultures?: string[];
    relatedArtistAsins?: string[];
    languages: string[];
    artistRootCountry?: string;
    description: string;
    musicStory?: string;
};

export type pitchPage = {
    newPlaylist: newPlaylistPitchInfo[];
    lastEvaluatedKey?: string;
};

export type tag = {
    code: string;
    name: string;
};

export type genreInfo = {
    genre: tag;
    subGenres: tag[];
};

export type pitchFormData = {
    genres: genreInfo[];
    compositions: tag[];
    moods: tag[];
    musicCultures?: tag[];
    themes: tag[];
    languages: tag[];
    countries: tag[];
};
