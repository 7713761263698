import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import {
    Table,
    Loading,
    IncrementalLoading,
    InlineError,
    CommonHeader,
    ProfileHeadProps,
    ProfileHead,
    CommonHeaderProps,
} from "../../components";
import {
    RequiredReportingProps,
    RequiredReportingDispatchProps,
    RequiredReportingState,
    METRIC_KEYS,
    TableRowProps,
    queryRequest,
    programmingInfo,
    TimeRange,
    hydrateCatalogPayload,
    overviewRequestPayload,
    telemetryPayload,
    timeRangePayload,
    track,
    CatalogItemType,
    createCsvFileRequestPayload,
    clientMetricsPayload,
    EntityType,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    RootState,
    stationsScreenSelector,
    reportingActions,
    telemetryActions,
    catalogActions,
    userActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import {
    getLocalizedString,
    generateStationRows,
    paths,
    HYDRATION_COUNT,
    generateQueryWithoutGranularity,
    guessTimeZone,
    testIDSuffixes,
    hydrationHelpers,
    buildUIClickPayload,
} from "../../../utils";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import {
    CsvButtonId,
    csvButtonLoadingStatusData,
    CsvType,
} from "../../../export";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";

const testIDPrefix = "StationsScreen";
const metricPrefix = "stationsPage";
const pagePath = paths.stations;

type ViewProps = {
    trackAsin: string;
};

type StateProps = RequiredReportingProps & {
    stations: programmingInfo[];
    reportingKey: string;
    selectedSongAsin?: string;
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = RequiredReportingDispatchProps & {
    hydrateAsins: (payload: hydrateCatalogPayload) => void;
    clearFailedAsins: () => void;
    updateCurrentPath: (payload: string) => void;
    setSelectedSongAsin: (payload: string) => void;
    removeSelectedSongAsin: () => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    loadedCount: number;
    trackCatalogItem?: track;
};

class StationsScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            loadedCount: 0,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }
        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();

        this.checkUrlParam();
        this.getReports();

        this.loadMore();

        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist],
                [METRIC_KEYS.timeRange, this.props.selectedRange],
                [METRIC_KEYS.page, paths.stations],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports(true);
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        } else if (this.props.selectedSongAsin !== prevProps.selectedSongAsin) {
            this.getReports(true);
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.state.loadedReports &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
            this.loadMore();
        }
    }

    componentWillUnmount() {
        this.props.removeSelectedSongAsin();
    }

    render() {
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const track = this.state.trackCatalogItem;

        const csvBasePayload = {
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: pagePath,
            csvExportType: "featuredInStations" as CsvType,
            contentName: track?.title,
            stationData: this.props.stations,
        };

        // if statement below guarantees either profile head or common header props are defined
        let commonHeaderProps: CommonHeaderProps | undefined;
        let profileHeadProps: ProfileHeadProps | undefined;
        let csvPayload: createCsvFileRequestPayload;

        if (track) {
            csvPayload = {
                ...csvBasePayload,
                csvButtonId: CsvButtonId.SongSeeAllFeaturedStations,
            };
            profileHeadProps = {
                title: track.title || "",
                imageDescription: track.title,
                imageSource:
                    track.images && track.images.artLarge
                        ? track.images.artLarge
                        : "",
                fallbackImage: ImageList.placeholder_track,
                subtitle: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.songsLabel,
                }),
                id: testIDPrefix + testIDSuffixes.profileImage,
                showCsvButton:
                    this.props.hasCsvExportCapabilities &&
                    !!this.props.stations.length,
                csvDownload: () => {
                    this.props.sendClientMetrics(
                        buildUIClickPayload(
                            buttonIds.CsvExport.downloadStations,
                            pageIds.stations,
                            this.props.selectedArtist,
                            EntityType.ARTIST
                        )
                    );
                    this.props.getCsvFile(csvPayload);
                },
                csvTooltipMessage: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.CSV_STRINGS,
                    stringId: stringIds.Csv.TooltipMessage.FeaturedInStations,
                }),
                csvButtonLoading:
                    this.props.csvButtonLoadingStatus[
                        "SongSeeAll-FeaturedStations"
                    ],
            };
        } else {
            csvPayload = {
                ...csvBasePayload,
                csvButtonId: CsvButtonId.ProgrammingSeeAllFeaturedStations,
            };
            commonHeaderProps = {
                title: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.stationLabel,
                }),
                id: `${testIDPrefix}_Header`,
                showCsvButton:
                    this.props.hasCsvExportCapabilities &&
                    !!this.props.stations.length,
                csvDownload: () => {
                    this.props.sendClientMetrics(
                        buildUIClickPayload(
                            buttonIds.CsvExport.downloadStations,
                            pageIds.stations,
                            this.props.selectedArtist,
                            EntityType.ARTIST
                        )
                    );
                    this.props.getCsvFile(csvPayload);
                },
                csvTooltipMessage: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.CSV_STRINGS,
                    stringId: stringIds.Csv.TooltipMessage.FeaturedInStations,
                }),
                csvButtonLoading:
                    this.props.csvButtonLoadingStatus[
                        "ProgrammingSeeAll-FeaturedStations"
                    ],
            };
        }

        const featuredStationsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.stationsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const featuredStations: TableRowProps[] = generateStationRows(
            this.props.stations.slice(0, this.state.loadedCount),
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            testIDPrefix + "_StationRows",
            this.props.bundleMap
        );

        // const bgImage = this.props.match.params?.image || this.props.artist?.images?.artSmall;
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {/* {bgImage &&
                    <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: bgImage }}
                    />
                } */}
                {track && profileHeadProps && (
                    <ProfileHead {...profileHeadProps} />
                )}
                {!track && commonHeaderProps && (
                    <CommonHeader {...commonHeaderProps} />
                )}
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                <Row>
                    <Col>
                        <Table
                            loading={this.state.refreshing}
                            rows={featuredStations}
                            labels={featuredStationsHeader}
                            loadMore={this.loadMore}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId:
                                    stringIds.Reports.featuredStationsTitle,
                            })}
                            scrolling={true}
                            footer={
                                this.props.stations.length <=
                                this.state.loadedCount ? (
                                    <div
                                        style={{
                                            height: rootStyles.spacers.huge,
                                        }}
                                    />
                                ) : (
                                    <IncrementalLoading />
                                )
                            }
                            id={testIDPrefix + "_StationTable"}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

    private checkUrlParam = () => {
        const trackAsin = this.props.match.params.trackAsin;
        if (trackAsin) {
            this.props.setSelectedSongAsin(trackAsin);
            const trackCatalogItem = this.props.catalog.get(trackAsin) as track;

            if (trackCatalogItem) {
                this.setState({ trackCatalogItem: trackCatalogItem });
            }
        } else {
            this.props.removeSelectedSongAsin();
        }
    };

    private loadMore = () => {
        if (!this.props.signedIn) {
            return;
        }

        const nextCount: number = hydrationHelpers.hydrateAsinsBatch(
            this.state.loadedCount,
            _.map(this.props.stations, (station) => {
                return {
                    asin: station.id,
                };
            }),
            this.props.hydrateAsins,
            CatalogItemType.Stations,
            this.props.userLocale
        );

        this.setState({
            loadedCount: nextCount,
        });
    };

    private getReports = (isRefresh?: boolean) => {
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId
        ) {
            return;
        }

        this.setState({
            loadedCount: HYDRATION_COUNT,
        });

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        const trackAsin = this.props.match.params.trackAsin;

        const featuredStationsQuery = {
            ...generateQueryWithoutGranularity(
                timeRangePayload,
                guessTimeZone(),
                300
            ),
            ...(trackAsin && { trackAsin: trackAsin }),
        };

        const query: queryRequest = {
            artistAsin: this.props.selectedArtist,
            featuredStationsQueryV2: featuredStationsQuery,
        };

        const payload: overviewRequestPayload = {
            query: query,
            requestPath: paths.stations,
            requestId: this.props.reportingKey,
            isRefresh: isRefresh,
            teamId: this.props.teamId,
        };

        this.props.getReport(payload);
    };
}

function mapStateToProps(state: RootState) {
    return stationsScreenSelector(state, paths.stations);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReport: (payload: overviewRequestPayload) =>
            dispatch(reportingActions.getReports(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        hydrateAsins: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        setSelectedSongAsin: (payload: string) =>
            dispatch(reportingActions.setSelectedSongAsin(payload)),
        removeSelectedSongAsin: (payload: string) =>
            dispatch(reportingActions.removeSelectedSongAsin()),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(StationsScreen)
);
