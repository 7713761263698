import * as React from "react";
import { Row, Modal } from "react-bootstrap";
import { Icon, IconsList } from "../icons";
import * as rootStyles from "../../../styles";
import { LargeSolidButton } from "../input/Buttons";
import { BlurredModal, styledTitle } from "..";

export type HelpModalProps = {
    accept?: () => void;
    acceptbuttonText?: string;
    onDismiss?: () => void;
    isVisible: boolean;
    title: string;
    titleStyle?: React.CSSProperties;
    body: any;
    footer?: any;
    id: string;
};

export const HelpModalPrefix = "HelpModal";

export class HelpModal extends React.Component<HelpModalProps> {
    render() {
        const largespacer = <div style={spacerStyle} />;
        const mediumSpacer = (
            <div
                style={{ ...spacerStyle, minHeight: rootStyles.spacers.medium }}
            />
        );

        return (
            <BlurredModal
                id="blurred-modal"
                onHide={this.props.onDismiss}
                show={this.props.isVisible}
                centered
                maxwidth={950}
                top="30%"
            >
                <Modal.Body
                    id={HelpModalPrefix + "-Body"}
                    style={HelpContainer}
                >
                    <Row
                        style={iconContainerStyle}
                        onClick={this.props.onDismiss}
                    >
                        <Icon
                            icon={IconsList.action_cancel}
                            size={32}
                            color={rootStyles.colors.primary}
                            id={`${HelpModalPrefix}_DismissButton`}
                        />
                    </Row>
                    {largespacer}
                    <Row>
                        <styledTitle.h4
                            style={{
                                ...this.props.titleStyle,
                            }}
                        >
                            {this.props.title}
                        </styledTitle.h4>
                    </Row>
                    {mediumSpacer}
                    {this.props.body()}
                    {!!this.props.accept && !!this.props.acceptbuttonText ? (
                        <>
                            {largespacer}
                            <Row style={{ width: "45%" }}>
                                <LargeSolidButton
                                    onClick={this.props.accept}
                                    title={this.props.acceptbuttonText}
                                    containerStyle={{ width: "100%", flex: 1 }}
                                    id={`${this.props.id}_Accept`}
                                />
                            </Row>
                        </>
                    ) : null}
                    {mediumSpacer}
                    {this.props.footer && this.props.footer()}
                </Modal.Body>
            </BlurredModal>
        );
    }
}
const spacerStyle: React.CSSProperties = {
    flex: 1,
    minHeight: rootStyles.spacers.large,
};

const HelpContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: rootStyles.spacers.base,
    paddingRight: rootStyles.spacers.base,
    backgroundColor: rootStyles.colors.background,
    display: "flex",
};

const iconContainerStyle: React.CSSProperties = {
    flex: 0,
    padding: 4,
    borderRadius: 24,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
    cursor: "pointer",
};
