import * as React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { LargeOutlineButton, LargeSolidButton } from "./input";

export type FooterProps = {
    show: boolean;
    rightButtonDisabled: boolean;
    leftButtonText: string;
    rightButtonText: string;
    onLeftButtonClick: () => void;
    onRightButtonClick: () => void;
    rightButtonLoading?: boolean;
    leftButtonLoading?: boolean;
    leftButtonDisabled?: boolean;
    onDisabledButtonClick?: () => void;
    id: string;
};

const footerHeight = 90;

export const Footer: React.FC<FooterProps> = ({
    show,
    rightButtonDisabled,
    leftButtonText,
    rightButtonText,
    onLeftButtonClick: onLeftButtonClick,
    onRightButtonClick: onRightButtonClick,
    rightButtonLoading,
    leftButtonLoading,
    leftButtonDisabled,
    onDisabledButtonClick: onDisabledButtonClick,
    id,
}) => {
    // Clickable Outline Button with styling to appear disabled
    const disabledClickableOutlineButtonLeft = (
        <LargeOutlineButton
            title={leftButtonText}
            onClick={
                !!onDisabledButtonClick ? onDisabledButtonClick : undefined
            }
            containerStyle={clickableDisabledButtonStyle}
            id={`${id}-FooterClickableDisabledLeftButton`}
        />
    );

    // Clickable Solid Button with styling to appear disabled
    const disabledClickableSolidButtonRight = (
        <LargeSolidButton
            title={rightButtonText}
            onClick={
                !!onDisabledButtonClick ? onDisabledButtonClick : undefined
            }
            containerStyle={clickableDisabledButtonStyle}
            id={`${id}-FooterClickableDisabledRightButton`}
        />
    );

    const largeOutlineLeftButton = (
        <LargeOutlineButton
            title={leftButtonText}
            onClick={onLeftButtonClick}
            disabled={rightButtonLoading || leftButtonDisabled}
            containerStyle={actionButtonStyle}
            id={`${id}-FooterLeftButton`}
        />
    );

    const largeSolidRightButton = (
        <LargeSolidButton
            title={rightButtonText}
            onClick={onRightButtonClick}
            disabled={rightButtonDisabled || leftButtonLoading}
            containerStyle={actionButtonStyle}
            id={`${id}-FooterRightButton`}
        />
    );

    return show ? (
        <>
            {/* Space at the button used by the footer */}
            <Row
                style={{ height: footerHeight + 1 + rootStyles.spacers.base }}
            />
            {/* Cancel Submit button */}

            <Row
                style={{
                    width: "100%",
                    position: "fixed",
                    bottom: footerHeight + 1,
                    left: 0,
                    height: 1,
                    zIndex: 1,
                    background: rootStyles.colors.secondary,
                }}
            ></Row>
            <Row style={footerButtonsStyle}>
                <Row style={{ width: 450, alignItems: "center" }}>
                    <Col xs={6}>
                        {leftButtonLoading ? (
                            <Row
                                style={{
                                    justifySelf: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Spinner animation="border" variant="light" />
                            </Row>
                        ) : (
                            <>
                                {leftButtonDisabled && !!onDisabledButtonClick
                                    ? disabledClickableOutlineButtonLeft
                                    : largeOutlineLeftButton}
                            </>
                        )}
                    </Col>
                    <Col xs={6}>
                        {rightButtonLoading ? (
                            <Row
                                style={{
                                    justifySelf: "center",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Spinner animation="border" variant="light" />
                            </Row>
                        ) : (
                            <>
                                {rightButtonDisabled && !!onDisabledButtonClick
                                    ? disabledClickableSolidButtonRight
                                    : largeSolidRightButton}
                            </>
                        )}
                    </Col>
                </Row>
            </Row>
        </>
    ) : null;
};

const footerButtonsStyle: React.CSSProperties = {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 2,
    height: footerHeight,
    backgroundColor: rootStyles.colors.background,
};

const actionButtonStyle: React.CSSProperties = {
    alignSelf: "stretch",
    width: "100%",
};

const clickableDisabledButtonStyle: React.CSSProperties = {
    alignSelf: "stretch",
    width: "100%",
    opacity: rootStyles.glass._4, // same opacity as a button with disabled={true}
};
