import { createSelector } from "reselect";
import _ from "lodash";
import { RootState } from "../reducers";
import { privilegedTeamId } from "../../service";
import { getSelectedTeamSelector } from "./teamManagementSelectors";
import {
    getRecentlyAddedToPlaylistsData,
    getBundleMap,
} from "./commonSelectors";
import {
    TimeRange,
    catalog,
    ErrorPayload,
    artist,
    Feature,
    BundleMap,
} from "../../models";
import { getSeeAllCountriesRequestId } from "../../utils";
import { CsvButtonId } from "../../export";
import { getShouldDisplayMerchCurationBanner } from "./merchCurateSelectors";
import { getHasPromoCardFeaturePermission } from "./promoCardSelectors";

const getReports = (state: RootState) => state.reporting.reports;
const getReportsInProgress = (state: RootState, path: string) =>
    state.reporting.inProgress.get(path);
const getSongPerformanceReports = (state: RootState) =>
    state.reporting.songPerformanceReports;
const getAlbumPerformanceReports = (state: RootState) =>
    state.reporting.albumPerformanceReports;
const getPlaylistPerformanceReports = (state: RootState) =>
    state.reporting.playlistPerformanceReports;
const getStationPerformanceReports = (state: RootState) =>
    state.reporting.stationPerformanceReports;
const getSelectedTimeRange = (state: RootState) =>
    state.reporting.selectedRange;
const getStartDate = (state: RootState) => state.reporting.startDate;
const getEndDate = (state: RootState) => state.reporting.endDate;
const getSelectedAlbumAsin = (state: RootState) =>
    state.reporting.selectedAlbumAsin;
const getSelectedSongAsin = (state: RootState) =>
    state.reporting.selectedSongAsin;
const getSelectedPlaylistAsin = (state: RootState) =>
    state.reporting.selectedPlaylistAsin;
const getSelectedStationAsin = (state: RootState) =>
    state.reporting.selectedStationAsin;

const getUserInfo = (state: RootState) => state.user.signedIn;
const getIsPrivileged = (state: RootState) => state.user.isPrivileged;
const getUserLocale = (state: RootState) => state.user.locale;

const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;
const getCatalog = (state: RootState) => state.catalog.catalog;
const getCatalogBuildCompleted = (state: RootState) =>
    state.catalog.catalogBuildCompleted;
const getHydrationInProgress = (state: RootState) =>
    state.catalog.hydrationInProgress;
const getHydratingAsins = (state: RootState) => state.catalog.hydratingAsins;
const getFailedAsins = (state: RootState) => state.catalog.failedAsins;

const getRecentlyAddedToPlaylistsInProgress = (state: RootState) =>
    state.catalog.recentlyAddedToPlaylistsInProgress;

const getListAlbumReleasesInProgress = (state: RootState) =>
    state.pitch.listAlbumsToPitchInProgress;

const getAlbumsToPitch = (state: RootState) => state.pitch.albumsToPitch;

const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);

const getShareLinkResponse = (state: RootState) =>
    state.catalog.shareLinkResponse;
const getGenerateShareLinkInProgress = (state: RootState) =>
    state.catalog.generateShareLinkInProgress;

const getCsvButtonLoadingStatus = (state: RootState) =>
    state.reporting.csvButtonLoadingStatus;
const getHasCsvExportPermissions = (state: RootState) => {
    return (
        state.featureAccess.featurePermissionsList.length !== 0 &&
        !!_.find(state.featureAccess.featurePermissionsList, (permission) => {
            return permission.feature === Feature.CsvExport;
        })
    );
};

//Disables csv export permissions if the time range is set to today/24 hours.
const getHasCsvExportCapabilities = createSelector(
    [getSelectedTimeRange, getHasCsvExportPermissions],
    (timeRange, csvExportPermissions) => {
        return csvExportPermissions && timeRange !== TimeRange.Today;
    }
);

// Get selected artist
const selectedArtistFromCatalogSelector = createSelector(
    [getSelectedArtistAsin, getCatalog],
    (selectedArtistAsin, catalog) => {
        if (!selectedArtistAsin) {
            return;
        }
        return catalog.get(selectedArtistAsin);
    }
);

// Gets the selected time range
const selectedTimeRangeReportsSelector = createSelector(
    [getReports, getSelectedTimeRange],
    (reports, selectedRange) => {
        return reports.get(selectedRange);
    }
);

// Gets the currently selected team id or privileged id if applicable
const selectedTeamIdSelector = createSelector(
    [getSelectedTeamSelector, getIsPrivileged],
    (teamInfo, isPrivileged) => {
        return isPrivileged ? privilegedTeamId : teamInfo && teamInfo.teamId;
    }
);

const albumTracksSelector = createSelector(
    [getReports, getSelectedTimeRange],
    (reports, selectedRange) => {
        const key = `seeAll_albumTracks_${selectedRange.toString()}`;
        const report = reports.get(key);
        return report?.topTracksInfo?.trackInfoList || [];
    }
);

const voiceTracksSelector = createSelector(
    [getReports, getSelectedTimeRange],
    (reports, selectedRange) => {
        const key = `seeAll_voiceTracks_${selectedRange.toString()}`;
        const report = reports.get(key);
        return report?.tracksVoiceInfo?.trackVoiceInfoList || [];
    }
);

const topTracksSelector = createSelector(
    [getReports, getSelectedTimeRange],
    (reports, selectedRange) => {
        const key = `seeAll_topTracks_${selectedRange.toString()}`;
        const report = reports.get(key);
        return report?.topTracksInfo?.trackInfoList || [];
    }
);

const coreReportsStateInfoSelector = createSelector<
    RootState,
    string,
    any,
    any
>(
    [
        getSelectedTimeRange,
        getStartDate,
        getEndDate,
        getUserInfo,
        getCatalog,
        getSelectedArtistAsin,
        selectedTeamIdSelector,
        getErrorFromStore,
        getHydratingAsins,
        getFailedAsins,
        getHydrationInProgress,
        getCatalogBuildCompleted,
        selectedArtistFromCatalogSelector,
        getRecentlyAddedToPlaylistsData,
        getUserLocale,
        getBundleMap,
    ],
    (
        selectedRange: TimeRange,
        startDate: Date | undefined,
        endDate: Date | undefined,
        signedIn: boolean,
        catalog: catalog,
        selectedArtistAsin: string | undefined,
        teamId: string | undefined,
        error: ErrorPayload | undefined,
        hydratingAsins: string[],
        failedAsins: string[],
        hydrationInProgress: boolean,
        catalogBuildCompleted: boolean,
        artist: artist,
        recentlyAddedToPlaylistData,
        userLocale: string,
        bundleMap: BundleMap
    ) => {
        return {
            selectedRange: selectedRange,
            startDate: startDate,
            endDate: endDate,
            signedIn: signedIn,
            catalog: catalog,
            selectedArtist: selectedArtistAsin,
            teamId: teamId,
            error: error,
            hydratingAsins: hydratingAsins,
            failedAsins: failedAsins,
            catalogBuildCompleted: catalogBuildCompleted,
            hydrationInProgress: hydrationInProgress,
            artist: artist,
            recentlyAddedToPlaylistData: recentlyAddedToPlaylistData || [],
            userLocale: userLocale,
            bundleMap: bundleMap,
        };
    }
);

export const overviewScreenSelector = createSelector(
    [
        selectedTimeRangeReportsSelector,
        getReportsInProgress,
        coreReportsStateInfoSelector,
        getRecentlyAddedToPlaylistsInProgress,
        getListAlbumReleasesInProgress,
        getAlbumsToPitch,
        getCsvButtonLoadingStatus,
        getHasCsvExportCapabilities,
        getShouldDisplayMerchCurationBanner,
    ],
    (
        result,
        inProgress,
        coreProps: any,
        recentlyAddedToPlaylistsInProgress,
        listAlbumReleasesInProgress,
        albumsToPitch,
        csvButtonLoadingStatus,
        hasCsvExportCapabilities,
        shouldDisplayMerchCurationBanner
    ) => {
        if (!result) {
            return {
                topAlbums: [],
                topTracks: [],
                streamSources: [],
                inProgress:
                    inProgress ||
                    recentlyAddedToPlaylistsInProgress ||
                    listAlbumReleasesInProgress,
                albumsToPitch,
                csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                    CsvButtonId.OverviewTrendlines,
                    CsvButtonId.OverviewTopSongs,
                    CsvButtonId.OverviewTopAlbums,
                ]),
                hasCsvExportCapabilities,
                shouldDisplayMerchCurationBanner,
                ...coreProps,
            };
        }

        return {
            topTracks: result.topTracksInfo?.trackInfoList || [],
            topAlbums: result.topAlbumsInfo?.albumInfoList || [],
            trackStreams: result.trackStreamsInfo,
            trackListeners: result.trackListenersInfo,
            streamSources:
                result.streamSourcesInfo?.streamSourcesInfoList || [],
            inProgress:
                inProgress ||
                recentlyAddedToPlaylistsInProgress ||
                listAlbumReleasesInProgress,
            albumsToPitch,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.OverviewTrendlines,
                CsvButtonId.OverviewTopSongs,
                CsvButtonId.OverviewTopAlbums,
            ]),
            hasCsvExportCapabilities,
            shouldDisplayMerchCurationBanner,
            ...coreProps,
        };
    }
);

export const voiceScreenSelector = createSelector(
    [
        selectedTimeRangeReportsSelector,
        getReportsInProgress,
        coreReportsStateInfoSelector,
    ],
    (reports, inProgress, coreProps: any) => {
        if (!reports) {
            return {
                streamingDetails: [],
                tracks: [],
                alexaIndexInfo: 0,
                inProgress: inProgress,
                ...coreProps,
            };
        }

        return {
            voiceRequests: reports.totalVoiceRequestsInfo,
            alexaIndexInfo: reports.alexaIndexInfo?.percentage || 0,
            streamingDetails:
                reports.alexaStreamingDetailsInfo?.alexaStreamDetailsInfoList,
            tracks: reports.tracksVoiceInfo?.trackVoiceInfoList,
            inProgress: inProgress,
            ...coreProps,
        };
    }
);

export const programmingScreenSelector = createSelector(
    [
        selectedTimeRangeReportsSelector,
        getReportsInProgress,
        coreReportsStateInfoSelector,
        getRecentlyAddedToPlaylistsInProgress,
        getListAlbumReleasesInProgress,
        getCsvButtonLoadingStatus,
        getHasCsvExportCapabilities,
    ],
    (
        result,
        inProgress,
        coreProps: any,
        recentlyAddedToPlaylistsInProgress,
        listAlbumReleasesInProgress,
        csvButtonLoadingStatus,
        hasCsvExportCapabilities
    ) => {
        return {
            playlists: result?.featuredPlaylistsInfo?.programmingInfoList || [],
            stations: result?.featuredStationsInfo?.programmingInfoList || [],
            inProgress:
                inProgress ||
                recentlyAddedToPlaylistsInProgress ||
                listAlbumReleasesInProgress,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.ProgrammingFeaturedStations,
                CsvButtonId.ProgrammingFeaturedPlaylists,
            ]),
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const playlistScreenReportsKey = createSelector(
    [getSelectedSongAsin, getSelectedTimeRange],
    (selectedSongAsin, selectedRange) =>
        selectedSongAsin && selectedSongAsin.length > 0
            ? `seeAll_playlistTracks_${selectedRange.toString()}_fromTrack_${selectedSongAsin}`
            : `seeAll_playlistTracks_${selectedRange.toString()}`
);

export const playlistScreenSelector = createSelector(
    [
        getReports,
        playlistScreenReportsKey,
        getReportsInProgress,
        coreReportsStateInfoSelector,
        getSelectedSongAsin,
        getCsvButtonLoadingStatus,
        getHasCsvExportCapabilities,
    ],
    (
        reports,
        playlistScreenReportsKey,
        inProgress,
        coreProps: any,
        selectedSongAsin,
        csvButtonLoadingStatus,
        hasCsvExportCapabilities
    ) => {
        const report = reports.get(playlistScreenReportsKey);

        return {
            playlists: report?.featuredPlaylistsInfo?.programmingInfoList || [],
            inProgress: inProgress,
            reportingKey: playlistScreenReportsKey,
            selectedSongAsin: selectedSongAsin,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.SongSeeAllFeaturedPlaylists,
                CsvButtonId.ProgrammingSeeAllFeaturedPlaylists,
            ]),
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const newAddsToPlaylistsScreenSelector = createSelector(
    [coreReportsStateInfoSelector, getRecentlyAddedToPlaylistsInProgress],
    (coreProps, inProgress) => {
        return {
            ...coreProps,
            inProgress,
        };
    }
);

export const stationScreenReportsKey = createSelector(
    [getSelectedSongAsin, getSelectedTimeRange],
    (selectedSongAsin, selectedRange) =>
        selectedSongAsin && selectedSongAsin.length > 0
            ? `seeAll_stationsTracks_${selectedRange.toString()}_fromTrack_${selectedSongAsin}`
            : `seeAll_stationsTracks_${selectedRange.toString()}`
);

export const stationsScreenSelector = createSelector(
    [
        getReports,
        stationScreenReportsKey,
        getReportsInProgress,
        coreReportsStateInfoSelector,
        getSelectedSongAsin,
        getCsvButtonLoadingStatus,
        getHasCsvExportCapabilities,
    ],
    (
        reports,
        stationScreenReportsKey,
        inProgress,
        coreProps: any,
        selectedSongAsin,
        csvButtonLoadingStatus,
        hasCsvExportCapabilities
    ) => {
        const report = reports.get(stationScreenReportsKey);
        return {
            stations: report?.featuredStationsInfo?.programmingInfoList || [],
            inProgress: inProgress,
            reportingKey: stationScreenReportsKey,
            selectedSongAsin: selectedSongAsin,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.SongSeeAllFeaturedStations,
                CsvButtonId.ProgrammingSeeAllFeaturedStations,
            ]),
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const albumsScreenSelector = createSelector(
    [
        getReports,
        getSelectedTimeRange,
        getReportsInProgress,
        getCsvButtonLoadingStatus,
        getHasCsvExportCapabilities,
        coreReportsStateInfoSelector,
    ],
    (
        reports,
        selectedRange,
        inProgress,
        csvButtonLoadingStatus,
        hasCsvExportCapabilities,
        coreProps: any
    ) => {
        const report = reports.get(
            `seeAll_topAlbums_${selectedRange.toString()}`
        );
        return {
            albums: report?.topAlbumsInfo?.albumInfoList || [],
            inProgress: inProgress,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.TopAlbumsTopAlbums,
            ]),
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const songsScreenSelector = createSelector(
    [
        topTracksSelector,
        getReportsInProgress,
        getCsvButtonLoadingStatus,
        getHasCsvExportCapabilities,
        coreReportsStateInfoSelector,
    ],
    (
        tracks,
        inProgress,
        csvButtonLoadingStatus,
        hasCsvExportCapabilities,
        coreProps: any
    ) => {
        return {
            inProgress: inProgress,
            tracks: tracks,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.TopSongsTopSongs,
            ]),
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const countriesScreenSelector = createSelector(
    [
        getReports,
        getSelectedTimeRange,
        getStartDate,
        getEndDate,
        getReportsInProgress,
        coreReportsStateInfoSelector,
    ],
    (reports, selectedRange, startDate, endDate, inProgress, coreProps) => {
        const key: string =
            selectedRange === TimeRange.Custom
                ? `seeAll_topCountries_${startDate?.toString()}_${endDate?.toString()}`
                : `seeAll_topCountries_${selectedRange.toString()}`;
        const report = reports.get(key);

        return {
            topCountries:
                (report &&
                    report.topCountriesInfo &&
                    report.topCountriesInfo.countryInfoList) ||
                [],
            inProgress: inProgress,
            ...coreProps,
        };
    }
);

export const songCountriesScreenSelector = createSelector(
    [
        getReports,
        getSelectedTimeRange,
        getStartDate,
        getEndDate,
        getReportsInProgress,
        getSelectedSongAsin,
        coreReportsStateInfoSelector,
    ],
    (
        reports,
        selectedRange,
        startDate,
        endDate,
        inProgress,
        selectedAsin,
        coreProps: any
    ) => {
        const timeRangePayload = {
            timeRange: selectedRange,
            startDate: startDate,
            endDate: endDate,
        };
        const key = getSeeAllCountriesRequestId(
            "song",
            selectedAsin || "",
            timeRangePayload
        );
        const report = reports.get(key);
        return {
            topCountries:
                (report &&
                    report.topCountriesInfo &&
                    report.topCountriesInfo.countryInfoList) ||
                [],
            inProgress: inProgress,
            ...coreProps,
        };
    }
);

export const albumCountriesScreenSelector = createSelector(
    [
        getReports,
        getSelectedTimeRange,
        getStartDate,
        getEndDate,
        getReportsInProgress,
        getSelectedAlbumAsin,
        coreReportsStateInfoSelector,
    ],
    (
        reports,
        selectedRange,
        startDate,
        endDate,
        inProgress,
        selectedAsin,
        coreProps: any
    ) => {
        const timeRangePayload = {
            timeRange: selectedRange,
            startDate: startDate,
            endDate: endDate,
        };
        const key = getSeeAllCountriesRequestId(
            "album",
            selectedAsin || "",
            timeRangePayload
        );
        const report = reports.get(key);
        return {
            topCountries:
                (report &&
                    report.topCountriesInfo &&
                    report.topCountriesInfo.countryInfoList) ||
                [],
            inProgress: inProgress,
            ...coreProps,
        };
    }
);

export const stationCountriesScreenSelector = createSelector(
    [
        getReports,
        getSelectedTimeRange,
        getStartDate,
        getEndDate,
        getReportsInProgress,
        getSelectedStationAsin,
        coreReportsStateInfoSelector,
    ],
    (
        reports,
        selectedRange,
        startDate,
        endDate,
        inProgress,
        selectedAsin,
        coreProps: any
    ) => {
        const timeRangePayload = {
            timeRange: selectedRange,
            startDate: startDate,
            endDate: endDate,
        };
        const key = getSeeAllCountriesRequestId(
            "station",
            selectedAsin || "",
            timeRangePayload
        );
        const report = reports.get(key);
        return {
            topCountries:
                (report &&
                    report.topCountriesInfo &&
                    report.topCountriesInfo.countryInfoList) ||
                [],
            inProgress: inProgress,
            ...coreProps,
        };
    }
);

export const playlistCountriesScreenSelector = createSelector(
    [
        getReports,
        getSelectedTimeRange,
        getStartDate,
        getEndDate,
        getReportsInProgress,
        getSelectedPlaylistAsin,
        coreReportsStateInfoSelector,
    ],
    (
        reports,
        selectedRange,
        startDate,
        endDate,
        inProgress,
        selectedAsin,
        coreProps: any
    ) => {
        const timeRangePayload = {
            timeRange: selectedRange,
            startDate: startDate,
            endDate: endDate,
        };
        const key = getSeeAllCountriesRequestId(
            "playlist",
            selectedAsin || "",
            timeRangePayload
        );
        const report = reports.get(key);
        return {
            topCountries:
                (report &&
                    report.topCountriesInfo &&
                    report.topCountriesInfo.countryInfoList) ||
                [],
            inProgress: inProgress,
            ...coreProps,
        };
    }
);

export const citiesScreenSelector = createSelector(
    [
        getReports,
        getSelectedTimeRange,
        getReportsInProgress,
        coreReportsStateInfoSelector,
    ],
    (reports, selectedRange, inProgress, coreProps: any) => {
        const report = reports.get(
            `seeAll_topCities_${selectedRange.toString()}`
        );

        return {
            topCities:
                (report &&
                    report.topCitiesInfo &&
                    report.topCitiesInfo.cityInfoList) ||
                [],
            inProgress: inProgress,
            ...coreProps,
        };
    }
);

export const voiceTracksScreenSelector = createSelector(
    [getReportsInProgress, voiceTracksSelector, coreReportsStateInfoSelector],
    (inProgress, voiceTracks, coreProps: any) => {
        return {
            inProgress: inProgress,
            voiceTracks: voiceTracks,
            ...coreProps,
        };
    }
);

export const albumTracksScreenSelector = createSelector(
    [
        getReportsInProgress,
        albumTracksSelector,
        getCsvButtonLoadingStatus,
        getHasCsvExportCapabilities,
        coreReportsStateInfoSelector,
    ],
    (
        inProgress,
        albumTracks,
        csvButtonLoadingStatus,
        hasCsvExportCapabilities,
        coreProps: any
    ) => {
        return {
            inProgress: inProgress,
            albumTracks: albumTracks,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.AlbumSeeAllTopSongs,
            ]),
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const fansScreenSelector = createSelector(
    [
        selectedTimeRangeReportsSelector,
        getReportsInProgress,
        getCsvButtonLoadingStatus,
        getHasCsvExportCapabilities,
        coreReportsStateInfoSelector,
    ],
    (
        result,
        inProgress,
        csvButtonLoadingStatus,
        hasCsvExportCapabilities,
        coreProps: any
    ) => {
        if (!result) {
            return {
                topCountries: [],
                inProgress: inProgress,
                csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                    CsvButtonId.FansTrendlines,
                ]),
                hasCsvExportCapabilities,
                ...coreProps,
            };
        }

        return {
            fanTiersInfo: result.fanTiersInfo,
            trackListeners: result.trackListenersInfo,
            topCountries:
                (result.topCountriesInfo &&
                    result.topCountriesInfo.countryInfoList) ||
                [],
            topCities:
                (result.topCitiesInfo && result.topCitiesInfo.cityInfoList) ||
                [],
            inProgress: inProgress,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.FansTrendlines,
            ]),
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const songsPerformanceScreenSelector = createSelector(
    [
        getSongPerformanceReports,
        getReportsInProgress,
        getSelectedSongAsin,
        getShareLinkResponse,
        getGenerateShareLinkInProgress,
        getCsvButtonLoadingStatus,
        getHasPromoCardFeaturePermission,
        getHasCsvExportCapabilities,
        coreReportsStateInfoSelector,
    ],
    (
        reports,
        inProgress,
        selectedAsin,
        shareLinkResponse,
        generateShareLinkInProgress,
        csvButtonLoadingStatus,
        showHypeDeck,
        hasCsvExportCapabilities,
        coreProps: any
    ) => {
        if (!reports || !selectedAsin) {
            return {
                tracks: [],
                playlists: [],
                stations: [],
                streamSources: [],
                topCountries: [],
                inProgress: inProgress,
                selectedAsin: selectedAsin,
                shareLinkResponse: shareLinkResponse,
                generateShareLinkInProgress: generateShareLinkInProgress,
                csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                    CsvButtonId.SongDetailTrendlines,
                    CsvButtonId.SongDetailFeaturedPlaylists,
                    CsvButtonId.SongDetailFeaturedStations,
                ]),
                showHypeDeck: showHypeDeck,
                hasCsvExportCapabilities,
                ...coreProps,
            };
        }

        const selectedReport = reports.get(selectedAsin);
        return {
            trackStreamInfo: selectedReport?.trackStreamsInfo,
            trackListenerInfo: selectedReport?.trackListenersInfo,
            playlists:
                selectedReport?.featuredPlaylistsInfo?.programmingInfoList ||
                [],
            stations:
                selectedReport?.featuredStationsInfo?.programmingInfoList || [],
            tracks: selectedReport?.topTracksInfo?.trackInfoList || [],
            streamSources:
                selectedReport?.streamSourcesInfo?.streamSourcesInfoList || [],
            topCountries:
                selectedReport?.topCountriesInfo?.countryInfoList || [],
            inProgress: inProgress,
            selectedAsin: selectedAsin,
            shareLinkResponse: shareLinkResponse,
            generateShareLinkInProgress: generateShareLinkInProgress,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.SongDetailTrendlines,
                CsvButtonId.SongDetailFeaturedPlaylists,
                CsvButtonId.SongDetailFeaturedStations,
            ]),
            showHypeDeck,
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const albumPerformanceScreenSelector = createSelector(
    [
        getAlbumPerformanceReports,
        getReportsInProgress,
        getSelectedAlbumAsin,
        getShareLinkResponse,
        getGenerateShareLinkInProgress,
        getCsvButtonLoadingStatus,
        getHasPromoCardFeaturePermission,
        getHasCsvExportCapabilities,
        coreReportsStateInfoSelector,
    ],
    (
        reports,
        inProgress,
        selectedAsin,
        shareLinkResponse,
        generateShareLinkInProgress,
        csvButtonLoadingStatus,
        showHypeDeck,
        hasCsvExportCapabilities,
        coreProps: any
    ) => {
        if (!reports || !selectedAsin) {
            return {
                tracks: [],
                topCountries: [],
                inProgress: inProgress,
                selectedAsin: selectedAsin,
                shareLinkResponse: shareLinkResponse,
                generateShareLinkInProgress: generateShareLinkInProgress,
                csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                    CsvButtonId.AlbumDetailTrendlines,
                    CsvButtonId.AlbumDetailTopSongs,
                ]),
                showHypeDeck,
                hasCsvExportCapabilities,
                ...coreProps,
            };
        }

        const report = reports.get(selectedAsin);
        return {
            listenersInfo: report?.trackListenersInfo,
            streamInfo: report?.trackStreamsInfo,
            tracks: report?.topTracksInfo?.trackInfoList || [],
            topCountries: report?.topCountriesInfo?.countryInfoList || [],
            selectedAsin: selectedAsin,
            inProgress: inProgress,
            shareLinkResponse: shareLinkResponse,
            generateShareLinkInProgress: generateShareLinkInProgress,
            csvButtonLoadingStatus: _.pick(csvButtonLoadingStatus, [
                CsvButtonId.AlbumDetailTrendlines,
                CsvButtonId.AlbumDetailTopSongs,
            ]),
            showHypeDeck,
            hasCsvExportCapabilities,
            ...coreProps,
        };
    }
);

export const playlistPerformanceScreenSelector = createSelector(
    [
        getPlaylistPerformanceReports,
        getReportsInProgress,
        getSelectedPlaylistAsin,
        coreReportsStateInfoSelector,
    ],
    (reports, inProgress, selectedAsin, coreProps: any) => {
        if (!reports || !selectedAsin) {
            return {
                tracks: [],
                inProgress: inProgress,
                selectedAsin: selectedAsin,
                topCountries: [],
                ...coreProps,
            };
        }

        const selectedReport = reports.get(selectedAsin);
        return {
            tracks: selectedReport?.topTracksInfo?.trackInfoList || [],
            trackStreamInfo: selectedReport?.trackStreamsInfo,
            trackListenerInfo: selectedReport?.trackListenersInfo,
            inProgress: inProgress,
            selectedAsin: selectedAsin,
            topCountries:
                selectedReport?.topCountriesInfo?.countryInfoList || [],
            ...coreProps,
        };
    }
);

export const stationPerformanceScreenSelector = createSelector(
    [
        getStationPerformanceReports,
        getReportsInProgress,
        getSelectedStationAsin,
        coreReportsStateInfoSelector,
    ],
    (reports, inProgress, selectedAsin, coreProps: any) => {
        if (!reports || !selectedAsin) {
            return {
                tracks: [],
                inProgress: inProgress,
                selectedAsin: selectedAsin,
                topCountries: [],
                ...coreProps,
            };
        }

        const selectedReport = reports.get(selectedAsin);
        return {
            tracks: selectedReport?.topTracksInfo?.trackInfoList || [],
            trackStreamInfo: selectedReport?.trackStreamsInfo,
            trackListenerInfo: selectedReport?.trackListenersInfo,
            inProgress: inProgress,
            selectedAsin: selectedAsin,
            topCountries:
                selectedReport?.topCountriesInfo?.countryInfoList || [],
            ...coreProps,
        };
    }
);

export const headerSelector = createSelector(
    [
        getIsPrivileged,
        getSelectedTimeRange,
        getUserInfo,
        getSelectedTeamSelector,
        getSelectedArtistAsin,
        getStartDate,
        getEndDate,
    ],
    (
        isPrivileged,
        selectedTimeRange,
        userInfo,
        team,
        asin,
        startDate,
        endDate
    ) => {
        return {
            selectedRange: selectedTimeRange,
            signedIn: userInfo,
            teamInfo: team,
            selectedArtist: asin,
            isPrivileged: isPrivileged,
            startDate: startDate,
            endDate: endDate,
        };
    }
);
