import { createSelector } from "reselect";
import {
    GlobalFeature,
    OAuth3PStateTokens,
    StateTokensInProgressStatus,
} from "../../../models";
import _ from "lodash";
import { RootState } from "../../reducers";

const customerSupportData = (state: RootState) => state.oAuth.customerSupport;
const intercomEvent = (state: RootState) => state.oAuth.intercomEvent;
const hideIntercomLauncher = (state: RootState) =>
    state.oAuth.hideIntercomLauncher;
const showIntercomMessenger = (state: RootState) =>
    state.oAuth.showIntercomMessenger;
const getUserLocale = (state: RootState) => state.user.locale;

const getUserData = (state: RootState) => state.user.userData;

const getHasIntercomFeaturePermission = (state: RootState) => {
    return (
        state.featureAccess.globalFeaturePermissionsList.length !== 0 &&
        !!_.find(
            state.featureAccess.globalFeaturePermissionsList,
            (permission) => {
                return permission.feature === GlobalFeature.INTERCOM;
            }
        )
    );
};

export const get3PStateTokens = (state: RootState): OAuth3PStateTokens =>
    state.oAuth.oAuth3PStateTokens;

export const get3PStateTokensInProgress = (
    state: RootState
): StateTokensInProgressStatus => state.oAuth.stateTokensInProgress;

export const customerSupportSelector = createSelector(
    [
        customerSupportData,
        intercomEvent,
        showIntercomMessenger,
        getHasIntercomFeaturePermission,
        hideIntercomLauncher,
        getUserLocale,
        getUserData,
    ],
    (
        customerSupport,
        intercomEvent,
        showIntercomMessenger,
        getHasIntercomFeaturePermission,
        hideIntercomLauncher,
        getUserLocale,
        userData
    ) => {
        return {
            customerSupport: customerSupport,
            intercomEvent: intercomEvent,
            showIntercomMessenger: showIntercomMessenger,
            hasIntercomFeaturePermission: getHasIntercomFeaturePermission,
            hideIntercomLauncher: hideIntercomLauncher,
            locale: getUserLocale,
            userData: userData,
        };
    }
);
