import * as React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { buttonIds } from "@amzn/ziggy-asset";
import _ from "lodash";
import * as rootStyles from "../../styles";
import { IconsList, merchImages } from "../../../assets";
import { EntityType, ProfileImageSize } from "../../../models";
import {
    Icon,
    ImageWithFallback,
    ImageWithFallbackProps,
} from "../../components";
import { MerchOverflow } from "./MerchOverflow";
import { clientMetricsActions } from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { selectedArtistFromCatalogSelector } from "../../../store/selectors";
import {
    buildUIClickPayload,
    LARGE_SCREEN_WIDTH_THRESHOLD,
    MerchLinkBuilders,
} from "../../../utils";

const IMAGE_SIZE = ProfileImageSize.Medium;
const COLUMN_PADDING = 15;

export const merchItemCardWidth = IMAGE_SIZE + COLUMN_PADDING * 2;

export type merchItemCardProps = {
    isLoading?: boolean;
    image?: string;
    itemAsin: string;
    primaryText?: string;
    secondaryText?: string;
    tertiaryText?: string;
    onClick?: () => void;
    id: string;
    requestPath: string;
    pageId: string;
};

export const MerchItemCard: React.FC<merchItemCardProps> = ({
    isLoading,
    image,
    itemAsin,
    primaryText,
    secondaryText,
    tertiaryText,
    onClick,
    id,
    requestPath,
    pageId,
}) => {
    const [isMouseOverImage, setIsMouseOverImage] =
        React.useState<boolean>(false);

    const artist = useSelector((state: RootState) =>
        selectedArtistFromCatalogSelector(state)
    );
    const dispatch = useDispatch();

    const openAmazonMusicMerchWindow = () => {
        if (artist?.asin) {
            dispatch(
                clientMetricsActions.sendClientMetrics(
                    buildUIClickPayload(
                        buttonIds.Merch.viewItemOnAmazonMusic,
                        pageId,
                        itemAsin,
                        EntityType.Merch
                    )
                )
            );
            const merchItemLink = MerchLinkBuilders.viewItemOnAmazonMusic(
                artist.asin,
                itemAsin
            );
            window.open(merchItemLink, "_blank", "noreferrer, noopener");
        }
    };
    const mobileView = window.innerWidth < LARGE_SCREEN_WIDTH_THRESHOLD;

    if (isLoading) {
        return (
            <Col
                style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: merchItemCardWidth,
                }}
            >
                <Row>
                    <div
                        style={{
                            ...rootStyles.tableStyles.imageLoading,
                            height: IMAGE_SIZE,
                            width: IMAGE_SIZE,
                            borderRadius: rootStyles.spacers.mini,
                        }}
                    />
                </Row>
                <Row
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginTop: rootStyles.spacers.mini,
                        flexWrap: "nowrap",
                    }}
                >
                    <span style={rootStyles.tableStyles.primaryLoading} />
                </Row>
            </Col>
        );
    }

    return (
        <Col
            style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: merchItemCardWidth,
            }}
        >
            <Row
                onMouseOver={() => setIsMouseOverImage(true)}
                onMouseOut={() => setIsMouseOverImage(false)}
                style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                }}
                onClick={openAmazonMusicMerchWindow}
            >
                <MerchItemImage
                    source={image}
                    fallback={merchImages.placeholderMerch}
                    id={`${id}-Image`}
                />
                {isMouseOverImage && !mobileView && (
                    <>
                        {/* https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101?node-id=814%3A43997 */}
                        <div
                            style={{
                                position: "absolute",
                                height: 64,
                                width: 64,
                                borderRadius: 32,
                                background: rootStyles.glassColors.primary3,
                                pointerEvents: "none",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Icon
                                size={30}
                                icon={IconsList.share_arrow}
                                style={{
                                    position: "absolute",
                                    pointerEvents: "none",
                                }}
                                id={`${id}_ShareArrow`}
                            />
                        </div>
                    </>
                )}
            </Row>
            <Row
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: rootStyles.spacers.mini,
                    flexWrap: "nowrap",
                }}
            >
                <PrimaryTextHoverSpan onClick={openAmazonMusicMerchWindow}>
                    {primaryText}
                </PrimaryTextHoverSpan>
                <MerchOverflow
                    showCuratedItemOptions={true}
                    itemAsin={itemAsin}
                    itemName={primaryText || ""}
                    productImage={image}
                    id={`${id}_OverflowMenu`}
                    iconSize={rootStyles.icons.small}
                    additionalButtonStyle={{
                        padding: 0,
                    }}
                    requestPath={requestPath}
                    pageId={pageId}
                />
            </Row>
            <Row>
                <span
                    style={{
                        ...rootStyles.textStyles.secondary,
                        ...noWrapEllipsisStyle,
                    }}
                >
                    {secondaryText}
                </span>
            </Row>
            <Row>
                <span
                    style={{
                        ...rootStyles.textStyles.tertiary,
                        ...noWrapEllipsisStyle,
                    }}
                >
                    {tertiaryText}
                </span>
            </Row>
        </Col>
    );
};

const noWrapEllipsisStyle: React.CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

//https://github.com/styled-components/styled-components/issues/1449#issuecomment-420087359
const MerchItemImage = styled((props: ImageWithFallbackProps) => (
    <ImageWithFallback {...props} />
))`
    height: ${IMAGE_SIZE}px;
    width: ${IMAGE_SIZE}px;
    border-radius: 8px;
    background-color: ${(props) =>
        !!props.source ? `${rootStyles.colors.primary}` : "default"};
    @media (min-width: 992px) {
        &:hover {
            opacity: ${rootStyles.glass._3};
        }
    }
`;

const PrimaryTextHoverSpan = styled.span`
    ${{ ...noWrapEllipsisStyle }};
    ${{ ...rootStyles.textStyles.primary }};
    flex: 1;
    cursor: pointer;
    @media (min-width: 992px) {
        &:hover {
            color: ${rootStyles.colors.accent};
        }
    }
`;
