import axios from "axios";
import {
    inviteTeamMemberResponse,
    getTeamResponse,
    TeamRole,
    inviteTeamMemberPayload,
    listInvitePayload,
    getTeamPayload,
    setPermissionsPayload,
    removeMemberPayload,
    setMemberInfoPayload,
    promoteToOwnerPayload,
    acceptTeamInvitePayload,
    cancelInvitePayload,
    backendInvitedTeamMemberModel,
    getInvitedMemberResponse,
    checkTeamExistsPayload,
    checkTeamExistsResponse,
    checkTeamExistsRequest,
} from "../models";
import { getEndpoints, testHeaders } from "./common";

const getManageTeamUrl = (baseUrl: string) => `${baseUrl}/teammanagement/teams`;
const getAcceptInviteUrl = (baseUrl: string) => `${baseUrl}/invite/accept`;
const getSendInviteUrl = (baseUrl: string, teamId: string) =>
    `${baseUrl}/${teamId}/invite`;
const getCancelInviteUrl = (baseUrl: string, teamId: string) =>
    `${baseUrl}/${teamId}/invite/cancel`;
const getListInviteUrl = (baseUrl: string, teamId: string) =>
    `${baseUrl}/${teamId}/invite/list`;

const baseUrls = getEndpoints();

export async function inviteTeamMember(
    query: inviteTeamMemberPayload
): Promise<inviteTeamMemberResponse> {
    const request = {
        role: query.access,
        permissions: query.access === TeamRole.Custom ? query.permissions : [],
        jobTitle: query.jobTitle,
        name: query.name,
        company: query.company,
        email: query.email,
    };

    const result = await buildRequest(
        getSendInviteUrl(baseUrls.inviteMangement, query.teamId),
        request,
        query.teamId
    );

    return result.data;
}

export async function cancelInvite(query: cancelInvitePayload): Promise<void> {
    const request = {
        email: query.email,
    };

    const result = await buildRequest(
        getCancelInviteUrl(baseUrls.inviteMangement, query.teamId),
        request,
        query.teamId
    );

    return result.data;
}

export async function listInvite(
    payload: listInvitePayload
): Promise<getInvitedMemberResponse> {
    const request = {
        paginationToken: payload.paginationToken,
    };

    let invitesResponse: backendInvitedTeamMemberModel[];

    let result = await buildRequest(
        getListInviteUrl(baseUrls.inviteMangement, payload.teamId),
        request,
        payload.teamId
    );

    invitesResponse = result.data.invites;

    while (result.data.paginationToken !== undefined) {
        request.paginationToken = result.data.paginationToken;

        result = await buildRequest(
            getListInviteUrl(baseUrls.inviteMangement, payload.teamId),
            request,
            payload.teamId
        );

        invitesResponse = invitesResponse.concat(result.data.invites);
    }

    result.data.invites = invitesResponse;

    return result.data;
}

export async function getTeam(
    payload: getTeamPayload
): Promise<getTeamResponse> {
    const requestBody = {};
    const result = await buildRequest(
        `${getManageTeamUrl(baseUrls.teamManagement)}/get`,
        requestBody,
        payload.teamId
    );

    return result.data;
}

export async function setMemberPermissions(
    payload: setPermissionsPayload
): Promise<void> {
    const request = {
        memberId: payload.memberId,
        permissions: payload.permissions,
        role: payload.role,
    };

    // Request doesn't return anything unless there's an error, which will throw an exception.
    await buildRequest(
        `${getManageTeamUrl(baseUrls.teamManagement)}/${
            payload.teamId
        }/permissions`,
        request,
        payload.teamId
    );
}

export async function setMemberInfo(
    payload: setMemberInfoPayload
): Promise<void> {
    const request = {
        memberId: payload.memberId,
        jobTitle: payload.jobTitle,
        company: payload.company,
    };

    // Request doesn't return anything unless there's an error, which will throw an exception.
    await buildRequest(
        `${getManageTeamUrl(baseUrls.teamManagement)}/${
            payload.teamId
        }/memberInfo`,
        request,
        payload.teamId
    );
}

export async function removeTeamMember(
    payload: removeMemberPayload
): Promise<void> {
    const request = {
        memberId: payload.memberId,
    };

    // Request doesn't return anything unless there's an error, which will throw an exception.
    await buildRequest(
        `${getManageTeamUrl(baseUrls.teamManagement)}/${payload.teamId}/remove`,
        request,
        payload.teamId
    );
}

export async function promoteToOwner(
    payload: promoteToOwnerPayload
): Promise<void> {
    const request = {
        memberId: payload.memberId,
    };

    await buildRequest(
        `${getManageTeamUrl(baseUrls.teamManagement)}/${
            payload.teamId
        }/promote`,
        request,
        payload.teamId
    );
}

export async function checkTeamExists(
    payload: checkTeamExistsPayload
): Promise<checkTeamExistsResponse> {
    const request: checkTeamExistsRequest = {
        artistAsin: payload.artistAsin,
    };

    const result = await axios.post(
        `${getManageTeamUrl(baseUrls.teamManagement)}/checkTeamExists`,
        request,
        {
            headers: {
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function acceptTeamInvite(
    payload: acceptTeamInvitePayload
): Promise<void> {
    return axios.post(`${getAcceptInviteUrl(baseUrls.inviteMangement)}`, {
        inviteId: payload.inviteId,
    });
}

function buildRequest(url: string, payload: any, teamId: string) {
    return axios.post(url, payload, {
        headers: {
            "x-team-id": teamId,
            ...testHeaders(),
        },
    });
}
