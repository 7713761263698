import _ from "lodash";
import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { group, itemProps } from "../../../models";
import * as rootStyles from "../../styles";
import { spacers } from "../../styles";
import { GroupItemsNotFoundProps } from "./helpers";
import { Icon, IconsList } from "./icons";
import { ImageWithFallback } from ".";

const DropdownResultsPrefix = "DropdownResults";
export const DROPDOWN_HEIGHT = "calc(24vh + 56px)";

export type DropdownResultsProps = {
    groups: group[];
    onChange: (groups: group[]) => void;
    width?: number;
    headerElement?: JSX.Element;
    hide: boolean;
    maxSelectReached: boolean;
    groupItemsNotFoundProps?: GroupItemsNotFoundProps;
    id: string;
};

export const DropdownResults: React.FC<DropdownResultsProps> = ({
    groups,
    onChange,
    width,
    headerElement,
    hide = false,
    maxSelectReached,
    groupItemsNotFoundProps,
    id,
}) => {
    const onClick = (
        groupIndex: number,
        itemIndex: number,
        selected: boolean
    ) => {
        // Don't allow selecting more items is max is reached
        if (maxSelectReached && selected) {
            return;
        }
        const newGroupState = _.cloneDeep(groups);
        newGroupState[groupIndex].items[itemIndex].selected = selected;
        onChange(newGroupState);
    };

    const sumUpItemsPerGroup = () => {
        let totalItems = 0;
        groups.map((group: group) => {
            totalItems += group.items.length;
        });
        return totalItems;
    };

    const renderMessageIfNoResults = (properties: itemProps) => (
        <div
            key={`${properties.name}_${groupItemsNotFoundProps?.groupIndex}_noResults`}
            style={{
                overflowY: "hidden",
            }}
        >
            {!!groupItemsNotFoundProps?.message ? (
                <Row
                    style={{
                        lineHeight: 1,
                    }}
                    id={`${id}-${DropdownResultsPrefix}-Group-${groupItemsNotFoundProps?.groupIndex}`}
                >
                    <span
                        style={{
                            ...dropdownItemTextStyle,
                        }}
                    >
                        {groupItemsNotFoundProps.message}
                    </span>
                </Row>
            ) : null}
        </div>
    );

    const renderGroup = (
        properties: itemProps,
        groupIndex: number,
        items: itemProps[]
    ) => {
        // Want to show no items found message for chosen group only if there are
        // no item results for any groups
        if (
            groupIndex === groupItemsNotFoundProps?.groupIndex &&
            sumUpItemsPerGroup() === 0
        ) {
            return renderMessageIfNoResults(properties);
        }

        const result = items.map((item: itemProps, index: number) =>
            renderItem(groupIndex, item, index)
        );
        return (
            <div
                key={`${properties.name}_${groupIndex}_${properties.hide}`}
                style={{ display: properties.hide ? "none" : undefined }}
            >
                {properties && items && items.length > 0 ? (
                    <Row
                        style={{
                            marginBottom: -rootStyles.spacers.micro,
                            lineHeight: 1,
                        }}
                        id={`${id}-${DropdownResultsPrefix}-Group-${groupIndex}`}
                    >
                        <span style={dropdownItemTextStyle}>
                            {properties.name}
                        </span>
                    </Row>
                ) : null}
                {result}
            </div>
        );
    };

    const renderItem = (
        groupIndex: number,
        item: itemProps,
        itemIndex: number
    ) => {
        return (
            <div
                key={`${item.name}_${itemIndex}_${item.hide}`}
                style={{ display: item.hide ? "none" : undefined }}
                id={`${id}-${DropdownResultsPrefix}-Item-${itemIndex}`}
            >
                <DropdownItem
                    title={item.name}
                    onChange={(selected) =>
                        onClick(groupIndex, itemIndex, selected)
                    }
                    value={item.selected}
                    id={`${id}-${DropdownResultsPrefix}`}
                    image={item.image}
                    fallbackImage={item.fallbackImage}
                />
            </div>
        );
    };

    let isFirstGroupWithItems = false;
    const result = groups.map((group: group, index: number) => {
        const doesGroupHaveItems: boolean = group.items.length > 0;

        const marginTop =
            doesGroupHaveItems && isFirstGroupWithItems
                ? rootStyles.spacers.large
                : undefined;
        isFirstGroupWithItems = isFirstGroupWithItems || doesGroupHaveItems;

        return (
            <div
                style={{
                    marginTop: marginTop,
                    display: group.properties.hide ? "none" : undefined,
                }}
            >
                {renderGroup(group.properties, index, group.items)}
            </div>
        );
    });

    return (
        <div
            style={{
                ...dropdownResultsOuterContainerStyle,
                width: width,
                display: hide ? "none" : undefined,
            }}
        >
            <div style={dropdownResultsInnerContainerStyle}>
                {headerElement ? (
                    <div id={`${id}-${DropdownResultsPrefix}-HeaderElement`}>
                        {headerElement}
                    </div>
                ) : null}
                {result}
            </div>
        </div>
    );
};

type DropdownItemProps = {
    title: string;
    onChange: (selected: boolean) => void;
    value?: boolean;
    id: string;
    image?: string;
    fallbackImage?: string;
};

const DropdownItem: React.FC<DropdownItemProps> = ({
    title,
    onChange,
    value = false,
    id,
    image,
    fallbackImage,
}) => {
    const [isMouseOverItem, setIsMouseOverItem] = useState<boolean>(false);
    const onClick = () => {
        const newSelectedValue = !value;
        onChange(newSelectedValue);
    };

    return (
        <Row
            onClick={() => onClick()}
            style={{
                ...dropdownItemContainerStyle,
                backgroundColor: isMouseOverItem
                    ? rootStyles.glassColors.primary2
                    : undefined,
            }}
            onMouseEnter={() => setIsMouseOverItem(true)}
            onMouseLeave={() => setIsMouseOverItem(false)}
        >
            {image || fallbackImage ? (
                <ImageWithFallback
                    source={image}
                    style={rootStyles.imageStyles.miniRoundImage}
                    fallback={fallbackImage}
                    id={`${id}_Image`}
                />
            ) : null}
            <div
                style={{
                    flex: 1,
                    userSelect: "none",
                    lineHeight: 1,
                    minHeight: 32,
                    display: "flex",
                    alignItems: "center",
                }}
                id={`${id}-Title`}
            >
                <span
                    style={{
                        ...rootStyles.textStyles.primary,
                        textTransform: "capitalize",
                    }}
                >
                    {title}
                </span>
            </div>
            {value ? (
                <div style={{ display: "flex", paddingRight: spacers.small }}>
                    <Icon
                        icon={IconsList.action_doneInline_blue}
                        size={20}
                        id={`${id}_DoneIcon`}
                    />
                </div>
            ) : null}
        </Row>
    );
};

const dropdownResultsOuterContainerStyle: React.CSSProperties = {
    flex: 1,
    width: "inherit",
    position: "absolute",
    backgroundColor: rootStyles.colors.secondary,
    backgroundClip: "content-box",
    maxHeight: DROPDOWN_HEIGHT,
    zIndex: 10,
    marginTop: rootStyles.spacers.small,
    borderRadius: 9,
};

const dropdownResultsInnerContainerStyle: React.CSSProperties = {
    width: "calc(100% - 15px)",
    marginRight: rootStyles.spacers.small,
    overflowY: "auto",
    maxHeight: "24vh",
    paddingRight: rootStyles.spacers.medium,
    paddingLeft: rootStyles.spacers.medium,
    marginBottom: rootStyles.spacers.large,
    marginTop: rootStyles.spacers.large,
};

const dropdownItemContainerStyle: React.CSSProperties = {
    alignItems: "center",
    marginTop: rootStyles.spacers.small,
    cursor: "pointer",
};

const dropdownItemTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.label,
    textTransform: "uppercase",
    userSelect: "none",
};

export default DropdownResults;
