import { Action } from "redux-ts";
import { promoCardActions } from "../actions";
import { CardLayout, EntityType, PromoCardBackgroundColor } from "../../models";
import { defaultLocale } from "../../utils";
export type PromoCardState = Readonly<{
    selectedStep?: number;
    selectedEntity: EntityType;
    selectedCardLayout: CardLayout;
    selectedCardColor: PromoCardBackgroundColor;
    selectedCardLocale: string;
}>;

export const initialPromoCardReducerState = {
    selectedEntity: EntityType.ARTIST,
    selectedCardLayout: CardLayout.Portrait,
    selectedCardColor: PromoCardBackgroundColor.CYAN,
    selectedCardLocale: defaultLocale,
};

export const promoCardReducer = (
    state: PromoCardState = initialPromoCardReducerState,
    action: Action
): PromoCardState => {
    switch (action.type) {
        case promoCardActions.selectStep.type:
            return { ...state, selectedStep: action.payload };
        case promoCardActions.selectEntity.type:
            return { ...state, selectedEntity: action.payload };
        case promoCardActions.selectCardLayout.type:
            return { ...state, selectedCardLayout: action.payload };
        case promoCardActions.selectCardColor.type:
            return { ...state, selectedCardColor: action.payload };
        case promoCardActions.selectCardLocale.type:
            return { ...state, selectedCardLocale: action.payload };
        default:
            return state;
    }
};
