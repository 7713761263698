import * as React from "react";
import * as rootStyles from "../../styles";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { styledTitle } from "../common/Title";
import ArtistImgDropdown from "../common/ArtistImgDropdown";
import { ProfileImageSize } from "../../../models";
import { IconsList } from "../../../assets";
import { Icon, ImageWithFallback } from "../common";

export type MerchSearchHeaderProps = {
    headerStyle?: React.CSSProperties;
    image?: any;
    imageFallBack?: any;
    hideImage?: boolean;
    imageDescription?: string;
    backButtonOnClick?: () => void;
    showBackButton?: boolean;
    showArtistImgDropdown?: boolean;
};

const testIDPrefix = "merchSearchHeader";

//Header for the merch section of AM4A, showing the artists profile image in the center along with a title below.
//Also has a back button in the upper left and the artistImgDropdown component in the top right
export class MerchSearchHeader extends React.Component<MerchSearchHeaderProps> {
    render() {
        return (
            <ImageHeaderContainer style={this.props.headerStyle}>
                {this.props.showBackButton && (
                    <Col xs={1}>
                        <Icon
                            icon={IconsList.chevron_caretleft}
                            size={rootStyles.icons.large}
                            color={rootStyles.colors.primary}
                            onClick={this.props.backButtonOnClick}
                            id={`${testIDPrefix}-backButton`}
                        />
                    </Col>
                )}
                <Col style={ImageStyle}>
                    {!this.props.hideImage && (
                        <ImageWithFallback
                            style={{
                                width: ProfileImageSize.Small,
                                height: ProfileImageSize.Small,
                                marginBottom: rootStyles.spacers.large,
                                borderRadius: ProfileImageSize.Small / 2,
                            }}
                            source={this.props.image}
                            fallback={this.props.imageFallBack}
                            id={`${testIDPrefix}-centerImage`}
                        />
                    )}
                    <Row>
                        <styledTitle.h4 style={{ textAlign: "center" }}>
                            {this.props.imageDescription}
                        </styledTitle.h4>
                    </Row>
                </Col>
                {this.props.showBackButton && (
                    <Col xs={1}>
                        {this.props.showArtistImgDropdown && (
                            <ArtistImgContainer>
                                <ArtistImgDropdown />
                            </ArtistImgContainer>
                        )}
                        <HiddenPseudoBackButton
                            style={{ visibility: "hidden" }}
                        />
                    </Col>
                )}
            </ImageHeaderContainer>
        );
    }
}

const ImageHeaderContainer = styled(Row)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: ${rootStyles.spacers.huge}px;
    @media (max-width: 992px) {
        margin-top: 80px;
    }
`;

const ImageStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

const HiddenPseudoBackButton = styled.div`
    width: ${rootStyles.icons.large}px;
    height: ${rootStyles.icons.large}px;
`;

const ArtistImgContainer = styled.div`
    margin-bottom: 16px;
    margin-top: -24px;
    @media (max-width: 992px) {
        display: none;
    }
`;
