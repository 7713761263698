import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getLocalizedString } from "../../../utils";
import * as rootStyles from "../../styles";
import { Icon, IconsList } from "../common";
import { commonSelectors } from "../../../store/selectors";
import { BundleMap } from "../../../models";

export type FormNavigationStep = {
    stringId: string;
    bundleId: string;
    enabled: boolean;
};

export type FormNavigationProps = {
    stepList: FormNavigationStep[];
    onChange: (index: number | undefined) => any;
    defaultValue?: number;
    id: string;
    jumpToStep?: number | undefined;
};

export const FormNavigation: React.FC<FormNavigationProps> = ({
    stepList,
    onChange,
    defaultValue,
    id,
    jumpToStep = undefined,
}) => {
    const FormNavigationPrefix = "FormNavigation";

    const [currentIndex, setCurrentIndex] = useState<number | undefined>(
        defaultValue
    );
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    React.useEffect(() => {
        if (jumpToStep === undefined) {
            return;
        }
        if (jumpToStep !== currentIndex) {
            setCurrentIndex(jumpToStep);
        }
    }, [jumpToStep]);

    React.useEffect(() => {
        onChange(currentIndex);
    }, [currentIndex]);

    const renderStep = (step: FormNavigationStep, index: number) => {
        const isStepSelected: boolean = index === currentIndex;
        const isLastStep: boolean = index === stepList.length - 1;
        return (
            <div
                style={{ display: "inline-block" }}
                key={`${step.stringId}_${index}`}
            >
                <span
                    style={{
                        ...rootStyles.textStyles.tertiary,
                        cursor: step.enabled ? "pointer" : "default",
                        color: isStepSelected
                            ? rootStyles.colors.accent
                            : rootStyles.glassColors.primary4,
                        textTransform: "uppercase",
                    }}
                    onClick={() => {
                        stepClick(index, step.enabled);
                    }}
                    id={`${id}-${FormNavigationPrefix}-StepText-${index}`}
                >
                    {getLocalizedString(bundleMap, {
                        bundleId: step.bundleId,
                        stringId: step.stringId,
                    })}
                </span>
                {!isLastStep ? (
                    <Icon
                        style={{ opacity: rootStyles.glass._4 }}
                        icon={IconsList.chevron_caretright}
                        size={20}
                        id={`${id}-${FormNavigationPrefix}-StepIcon-${index}`}
                    />
                ) : null}
            </div>
        );
    };

    const results = stepList.map((step: FormNavigationStep, index: number) =>
        renderStep(step, index)
    );

    const stepClick = (index: number, enabled: boolean) => {
        if (enabled) {
            setCurrentIndex(index);
        }
    };

    return <div style={{ display: "inline-block" }}>{results}</div>;
};

export default FormNavigation;
