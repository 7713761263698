import { createAction } from "redux-ts";
import { ModalRenderFunction, ToastViewData } from "../../models";

/**
 * Clients call with this action to request their modal be displayed.
 *
 * It's not guaranteed to be displayed as others may be ahead of it. If
 * another modal is currently being displayed / about to be displayed,
 * it is added to the queue and will display after all other modals have
 * displayed / dismissed.
 */
export const requestModalDisplay = createAction<ModalRenderFunction>(
    "GLOBAL_NOTIFICATIONS::REQUEST_MODAL_DISPLAY"
);

/**
 * Clears the current active modal. If another modal is waiting in the
 * queue, display it.
 */
export const closeActiveModal = createAction(
    "GLOBAL_NOTIFICATIONS::CLOSE_ACTIVE_MODAL"
);

/**
 * Clients call with this action to request their toast be displayed.
 *
 * It's not guaranteed to be displayed as others may be ahead of it. If
 * another toast is currently being displayed / about to be displayed,
 * it is added to the queue and will display after all other toasts have
 * displayed / dismissed.
 */
export const requestToastView = createAction<ToastViewData | undefined>(
    "GLOBAL_NOTIFICATIONS::REQUEST_TOAST_VIEW"
);

/**
 * Clears the current active toast. If another toast is waiting in the
 * queue, display it.
 */
export const closeActiveToast = createAction(
    "GLOBAL_NOTIFICATIONS::CLOSE_ACTIVE_TOAST"
);
