import * as React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Image } from "react-bootstrap";
import styled from "styled-components";
import { stringIds, bundleIds } from "../../../assets";
import * as rootStyles from "../../styles";
import LanguageSelectionScreen from "./LanguageSelectionScreen";
import { LargeSolidButton, RadioList } from "../../components";
import { getLocalizedString } from "../../../utils";
import WelcomeScreen from "./WelcomeScreen";
import { goToSignInURl } from "../../../service/common";
import { RootState, commonSelectors } from "../../../store";
import { BundleMap } from "../../../models";

const Logo = require("../../../assets/images/am4a_h_wht.png");

const LandingScreenPrefix = "LandingScreen";

enum Region {
    Global = 0,
    Japan = 1,
}

export const LandingScreen: React.FC<{}> = ({}) => {
    const [selectedRegionIndex, setSelectedRegionIndex] =
        React.useState<number>(0);

    const userLocale: string = useSelector(
        (state: RootState) => state.user.locale
    );

    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const getRadioItems = () => {
        return [
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.LOGINSPLASH_STRINGS,
                    stringId: stringIds.LoginSplash.regionGlobal,
                }),
            },
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.LOGINSPLASH_STRINGS,
                    stringId: stringIds.LoginSplash.regionJapan,
                }),
            },
        ];
    };

    return (
        <>
            <LanguageHeaderContainer>
                <LanguageSelectionScreen />
            </LanguageHeaderContainer>
            <StyledContainer>
                <RegionSelectionCol>
                    <RegionSelectionContainer
                        id={LandingScreenPrefix + "Container.Sign-in"}
                    >
                        <Row style={{ justifyContent: "center" }}>
                            <Image src={Logo} style={LogoStyle} />
                        </Row>
                        <Row
                            style={{
                                marginTop: rootStyles.spacers.medium,
                            }}
                        >
                            <Col>
                                <div
                                    style={{
                                        ...rootStyles.textStyles.primary,
                                        textAlign: "center",
                                    }}
                                >
                                    {getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.WELCOME_STRINGS,
                                        stringId: stringIds.Welcome.description,
                                    })}
                                </div>
                            </Col>
                        </Row>
                        <div id={LandingScreenPrefix + "-SignInElements"}>
                            <Row
                                style={{
                                    marginTop: rootStyles.spacers.large,
                                }}
                            >
                                <Col>
                                    <RadioList
                                        elements={getRadioItems()}
                                        initialSelectedIndex={
                                            selectedRegionIndex
                                        }
                                        onChangeElement={(index) =>
                                            setSelectedRegionIndex(index)
                                        }
                                        id={"SignInRegion"}
                                        horizontal={true}
                                        centeredElements={true}
                                    />
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginTop: rootStyles.spacers.large,
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center",
                                        padding: 0,
                                    }}
                                >
                                    <LargeSolidButton
                                        title={getLocalizedString(bundleMap, {
                                            bundleId: bundleIds.WELCOME_STRINGS,
                                            stringId:
                                                stringIds.Welcome.getStated,
                                        })}
                                        containerStyle={{ width: "100%" }}
                                        onClick={() =>
                                            goToSignInURl(
                                                selectedRegionIndex ===
                                                    Region.Japan,
                                                userLocale
                                            )
                                        }
                                        id={`${LandingScreenPrefix}_GetStarted`}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </RegionSelectionContainer>
                </RegionSelectionCol>

                <WelcomeCol>
                    <WelcomeScreen />
                </WelcomeCol>
            </StyledContainer>
        </>
    );
};

const LanguageHeaderContainer = styled.div`
    width: 100%;
    height: 60px;
    position: relative;
    z-index: 500;
    background-color: ${rootStyles.glassColors.secondary5};
`;

const StyledContainer = styled.div`
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    @media (min-width: ${rootStyles.breakpoints.xl}px) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const RegionSelectionCol = styled.div`
    background: ${rootStyles.colors.background};
    padding: 0;
    display: flex;
    @media (min-width: ${rootStyles.breakpoints.xl}px) {
        margin-top: 266px;
`;

const RegionSelectionContainer = styled.div`
    padding: 5vh;
    margin: auto;
    max-width: 400px;
    align-items: center;
`;

const WelcomeCol = styled.div`
    background-image: linear-gradient(
        to bottom,
        ${rootStyles.colors.aux2} 0%,
        ${rootStyles.colors.background} 120%
    );
    padding: 0;
    flex-grow: 1;
    @media (min-width: ${rootStyles.breakpoints.xl}px) {
        flex-grow: 2;
    }
`;

const LogoStyle: React.CSSProperties = {
    flex: 1,
    height: "100%",
    width: 50,
    objectFit: "contain",
};

export default LandingScreen;
