import { Action } from "redux-ts";
import {
    CustomerSupportData,
    IntercomEvent,
    OAuth3PStateTokens,
    OAuthPartners,
    SocialAccountData,
    SocialAccountPartners,
    StateTokensInProgressStatus,
    initialOAuth3PStateTokens,
    initialSocialAccountData,
    initialStateTokensInProgress,
} from "../../models";
import { oAuthActions } from "../actions";
import _ from "lodash";

export type oAuthState = Readonly<{
    customerSupport?: CustomerSupportData;
    intercomEvent?: IntercomEvent;
    showIntercomMessenger: boolean;
    hideIntercomLauncher: boolean;
    oAuth3PStateTokens: OAuth3PStateTokens;
    stateTokensInProgress: StateTokensInProgressStatus;
    socialAccountData: SocialAccountData;
}>;

export const initialOAuthState: oAuthState = {
    showIntercomMessenger: false,
    hideIntercomLauncher: true,
    oAuth3PStateTokens: initialOAuth3PStateTokens,
    stateTokensInProgress: initialStateTokensInProgress,
    socialAccountData: initialSocialAccountData,
};

export const oAuthReducer = (
    state: oAuthState = initialOAuthState,
    action: Action
) => {
    switch (action.type) {
        case oAuthActions.getCustomerSupportDataCompleted.type:
            return {
                ...state,
                customerSupport: action.payload,
            };
        case oAuthActions.setIntercomEvent.type:
            return {
                ...state,
                intercomEvent: action.payload,
            };
        case oAuthActions.setShowIntercomMessenger.type:
            return {
                ...state,
                showIntercomMessenger: action.payload,
            };
        case oAuthActions.setHideIntercomLauncher.type:
            return {
                ...state,
                hideIntercomLauncher: action.payload,
            };
        case oAuthActions.getStateTokenCompleted.type:
            const updatedStateTokens: OAuth3PStateTokens = _.cloneDeep(
                state.oAuth3PStateTokens
            );
            const partner: OAuthPartners = action.payload.partner;
            updatedStateTokens[partner] = action.payload.stateToken;
            return {
                ...state,
                oAuth3PStateTokens: updatedStateTokens,
            };
        case oAuthActions.setStateTokenInProgress.type:
            const updatedStateTokensInProgress: StateTokensInProgressStatus =
                _.cloneDeep(state.stateTokensInProgress);
            const partnerToUpdate: OAuthPartners = action.payload.partner;
            updatedStateTokensInProgress[partnerToUpdate] =
                action.payload.status;
            return {
                ...state,
                stateTokensInProgress: updatedStateTokensInProgress,
            };
        case oAuthActions.getSocialAccountDataCompleted.type:
            const updatedSocialAccountData: SocialAccountData = _.cloneDeep(
                state.socialAccountData
            );
            const socialPartner: SocialAccountPartners = action.payload.partner;
            updatedSocialAccountData[socialPartner] = action.payload.userName;
            return {
                ...state,
                socialAccountData: updatedSocialAccountData,
            };
        default:
            return state;
    }
};
