import axios from "axios";
import { reportingResult, reportingRequestPayload } from "../../models";
import { getEndpoints, testHeaders } from "../common";

const reportingUrl = (baseUrl: string) => `${baseUrl}/report/-`;
const baseUrls = getEndpoints();

export async function getReports(
    request: reportingRequestPayload
): Promise<reportingResult> {
    const result = await axios.post(
        reportingUrl(baseUrls.reporting),
        request.query,
        {
            headers: {
                "x-team-id": request.teamId,
                ...testHeaders(),
            },
            withCredentials: true,
        }
    );
    return result.data;
}
