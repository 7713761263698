import { useEffect } from "react";
import * as ReactDOM from "react-dom";

type PortalProps = {
    children: JSX.Element;
};

export const Portal: React.FC<PortalProps> = ({ children }) => {
    //get div located in rootNavigationContainer
    const portalRoot = document.getElementById("portal-root");
    //create div that will contain child prop
    const portalContainer = document.createElement("div");

    useEffect(() => {
        portalRoot?.appendChild(portalContainer);
        return () => {
            portalRoot?.removeChild(portalContainer);
        };
    }, [portalContainer, portalRoot]);

    return ReactDOM.createPortal(children, portalContainer);
};

export default Portal;
