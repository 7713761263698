import * as React from "react";
import { useEffect, useRef } from "react";
import { Col, Spinner } from "react-bootstrap";
import styled from "styled-components";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import * as rootStyles from "../../styles";
import { IconsList, Search } from "../common";
import { BundleMap, EntityType, hydratedMerchItem } from "../../../models";
import { generateListItemFromMerchItem } from "./merchHelpers";
import { MerchListItemTable } from "./MerchListItemTable";
import { MODBanner } from "./MODBanner";
import { FOOTER_HEIGHT } from "../../containers/merch/ManualSearchScreen";
import { buttonIds, pageIds, stringIds, bundleIds } from "@amzn/ziggy-asset";
import { buildUIClickPayload, getLocalizedString, paths } from "../../../utils";
import { MerchListItemProps } from "./MerchListItem";
import { clientMetricsActions } from "../../../store/actions";
import {
    commonSelectors,
    getActiveModalRenderFunction,
    hasMODPermission,
    showModSelector,
} from "../../../store/selectors";

const testIDPrefix = "merchSearchWithResults";

type Props = {
    showDropdown: boolean;
    inProgress: boolean;
    searchText: string;
    alreadySelectedItemsAsins: string[];
    searchResultItems: hydratedMerchItem[];
    onItemSelectCallback: (set: hydratedMerchItem[], remove: string[]) => void;
    onTextChange: (text: string) => void;
    onTextInputFocus: (inFocus: boolean) => void;
    disabledAsins: string[];
};

export const MerchSearchWithSelect: React.FC<Props> = ({
    showDropdown,
    inProgress,
    searchText,
    alreadySelectedItemsAsins,
    searchResultItems,
    onItemSelectCallback,
    onTextChange,
    onTextInputFocus,
    disabledAsins,
}) => {
    const containerRef = useRef<HTMLInputElement>(null);
    const hasMODAccess = useSelector(hasMODPermission);

    const showMod = useSelector(showModSelector);
    const modalID = useSelector(getActiveModalRenderFunction);
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const dispatch = useDispatch();

    const onClickOutside = (event: any) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target) &&
            !modalID &&
            event.target?.classList["value"] !== "dropdown-item"
        ) {
            onTextInputFocus(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", onClickOutside);
        return () => {
            document.removeEventListener("mousedown", onClickOutside);
        };
    }, [modalID]);

    const updateText = (textObject: any) => {
        const text = textObject.target.value ? textObject.target.value : "";
        onTextChange(text);
    };

    const selectableItems = searchResultItems.filter(
        (item) => !_.includes(disabledAsins, item.itemAsin)
    );
    const selectableAsins = _.map(selectableItems, (item) => item.itemAsin);

    const isItemSelected = (item: hydratedMerchItem) => {
        return alreadySelectedItemsAsins.includes(item.itemAsin);
    };

    const onItemSelect = (item: hydratedMerchItem) => {
        if (alreadySelectedItemsAsins.includes(item.itemAsin)) {
            dispatch(
                clientMetricsActions.sendClientMetrics({
                    ...buildUIClickPayload(
                        buttonIds.Merch.deselectMerchItem,
                        pageIds.artistMerchManualSearchScreen,
                        item.itemAsin,
                        EntityType.Merch
                    ),
                    searchQuery: searchText,
                })
            );
            //deselect item
            onItemSelectCallback([], [item.itemAsin]);
        } else {
            dispatch(
                clientMetricsActions.sendClientMetrics({
                    ...buildUIClickPayload(
                        buttonIds.Merch.selectMerchItem,
                        pageIds.artistMerchManualSearchScreen,
                        item.itemAsin,
                        EntityType.Merch
                    ),
                    searchQuery: searchText,
                })
            );
            //select item
            onItemSelectCallback([item], []);
        }
    };

    const generateMerchRows = () => {
        return searchResultItems.map((item: hydratedMerchItem) => {
            if (_.includes(disabledAsins, item.itemAsin)) {
                const curatedRow: MerchListItemProps =
                    generateListItemFromMerchItem({
                        result: item,
                        id: `${testIDPrefix}-${item.itemAsin}`,
                        //false as we don't want users to interact with disabled items in search results
                        showCuratedItemFeatures: false,
                        disabled: true,
                        requestPath: paths.manualSearchMerch,
                        pageId: pageIds.artistMerchManualSearchScreen,
                    });
                return {
                    ...curatedRow,
                    secondaryText: getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .ProductAlreadyAddedModalTitle,
                    }),
                };
            } else {
                const nonCuratedRow: MerchListItemProps =
                    generateListItemFromMerchItem({
                        result: item,
                        id: `${testIDPrefix}-${item.itemAsin}`,
                        showCuratedItemFeatures: false,
                        selected: isItemSelected(item),
                        onSelect: () => onItemSelect(item),
                        requestPath: paths.manualSearchMerch,
                        pageId: pageIds.artistMerchManualSearchScreen,
                    });
                return nonCuratedRow;
            }
        });
    };

    const numResults = searchResultItems.length;
    const searchResults = numResults ? (
        <MerchListItemTable
            rows={generateMerchRows()}
            tableHeader={
                numResults === 1
                    ? getLocalizedString(bundleMap, {
                          bundleId: bundleIds.MERCHCURATION_STRINGS,
                          stringId:
                              stringIds.Merch.Curation.ProductsFoundSingular,
                      })
                    : getLocalizedString(
                          bundleMap,
                          {
                              bundleId: bundleIds.MERCHCURATION_STRINGS,
                              stringId:
                                  stringIds.Merch.Curation.ProductsFoundPlural,
                          },
                          { "0": numResults.toString() }
                      )
            }
        />
    ) : (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <NoResultsTitleContainer>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.NoProductsFound,
                })}
            </NoResultsTitleContainer>
            {showMod && <MODBanner hasMerchIngressPermissions={hasMODAccess} />}
        </div>
    );

    return (
        <div ref={containerRef}>
            <Search
                //styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101
                value={searchText}
                onChange={updateText}
                textInputStyle={{
                    ...rootStyles.textStyles.secondary,
                    color: rootStyles.glassColors.primary4,
                }}
                dark={true}
                placeholder={getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.SearchBy,
                })}
                onFocus={() => onTextInputFocus(true)}
                containerStyle={{
                    borderColor: rootStyles.glassColors.primary4,
                }}
                searchIcon={IconsList.merch_search}
                inputPlaceholderColor={rootStyles.glassColors.primary4}
                id={`${testIDPrefix}_Search`}
            />
            {showDropdown && (
                <SearchResultsContainer
                    //search results need a flex display inorder to utilize dynamic rendering from virtualizer.
                    //flex display in the no search results view causes MOD banner to shrink and hide content.
                    style={{
                        display: numResults ? "flex" : "initial",
                    }}
                >
                    {!inProgress ? (
                        searchResults
                    ) : (
                        <Col
                            style={{
                                textAlign: "center",
                                marginTop: rootStyles.spacers.large,
                                marginBottom: rootStyles.spacers.large,
                            }}
                        >
                            <Spinner animation="border" variant="light" />
                        </Col>
                    )}
                </SearchResultsContainer>
            )}
        </div>
    );
};

// If adding more elements to this page be careful about this styling bloating up
const SEARCH_BAR_HEIGHT = 50;
const SEARCH_RESULTS_TOP_MARGIN = rootStyles.spacers.small;
const WEB_HEADER_HEIGHT = 96;
const MOBILE_HEADER_HEIGHT = 144;
const SEARCH_AMAZON_COM_STRING_HEIGHT = 36;

//height value calculated by taking height of root container
//and subtracting height values from other elements in container
//width calculated by taking width of searchResults and subtracting
//margins on search bar (because element is absolutely positioned to
//root container)
const SearchResultsContainer = styled(Col)`
    flex: 1;
    position: absolute;
    background-color: ${rootStyles.colors.secondary};
    border-radius: 8px;
    z-index: 12;
    padding: 0;
    overflow-y: auto;
    margin-top: ${SEARCH_RESULTS_TOP_MARGIN}px;
    max-height: calc(
        100% - ${SEARCH_BAR_HEIGHT}px - ${SEARCH_RESULTS_TOP_MARGIN}px -
            ${FOOTER_HEIGHT}px - ${SEARCH_AMAZON_COM_STRING_HEIGHT}px -
            ${WEB_HEADER_HEIGHT}px
    );
    width: calc(100% - ${rootStyles.spacers.base * 2}px);
    @media (max-width: 992px) {
        max-height: calc(
            100% - ${SEARCH_BAR_HEIGHT}px - ${SEARCH_RESULTS_TOP_MARGIN}px -
                ${FOOTER_HEIGHT}px - ${SEARCH_AMAZON_COM_STRING_HEIGHT}px -
                ${MOBILE_HEADER_HEIGHT}px
        );
    }
`;

const NoResultsTitleContainer = styled.span`
    ${{ ...rootStyles.textStyles.primary }}
    padding-top: ${rootStyles.spacers.epic}px;
    padding-bottom: ${rootStyles.spacers.giant}px;
    text-align: center;
    width: 100%;
    @media (max-width: 992px) {
        padding-top: ${rootStyles.spacers.large}px;
        padding-bottom: ${rootStyles.spacers.small}px;
    }
`;
