import { createAction } from "redux-ts";
import {
    teamInfo,
    artist,
    settings,
    updateSettingsPayload,
    selectArtistPayload,
    updateRequesterPayload,
    optOutMarketingEmailsPayload,
    oAuthClientIds,
    userData,
    cookieModalText,
    CookieConsentString,
    deeplinkConfig,
} from "../../models";

export const updateUserState = createAction<boolean>("AUTH::USER_UPDATE_STATE");
export const updateSettings = createAction<updateSettingsPayload>(
    "AUTH::UPDATE_SETTINGS"
);
export const updateSettingsInProgress = createAction<boolean>(
    "AUTH::UPDATE_SETTINGS_IN_PROGRESS"
);
export const updateSettingsCompleted = createAction<settings>(
    "AUTH::UPDATE_SETTINGS_COMPLETED"
);

export const updateOAuthClientIdsCompleted = createAction<oAuthClientIds>(
    "AUTH::OAUTH_CLIENT_IDS_COMPLETED"
);

export const registerUser = createAction("AUTH::REGISTER_USER");

export const optOutMarketingEmails = createAction<optOutMarketingEmailsPayload>(
    "AUTH::OPT_OUT_MARKETING_EMAILS"
);
export const optOutInProgress = createAction<boolean>(
    "AUTH::OPT_OUT_IN_PROGRESS"
);
export const optOutCompleted = createAction<boolean>("AUTH:OPT_OUT_COMPLETED");

export const loginCompleted = createAction<teamInfo[]>(
    "AUTH::USER_LOGIN_COMPLETED"
);
export const loginInProgress = createAction<boolean>(
    "AUTH::USER_LOGIN_IN_PROGRESS"
);

export const refreshInProgress = createAction<boolean>(
    "AUTH::USER_REFRESH_IN_PROGRESS"
);

export const logOut = createAction("AUTH::USER_LOGOUT");
export const logOutCompleted = createAction("AUTH::USER_LOGOUT_COMPLETED");

export const selectArtist = createAction<selectArtistPayload>(
    "AUTH::ARTIST_SELECTED"
);
export const selectArtistCompleted = createAction<selectArtistPayload>(
    "AUTH::ARTIST_SELECTED_COMPLETED"
);

export const shouldRefreshToken = createAction<boolean>(
    "AUTH::USER_SHOULD_REFRESH_TOKEN"
);
export const refreshTokenCompleted = createAction<string>(
    "AUTH::REFRESH_TOKEN_COMPLETED"
);

export const updateArtistRecentlyViewed = createAction<artist>(
    "AUTH::UPDATE_ARTIST_RECENTLY_VIEWED"
);
export const clearArtistRecentlyViewed = createAction(
    "AUTH::CLEAR_ARTIST_RECENTLY_VIEWED"
);

export const updateRequesterInUser = createAction<updateRequesterPayload>(
    "TEAM_MANAGEMENT::UPDATE_REQUESTER"
);

export const updateCurrentPath = createAction<string>(
    "AUTH:UPDATE_CURRENT_PATH"
);

export const updatePrevPath = createAction<string>("AUTH:UPDATE_PREV_PATH");

export const updateUserLocale = createAction<string>(
    "AUTH::UPDATE_USER_LOCALE"
);
export const updateUserLocaleCompleted = createAction<string>(
    "AUTH::UPDATE_USER_LOCALE_COMPLETED"
);
export const updateUserRedirectedToReports = createAction<boolean>(
    "AUTH::UPDATE_REDIRECTED_USER_TO_REPORTS"
);

export const storeCookieConsentSetting = createAction<string>(
    "AUTH::STORE_COOKIE_CONSENT_SETTING"
);

export const storeCookieModalText = createAction<cookieModalText>(
    "AUTH::STORE_COOKIE_MODAL_TEXT"
);

export const updateCookieConsentSetting = createAction<CookieConsentString>(
    "AUTH::UPDATE_COOKIE_CONSENT_SETTING"
);

export const updateUserData = createAction<userData>("AUTH::UPDATE_USER_DATA");

export const isJapanUser = createAction<boolean>("AUTH::IS_JAPAN_USER");

export const setCsrfToken = createAction<string>("AUTH:SET_CSRF_TOKEN");

export const updateDeeplinkRoute = createAction<string>(
    "DEEPLINK::UPDATE_IS_DEEPLINK"
);

export const updateDeeplinkMapConfig = createAction<deeplinkConfig>(
    "DEEPLINK::UPDATE_DEEPLINK_PATH"
);

export const resetDeeplink = createAction("DEEPLINK:RESET_DEEPLINK");

export const updateIsDeeplinkArtistSearchInProgress = createAction<boolean>(
    "DEEPLINK::IS_SEARCH_IN_PROGRESS"
);

export const clearDeeplinkSelectedArtist = createAction("DEEPLINK::CLEAR_ARTIST")
