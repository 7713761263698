import React, { Dispatch, useCallback, useState } from "react";
import {
    LargeSolidButton,
    RadioList,
    styledTitle,
} from "../../../view/components";
import * as rootStyles from "../../styles";
import { RootState, promoCardActions } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { BundleMap, EntityType } from "../../../models";
import {
    PromoCardStep,
    buildUIClickPayload,
    getLocalizedString,
} from "../../../utils";
import { bundleIds, stringIds } from "../../../assets";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { sendClientMetrics } from "../../../store/actions/clientMetricsActions";

const testIDPrefix = "SearchCardContent";

type SearchCardContentProps = {
    trackSearchBar: JSX.Element;
    albumSearchBar: JSX.Element;
    playlistSearchBar: JSX.Element;
    bundleMap: BundleMap;
};

const entityList: EntityType[] = [
    EntityType.ARTIST,
    EntityType.Playlist,
    EntityType.Track,
    EntityType.Album,
];

export const SearchCardContent: React.FC<SearchCardContentProps> = ({
    trackSearchBar,
    albumSearchBar,
    playlistSearchBar,
    bundleMap,
}) => {
    const dispatch: Dispatch<AnyAction> = useDispatch();

    const entityRadioElements = () => {
        return [
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.typeArtist,
                }),
            },
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.typePlaylist,
                }),
            },
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.typeSong,
                }),
            },
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.typeAlbum,
                }),
            },
        ];
    };

    const setStepIndex = useCallback(
        (step: number) => {
            dispatch(promoCardActions.selectStep(step));
        },
        [dispatch]
    );

    const selectedEntity = useSelector(
        (state: RootState) => state.promoCard.selectedEntity
    );

    const selectedArtistAsin = useSelector(
        (state: RootState) => state.user.selectedArtistAsin
    );

    const setCardEntity = useCallback(
        (entity: EntityType) => {
            dispatch(promoCardActions.selectEntity(entity));
        },
        [dispatch]
    );

    const onSelectEntity = (index: number) => {
        const entity = entityList[index];
        if (entity) {
            dispatch(
                sendClientMetrics(
                    buildUIClickPayload(
                        buttonIds.HypeCard.selectContent,
                        pageIds.promoCard,
                        selectedArtistAsin,
                        EntityType.ARTIST
                    )
                )
            );
            setCardEntity(entity);
        }
    };

    const onSelectContinue = () => {
        setStepIndex(PromoCardStep.CUSTOMIZE);
    };

    return (
        <div
            style={{
                padding: rootStyles.spacers.huge,
                width: "100%",
            }}
        >
            <div>
                <styledTitle.h4>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PROMO_CARD_STRINGS,
                        stringId: stringIds.PromoCard.selectContentTitle,
                    })}
                </styledTitle.h4>
            </div>
            <div>
                <RadioList
                    elements={entityRadioElements()}
                    onChangeElement={(index: number) => onSelectEntity(index)}
                    horizontal={true}
                    centeredElements={true}
                    initialSelectedIndex={entityList.indexOf(selectedEntity)}
                    textNoWrap={true}
                    elementSpacing={rootStyles.spacers.huge}
                    containerStyling={{}}
                    id={`${testIDPrefix}_selectEntity`}
                />
            </div>
            {selectedEntity == EntityType.Playlist ? (
                <div style={{ marginTop: rootStyles.spacers.large }}>
                    {playlistSearchBar}
                </div>
            ) : null}
            {selectedEntity == EntityType.Track ? (
                <div>
                    <div style={{ marginTop: rootStyles.spacers.large }}>
                        {trackSearchBar}
                    </div>
                </div>
            ) : null}
            {selectedEntity == EntityType.Album ? (
                <div>
                    <div style={{ marginTop: rootStyles.spacers.large }}>
                        {albumSearchBar}
                    </div>
                </div>
            ) : null}
            <div style={{ marginTop: rootStyles.spacers.huge }}>
                <LargeSolidButton
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.GENERIC_STRINGS,
                        stringId: stringIds.Generic.continue,
                    })}
                    onClick={onSelectContinue}
                    id={`${testIDPrefix}_Continue`}
                />
            </div>
        </div>
    );
};
