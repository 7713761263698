import _ from "lodash";
import {
    BundleMap,
    METRIC_KEYS,
    telemetryPayload,
    trackListenersInfo,
    trackStreamsInfo,
} from "../../../models/";
import { CsvHelper, FilenameInfo, Trendline } from "./../../../export/";

export function createTrendlinesCsvFile({
    startDate,
    endDate,
    requestPath,
    artistName,
    appEventCallback,
    streamsTrendlineData,
    listenersTrendlineData,
    contentName,
    bundleMap,
}: {
    startDate: Date;
    endDate: Date;
    requestPath: string;
    artistName: string;
    appEventCallback: (payload: telemetryPayload) => void;
    streamsTrendlineData: trackStreamsInfo;
    listenersTrendlineData: trackListenersInfo;
    contentName?: string;
    bundleMap: BundleMap;
}) {
    const start = Date.now();
    const streamsMap = new Map<string, number>();
    streamsTrendlineData.dataPointList.forEach((a) => {
        streamsMap.set(a.timestamp, a.value);
    });
    const listenersMap = new Map<string, number>();
    listenersTrendlineData.dataPointList.forEach((a) => {
        listenersMap.set(a.timestamp, a.value);
    });

    if (streamsMap.keys !== listenersMap.keys) {
        appEventCallback({
            name: "TrendlinesTimestampsNotMatching",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, requestPath.toString()],
                [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            ]),
        });
    }

    const csvRowData: Trendline[] = [];
    streamsMap.forEach((val: number, time: string) => {
        const date = new Date(time);
        let numListeners: number;
        if (listenersMap.has(time)) {
            numListeners = listenersMap.get(time) as number; //if else statement guarantees number assignment
        } else {
            numListeners = 0;
            appEventCallback({
                name: "TrendlinesDefaultValueGiven",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, requestPath.toString()],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            });
        }
        csvRowData.push([date, val, numListeners]);
    });

    //define file name info
    const filenameInfo: FilenameInfo = {
        artistName: artistName,
        contentName: contentName,
        startDate: startDate as Date,
        endDate: endDate as Date,
    };

    //download csv
    CsvHelper.downloadCSV("trendlines", csvRowData, filenameInfo, bundleMap);
}
