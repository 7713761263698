import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import React from "react";
import { getLocalizedString } from "../../../../utils";
import {
    BlurredModal,
    Icon,
    IconsList,
    LargeGlassButton,
    styledTitle,
} from "../../common";
import { BundleMap, SocialAccountData } from "../../../../models";
import { Modal } from "react-bootstrap";
import * as rootStyles from "../../../styles";

export type ViewConnectedSocialAccountsModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
    bundleMap: BundleMap;
    instagramUserName: string | undefined;
    twitterUserName: string | undefined;
};

const testIDPrefix = "ViewConnectedSocialAccountsModal";

export class ViewConnectedSocialAccountsModal extends React.Component<ViewConnectedSocialAccountsModalProps> {
    render() {
        return (
            <BlurredModal
                id="blurred-modal"
                show={this.props.isVisible}
                centered
                maxwidth={428}
                top="30%"
            >
                <Modal.Body
                    style={{
                        ...rootStyles.containerStyles.modalBody,
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            style={cancelButtonStyle}
                            onClick={this.props.onDismiss}
                        >
                            <Icon
                                icon={IconsList.action_cancel}
                                size={rootStyles.spacers.huge}
                                color={rootStyles.colors.primary}
                                id={testIDPrefix + "_DismissIcon"}
                            />
                        </div>
                        <styledTitle.h4
                            style={{
                                textTransform: "capitalize",
                            }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                stringId:
                                    stringIds.ProvideYourInformation
                                        .connectedAccountsPageTitle,
                            })}
                        </styledTitle.h4>
                        {this.props.instagramUserName ? (
                            <div>
                                <div style={titleTextStyle}>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                        stringId:
                                            stringIds.ProvideYourInformation
                                                .connectIGAccountTitle,
                                    })}
                                </div>
                                <div style={socialAccountContainerStyle}>
                                    <Icon
                                        icon={IconsList.ic_instagram}
                                        size={rootStyles.spacers.huge}
                                        color={rootStyles.colors.primary}
                                        id={testIDPrefix + "Instagram_Icon"}
                                    />
                                    <span style={socialAccountTextStyle}>
                                        {"@" + this.props.instagramUserName}
                                    </span>
                                </div>
                            </div>
                        ) : null}
                        {this.props.twitterUserName ? (
                            <div>
                                <div style={titleTextStyle}>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                        stringId:
                                            stringIds.ProvideYourInformation
                                                .connectXAccountTitle,
                                    })}
                                </div>
                                <div style={socialAccountContainerStyle}>
                                    <Icon
                                        icon={IconsList.ic_twitter}
                                        size={rootStyles.spacers.huge}
                                        color={rootStyles.colors.primary}
                                        id={testIDPrefix + "X_Icon"}
                                    />
                                    <span style={socialAccountTextStyle}>
                                        {"@" + this.props.twitterUserName}
                                    </span>
                                </div>
                            </div>
                        ) : null}
                        <LargeGlassButton
                            containerStyle={{
                                width: "100%",
                                marginTop: rootStyles.spacers.epic,
                            }}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.dismiss,
                            })}
                            onClick={this.props.onDismiss}
                            id={testIDPrefix + "DismissButton"}
                        />
                    </div>
                </Modal.Body>
            </BlurredModal>
        );
    }
}

const cancelButtonStyle: React.CSSProperties = {
    flex: 0,
    padding: rootStyles.spacers.micro,
    borderRadius: rootStyles.spacers.medium,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
    cursor: "pointer",
};
const titleTextStyle: React.CSSProperties = {
    marginTop: rootStyles.spacers.large,
    ...rootStyles.textStyles.primaryBold,
    textTransform: "uppercase",
};
const socialAccountContainerStyle: React.CSSProperties = {
    marginTop: rootStyles.spacers.base,
    flexDirection: "row",
};
const socialAccountTextStyle: React.CSSProperties = {
    marginTop: rootStyles.spacers.base,
    marginLeft: rootStyles.spacers.base,
    ...rootStyles.textStyles.primary,
};
