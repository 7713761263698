import { Action } from "redux-ts";
import { takeEvery, put } from "redux-saga/effects";
import {
    artistIssueActions,
    errorActions,
    opsMetricsActions,
    telemetryActions,
} from "../actions";
import {
    ErrorPayload,
    METRIC_KEYS,
    newDisambiguationPayload,
    NewDisambiguationResponse,
} from "../../models";
import { newDisambiguation } from "../../service";
import { createSuccessOpsMetricsPayload } from "../../utils";

export const artistIssueSagas = [watchNewDisambiguationRequest()];

function* newDisambiguationRequest(action: Action<newDisambiguationPayload>) {
    const start = Date.now();
    const functionName = "newDisambiguationRequest";
    try {
        yield put(artistIssueActions.newDisambiguationInProgress(true));
        yield put(artistIssueActions.newDisambiguationSuccess(undefined));
        yield put(
            telemetryActions.appEvent({
                name: "newDisambiguationRequest",
                dataPoints: new Map<string, string | undefined>([]),
            })
        );

        const response: NewDisambiguationResponse = yield newDisambiguation(
            action.payload.request,
            action.payload.teamId
        );

        yield put(
            artistIssueActions.newDisambiguationSuccess(
                response.id !== undefined
            )
        );
        yield put(artistIssueActions.newDisambiguationInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        const payload: ErrorPayload = {
            requestPath: action.payload.requestPath,
            exception: ex,
            dataPoints,
            eventName: functionName,
        };

        yield put(errorActions.handleError(payload));
        yield put(artistIssueActions.newDisambiguationInProgress(false));
        yield put(artistIssueActions.newDisambiguationSuccess(false));
    }
}

function* watchNewDisambiguationRequest() {
    yield takeEvery(
        artistIssueActions.newDisambiguation.type,
        newDisambiguationRequest
    );
}
