import * as React from "react";
import { Col, Row, Image } from "react-bootstrap";
import Collapsible from "react-collapsible";
import * as rootStyles from "../../styles";
import { IconsList } from "../../../assets";
import styled from "styled-components";

type Props = {
    cardStyle?: React.CSSProperties;
    title: string;
    titleStyle: React.CSSProperties;
    collapseImage: string;
    testIDPrefix: string;
};

/**
 * Usage Notes: Use this componenet to add a collapsible section to UI
 * FOr example see FAQ in this path: merch/modlanding
 */
export class CollapsibleCard extends React.Component<Props> {
    render() {
        const actionAdd = (
            <Col
                xs={1}
                style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img style={caretStyle} src={IconsList.action_add} />;
            </Col>
        );

        const actionCancel = (
            <Col xs={1}>
                <img style={caretStyle} src={IconsList.action_cancel} />
            </Col>
        );

        const title = (
            <Col
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    textAlign: "left",
                }}
            >
                <div
                    style={{
                        ...this.props.titleStyle,
                    }}
                >
                    {this.props.title}
                </div>
            </Col>
        );

        const headerClosed = (
            <HeaderContainer>
                {title}
                {actionAdd}
            </HeaderContainer>
        );

        const headerOpened = (
            <HeaderContainer>
                {title}
                {actionCancel}
            </HeaderContainer>
        );

        const collapsible = (
            <span
                style={{
                    width: "100%",
                }}
            >
                <Collapsible
                    trigger={headerClosed}
                    triggerWhenOpen={headerOpened}
                >
                    {this.props.children}
                </Collapsible>
            </span>
        );
        return <div style={this.props.cardStyle}>{collapsible}</div>;
    }
}

const caretStyle: React.CSSProperties = {
    height: rootStyles.icons.medium,
    width: rootStyles.icons.medium,

    alignSelf: "right",
};

const HeaderContainer = styled(Row)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`;
