import axios from "axios";
import {
    CustomerSupportSupportedPlatform,
    GetSocialAccountDataRequest,
    GetSocialAccountDataResponse,
    GetStateTokenRequest,
    GetStateTokenResponse,
    getCustomerSupportDataResponse,
} from "../models";
import { getEndpoints } from "./common";

const oAuthUrl = getEndpoints().oAuth;
const customerSupportDataUrl = `${oAuthUrl}/data/customersupport`;
const stateTokenUrl = `${oAuthUrl}/api/statetoken`;
const getSocialAccountDataUrl = `${oAuthUrl}/data/socialaccountdata`;

export async function getCustomerSupportData(): Promise<getCustomerSupportDataResponse> {
    const result = await axios.post(customerSupportDataUrl, {
        devicePlatform: CustomerSupportSupportedPlatform.WEB,
    });

    return result.data;
}

export async function getStateToken(
    request: GetStateTokenRequest
): Promise<GetStateTokenResponse> {
    const result = await axios.post(stateTokenUrl, request);
    return result.data;
}

export async function getSocialAccountData(
    request: GetSocialAccountDataRequest
): Promise<GetSocialAccountDataResponse> {
    const result = await axios.post(getSocialAccountDataUrl, request);
    return result.data;
}
