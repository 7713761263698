import * as React from "react";
import { useSelector } from "react-redux";
import { Media, Image, Row, Col, Container } from "react-bootstrap";
import { getLocalizedString, getArtistHandbookUrl } from "../../../utils";
import { stringIds, ImageList, IconsList, bundleIds } from "../../../assets";
import { SmallGlassButton } from "../../components";
import styled from "styled-components";
import {
    mobileFontSizes,
    breakpoints,
    webFontSizes,
    colors,
    spacers,
} from "../../styles";
import { commonSelectors } from "../../../store/selectors";
import { BundleMap } from "../../../models";

const WelcomeScreen: React.FC<{}> = () => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const header = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.header,
    });
    const subhead = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.subhead,
    });
    const valueProp1 = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.valueProp1,
    });
    const valueProp1Caption = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.valueProp1Caption,
    });
    const valueProp2 = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.valueProp2,
    });
    const valueProp2Caption = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.valueProp2Caption,
    });
    const valueProp3 = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.valueProp3,
    });
    const valueProp3Caption = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.valueProp3Caption,
    });
    const learnMoreRationale = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.actionRationale,
    });
    const learnMore = getLocalizedString(bundleMap, {
        bundleId: bundleIds.LOGINSPLASH_STRINGS,
        stringId: stringIds.LoginSplash.action,
    });

    return (
        <Container>
            <Row style={{ paddingTop: "8vh" }}>
                <Col
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Header>{header}</Header>
                    <SubHeader>{subhead}</SubHeader>
                </Col>
            </Row>

            <Row style={{ paddingTop: "8vh" }}>
                <SplashImage>
                    <Image src={ImageList.loginSplash_devices} fluid={true} />
                </SplashImage>

                <ValueProps>
                    <ValueProp
                        icon={IconsList.ic_graphbars}
                        title={valueProp1}
                        caption={valueProp1Caption}
                    />
                    <ValueProp
                        icon={IconsList.ic_smileyface}
                        title={valueProp2}
                        caption={valueProp2Caption}
                    />
                    <ValueProp
                        icon={IconsList.ic_thermometer}
                        title={valueProp3}
                        caption={valueProp3Caption}
                    />
                </ValueProps>
            </Row>

            <Row
                style={{
                    paddingTop: "4vh",
                    paddingBottom: "8vh",
                    justifyContent: "center",
                }}
            >
                <Col>
                    <LearnMore
                        rationale={learnMoreRationale}
                        action={learnMore}
                    />
                </Col>
            </Row>
        </Container>
    );
};

const ValueProp: React.FC<{ icon: string; title: string; caption: string }> = ({
    icon,
    title,
    caption,
}) => {
    return (
        <StyledMedia>
            <ActionIcon>
                <Image src={icon} style={{ width: spacers.giant }} />
            </ActionIcon>
            <Media.Body>
                <ValuePropTitle>{title}</ValuePropTitle>
                <Caption>{caption}</Caption>
            </Media.Body>
        </StyledMedia>
    );
};

const LearnMore: React.FC<{ rationale: string; action: string }> = ({
    rationale,
    action,
}) => {
    const goToLearnMoreUrl = () => {
        window.location.href = getArtistHandbookUrl();
    };

    return (
        <Action>
            <Rationale>{rationale}</Rationale>
            <SmallGlassButton
                containerStyle={{ flex: "0 1 auto" }}
                title={action}
                onClick={goToLearnMoreUrl}
                id={"WelcomeScreen_LearnMore"}
            />
        </Action>
    );
};

const Header = styled.header`
    font-family: AmazonEmberDisplayCdApp-Heavy;
    font-size: ${mobileFontSizes.h2}px;
    font-weight: 400;
    text-transform: uppercase;
    color: ${colors.primary};
    text-align: center;
    line-height: 1.25;
    @media (min-width: ${breakpoints.lg}px) {
        font-size: ${webFontSizes.h2}px;
    }
`;

const SubHeader = styled.header`
    font-family: AmazonEmberMono-Bold;
    font-size: ${mobileFontSizes.h4}px;
    color: ${colors.primary};
    text-align: center;
    max-width: 100%;
    @media (min-width: ${breakpoints.lg}px) {
        font-size: ${webFontSizes.h4}px;
        max-width: 75%;
    }
`;

const ValuePropTitle = styled.header`
    font-family: AmazonEmberDisplayCdApp-Heavy;
    font-size: ${mobileFontSizes.h2}px;
    color: ${colors.primary};
    text-align: center;
    text-transform: uppercase;
    line-height: 1.25;
    @media (min-width: ${breakpoints.xl2}px) {
        font-size: ${webFontSizes.h3}px;
        text-align: left;
    }
`;

const Caption = styled.p`
    font-family: AmazonEmber;
    color: ${colors.primary};
    text-align: center;
    @media (min-width: ${breakpoints.xl2}px) {
        text-align: left;
    }
`;

const WavyPatternTop = styled(Image)`
    position: absolute;
    width: 100%;
    top: -5%;
    right: 0;
    @media (min-width: ${breakpoints.xl}px) {
        top: -25%;
        right: -20%;
        width: 75%;
        transform: rotateX(45deg);
    }
`;

const WavyPatternBottom = styled(Image)`
    position: absolute;
    width: 100%;
    bottom: -5%;
    left: 0;
    @media (min-width: ${breakpoints.xl}px) {
        bottom: -30%;
        left: -20%;
        width: 75%;
        transform: scaleY(-1) rotateX(45deg);
    }
`;

const StyledMedia = styled(Media)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    @media (min-width: ${breakpoints.xl2}px) {
        flex-direction: row;
        align-items: stretch;
        padding-bottom: 12px;
    }
`;

const ActionIcon = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media (min-width: ${breakpoints.xl2}px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const Rationale = styled.span`
    font-family: AmazonEmber;
    color: ${colors.primary};
    margin-bottom: 12px;
    @media (min-width: ${breakpoints.xl2}px) {
        margin-right: 12px;
        margin-bottom: 0px;
    }
`;

const Action = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @media (min-width: ${breakpoints.xl2}px) {
        flex-direction: row;
        justify-content: flex-end;
        text-align: right;
    }
`;

const SplashImage = styled.div`
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex: 0 0 auto;
    max-width: 100%;
    @media (min-width: ${breakpoints.xl2}px) {
        flex-direction: column;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
`;

const ValueProps = styled.div`
    padding: 0;
    flex-direction: row;
    flex: 0 0 auto;
    max-width: 100%;
    @media (min-width: ${breakpoints.xl2}px) {
        flex-direction: column;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
`;

export default WelcomeScreen;
