import styled from "styled-components";
import { BasePlacement } from "@popperjs/core";
import { Tooltip } from "react-bootstrap";
import { colors } from "./colors";

const tooltipBackground = "#042026";

export type tooltipPlacement = "top" | "bottom" | "right" | "left";

export const StyledTooltip = styled(Tooltip)<{
    placement: tooltipPlacement;
}>`
    z-index: 999;

    .tooltip-inner {
        position: relative;
        text-align: center;
        background-color: ${tooltipBackground};
        border-width: 1px;
        border-style: solid;
        border-color: ${colors.accent};
        border-radius: 8px;
        max-width: 280px;
    }

    .arrow::before {
        ${(props) =>
            props.placement === "left" &&
            `border-left-color: ${colors.accent};`}
        ${(props) =>
            props.placement === "right" &&
            `border-right-color: ${colors.accent};`}
        ${(props) =>
            props.placement === "top" && `border-top-color: ${colors.accent};`}
        ${(props) =>
            props.placement === "bottom" &&
            `border-bottom-color: ${colors.accent};`}
    }
`;

/*
    This is the original styling for the tooltip in csv export. This created a custom 
    tip according to the original UX design. This design was put on hold for the time 
    being as the tip is centered in the middle of whichever side it is on; it does not 
    dynamically move left/right/up/down when the tooltip is not centered around its 
    originating button. 
    Backlog sim: https://issues.amazon.com/issues/9c272a00-716b-4bcb-aa0a-72b37d0888bd

*/
// z-index: 999;

// .tooltip-inner {
//     position: relative;
//     text-align: center;
//     background-color: ${tooltipBackground};
//     border-width: 1px;
//     border-style: solid;
//     border-color: ${colors.accent};
//     border-radius: 8px;
//     max-width: 280px;
// }
// .arrow {
//     display: none !important;
// }
//
// .tooltip-inner::before,
// .tooltip-inner::after {
//     content: "";
//     display: block;
//     position: absolute;
//     ${(props) => props.placement === "left" && "left: 100%;"}
//     ${(props) => props.placement === "right" && "right: 100%;"}
//     ${(props) => props.placement === "top" && "top: 100%;"}
//     ${(props) => props.placement === "bottom" && "bottom: 100%;"}
//     width: 0;
//     height: 0;
//     border-style: solid;
// }

// .tooltip-inner::after {
//     ${(props) =>
//         (props.placement === "left" || props.placement === "right") &&
//         "top: calc(50% - 12px);"}
//     ${(props) =>
//         (props.placement === "top" || props.placement === "bottom") &&
//         "left: calc(50% - 12px);"}
//     border-color: ${(props) =>
//         props.placement === "top" ? tooltipBackground : "transparent"} ${(
//         props
//     ) =>
//         props.placement === "right" ? tooltipBackground : "transparent"} ${(
//         props
//     ) =>
//         props.placement === "bottom"
//             ? tooltipBackground
//             : "transparent"} ${(props) =>
//         props.placement === "left" ? tooltipBackground : "transparent"};
//     border-width: 12px;
// }

// .tooltip-inner::before {
//     ${(props) =>
//         (props.placement === "left" || props.placement === "right") &&
//         "top: calc(50% - 13px);"}
//     ${(props) =>
//         (props.placement === "top" || props.placement === "bottom") &&
//         "left: calc(50% - 13px);"}
//     border-color: ${(props) =>
//         props.placement === "top" ? colors.accent : "transparent"} ${(
//         props
//     ) => (props.placement === "right" ? colors.accent : "transparent")} ${(
//         props
//     ) => (props.placement === "bottom" ? colors.accent : "transparent")} ${(
//         props
//     ) => (props.placement === "left" ? colors.accent : "transparent")};
//     border-width: 13px;
// }
