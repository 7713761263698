import * as React from "react";
import { useLocalizationContext } from "@amzn/react-arb-tools";
import { localizationContextBuilder } from "../../../utils";
import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { usePrevious } from "../hooks";
import { localizationActions } from "../../../store";
import { bundleIds } from "../../../assets";

export const SwitchLocaleHandler: React.FC<{}> = () => {
    const { localizationContext, setLocalizationContext } =
        useLocalizationContext();

    const locale: string = useSelector((state: RootState) => state.user.locale);
    const previousLocale = usePrevious(locale);

    const updateLocale = (userSelectedLocale: string) => {
        if (localizationContext.getLocale() !== userSelectedLocale) {
            setLocalizationContext(
                localizationContextBuilder
                    .withLocale(userSelectedLocale.replace("_", "-"))
                    .build()
            );
        }
    };

    if (locale != previousLocale) {
        updateLocale(locale);
    }

    // Load bundles at once
    const dispatch = useDispatch();
    Object.values(bundleIds).forEach(function (bundleId) {
        dispatch(localizationActions.updateBundle({ bundleId }));
    });
    return null;
};
