import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./view/styles/global.css";
import "./assets/fonts/Amazon_Ember_Bd.ttf";
import "./assets/fonts/Amazon_Ember_Rg.ttf";
import "./assets/fonts/AmazonEmberDisplayCd.ttf";
import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));
