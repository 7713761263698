import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Col, Row, Container, Image } from "react-bootstrap";
import _ from "lodash";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import {
    MerchListItemTable,
    MerchSearchHeader,
    MerchSearchWithSelect,
    generateListItemFromMerchItem,
    LeavePageModal,
    ArtistGradientBackgroundImage,
    limitReachedModalRenderFunction,
    MediumSolidButton,
    LargeSolidButton,
} from "../../components";
import {
    buildUIClickPayload,
    getLocalizedString,
    LARGE_SCREEN_WIDTH_THRESHOLD,
    MOBILE_SCREEN_MAX_WIDTH,
    paths,
    US_MARKETPLACE,
} from "../../../utils";
import * as rootStyles from "../../styles";
import { merchImages, stringIds, bundleIds } from "../../../assets";
import {
    artist,
    merchDeviceFamily,
    hydratedMerchItem,
    searchMerchPayload,
    curateMerchPayload,
    merchUserContext,
    merchCollection,
    MAX_ITEMS_IN_COLLECTION,
    searchMerchCollectionsPayload,
    clientMetricsPayload,
    EntityType,
    ModalRenderFunction,
    BundleMap,
} from "../../../models";
import {
    clientMetricsActions,
    globalNotificationsActions,
    merchCurateActions,
} from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { manualSearchScreenSelector } from "../../../store/selectors";

const testIDPrefix = "MerchManualSearchScreen";
const pagePath = paths.manualSearchMerch;

export const FOOTER_HEIGHT = 90;
const QUERY_DELAY_TIME = 1000;
const MINIMUM_SEARCH_TEXT_LENGTH = 2;

const SEARCH_RESULT_SIZE = 25;

type ViewProps = {};

type StateProps = {
    searchInProgress: boolean;
    curateInProgress: boolean;
    searchResultItems: hydratedMerchItem[];
    artist?: artist;
    teamId?: string;
    locale: string;
    collection?: merchCollection;
    bundleMap: BundleMap;
};

type DispatchProps = {
    merchSearch: (payload: searchMerchPayload) => void;
    curateMerch: (payload: curateMerchPayload) => void;
    requestModal: (payload: ModalRenderFunction) => void;
    searchCollections: (payload: searchMerchCollectionsPayload) => void;
    clearMerchSearchResults: () => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    selectedItems: hydratedMerchItem[];
    showDropdown: boolean;
    searchText: string;
    showLeavePageModal: boolean;
    searchResultsInProgress: boolean;
    addProductsInitiated: boolean;
};

class ManualSearchScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItems: [],
            showDropdown: false,
            searchText: "",
            showLeavePageModal: false,
            searchResultsInProgress: false,
            addProductsInitiated: false,
        };

        this.makeSearchQuery = this.makeSearchQuery.bind(this);
        this.makeSearchQuery = _.debounce(
            this.makeSearchQuery,
            QUERY_DELAY_TIME
        );
    }

    componentDidMount() {
        //search collections to get asins which have already been curated
        const searchCollectionsPayload: searchMerchCollectionsPayload = {
            teamId: this.props.teamId as string,
            requestPath: pagePath,
            request: {
                artistAsin: this.props.artist?.asin as string,
            },
        };
        this.props.searchCollections(searchCollectionsPayload);

        //clear search results in redux
        this.props.clearMerchSearchResults();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!this.props.searchInProgress && prevProps.searchInProgress) {
            //if hydration call is finished, then set in progress state to false
            this.setState({
                searchResultsInProgress: false,
            });
        }

        if (
            this.state.addProductsInitiated &&
            !this.props.curateInProgress &&
            prevProps.curateInProgress
        ) {
            this.props.history.push(paths.merch);
        }
    }

    render() {
        const mobileWidth = window.innerWidth <= MOBILE_SCREEN_MAX_WIDTH;
        const footerButtonStyle = mobileWidth
            ? footerButtonMobileStyle
            : footerButtonWebStyle;
        const AddSelectedButton = mobileWidth
            ? MediumSolidButton
            : LargeSolidButton;
        const numResults = this.state.selectedItems.length;
        return (
            <>
                <ArtistGradientBackgroundImage artist={this.props.artist} />
                <Container
                    fluid={true}
                    className="rootContainer"
                    style={{
                        ...rootStyles.containerStyles.rootViewContainer,
                        //16px of padding from parent element of rootContainer
                        height: "calc(100vh - 16px)",
                        display: "flex",
                        position: "relative",
                        backgroundColor: "transparent",
                    }}
                >
                    <MerchSearchHeader
                        hideImage={true}
                        imageDescription={getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.MERCHCURATION_STRINGS,
                                stringId: stringIds.Merch.Curation.AddProducts,
                            }
                        )}
                        showBackButton={true}
                        backButtonOnClick={this.onBackButtonClick}
                    />
                    <div
                        style={{
                            marginTop: rootStyles.spacers.huge,
                            width: "100%",
                            paddingLeft: rootStyles.spacers.base,
                            paddingRight: rootStyles.spacers.base,
                        }}
                    >
                        <div
                            style={{
                                ...rootStyles.textStyles.primary,
                                marginBottom: rootStyles.spacers.small,
                            }}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.MERCHCURATION_STRINGS,
                                stringId:
                                    stringIds.Merch.Curation.ManualSearchAmazon,
                            })}
                        </div>
                        <MerchSearchWithSelect
                            showDropdown={
                                this.state.showDropdown &&
                                this.state.searchText.length >
                                    MINIMUM_SEARCH_TEXT_LENGTH
                            }
                            inProgress={this.state.searchResultsInProgress}
                            searchText={this.state.searchText}
                            searchResultItems={this.props.searchResultItems}
                            onItemSelectCallback={this.merchSearchItemChange}
                            alreadySelectedItemsAsins={this.getSelectedItemAsins()}
                            onTextChange={this.onTextChange}
                            onTextInputFocus={this.onTextInputFocus}
                            disabledAsins={this.getCuratedItemAsins()}
                        />
                    </div>

                    {this.state.selectedItems.length ? (
                        <MerchListItemTable
                            rows={this.state.selectedItems.map(
                                (item: hydratedMerchItem) =>
                                    generateListItemFromMerchItem({
                                        result: item,
                                        id: `${testIDPrefix}-${item.itemAsin}`,
                                        selected: true,
                                        showCuratedItemFeatures: false,
                                        onSelect: () =>
                                            this.removeSelectedItem(
                                                item.itemAsin
                                            ),
                                        requestPath: paths.manualSearchMerch,
                                        pageId: pageIds.artistMerchManualSearchScreen,
                                    })
                            )}
                            tableHeader={
                                numResults === 1
                                    ? getLocalizedString(this.props.bundleMap, {
                                          bundleId:
                                              bundleIds.MERCHCURATION_STRINGS,
                                          stringId:
                                              stringIds.Merch.Curation
                                                  .ProductsFoundSingular,
                                      })
                                    : getLocalizedString(
                                          this.props.bundleMap,
                                          {
                                              bundleId:
                                                  bundleIds.MERCHCURATION_STRINGS,
                                              stringId:
                                                  stringIds.Merch.Curation
                                                      .ProductsFoundPlural,
                                          },
                                          { "0": numResults.toString() }
                                      )
                            }
                            trailingRowHeight={FOOTER_HEIGHT}
                        />
                    ) : (
                        <Col
                            //styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                paddingTop: 42,
                                position: "relative",
                                overflowY: "auto",
                                paddingBottom: FOOTER_HEIGHT + 60,
                            }}
                        >
                            <ul
                                style={{
                                    maxWidth: 576,
                                    marginBottom: 0,
                                    paddingLeft: 0,
                                    marginLeft: rootStyles.spacers.huge,
                                    marginRight: rootStyles.spacers.huge,
                                }}
                            >
                                <li style={searchContextTextStyle}>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .ManualSearchRelatedToArtist,
                                    })}
                                </li>
                                <li style={searchContextTextStyle}>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .ManualSearchContext,
                                    })}
                                </li>
                                <li style={searchContextTextStyle}>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .ManualSearchAsinSearch,
                                    })}
                                </li>
                                <li style={searchContextTextStyle}>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .ManualSearchVisibleAmazonMusic,
                                    })}
                                </li>
                                <li style={searchContextTextStyle}>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .ManualSearchTermsOfUse1,
                                    })}
                                    <span
                                        style={{
                                            color: rootStyles.colors.accent,
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            this.props.history.push(
                                                paths.termsAndConditions
                                            )
                                        }
                                    >
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.TERMS_STRINGS,
                                                stringId:
                                                    stringIds.Terms
                                                        .termsClickableText,
                                            }
                                        )}
                                    </span>

                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .ManualSearchTermsOfUse2,
                                    })}
                                </li>
                            </ul>
                            <Image
                                src={merchImages.amazonSmile}
                                style={{
                                    width: 113,
                                    height: 25,
                                    marginTop: rootStyles.spacers.medium,
                                }}
                            />
                        </Col>
                    )}

                    <FooterContainer>
                        <Row style={{ ...footerButtonStyle }}>
                            <Col xs={{ span: "auto" }}>
                                <AddSelectedButton
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.MERCHCURATION_STRINGS,
                                            stringId:
                                                stringIds.Merch.Curation
                                                    .SearchResultsAddProductsCTA,
                                        },
                                        {
                                            "0": this.state.selectedItems.length.toString(),
                                        }
                                    )}
                                    containerStyle={buttonStyle}
                                    disabled={
                                        this.state.selectedItems.length === 0 ||
                                        this.state.addProductsInitiated
                                    }
                                    onClick={this.addSelectedItems}
                                    id={`${testIDPrefix}_AddSelected`}
                                />
                            </Col>
                        </Row>
                    </FooterContainer>
                    <FooterBackground />
                </Container>

                <LeavePageModal
                    isVisible={this.state.showLeavePageModal}
                    onConfirm={this.confirmBack}
                    onCancel={this.cancelBack}
                    bundleMap={this.props.bundleMap}
                />
            </>
        );
    }

    private getCuratedItemAsins = () => {
        if (this.props.collection?.items.length) {
            const collectionItems = this.props.collection.items;
            const resultItems = this.props.searchResultItems;
            const curatedResultItems = _.filter(resultItems, (item) =>
                _.includes(collectionItems, item.itemAsin)
            );
            return _.map(curatedResultItems, (item) => item.itemAsin);
        } else {
            return [];
        }
    };

    private onBackButtonClick = () => {
        if (this.state.selectedItems.length) {
            this.setState({ showLeavePageModal: true });
        } else {
            this.confirmBack();
        }
    };

    private confirmBack = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Merch.goBack,
                pageIds.artistMerchManualSearchScreen,
                this.props.artist?.asin,
                EntityType.ARTIST
            )
        );
        this.props.history.goBack();
    };

    private cancelBack = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Merch.goBackCanceled,
                pageIds.artistMerchManualSearchScreen,
                this.props.artist?.asin,
                EntityType.ARTIST
            )
        );
        this.setState({ showLeavePageModal: false });
    };

    private addSelectedItems = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Merch.addMerchItems,
                pageIds.artistMerchManualSearchScreen,
                this.props.artist?.asin,
                EntityType.ARTIST
            )
        );
        const selectedItems = this.state.selectedItems;
        const payload: curateMerchPayload = {
            requestPath: pagePath,
            teamId: this.props.teamId as string,
            collectionId: this.props.collection?.collectionId,
            artist: this.props.artist as artist,
            set: selectedItems,
            remove: [],
        };
        this.props.curateMerch(payload);
        this.setState({ addProductsInitiated: true });
    };

    private getSelectedItemAsins = () => {
        return this.state.selectedItems.map((item) => item.itemAsin);
    };

    private merchSearchItemChange = (
        set: hydratedMerchItem[],
        remove: string[]
    ) => {
        const filteredMerchItems = this.state.selectedItems.filter(
            (item) => !remove.includes(item.itemAsin)
        );

        const newSelection = [...filteredMerchItems, ...set];
        if (
            newSelection.length + (this.props.collection?.items.length || 0) >
            MAX_ITEMS_IN_COLLECTION
        ) {
            this.props.requestModal(limitReachedModalRenderFunction);
            return;
        }

        this.setState({
            selectedItems: newSelection,
        });
    };

    private removeSelectedItem = (asin: string) => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Merch.deselectMerchItem,
                pageIds.artistMerchManualSearchScreen,
                asin,
                EntityType.Merch
            )
        );
        this.merchSearchItemChange([], [asin]);
    };

    private onTextInputFocus = (inFocus: boolean) => {
        this.setState({
            showDropdown: inFocus,
        });
    };

    private onTextChange = (text: string) => {
        this.setState({
            searchText: text,
        });
        if (text.length > MINIMUM_SEARCH_TEXT_LENGTH) {
            this.makeSearchQuery(text);
            this.setState({
                searchResultsInProgress: true,
            });
        }
    };

    private makeSearchQuery = (text: string) => {
        if (!this.props.artist?.asin) {
            return;
        }

        const userContext: merchUserContext = {
            marketplaceId: US_MARKETPLACE,
            locale: this.props.locale,
            deviceFamily: merchDeviceFamily.AM4A_WEB,
        };
        const searchPayload: searchMerchPayload = {
            requestPath: pagePath,
            request: {
                keywords: text,
                resultSize: SEARCH_RESULT_SIZE,
                artistAsin: this.props.artist.asin,
                userContext: userContext,
            },
        };
        this.props.merchSearch(searchPayload);
    };
}

const footerButtonWebStyle: React.CSSProperties = {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
};

const buttonStyle: React.CSSProperties = {
    alignSelf: "stretch",
    width: "100%",
};

const footerButtonMobileStyle: React.CSSProperties = {
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "stretch",
    justifyContent: "space-around",
};

const searchContextTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    marginBottom: rootStyles.spacers.medium,
};

const FooterContainer = styled.div`
    width: 100%;
    height: ${FOOTER_HEIGHT}px;
    position: absolute;
    display: flex;
    bottom: 0;
    z-index: 10;
    justify-content: flex-end;
    @media (max-width: ${MOBILE_SCREEN_MAX_WIDTH}px) {
        background: ${rootStyles.colors.background};
    }
    @media (max-width: ${LARGE_SCREEN_WIDTH_THRESHOLD}px) {
        position: fixed;
    }
`;

const FooterBackground = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    height: ${FOOTER_HEIGHT}px;
    width: 100%;
    background: linear-gradient(transparent, ${rootStyles.colors.background});
`;

function mapStateToProps(state: RootState): StateProps {
    return manualSearchScreenSelector(state);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        merchSearch: (payload: searchMerchPayload) =>
            dispatch(merchCurateActions.merchSearch(payload)),
        curateMerch: (payload: curateMerchPayload) =>
            dispatch(merchCurateActions.curateMerch(payload)),
        requestModal: (payload: ModalRenderFunction) =>
            dispatch(globalNotificationsActions.requestModalDisplay(payload)),
        searchCollections: (payload: searchMerchCollectionsPayload) =>
            dispatch(merchCurateActions.getMerchCollections(payload)),
        clearMerchSearchResults: () =>
            dispatch(merchCurateActions.clearMerchSearchResults()),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ManualSearchScreen)
);
