import { createAction } from "redux-ts";
import { CsvButtonId } from "../../export";
import {
    queryResult,
    songPerformanceResult,
    albumPerformanceResult,
    playlistPerformanceResult,
    stationPerformanceResult,
} from "../../models";
import {
    reportingOverviewRequestPayload,
    songPerformanceRequestPayload,
    albumPerformanceRequestPayload,
    programmingPerformanceRequestPayload,
    timeRangePayload,
    getReportsInProgessPayload,
    getReportsCompletedPayload,
    overviewRequestPayload,
} from "../../models";

export const getReportsOverview = createAction<reportingOverviewRequestPayload>(
    "REPORTS::GET_REPORTS_OVERVIEW"
);
export const getReportsOverviewInProgress = createAction<boolean>(
    "REPORTS::GET_REPORTS_OVERVIEW_IN_PROGRESS"
);
export const getReportsOverviewCompleted = createAction<queryResult>(
    "REPORTS::GET_REPORTS_OVERVIEW_COMPLETED"
);

export const getProgrammingReports =
    createAction<reportingOverviewRequestPayload>(
        "REPORTS::GET_PROGRAMMING_REPORTS"
    );
export const getProgrammingReportsInProgress = createAction<boolean>(
    "REPORTS::GET_PROGRAMMING_REPORTS_IN_PROGRESS"
);
export const getProgrammingReportsCompleted = createAction<queryResult>(
    "REPORTS::GET_PROGRAMMING_REPORTS_COMPLETED"
);

export const getVoiceReports = createAction<reportingOverviewRequestPayload>(
    "REPORTS::GET_VOICE_REPORTS"
);
export const getVoiceReportsInProgress = createAction<boolean>(
    "REPORTS::GET_VOICE_REPORTS_IN_PROGRESS"
);
export const getVoiceReportsCompleted = createAction<queryResult>(
    "REPORTS::GET_VOICE_REPORTS_COMPLETED"
);

export const getFanTiersReports = createAction<reportingOverviewRequestPayload>(
    "REPORTS::GET_FAN_TIERS_REPORTS"
);
export const getFanTiersReportsInProgress = createAction<boolean>(
    "REPORTS::GET_FAN_TIERS_REPORTS_IN_PROGRESS"
);
export const getFanTiersReportsCompleted = createAction<queryResult>(
    "REPORTS::GET_FAN_TIERS_REPORTS_COMPLETED"
);

export const getReports = createAction<overviewRequestPayload>(
    "REPORTS::GET_REPORTS"
);
export const getReportsInProgress = createAction<getReportsInProgessPayload>(
    "REPORTS::GET_REPORTS_IN_PROGRESS"
);
export const getReportsCompleted = createAction<getReportsCompletedPayload>(
    "REPORTS::GET_REPORTS_COMPLETED"
);

export const updateTimeRange = createAction<timeRangePayload>(
    "REPORTS::UPDATE_TIMERANGE"
);

export const getSongPerformance = createAction<songPerformanceRequestPayload>(
    "REPORTS::GET_SONG_PERFORMANCE"
);
export const getSongPerformanceInProgress = createAction<boolean>(
    "REPORTS::GET_SONG_PERFORMANCE_IN_PROGRESS"
);
export const getSongPerformanceCompleted = createAction<songPerformanceResult>(
    "REPORTS::GET_SONG_PERFORMANCE_COMPLETED"
);

export const getAlbumPerformance = createAction<albumPerformanceRequestPayload>(
    "REPORTS::GET_ALBUM_PERFORMANCE"
);
export const getAlbumPerformanceInProgress = createAction<boolean>(
    "REPORTS::GET_ALBUM_PERFORMANCE_IN_PROGRESS"
);
export const getAlbumPerformanceCompleted =
    createAction<albumPerformanceResult>(
        "REPORTS::GET_ALBUM_PERFORMANCE_COMPLETED"
    );

export const getPlaylistPerformance =
    createAction<programmingPerformanceRequestPayload>(
        "REPORTS::GET_PLAYLIST_PERFORMANCE"
    );
export const getPlaylistPerformanceInProgress = createAction<boolean>(
    "REPORTS::GET_PLAYLIST_PERFORMANCE_IN_PROGRESS"
);
export const getPlaylistPerformanceCompleted =
    createAction<playlistPerformanceResult>(
        "REPORTS::GET_PLAYLIST_PERFORMANCE_COMPLETED"
    );

export const getStationPerformance =
    createAction<programmingPerformanceRequestPayload>(
        "REPORTS:GET_STATION_PERFORMANCE"
    );
export const getStationPerformanceInProgress = createAction<boolean>(
    "REPORTS::GET_STATION_PERFORMANCE_IN_PROGRESS"
);
export const getStationPerformanceCompleted =
    createAction<stationPerformanceResult>(
        "REPORTS::GET_STATION_PERFORMANCE_COMPLETED"
    );

export const setSelectedAlbumAsin = createAction<string>(
    "REPORTS::SET_SELECTED_ALBUM_ASIN"
);
export const setSelectedSongAsin = createAction<string>(
    "REPORTS::SET_SELECTED_SONG_ASIN"
);
export const setSelectedPlaylistAsin = createAction<string>(
    "REPORTS::SET_SELECTED_PLAYLIST_ASIN"
);
export const setSelectedStationAsin = createAction<string>(
    "REPORTS::SET_SELECTED_STATION_ASIN"
);

export const removeSelectedAlbumAsin = createAction<string>(
    "REPORTS::REMOVE_SELECTED_ALBUM_ASIN"
);
export const removeSelectedSongAsin = createAction<string>(
    "REPORTS::REMOVE_SELECTED_SONG_ASIN"
);
export const removeSelectedPlaylistAsin = createAction<string>(
    "REPORTS::REMOVE_SELECTED_PLAYLIST_ASIN"
);
export const removeSelectedStationAsin = createAction<string>(
    "REPORTS::REMOVE_SELECTED_STATION_ASIN"
);

export const csvDownloadInProgress = createAction<CsvButtonId>(
    "REPORTS::CSV_DOWNLOAD_IN_PROGRESS"
);
export const csvDownloadCompleted = createAction<CsvButtonId>(
    "REPORTS::CSV_DOWNLOAD_COMPLETED"
);

export const clearState = createAction("REPORTS::CLEAR_STATE");
