import * as React from "react";
import { Card } from "react-bootstrap";
import * as rootStyles from "../../../styles";

type OutageBannerProps = {
    text: string;
    height: number;
};

export class OutageBanner extends React.Component<OutageBannerProps> {
    
    render() {
        return (
          <Card style={outageBannerStyle(this.props.height)}>
              <div style={textStyle}>{this.props.text}</div>
          </Card>
        );
    }
}

const outageBannerStyle = (height: number): React.CSSProperties => {
    return {
        flex: 0,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: height,
        width: "100%",
        top: 0,
        backgroundColor: rootStyles.colors.error
    };
};

const textStyle: React.CSSProperties = {
    flex: 0,
    textAlign: "center",
    ...rootStyles.textStyles.tertiary,
    color: rootStyles.textStyles.primary.color,
    padding: "8px"
};
