import { createAction } from "redux-ts";
import {
    getCustomerSupportDataResponse,
    GetSocialAccountDataRequest,
    GetSocialAccountDataResponse,
    GetStateTokenCompletedPayload,
    GetStateTokenPayload,
    IntercomEvent,
    SetStateTokenInProgressPayload,
} from "../../models";

export const getCustomerSupportData = createAction(
    "OAUTH::GET_CUSTOMER_SUPPORT_DATA"
);

export const getCustomerSupportDataCompleted =
    createAction<getCustomerSupportDataResponse>(
        "OAUTH::GET_CUSTOMER_SUPPORT_DATA_COMPLETED"
    );

export const setIntercomEvent = createAction<IntercomEvent>(
    "OAUTH::SET_INTERCOM_EVENT"
);

export const setShowIntercomMessenger = createAction<boolean>(
    "OAUTH::SET_SHOW_INTERCOM_MESSENGER"
);

export const setHideIntercomLauncher = createAction<boolean>(
    "OAUTH::SET_HIDE_INTERCOM_LAUNCHER"
);

export const getStateToken = createAction<GetStateTokenPayload>(
    "OAUTH::GET_STATE_TOKEN"
);

export const getStateTokenCompleted =
    createAction<GetStateTokenCompletedPayload>(
        "OAUTH::GET_STATE_TOKEN_COMPLETED"
    );

export const setStateTokenInProgress =
    createAction<SetStateTokenInProgressPayload>(
        "OAUTH::SET_STATE_TOKEN_IN_PROGRESS"
    );

export const getSocialAccountData = createAction<GetSocialAccountDataRequest>(
    "OAUTH::GET_SOCIAL_ACCOUNT_DATA"
);
export const getSocialAccountDataCompleted =
    createAction<GetSocialAccountDataResponse>(
        "OAUTH::GET_SOCIAL_DATA_COMPLETED"
    );
