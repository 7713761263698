import * as React from "react";
import * as rootStyles from "../../../styles";
import { LanguageRadioElement } from ".";
import {
    testIDSuffixes,
    userLanguages,
    localizedUserLanguagesList,
    translateUserLanguageToLocaleString,
} from "../../../../utils";

export type LanguageRadioListProps = {
    label?: string;
    onChangeElement: (userSelectedLocale: string) => void;
    initialSelectedIndex: number;
    id: string;
};

type State = {
    selectedIndex?: number;
};

export class LanguageRadioList extends React.Component<
    LanguageRadioListProps,
    State
> {
    constructor(props: LanguageRadioListProps) {
        super(props);
        this.state = {
            selectedIndex: props.initialSelectedIndex,
        };
    }

    componentDidUpdate(prevProps: LanguageRadioListProps) {
        if (
            prevProps.initialSelectedIndex !== this.props.initialSelectedIndex
        ) {
            this.setState({ selectedIndex: this.props.initialSelectedIndex });
        }
    }

    render() {
        return (
            <div style={radioContainer}>
                {this.props.label && (
                    <p
                        style={labelStyle}
                        id={`${this.props.id}${testIDSuffixes.label}`}
                    >
                        {this.props.label}
                    </p>
                )}
                {this.renderButtonList()}
            </div>
        );
    }

    private renderButtonList = () => {
        const ret = [];

        for (let i = 0; i < userLanguages.length; i++) {
            ret.push(
                <LanguageRadioElement
                    primary={userLanguages[i]}
                    secondary={
                        localizedUserLanguagesList[
                            this.state.selectedIndex
                                ? this.state.selectedIndex
                                : this.props.initialSelectedIndex
                        ][i]
                    }
                    onButtonSelected={() => this.onButtonSelected(i)}
                    selected={
                        i === (this.state ? this.state.selectedIndex : -1)
                    }
                    key={i}
                    id={this.props.id + testIDSuffixes.item + i}
                />
            );
        }

        return ret;
    };

    private onButtonSelected = (index: number) => {
        this.setState({ selectedIndex: index });
        this.props.onChangeElement(
            translateUserLanguageToLocaleString(userLanguages[index])
        );
    };
}

const radioContainer: React.CSSProperties = {
    flex: 1,
};

const labelStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    marginBottom: rootStyles.spacers.small,
};
