import React from "react";
import { Dropdown } from "react-bootstrap";

export type OverflowMenuItemProps = {
    onSelect: () => void;
    id: string;
};

export const OverflowMenuItem: React.FC<OverflowMenuItemProps> = ({
    children,
    onSelect,
    id,
}) => {
    return (
        <Dropdown.Item
            onSelect={onSelect}
            id={id}
            style={{ fontFamily: "AmazonEmber" }}
        >
            {children}
        </Dropdown.Item>
    );
};
