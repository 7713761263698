import _ from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import { Icon } from "..";
import * as rootStyles from "../../../styles";

type Props = {
    icon: string;
    iconSize: number;
    isCircle?: boolean;
    iconColor?: string;
    noGradient?: boolean;
    noGradientBackgroundColor?: string;
    id: string;
    iconDescription?: string;
    onClick?: () => void;
    iconStyle?: React.CSSProperties;
};

export class IconTile extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const noOnClick = () => {
            return (
                <div
                    style={{
                        display: "flex",
                        height: this.props.iconSize * 3, // this is the height of the original icon + its size both on top and below it (making a twice as big square around it)
                        width: this.props.iconSize * 3,
                        background: this.props.noGradient
                            ? this.props.noGradientBackgroundColor
                                ? this.props.noGradientBackgroundColor
                                : rootStyles.glassColors.primary2
                            : `linear-gradient(${rootStyles.colors.secondary}, ${rootStyles.glassColors.primary1})`,
                        borderRadius: this.props.isCircle
                            ? (this.props.iconSize * 3) / 2
                            : 4,
                        boxShadow: `0 3px 10px rgb(0 0 0 / 0.2)`,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Icon
                        icon={this.props.icon}
                        size={this.props.iconSize}
                        color={this.props.iconColor}
                        id={this.props.id}
                        iconDescription={this.props.iconDescription}
                        style={this.props.iconStyle}
                    />
                </div>
            );
        };

        const withOnClick = () => {
            return (
                <Button
                    style={{
                        display: "flex",
                        height: this.props.iconSize * 3, // this is the height of the original icon + its size both on top and below it (making a twice as big square around it)
                        width: this.props.iconSize * 3,
                        background: this.props.noGradient
                            ? this.props.noGradientBackgroundColor
                                ? this.props.noGradientBackgroundColor
                                : rootStyles.glassColors.primary2
                            : `linear-gradient(${rootStyles.colors.secondary}, ${rootStyles.glassColors.primary1})`,
                        borderRadius: this.props.isCircle
                            ? (this.props.iconSize * 3) / 2
                            : 4,
                        justifyContent: "center",
                        alignItems: "center",
                        borderColor: "transparent",
                    }}
                    onClick={this.props.onClick}
                >
                    <Icon
                        icon={this.props.icon}
                        size={this.props.iconSize}
                        color={this.props.iconColor}
                        id={this.props.id}
                        iconDescription={this.props.iconDescription}
                        style={this.props.iconStyle}
                    />
                </Button>
            );
        };

        const componentToRender = this.props.onClick ? withOnClick : noOnClick;

        return componentToRender();
    }
}
