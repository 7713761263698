import React, { CSSProperties } from "react";
import { ButtonWrapper, Icon } from "..";
import { IconsList } from "../../../assets/icons/IconFiles";
import * as rootStyles from "../../styles";

export const GoBackButton = ({
    style,
    onClick,
    id,
}: {
    style?: React.CSSProperties;
    onClick?: () => void;
    id: string;
}) => {
    return (
        <ButtonWrapper
            onClick={onClick}
            style={{
                ...buttonStyle,
                ...style,
            }}
            id={`${id}-GoBack`}
        >
            <Icon
                icon={IconsList.chevron_caretleft}
                size={rootStyles.icons.medium}
                id={`${id}-caretleftIcon`}
            />
        </ButtonWrapper>
    );
};

const buttonStyle: React.CSSProperties = {
    display: "flex",
    background: "none",
    border: 0,
    padding: 0,
};
