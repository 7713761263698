import { buttonIds, stringIds, bundleIds } from "@amzn/ziggy-asset";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clientMetricsActions,
    merchCurateActions,
} from "../../../store/actions";
import {
    getActiveCollectionIdSelector,
    getSelectedTeamSelector,
    hasCurateMerchPermission,
    selectedArtistFromCatalogSelector,
} from "../../../store/selectors";
import { IconsList, OverflowMenu, OverflowMenuItem } from "../";
import {
    getBundleMap,
    getSelectedArtistAsin,
} from "../../../store/selectors/commonSelectors";
import {
    buildUIClickPayload,
    getLocalizedString,
    MerchLinkBuilders,
} from "../../../utils";
import { MerchShare } from "./MerchShare";
import { BundleMap, curateMerchPayload, EntityType } from "../../../models";

export type MerchOverflowProps = {
    showCuratedItemOptions: boolean;
    itemAsin: string;
    itemName: string;
    productImage?: string;
    id: string;
    iconSize?: number;
    additionalButtonStyle?: React.CSSProperties;
    requestPath: string;
    pageId: string;
};

export const MerchOverflow: React.FC<MerchOverflowProps> = ({
    showCuratedItemOptions,
    itemAsin,
    itemName,
    productImage,
    id,
    iconSize,
    additionalButtonStyle,
    requestPath,
    pageId,
}) => {
    const artist = useSelector(selectedArtistFromCatalogSelector);
    const artistAsin = useSelector(getSelectedArtistAsin);
    const teamId = useSelector(getSelectedTeamSelector)?.teamId;
    const hasCuratePermissions = useSelector(hasCurateMerchPermission);
    const collectionId = useSelector(getActiveCollectionIdSelector);
    const bundleMap: BundleMap = useSelector(getBundleMap);

    const dispatch = useDispatch();

    const [showShareModal, setShowShareModal] = useState<boolean>(false);

    const selectShare = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.shareProduct,
                    pageId,
                    itemAsin,
                    EntityType.Merch
                )
            )
        );
        setShowShareModal(true);
    };

    const selectViewOnAmazon = () => {
        if (!artistAsin) {
            // should never happen, but we also have protections
            // on this below
            return;
        }

        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.viewItemOnAmazon,
                    pageId,
                    itemAsin,
                    EntityType.Merch
                )
            )
        );
        window.open(
            MerchLinkBuilders.viewOnAmazon(itemAsin, artistAsin),
            "_blank",
            "noreferrer, noopener"
        );
    };

    const selectReportInfringement = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.reportInfringement,
                    pageId,
                    itemAsin,
                    EntityType.Merch
                )
            )
        );
        window.open(
            "https://www.amazon.com/report/infringement/signin",
            "_blank",
            "noreferrer, noopener"
        );
    };

    const selectRemoveFromList = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.removeMerchItem,
                    pageId,
                    itemAsin,
                    EntityType.Merch
                )
            )
        );
        if (!artist) {
            return;
        }
        const payload: curateMerchPayload = {
            artist,
            teamId,
            collectionId,
            set: [],
            remove: [{ itemAsin: itemAsin }],
            requestPath,
        };

        dispatch(merchCurateActions.curateMerch(payload));
    };

    return (
        <>
            <OverflowMenu
                icon={IconsList.action_overflow}
                id={id}
                buttonStyle={{
                    backgroundColor: "rgba(0,0,0,0.0)", // 0.0 is the important part as it's completely transparent
                    ...additionalButtonStyle,
                }}
                iconSize={iconSize}
            >
                {showCuratedItemOptions ? (
                    <OverflowMenuItem
                        onSelect={selectShare}
                        id={`${id}_ShareProduct`}
                    >
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCHCURATION_STRINGS,
                            stringId:
                                stringIds.Merch.Curation
                                    .ContextMenuShareProduct,
                        })}
                    </OverflowMenuItem>
                ) : undefined}
                {artistAsin ? (
                    <OverflowMenuItem
                        onSelect={selectViewOnAmazon}
                        id={`${id}_ViewOnAmazon`}
                    >
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCHCURATION_STRINGS,
                            stringId:
                                stringIds.Merch.Curation
                                    .ContextMenuViewOnAmazon,
                        })}
                    </OverflowMenuItem>
                ) : undefined}
                <OverflowMenuItem
                    onSelect={selectReportInfringement}
                    id={`${id}_ReportInfringement`}
                >
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .ContextMenuReportInfringement,
                    })}
                </OverflowMenuItem>
                {showCuratedItemOptions && hasCuratePermissions ? (
                    <OverflowMenuItem
                        onSelect={selectRemoveFromList}
                        id={`${id}_RemoveProduct`}
                    >
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCHCURATION_STRINGS,
                            stringId:
                                stringIds.Merch.Curation
                                    .ContextMenuRemoveFromList,
                        })}
                    </OverflowMenuItem>
                ) : undefined}
            </OverflowMenu>

            <MerchShare
                itemAsin={itemAsin}
                itemName={itemName}
                productImage={productImage}
                id={`${id}_ShareModal`}
                isVisible={showShareModal}
                onHide={() => setShowShareModal(false)}
            />
        </>
    );
};
