import React from "react";
import _ from "lodash";
import {
    VictoryAxis,
    VictoryChart,
    VictoryArea,
    VictoryVoronoiContainer,
    VictoryTooltip,
    VictoryLabel,
} from "victory";
import { chartStyles, generateLabel, generateFanLabel } from "./styles";
import { containerStyles } from "./";
import * as rootStyles from "../../../view/styles";
import { ChartProps, DataSet } from "./common";
import {
    getLocalizedString,
    testIDSuffixes,
    formatNumber,
    formatShortNumber,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import { ChartHeader } from "./ChartHeader";
import { Card, Row } from "react-bootstrap";
import { BundleMap } from "../../../models";

export type LineGraphProps = ChartProps & {
    data: DataSet[];
    labels?: string[];
    xTickCount: number;
    tickFormat?: (datum: string) => string;
    dependentAxisTickFormat?: (dataum: any) => any;
    tooltipFormat?: (datum: string) => string;
    emptyMessage?: string;
    useDynamicMin?: boolean;
    footNoteText?: string;
    footNoteClickableText?: string;
    footNoteUrl?: string;
    toolTipStrokeColor?: string;
    bundleMap: BundleMap;
};

export class LineGraph extends React.Component<LineGraphProps> {
    shouldComponentUpdate(prevProps: LineGraphProps) {
        // Update when the data changes or when the title (# total streams/listeners etc) changes
        if (
            (prevProps.data.length === 0 && this.props.data.length > 0) ||
            prevProps.title !== this.props.title
        ) {
            return true;
        }
        return false;
    }

    render() {
        console.log("Graph render");
        const emptyMessage =
            this.props.emptyMessage ||
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.EMPTYSTATE_STRINGS,
                stringId: stringIds.EmptyState.message,
            });

        // Calculate the maximum value for the y axis.
        let max = 1;
        let min = Number.MAX_VALUE;
        this.props.data.forEach((dataSet) => {
            dataSet.forEach((point: any) => {
                max = Math.max(max, point.y);
                min = Math.min(min, point.y);
            });
        });

        /**
         * useDynamicMin Setting:
         * Makes the graph zoom in on the differntial between max and min over the period.
         * Instead of starting from 0, the graph starts at a % below min value.
         * If dynamicMin is not being used, then we use standard 1.2 times of max.
         */
        const maxMultiplier = 1 + 0.03;
        const minMultiplier = 1 - 0.06;

        max = this.props.useDynamicMin ? max * maxMultiplier : max * 1.2;
        min = this.props.useDynamicMin ? min * minMultiplier : 0;

        const isValidData =
            (this.props.data[0] && this.props.data[0].length > 0) ||
            (this.props.data[1] && this.props.data[1].length > 0) ||
            (this.props.data[2] && this.props.data[2].length > 0);

        const data1 =
            this.props.data[0] && this.transformDataSet(this.props.data[0]);
        const data2 =
            this.props.data[1] && this.transformDataSet(this.props.data[1]);
        const data3 =
            this.props.data[2] && this.transformDataSet(this.props.data[2]);

        let dynamicPadding = 50;
        let dynamicChartMargin = -15;

        /**
         * When using dynamicMin view, we want to show complete value instead of short number
         * When showing complete value, margin and padding need to be adjusted so that the numbers do not get cut off
         * and the graph alignement is correct.
         * dynamicPadding = allow for numbers to not get off
         * dynamicChartMargin = Align the graph correctly, after dynamic padding
         */

        // DynamicRightPadding: So x axis label on right edge do not get cut off.
        let dynamicRightPadding = 50;
        if (this.props.useDynamicMin) {
            dynamicRightPadding = 60;

            dynamicPadding = max >= 10000 ? 55 : dynamicPadding;
            dynamicPadding = max >= 100000 ? 60 : dynamicPadding;
            dynamicPadding = max >= 1000000 ? 65 : dynamicPadding;
            dynamicPadding = max >= 10000000 ? 70 : dynamicPadding;

            dynamicChartMargin = max >= 10000 ? -10 : dynamicChartMargin;
            dynamicChartMargin = max >= 100000 ? -5 : dynamicChartMargin;
            dynamicChartMargin = max >= 1000000 ? 0 : dynamicChartMargin;
            dynamicChartMargin = max >= 10000000 ? 5 : dynamicChartMargin;
        }

        return (
            <Card style={containerStyles.chartContainer}>
                <Row id={`${this.props.id}-Header`}>
                    <ChartHeader {...this.props} />
                </Row>
                <Row
                    style={chartStyle}
                    id={`${this.props.id}${testIDSuffixes.chart}`}
                >
                    {isValidData ? (
                        <VictoryChart
                            padding={{
                                top: 8,
                                bottom: 50,
                                left: dynamicPadding + 10,
                                right: dynamicRightPadding,
                            }}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    labels={({ datum }) => {
                                        const date = this.props.tooltipFormat
                                            ? `\n${this.props.tooltipFormat(
                                                  datum.x
                                              )}`
                                            : "";
                                        return `${formatNumber(
                                            datum.y
                                        )}${date}`;
                                    }}
                                    labelComponent={
                                        <VictoryTooltip
                                            cornerRadius={12}
                                            flyoutStyle={{
                                                stroke: this.props
                                                    .toolTipStrokeColor
                                                    ? this.props
                                                          .toolTipStrokeColor
                                                    : rootStyles.colors.accent,
                                                strokeWidth: 2,
                                                fill: rootStyles.colors
                                                    .background,
                                            }}
                                            style={{
                                                fill: rootStyles.colors.primary,
                                                minWidth: 100,
                                                ...rootStyles.textStyles.label,
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                            }}
                                            pointerWidth={1}
                                            width={90}
                                            constrainToVisibleArea={true}
                                            renderInPortal={true}
                                            x={100}
                                            y={30}
                                        />
                                    }
                                />
                            }
                            height={200}
                        >
                            <VictoryAxis
                                key="dependant_axis"
                                tickCount={4}
                                dependentAxis={true}
                                tickFormat={
                                    this.props.dependentAxisTickFormat
                                        ? this.props.dependentAxisTickFormat
                                        : formatShortNumber
                                }
                                style={{
                                    grid: {
                                        stroke: rootStyles.generateColor(
                                            rootStyles.colors.primary,
                                            rootStyles.glass._2
                                        ),
                                    },
                                    tickLabels: chartStyles.tickLabels,
                                }}
                            />
                            <VictoryAxis
                                key="axis"
                                tickCount={this.props.xTickCount}
                                tickFormat={this.props.tickFormat}
                                axisLabelComponent={
                                    <VictoryLabel
                                        textAnchor="middle"
                                        style={{ textAlign: "center" }}
                                    />
                                }
                                style={{
                                    tickLabels: chartStyles.tickLabels,
                                }}
                            />

                            {data1 && data1.length > 1 && (
                                <VictoryArea
                                    data={data1}
                                    domain={{ y: [min, max] }}
                                    style={{
                                        data: {
                                            fill: rootStyles.colors.accent,
                                            stroke: rootStyles.colors.accent,
                                            ...chartStyles.lineChart.areaStyle,
                                        },
                                    }}
                                />
                            )}

                            {data2 && data2.length > 1 && (
                                <VictoryArea
                                    data={data2}
                                    domain={{ y: [min, max] }}
                                    style={{
                                        data: {
                                            fill: rootStyles.colors.aux3,
                                            stroke: rootStyles.colors.aux3,
                                            ...chartStyles.lineChart.areaStyle,
                                        },
                                    }}
                                />
                            )}

                            {data3 && data3.length > 1 && (
                                <VictoryArea
                                    data={data3}
                                    domain={{ y: [min, max] }}
                                    style={{
                                        data: {
                                            fill: rootStyles.colors.aux1,
                                            stroke: rootStyles.colors.aux1,
                                            ...chartStyles.lineChart.areaStyle,
                                        },
                                    }}
                                />
                            )}
                        </VictoryChart>
                    ) : (
                        <div
                            id={this.props.id + "-MessageOnly"}
                            style={emptyChartStyle}
                        >
                            <span style={textStyle}>{emptyMessage}</span>
                        </div>
                    )}
                </Row>
                {/* labels for tool tips */}
                {this.props.labels &&
                    isValidData &&
                    this.props.data.length === 1 && (
                        <Row
                            id={this.props.id + "-Labels"}
                            style={containerStyles.labelContainer}
                        >
                            {this.props.labels[0] &&
                                generateLabel(
                                    this.props.labels[0],
                                    rootStyles.generateColor(
                                        rootStyles.colors.aux1,
                                        rootStyles.glass._4
                                    )
                                )}
                        </Row>
                    )}
                {/* labels for fan tiers label */}
                {this.props.labels &&
                    isValidData &&
                    this.props.data.length > 1 && (
                        <Row
                            id={this.props.id + "-FanTierLabel"}
                            style={containerStyles.labelContainer}
                        >
                            {this.props.labels[0] &&
                                generateFanLabel(
                                    this.props.labels[0],
                                    rootStyles.generateColor(
                                        rootStyles.colors.accent,
                                        rootStyles.glass._4
                                    )
                                )}
                            {this.props.labels[1] &&
                                generateFanLabel(
                                    this.props.labels[1],
                                    rootStyles.generateColor(
                                        rootStyles.colors.aux3,
                                        rootStyles.glass._4
                                    )
                                )}
                            {this.props.labels[2] &&
                                generateFanLabel(
                                    this.props.labels[2],
                                    rootStyles.generateColor(
                                        rootStyles.colors.aux1,
                                        rootStyles.glass._4
                                    )
                                )}
                        </Row>
                    )}
                {/* footnote */}
                {this.props.footNoteText && this.props.footNoteClickableText && (
                    <Row id={this.props.id + "-Footer"}>
                        <span style={rootStyles.textStyles.tertiary}>
                            {this.props.footNoteText}{" "}
                            <a
                                style={clickableTextStyle}
                                href={this.props.footNoteUrl}
                                target="_blank"
                            >
                                {this.props.footNoteClickableText}
                            </a>
                        </span>
                    </Row>
                )}
            </Card>
        );
    }

    private transformDataSet = (data: DataSet): DataSet => {
        if (data.length === 1) {
            // We transform this to support single-day date ranges
            return [
                data[0],
                {
                    ...data[0],
                    // This is a hack because VictoryChart will dedupe datapoints with the same x value.
                    // The x value comes in as ISO format, including timestamps, so this keeps the x value
                    // the same for the first data point, and truncates for the second data point.
                    x: data[0].x.toString().substring(0, "YYYY-MM-DD".length),
                },
            ];
        }

        return data;
    };
}

const chartStyle: React.CSSProperties = {
    flex: 1,
    flexDirection: "row",
    alignContent: "flex-start",
    padding: 0,
    alignSelf: "flex-start",
    width: "100%",
    height: "100%",
};
const emptyChartStyle: React.CSSProperties = {
    paddingLeft: rootStyles.spacers.large,
    paddingRight: rootStyles.spacers.large,
    paddingTop: rootStyles.spacers.medium,
    flex: 1,
    paddingBottom: rootStyles.spacers.huge,
    flexDirection: "row",
    alignItems: "stretch",
    alignContent: "stretch",
    justifyContent: "center",
};
const textStyle: React.CSSProperties = {
    flex: 1,
    ...rootStyles.textStyles.primary,
    marginTop: rootStyles.spacers.large,
    marginBottom: rootStyles.spacers.medium,
    textAlign: "left",
    color: rootStyles.glassColors.primary3,
    justifyContent: "flex-start",
};
const clickableTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    color: rootStyles.colors.accent,
    marginTop: 2,
};
