import * as React from "react";
import { Col, Row } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { Icon } from "./icons";

export type StepFieldProps = {
    leftIcon?: any;
    title: string;
    subtitle?: string;
    note?: string;
    bottomElement?: any;
    bottomElementIndent?: boolean;
    rightIcon?: any;
    id: string;
};

export const StepField: React.FC<StepFieldProps> = ({
    leftIcon,
    title,
    subtitle,
    bottomElement,
    bottomElementIndent = true,
    rightIcon,
    id,
}) => {
    const StepFieldPrefix = "-StepField";

    return (
        <>
            <div
                style={{
                    alignItems: "center",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 32,
                }}
            >
                {leftIcon ? (
                    <div
                        style={{
                            alignSelf: "flex-start",
                            paddingRight: rootStyles.spacers.mini,
                            marginTop: -2,
                        }}
                        id={id + StepFieldPrefix + "-LeftIcon"}
                    >
                        <Icon size={20} icon={leftIcon} id={`${id}_LeftIcon`} />
                    </div>
                ) : null}
                <div>
                    <Row
                        style={{ alignItems: "end" }}
                        id={id + StepFieldPrefix + "-Title"}
                    >
                        <span style={stepFieldTitleStyle}>{title}</span>
                    </Row>
                    {subtitle ? (
                        <Row id={id + StepFieldPrefix + "-Subtitle"}>
                            <span style={stepFieldSubtitleStyle}>
                                {subtitle}
                            </span>
                        </Row>
                    ) : null}
                </div>
                {rightIcon && (
                    <div
                        style={{
                            paddingLeft: rootStyles.spacers.mini,
                            paddingRight: 15,
                            alignSelf: "flex-start",
                            marginTop: rootStyles.spacers.micro,
                        }}
                        id={id + StepFieldPrefix + "-RightIcon"}
                    >
                        {rightIcon}
                    </div>
                )}
            </div>
            {bottomElement && (
                <div
                    style={{
                        width: "100%",
                        paddingLeft: bottomElementIndent ? 30 : 0,
                        marginTop: rootStyles.spacers.base,
                    }}
                    id={id + StepFieldPrefix + "-BottomElement"}
                >
                    {bottomElement}
                </div>
            )}
        </>
    );
};

const stepFieldTitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
};

const stepFieldSubtitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
};

const colVerticalAlignStyle: React.CSSProperties = {
    marginTop: "auto",
    marginBottom: "auto",
};

export default StepField;
