import * as React from "react";
import { Row, Modal } from "react-bootstrap";
import { Icon, IconsList } from "../icons";
import * as rootStyles from "../../../styles";
import { LargeSolidButton, LargeOutlineButton } from "../input/Buttons";
import { BlurredModal } from "..";

export type LearnMoreModalProps = {
    accept: () => void;
    onDismiss?: () => void;
    isVisible: boolean;
    acceptbuttonText: string;
    moreInformationButtonText: string;
    moreInformationButtonAction: () => void;
    title: string;
    body: any;
    footer?: any;
    modalWidth: string;
    id: string;
};

export class LearnMoreModal extends React.Component<LearnMoreModalProps> {
    render() {
        const createSpacer = (height: number) => {
            return (
                <div
                    style={{
                        flex: 1,
                        minHeight: height,
                    }}
                />
            );
        };

        return (
            <BlurredModal
                id="blurred-modal"
                onHide={this.props.onDismiss}
                show={this.props.isVisible}
            >
                <Modal.Body
                    id={this.props.id + "-Body"}
                    style={{ ...modalContainer, width: this.props.modalWidth }}
                >
                    <Row
                        style={iconContainerStyle}
                        onClick={this.props.onDismiss}
                    >
                        <Icon
                            icon={IconsList.action_cancel}
                            size={32}
                            color={rootStyles.colors.primary}
                            id={`${this.props.id}_DismissIcon`}
                        />
                    </Row>
                    {createSpacer(rootStyles.spacers.large)}
                    <Row style={{ alignSelf: "flex-start" }}>
                        <span
                            style={{
                                ...rootStyles.textStyles.primary,
                                fontSize: rootStyles.webFontSizes.h4,
                            }}
                        >
                            {this.props.title}
                        </span>
                    </Row>
                    {createSpacer(rootStyles.spacers.small)}
                    {this.props.body()}
                    {createSpacer(rootStyles.spacers.medium)}
                    <Row
                        style={{
                            width: "100%",
                        }}
                    >
                        <LargeOutlineButton
                            id={this.props.id + "_SecondaryButton"}
                            title={this.props.moreInformationButtonText}
                            onClick={this.props.moreInformationButtonAction}
                            containerStyle={{
                                width: "100%",
                                flex: 1,
                            }}
                        />
                    </Row>
                    {createSpacer(rootStyles.spacers.large)}
                    <Row
                        style={{
                            width: "100%",
                        }}
                    >
                        <LargeSolidButton
                            onClick={this.props.accept}
                            title={this.props.acceptbuttonText}
                            containerStyle={{ width: "100%", flex: 1 }}
                            id={`${this.props.id}_AcceptButton`}
                        />
                    </Row>
                    {createSpacer(rootStyles.spacers.medium)}
                    {this.props.footer && this.props.footer()}
                </Modal.Body>
            </BlurredModal>
        );
    }
}

const modalContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: rootStyles.spacers.base,
    paddingRight: rootStyles.spacers.base,
    backgroundColor: rootStyles.colors.background,
    display: "flex",
};

const iconContainerStyle: React.CSSProperties = {
    flex: 0,
    padding: 4,
    borderRadius: 24,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
};
