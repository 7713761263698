import { Action } from "redux-ts";
import { put, takeEvery, select } from "redux-saga/effects";
import {
    errorActions,
    globalNotificationsActions,
    merchCurateActions,
    opsMetricsActions,
} from "../../actions";
import {
    createMerchCollectionRequest,
    curateMerchPayload,
    merchEntityType,
    merchListingType,
    METRIC_KEYS,
    updateMerchCollectionRequest,
    searchMerchCollectionsResponse,
    drTeethMerchIdentifiers,
    MAX_ITEMS_IN_COLLECTION,
    BundleMap,
} from "../../../models";
import * as services from "../../../service";
import _ from "lodash";
import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import {
    createSuccessOpsMetricsPayload,
    getLocalizedString,
} from "../../../utils";
import { IconsList } from "../../../assets";
import {
    limitReachedModalRenderFunction,
    productAddedConfirmationModalRenderFunction,
} from "../../../view/components";
import { getBundleMap } from "../../selectors/commonSelectors";

export const curateMerchSagas = [watchCurateMerch()];

function* curateMerch(action: Action<curateMerchPayload>) {
    const start = Date.now();
    const functionName = "curateMerch";
    const timerMetric = services.startTimer("curateMerchDuration");
    try {
        const bundleMap: BundleMap = yield select(getBundleMap);
        yield put(merchCurateActions.curateMerchInProgress(true));

        const artistAsin = action.payload.artist.asin;
        if (!artistAsin) {
            return;
        }

        const searchCollectionsResult: searchMerchCollectionsResponse =
            yield services.searchMerchCollections(
                {
                    artistAsin: artistAsin,
                },
                action.payload.teamId
            );
        const collection = searchCollectionsResult.collections.length
            ? searchCollectionsResult.collections[0]
            : undefined;

        //if collectionId is available, we should update that collection with updateCollection
        if (collection) {
            const addAsins = _.map(
                action.payload.set,
                (item: drTeethMerchIdentifiers) => item.itemAsin
            );
            const removeAsins = _.map(
                action.payload.remove,
                (item: drTeethMerchIdentifiers) => item.itemAsin
            );

            // num asins we remove
            const expectedRemoveItemsCount = _.filter(
                collection.items,
                (item: drTeethMerchIdentifiers) =>
                    removeAsins.includes(item.itemAsin)
            ).length;
            // num asins we overwrite
            const overwritingAsinCount = _.filter(
                collection.items,
                (item: drTeethMerchIdentifiers) =>
                    addAsins.includes(item.itemAsin)
            ).length;
            const expectedSize =
                collection.items.length -
                expectedRemoveItemsCount +
                addAsins.length -
                overwritingAsinCount;
            if (expectedSize > MAX_ITEMS_IN_COLLECTION) {
                yield put(
                    globalNotificationsActions.requestModalDisplay(
                        limitReachedModalRenderFunction
                    )
                );
                return;
            }

            const updateCollectionRequest: updateMerchCollectionRequest = {
                artistAsin: artistAsin,
                collectionId: collection.collectionId,
                set: action.payload.set,
                remove: action.payload.remove,
            };

            yield services.updateMerchCollection(
                updateCollectionRequest,
                action.payload.teamId
            );
        } else {
            //if collectionId is not present then make call to createCollectionAPI
            const createCollectionRequest: createMerchCollectionRequest = {
                collectionName: action.payload.artist.title || "",
                artistAsin: artistAsin,
                items: action.payload.set,
                initialListing: {
                    entity: {
                        entityId: action.payload.artist.asin as string,
                        entityType: merchEntityType.ARTIST,
                    },
                    listingType: merchListingType.listingType,
                },
            };

            yield services.createMerchCollection(
                createCollectionRequest,
                action.payload.teamId
            );
        }

        if (action.payload.set.length) {
            yield put(
                globalNotificationsActions.requestModalDisplay(
                    productAddedConfirmationModalRenderFunction
                )
            );
        }

        if (action.payload.remove.length) {
            yield put(
                globalNotificationsActions.requestToastView({
                    toastText: getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId: stringIds.Merch.Curation.ProductRemovedToast,
                    }),
                    icon: IconsList.action_done,
                    id: "ProductRemovedToast",
                })
            );
        }

        //once collection as been updated, call searchCollection to get updated collection
        yield put(
            merchCurateActions.getMerchCollections({
                teamId: action.payload.teamId,
                request: {
                    artistAsin,
                },
                requestPath: action.payload.requestPath,
            })
        );

        services.publishTimer(timerMetric);

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.page, action.payload.requestPath],
        ]);
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
                silent: false,
            })
        );
    } finally {
        yield put(merchCurateActions.curateMerchInProgress(false));
    }
}

function* watchCurateMerch() {
    yield takeEvery(merchCurateActions.curateMerch.type, curateMerch);
}
