import * as React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { buttonIds } from "@amzn/ziggy-asset";
import { merchImages } from "../../../assets";
import {
    clientMetricsActions,
    globalNotificationsActions,
} from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { selectedArtistFromCatalogSelector } from "../../../store/selectors";
import { buildUIClickPayload, MerchLinkBuilders } from "../../../utils";
import * as rootStyles from "../../styles";
import { ImageWithFallback, Icon, IconsList } from "../common";
import { MerchOverflow } from "./MerchOverflow";
import { EntityType, GlobalModal } from "../../../models";
import { AlreadySelectedModal } from "./modals";

export type MerchListItemProps = {
    isLoading?: boolean;
    primaryText?: string;
    secondaryText?: string;
    tertiaryText?: string;
    image?: string;
    itemAsin: string;
    selected?: boolean;
    onSelect?: () => void;
    showCuratedItemFeatures: boolean;
    disabled?: boolean;
    id: string;
    hideCheckbox?: boolean;
    requestPath: string;
    displayAlreadySelectedModal?: () => void;
    pageId: string;
};

export const MerchListItemIdPrefix = "MerchListItemId";

export const MerchListItem: React.FC<MerchListItemProps> = ({
    isLoading,
    primaryText,
    secondaryText,
    tertiaryText,
    image,
    itemAsin,
    selected,
    onSelect,
    showCuratedItemFeatures,
    disabled,
    id,
    hideCheckbox,
    requestPath,
    pageId,
}) => {
    let checkBox;
    if (disabled) {
        checkBox = IconsList.disabledCheckbox;
    } else {
        checkBox = selected
            ? IconsList.ic_action_checkbox
            : IconsList.ic_action_uncheckbox;
    }
    const artist = useSelector((state: RootState) =>
        selectedArtistFromCatalogSelector(state)
    );
    const dispatch = useDispatch();

    const displayAlreadySelectedModal = () => {
        dispatch(
            globalNotificationsActions.requestModalDisplay(
                (dismiss: () => void, isVisible: boolean): GlobalModal => {
                    return (
                        <AlreadySelectedModal
                            onDismiss={dismiss}
                            isVisible={isVisible}
                        />
                    );
                }
            )
        );
    };

    const getTextOnClick = () => {
        //clicking on any curated item should navigate to that item on Amazon Music
        if (artist?.asin && showCuratedItemFeatures && hideCheckbox) {
            return () => {
                dispatch(
                    clientMetricsActions.sendClientMetrics(
                        buildUIClickPayload(
                            buttonIds.Merch.viewItemOnAmazonMusic,
                            pageId,
                            itemAsin,
                            EntityType.Merch
                        )
                    )
                );
                const merchItemLink = MerchLinkBuilders.viewItemOnAmazonMusic(
                    artist.asin as string,
                    itemAsin
                );
                window.open(merchItemLink, "_blank", "noreferrer, noopener");
            };
        } else {
            //clicking on any text on a search item should toggle the checkbox
            if (disabled) {
                return displayAlreadySelectedModal;
            } else {
                return onSelect;
            }
        }
    };
    return (
        <MerchListItemContainer>
            {!hideCheckbox ? (
                <Col xs={{ span: "auto" }} sm={1} style={centerColumnStyle}>
                    <Icon
                        icon={checkBox}
                        size={rootStyles.icons.small}
                        onClick={
                            disabled ? displayAlreadySelectedModal : onSelect
                        }
                        id={`${id}_Checkbox`}
                    />
                </Col>
            ) : null}
            <div style={centerColumnStyle}>
                {isLoading ? (
                    <div
                        style={{
                            ...rootStyles.tableStyles.imageLoading,
                            ...imageStyle,
                        }}
                    />
                ) : (
                    <ImageWithFallback
                        style={{
                            ...imageStyle,
                            backgroundColor: image
                                ? rootStyles.colors.primary
                                : "default",
                            opacity: disabled ? rootStyles.glass._4 : "default",
                        }}
                        source={image}
                        fallback={merchImages.placeholderMerch}
                        id={`${id}_Image`}
                    />
                )}
            </div>
            <Col
                style={{ ...centerColumnStyle, cursor: "pointer" }}
                onClick={getTextOnClick()}
            >
                {isLoading ? (
                    <div style={rootStyles.tableStyles.primaryLoading} />
                ) : (
                    primaryText && (
                        <Row id={MerchListItemIdPrefix + "-Primary"}>
                            <PrimaryTextHoverSpan
                                curated={showCuratedItemFeatures}
                            >
                                {primaryText}
                            </PrimaryTextHoverSpan>
                        </Row>
                    )
                )}
                {secondaryText && (
                    <Row id={MerchListItemIdPrefix + "-Secondary"}>
                        <span
                            style={{
                                ...noWrapEllipsisStyle,
                                ...rootStyles.textStyles.secondary,
                            }}
                        >
                            {secondaryText}
                        </span>
                    </Row>
                )}
                {tertiaryText && !disabled && (
                    <Row id={MerchListItemIdPrefix + "-Tertiary"}>
                        <span
                            style={{
                                ...noWrapEllipsisStyle,
                                ...rootStyles.textStyles.secondary,
                            }}
                        >
                            {tertiaryText}
                        </span>
                    </Row>
                )}
            </Col>
            <Col
                xs={1}
                id={MerchListItemIdPrefix + "-RightIcon"}
                style={endIconStyle}
            >
                <MerchOverflow
                    showCuratedItemOptions={showCuratedItemFeatures}
                    itemName={primaryText || ""}
                    productImage={image}
                    itemAsin={itemAsin}
                    id={`${id}_OverflowMenu`}
                    requestPath={requestPath}
                    additionalButtonStyle={{
                        padding: rootStyles.spacers.mini,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    pageId={pageId}
                />
            </Col>
        </MerchListItemContainer>
    );
};

const noWrapEllipsisStyle: React.CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
};

const centerColumnStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: 0,
};

const endIconStyle: React.CSSProperties = {
    ...centerColumnStyle,
    alignItems: "flex-end",
    paddingLeft: 0,
    paddingRight: 0,
};

const imageStyle: React.CSSProperties = {
    ...rootStyles.imageStyles.mediumSquareImage,
    marginBottom: 0,
    marginTop: 0,
};

const MerchListItemContainer = styled(Row)`
    border-bottom-width: 2px;
    border-bottom-color: ${rootStyles.glassColors.primary2};
    border-bottom-style: solid;
    padding-top: ${rootStyles.spacers.small}px;
    padding-bottom: ${rootStyles.spacers.small}px;
    @media (min-width: 992px) {
        &:hover {
            background-color: ${rootStyles.glassColors.primary2};
        }
    }
`;

const PrimaryTextHoverSpan = styled.span<{ curated: boolean }>`
    ${{ ...noWrapEllipsisStyle }};
    ${{ ...rootStyles.textStyles.primary }};
    @media (min-width: 992px) {
        &:hover {
            color: ${(props) => props.curated && rootStyles.colors.accent};
        }
    }
`;
