const EXPANSION_FACTOR = 30;
const BEGINNING_CHAR = "萬";
const ENDING_CHAR = "國";

const PSEUDO_LOC_MAP: Record<string, string> = {
    a: "àáâãäåāăąǻάαа",
    A: "ÀÁÂÃÄÅĀĂĄǺΆΑА",
    b: "вьъ",
    B: "ΒßβБВ",
    c: "¢çćĉċčсς",
    C: "ÇĆĈĊČС",
    d: "ďđ",
    D: "ÐĎĐ",
    e: "èéêëēĕėęěέεеёє℮",
    E: "ÈÉÊËĒĔĖĘĚΈΕΣЕЁЄЄ",
    f: "ƒ",
    F: "₣",
    g: "ĝğġģ",
    G: "ĜĞĠĢ",
    h: "ĥħнћ",
    H: "ĤĦΉΗН",
    i: "ìíîïĩīĭįίιϊіїΐ",
    I: "ÌÍÎĨĪĬĮİΊΪІЇ",
    j: "ĵј",
    J: "ĴЈ",
    k: "ķĸκкќ",
    K: "ĶΚЌК",
    l: "ĺļľł",
    L: "ĹĻĽĿŁ",
    m: "mм",
    M: "ΜМм",
    n: "ийлпπήηńņňŉŋñ",
    N: "ÑŃŅŇŊΝИЙП",
    o: "òóôõöøōŏőοσόоǿ",
    O: "ÒÓÔÕÖØŌŎŐǾΌΘΟО",
    p: "ρр",
    P: "ΡР",
    q: "q",
    Q: "Q",
    r: "ŕŗřяѓґгř",
    R: "ŔŖŘЯΓЃҐГ",
    s: "śŝşѕš",
    S: "ŚŜŞЅŠ",
    t: "ţťŧτт",
    T: "ŢŤŦΤТ",
    u: "µùúûüũūŭůűųцμџ",
    U: "ÙÚÛÜŨŪŬŮŰŲЏЦ",
    v: "ν",
    V: "V",
    w: "ŵωώшщẁẃẅ",
    W: "ŴШЩẀẂẄ",
    x: "×хж",
    X: "ΧχХЖ",
    y: "ýÿŷγУўỳу",
    Y: "ÝŶΎΥΫỲЎ",
    z: "źżž",
    Z: "ŹŻΖŽ",
};

export function getPseudoLocString(stringValue: string): string {
    const words = stringValue.split(" ");
    let pseudoLocString = "";
    for (let word of words) {
        pseudoLocString += convertStringToPseudoLoc(word);
    }
    return pseudoLocString;
}

function convertStringToPseudoLoc(localeString: string): string {
    if (localeString) {
        // conver ascii to diascritic string
        const diascriticString = Array.from(localeString)
            .map(getRandomCharFromMap)
            .join("");
        const expandedDiascriticString = expand(diascriticString);
        return `⟦${expandedDiascriticString}⟧`;
    }
    return "";
}

function expand(stringToExpand: string): string {
    const expandedStringLength = Math.ceil(
        (stringToExpand.length * EXPANSION_FACTOR) / 100
    );
    return `${BEGINNING_CHAR.repeat(
        expandedStringLength - 1
    )}${stringToExpand}${ENDING_CHAR.repeat(expandedStringLength - 1)}`;
}

function getRandomCharFromMap(char: string): string {
    const randomCharString = PSEUDO_LOC_MAP[char];
    if (randomCharString !== undefined) {
        const maxIndex = randomCharString.length - 1;
        const randomIndex = Math.floor(Math.random() * (maxIndex + 1));
        return randomCharString[randomIndex];
    }
    return char;
}
