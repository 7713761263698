import React from "react";
import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import { getLocalizedString } from "../../../../utils";
import { DeferredActionModal, IconsList } from "../../common";
import { BundleMap } from "../../../../models";

export type LeavePageModalProps = {
    isVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    bundleMap: BundleMap;
};

export class LeavePageModal extends React.Component<LeavePageModalProps> {
    render() {
        return (
            <DeferredActionModal
                isVisible={this.props.isVisible}
                onConfirm={this.props.onConfirm}
                onCancel={this.props.onCancel}
                id={"LeavePageModal"}
                icon={IconsList.alerts_warning}
                title={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.LeavePageModalTitle,
                })}
                description={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.LeavePageModalSubtitle,
                })}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.LeaveButtonCTA,
                })}
                cancelButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.CancelButtonCTA,
                })}
            />
        );
    }
}
