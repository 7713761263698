// CSV types
export type CsvType =
    | "trendlines"
    | "topSongs"
    | "topSongsOnAlbum"
    | "topAlbums"
    | "featuredInPlaylists"
    | "featuredInStations"
    | "fans";

export enum CsvButtonId {
    OverviewTrendlines = "Overview-Trendlines",
    OverviewTopAlbums = "Overview-TopAlbums",
    OverviewTopSongs = "Overview-TopSongs",
    AlbumDetailTrendlines = "AlbumDetail-Trendlines",
    AlbumDetailTopSongs = "AlbumDetail-TopSongs",
    SongDetailTrendlines = "SongDetail-Trendlines",
    SongDetailFeaturedPlaylists = "SongDetail-FeaturedPlaylists",
    SongDetailFeaturedStations = "SongDetail-FeaturedStations",
    TopSongsTopSongs = "TopSongs-TopSongs",
    FansTrendlines = "Fans-Trendlines",
    ProgrammingFeaturedPlaylists = "Programming-FeaturedPlaylists",
    ProgrammingFeaturedStations = "Programming-FeaturedStations",
    TopAlbumsTopAlbums = "TopAlbums-TopAlbums",
    AlbumSeeAllTopSongs = "AlbumSeeAll-TopSongs",
    SongSeeAllFeaturedPlaylists = "SongSeeAll-FeaturedPlaylists",
    SongSeeAllFeaturedStations = "SongSeeAll-FeaturedStations",
    ProgrammingSeeAllFeaturedPlaylists = "ProgrammingSeeAll-FeaturedPlaylists",
    ProgrammingSeeAllFeaturedStations = "ProgrammingSeeAll-FeaturedStations",
}

export type csvButtonLoadingStatusData = { [key in CsvButtonId]: boolean };

export const csvButtonLoadingStatusInitialState: csvButtonLoadingStatusData = {
    "Overview-Trendlines": false,
    "Overview-TopAlbums": false,
    "Overview-TopSongs": false,
    "AlbumDetail-Trendlines": false,
    "AlbumDetail-TopSongs": false,
    "SongDetail-Trendlines": false,
    "SongDetail-FeaturedPlaylists": false,
    "SongDetail-FeaturedStations": false,
    "TopSongs-TopSongs": false,
    "Fans-Trendlines": false,
    "Programming-FeaturedPlaylists": false,
    "Programming-FeaturedStations": false,
    "TopAlbums-TopAlbums": false,
    "AlbumSeeAll-TopSongs": false,
    "SongSeeAll-FeaturedPlaylists": false,
    "SongSeeAll-FeaturedStations": false,
    "ProgrammingSeeAll-FeaturedPlaylists": false,
    "ProgrammingSeeAll-FeaturedStations": false,
};
