import * as React from "react";
import * as rootStyles from "../../styles";
import { extractLinks, TextWithLink } from "../../../utils";

export type TextWithHyperLinkProps = {
    inputText: string;
    testID: string;
};

export const CookieTextWithHyperLink: React.FC<TextWithHyperLinkProps> = ({
    inputText,
    testID,
}) => {
    const textWithLinks: TextWithLink[] = extractLinks(inputText);
    const rows: JSX.Element[] = [];

    for (const textWithLink of textWithLinks) {
        const text = textWithLink.text;
        const link = textWithLink.url;
        if (!link) {
            rows.push(
                <span style={rootStyles.textStyles.primary}>{text}</span>
            );
        } else {
            rows.push(
                <span
                    style={rootStyles.textStyles.linkText}
                    onClick={() => window.open(link, "_blank")}
                    id={`${testID}_${text}_link`}
                >
                    {text}
                </span>
            );
        }
    }

    return <>{rows}</>;
};

export default CookieTextWithHyperLink;
