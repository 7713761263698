import { webLocale } from "./localeType";

export function formatNumber(decNumber: number | string) {
    if (typeof decNumber === "string") {
        return decNumber;
    } else {
        const formattedLocale = webLocale[0].replace("_", "-");
        return new Intl.NumberFormat(formattedLocale).format(decNumber);
    }
}

export function formatShortNumber(datum: number) {
    const formattedLocale = webLocale[0].replace("_", "-");
    return new Intl.NumberFormat(formattedLocale, {
        // @ts-ignore
        notation: "compact",
        compactDisplay: "short",
    }).format(datum);
}
