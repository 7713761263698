import { createGlobalStyle } from "styled-components";

export const CustomScrollbar = createGlobalStyle`
    * {
        scrollbar-color: rgba(0, 0, 0, 0.6) rgb(20, 25, 26);
    }

    /* Works on Chrome/Edge/Safari */

    *::-webkit-scrollbar {
        width: 12px;
    }

    *::-webkit-scrollbar-track {
        background: rgb(20, 25, 26);
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;
