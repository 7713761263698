import { getBundleMap } from "./../commonSelectors";
import { createSelector } from "reselect";
import _ from "lodash";
import { RootState } from "../../reducers";
import {
    getUserInfo,
    getUserLocale,
    selectedArtistFromCatalogSelector,
} from "../commonSelectors";
import { getSelectedTeamSelector } from "../teamManagementSelectors";
import {
    getMerchCatalog,
    getMerchCollection,
    getMerchCollectionsInProgress,
    getMerchCollectionsCompleted,
    hydrateMerchCompleted,
    getHydratingIdentifiers,
} from "./merchCurateCommonSelectors";

export const seeAllMerchScreenSelector = createSelector(
    [
        getMerchCollection,
        getMerchCatalog,
        getHydratingIdentifiers,
        getMerchCollectionsInProgress,
        getMerchCollectionsCompleted,
        hydrateMerchCompleted,
        selectedArtistFromCatalogSelector,
        getSelectedTeamSelector,
        getUserLocale,
        getUserInfo,
        getBundleMap,
    ],
    (
        collection,
        merchCatalog,
        hydratingIdentifiers,
        getMerchCollectionsInProgress,
        getMerchCollectionsCompleted,
        hydrateMerchCompleted,
        artist,
        teamInfo,
        locale,
        signedIn,
        bundleMap
    ) => {
        return {
            collection: collection,
            merchCatalog: merchCatalog,
            hydratingIdentifiers: hydratingIdentifiers,
            getMerchCollectionsInProgress: getMerchCollectionsInProgress,
            getMerchCollectionsCompleted: getMerchCollectionsCompleted,
            hydrateMerchCompleted: hydrateMerchCompleted,
            artist: artist,
            teamId: teamInfo.teamId,
            locale: locale,
            signedIn: signedIn,
            bundleMap: bundleMap,
        };
    }
);
