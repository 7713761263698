// import { Action } from "redux-ts";
import { metricDataPoints } from "../";

export type managedErrorPayload = {
    requestPath: string;
};

export type ErrorPayload = {
    silent?: boolean;
    requestPath?: string;
    shouldRetry?: boolean;
    retryCount?: number;
    retryAction?: any;
    exception?: any;
    eventName?: string;
    dataPoints?: metricDataPoints;
    type?: ErrorType;
    error?: Error;
    uncaught?: boolean;
    dontRefreshOutageStatus?: boolean;
};

export enum ErrorType {
    inline,
    modal,
}
