import { bundleIds, buttonIds, pageIds, stringIds } from "@amzn/ziggy-asset";
import React, { useCallback, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";
import {
    BundleMap,
    clientMetricsPayload,
    IntercomEvent,
} from "../../../models";
import {
    clientMetricsActions,
    oAuthActions,
    userActions,
} from "../../../store";
import { getBundleMap } from "../../../store/selectors/commonSelectors";
import { buildUIClickPayload, getLocalizedString, paths } from "../../../utils";
import {
    ButtonWrapper,
    ClaimHeaderWithBackButton,
    GoBackButton,
    styledTitle,
} from "../../components/";
import * as rootStyles from "../../styles";
import { vendorLabelFlowContainerStyle } from "../../styles/containerStyles";

const testIDPrefix = "ClaimVendorLabelLearnMoreScreen";
const DIVIDER_DIMENSIONS = {
    WIDTH: 120,
    HEIGHT: 1,
};

const ClaimVendorLabelLearnMoreScreen: React.FC<{}> = () => {
    const dispatch: Dispatch<AnyAction> = useDispatch();
    const history = useHistory();
    const bundleMap: BundleMap = useSelector(getBundleMap);

    const sendClientMetrics = useCallback(
        (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        [dispatch]
    );
    const updateCurrentPath = useCallback(
        (payload: string) => dispatch(userActions.updateCurrentPath(payload)),
        [dispatch]
    );
    const setIntercomEvent = (payload: IntercomEvent) =>
        dispatch(oAuthActions.setIntercomEvent(payload));

    useEffect(() => {
        updateCurrentPath(window.location.pathname);
        dispatch(oAuthActions.setHideIntercomLauncher(false));

        return () => {
            dispatch(oAuthActions.setHideIntercomLauncher(true));
        };
    }, []);

    return (
        <Container style={rootContainerStyle}>
            <ClaimHeaderWithBackButton />
            <GoBackButton
                style={{ alignSelf: "flex-start" }}
                onClick={() => history.push(paths.vendorFlow)}
                id={testIDPrefix}
            />
            <div style={vendorLabelFlowContainerStyle}>
                <div style={{ height: rootStyles.spacers.huge }} />
                <styledTitle.h2 style={titleStyle}>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CLAIMVENDORLABELLEARNMORE_STRINGS,
                        stringId: stringIds.ClaimVendorLabelLearnMore.title,
                    })}
                </styledTitle.h2>
                <div style={{ height: rootStyles.spacers.huge }} />
                <span style={primaryTextStyle}>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CLAIMVENDORLABELLEARNMORE_STRINGS,
                        stringId: stringIds.ClaimVendorLabelLearnMore.body,
                    })}
                </span>
                <div style={{ height: rootStyles.spacers.huge }} />
                <span style={secondaryTextStyle}>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CLAIMVENDORLABELLEARNMORE_STRINGS,
                        stringId:
                            stringIds.ClaimVendorLabelLearnMore.areYouALabel,
                    })}
                </span>
                <div style={{ height: rootStyles.spacers.mini }} />
                <ButtonWrapper
                    onClick={() => {
                        setIntercomEvent(
                            IntercomEvent.SHOW_VENDOR_LABEL_CANNOT_ACCESS_DISTRIBUTOR
                        );
                        sendClientMetrics(
                            buildUIClickPayload(
                                buttonIds.ClaimVendorLabelLearnMore
                                    .claimVendorLabelLearnMoreLetUsKnow,
                                pageIds.claimVendorLabelLearnMore
                            )
                        );
                    }}
                    id={`${testIDPrefix}_LetUsKnow`}
                    style={{ padding: 0 }}
                >
                    <span style={clickableTextStyle}>
                        {getLocalizedString(bundleMap, {
                            bundleId:
                                bundleIds.CLAIMVENDORLABELLEARNMORE_STRINGS,
                            stringId:
                                stringIds.ClaimVendorLabelLearnMore.letUsKnow,
                        })}
                    </span>
                </ButtonWrapper>

                <div style={{ height: rootStyles.spacers.huge }} />
                <div style={dividerContainerStyle} />
                <div style={{ height: rootStyles.spacers.huge }} />
                <span style={secondaryTextStyle}>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CLAIMVENDORLABELLEARNMORE_STRINGS,
                        stringId:
                            stringIds.ClaimVendorLabelLearnMore
                                .areYouADistributor,
                    })}
                </span>
                <div style={{ height: rootStyles.spacers.mini }} />
                <ButtonWrapper
                    onClick={() => {
                        setIntercomEvent(
                            IntercomEvent.SHOW_VENDOR_LABEL_CREATE_A_TEAM
                        );
                        sendClientMetrics(
                            buildUIClickPayload(
                                buttonIds.ClaimVendorLabelLearnMore
                                    .claimVendorLabelLearnMoreContactSupport,
                                pageIds.claimVendorLabelLearnMore
                            )
                        );
                    }}
                    id={`${testIDPrefix}_ContactSupport`}
                    style={{ padding: 0 }}
                >
                    <span style={clickableTextStyle}>
                        {getLocalizedString(bundleMap, {
                            bundleId:
                                bundleIds.CLAIMVENDORLABELLEARNMORE_STRINGS,
                            stringId:
                                stringIds.ClaimVendorLabelLearnMore
                                    .contactSupport,
                        })}
                    </span>
                </ButtonWrapper>
            </div>
        </Container>
    );
};

const rootContainerStyle: React.CSSProperties = {
    ...rootStyles.containerStyles.rootViewContainer,
    margin: "auto",
    display: "flex",
    paddingLeft: rootStyles.spacers.base,
    paddingRight: rootStyles.spacers.base,
    alignItems: "center",
};

const titleStyle: React.CSSProperties = {
    textAlign: "center",
};
const primaryTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    textAlign: "center",
};
const secondaryTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    textAlign: "center",
};
const dividerContainerStyle: React.CSSProperties = {
    width: DIVIDER_DIMENSIONS.WIDTH,
    height: DIVIDER_DIMENSIONS.HEIGHT,
    backgroundColor: rootStyles.colors.primary,
    opacity: rootStyles.glass._4,
};
const clickableTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondaryClickableText,
    textAlign: "center",
};

export default ClaimVendorLabelLearnMoreScreen;
