import { Action } from "redux-ts";
import _ from "lodash";
import { opsMetricsActions } from "../actions";
import { OpsMetricDeviceInfo, SingleOpsMetricInfo } from "../../models";
import { MAX_OPS_METRIC_LOG_SIZE } from "../../utils";

export type OpsMetricsState = Readonly<{
    opsMetricsQueue: SingleOpsMetricInfo[];
    deviceInfo?: OpsMetricDeviceInfo;
}>;

const initialState: OpsMetricsState = {
    opsMetricsQueue: [],
};

export const opsMetricsReducer = (
    state: OpsMetricsState = initialState,
    action: Action
): OpsMetricsState => {
    switch (action.type) {
        case opsMetricsActions.addOpsMetric.type:
            const opsMetric: SingleOpsMetricInfo = action.payload;
            const metricSize = JSON.stringify(opsMetric).length;
            if (metricSize > MAX_OPS_METRIC_LOG_SIZE) {
                const amountToTruncate = metricSize - MAX_OPS_METRIC_LOG_SIZE;
                if (opsMetric.stackTrace) {
                    const truncatedStackTrace: string =
                        opsMetric.stackTrace.slice(
                            0,
                            opsMetric.stackTrace.length - amountToTruncate
                        );
                    opsMetric.stackTrace = truncatedStackTrace;
                }
            }
            return {
                ...state,
                opsMetricsQueue: _.concat(state.opsMetricsQueue, opsMetric),
            };
        case opsMetricsActions.clearOpsMetricQueue.type:
            const metricsQueue = state.opsMetricsQueue;
            metricsQueue.splice(0, action.payload);
            return {
                ...state,
                opsMetricsQueue: metricsQueue,
            };
        case opsMetricsActions.setOpsMetricsDeviceInfo.type:
            return {
                ...state,
                deviceInfo: action.payload,
            };
        default:
            return state;
    }
};
