import { colors, glassColors } from "./colors";

export function navBarStyles(bannerHeight: number) {
    const burgerButtonTop = 16 + bannerHeight;

    return {
        bmMenu: {
            boxShadow: "rgb(0, 0, 0, 0.3) 2px 0px 5px",
            background: `linear-gradient(${colors.secondary}, ${colors.background} )`,
            paddingTop: "24px",
        },
        bmBurgerButton: {
            position: "fixed",
            width: "42px",
            height: "42px",
            left: "16px",
            top: `${burgerButtonTop}px`,
            background: glassColors.primary3,
            justifyContent: "center",
            flex: "0",
            alignItems: "center",
            display: "flex",
            borderRadius: "50%",
        },
        bmCrossButton: {
            width: "48px",
            height: "48px",
            background: glassColors.primary2,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderRadius: "50%",
            position: "absolute",
            top: "16px",
            left: "260px",
        },
        bmItemList: {
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
        },
        bmItem: {
            display: "inline-block",
            outline: "none",
        },
    }
};
