/* tslint:disable */
import React from "react";

export const terms_frFR = (
    <div>
        <p className="p1">
            <span className="s1">
                <b>
                    Amazon Music for Artists Conditions d’utilisation
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b>Dernière mise à jour le 19 février 2022</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1">
                Les présentes constituent un accord entre vous et Amazon.com
                Services LLC (avec ses sociétés affiliés, « Amazon », « nous »
                ou « nos ») qui régit votre utilisation du service Amazon Music
                for Artists (« Amazon Music for Artists »). Avant d’utiliser
                Amazon Music for Artists, veuillez lire les présentes Conditions
                d’utilisation, toutes les règles et politiques associées (y
                compris l’ensemble des règles, restrictions d’utilisation et
                autres conditions ou procédures spécifiques que nous publions ou
                mettons à jour sur ou via Amazon Music for Artists ou sur
                n’importe quelle page d’aide ou autre page d’information
                relative à Amazon Music for Artists), l'
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&amp;view-type=content-only">
                    <span className="s3">Avis de confidentialité</span>
                </a>
                , les{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&amp;nodeId=508088">
                    <span className="s3">Conditions d’utilisation</span>
                </a>{" "}
                et les{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050&amp;view-type=content-only">
                    <span className="s3">
                        {" "}
                        Publicités basées sur les centres d’intérêt
                    </span>
                </a>{" "}
                (collectivement, le « Contrat »). Le Contrat prend effet lorsque
                vous cliquez sur un bouton « J’accepte » ou sur une case à
                cocher figurant dans les présentes Conditions d’utilisation.
                Vous nous déclarez que vous êtes légalement en mesure de
                conclure des contrats (par exemple, vous n’êtes pas mineur). Si
                vous concluez le présent Contrat au nom d’une personne physique
                ou morale, telle que l’entreprise pour laquelle vous travaillez
                ou l’artiste que vous représentez, vous nous déclarez que vous
                avez l’autorisation légale d’engager cette personne physique ou
                morale.<span className="Apple-converted-space"> </span>
            </span>
        </p>{" "}
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">1.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b> Description générale. </b>Amazon Music for Artists comprend
                une variété d’outils et de fonctionnalités qui permettent aux
                artistes et aux représentants d’artistes autorisés d’obtenir des
                informations sur les fans des artistes et d’interagir avec leurs
                fans sur les services fournis par Amazon Music (les « Services
                »).<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">2.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Éligibilité</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                Pour utiliser Amazon Music for Artists, vous devez posséder un
                compte Amazon et être a) un artiste ou un représentant autorisé
                d’un artiste dont le contenu est fourni à Amazon pour
                distribution sur les Services ou b) un représentant autorisé
                d’une personne morale qui a signé un accord en vigueur avec
                Amazon pour fournir du contenu à Amazon pour distribution sur
                les Services.<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                Vous ne pouvez pas utiliser une fausse adresse e-mail, usurper
                l’identité de toute personne physique ou morale, ou fournir
                autrement des informations fausses ou trompeuses, y compris
                toute information fournie lors de la création d’un compte ou
                fournie quant à la propriété de tout Contenu de l’artiste.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">2.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                Vous ne devez pas partager votre nom d’utilisateur et votre mot
                de passe Amazon avec d’autres personnes ou utiliser le nom
                d’utilisateur et le mot de passe Amazon de quelqu’un d’autre.
                Cependant, vous pouvez autoriser d’autres personnes que vous
                avez approuvées à afficher vos Informations sur les fans dans
                leurs propres comptes Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p8">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.4.</span>
            <span className="s4"> </span>
            <span className="s1">
                Vous êtes responsable de toutes les communications et activités
                qui se produisent en relation avec Amazon Music for Artists
                provenant de votre ordinateur ou de vos installations
                informatiques, y compris votre compte de messagerie et votre mot
                de passe.
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.5.</span>
            <span className="s4"> </span>
            <span className="s1">
                Nous pouvons être amenés à partager vos coordonnées (y compris
                le nom, l’adresse e-mail et les noms d’utilisateur sur les
                réseaux sociaux) avec des tiers à des fins de vérification et
                d’éligibilité et avec d’autres personnes accédant à vos
                Informations sur les fans.
            </span>
            <span className="s5">
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">3.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Informations sur les fans</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                Nous pouvons vous fournir un accès aux données relatives à la
                distribution de votre contenu sur les Services (les «
                Informations sur les fans »). Vous ne pouvez utiliser les
                Informations sur les fans que pour une évaluation et une analyse
                en interne et vous ne pouvez pas les partager avec un tiers, à
                moins que ce tiers n’accepte les présentes Conditions
                d’utilisation. Cependant, Amazon peut vous fournir certaines
                informations (par exemple les jalons que vous avez atteints dans
                les Services) via des « cartes de partage » que vous pouvez
                rendre publiques.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                Les Informations sur les fans vous sont fournies uniquement à
                titre d’aide et nous ne garantissons pas leur exactitude. À ce
                titre, elles ne doivent pas être invoquées pour calculer les
                frais de licence ou autres montants qui vous sont dus à la suite
                de la distribution de votre contenu sur les Services.{" "}
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                Vous vous interdisez d’utiliser les Informations sur les fans
                pour tenter d’obtenir des données plus détaillées que les
                données fournies (par exemple pour obtenir des informations
                personnelles sur un utilisateur final des Services).
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">4.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    Droits<span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Contenu de l’artiste. </b>Vous pouvez utiliser Amazon Music
                for Artists pour envoyer des supports (par exemple des images et
                des œuvres) que vous possédez ou que vous êtes autrement
                autorisé à envoyer (le « Contenu de l’artiste »). Vous ne pouvez
                pas envoyer du Contenu d’artiste qui est illégal, obscène,
                menaçant, diffamatoire ou qui contient autrement du contenu
                répréhensible ou inapproprié, ni envoyer du Contenu d’artiste
                qui a) porte atteinte aux droits d’un tiers, ou enfreint les
                droits d’un tiers, y compris la confidentialité, la protection
                de la personnalité, les droits d’auteur, les marques de
                commerce, les brevets ou autres droits de propriété
                intellectuelle ou b) nous obligeraient à payer des redevances ou
                tout autre frais à un tiers pour l’utilisation du Contenu de
                l’artiste tel qu’autorisé par le présent Contrat. Vous êtes
                responsable de, et acceptez de payer tous les frais dus à
                l’égard du Contenu de l’artiste. Un envoi à Amazon Music for
                Artists ne modifie en rien le fait que vous êtes le propriétaire
                du Contenu de l’artiste.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p9">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Octroi de licence. </b>Vous nous accordez un droit et une
                licence non exclusifs, cessibles en sous-licence, perpétuels,
                irrévocables et libres de droits, dans le monde entier, pour
                utiliser, reproduire, distribuer, transmettre, exécuter,
                modifier et afficher tout le Contenu de l’artiste dans tous les
                médias et formats technologiques en rapport avec les Services et
                Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s5">4.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Suggestions. </b>
            </span>
            <span className="s1">
                Si vous faites des suggestions d’améliorations à Amazon Music
                for Artists (les « Suggestions »), nous serons en droit
                d’utiliser les Suggestions sans restriction. Par les présentes,
                vous nous cédez de manière irrévocable tous les droits, titres
                et intérêts relatifs aux Suggestions et acceptez de nous
                apporter toute l’aide dont nous pouvons avoir besoin pour
                documenter, perfectionner et conserver nos droits sur les
                Suggestions.
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">5.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Sécurité et confidentialité des données</b>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Interdiction d’ingénierie inverse ou de contournement. </b>
            </span>
            <span className="s1">
                Vous n’êtes pas autorisé, et vous n’encouragerez, n’aiderez ou
                n’autoriserez quiconque à, modifier, effectuer une ingénierie
                inverse, contourner, décompiler, désassembler, duper ou altérer
                d’une autre manière une partie d’Amazon Music for Artists.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Interdiction de collecte de données en masse. </b>Vous n’êtes
                pas autorisé, et vous n’encouragerez, n’aiderez ou n’autoriserez
                quiconque à « fouiller » Amazon Music for Artists ou à utiliser
                d’une autre manière des outils d’extraction, de récupération ou
                de recueil de données pour collecter des informations auprès
                d’Amazon.
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">6.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    Modifications et suspension
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">6.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Modifications. </b>
            </span>
            <span className="s1">
                Nous pouvons être amenés à modifier, suspendre ou interrompre
                Amazon Music for Artists, ou une partie de ce service, à tout
                moment. De temps à autre, nous pouvons supprimer des
                fonctionnalités d’Amazon Music for Artists ou en ajouter. Dans
                certaines circonstances exceptionnelles, nous pourrions ne plus
                être en mesure de stocker ou de vous donner accès à des
                fonctionnalités particulières ou à des Informations sur les
                fans.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">6.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Suspension et résiliation par Nous.</b>
            </span>
            <span className="s1">
                Vos droits en vertu du Contrat prendront automatiquement fin
                sans préavis si vous ne respectez pas ses conditions. Nous
                pouvons résilier le Contrat ou restreindre, suspendre ou mettre
                fin à votre utilisation d’Amazon Music for Artists à tout
                moment, y compris si nous estimons que votre utilisation
                enfreint le Contrat, est inappropriée, outrepasse
                considérablement ou diffère d’une utilisation normale par
                d’autres utilisateurs, implique une fraude ou une utilisation
                abusive d’Amazon Music for Artists, ou nuit à nos intérêts ou à
                ceux d’un autre utilisateur d’Amazon Music for Artists. Vous
                pouvez résilier le Contrat pour quelque raison que ce soit en
                fermant votre compte. Si votre accès à Amazon Music for Artists
                est restreint, suspendu ou résilié, vous ne pourrez peut-être
                pas accéder aux Informations sur les fans. Suite à une
                suspension ou une résiliation, nous conserverons les droits sur
                le Contenu de l’artiste qui nous sont accordés en vertu du
                Contrat.
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">7.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Autorisation et indemnités. </b>Vous déclarez et garantissez
                que : a) vous détenez tous les droits nécessaires pour nous
                conférer les droits accordés dans le Contrat ; b) les supports
                que vous envoyez et notre utilisation de ceux-ci telle
                qu’autorisée par le Contrat i) ne violeront pas et
                n’enfreindront pas les droits au respect de la vie privée, les
                droits à la protection de la personnalité, les droits de
                propriété intellectuelle, ou d’autres droits de tiers, ii) ne
                sont pas diffamatoires, illégaux ou obscènes, et iii)
                n’impliqueront pas que nous devions payer des redevances ou
                autres frais à un tiers pour l’utilisation de ces supports ; et
                c) toutes les déclarations factuelles que vous envoyez sont
                exactes et non trompeuses. Vous acceptez de couvrir, défendre et
                dégager de toute responsabilité Amazon et ses administrateurs,
                dirigeants, employés, mandataires et ayants droit en cas de
                réclamations, dettes, dommages-intérêts et dépenses, comprenant
                les frais de justice et honoraires d’avocat raisonnables,
                découlant de, ou en relation avec les supports que vous envoyez
                ou votre manquement au Contrat ou à vos déclarations ou
                garanties, ou résultant de votre utilisation inappropriée, non
                autorisée ou illégale d’Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">8.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Généralités</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">8.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Modifications. </b>
            </span>
            <span className="s1">
                Nous pouvons modifier le Contrat à tout moment en publiant les
                conditions révisées sur ou via Amazon Music for Artists. Votre
                utilisation continue d’Amazon Music for Artists après une
                modification prouve que vous acceptez d’être lié par celui-ci.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">8.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Notifications. </b>
            </span>
            <span className="s1">
                Nous pouvons communiquer avec vous dans le cadre de votre
                utilisation d’Amazon Music for Artists par voie électronique et
                dans d’autres médias, et vous consentez à ces communications
                indépendamment de toute communication client ou des préférences
                ou demandes similaires que vous avez pu indiquer sur le site
                Internet d’Amazon.com ou par tout autre biais.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Déclarations concernant les droits d’auteur. </b>
            </span>
            <span className="s1">
                Si vous pensez que votre œuvre a été copiée d’une manière
                constituant une violation du droit d’auteur, veuillez suivre
                l’Avis et la Procédure de réclamation en cas de violation du
                droit d’auteur figurant dans les{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909000&amp;view-type=content-only">
                    <span className="s8">Conditions d’utilisation</span>
                </a>
                .
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.4.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Intégralité du Contrat ; Divisiblité. </b>
            </span>
            <span className="s1">
                Le Contrat constitue l’intégralité de l’accord entre vous et
                Amazon et régit votre utilisation d’Amazon Music for Artists ;
                il annule et remplace tout accord antérieur entre vous et
                Amazon. Si une disposition du Contrat est réputée non valide,
                nulle, ou inapplicable pour quelque motif que ce soit, cette
                disposition sera réputée séparable et n’affectera pas la
                validité et l’applicabilité des autres dispositions ou
                conditions.
            </span>
        </p>
    </div>
);
