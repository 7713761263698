import axios from "axios";
import { userInfo, settings, registerUserResponse, optOut } from "../../models";
import { getEndpoints, testHeaders } from "../common";

export const privilegedTeamId = "super-user-team";
const getRegisterUrl = (baseUrl: string) =>
    `${baseUrl}/usermanagement/register`;
const getUpdateSettingsUrl = (baseUrl: string) =>
    `${baseUrl}/usermanagement/setUserSetting`;
const getOptOutMarketingEmailsUrl = (baseUrl: string) =>
    `${baseUrl}/usermanagement/optOut`;
const baseUrls = getEndpoints();

export async function registerUser(): Promise<registerUserResponse> {
    const http = axios.create();

    const result = await http.post(
        getRegisterUrl(baseUrls.userManagement),
        {},
        {
            headers: {
                ...testHeaders(),
            },
            withCredentials: true,
        }
    );

    return result.data;
}

export async function updateSetting(settings: settings): Promise<void> {
    const result = await axios.post(
        getUpdateSettingsUrl(baseUrls.userManagement),
        {
            userSettings: settings,
        }
    );

    return result.data;
}

export async function optOutMarketingEmails(optOut: optOut): Promise<void> {
    const http = axios.create();

    const result = await http.post(
        getOptOutMarketingEmailsUrl(baseUrls.userManagement),
        {
            directedId: optOut.id,
        }
    );

    return result.data;
}
