import * as React from "react";
import { Icon, IconsList } from "../icons";
import * as rootStyles from "../../../styles";
import { Card, Row } from "react-bootstrap";

export type InlineErrorProps = {
    text: string;
    retryAction?: () => void;
    onDismiss?: () => void;
    sticky?: boolean;
    id: string;
};

export class InlineError extends React.Component<InlineErrorProps> {
    render() {
        let icon;
        let action;
        if (!this.props.sticky) {
            action = this.props.retryAction
                ? this.props.retryAction
                : this.props.onDismiss;
            icon = (
                <Icon
                    size={16}
                    icon={
                        this.props.retryAction
                            ? IconsList.navigation_refresh
                            : IconsList.action_cancelInline
                    }
                    id={`${this.props.id}_Retry`}
                />
            );
        }
        return (
            <Card style={headerErrorContainer} onClick={action}>
                <Row id={this.props.id + "-ErrorText"} style={innerErrorStyle}>
                    <span style={textStyle}>{this.props.text}</span>
                    <div style={iconStyle}>{icon}</div>
                </Row>
            </Card>
        );
    }
}

const innerErrorStyle: React.CSSProperties = {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
};
const textStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "center",
    ...rootStyles.textStyles.primary,
};
const iconStyle: React.CSSProperties = {
    alignSelf: "center",
    flex: 0,
};
const headerErrorContainer: React.CSSProperties = {
    ...rootStyles.errorStyles.errorContainer,
    height: 75,
    maxHeight: 75,
};
