import React from "react";
import { connect } from "react-redux";
import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import { getLocalizedString } from "../../../../utils";
import { FullScreenConfirmationModal, IconsList } from "../../common";
import { RootState } from "../../../../store";
import { bundleMapSelector } from "../../../../store/selectors/commonSelectors";
import { BundleMap } from "../../../../models";

export type AlreadySelectedModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = StateProps & AlreadySelectedModalProps;
class AlreadySelectedModalClass extends React.Component<Props> {
    render() {
        return (
            <FullScreenConfirmationModal
                isVisible={this.props.isVisible}
                confirmButtonAction={this.props.onDismiss}
                onDismiss={this.props.onDismiss}
                id={"AlreadySelectedModal"}
                icon={IconsList.alerts_Information}
                text={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId:
                        stringIds.Merch.Curation.ProductAlreadyAddedModalTitle,
                })}
                description={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId:
                        stringIds.Merch.Curation
                            .ProductAlreadyAddedModalSubtitle,
                })}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.GotItButtonCTA,
                })}
            />
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

export const AlreadySelectedModal = connect(mapStateToProps)(
    AlreadySelectedModalClass
);
