import axios, { AxiosResponse } from "axios";
import {
    QueryType,
    artistSearchQueryPayload,
    trackAlbumSearchQueryPayload,
    generateShareLinkPayload,
    EntityType,
    RecentlyAddedToPlaylistResponse,
    ArtistMarqueePlaylists,
    getMarqueePlaylistsPayload,
    generatePromoCardShareLinkPayload,
} from "../models";
import { getEndpoints, testHeaders } from "./common";

const catalogProxyUrl = getEndpoints().catalog;
const artistEndpoint = `${catalogProxyUrl}/hydrate/artist`;
const albumEndpoint = `${catalogProxyUrl}/hydrate/album`;
const trackEndpoint = `${catalogProxyUrl}/hydrate/track`;
const albumTitleSetEndpoint = `${catalogProxyUrl}/hydrate/titleSetAlbum`;
const trackTitleSetEndpoint = `${catalogProxyUrl}/hydrate/titleSetTrack`;
const playlistEndpoint = `${catalogProxyUrl}/hydrate/playlist`;
const stationEndpoint = `${catalogProxyUrl}/hydrate/station`;
const titleSetEndpoint = `${catalogProxyUrl}/hydrate/titleSet`;
const artistSearchEndpoint = `${catalogProxyUrl}/hydrate/artistSearch`;
const recentlyAddedToPlaylistEndpoint = `${catalogProxyUrl}/hydrate/playlist/recentlyAdded`;
const trackAlbumSearchEndpoint = `${catalogProxyUrl}/hydrate/trackAlbumSearch`;
const generateAlbumShareLinkEndpoint = `${albumEndpoint}/generateShareLink`;
const generateTrackShareLinkEndpoint = `${trackEndpoint}/generateShareLink`;
const getMarqueePlaylistsEndpoint = `${artistEndpoint}/getMarqueePlaylists`;
const generatePromoCardShareLinkEndpoint = `${artistEndpoint}/promoCard/generateShareLink`;

export async function hydrateAsins(
    asins: string[],
    teamId: string,
    queryType: QueryType,
    locale?: string
) {
    let result;
    switch (queryType) {
        case QueryType.Artists: {
            const start = Date.now();
            result = await hydrateProxy(asins, artistEndpoint, teamId);
            console.log(
                "Done Hydration Proxy (Artists): " + `${Date.now() - start} ms`
            );
            break;
        }
        case QueryType.Tracks: {
            const start = Date.now();
            result = await hydrateProxy(asins, trackEndpoint, teamId, locale);
            console.log(
                "Done Hydration Proxy (Tracks): " + `${Date.now() - start} ms`
            );
            break;
        }
        case QueryType.Albums: {
            const start = Date.now();
            result = await hydrateProxy(asins, albumEndpoint, teamId, locale);
            console.log(
                "Done Hydration Proxy (Albums): " + `${Date.now() - start} ms`
            );
            break;
        }
        case QueryType.Playlists: {
            const start = Date.now();
            result = await hydrateProxy(
                asins,
                playlistEndpoint,
                teamId,
                locale
            );
            console.log(
                "Done Hydration Proxy (Playlists): " +
                    `${Date.now() - start} ms`
            );
            break;
        }
        case QueryType.Stations: {
            const start = Date.now();
            result = await hydrateProxy(asins, stationEndpoint, teamId, locale);
            console.log(
                "Done Hydration Proxy (Stations): " + `${Date.now() - start} ms`
            );
            break;
        }
        case QueryType.AlbumTitleSets: {
            const start = Date.now();
            result = await hydrateProxy(
                asins,
                albumTitleSetEndpoint,
                teamId,
                locale
            );
            console.log(
                "Done Hydration Proxy (AlbumTitleSets): " +
                    `${Date.now() - start} ms`
            );
            break;
        }
        case QueryType.TrackTitleSets: {
            const start = Date.now();
            result = await hydrateProxy(
                asins,
                trackTitleSetEndpoint,
                teamId,
                locale
            );
            console.log(
                "Done Hydration Proxy (TrackTitleSets): " +
                    `${Date.now() - start} ms`
            );
            break;
        }
        case QueryType.TitleSets: {
            const start = Date.now();
            result = await hydrateProxy(
                asins,
                titleSetEndpoint,
                teamId,
                locale
            );
            console.log(
                "Done Hydration Proxy (TitleSets): " +
                    `${Date.now() - start} ms`
            );
            break;
        }
    }

    return result ? result.data : undefined;
}

export async function artistSearchProxy(payload: artistSearchQueryPayload) {
    if (!payload.query || payload.query.length < 2) {
        return {
            results: [],
        };
    }

    const result = await axios.post(
        artistSearchEndpoint,
        {
            artistSearchString: payload.query,
            deviceId: "",
            deviceType: "A16P06TZYJE5VL",
            musicTerritory: payload.musicTerritory,
        },
        {
            headers: {
                ...testHeaders(),
            },
            withCredentials: true,
        }
    );

    return result.data;
}

export async function trackAlbumSearchProxy(
    payload: trackAlbumSearchQueryPayload
) {
    if (!payload.query || payload.query.length < 2) {
        return {
            results: [],
        };
    }

    const result = await axios.post(
        trackAlbumSearchEndpoint,
        {
            searchString: payload.query,
            deviceId: "",
            deviceType: "A16P06TZYJE5VL",
            artistAsin: payload.artistAsin,
        },
        {
            headers: {
                ...testHeaders(),
            },
            withCredentials: true,
        }
    );

    return result.data;
}

async function hydrateProxy(
    asins: string[],
    uri: string,
    teamId: string,
    locale?: string
) {
    return axios.post(
        uri,
        {
            asins: asins,
            locale: locale,
        },
        {
            headers: {
                "x-team-id": teamId,
                ...testHeaders(),
            },
        }
    );
}

export async function fetchRecentlyAddedToPlaylist(
    artistAsin: string,
    teamId: string
): Promise<RecentlyAddedToPlaylistResponse> {
    const result: AxiosResponse<RecentlyAddedToPlaylistResponse> =
        await axios.post(
            recentlyAddedToPlaylistEndpoint,
            {
                artistAsin,
            },
            {
                headers: {
                    "x-team-id": teamId,
                    ...testHeaders(),
                },
            }
        );

    return result.data;
}

export async function getMarqueePlaylists(
    payload: getMarqueePlaylistsPayload
): Promise<ArtistMarqueePlaylists> {
    const result: AxiosResponse<ArtistMarqueePlaylists> = await axios.post(
        getMarqueePlaylistsEndpoint,
        {
            artistAsin: payload.artistAsin,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function generatePromoCardShareLink(
    payload: generatePromoCardShareLinkPayload
): Promise<string> {
    const result: AxiosResponse<string> = await axios.post(
        generatePromoCardShareLinkEndpoint,
        {
            artistAsin: payload.artistAsin,
            language: payload.language,
            contentType: payload.contentType,
            landscapeType: payload.landscapeType,
            contentAsin: payload.contentAsin,
            contentContainerAsin: payload.contentContainerAsin,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function generateShareLink(payload: generateShareLinkPayload) {
    if (
        !payload.artistAsin ||
        !payload.titleSetAsin ||
        !payload.teamId ||
        !payload.contentType
    ) {
        return undefined;
    }

    let endpoint = "";
    switch (payload.contentType) {
        case EntityType.Album:
            endpoint = generateAlbumShareLinkEndpoint;
            break;
        case EntityType.Track:
            endpoint = generateTrackShareLinkEndpoint;
            break;
        default:
            return undefined;
    }

    const result = await axios.post(
        endpoint,
        {
            artistAsin: payload.artistAsin,
            titleSetAsin: payload.titleSetAsin,
        },
        {
            headers: {
                ...testHeaders(),
                "x-team-id": payload.teamId,
            },
            withCredentials: true,
        }
    );

    return result.data;
}
