import {
    browserName,
    browserVersion,
    deviceType,
    getUA,
    osVersion,
    osName,
    mobileVendor,
    mobileModel,
    engineVersion,
    engineName,
    fullBrowserVersion,
    isFirefox
} from "react-device-detect";
/**
 * Wrapper around the react-device-detect library selectors
 * NPM Page: https://www.npmjs.com/package/react-device-detect
 * Selectors: https://github.com/duskload/react-device-detect/blob/HEAD/docs/selectors.md
 */

const getUserAgent = (): string => {
    return getUA;
};

const getBrowserName = (): string => {
    return browserName;
};

const getBrowserMajorVersion = (): string => {
    return browserVersion;
};

const getBrowserFullVersion = (): string => {
    return fullBrowserVersion;
};

const getDeviceType = (): string => {
    return deviceType;
};

const getOSName = (): string => {
    return osName;
};

const getOSVersion = (): string => {
    return osVersion;
}

const getMobileVendor = (): string => {
    return mobileVendor;
};

const getMobileModel = (): string => {
    return mobileModel;
};

const getEngineName = (): string => {
    return engineName;
};

const getEngineVersion = (): string => {
    return engineVersion;
};

const isBrowserFirefox = (): boolean => {
    return isFirefox;
}

export const DeviceUtil = {
    getUserAgent,
    getBrowserName,
    getBrowserMajorVersion,
    getBrowserFullVersion,
    getDeviceType,
    getOSName,
    getOSVersion,
    getMobileVendor,
    getMobileModel,
    getEngineName,
    getEngineVersion,
    isBrowserFirefox,
};
