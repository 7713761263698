export interface TextWithLink {
    text: string;
    url: string;
}

// This function is used for extract cookie modal text with hyper link
// Text needs to be inserted with links are wrapped with <a> tags with href attributes
// Sample input text: This is a <a href="https://www.somelink.com">sample url</a>
export function extractLinks(text: string): TextWithLink[] {
    // Regex to find strings wrapped in a tags with href attribute
    const regex = /<a href='([^']*)'>([^<]*)<\/a>/g;
    let matches;
    matches = findAllMatches(regex, text, matches);
    const links: TextWithLink[] = [];

    let lastIndex = 0;
    for (const match of matches) {
        const [, url, linkText] = match;
        const nonLinkText = text.substring(lastIndex, match.index);
        links.push({ text: nonLinkText, url: "" }, { text: linkText, url });
        if (match.index) {
            lastIndex = match.index + match[0].length;
        }
    }

    const remainingText = text.substring(lastIndex);
    if (remainingText.length > 0) {
        links.push({ text: remainingText, url: "" });
    }

    return links.filter((link) => link.text !== "");
}

export function findAllMatches(
    regex: RegExp,
    str: string,
    matches: RegExpExecArray[] = []
) {
    const res = regex.exec(str);
    if (res) {
        matches.push(res);
        findAllMatches(regex, str, matches);
    }
    return matches;
}
