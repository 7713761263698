import * as rootStyles from "../../../view/styles";

export const chartContainer: React.CSSProperties = {
    flex: 1,
    alignSelf: "stretch",
    alignItems: "stretch",
    alignContent: "stretch",
    padding: rootStyles.spacers.large,
    backgroundColor: rootStyles.generateColor(
        rootStyles.colors.primary,
        rootStyles.glass._1
    ),
    borderRadius: rootStyles.spacers.base,
    height: `calc(100% - ${rootStyles.spacers.small * 2}px)`,
    marginTop: rootStyles.spacers.small,
    marginBottom: rootStyles.spacers.small,
    borderWidth: 0,
};
export const label: React.CSSProperties = {
    flex: 1,
    height: 25,
    flexDirection: "row",
    alignSelf: "flex-start",
    justifyContent: "flex-start",
};
export const graphLabel: React.CSSProperties = {
    flex: 1,
    height: 25,
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
};
export const circle: React.CSSProperties = {
    borderRadius: 25,
    height: 14,
    width: 14,
    marginLeft: 5,
    marginRight: rootStyles.spacers.micro,
};
export const labelContainer: React.CSSProperties = {
    flexDirection: "row",
    marginBottom: 10,
    alignSelf: "stretch",
    alignContent: "flex-start",
    alignItems: "flex-start",
};
