import React from "react";
import _ from "lodash";
import { colors, generateColor, glass } from "../../styles/colors";
import * as rootStyles from "../../../view/styles";
import * as containerStyles from "./containerStyles";

// This is not an actual StyleSheet style, but set of style properties.
export const chartStyles = {
    tickLabels: {
        ...rootStyles.textStyles.label,
        lineWeight: 1,
        fill: colors.primary,
    },
    labelStyle: {
        ...rootStyles.textStyles.label,
        fill: colors.primary,
    },
    columnLabelStyle: {
        ...rootStyles.textStyles.label,
        position: "absolute",
        right: 30,
    },
    barChart: {
        chartWidth: rootStyles.breakpoints.sm,
        barWidth: 20,
        barPadding: 10,
        strokeWidth: 0,
        labelOffset: 0,
        defaultPadding: 50,
    },
    lineChart: {
        chartHeight: 250,
        width: 30,
        strokeWidth: 0,
        labelOffset: 30,
        areaStyle: {
            fillOpacity: glass._4,
            strokeWidth: 2,
        },
    },
    pieChart: {
        chartWidth: rootStyles.breakpoints.sm,
        strokeWidth: 0,
        labelOffset: 0,
        innerRadius: 85,
        width: 275,
        height: 225,
    },
};

export const calculateOpacity = (val: number, max: number) => {
    const c = val / max;
    if (c > 0.75) {
        return glass._6;
    }
    if (c > 0.5) {
        return glass._5;
    }
    if (c > 0.25) {
        return glass._4;
    }
    return glass._3;
};

export const generateLabel = (label: string, _color: string) => {
    return (
        <div key={`label_${label}`} style={containerStyles.chartContainer}>
            <span style={rootStyles.textStyles.tertiary}>{label}</span>
        </div>
    );
};

export const generateFanLabel = (label: string, color: string) => {
    return (
        <div key={`label_${label}`} style={containerStyles.graphLabel}>
            <div
                style={{ ...containerStyles.circle, backgroundColor: color }}
            />
            <span style={rootStyles.textStyles.tertiary}>{label}</span>
        </div>
    );
};
