/* tslint:disable */
import React from "react";

export const terms_deDE = (
    <div>
        <p className="p1">
            <span className="s1">
                <b>
                    Amazon Music for Artists Nutzungsbedingungen
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b>Letzte Aktualisierung: 19 Februar 2022</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1">
                Dies ist eine Vereinbarung zwischen Ihnen und Amazon.com
                Services LLC (mit seinen verbundenen Unternehmen "Amazon", "wir"
                oder "uns") und regelt Ihre Nutzung des Dienstes Amazon Music
                for Artists ("Amazon Music for Artists"). Bevor Sie Amazon Music
                for Artists verwenden, lesen Sie bitte diese
                Nutzungsbedingungen, alle zugehörigen Regeln und Richtlinien
                (einschließlich spezifischer Regeln, Nutzungsbeschränkungen und
                sonstiger Bedingungen oder Verfahren, die wir auf oder über
                Amazon Music for Artists oder auf einer Hilfs- oder sonstigen
                Informationsseite für Amazon Music for Artists veröffentlichen
                oder aktualisieren), die{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&amp;view-type=content-only">
                    <span className="s3">Datenschutzerklärung</span>
                </a>
                , die{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&amp;nodeId=508088">
                    <span className="s3">Nutzungsbedingungen</span>
                </a>
                , und die Hinweise zu{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050&amp;view-type=content-only">
                    <span className="s3"> interessenbezogenen Anzeigen (</span>
                </a>{" "}
                (gemeinsam die "Vereinbarung"). Die Vereinbarung wird wirksam,
                wenn Sie auf die Schaltfläche "Akzeptieren" bzw. das
                Kontrollkästchen in diesen Nutzungsbedingungen klicken. Sie
                versichern uns, dass Sie rechtsgültige Verträge abschließen
                dürfen (z. B. weil Sie nicht minderjährig sind). Wenn Sie diese
                Vereinbarung für eine juristische oder natürliche Person
                abschließen, z. B. für Ihren Arbeitgeber oder einen von Ihnen
                vertretenen Künstler, erklären Sie uns gegenüber, dass Sie
                rechtlich befugt sind, diese juristische oder natürliche Person
                zu binden.<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">1.</span>
            <span className="s4">  </span>
            <span className="s1">
                <b>Allgemeine Beschreibung</b>Amazon Music for Artists enthält
                diverse Tools und Funktionen, mit denen Künstler sowie ihre
                Bevollmächtigten Einblick in die Fangemeinde der Künstler
                erhalten und über die von Amazon Music bereitgestellten Dienste
                (die „Dienste“) mit Fans interagieren können.{" "}
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">2.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Nutzungsberechtigung</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                Um Amazon Music for Artists nutzen zu können, müssen Sie über
                ein Amazon-Konto verfügen und (a) ein Künstler oder
                Bevollmächtigter eines Künstlers sein, dessen Inhalte Amazon zur
                Verbreitung in den Diensten zur Verfügung gestellt werden, oder
                (b) ein Bevollmächtigter einer juristischen Person mit einer
                bestehenden Vereinbarung mit Amazon über die Bereitstellung von
                Inhalten zur Verbreitung in den Diensten. 
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.2.</span>
            <span className="s4">  </span>
            <span className="s1">
                Sie dürfen keine falsche E-Mail-Adresse verwenden, sich nicht
                als eine juristische oder natürliche Person ausgeben oder auf
                andere Weise falsche oder irreführende Informationen angeben,
                einschließlich Informationen, die bei der Einrichtung eines
                Kontos oder in Bezug auf das Eigentum an Künstlerinhalten
                angegeben wurden.{" "}
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">2.3.</span>
            <span className="s4">  </span>
            <span className="s1">
                Sie dürfen Ihren Amazon-Benutzernamen und Ihr Passwort nicht mit
                anderen teilen oder den Amazon-Benutzernamen und das Passwort
                anderer Personen verwenden. Sie können jedoch anderen von Ihnen
                autorisierten Personen gestatten, Ihre Fandaten in ihren eigenen
                Konten auf Amazon Music for Artists anzuzeigen.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p8">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.4.</span>
            <span className="s4">  </span>
            <span className="s1">
                Sie sind für alle Kommunikationen und Aktivitäten im
                Zusammenhang mit Amazon Music for Artists verantwortlich, die
                von Ihrem Computer oder Ihren Computereinrichtungen stammen,
                einschließlich Ihres E-Mail-Kontos und Ihres Passworts.
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.5.</span>
            <span className="s4"> </span>
            <span className="s1">
                Wir sind berechtigt, Ihre Kontaktdaten (einschließlich Name,
                E-Mail-Adresse und Benutzernamen in sozialen Medien) für
                Überprüfungs- und Berechtigungszwecke an Dritte und andere
                Personen weiterzugeben, die auf Ihre Fandaten zugreifen.
            </span>
            <span className="s5">
                .<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">3.</span>
            <span className="s4">    </span>
            <span className="s1">
                <b>Fandaten</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.1.</span>
            <span className="s4"></span>
            <span className="s1">
                Wir können Ihnen Zugriff auf Daten gewähren, die sich auf die
                Verbreitung Ihrer Inhalte auf den Diensten beziehen
                („Fandaten“). Fandaten dürfen nur für interne Bewertungen und
                Analysen verwendet und nicht an Dritte weitergegeben werden, es
                sei denn, der betreffende Dritte stimmt diesen
                Nutzungsbedingungen zu. Amazon kann Ihnen jedoch bestimmte
                Informationen (z. B. Meilensteine, die Sie in den Diensten
                erreicht haben) über "Freigabekarten" zur Verfügung stellen, die
                Sie öffentlich zugänglich machen dürfen.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                Fandaten werden nur als Annehmlichkeit für Sie bereitgestellt
                und wir übernehmen keine Garantie für deren Richtigkeit. Daher
                sollten sie sich nicht darauf verlassen, um Lizenzgebühren oder
                andere Beträge berechnen, die Ihnen aufgrund der Verbreitung
                Ihrer Inhalte auf den Diensten zustehen.
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                Fandaten dürfen nicht zur Ableitung von Daten verwendet werden,
                die detaillierter sind als die bereitgestellten (z. B. um
                personenbezogene Daten über einen Endnutzer der Dienste zu
                ermitteln).
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">4.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    Rechte<span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.1.</span>
            <span className="s4">  </span>
            <span className="s1">
                <b>Künstlerinhalte</b>. Sie dürfen Amazon Music for Artists
                verwenden, um Materialien (z. B. Bilder und Kunstwerke)
                einzureichen, die Sie besitzen oder auf andere Weise einreichen
                dürfen („Künstlerinhalte“). Sie dürfen weder Künstlerinhalte
                einreichen, die illegal, obszön, bedrohlich oder diffamierend
                sind oder auf andere Weise anstößige oder unangemessene Inhalte
                enthalten, noch Künstlerinhalte einreichen, die (a) Rechte
                Dritter verletzen, einschließlich Datenschutz-,
                Veröffentlichungs-, Urheber-, Marken-, Patent- oder sonstiger
                Rechte an geistigem Eigentum oder (b) uns dazu verpflichten
                würden, Lizenzgebühren oder sonstige Gebühren an Dritte für die
                Verwendung der Künstlerinhalte im Rahmen der Vereinbarung zu
                zahlen. Sie sind dafür verantwortlich und erklären sich damit
                einverstanden, etwaige Gebühren für Künstlerinhalte zu zahlen.
                Die Einreichung an Amazon Music for Artists ändert nichts an
                Ihrem Eigentum an den Künstlerinhalten.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p9">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Lizenzgewährung</b>. Sie gewähren uns eine nicht exklusive,
                unterlizenzierbare, weltweite, unbefristete, unwiderrufliche,
                unentgeltliche Lizenz zur Nutzung, Reproduktion, Verbreitung,
                Übertragung, Aufführung, Änderung und Anzeige sämtlicher
                Künstlerinhalte in allen Medien und Technologieformen in
                Verbindung mit den Diensten und Amazon Music for Artists.{" "}
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s5">4.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Vorschläge</b>.{" "}
            </span>
            <span className="s1">
                Wenn Sie uns Verbesserungsvorschläge für Amazon Music for
                Artists („Vorschläge“) machen, sind wir zur uneingeschränkten
                Verwendung dieser Vorschläge berechtigt. Sie übertragen uns
                hiermit unwiderruflich alle Rechte, Titel und Anteile an
                Vorschlägen und erklären sich damit einverstanden, uns jedwede
                erforderliche Unterstützung zu gewähren, um unsere Rechte an
                Vorschlägen zu dokumentieren, umzusetzen und aufrechtzuerhalten.{" "}
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">5.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Sicherheit und Datenschutz</b>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Kein Reverse Engineering oder Umgehung</b>
            </span>
            <span className="s1">
                . Sie dürfen und werden keine andere Person ermutigen,
                unterstützen oder autorisieren, Teile von Amazon Music for
                Artists zu modifizieren, zurückzuentwickeln, zu umgehen, zu
                dekompilieren, zu disassemblieren, zu überlisten oder auf andere
                Weise zu manipulieren.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.2.</span>
            <span className="s4">  </span>
            <span className="s1">
                <b>Keine Massendatenerfassung</b>. Sie dürfen keine andere
                Person ermutigen, unterstützen oder autorisieren, Amazon Music
                for Artists zu durchsuchen oder anderweitig Data Mining,
                Scraping oder sonstige Methoden der Datenerfassung zur Erhebung
                von Informationen von Amazon zu verwenden.
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">6.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    Änderungen und Aussetzung
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">6.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Änderungen</b>
            </span>
            <span className="s1">
                . Wir können Amazon Music for Artists oder einen Teil davon
                jederzeit ändern, aussetzen oder einstellen. Von Zeit zu Zeit
                können wir Funktionen zu Amazon Music for Artists hinzufügen
                oder daraus entfernen. Es kann ungewöhnliche Umstände geben,
                unter denen wir möglicherweise nicht mehr in der Lage sind,
                bestimmte Funktionen oder Fandaten zu speichern oder Ihnen
                Zugriff darauf zu gewähren.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">6.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Aussetzung und Kündigung durch uns</b>
            </span>
            <span className="s1">
                . Ihre Rechte im Rahmen der Vereinbarung erlöschen automatisch
                und ohne vorherige Ankündigung, wenn Sie gegen die Bedingungen
                verstoßen. Wir können die Vereinbarung jederzeit kündigen oder
                Ihre Nutzung von Amazon Music for Artists einschränken,
                aussetzen oder beenden, auch wenn wir feststellen, dass Ihre
                Nutzung gegen die Vereinbarung verstößt, unangemessen ist, die
                normale Nutzung durch andere Benutzer erheblich übersteigt oder
                von dieser abweicht, Betrug oder Missbrauch von Amazon Music for
                Artists darstellen oder unseren Interessen oder denen eines
                anderen Benutzers von Amazon Music for Artists schadet. Sie
                können die Vereinbarung aus beliebigem Grund kündigen, indem Sie
                Ihr Konto schließen. Wenn Ihr Zugriff auf Amazon Music for
                Artists eingeschränkt, gesperrt oder beendet ist, können Sie
                möglicherweise nicht auf die Fandaten zugreifen. Nach jeder
                Aufhebung oder Kündigung behalten wir die Rechte an
                Künstlerinhalten, die uns im Rahmen der Vereinbarung gewährt
                wurden.
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">7.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Befugnisse und Freistellungen</b>. Sie versichern und
                garantieren, dass: (a) Sie alle erforderlichen Rechte besitzen,
                um uns die in der Vereinbarung gewährten Rechte zu gewähren; (b)
                die von Ihnen eingereichten Materialien und unsere Verwendung
                gemäß den Bestimmungen der Vereinbarung (i) keine
                Datenschutzrechte, Veröffentlichungsrechte, Rechte an geistigem
                Eigentum oder andere Rechte Dritter verletzen, (ii) nicht
                diffamierend, illegal oder obszön sind und (iii) uns nicht zur
                Zahlung von Lizenzgebühren oder sonstigen Gebühren für die
                Verwendung solcher Materialien an Dritte verpflichten; und (c)
                alle Ihre Angaben korrekt und nicht irreführend sind. Sie
                erklären sich damit einverstanden, Amazon und seine Direktoren,
                leitenden Angestellten, Mitarbeiter, Vertreter und Beauftragten
                hinsichtlich sämtlicher Ansprüche, Verbindlichkeiten, Schäden
                und Aufwendungen, einschließlich angemessener Anwaltskosten, die
                aus oder im Zusammenhang mit von Ihnen eingereichten
                Materialien, Verstößen gegen diese Vereinbarung Ihrerseits,
                Ihren Zusicherungen bzw. Garantien oder Ihrer missbräuchlichen,
                nicht autorisierten oder rechtswidrigen Nutzung von Amazon Music
                for Artists entstehen, freizustellen, zu verteidigen und
                schadlos zu halten.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">8.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Allgemeine Bestimmungen</b>.
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">8.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Änderungen</b>
            </span>
            <span className="s1">
                . Wir können die Vereinbarung jederzeit ändern, indem wir die
                überarbeiteten Bedingungen auf oder über Amazon Music for
                Artists veröffentlichen. Mit Ihrer Weiternutzung von Amazon
                Music for Artists nach einer Änderung geben Sie an, dass Sie
                damit einverstanden sind.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">8.2.</span>
            <span className="s4">  </span>
            <span className="s5">
                <b>Mitteilungen</b>.{" "}
            </span>
            <span className="s1">
                {" "}
                Wir können im Zusammenhang mit Ihrer Nutzung von Amazon Music
                for Artists elektronisch und in anderen Medien mit Ihnen
                kommunizieren, und Sie stimmen dem Erhalt dieser Mitteilungen
                unabhängig von Kundenkommunikationen oder ähnlichen Präferenzen
                oder Wünschen zu, die Sie auf Amazon.com oder in anderer Weise
                angegeben haben.<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Urheberrechtshinweise</b>
            </span>
            <span className="s1">
                . Wenn Sie der Meinung sind, dass Ihre Arbeit in einer Weise
                kopiert wurde, die eine Urheberrechtsverletzung darstellt,
                befolgen Sie bitte die in den{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909000&amp;view-type=content-only">
                    <span className="s8">Nutzungsbedingungen</span>
                </a>{" "}
                angegebenen Hinweise und Verfahren zur Geltendmachung von
                Ansprüchen wegen Urheberrechtsverletzung.
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.4.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Vollständigkeitsklausel, salvatorische Klausel</b>
            </span>
            <span className="s1">
                . Die Vereinbarung stellt die gesamte Übereinkunft zwischen
                Ihnen und Amazon dar und regelt Ihre Nutzung von Amazon Music
                for Artists. Sie ersetzt alle vorherigen Übereinkünfte zwischen
                Ihnen und Amazon. Sollte eine Bedingung der Vereinbarung für
                ungültig, nichtig oder undurchsetzbar befunden werden, gleich
                aus welchem Grund, gilt dieser Teil als abgetrennt und wirkt
                sich nicht auf die Gültigkeit und Durchsetzbarkeit der übrigen
                Bedingungen aus.
            </span>
        </p>
    </div>
);
