import * as React from "react";
import { Col, Row, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import _ from "lodash";
import { testIDSuffixes, getLocalizedString } from "../../../utils";
import styled from "styled-components";
import * as rootStyles from "../../styles";
import { merchImages } from "../../../assets";
import { styledTitle } from "..";
import { stringIds, bundleIds } from "../../../assets";
import { commonSelectors } from "../../../store/selectors";
import { BundleMap } from "../../../models";

const testIDPrefix = "MoDLandingHowItWorks";

export const MoDLandingHowItWorks: React.FC<{}> = (props) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);
    return (
        <SectionContainer id={testIDPrefix}>
            <Row>
                <Col>
                    <StyledBannerTitleContainer>
                        <styledTitle.h4
                            style={{
                                textTransform: "none",
                            }}
                        >
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch.merch_mod_landing_HowDoes,
                            })}
                        </styledTitle.h4>
                    </StyledBannerTitleContainer>

                    <Container>
                        {/* Upload Artwork  */}
                        <ItemContainer>
                            <EllipseImage src={merchImages.uploadArtwork} />
                            <StyledTitle
                                style={rootStyles.textStyles.quaternary}
                            >
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_landing_How_Upload,
                                })}
                            </StyledTitle>
                        </ItemContainer>
                        {/* Set your price  */}
                        <ItemContainer>
                            <EllipseImage src={merchImages.setYourPrice} />
                            <StyledTitle
                                style={rootStyles.textStyles.quaternary}
                            >
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_landing_How_SetPrice,
                                })}
                            </StyledTitle>
                        </ItemContainer>
                        {/* We print */}
                        <ItemContainer>
                            <EllipseImage src={merchImages.wePrint} />
                            <StyledTitle
                                style={rootStyles.textStyles.quaternary}
                            >
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_landing_How_WePrint,
                                })}
                            </StyledTitle>
                        </ItemContainer>
                        {/* prime shipping  */}
                        <ItemContainer>
                            <EllipseImage
                                src={merchImages.primeShippingTruck}
                            />
                            <StyledTitle
                                style={rootStyles.textStyles.quaternary}
                            >
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_landing_How_PrimeShipping,
                                })}
                            </StyledTitle>
                        </ItemContainer>
                        <ItemContainer>
                            <EllipseImage src={merchImages.earnRoyalties} />
                            <StyledTitle
                                style={rootStyles.textStyles.quaternary}
                            >
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_landing_How_Earn,
                                })}
                            </StyledTitle>
                        </ItemContainer>
                    </Container>
                    <div style={subTitle}>
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCH_STRINGS,
                            stringId: stringIds.Merch.merch_mod_landing_simply,
                        })}
                    </div>
                </Col>
            </Row>
        </SectionContainer>
    );
};

//styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101
const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 878px;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.large}px) {
        flex-direction: column;
        width: 568px;
    }
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        flex-direction: column;
        width: 335px;
    }
    padding-top: ${rootStyles.spacers.small}px;
    border-bottom: 1px solid;
    border-bottom-color: ${rootStyles.glassColors.primary4};
    padding-bottom: ${rootStyles.spacers.epic}px;
`;

const EllipseImage = styled(Image)`
    width: 140px;
    height: 140px;
    padding: 10px;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.large}px) {
        width: 100px;
        height: 100px;
    }
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        width: 72px;
        height: 72px;
    }
`;
const StyledBannerTitleContainer = styled.div`
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: ${rootStyles.spacers.medium}px;
    margin-bottom: ${rootStyles.spacers.medium}px;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        flex-direction: row;
    }
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.small}px) {
        flex-direction: row;
    }
`;

const StyledTitle = styled.div`
    margin-top: ${rootStyles.spacers.micro};
    margin: auto;
    text-align: center;
    width: 100%;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        text-align: left;
    }
`;

const subTitle: React.CSSProperties = {
    ...rootStyles.textStyles.quaternary,
    marginTop: rootStyles.spacers.micro,
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-item: center;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        flex-direction: column;
    }
    alignself: center;
    padding-bottom: ${rootStyles.spacers.epic}px;
`;
