import { createAction } from "redux-ts";
import { baseMediaItem, newDisambiguationPayload } from "../../models";

export const selectReportIssueItem = createAction<baseMediaItem>(
    "ARTIST_ISSUE::SELECT_REPORT_ISSUE_ITEM"
);

export const clearSelectedReportIssueItem = createAction(
    "ARTIST_ISSUE::CLEAR_REPORT_ISSUE_ITEM"
);

export const newDisambiguation = createAction<newDisambiguationPayload>(
    "ARTIST_ISSUE::NEW_DISAMBIGUATION"
);
export const newDisambiguationInProgress = createAction<boolean>(
    "ARTIST_ISSUE::NEW_DISAMBIGUATION_IN_PROGRESS"
);
export const newDisambiguationSuccess = createAction<boolean | undefined>(
    "ARTIST_ISSUE::NEW_DISAMBIGUATION_SUCCESS"
);
