import React, { FC, FunctionComponent, SVGProps } from "react";
import * as rootStyles from "../../styles";
import { SvgIcon, styledTitle } from "../../components/common";
import { StyledCardButton } from "../../components/common/input/Buttons";
import styled from "styled-components";

type Props = {
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    text: string;
    subText: string;
    action: () => void;
    id: string;
};

const ClaimRequesterTypeCard: FC<Props> = ({
    Icon,
    text,
    subText,
    action,
    id,
}) => {
    return (
        <StyledCard onClick={action} id={`${id}-ClaimRequesterTypeCard`}>
            <SvgIcon
                Icon={Icon}
                size={rootStyles.icons.huge}
                fill={rootStyles.colors.accent}
                id={id}
            />
            <div style={{ height: rootStyles.spacers.mini }} />
            <div style={textContainer}>
                <styledTitle.h4 style={primaryTextStyle}>{text}</styledTitle.h4>
                <div style={{ height: rootStyles.spacers.nano }} />
                <p style={secondaryTextStyle}>{subText}</p>
            </div>
        </StyledCard>
    );
};

const StyledCard = styled(
    (props: {
        onClick?: () => void;
        id: string;
        children?: React.ReactNode;
    }) => <StyledCardButton {...props} />
)`
    padding: ${rootStyles.spacers.huge}px ${rootStyles.spacers.large}px;

    @media (min-width: ${rootStyles.breakpoints.xl3}px) {
        padding: ${rootStyles.spacers.epic}px ${rootStyles.spacers.large}px;
    }
`;

const textContainer: React.CSSProperties = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
};

const primaryTextStyle: React.CSSProperties = {
    textAlign: "center",
};

const secondaryTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    textAlign: "center",
};

export default ClaimRequesterTypeCard;
