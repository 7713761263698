import * as React from "react";
import { Row, Col } from "react-bootstrap";
import * as rootStyles from "../../../styles";
import { testIDSuffixes } from "../../../../utils";

export type TableHeaderRowProps = {
    primary?: string;
    stat1?: string;
    stat2?: string;
    stat2RightIcon?: JSX.Element;
    secondary?: string;
    showIndex?: boolean;
    id: string;
};

export class TableHeaderRow extends React.PureComponent<TableHeaderRowProps> {
    render() {
        return (
            <div style={tableHeaderRow} id={`${this.props.id}-Container`}>
                {this.props.showIndex ? (
                    <div
                        id={`${this.props.id}-Index`}
                        style={{
                            ...rootStyles.tableStyles.indexContainer,
                            paddingLeft: 15,
                        }}
                    >
                        <span style={rootStyles.textStyles.index}>#</span>
                    </div>
                ) : null}

                {this.props.primary ? (
                    <div
                        id={`${this.props.id}-Primary`}
                        style={{
                            ...rootStyles.tableStyles.headerTextContainer,
                            paddingLeft: 15,
                        }}
                    >
                        <span style={rootStyles.textStyles.index}>
                            {this.props.primary}
                        </span>
                    </div>
                ) : null}
                {this.props.stat1 ? (
                    <div
                        id={`${this.props.id}-Stat1`}
                        style={rootStyles.tableStyles.statContainer}
                    >
                        <span style={rootStyles.textStyles.index}>
                            {this.props.stat1}
                        </span>
                    </div>
                ) : null}
                {this.props.stat2 ? (
                    <div
                        id={`${this.props.id}-Stat2`}
                        style={{
                            ...rootStyles.tableStyles.statContainer,
                        }}
                    >
                        <span style={rootStyles.textStyles.index}>
                            {this.props.stat2}
                        </span>
                        {this.props.stat2RightIcon}
                    </div>
                ) : null}
            </div>
        );
    }
}

const tableHeaderRow: React.CSSProperties = {
    ...rootStyles.tableStyles.headerContainerStyle,
    borderBottomWidth: 1,
    borderStyle: "solid",
    marginBottom: rootStyles.spacers.small,
    borderBottomColor: rootStyles.glassColors.primary3,
    minHeight: 36,
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
};
