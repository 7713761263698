export const squareLogos = {
    am_follow_deDE_white: require("./AM_Follow-on_de-DE_wht.png"),
    am_follow_deDE_black: require("./AM_Follow-on_de-DE.png"),
    am_listen_deDE_white: require("./AM_Listen-on_de-DE_wht.png"),
    am_listen_deDE_black: require("./AM_Listen-on_de-DE.png"),
    am_follow_enUS_white: require("./AM_Follow-on_en-US_wht.png"),
    am_follow_enUS_black: require("./AM_Follow-on_en-US.png"),
    am_listen_enUS_white: require("./AM_Listen-on_en-US_wht.png"),
    am_listen_enUS_black: require("./AM_Listen-on_en-US.png"),
    am_follow_esES_white: require("./AM_Follow-on_es-ES_wht.png"),
    am_follow_esES_black: require("./AM_Follow-on_es-ES.png"),
    am_listen_esES_white: require("./AM_Listen-on_es-ES_wht.png"),
    am_listen_esES_black: require("./AM_Listen-on_es-ES.png"),
    am_follow_frFR_white: require("./AM_Follow-on_fr-FR_wht.png"),
    am_follow_frFR_black: require("./AM_Follow-on_fr-FR.png"),
    am_listen_frFR_white: require("./AM_Listen-on_fr-FR_wht.png"),
    am_listen_frFR_black: require("./AM_Listen-on_fr-FR.png"),
    am_follow_itIT_white: require("./AM_Follow-on_it-IT_wht.png"),
    am_follow_itIT_black: require("./AM_Follow-on_it-IT.png"),
    am_listen_itIT_white: require("./AM_Listen-on_it-IT_wht.png"),
    am_listen_itIT_black: require("./AM_Listen-on_it-IT.png"),
    am_follow_jaJP_white: require("./AM_Follow-on_ja-JP_wht.png"),
    am_follow_jaJP_black: require("./AM_Follow-on_ja-JP.png"),
    am_listen_jaJP_white: require("./AM_Listen-on_ja-JP_wht.png"),
    am_listen_jaJP_black: require("./AM_Listen-on_ja-JP.png"),
    am_follow_ptBR_white: require("./AM_Follow-on_pt-BR_wht.png"),
    am_follow_ptBR_black: require("./AM_Follow-on_pt-BR.png"),
    am_listen_ptBR_white: require("./AM_Listen-on_pt-BR_wht.png"),
    am_listen_ptBR_black: require("./AM_Listen-on_pt-BR.png"),
};
