import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { RootState } from "../../../store";
import { stringIds, IconsList, bundleIds } from "../../../assets";
import { getLocalizedString, getLocalizedUrl } from "../../../utils";
import SettingsListItem from "../../components/lists/SettingsListItem";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";
import { LocalizedUrls } from "@amzn/ziggy-asset";
import { AnnouncementToast, Icon, CommonHeader } from "../../components";
import { BundleMap, userData } from "../../../models";

const testIDPrefix = "AccountDetailsScreen";

type StateProps = {
    userData: userData;
    bundleMap: BundleMap;
};

type Props = StateProps & RouteComponentProps<{}>;

class AccountDetailsScreen extends React.Component<Props> {
    render() {
        const rightArrow = () => {
            return (
                <Icon
                    size={rootStyles.icons.small}
                    icon={IconsList.chevron_caretright}
                    id={`${testIDPrefix}_DeleteAccountIcon`}
                />
            );
        };
        const BannerToast = styled(AnnouncementToast)`
            height: auto;
        `;
        return (
            <Container>
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SETTINGS_STRINGS,
                        stringId: stringIds.Settings.accountDetails,
                    })}
                    id={`${testIDPrefix}_Header`}
                    hideArtistImgDropdown={true}
                    hideDatePicker={true}
                />
                <Col style={containerStyle}>
                    <SettingsListItem
                        key="userName"
                        primary={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SETTINGS_STRINGS,
                            stringId: stringIds.Settings.username,
                        })}
                        primaryStyle={profilePrimary}
                        secondary={this.props.userData.userName}
                        secondaryStyle={profileSecondary}
                        id={`${testIDPrefix}_UserName`}
                    />
                    <SettingsListItem
                        key="emailAddress"
                        primary={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SETTINGS_STRINGS,
                            stringId: stringIds.Settings.emailAddress,
                        })}
                        primaryStyle={profilePrimary}
                        secondary={this.props.userData.emailAddress}
                        secondaryStyle={profileSecondary}
                        id={`${testIDPrefix}_EmailAddress`}
                    />
                    <Row
                        style={{
                            ...rowStyle,
                            marginTop: rootStyles.spacers.epic,
                        }}
                    >
                        <SettingsListItem
                            onClick={() =>
                                (window.location.href = getLocalizedUrl(
                                    LocalizedUrls.SettingsDeleteAccountLink
                                ))
                            }
                            key={"deleteAccount"}
                            primary={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SETTINGS_STRINGS,
                                stringId: stringIds.Settings.deleteAccount,
                            })}
                            secondary={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.SETTINGS_STRINGS,
                                    stringId:
                                        stringIds.Settings
                                            .deleteAccountSubtitle,
                                }
                            )}
                            rightElement={rightArrow()}
                            id={`${testIDPrefix}_DeleteAccount`}
                        />
                    </Row>
                    <BannerToast>
                        <div style={{ margin: rootStyles.spacers.small }}>
                            <span
                                style={{
                                    ...rootStyles.textStyles.secondary,
                                    color: rootStyles.colors.primary,
                                }}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.SETTINGS_STRINGS,
                                    stringId:
                                        stringIds.Settings.deleteAccountBanner,
                                })}
                            </span>
                        </div>
                    </BannerToast>
                </Col>
            </Container>
        );
    }
}

const containerStyle: React.CSSProperties = {
    flex: 1,
    paddingLeft: rootStyles.spacers.medium,
    paddingRight: rootStyles.spacers.medium,
    marginTop: rootStyles.spacers.large,
};

const rowStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "stretch",
};

const profilePrimary: React.CSSProperties = {
    ...rootStyles.textStyles.navigationLabelStyle,
};

const profileSecondary: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
};

function mapStateToProps(state: RootState): StateProps {
    return {
        userData: state.user.userData,
        ...bundleMapSelector(state),
    };
}

function mapDispatchToProps() {
    return {};
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AccountDetailsScreen)
);
