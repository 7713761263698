import * as React from "react";
import { Icon } from "..";
import * as rootStyles from "../../../styles";

export const generateDropdownOption = (
    icon: any,
    message: string,
    id: string,
    iconSize?: number
) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Icon
                icon={icon}
                size={iconSize ? iconSize : 20}
                id={`${id}_Icon`}
            />
            <span
                style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: rootStyles.spacers.small,
                }}
            >
                {message}
            </span>
        </div>
    );
};
