import React from "react";
import { IconsList } from "../../../../assets";
import { Box } from "./Box";
import * as rootStyles from "../../../styles";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import { itemProps } from "../../../../models";

export type BoxListProps = {
    itemsProps: itemProps[];
    onChange?: (itemsProps: itemProps[]) => void;
    showCancelIcon?: boolean;
    viewOnly?: boolean;
    maxSelect?: number;
    id: string;
};

export const BoxList: React.FC<BoxListProps> = ({
    itemsProps,
    onChange,
    showCancelIcon = false,
    viewOnly = false,
    maxSelect,
    id,
}) => {
    const rightIconName = showCancelIcon
        ? IconsList.action_cancel_black
        : undefined;

    const selectedItemsCount: number = itemsProps.reduce(
        (count, item) => (item.selected ? count + 1 : count),
        0
    );

    const onClick = (index: number, selected: boolean) => {
        if (maxSelect && selectedItemsCount >= maxSelect && selected === true) {
            return;
        }
        const newBoxPropertiesList = _.cloneDeep(itemsProps);
        newBoxPropertiesList[index].selected = selected;
        onChange && onChange(newBoxPropertiesList);
    };

    const renderBoxList = (itemProperties: itemProps, index: number) => {
        return (
            <Col
                style={boxContainerStyle}
                xs="auto"
                key={`${itemProperties.name}_${index}`}
            >
                <Box
                    title={itemProperties.name}
                    onClick={(selected: boolean) => onClick(index, selected)}
                    rightIconName={rightIconName}
                    value={itemProperties.selected}
                    viewOnly={viewOnly}
                    id={`${id}_Box${index}`}
                />
            </Col>
        );
    };

    const results = itemsProps.map((itemProperties: itemProps, index: number) =>
        renderBoxList(itemProperties, index)
    );

    return <Row>{results}</Row>;
};

const boxContainerStyle: React.CSSProperties = {
    marginRight: rootStyles.spacers.base,
    padding: 0,
    marginBottom: rootStyles.spacers.base,
};

export default BoxList;
