import { localeMap, defaultLocale, UserLanguage, locale } from "./localeType";
import _ from "lodash";
import Cookies from "js-cookie";

const LanguageCookie = "lc-main";

export function getWebLocale() {
    // If there is locale stored in local storage, use that as web locale.
    // Else if there is "lc-main" cookie set by auth portal, use that as the locale
    // Else, use the browser locale with a fall back to en_US as default
    return (
        getUserLocaleFromStore() ||
        getUserLocaleFromLanguageCookie() ||
        getBrowserLocale()
    );
}

export function getUserLocaleFromStore() {
    const serializedState = localStorage.getItem("state");

    return serializedState === null
        ? undefined
        : JSON.parse(serializedState).userLocale;
}

export function getUserLocaleFromLanguageCookie() {
    const languageCookie = Cookies.get(LanguageCookie);
    if (
        languageCookie &&
        Array.from(localeMap.values()).includes(languageCookie)
    ) {
        return languageCookie;
    } else {
        return undefined;
    }
}

export function getBrowserLocale() {
    const browserLanguage = navigator.language;

    const formattedWebLocale = _.replace(browserLanguage, "-", "_");
    const formatSplit = formattedWebLocale.split("_");
    const languageCode = formatSplit[0] ? formatSplit[0] : "en";

    const browserLocale = localeMap.has(languageCode)
        ? localeMap.get(languageCode)!
        : defaultLocale;

    return browserLocale;
}

export const translateUserLanguageToLocaleString: (
    language: UserLanguage
) => string = (language: UserLanguage) => {
    switch (language) {
        case UserLanguage.DE_DE:
            return locale.deDE;
        case UserLanguage.EN_US:
            return locale.enUS;
        case UserLanguage.ES_ES:
            return locale.esES;
        case UserLanguage.FR_FR:
            return locale.frFR;
        case UserLanguage.IT_IT:
            return locale.itIT;
        case UserLanguage.JA_JP:
            return locale.jaJP;
        case UserLanguage.PT_BR:
            return locale.ptBR;
        default:
            return locale.enUS;
    }
};

export const translateLocaleStringToUserLanguage: (
    localeString: string
) => UserLanguage = (localeString: string) => {
    switch (localeString) {
        case locale.deDE:
            return UserLanguage.DE_DE;
        case locale.enUS:
            return UserLanguage.EN_US;
        case locale.esES:
            return UserLanguage.ES_ES;
        case locale.frFR:
            return UserLanguage.FR_FR;
        case locale.itIT:
            return UserLanguage.IT_IT;
        case locale.jaJP:
            return UserLanguage.JA_JP;
        case locale.ptBR:
            return UserLanguage.PT_BR;
        default:
            return UserLanguage.EN_US;
    }
};

export function getArtistHandbookUrl() {
    const locale = getWebLocale();
    switch (locale) {
        case "en_US":
            return "https://artists.amazonmusic.com/frequently-asked-questions";
        // Replace the link with correct jp version of artist handbook url once we have it
        case "ja_JP":
            return "https://artists.amazonmusic.com/frequently-asked-questions";
        default:
            return "https://artists.amazonmusic.com/frequently-asked-questions";
    }
}
