import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as rootStyles from "../../styles";
import { ImageList, bundleIds, stringIds } from "../../../assets";
import {
    BundleMap,
    EntityType,
    promoCardSelectedContent,
} from "../../../models";
import { playlistRow, formatDate, getLocalizedString } from "../../../utils";
import { ImageWithFallback, ImageWithFallbackProps } from "../../components";
import styled from "styled-components";
import { commonSelectors } from "../../../store";

type PlaylistSearchBarProps = {
    playlistRows: playlistRow[];
    selectPlaylist: (item: promoCardSelectedContent) => void;
    onClickLearnMore: () => void;
};
const testIDPrefix = "PromCard_PlaylistSearchBar";
export const PlaylistSearchBar: React.FC<PlaylistSearchBarProps> = ({
    playlistRows,
    selectPlaylist,
    onClickLearnMore,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const renderItem = (item: playlistRow, index: number) => {
        return (
            <div
                id={`${testIDPrefix}_Container`}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: rootStyles.spacers.small,
                    backgroundColor:
                        selectedIndex === index
                            ? rootStyles.glassColors.primary2
                            : "transparent",
                    borderRadius: selectedIndex === index ? "7px" : "0px",
                }}
                onClick={() => {
                    selectPlaylist({
                        title: item.playlistTitle,
                        images: {
                            artSmall: item.playlistImage,
                            artExtraLarge: item.playlistImage,
                        },
                        type: EntityType.Playlist,
                        asin: item.trackAsin,
                        contentContainerAsin: item.playlistAsin,
                        playlistImageName: item.playlistImageName,
                        playlistLogoColor: item.playlistLogoColor,
                        landscapeReversed: item.landscapeReversed,
                    });
                    setSelectedIndex(index);
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            position: "relative",
                            justifyContent: "start",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                    >
                        <PlaylistItemImage
                            source={playlistRows[index]?.playlistImage}
                            fallback={ImageList.placeholder_playlist}
                            id={`${testIDPrefix}-${index}-PromoPlaylistImage`}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        justifyContent: "center",
                        alignItems: "start",
                        cursor: "pointer",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            justifyContent: "center",
                            alignItems: "start",
                            cursor: "pointer",
                            marginLeft: rootStyles.spacers.medium,
                            marginRight: rootStyles.spacers.medium,
                        }}
                    >
                        <div style={rootStyles.textStyles.primary}>
                            {getLocalizedString(
                                bundleMap,
                                {
                                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                                    stringId:
                                        stringIds.PromoCard.trackOnPlaylist,
                                },
                                {
                                    0: playlistRows[index].trackName,
                                    1: playlistRows[index].playlistTitle,
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            style={{
                marginTop: rootStyles.spacers.medium,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <div style={rootStyles.textStyles.primary}>
                        {getLocalizedString(
                            bundleMap,
                            {
                                bundleId: bundleIds.PROMO_CARD_STRINGS,
                                stringId: stringIds.PromoCard.appearOnPlaylists,
                            },
                            { "0": playlistRows.length }
                        )}
                    </div>
                    <div
                        id={`${testIDPrefix}_LearnMore`}
                        style={{
                            ...rootStyles.textStyles.primaryClickableText,
                            paddingLeft: rootStyles.spacers.micro,
                        }}
                        onClick={onClickLearnMore}
                    >
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PROMO_CARD_STRINGS,
                            stringId: stringIds.PromoCard.learnMore,
                        })}
                    </div>
                </div>
                {playlistRows.length > 0 ? (
                    <div
                        style={{
                            marginTop: rootStyles.spacers.small,
                            height: 300,
                            overflow: "auto",
                        }}
                    >
                        {playlistRows.map(renderItem)}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const PlaylistItemImage = styled((props: ImageWithFallbackProps) => (
    <ImageWithFallback {...props} />
))`
    height: 82px;
    width: 82px;
    background-color: ${(props) =>
        !!props.source ? `${rootStyles.colors.primary}` : "default"};
    @media (min-width: 992px) {
        &:hover {
            opacity: ${rootStyles.glass._3};
        }
    }
`;
