import { bundleIds, stringIds } from "@amzn/ziggy-asset";
import * as React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import { IconsList } from "../../../../assets";
import { BundleMap, ProfileImageSize } from "../../../../models";
import { RootState } from "../../../../store";
import { getLocalizedString, testIDSuffixes } from "../../../../utils";
import * as rootStyles from "../../../styles";
import { ButtonSize } from "../../../styles";
import { TimeRangePicker } from "../../datepicker";
import ArtistImgDropdown from "./../ArtistImgDropdown";
import { ImageWithFallback } from "./../ImageWithFallback";
import {
    SMALL_TEXT_BREAKPOINT,
    styledTitle,
    titleBreakpoint,
} from "./../Title";
import { generateDropdownOption } from "./../helpers";
import { DownloadButton, GenericDropdown, MediumGlassButton } from "./../input";

export type CommonHeaderProps = {
    title: string;
    subtitle?: string;
    subsubtitle?: string;
    artistName?: string;
    note?: string;
    mobileAlignment?: "center" | "left"; // default left
    id: string;
    hideDatePicker?: boolean;
    hideArtistImgDropdown?: boolean;
    showCsvButton?: boolean;
    csvDownload?: () => void;
    csvTooltipMessage?: string;
    csvButtonLoading?: boolean;
    imageSource?: string;
    imageSize?: number;
    imageDescription?: string;
    fallbackImage?: string;
    shareContentAction?: () => void;
    showShareContentButton?: boolean;
    callback?: () => void;
    callbackString?: string;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = CommonHeaderProps & StateProps;

type State = {
    screenSize: number;
};

class CommonHeaderClass extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            screenSize: window.innerWidth,
        };
    }

    resize() {
        this.setState({ screenSize: window.innerWidth });
    }
    render() {
        const imageSize = this.props.imageSize || ProfileImageSize.Medium;

        const imageStyling: any = {
            shadowOffset: {
                width: 0,
                height: 10,
            },
            height: imageSize,
            width: imageSize,
            borderRadius: 8,
        };

        const isLongText = this.props.title.length >= SMALL_TEXT_BREAKPOINT;

        const ResolvedTitle = isLongText ? SmallTitle : Title;

        return (
            <CommonHeaderContainer>
                <ArtistImgWrapper>
                    {!this.props.hideArtistImgDropdown && (
                        <ArtistImgContainer>
                            <ArtistImgDropdown />
                        </ArtistImgContainer>
                    )}
                </ArtistImgWrapper>
                <StackedWrapper>
                    {!this.props.hideDatePicker ? (
                        <TimeRangePickerSmallContainer>
                            {!this.props.hideDatePicker && (
                                <TimeRangePicker
                                    idOverride={"timepicker_Small"}
                                />
                            )}
                        </TimeRangePickerSmallContainer>
                    ) : null}
                    <HeadContentContainer
                        mobileStyleOverride={{
                            alignItems:
                                this.props.mobileAlignment === "center"
                                    ? "center"
                                    : "flex-start",
                        }}
                    >
                        {this.props.imageSource ? (
                            <MediaImageContainer>
                                <ImageWithFallback
                                    style={imageStyling}
                                    source={this.props.imageSource}
                                    id={`${this.props.id}_Image`}
                                    imageDescription={
                                        this.props.imageDescription
                                    }
                                    fallback={this.props.fallbackImage}
                                    className={"ProfileHeadImage"}
                                />
                            </MediaImageContainer>
                        ) : null}
                        <HeadTextContainer
                            mobileStyleOverride={{
                                alignItems:
                                    this.props.mobileAlignment === "center"
                                        ? "center"
                                        : "flex-start",
                            }}
                        >
                            <div
                                style={{
                                    zIndex: 1,
                                }}
                            >
                                <div>
                                    {this.props.subtitle && (
                                        <p
                                            style={subtitleStyle}
                                            id={`${this.props.id}${testIDSuffixes.subtitle}`}
                                        >
                                            {this.props.subtitle}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <ResolvedTitle
                                        id={`${this.props.id}${testIDSuffixes.title}`}
                                        className={
                                            this.state.screenSize <=
                                                titleBreakpoint || isLongText
                                                ? "truncatedTwoLinesText"
                                                : "truncatedOneLineText"
                                        }
                                    >
                                        {this.props.title}
                                    </ResolvedTitle>
                                </div>
                            </div>

                            {!!this.props.artistName && (
                                <Row>
                                    <span
                                        className="truncatedOneLineText"
                                        style={rootStyles.textStyles.primary}
                                    >
                                        {this.props.artistName}
                                    </span>
                                </Row>
                            )}
                            {!!this.props.subsubtitle && (
                                <Row>
                                    <span
                                        className="truncatedTwoLineText"
                                        style={rootStyles.textStyles.primary}
                                    >
                                        {this.props.subsubtitle}
                                    </span>
                                </Row>
                            )}
                            {!!this.props.note && (
                                <Row>
                                    <span
                                        className="truncatedOneLineText"
                                        style={rootStyles.textStyles.secondary}
                                    >
                                        {this.props.note}
                                    </span>
                                </Row>
                            )}
                            {this.props.showShareContentButton ||
                            (this.props.callback &&
                                this.props.callbackString) ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: rootStyles.spacers.mini,
                                        width: "100%",
                                    }}
                                >
                                    {/* Needed to center share/more in detail pages when CSV button exists */}
                                    {!!this.props.showCsvButton &&
                                        !!this.props.csvDownload &&
                                        !!this.props.csvTooltipMessage && (
                                            <PhantomContainer />
                                        )}
                                    <CenteredMobileButtonsContainer>
                                        {this.props.showShareContentButton && (
                                            <MediumGlassButton
                                                className={"ShareContentButton"}
                                                containerStyle={{
                                                    marginRight:
                                                        rootStyles.spacers
                                                            .small,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    flex: 0,
                                                }}
                                                onClick={
                                                    this.props
                                                        .shareContentAction
                                                }
                                                title={getLocalizedString(
                                                    this.props.bundleMap,
                                                    {
                                                        bundleId:
                                                            bundleIds.CONTENTSHARING_STRINGS,
                                                        stringId:
                                                            stringIds
                                                                .ContentSharing
                                                                .shareButtonTitle,
                                                    }
                                                )}
                                                id={
                                                    this.props.id +
                                                    "_ShareContentButton"
                                                }
                                                leftIcon={
                                                    IconsList.action_share_web
                                                }
                                            />
                                        )}
                                        {this.props.callback &&
                                        this.props.callbackString ? (
                                            <GenericDropdown
                                                items={this.getDropdownItems(
                                                    this.props.callbackString
                                                )}
                                                className={
                                                    "trackAlbumMoreDropdown"
                                                }
                                                id={`${this.props.id}_Dropdown`}
                                                iconSize={24}
                                                dropdownStyle={{
                                                    margin: 0,
                                                    alignSelf: "flex-end",
                                                }}
                                                buttonStyle={{
                                                    width: "none",
                                                    borderRadius: 20,
                                                    padding: 8,
                                                }}
                                                menuStyle={{
                                                    marginLeft: 20,
                                                    padding: 0,
                                                    width: 220,
                                                }}
                                                direction={
                                                    this.state.screenSize <=
                                                    rootStyles.breakpoints.md
                                                        ? "down"
                                                        : "right"
                                                }
                                            />
                                        ) : null}
                                    </CenteredMobileButtonsContainer>
                                    {this.props.showCsvButton &&
                                        !!this.props.csvDownload &&
                                        !!this.props.csvTooltipMessage && (
                                            <CsvDownloadButtonContainer
                                                style={{
                                                    marginLeft: "auto",
                                                }}
                                            >
                                                <DownloadButton
                                                    loading={
                                                        !!this.props
                                                            .csvButtonLoading
                                                    }
                                                    size={
                                                        rootStyles.ButtonSize
                                                            .small
                                                    }
                                                    id={`${this.props.id}_CsvButton-ProfileHead`}
                                                    tooltipMessage={
                                                        this.props
                                                            .csvTooltipMessage
                                                    }
                                                    onClick={
                                                        this.props.csvDownload
                                                    }
                                                />
                                            </CsvDownloadButtonContainer>
                                        )}
                                    {!this.props.hideDatePicker ? (
                                        <TimeRangePickerLargeContainer>
                                            {!this.props.hideDatePicker && (
                                                <TimeRangePicker />
                                            )}
                                        </TimeRangePickerLargeContainer>
                                    ) : null}
                                </div>
                            ) : null}
                        </HeadTextContainer>
                    </HeadContentContainer>
                    {/* If we don't have a lower row of buttons, we should align the download
                         and time range buttons with the text */}
                    {!this.props.showShareContentButton &&
                    !(this.props.callback && this.props.callbackString) ? (
                        <div
                            style={{ display: "flex", alignItems: "flex-end" }}
                        >
                            {this.props.showCsvButton &&
                                this.props.csvDownload &&
                                this.props.csvTooltipMessage && (
                                    <CsvDownloadButtonContainer
                                        style={{
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <DownloadButton
                                            loading={
                                                !!this.props.csvButtonLoading
                                            }
                                            size={rootStyles.ButtonSize.small}
                                            id={`${this.props.id}_CsvButton-ProfileHead`}
                                            tooltipMessage={
                                                this.props.csvTooltipMessage
                                            }
                                            onClick={this.props.csvDownload}
                                        />
                                    </CsvDownloadButtonContainer>
                                )}
                            {!this.props.hideDatePicker ? (
                                <TimeRangePickerLargeContainer>
                                    {!this.props.hideDatePicker && (
                                        <TimeRangePicker />
                                    )}
                                </TimeRangePickerLargeContainer>
                            ) : null}
                        </div>
                    ) : null}
                </StackedWrapper>
            </CommonHeaderContainer>
        );
    }

    private getDropdownItems = (message: string) => {
        return (
            <Dropdown.Item
                onSelect={() => {
                    this.props.callback && this.props.callback();
                }}
                style={rootStyles.buttonStyles.dropDownStyles.dropdownItem}
            >
                {generateDropdownOption(
                    IconsList.ic_important,
                    message,
                    `${this.props.id}_ReportIssue`
                )}
            </Dropdown.Item>
        );
    };
}

// Just using `style` for this will override any styles, need specific divs
type MobileStyleProps = {
    mobileStyleOverride?: React.CSSProperties;
};

const MobileStyleWrappers: React.FC<
    React.PropsWithChildren<MobileStyleProps>
> = (props) => {
    return <div {...props} />;
};

const Title = styled(styledTitle.h2)`
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        text-align: center;
    }
`;

const SmallTitle = styled(styledTitle.h2_xl)`
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        text-align: center;
    }
`;

const CommonHeaderContainer = styled.div`
    margin-top: ${rootStyles.spacers.micro}px;
    margin-bottom: ${rootStyles.spacers.base}px;
    min-height: 100px;
    @media (max-width: 992px) {
        margin-top: 80px;
        height: auto;
    }
`;

const StackedWrapper = styled(Row)`
    flex-direction: row;
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        flex-direction: column;
    }
`;

const ArtistImgWrapper = styled(Row)`
    height: ${rootStyles.spacers.epic}px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: ${rootStyles.spacers.medium}px;
    @media (max-width: 992px) {
        display: none;
        margin-top: 0;
    }
`;

const ArtistImgContainer = styled.div`
    @media (max-width: 992px) {
        display: none;
        margin-top: 0;
    }
`;

const TimeRangePickerSmallContainer = styled.div`
    margin-top: ${rootStyles.spacers.base}px;
    padding: 0 ${rootStyles.spacers.base}px;
    margin-bottom: 16px;
    @media (min-width: ${rootStyles.breakpoints.lg + 1}px) {
        display: none;
    }
`;

const TimeRangePickerLargeContainer = styled.div`
    padding: 0 ${rootStyles.spacers.base}px;
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        display: none;
    }
`;

const CsvDownloadButtonContainer = styled(Col)`
    flex: 0;
    align-self: flex-end;
    padding: 0;
`;

const PhantomContainer = styled.div`
    width: ${ButtonSize.small}px;
    @media (min-width: ${rootStyles.breakpoints.lg + 1}px) {
        display: none;
    }
`;

const CenteredMobileButtonsContainer = styled.div`
    flex: 1;
    display: flex;
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        justify-content: center;
    }
`;

const HeadContentContainer = styled(MobileStyleWrappers)`
    display: flex;
    flex: 1;
    padding-left: ${rootStyles.spacers.base}px;
    padding-right: ${rootStyles.spacers.base}px;
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        flex-direction: column;
        align-items: ${(props) =>
            props.mobileStyleOverride?.alignItems || "flex-start"};
        order: 2;
    }
`;

const HeadTextContainer = styled(MobileStyleWrappers)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    @media (max-width: 992px) {
        margin-top: ${rootStyles.spacers.base}px;
    }
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        align-items: ${(props) =>
            props.mobileStyleOverride?.alignItems || "flex-start"};
        width: 100%;
    }
`;

const MediaImageContainer = styled.div`
    padding-right: ${rootStyles.spacers.base}px;
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        padding: 0px;
    }
`;

const subtitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    flex: 1,
    textAlign: "left",
    color: rootStyles.colors.accent,
    margin: 0,
    height: 20,
};

function mapStateToProps(state: RootState): StateProps {
    return {
        bundleMap: state.localization.bundleMap,
    };
}

export const CommonHeader = connect(mapStateToProps)(CommonHeaderClass);
