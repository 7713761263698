import { TeamRole, TeamPermissions } from "./";

export type teamManagementMember = {
    name: string;
    invite?: invite;
    access: TeamRole;
    permissions: TeamPermissions[];
    memberId: string;
    company?: string;
    jobTitle: JobTitle;
};

export type invite = {
    email: string;
    date: Date;
    inviteUrl?: string;
};

export enum JobTitle {
    Manager = "Manager",
    Artist = "Artist",
    LabelRep = "LabelRep",
}
