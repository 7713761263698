import { spacers } from "./styles";
import * as colorStyles from "./colors";

export const headerContainerStyle: React.CSSProperties = {
    flexDirection: "row",
    borderColor: colorStyles.glassColors.primary3,
    alignItems: "flex-start",
    justifyContent: "center",
    borderWidth: 0,
};
export const headerTextContainer: React.CSSProperties = {
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    borderWidth: 0,
    display: "flex",
    flex: 1,
};
export const containerStyle: React.CSSProperties = {
    borderWidth: "0 !important",
    minHeight: 48,
};
export const containerWithBottomBorder: React.CSSProperties = {
    borderBottomWidth: 1,
    borderColor: colorStyles.glassColors.primary2,
    paddingBottom: spacers.mini,
};
export const textContainer: React.CSSProperties = {
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    borderWidth: 0,
    display: "flex",
    flex: 1,
};
export const textContainerLoading: React.CSSProperties = {
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "stretch",
    margin: 4,
    borderWidth: 0,
};
export const imageLoading: React.CSSProperties = {
    width: 56,
    height: 56,
    borderRadius: 4,
    marginRight: spacers.base,
    marginTop: spacers.mini,
    marginBottom: spacers.mini,
    alignSelf: "center",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: colorStyles.colors.primary,
    opacity: colorStyles.glass._1,
};
export const indexContainer: React.CSSProperties = {
    alignSelf: "center",
    alignItems: "flex-start",
    textAlign: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    padding: 0,
    maxWidth: 24,
    borderWidth: 0,
    flex: 0,
    display: "flex",
};
export const statContainer: React.CSSProperties = {
    textAlign: "right",
    alignSelf: "center",
    justifyContent: "flex-end",
    borderWidth: 0,
    width: 100, // per spec
    display: "flex",
};
export const statContainerLoading: React.CSSProperties = {
    alignSelf: "flex-end",
    alignItems: "flex-end",
    textAlign: "right",
    justifyContent: "center",
    maxHeight: 20,
    width: 50,
    backgroundColor: colorStyles.colors.primary,
    opacity: colorStyles.glass._1,
    borderWidth: 0,
};
export const primaryLoading: React.CSSProperties = {
    alignSelf: "stretch",
    alignItems: "flex-end",
    justifyContent: "center",
    height: 20,
    width: 100,
    maxHeight: 20,
    maxWidth: 100,
    backgroundColor: colorStyles.colors.primary,
    opacity: colorStyles.glass._1,
};
export const secondaryLoading: React.CSSProperties = {
    alignSelf: "stretch",
    alignItems: "flex-end",
    marginTop: 2,
    height: 16,
    maxHeight: 16,
    width: 60,
    maxWidth: 60,
    justifyContent: "center",
    backgroundColor: colorStyles.colors.primary,
    opacity: colorStyles.glass._1,
};
