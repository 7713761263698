import * as React from "react";
import { Col, Row, Image } from "react-bootstrap";
import _ from "lodash";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { LargeSolidButton, styledTitle } from "../../components";
import { buildUIClickPayload, getLocalizedString, paths } from "../../../utils";
import * as rootStyles from "../../styles";
import { merchImages, stringIds, bundleIds } from "../../../assets";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clientMetricsActions } from "../../../store/actions";
import { showModSelector, commonSelectors } from "../../../store/selectors";
import { BundleMap, EntityType } from "../../../models";

const testIDPrefix = "MerchFTUDisplay";

type Props = {
    merchCuratePermission: boolean;
    hasMerchIngressPermissions: boolean;
};

export const MerchOverviewFTUDisplay: React.FC<Props> = ({
    merchCuratePermission,
    hasMerchIngressPermissions,
}) => {
    const history = useHistory();
    const showMod = useSelector(showModSelector);
    const dispatch = useDispatch();
    const artistAsin = useSelector(commonSelectors.getSelectedArtistAsin);
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const toManualSearchScreen = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.FTUOpenSearch,
                    pageIds.artistMerchHomePage,
                    artistAsin,
                    EntityType.ARTIST
                )
            )
        );
        history.push(paths.manualSearchMerch);
    };
    const toMODLandingPage = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.showMoDLanding,
                    pageIds.artistMerchHomePage,
                    artistAsin,
                    EntityType.ARTIST
                )
            )
        );
        history.push(paths.modLanding);
    };
    return (
        <Col>
            <MerchFTUCardContainer>
                <MerchFTUCard>
                    <ImageContainer>
                        <Image
                            style={{
                                position: "relative",
                                width: 178,
                                bottom: -50,
                            }}
                            src={merchImages.alreadyHaveMerch}
                        />
                    </ImageContainer>
                    <styledTitle.h4 style={primaryTitleStyle}>
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCHCURATION_STRINGS,
                            stringId:
                                stringIds.Merch.Curation
                                    .LandingPageCurationCardTitle,
                        })}
                    </styledTitle.h4>
                    <span style={secondaryTitleStyle}>
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCHCURATION_STRINGS,
                            stringId:
                                stringIds.Merch.Curation
                                    .LandingPageCurationCardSubTitle,
                        })}
                    </span>
                    {merchCuratePermission ? (
                        <LargeSolidButton
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCHCURATION_STRINGS,
                                stringId:
                                    stringIds.Merch.Curation
                                        .LandingPageCurationCardButtonCTA,
                            })}
                            containerStyle={buttonContainerStyle}
                            onClick={toManualSearchScreen}
                            id={`${testIDPrefix}_CurateMerchCTA`}
                        />
                    ) : (
                        <span style={secondaryTitleStyle}>
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCHCURATION_STRINGS,
                                stringId:
                                    stringIds.Merch.Curation
                                        .LandingPageCurationCardContactAdmin,
                            })}
                        </span>
                    )}
                </MerchFTUCard>
                {showMod && (
                    <>
                        <Col xs="auto" style={centerFlexboxStyle}>
                            <div
                                style={{
                                    fontSize: rootStyles.webFontSizes.t1,
                                    textTransform: "capitalize",
                                    margin: 0,
                                    padding: 0,
                                    color: rootStyles.colors.primary,
                                }}
                            >
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                                    stringId: stringIds.Merch.Curation.OR,
                                })}
                            </div>
                        </Col>
                        <MerchFTUCard>
                            <ImageContainer>
                                <Image
                                    //taken from figma mocks
                                    style={{
                                        position: "relative",
                                        width: 194,
                                        bottom: -30,
                                    }}
                                    src={merchImages.createMerch}
                                />
                            </ImageContainer>
                            <styledTitle.h4 style={primaryTitleStyle}>
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                                    stringId:
                                        stringIds.Merch.Curation
                                            .LandingPageMODCardTitle,
                                })}
                            </styledTitle.h4>
                            <span style={secondaryTitleStyle}>
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                                    stringId:
                                        stringIds.Merch.Curation
                                            .LandingPageMODCardSubtitle,
                                })}
                            </span>
                            {hasMerchIngressPermissions ? (
                                <LargeSolidButton
                                    title={getLocalizedString(bundleMap, {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .LandingPageMODCardButtonCTA,
                                    })}
                                    containerStyle={buttonContainerStyle}
                                    onClick={toMODLandingPage}
                                    id={`${testIDPrefix}_MoDCardCTA`}
                                />
                            ) : (
                                <span style={secondaryTitleStyle}>
                                    {getLocalizedString(bundleMap, {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .LandingPageMODCardContactAdmin,
                                    })}
                                </span>
                            )}
                        </MerchFTUCard>
                    </>
                )}
            </MerchFTUCardContainer>
            <BottomFTUDisplay>
                <span
                    style={{
                        ...rootStyles.textStyles.secondary,
                        textAlign: "center",
                    }}
                >
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .LandingPageCurationLearnMoreTitle,
                    })}
                    <span
                        style={{
                            color: rootStyles.colors.accent,
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            window.open(
                                "https://artists.amazonmusic.com/merch#listarticle-2",
                                "_blank",
                                "noreferrer, noopener"
                            )
                        }
                    >
                        {" "}
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCHCURATION_STRINGS,
                            stringId:
                                stringIds.Merch.Curation
                                    .LandingPageCurationLearnMoreAction,
                        })}
                    </span>
                </span>
            </BottomFTUDisplay>
        </Col>
    );
};

const ImageContainer = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: url(${merchImages.backgroundGradient});
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    aspect-ratio: 1;
`;

const MerchFTUCardContainer = styled(Row)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

const centerFlexboxStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

const BottomFTUDisplay = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${rootStyles.spacers.large}px;
    border-bottom-color: ${rootStyles.glassColors.primary4};
    border-bottom-width: 1px;
    border-bottom-style: solid;
`;

const MerchFTUCard = styled(Col)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${rootStyles.glassColors.primary2};
    padding: ${rootStyles.spacers.giant}px;
    margin-top: ${rootStyles.spacers.large}px;
    margin-bottom: ${rootStyles.spacers.large}px;
    border-radius: 8px;
    flex-direction: column;
    @media (max-width: 992px) {
        margin-top: ${rootStyles.spacers.medium}px;
        padding-top: ${rootStyles.spacers.medium}px;
        margin-bottom: ${rootStyles.spacers.medium}px;
        aspect-ratio: auto;
    }
`;

const primaryTitleStyle: React.CSSProperties = {
    textAlign: "center",
    marginTop: rootStyles.spacers.medium,
};

const secondaryTitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    textAlign: "center",
    marginTop: rootStyles.spacers.small,
};

const buttonContainerStyle: React.CSSProperties = {
    marginTop: rootStyles.spacers.large,
};
