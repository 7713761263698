import React, { PropsWithChildren, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { cookieStrings, localStorageStrings } from "../../../models";
import {
    getUrlParam,
    goToSignInURl,
    hasCookieForKey,
    setCsrfToken,
    signOut,
} from "../../../service/common";
import { opsMetricsActions, RootState, userActions } from "../../../store";
import { getDeviceInformation, paths } from "../../../utils";
import { CustomScrollbar } from "../../styles/CustomScrollbar";
import * as Optout from "../../containers/optout";
import LandingScreen from "../../containers/auth/LandingScreen";
import { Header, Loading } from "../common";
import { usePrevious } from "../hooks";
import { bundleMapLoadInProgress } from "../../../store/selectors/commonSelectors";

export const LogIn: React.FC<{}> = (props: PropsWithChildren<{}>) => {
    const shouldRefreshToken: boolean = useSelector(
        (state: RootState) => state.user.shouldRefreshToken
    );
    const locale: string = useSelector((state: RootState) => state.user.locale);
    const signedIn: boolean = useSelector(
        (state: RootState) => state.user.signedIn
    );
    const previousSignedIn = usePrevious(signedIn);
    const bundleMapLoading = useSelector(bundleMapLoadInProgress);
    const dispatch = useDispatch();

    const [logOutInProgress, setLogOutInProgress] = useState(false);

    const getOptOutExternalId = (): string | null => {
        if (window.location.pathname.includes(paths.optOut)) {
            return getUrlParam("id");
        }
        return null;
    };

    // If signedIn state moves to false, toggle logOutInProgress to true.
    // logOutInProgress state returns to false as login component is remounted
    // when we navigate to the signout link and back.
    useEffect(() => {
        if (!signedIn && previousSignedIn) {
            setLogOutInProgress(true);
        }
    }, [signedIn]);

    useEffect(() => {
        /**
         * If the user is signed in, set the device info for ops metrics
         * and start the timed emitter
         */
        if (signedIn && !previousSignedIn) {
            dispatch(
                opsMetricsActions.setOpsMetricsDeviceInfo(
                    getDeviceInformation()
                )
            );
            dispatch(opsMetricsActions.startTimedEmitter());
        } else if (!signedIn && previousSignedIn) {
            dispatch(opsMetricsActions.stopTimedEmitter());
        }
    }, [signedIn]);

    const deeplinkUnauthenticatedRoute = (): any => {
        return (
            <Container
                id={"Container.OptOut"}
                fluid={true}
                style={{ padding: 0 }}
            >
                <CustomScrollbar />
                <Router>
                    <Header />
                    <Switch>
                        <Route
                            path={paths.optOut}
                            component={Optout.OptOutScreen}
                        />
                    </Switch>
                </Router>
            </Container>
        );
    };

    const getInviteId = (): string | null => {
        const isAcceptInvitePath = _.includes(
            window.location.pathname,
            paths.acceptInvite
        );

        if (isAcceptInvitePath) {
            let splitPath = window.location.pathname.split("/");
            const inviteId = splitPath[splitPath.length - 1];
            if (!_.includes(paths.acceptInvite, inviteId)) {
                return inviteId;
            }
        }
        return null;
    };

    const getDeeplinkURL = (): string | undefined => {
        const url = new URL(document.URL);
        if (url.pathname.startsWith("/link/v2/")) {
            return document.URL;
        }
        return undefined;
    };

    const hasToken = (): boolean => {
        return (
            hasCookieForKey(cookieStrings.xUS) ||
            hasCookieForKey(cookieStrings.xJP)
        );
    };

    const hasCSRFToken = (): boolean => {
        const csrfToken: string | null = getUrlParam("csrfToken");

        if (csrfToken) {
            const cleanCsrfToken = csrfToken.replace(new RegExp(" ", "g"), "+");
            localStorage.setItem(localStorageStrings.csrf, cleanCsrfToken);
            setCsrfToken(axios, cleanCsrfToken);
            dispatch(userActions.setCsrfToken(cleanCsrfToken));
            return true;
        } else {
            // We already have a token
            const localCsrfToken = localStorage.getItem(
                localStorageStrings.csrf
            );
            if (localCsrfToken) {
                setCsrfToken(axios, localCsrfToken);
                dispatch(userActions.setCsrfToken(localCsrfToken));
                return true;
            }
        }
        return false;
    };

    const render = (props: PropsWithChildren<{}>): JSX.Element => {
        const isJapan = hasCookieForKey(cookieStrings.xJP);
        const directedId = getOptOutExternalId();
        const inviteId = getInviteId();
        const deeplinkURL = getDeeplinkURL();
        // is SignoutPath
        if (window.location.pathname.includes(paths.signOut)) {
            signOut();
            return <></>;
        }

        // need both conditions to consistently return Loading screen as soon as signedIn state turns to false
        // useEffect hooks only run after renders
        if ((!signedIn && previousSignedIn) || logOutInProgress) {
            return <Loading />;
        }
        if (directedId) return deeplinkUnauthenticatedRoute();
        if (inviteId) {
            signOut(() =>
                localStorage.setItem(localStorageStrings.inviteId, inviteId)
            );
            return <></>;
        }

        if (!signedIn && deeplinkURL) {
            localStorage.setItem(localStorageStrings.deeplinkUrl, deeplinkURL);
            goToSignInURl(isJapan, locale);
        }

        if (shouldRefreshToken) {
            if (deeplinkURL) {
                localStorage.setItem(
                    localStorageStrings.deeplinkUrl,
                    deeplinkURL
                );
            }
            goToSignInURl(isJapan, locale);
            return <></>;
        }
        if (bundleMapLoading) {
            return <Loading />;
        }
        if (!hasToken()) return <LandingScreen />;
        if (!hasCSRFToken()) {
            if (deeplinkURL) {
                localStorage.setItem(
                    localStorageStrings.deeplinkUrl,
                    deeplinkURL
                );
            }
            goToSignInURl(isJapan, locale);
            return <></>;
        }
        return <>{props.children}</>;
    };

    return <>{render(props)}</>;
};
