import { Action } from "redux-ts";
import _ from "lodash";
import * as localizationActions from "../actions/localizationActions";
import { Bundle, BundleMap } from "../../models";

export type LocalizationState = Readonly<{
    failedToResolveStringSet: Set<string>;
    bundleMap: BundleMap;
}>;

export const initialLocalizationState: LocalizationState = {
    failedToResolveStringSet: new Set(),
    bundleMap: new Map<string, Bundle>(),
};

export const localizationReducer = (
    state: LocalizationState = initialLocalizationState,
    action: Action
) => {
    switch (action.type) {
        case localizationActions.addFailedToResolveString.type: {
            return {
                ...state,
                failedToResolveStringSet: state.failedToResolveStringSet.add(
                    action.payload.stringKey
                ),
            };
        }
        case localizationActions.clearBundleMap.type: {
            return initialLocalizationState;
        }
        case localizationActions.addBundle.type:
            const newBundleMap = _.cloneDeep(state.bundleMap);
            newBundleMap.set(action.payload.bundleId, action.payload.bundle);
            return {
                ...state,
                bundleMap: newBundleMap,
            };
        default:
            return state;
    }
};
