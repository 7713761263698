import React, { Dispatch } from "react";
import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import { MerchOverviewFTUDisplay } from "./MerchOverviewFTUDisplay";
import { MerchCarouselDisplay } from "./MerchCarouselDisplay";
import { connect } from "react-redux";
import { globalNotificationsActions } from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { getMerchCollection } from "../../../store/selectors";
import * as rootStyles from "../../styles";
import _ from "lodash";
import {
    BundleMap,
    GlobalModal,
    merchCollection,
    ModalRenderFunction,
} from "../../../models";
import { getLocalizedString } from "../../../utils";
import { Icon, IconsList } from "../common";
import { AnyAction } from "redux";
import { AboutMerchCurationModal } from "./modals";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

const testIDPrefix = "MoDAndCurateDisplay";

type ViewProps = {
    merchCuratePermission: boolean;
    hasMerchIngressPermissions: boolean;
    showMod: boolean;
};

type StateProps = {
    collection?: merchCollection;
    bundleMap: BundleMap;
};

type DispatchProps = {
    requestModal: (payload: ModalRenderFunction) => void;
};

type Props = ViewProps & StateProps & DispatchProps;

class ModAndCurateDisplay extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        let display;
        const showAboutMerchCurationModal = (
            dismiss: () => void,
            isVisible: boolean
        ): GlobalModal => {
            return (
                <AboutMerchCurationModal
                    onDismiss={dismiss}
                    isVisible={isVisible}
                />
            );
        };
        if (!this.props.collection || !this.props.collection.items.length) {
            display = (
                <MerchOverviewFTUDisplay
                    merchCuratePermission={this.props.merchCuratePermission}
                    hasMerchIngressPermissions={
                        this.props.hasMerchIngressPermissions
                    }
                />
            );
        } else {
            display = (
                <MerchCarouselDisplay
                    merchItems={this.props.collection.items}
                    hasMerchIngressPermissions={
                        this.props.hasMerchIngressPermissions
                    }
                    showMod={this.props.showMod}
                    id={`${testIDPrefix}_Carousel`}
                />
            );
        }
        return (
            <>
                <div
                    style={{
                        ...rootStyles.textStyles.primary,
                        position: "relative",
                        paddingLeft: rootStyles.bootstrapColPadding,
                        paddingRight: rootStyles.bootstrapColPadding,
                    }}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation.OverviewFeatureContext,
                    })}
                    <Icon
                        size={rootStyles.icons.tiny}
                        icon={IconsList.ic_help}
                        style={{
                            opacity: rootStyles.glass._4,
                            marginLeft: rootStyles.spacers.mini,
                            marginBottom: rootStyles.spacers.micro,
                        }}
                        onClick={() =>
                            this.props.requestModal(showAboutMerchCurationModal)
                        }
                        id={`${testIDPrefix}_HelpIcon`}
                    />
                </div>
                {display}
            </>
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return {
        collection: getMerchCollection(state),
        ...bundleMapSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        requestModal: (payload: ModalRenderFunction) =>
            dispatch(globalNotificationsActions.requestModalDisplay(payload)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModAndCurateDisplay);
