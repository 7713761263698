import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row, Image } from "react-bootstrap";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import _ from "lodash";
import styled from "styled-components";
import {
    testIDSuffixes,
    getLocalizedString,
    paths,
    buildUIClickPayload,
    buildUIPageViewPayload,
} from "../../../utils";
import * as rootStyles from "../../styles";
import { merchImages, stringIds, bundleIds } from "../../../assets";
import { LargeSolidButton, styledTitle } from "../../components";
import { clientMetricsActions } from "../../../store/actions";
import { getSelectedArtistAsin } from "../../../store/selectors/commonSelectors";
import { BundleMap, cookieStrings, EntityType } from "../../../models";
import { hasCookieForKey } from "../../../service/common";
import { commonSelectors } from "../../../store/selectors";

type MODBannerProps = {
    hasMerchIngressPermissions: boolean;
};

const testIDPrefix = "MoDBanner";
export const MODBanner: React.FC<MODBannerProps> = ({
    hasMerchIngressPermissions,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const selectedArtistAsin = useSelector(getSelectedArtistAsin);
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const isJPAccountPoolUser = hasCookieForKey(cookieStrings.xJP);

    const emitMetrics = (key: string, pageId: string) => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    key,
                    pageId,
                    selectedArtistAsin,
                    EntityType.ARTIST
                )
            )
        );
    };

    useEffect(() => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIPageViewPayload(
                    pageIds.artistMerchMoDBanner,
                    selectedArtistAsin
                )
            )
        );
    }, []);

    const goToModLanding = () => {
        emitMetrics(
            buttonIds.Merch.showMoDLanding,
            pageIds.artistMerchMoDBanner
        );
        history.push(paths.modLanding);
    };

    return (
        <CreateMerchBannerContainer>
            <Col>
                <ImageBannerRowContainer>
                    <MODImage
                        id={`${testIDPrefix}${testIDSuffixes.image}`}
                        src={merchImages.createMerch}
                    />
                </ImageBannerRowContainer>
            </Col>
            <TextBannerContainer>
                <MODLogoImage src={merchImages.amazonMODBanner} />
                <StyledBannerTitle
                    id={`${testIDPrefix}${testIDSuffixes.label}`}
                >
                    <styledTitle.h4
                        style={{
                            textTransform: "none",
                        }}
                    >
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCH_STRINGS,
                            stringId: stringIds.Merch.merch_mod_banner_title,
                        })}
                    </styledTitle.h4>
                </StyledBannerTitle>
                <StyledTextRow>
                    {hasMerchIngressPermissions && isJPAccountPoolUser && (
                        <div>
                            <StyledBannerSubtitle>
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_banner_noJP_Text,
                                })}
                            </StyledBannerSubtitle>
                        </div>
                    )}
                    {hasMerchIngressPermissions && !isJPAccountPoolUser && (
                        <div>
                            <LargeSolidButton
                                title={getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                                    stringId:
                                        stringIds.Merch.Curation
                                            .MODPrimaryButtonAlt2,
                                })}
                                onClick={goToModLanding}
                                id={`${testIDPrefix}${testIDSuffixes.button}`}
                            />
                        </div>
                    )}
                    {!hasMerchIngressPermissions && (
                        <>
                            <div>
                                <StyledBannerSubtitle>
                                    {getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.MERCH_STRINGS,
                                        stringId:
                                            stringIds.Merch
                                                .merch_mod_banner_noPermission_Text,
                                    })}
                                </StyledBannerSubtitle>
                            </div>
                        </>
                    )}
                </StyledTextRow>
                {!hasMerchIngressPermissions && (
                    <>
                        <div>
                            <StyledBannerSubtitleItalics>
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_banner_noJP_Text,
                                })}
                            </StyledBannerSubtitleItalics>
                        </div>
                    </>
                )}
            </TextBannerContainer>
        </CreateMerchBannerContainer>
    );
};

//max-wdith value of 767 comes from standard tablet width of 768
const TextBannerContainer = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: ${rootStyles.spacers.medium}px;
    padding-bottom: ${rootStyles.spacers.medium}px;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        align-items: center;
        padding-bottom: 0px;
        justify-self: flex-start;
    }
`;

const StyledBannerTitle = styled(Row)`
    margin-bottom: ${rootStyles.spacers.medium}px;

    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        text-align: center;
    }
`;

const StyledTextRow = styled(Row)`
    display: flex;
    flex-direction: column;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        text-align: center;
    }
`;

const StyledBannerSubtitle = styled.span`
    ${{ ...rootStyles.textStyles.secondary }}
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        text-align: center;
    }
`;

const StyledBannerSubtitleItalics = styled.span`
    ${{ ...rootStyles.textStyles.secondary }}
    font-style: italic;
    align-self: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: ${rootStyles.spacers.small}px;
    margin-right: ${rootStyles.spacers.small}px;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        position: relative;
    }
`;

//styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101
const MODImage = styled(Image)`
    width: 411px;
    position: absolute;
    bottom: -150px;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        width: 313px;
        bottom: -120px;
    }
`;

//styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101
const MODLogoImage = styled(Image)`
    width: 254px;
    height: 27px;
    margin-bottom ${rootStyles.spacers.base}px;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        width: 188px;
        height: 20px;
    }
`;

//styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101
const ImageBannerRowContainer = styled(Row)`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 282px;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        height: 204px;
    }
`;

const CreateMerchBannerContainer = styled(Row)`
    position: relative;
    border-radius: 10px;
    margin: 15px;
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-image: url(${merchImages.backgroundGradient});
    background-position: center center;
    background-size: 100% 100%;
    overflow: hidden;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        align-items: center;
    }
`;
