import axios from "axios";
import { outageStatusEntry } from "../models";
import { getEndpoints } from "./common";

const baseUrls = getEndpoints();

export async function getOutageStatus(): Promise<outageStatusEntry[]> {
    const result = await buildRequest(baseUrls.outageStatus);
    return result.data;
}

function buildRequest(url: string) {
    return axios.get(url);
}
