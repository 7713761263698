import axios, { AxiosResponse } from "axios";
import { getEndpoints, testHeaders } from "./common";
import { artistImagePayload } from "../models";

const artistControlUrl = getEndpoints().artistControl;
const imageUploadEndpoint = `${artistControlUrl}/artist/images/upload`;

export async function uploadImage(payload: artistImagePayload) {
    const result = await axios.post(
        imageUploadEndpoint,
        {
            artistAsin: payload.asin,
            imageType: payload.imageType,
            fileExtension: payload.fileExtension,
        },
        {
            headers: {
                "x-team-id": payload.teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function uploadCASImage(
    file: Blob,
    casUrl: string
): Promise<AxiosResponse> {
    const data = new FormData();
    data.append("file", file);

    return await axios.post(casUrl, data, {
        withCredentials: false,
        headers: null,
    });
}
