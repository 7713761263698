import React, { useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {} from "@amzn/arb-tools";
import {
    BundleMap,
    artist,
    artistSearchQueryPayload,
    group,
    itemProps,
    tag,
} from "../../../models";
import { BoxList, Dropdown, StepField, styledTitle } from "../common";
import * as rootStyles from "../../styles";
import {
    artistSearchActions,
    pitchFormActions,
    RootState,
    commonSelectors,
} from "../../../store";
import { AnyAction, Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
    getLocalizedString,
    getUpdatedGroupsWithItemProperties,
    selectedInGroupsToItemPropsList,
    paths,
    tagListToGroupList,
    tagListToItemProps,
} from "../../../utils";
import _ from "lodash";
import { ImageList, stringIds, bundleIds } from "../../../assets";

export type PitchMoreInfoProps = {
    songMoods: tag[];
    songStyles: tag[];
    relatedArtists: artist[];
    locations: tag[];
};

const PitchMoreInfoPrefix = "PitchMoreInfo";
enum relatedGroupsIndex {
    SELECTED_ARTISTS = 0,
    SEARCH_RESULTS = 1,
    SUGGESTED_ARTISTS = 2,
}
const COMMON_WIDTH = 450;
const MAX_MOOD_SELECTION = 3;
const MAX_SONG_STYLE_SELECTION = 3;
const MAX_RELATED_ARTISTS_SELECTION = 3;

export const PitchMoreInfo: React.FC<PitchMoreInfoProps> = ({
    songMoods,
    songStyles,
    relatedArtists,
    locations,
}) => {
    // Dispatch
    const dispatch: Dispatch<AnyAction> = useDispatch();

    // Selectors
    const songMoodsProps = useSelector(
        (state: RootState) => state.pitchForm.songMoodsProps
    );
    const songStylesProps = useSelector(
        (state: RootState) => state.pitchForm.songStylesProps
    );

    const relatedArtistsProps = useSelector(
        (state: RootState) => state.pitchForm.relatedAritstProps
    );
    const locationProps = useSelector(
        (state: RootState) => state.pitchForm.locationProps
    );
    const artistSearchResults: artist[] = useSelector(
        (state: RootState) => state.artistSearch.artists
    );
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const artistSearchAction = useCallback(
        (payload: artistSearchQueryPayload) => {
            dispatch(artistSearchActions.artistSearch(payload));
        },
        [dispatch]
    );

    const makeArtistSearchQuery = (artistSearch: string) => {
        const payload: artistSearchQueryPayload = {
            query: artistSearch,
            requestPath: paths.pitch,
            musicTerritory: "US",
        };

        artistSearchAction(payload);
    };

    // Callbacks
    const setSongMoodsProps = useCallback(
        (songMoods: itemProps[]) => {
            dispatch(pitchFormActions.setSongMoodsProps(songMoods));
        },
        [dispatch]
    );

    const setSongStylesProps = useCallback(
        (songStyles: itemProps[]) => {
            dispatch(pitchFormActions.setSongStylesProps(songStyles));
        },
        [dispatch]
    );

    const setRelatedArtistsProps = useCallback(
        (artistsProps: group[]) => {
            dispatch(pitchFormActions.setRelatedArtistProps(artistsProps));
        },
        [dispatch]
    );

    const setLocationProps = useCallback(
        (locations: group[]) => {
            dispatch(pitchFormActions.setLocationProps(locations));
        },
        [dispatch]
    );

    const makeSearchQuery = useCallback(
        _.debounce((text: string) => makeArtistSearchQuery(text), 2000),
        []
    );

    const clearSearchArtistResults = useCallback(
        () => dispatch(artistSearchActions.clearArtistSearch()),
        [dispatch]
    );

    //Hooks
    useEffect(() => {
        if (!songMoodsProps.length) {
            setSongMoodsProps(tagListToItemProps(songMoods));
        }

        if (!songStylesProps.length) {
            setSongStylesProps(tagListToItemProps(songStyles));
        }

        if (!locationProps.length) {
            setLocationProps(tagListToGroupList(locations));
        }

        if (!relatedArtistsProps.length) {
            setRelatedArtistsProps(
                artistListToGroupList(
                    [],
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formSelectedArtistsLabel,
                    })
                )
            );
        }
        clearSearchArtistResults();
    }, []);

    const artistListToGroupList = (
        artists: artist[],
        groupName: string
    ): group[] => {
        return [
            {
                properties: { name: groupName, code: "", selected: false },
                items: artists.map((artist) => {
                    return {
                        code: artist.asin || "",
                        name: artist.title || "",
                        selected: false,
                        hide: false,
                        image: artist.images?.artSmall,
                        fallbackImage: ImageList.placeholder_artist,
                    };
                }),
            },
        ];
    };

    const getGroup = (groups: group[], index: number): group | undefined => {
        return groups.length > 0 && index < groups.length
            ? groups[index]
            : undefined;
    };

    const getRelatedArtistsPropsFromGroups = (groups: group[]): group[] => {
        const newGroups = _.cloneDeep(groups);
        const itemsGroup0 = getSelectedItems(
            groups[relatedGroupsIndex.SELECTED_ARTISTS]
        );
        const itemsGroup1 = getSelectedItems(
            groups[relatedGroupsIndex.SEARCH_RESULTS]
        );
        const itemsGroup2 = getSelectedItems(
            groups[relatedGroupsIndex.SUGGESTED_ARTISTS]
        );
        newGroups[relatedGroupsIndex.SELECTED_ARTISTS].items = [
            ...itemsGroup0,
            ...itemsGroup1,
            ...itemsGroup2,
        ];
        return [newGroups[relatedGroupsIndex.SELECTED_ARTISTS]];
    };

    const getNotAlreadySelectedResults = (
        group: group | undefined,
        artists: artist[]
    ): artist[] => {
        if (group === undefined) {
            return artists;
        }

        return artists.filter(
            (artist) =>
                !_.reduce(
                    group.items,
                    (result: boolean, item: itemProps) =>
                        result || item.code === artist.asin,
                    false
                )
        );
    };

    const getSelectedItems = (group: group): itemProps[] => {
        return group.items.filter((item) => item.selected);
    };
    const pitchMoreInfoHeader = () => {
        return (
            <>
                <Row>
                    <styledTitle.h4>
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formMoreInfoTitle,
                        })}
                    </styledTitle.h4>
                </Row>
                <Row>
                    <span style={rootStyles.textStyles.secondary}>
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formMoreInfoSubtitle,
                        })}
                    </span>
                </Row>
            </>
        );
    };

    const pitchMoreInfoFields = () => {
        const songMoods = () => {
            return (
                <>
                    <Row style={{ marginBottom: rootStyles.spacers.micro }}>
                        <span
                            style={{
                                ...rootStyles.textStyles.tertiary,
                                textTransform: "uppercase",
                            }}
                        >
                            {getLocalizedString(
                                bundleMap,
                                {
                                    bundleId: bundleIds.PITCH_STRINGS,
                                    stringId: stringIds.Pitch.formSelectUpTo,
                                },
                                { "0": MAX_MOOD_SELECTION.toString() }
                            )}
                        </span>
                    </Row>
                    <Row>
                        <BoxList
                            onChange={(boxPropertiesList: itemProps[]) =>
                                setSongMoodsProps(boxPropertiesList)
                            }
                            showCancelIcon={false}
                            itemsProps={songMoodsProps}
                            maxSelect={MAX_MOOD_SELECTION}
                            id={`${PitchMoreInfoPrefix}-SongMoods`}
                        />
                    </Row>
                </>
            );
        };

        const songStyle = () => {
            return (
                <>
                    <Row style={{ marginBottom: rootStyles.spacers.micro }}>
                        <span
                            style={{
                                ...rootStyles.textStyles.tertiary,
                                textTransform: "uppercase",
                            }}
                        >
                            {getLocalizedString(
                                bundleMap,
                                {
                                    bundleId: bundleIds.PITCH_STRINGS,
                                    stringId: stringIds.Pitch.formSelectUpTo,
                                },
                                { "0": MAX_SONG_STYLE_SELECTION.toString() }
                            )}
                        </span>
                    </Row>
                    <Row>
                        <BoxList
                            onChange={(boxPropertiesList: itemProps[]) =>
                                setSongStylesProps(boxPropertiesList)
                            }
                            showCancelIcon={false}
                            itemsProps={songStylesProps}
                            maxSelect={MAX_SONG_STYLE_SELECTION}
                            id={`${PitchMoreInfoPrefix}-SongStyles`}
                        />
                    </Row>
                </>
            );
        };

        const similarArtists = () => {
            return (
                <>
                    <Row style={{ marginBottom: rootStyles.spacers.micro }}>
                        <span
                            style={{
                                ...rootStyles.textStyles.tertiary,
                                textTransform: "uppercase",
                            }}
                        >
                            {getLocalizedString(
                                bundleMap,
                                {
                                    bundleId: bundleIds.PITCH_STRINGS,
                                    stringId: stringIds.Pitch.formSelectUpTo,
                                },
                                {
                                    "0": MAX_RELATED_ARTISTS_SELECTION.toString(),
                                }
                            )}
                        </span>
                    </Row>
                    <div style={{ maxWidth: COMMON_WIDTH }}>
                        <Dropdown
                            groups={[
                                ...relatedArtistsProps,
                                ...artistListToGroupList(
                                    getNotAlreadySelectedResults(
                                        getGroup(relatedArtistsProps, 0),
                                        artistSearchResults
                                    ),
                                    getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.PITCH_STRINGS,
                                        stringId:
                                            stringIds.Pitch
                                                .formSearchResultsLabel,
                                    })
                                ),
                                ...artistListToGroupList(
                                    getNotAlreadySelectedResults(
                                        getGroup(relatedArtistsProps, 0),
                                        relatedArtists
                                    ),
                                    getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.PITCH_STRINGS,
                                        stringId:
                                            stringIds.Pitch
                                                .formSuggestedArtistsLabel,
                                    })
                                ),
                            ]}
                            onChange={(groups) => {
                                const newRelatedArtistsProps: group[] =
                                    getRelatedArtistsPropsFromGroups(groups);
                                setRelatedArtistsProps(newRelatedArtistsProps);
                            }}
                            onTextChange={(text) => {
                                makeSearchQuery(text);
                            }}
                            maxSelect={MAX_RELATED_ARTISTS_SELECTION}
                            filterResults={false}
                            id={`${PitchMoreInfoPrefix}-SimilarArtists`}
                            placeholder={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId:
                                    stringIds.Pitch
                                        .formSimilarArtistsPlaceholder,
                            })}
                            groupItemsNotFoundProps={{
                                groupIndex:
                                    relatedGroupsIndex.SUGGESTED_ARTISTS,
                                message: `0 ${getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.PITCH_STRINGS,
                                    stringId:
                                        stringIds.Pitch
                                            .formSuggestedArtistsLabel,
                                })}`,
                            }}
                        />
                    </div>
                    <div style={{ marginTop: rootStyles.spacers.base }}>
                        <BoxList
                            showCancelIcon={true}
                            itemsProps={selectedInGroupsToItemPropsList(
                                relatedArtistsProps
                            )}
                            onChange={(itemsProps: itemProps[]) => {
                                const newGroups =
                                    getUpdatedGroupsWithItemProperties(
                                        itemsProps,
                                        relatedArtistsProps
                                    );

                                const newGroup = getGroup(newGroups, 0);
                                if (newGroup) {
                                    newGroup.items = getSelectedItems(
                                        newGroups[0]
                                    );
                                    setRelatedArtistsProps([newGroup]);
                                }
                            }}
                            id={`${PitchMoreInfoPrefix}-SimilarArtists`}
                        />
                    </div>
                </>
            );
        };

        const listenersLocations = () => {
            return (
                <>
                    <div style={{ maxWidth: 450 }}>
                        <Dropdown
                            groups={locationProps}
                            onChange={(groups) => {
                                setLocationProps(groups);
                            }}
                            maxSelect={1}
                            id={`${PitchMoreInfoPrefix}-Locations`}
                            placeholder={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId:
                                    stringIds.Pitch
                                        .formListenersLocationPlaceholder,
                            })}
                        />
                    </div>
                    <div style={{ marginTop: rootStyles.spacers.base }}>
                        <BoxList
                            showCancelIcon={true}
                            itemsProps={selectedInGroupsToItemPropsList(
                                locationProps
                            )}
                            onChange={(itemsProps: itemProps[]) => {
                                const newGroups =
                                    getUpdatedGroupsWithItemProperties(
                                        itemsProps,
                                        locationProps
                                    );
                                setLocationProps(newGroups);
                            }}
                            id={`${PitchMoreInfoPrefix}-Locations`}
                        />
                    </div>
                </>
            );
        };

        return (
            <div style={{ marginBottom: "8vh" }}>
                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formSimilarAritstsTitle,
                    })}
                    id={`${PitchMoreInfoPrefix}-SimilarArtists`}
                    bottomElement={similarArtists()}
                    bottomElementIndent={false}
                />

                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formListenersLocationTitle,
                    })}
                    id={`${PitchMoreInfoPrefix}-Locations`}
                    bottomElement={listenersLocations()}
                    bottomElementIndent={false}
                />
                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formSongMoodTitle,
                    })}
                    id={`${PitchMoreInfoPrefix}-SongMoods`}
                    bottomElement={songMoods()}
                    bottomElementIndent={false}
                />

                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formSongStyleTitle,
                    })}
                    id={`${PitchMoreInfoPrefix}-SongStyles`}
                    bottomElement={songStyle()}
                    bottomElementIndent={false}
                />
            </div>
        );
    };

    return (
        <Row style={{ marginTop: rootStyles.spacers.medium }}>
            <Col>
                {pitchMoreInfoHeader()}
                {pitchMoreInfoFields()}
            </Col>
        </Row>
    );
};

export default PitchMoreInfo;
