import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { CSSProperties } from "react";

export type CustomListHeaderProps = {
    primary?: string;
    secondary?: string;
    stat1?: string;
    stat2?: string;
    stat3?: string;
    style?: any;
    id: string;
};

export class CustomListHeader extends React.PureComponent<CustomListHeaderProps> {
    render() {
        return (
            <Row style={{ ...headerRow, ...this.props.style }}>
                <Col style={{ padding: 0 }}>
                    <Container fluid={true} style={{ padding: 0, margin: 0 }}>
                        <Row>
                            <Col
                                id={this.props.id + "-Primary"}
                                lg={true}
                                sm={6}
                                xs={8}
                                style={textContainer}
                            >
                                <span style={rootStyles.textStyles.index}>
                                    {this.props.primary}
                                </span>
                            </Col>
                            {this.props.secondary ? (
                                <Col
                                    id={this.props.id + "-Secondary"}
                                    lg={true}
                                    sm={3}
                                    className="d-none d-sm-block"
                                    style={textContainer}
                                >
                                    <span style={rootStyles.textStyles.index}>
                                        {this.props.secondary}
                                    </span>
                                </Col>
                            ) : null}
                            {this.props.stat1 ? (
                                <Col
                                    id={this.props.id + "-Stat1"}
                                    className="d-none d-sm-block"
                                    style={textContainer}
                                >
                                    <span style={rootStyles.textStyles.index}>
                                        {this.props.stat1}
                                    </span>
                                </Col>
                            ) : null}
                            {this.props.stat2 ? (
                                <Col
                                    id={this.props.id + "-Stat2"}
                                    lg={true}
                                    sm={3}
                                    xs={4}
                                    style={textContainer}
                                >
                                    <span style={rootStyles.textStyles.index}>
                                        {this.props.stat2}
                                    </span>
                                </Col>
                            ) : null}
                        </Row>
                    </Container>
                </Col>
                {this.props.stat3 ? (
                    <Col
                        id={this.props.id + "-Stat3"}
                        xs={"auto"}
                        style={{ textAlign: "right", width: 130 }}
                    >
                        <span style={rootStyles.textStyles.index}>
                            {this.props.stat3}
                        </span>
                    </Col>
                ) : null}
            </Row>
        );
    }
}

const headerRow: CSSProperties = {
    ...rootStyles.tableStyles.headerContainerStyle,
    borderBottomWidth: 1,
    borderStyle: "solid",
    marginBottom: rootStyles.spacers.small,
    borderBottomColor: rootStyles.glassColors.primary3,
    minHeight: 36,
    marginTop: 24,
};

const textContainer: CSSProperties = {
    textAlign: "left",
};
