import { createSelector } from "reselect";
import _ from "lodash";
import { Feature, TeamPermissions, TeamType } from "../../../models";
import {
    getSelectedTeamSelector,
    getUserPermissionsSelector,
} from "../teamManagementSelectors";
import { RootState } from "../../reducers";
import { getIsPrivileged } from "../commonSelectors";

const getHasMoDIngressFeaturePermission = (state: RootState) => {
    return (
        state.featureAccess.featurePermissionsList.length !== 0 &&
        !!_.find(state.featureAccess.featurePermissionsList, (permission) => {
            return permission.feature === Feature.MOD_INGRESS;
        })
    );
};

const getHasCurateFeaturePermission = (state: RootState) => {
    return (
        state.featureAccess.featurePermissionsList.length !== 0 &&
        !!_.find(state.featureAccess.featurePermissionsList, (permission) => {
            return permission.feature === Feature.MerchCuration;
        })
    );
};

export const showModSelector = createSelector(
    [
        getHasMoDIngressFeaturePermission,
        getSelectedTeamSelector,
        getIsPrivileged,
    ],
    (hasMoDIngressFeaturePermission, selectedTeam, isPrivileged) => {
        if (!selectedTeam) {
            return false;
        }

        // only show MoD ONLY to users on Artist Team
        const isValidTeamType =
            selectedTeam?.teamType === TeamType.ARTIST && !isPrivileged;

        return hasMoDIngressFeaturePermission && isValidTeamType;
    }
);

export const showCurationSelector = createSelector(
    [getHasCurateFeaturePermission],
    (hasCurateFeaturePermission) => hasCurateFeaturePermission
);

export const getHasMerchIngressPermissions = createSelector(
    [getUserPermissionsSelector],
    (userPermissions) => {
        return !!_.find(userPermissions, (permission) => {
            return permission === TeamPermissions.MerchOnDemandIngress;
        });
    }
);

export const getMerchSearchInProgressSelector = (state: RootState) =>
    state.merchCurate.merchSearchInProgress;
export const getMerchSearchResultsSelector = (state: RootState) =>
    state.merchCurate.merchSearchResultItems;

export const getMerchCollection = (state: RootState) =>
    state.merchCurate.merchCollection;
export const getMerchCollectionsInProgress = (state: RootState) =>
    state.merchCurate.getMerchCollectionsInProgress;

export const getMerchCatalog = (state: RootState) =>
    state.merchHydrator.merchCatalog;
export const getHydratingIdentifiers = (state: RootState) =>
    state.merchHydrator.hydratingIdentifiers;

export const getMerchCollectionsCompleted = (state: RootState) =>
    state.merchCurate.getMerchCollectionsCompleted;

export const hydrateMerchCompleted = (state: RootState) =>
    state.merchHydrator.hydrateMerchCompleted;

export const getActiveCollectionIdSelector = (state: RootState) =>
    state.merchCurate.merchCollection?.collectionId;

export const getCurateMerchInProgress = (state: RootState) =>
    state.merchCurate.curateMerchInProgress;

export const getShouldDisplayMerchCurationBanner = createSelector(
    [getMerchCollection, getHasCurateFeaturePermission],
    (collection, hasFeaturePermission) => !collection && hasFeaturePermission
);

export const hasCurateMerchPermission = createSelector(
    [getUserPermissionsSelector],
    (permissions) => {
        return permissions.includes(TeamPermissions.CurateMerch);
    }
);

//will delete when MOD is merged into branch
export const hasMODPermission = createSelector(
    [getUserPermissionsSelector],
    (permissions) => {
        return permissions.includes(TeamPermissions.MerchOnDemandIngress);
    }
);
