import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { Dropdown, Col, Row, Container } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import {
    TeamRole,
    TeamType,
    teamInfo,
    JobTitle,
    teamManagementMember,
    TeamPermissions,
    artist,
    inviteTeamMemberPayload,
    setPermissionsPayload,
    setMemberInfoPayload,
    removeMemberPayload,
    promoteToOwnerPayload,
    cancelInvitePayload,
    METRIC_KEYS,
    telemetryPayload,
    cookieStrings,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    getLocalizedString,
    translateJobTitleToString,
    paths,
    GENERIC_TEXT_FIELD_LIMIT,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import {
    TextField,
    MediumOutlineButton,
    IconsList,
    RadioList,
    LargeSolidButton,
    styledTitle,
    MediumSolidButton,
    CommonHeader,
} from "../../components";
import {
    teamManagementActions,
    RootState,
    ModifyMemberType,
    inviteTeamMembersScreenSelector,
    telemetryActions,
    userActions,
} from "../../../store";
import { ListItem } from "../../components/lists";
import { FullScreenConfirmationModal } from "../../components/common/modals";
import { dropDownStyles, buttonSharedStyles } from "../../styles/buttonStyles";
import Cookies from "js-cookie";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";
import styled from "styled-components";

const testIDPrefix = "ModifyTeamMemberScreen";
const metricPrefix = "modifyTeamMemberPage";

type ViewProps = {
    memberId: string;
};

type StateProps = {
    teamMembers: teamManagementMember[];
    invitedMembers: teamManagementMember[];
    error?: string;
    inviteSent: boolean;
    modifyMemberInProgress: boolean;
    modifyMemberSuccess: boolean;
    userRole?: TeamRole;
    userPermissions?: TeamPermissions[];
    teamId: string;
    modifyType: ModifyMemberType;
    artist?: artist;
    team: teamInfo;
    bundleMap: BundleMap;
};

type DispatchProps = {
    submitInvite: (data: inviteTeamMemberPayload) => void;
    submitUpdatePermissions: (data: setPermissionsPayload) => void;
    submitUpdateMemberInfo: (data: setMemberInfoPayload) => void;
    submitRemove: (data: removeMemberPayload) => void;
    submitPromoteToOwner: (data: promoteToOwnerPayload) => void;
    submitCancelInvite: (data: cancelInvitePayload) => void;
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    name: string;
    email: string;
    jobTitle: JobTitle;
    company?: string;
    artist?: artist;
    showMenu: boolean;
    showPromoteToOwnerModal: boolean;
    selectedAccessIndex?: number;
    modifyInProgress: boolean;
    modifySuccess: false;
    showPermissionsList: boolean;
    modifyType: ModifyMemberType;
    selectedMember?: teamManagementMember;
    inviteSent: boolean;
};

const AdminAccessIndex = 0;
const DataAccessIndex = 1;

class ModifyTeamMemberScreen extends React.Component<Props, State> {
    private jobTitles = [JobTitle.Artist, JobTitle.Manager, JobTitle.LabelRep];

    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            email: "",
            jobTitle: JobTitle.Manager,
            showMenu: false,
            showPromoteToOwnerModal: false,
            company: "",
            modifyInProgress: false,
            modifySuccess: false,
            showPermissionsList: false,
            modifyType: this.props.modifyType,
            inviteSent: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.inviteTeamMember],
            ]),
        });
        if (!this.props.teamId) {
            this.props.history?.replace(paths.currentTeamMembers);
            return;
        }

        this.setState({
            artist: this.props.artist,
        });

        const memberId = this.props.match.params.memberId;
        const state: any = this.props.location.state;
        const member =
            this.props.teamMembers.find((m) => m.memberId === memberId) ||
            this.props.invitedMembers.find(
                (m) =>
                    m.memberId === memberId &&
                    m.invite?.email === state.invitedMember?.email
            );

        if (!member) {
            return;
        }

        this.setState({
            name: member.name,
            company: member.company,
            jobTitle: member.jobTitle,
            selectedAccessIndex: this.translateTeamRoleToAccessIndex(
                member.access
            ),
            selectedMember: member,
        });
    }

    componentDidUpdate(prevProps: StateProps) {
        if (this.props.modifyType !== prevProps.modifyType) {
            this.setState({ modifyType: this.props.modifyType });
        }

        if (
            this.props.modifyMemberInProgress !==
            prevProps.modifyMemberInProgress
        ) {
            this.setState({
                modifyInProgress: this.props.modifyMemberInProgress,
            });
        }

        // This is if we SetPermissions, SetMemberInfo, or RemoveMember. We want to go back to the team screen in this case.
        if (this.props.modifyMemberSuccess && !prevProps.modifyMemberSuccess) {
            this.props.history?.replace(paths.currentTeamMembers);
        }

        // If we updated the team member, then we want to refresh the state.
        if (
            this.props.teamMembers &&
            prevProps.teamMembers !== this.props.teamMembers &&
            this.state.modifyType !== ModifyMemberType.Add &&
            this.state.selectedMember !== undefined
        ) {
            const selectedMember: teamManagementMember =
                this.state.selectedMember;
            const updatedMember = _.find(
                this.props.teamMembers,
                (member) => member.memberId === selectedMember.memberId
            );
            if (updatedMember) {
                this.setState({
                    name: updatedMember.name,
                    company: updatedMember.company,
                    jobTitle: updatedMember.jobTitle,
                    selectedAccessIndex: this.translateTeamRoleToAccessIndex(
                        updatedMember.access
                    ),
                    selectedMember:
                        this.transformMemberToMutable(updatedMember),
                });
            }
        }

        // If we resent an invite, we want to go back to the team screen
        if (this.props.inviteSent && !prevProps.inviteSent) {
            this.props.history?.replace(paths.currentTeamMembers);
        }
    }

    render() {
        const useTeamUi =
            this.props.team.teamType === TeamType.VENDOR ||
            this.props.team.teamType === TeamType.LABEL;

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {!useTeamUi && (
                    <div>
                        <CommonHeader
                            title={this.state.name}
                            id={`${testIDPrefix}_Header`}
                            hideArtistImgDropdown={true}
                            hideDatePicker={true}
                        />
                        <Row>
                            <Col>
                                {this.state.selectedMember &&
                                    this.state.selectedMember.access !=
                                        TeamRole.Owner &&
                                    this.userHasPermission(
                                        TeamPermissions.RemoveMember
                                    ) &&
                                    this.state.selectedMember.memberId !==
                                        Cookies.get(cookieStrings.userId) &&
                                    !this.isViewingInvite() && (
                                        <MediumSolidButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.MANAGECURRENTTEAM_STRINGS,
                                                    stringId:
                                                        stringIds
                                                            .ManageCurrentTeam
                                                            .revokeAccess,
                                                }
                                            )}
                                            containerStyle={
                                                revokeAccesssButtonStyle
                                            }
                                            onClick={this.pressSubmitRemove}
                                            id={
                                                testIDPrefix +
                                                "_RevokeAccessButton"
                                            }
                                            disabled={
                                                this.state.modifyInProgress
                                            }
                                        />
                                    )}
                            </Col>
                        </Row>
                    </div>
                )}
                {useTeamUi && (
                    <HeaderContainer>
                        <Row>
                            <Col>
                                <p
                                    style={{
                                        ...labelStyle,
                                        marginTop: rootStyles.spacers.small,
                                    }}
                                    id={`${testIDPrefix}_TeamNameLabel`}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                        stringId:
                                            stringIds.ProvideYourInformation
                                                .team,
                                    })}
                                </p>
                                <p
                                    style={{
                                        ...rootStyles.textStyles.primary,
                                        marginTop: rootStyles.spacers.small,
                                    }}
                                    id={`${testIDPrefix}_TeamNameText`}
                                >
                                    {this.props.team.teamName}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p
                                    style={{
                                        ...labelStyle,
                                        marginTop: rootStyles.spacers.small,
                                    }}
                                    id={`${testIDPrefix}_FullNameLabel`}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                        stringId:
                                            stringIds.ProvideYourInformation
                                                .name,
                                    })}
                                </p>
                                <p
                                    style={{
                                        ...rootStyles.textStyles.primary,
                                        marginTop: rootStyles.spacers.small,
                                    }}
                                    id={`${testIDPrefix}_FullNameText`}
                                >
                                    {this.state.name}
                                </p>
                            </Col>
                        </Row>
                    </HeaderContainer>
                )}
                {this.state.selectedMember?.invite && this.isViewingInvite() && (
                    <Row>
                        <Col>
                            <p
                                style={{
                                    ...labelStyle,
                                    marginTop: rootStyles.spacers.small,
                                }}
                                id={`${testIDPrefix}_EmailLabel`}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                    stringId:
                                        stringIds.ProvideYourInformation.email,
                                })}
                            </p>
                            <p
                                style={{
                                    ...rootStyles.textStyles.primary,
                                    marginTop: rootStyles.spacers.small,
                                }}
                                id={`${testIDPrefix}_EmailText`}
                            >
                                {this.state.selectedMember.invite.email}
                            </p>
                        </Col>
                    </Row>
                )}
                {this.hasUpdatePermission() && !this.isViewingInvite() ? (
                    <Row style={{ alignItems: "center" }}>
                        <Col
                            xs={12}
                            sm={6}
                            style={{ marginTop: rootStyles.spacers.large }}
                        >
                            <p
                                style={labelStyle}
                                id={`${testIDPrefix}_CompanyLabel`}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                    stringId:
                                        stringIds.ProvideYourInformation
                                            .companyLiteral,
                                })}
                            </p>
                            <TextField
                                label={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                        stringId:
                                            stringIds.ProvideYourInformation
                                                .companyOptional,
                                    }
                                )}
                                value={this.state.company}
                                onChange={(value: string) =>
                                    this.setState({ company: value })
                                }
                                maxLength={GENERIC_TEXT_FIELD_LIMIT}
                                id={testIDPrefix + "_CompanyTextField"}
                            />
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            style={{
                                justifyContent: "flex-start",
                                alignSelf: "flex-end",
                                marginTop: rootStyles.spacers.large,
                            }}
                        >
                            <p
                                style={{
                                    ...labelStyle,
                                    marginTop: rootStyles.spacers.small,
                                }}
                                id={`${testIDPrefix}_RoleLabel`}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                    stringId:
                                        stringIds.ProvideYourInformation
                                            .roleLiteral,
                                })}
                            </p>
                            <Dropdown
                                style={{
                                    ...dropDownStyles.dropdown,
                                    maxWidth: 200,
                                }}
                                alignRight={true}
                                className="centerPointer"
                            >
                                <Dropdown.Toggle
                                    id="jobPicker"
                                    style={{
                                        ...dropDownStyles.dropdownButton,
                                        ...buttonSharedStyles.large,
                                        minWidth: 200,
                                    }}
                                >
                                    {translateJobTitleToString(
                                        this.state.jobTitle,
                                        this.props.bundleMap
                                    )}
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                    style={dropDownStyles.dropdownMenu}
                                >
                                    {this.getJobTitles()}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                ) : null}
                {(this.isViewingInvite() || !this.hasUpdatePermission()) &&
                    this.state.company !== undefined &&
                    this.state.company !== "" && (
                        <Row style={rowStyle}>
                            <Col>
                                <p
                                    style={{
                                        ...labelStyle,
                                        marginTop: rootStyles.spacers.small,
                                    }}
                                    id={`${testIDPrefix}_CompanyLabel`}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                        stringId:
                                            stringIds.ProvideYourInformation
                                                .companyOptional,
                                    })}
                                </p>
                                <p
                                    style={{
                                        ...rootStyles.textStyles.primary,
                                        marginTop: rootStyles.spacers.small,
                                    }}
                                    id={`${testIDPrefix}_CompanyText`}
                                >
                                    {this.state.company}
                                </p>
                            </Col>
                        </Row>
                    )}

                <Row style={rowStyle}>
                    <Col>
                        {!this.isViewingInvite() &&
                        this.hasUpdatePermission() &&
                        this.state.selectedMember &&
                        this.state.selectedMember.access !== TeamRole.Owner ? (
                            <RadioList
                                elements={this.getRadioItems()}
                                label={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.MANAGECURRENTTEAM_STRINGS,
                                        stringId:
                                            stringIds.ManageCurrentTeam
                                                .assignAccessLevel,
                                    }
                                )}
                                initialSelectedIndex={
                                    this.state.selectedAccessIndex
                                }
                                onChangeElement={this.onChangeTeamRole}
                                id={testIDPrefix + "_RoleRadioInput"}
                            />
                        ) : (
                            <div>
                                <p
                                    style={{ ...labelStyle }}
                                    id={`${testIDPrefix}_AccessLevelLabel`}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.MANAGECURRENTTEAM_STRINGS,
                                        stringId:
                                            stringIds.ManageCurrentTeam
                                                .accessLevel,
                                    })}
                                </p>
                                <ListItem
                                    {...this.getAccessLevelInfo(
                                        this.state.selectedMember
                                            ? this.state.selectedMember.access
                                            : TeamRole.Owner
                                    )}
                                    isLast={true}
                                    style={{ marginLeft: -15 }}
                                    disabled={true}
                                    id={testIDPrefix + "_AccessLevel"}
                                />
                            </div>
                        )}
                    </Col>
                </Row>

                <Row style={rowStyle}>
                    <Col xs={3}>
                        {!this.isViewingInvite() &&
                            this.props.userRole === TeamRole.Owner &&
                            this.state.selectedMember &&
                            this.state.selectedMember.access !==
                                TeamRole.Owner && (
                                <MediumOutlineButton
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.PROVIDEYOURINFORMATION_STRINGS,
                                            stringId:
                                                stringIds.ProvideYourInformation
                                                    .promoteToOwner,
                                        }
                                    )}
                                    containerStyle={actionButtonStyle}
                                    onClick={this.pressPromoteToOwner}
                                    id={testIDPrefix + "_PromoteToOwnerButton"}
                                    disabled={this.state.modifyInProgress}
                                />
                            )}
                    </Col>
                </Row>

                <Row style={rowStyle}>
                    <Col xs={3}>
                        {useTeamUi &&
                            this.state.selectedMember &&
                            this.state.selectedMember.access !=
                                TeamRole.Owner &&
                            this.userHasPermission(
                                TeamPermissions.RemoveMember
                            ) &&
                            this.state.selectedMember.memberId !==
                                Cookies.get(cookieStrings.userId) &&
                            !this.isViewingInvite() && (
                                <MediumOutlineButton
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.MANAGECURRENTTEAM_STRINGS,
                                            stringId:
                                                stringIds.ManageCurrentTeam
                                                    .revokeAccess,
                                        }
                                    )}
                                    containerStyle={{
                                        ...actionButtonStyle,
                                        marginTop: rootStyles.spacers.small,
                                        marginBottom: rootStyles.spacers.small,
                                    }}
                                    onClick={this.pressSubmitRemove}
                                    id={testIDPrefix + "_RevokeAccessButton"}
                                    disabled={this.state.modifyInProgress}
                                />
                            )}
                        {!this.isViewingInvite() &&
                            (this.userHasPermission(
                                TeamPermissions.SetMemberInfo
                            ) ||
                                this.userHasPermission(
                                    TeamPermissions.SetPermissions
                                )) && (
                                <LargeSolidButton
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.MANAGECURRENTTEAM_STRINGS,
                                            stringId:
                                                stringIds.ManageCurrentTeam
                                                    .saveChange,
                                        }
                                    )}
                                    containerStyle={actionButtonStyle}
                                    disabled={
                                        this.state.name === undefined ||
                                        this.state.name === "" ||
                                        this.state.jobTitle === undefined ||
                                        this.state.selectedAccessIndex ===
                                            undefined ||
                                        this.state.modifyInProgress
                                    }
                                    onClick={this.pressSubmitUpdate}
                                    id={testIDPrefix + "_SubmitChangesButton"}
                                />
                            )}
                    </Col>
                </Row>

                <Row style={rowStyle}>
                    <Col xs={12}>
                        {this.isViewingInvite() &&
                            this.userHasPermission(
                                TeamPermissions.SendInvite
                            ) && (
                                <LargeSolidButton
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.MANAGECURRENTTEAM_STRINGS,
                                            stringId:
                                                stringIds.ManageCurrentTeam
                                                    .resendInvite,
                                        }
                                    )}
                                    containerStyle={actionButtonStyle}
                                    disabled={
                                        this.state.modifyInProgress ||
                                        this.state.inviteSent
                                    }
                                    onClick={this.pressSubmitInvite}
                                    id={testIDPrefix + "_ResendInviteButton"}
                                />
                            )}
                    </Col>
                </Row>

                <FullScreenConfirmationModal
                    icon={IconsList.navigation_profile}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId:
                            stringIds.ManageCurrentTeam.transferOwnershipTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId:
                            stringIds.ManageCurrentTeam
                                .transferOwnershipDescription,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                            stringId:
                                stringIds.ManageCurrentTeam
                                    .confirmOwnershipTransfer,
                        }
                    )}
                    cancelButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId:
                            stringIds.ManageCurrentTeam.cancelOwnershipTransfer,
                    })}
                    confirmButtonAction={this.onConfirmPromoteToOwner}
                    cancelButtonAction={this.onCancelPromoteToOwner}
                    onDismiss={this.onCancelPromoteToOwner}
                    isVisible={this.state.showPromoteToOwnerModal}
                    id={testIDPrefix + "_PromoteToOwnerConfirmationModal"}
                />
            </Container>
        );
    }

    private onConfirmPromoteToOwner = () => {
        const memberId = this.props.match.params.memberId;
        const member = this.props.teamMembers.find(
            (m) => m.memberId === memberId
        );

        this.props.userAction({
            name: metricPrefix + "ConfirmPromoteOwnerButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.invitedTeamMembers],
            ]),
        });

        if (!member) {
            return;
        }

        this.props.submitPromoteToOwner({
            teamId: this.props.teamId,
            memberId: member.memberId,
            requestPath: paths.manageTeamMember,
        });

        this.setState({ showPromoteToOwnerModal: false });
    };

    private onCancelPromoteToOwner = () => {
        this.props.userAction({
            name: metricPrefix + "CancelPromoteOwnerButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.invitedTeamMembers],
            ]),
        });
        this.setState({ showPromoteToOwnerModal: false });
    };

    private getJobTitles = () => {
        return this.jobTitles.map((item: JobTitle) => {
            return (
                <Dropdown.Item
                    key={translateJobTitleToString(item, this.props.bundleMap)}
                    eventKey={translateJobTitleToString(
                        item,
                        this.props.bundleMap
                    )}
                    onSelect={() => this.onJobTitleChangeCallback(item)}
                    style={dropDownStyles.dropdownItem}
                >
                    <Row>
                        <Col>
                            {translateJobTitleToString(
                                item,
                                this.props.bundleMap
                            )}
                        </Col>
                    </Row>
                </Dropdown.Item>
            );
        });
    };

    private onJobTitleChangeCallback = (item: JobTitle) => {
        this.setState({ showMenu: false, jobTitle: item });
    };

    private onChangeTeamRole = (itemIndex: number) => {
        this.setState({
            selectedAccessIndex: itemIndex,
            showPermissionsList: itemIndex === 2,
        });
    };

    private getRadioItems = () => {
        return [
            this.getAccessLevelInfo(TeamRole.Admin),
            this.getAccessLevelInfo(TeamRole.Viewer),
        ];
    };

    private getAccessLevelInfo(accessLevel: TeamRole) {
        switch (accessLevel) {
            case TeamRole.Owner:
                return {
                    primary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ROLES_STRINGS,
                        stringId: stringIds.Roles.teamRoleOwner,
                    }),
                    secondary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId: stringIds.ManageCurrentTeam.adminDescription,
                    }),
                };
            case TeamRole.Admin:
                return {
                    primary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ROLES_STRINGS,
                        stringId: stringIds.Roles.teamRoleAdmin,
                    }),
                    secondary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId: stringIds.ManageCurrentTeam.adminDescription,
                    }),
                };
            case TeamRole.Viewer:
                return {
                    primary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ROLES_STRINGS,
                        stringId: stringIds.Roles.teamRoleViewer,
                    }),
                    secondary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                        stringId: stringIds.ManageCurrentTeam.dataDescription,
                    }),
                };
            case TeamRole.Custom:
                return {
                    primary: getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ROLES_STRINGS,
                        stringId: stringIds.Roles.teamRoleCustom,
                    }),
                };
        }
    }

    private pressSubmitInvite = () => {
        if (
            this.state.inviteSent ||
            (this.isViewingInvite() &&
                (!this.state.selectedMember ||
                    !this.state.selectedMember.invite))
        ) {
            return;
        }

        this.props.submitInvite({
            teamId: this.props.teamId,
            name: this.state.name,
            email:
                this.isViewingInvite() && this.state.selectedMember?.invite
                    ? this.state.selectedMember.invite.email
                    : this.state.email,
            jobTitle: this.state.jobTitle,
            company: this.state.company,
            access: this.translateAccessIndexToTeamRole(
                this.state.selectedAccessIndex !== undefined
                    ? this.state.selectedAccessIndex
                    : 1
            ),
            permissions: [],
            requestPath: paths.inviteTeamMember,
            isResend: true,
        });

        this.setState({ inviteSent: true });
    };

    private pressPromoteToOwner = () => {
        this.setState({ showPromoteToOwnerModal: true });
    };

    private pressSubmitUpdate = () => {
        const memberId = this.props.match.params.memberId;
        const member = this.props.teamMembers.find(
            (m) => m.memberId === memberId
        );
        if (!member) {
            return;
        }

        const newRole: TeamRole = this.translateAccessIndexToTeamRole(
            this.state.selectedAccessIndex !== undefined
                ? this.state.selectedAccessIndex
                : 1
        );
        const newCompany = this.state.company;
        const newJobTitle = this.state.jobTitle;

        if (
            member.access !== TeamRole.Owner &&
            newRole !== member.access &&
            this.userHasPermission(TeamPermissions.SetPermissions)
        ) {
            this.props.submitUpdatePermissions({
                teamId: this.props.teamId,
                role: newRole,
                permissions: [],
                memberId: memberId,
                requestPath: paths.inviteTeamMember,
            });
        }

        if (
            (newCompany !== member.company ||
                newJobTitle !== member.jobTitle) &&
            this.userHasPermission(TeamPermissions.SetMemberInfo)
        ) {
            this.props.submitUpdateMemberInfo({
                teamId: this.props.teamId,
                memberId: memberId,
                company: newCompany,
                jobTitle: newJobTitle,
                requestPath: paths.inviteTeamMember,
            });
        }
    };

    private pressSubmitRemove = () => {
        const memberId = this.props.match.params.memberId;
        const member = this.props.teamMembers.find(
            (m) => m.memberId === memberId
        );

        this.props.userAction({
            name: metricPrefix + "SubmitRemoveButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.invitedTeamMembers],
            ]),
        });

        if (!member) {
            return;
        }

        if (this.userHasPermission(TeamPermissions.RemoveMember)) {
            this.props.submitRemove({
                teamId: this.props.teamId,
                memberId: member.memberId,
                requestPath: paths.inviteTeamMember,
            });
        }
    };

    private transformMemberToMutable(
        member: teamManagementMember
    ): teamManagementMember {
        const invite = member.invite
            ? {
                  ...member.invite,
                  date: member.invite.date,
              }
            : undefined;

        return {
            ...member,
            invite: invite,
            permissions: member.permissions && member.permissions,
        };
    }

    private translateAccessIndexToTeamRole(index: number): TeamRole {
        if (index === AdminAccessIndex) {
            return TeamRole.Admin;
        } else {
            return TeamRole.Viewer;
        }
    }

    private translateTeamRoleToAccessIndex(role: TeamRole): number {
        switch (role) {
            case TeamRole.Viewer:
                return DataAccessIndex;
            case TeamRole.Admin:
                return AdminAccessIndex;
            default:
                return DataAccessIndex;
        }
    }

    private isViewingInvite() {
        return (
            this.state.selectedMember?.invite &&
            this.state.modifyType !== ModifyMemberType.Add
        );
    }

    private hasUpdatePermission() {
        return (
            this.userHasPermission(TeamPermissions.SetPermissions) &&
            this.userHasPermission(TeamPermissions.SetMemberInfo)
        );
    }

    private userHasPermission(teamPermission: TeamPermissions) {
        return (
            this.props.userPermissions &&
            this.props.userPermissions.includes(teamPermission)
        );
    }
}

const revokeAccesssButtonStyle: React.CSSProperties = {
    alignSelf: "stretch",
    width: 160,
};
const actionButtonStyle: React.CSSProperties = {
    alignSelf: "stretch",
    width: 260,
};
const labelStyle: React.CSSProperties = {
    ...rootStyles.textStyles.label,
    textTransform: "uppercase",
    color: rootStyles.glassColors.primary4,
};
const rowStyle: React.CSSProperties = {
    marginTop: rootStyles.spacers.large,
    alignItems: "center",
};
function mapStateToProps(state: RootState) {
    return {
        ...inviteTeamMembersScreenSelector(state),
        ...bundleMapSelector(state),
    };
}

const HeaderContainer = styled.div`
    margin-top: ${rootStyles.spacers.micro}px;
    @media (max-width: 992px) {
        margin-top: 80px;
    }
`;

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        submitInvite: (payload: inviteTeamMemberPayload) =>
            dispatch(teamManagementActions.inviteTeamMember(payload)),
        submitUpdatePermissions: (payload: setPermissionsPayload) =>
            dispatch(teamManagementActions.setPermissions(payload)),
        submitUpdateMemberInfo: (payload: setMemberInfoPayload) =>
            dispatch(teamManagementActions.setMemberInfo(payload)),
        submitRemove: (payload: removeMemberPayload) =>
            dispatch(teamManagementActions.removeMember(payload)),
        submitPromoteToOwner: (payload: promoteToOwnerPayload) =>
            dispatch(teamManagementActions.promoteToOwner(payload)),
        submitCancelInvite: (payload: cancelInvitePayload) =>
            dispatch(teamManagementActions.cancelInvite(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ModifyTeamMemberScreen)
);
