import { createSelector } from "reselect";
import { RootState } from "../reducers";

const getGlobalNotificationState = (state: RootState) =>
    state.globalNotifications;

export const getActiveModalRenderFunction = (state: RootState) =>
    getGlobalNotificationState(state).modal;

const getToast = (state: RootState) => getGlobalNotificationState(state).toast;

export const globalModalControllerSelector = createSelector(
    [getActiveModalRenderFunction],
    (activeModalRenderFunction) => ({
        modalRenderFunction: activeModalRenderFunction,
    })
);

export const globalToastControllerSelector = createSelector(
    [getToast],
    (toast) => ({ toast })
);
