import _ from "lodash";
import { RootState } from "../..";
import { GlobalFeature, FeatureEffect } from "../../../models/featureAccess";

const generateBlockedPartnerButtonSelector = (partner: GlobalFeature) => {
    const blockedPartnerPermission = {
        effect: FeatureEffect.BLOCKED,
        feature: partner,
    };
    const getBlockedPartnerButtonPermission = (state: RootState): boolean => {
        return (
            state.featureAccess.globalFeaturePermissionsList.length !== 0 &&
            !!_.find(
                state.featureAccess.globalFeaturePermissionsList,
                blockedPartnerPermission
            )
        );
    };
    return getBlockedPartnerButtonPermission;
};

export const getBlockedInstagramButton = generateBlockedPartnerButtonSelector(
    GlobalFeature.INSTAGRAMV3
);

export const getBlockedTwitterButton = generateBlockedPartnerButtonSelector(
    GlobalFeature.TWITTER
);

export const getBlockedCdBabyButton = generateBlockedPartnerButtonSelector(
    GlobalFeature.CDBABY
);

export const getBlockedTunecoreButton = generateBlockedPartnerButtonSelector(
    GlobalFeature.TUNECORE
);

export const getBlockedTunecoreJpButton = generateBlockedPartnerButtonSelector(
    GlobalFeature.TUNECORE_JP
);

export const getBlockedDistrokidButton = generateBlockedPartnerButtonSelector(
    GlobalFeature.DISTROKID
);
