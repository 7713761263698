import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AnyAction } from "redux";
import {
    BundleMap,
    METRIC_KEYS,
    optOut,
    optOutMarketingEmailsPayload,
    telemetryPayload,
} from "../../../models";
import {
    optOutHandlerSelector,
    RootState,
    telemetryActions,
    userActions,
} from "../../../store";
import { getLocalizedString, locale, paths, webLocale } from "../../../utils";
import {
    LargeGlassButton,
    LargeSolidButton,
    Loading,
    MediumSolidButton,
    styledTitle,
    testIDPrefix,
} from "../../components";
import { InlineMessage } from "../../components/common/inline";
import { stringIds, bundleIds } from "../../../assets";
import * as rootStyles from "../../styles";
import { Container, Row, Col } from "react-bootstrap";

const metricPrefix = "optOutPage";

const ID = "id";
const CAMPAIGN_ID = "campaignId";
const LANGUAGE = "language";

type ViewProps = {};

type StateProps = {
    optOutCompleted: boolean;
    optOutInProgress: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    optOutMarketingEmails: (payload: optOutMarketingEmailsPayload) => void;
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    optOutSucceed: boolean;
    userLocale: string;
};

export class OptOutScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            optOutSucceed: false,
            userLocale: "",
        };

        const params = new URLSearchParams(window.location.search);
        const directedId: string = params.get(ID) || "";
        const campaignId: string = params.get(CAMPAIGN_ID) || "";
        let language: string = params.get(LANGUAGE) || "";

        if (directedId) {
            localStorage.setItem(ID, directedId);
            localStorage.setItem(CAMPAIGN_ID, campaignId);
            localStorage.setItem(LANGUAGE, language);
            this.props.history.push(paths.optOut);
        } else if (!localStorage.getItem(ID)) {
            this.goHome();
        }

        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.optOut],
            ]),
        });

        let language = localStorage.getItem(LANGUAGE) || "";

        if (!language || !!(language in locale)) {
            language = locale.enUS;
        }

        this.updateLocale(language);

        const optOutConfig: optOut = {
            id: localStorage.getItem(ID) || "",
        };

        this.props.optOutMarketingEmails({
            optOut: optOutConfig,
            requestPath: paths.optOut,
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.optOutCompleted && !prevProps.optOutCompleted) {
            this.setState({ optOutSucceed: true });
            this.removeOptOutInfoFromLocalStorage();
        }

        if (
            !this.props.optOutInProgress &&
            prevProps.optOutInProgress &&
            !this.props.optOutCompleted
        ) {
            this.setState({ optOutSucceed: false });
            this.removeOptOutInfoFromLocalStorage();
        }
    }

    render() {
        if (this.props.optOutInProgress) {
            return <Loading />;
        }

        return (
            <Container
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    margin: "auto",
                    paddingTop: "10vh",
                }}
            >
                {this.state.optOutSucceed ? (
                    <Col style={containerStyle}>
                        <Row style={{ alignItems: "center" }}>
                            <styledTitle.h2 style={titleStyle}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.OPTOUT_STRINGS,
                                    stringId: stringIds.OptOut.successTitle,
                                })}
                            </styledTitle.h2>
                        </Row>
                        <Row
                            style={{
                                justifyContent: "center",
                                marginBottom: rootStyles.spacers.large,
                            }}
                        >
                            <span style={subtitleStyle}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.OPTOUT_STRINGS,
                                    stringId: stringIds.OptOut.successSubtitle,
                                })}
                            </span>
                        </Row>
                        <Row
                            style={{
                                justifyContent: "center",
                                marginBottom: rootStyles.spacers.large,
                            }}
                        >
                            <span style={descriptionStyle}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.OPTOUT_STRINGS,
                                    stringId:
                                        stringIds.OptOut.successDescription,
                                })}
                            </span>
                        </Row>
                        <Row
                            style={{
                                alignSelf: "center",
                                marginBottom: rootStyles.spacers.large,
                            }}
                        >
                            <Col style={{ textAlign: "center" }}>
                                <MediumSolidButton
                                    onClick={this.goToSettings}
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId: bundleIds.OPTOUT_STRINGS,
                                            stringId:
                                                stringIds.OptOut
                                                    .successManageSettingsButton,
                                        }
                                    )}
                                    id={testIDPrefix + "_ManageSettings"}
                                    containerStyle={manageSettingsButtonStyle}
                                />
                            </Col>
                        </Row>
                    </Col>
                ) : (
                    <Col>
                        <Row style={{ alignItems: "center" }}>
                            <styledTitle.h2 style={titleStyle}>
                                !
                            </styledTitle.h2>
                        </Row>
                        <Row
                            style={{
                                justifyContent: "center",
                                marginBottom: rootStyles.spacers.large,
                            }}
                        >
                            <span style={subtitleStyle}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.OPTOUT_STRINGS,
                                    stringId: stringIds.OptOut.failMessage,
                                })}
                            </span>
                        </Row>
                        <Row
                            style={{
                                justifyContent: "center",
                                marginBottom: rootStyles.spacers.large,
                            }}
                        >
                            <span style={descriptionStyle}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.OPTOUT_STRINGS,
                                    stringId: stringIds.OptOut.failDescription,
                                })}
                            </span>
                        </Row>
                        <Row
                            style={{
                                alignSelf: "center",
                                marginBottom: rootStyles.spacers.large,
                            }}
                        >
                            <Col style={{ textAlign: "center" }}>
                                <MediumSolidButton
                                    onClick={this.goToSettings}
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId: bundleIds.OPTOUT_STRINGS,
                                            stringId:
                                                stringIds.OptOut
                                                    .successManageSettingsButton,
                                        }
                                    )}
                                    id={testIDPrefix + "_ManageSettings"}
                                    containerStyle={manageSettingsButtonStyle}
                                />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Container>
        );
    }

    private goHome = () => {
        window.location.replace(paths.home);
    };

    private goToSettings = () => {
        this.props.userAction({
            name: metricPrefix + "GoToSettingsButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.optOut],
            ]),
        });
        window.location.replace(paths.settings);
    };

    private removeOptOutInfoFromLocalStorage = () => {
        localStorage.removeItem(ID);
        localStorage.removeItem(CAMPAIGN_ID);
        localStorage.removeItem(LANGUAGE);
    };

    private updateLocale = (language: string) => {
        this.setState({ userLocale: language }, () => this.setLocale());
    };

    private setLocale = () => {
        const locale = this.state.userLocale;
        webLocale[0] = locale;
    };
}

const containerStyle: React.CSSProperties = {
    maxWidth: 600,
    margin: "auto",
    marginTop: rootStyles.spacers.large,
    alignItems: "center",
};

const manageSettingsButtonStyle: React.CSSProperties = {
    alignSelf: "stretch",
    width: "100%",
    maxWidth: 320,
};

const titleStyle: React.CSSProperties = {
    textAlign: "center",
    width: "100%",
    lineHeight: 1,
    marginBottom: rootStyles.spacers.large,
};

const subtitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    maxWidth: 400,
    textAlign: "center",
};

const descriptionStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    maxWidth: 320,
    color: rootStyles.colors.primary,
    textAlign: "center",
};

function mapStateToProps(state: RootState) {
    return optOutHandlerSelector(state);
}

function mapDispatchToProps(dispatch: React.Dispatch<AnyAction>) {
    return {
        optOutMarketingEmails: (payload: optOutMarketingEmailsPayload) =>
            dispatch(userActions.optOutMarketingEmails(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OptOutScreen)
);
