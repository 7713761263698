import { createSelector } from "reselect";
import { RootState } from "../reducers/rootReducer";

export const getCurrentPath = (state: RootState) => state.user.currentPath;

export const getDeeplinkRoute = (state: RootState) => state.user.deeplinkRoute;

export const getDeeplinkMap = (state: RootState) => state.user.deeplinkMap;

export const getIsPrivileged = (state: RootState) => state.user.isPrivileged;

export const getTeams = (state: RootState) => state.user.teams;

export const getSignedIn = (state: RootState) => state.user.signedIn;

export const getIsDeeplinkArtistSearchInProgress = (state: RootState) =>
    state.user.isDeeplinkArtistSearchInProgress;

export const getArtistsAfterSearch = (state: RootState) =>
    state.artistSearch.artists;

export const getFeaturePermissionInProgress = (state: RootState) =>
    state.featureAccess.isGetFeaturePermissionInProgress;

export const getFeaturePermissionsList = (state: RootState) =>
    state.featureAccess.featurePermissionsList;

export const getSelectedArtist = (state: RootState) =>
    state.user.selectedArtistAsin;

export const currentPathSelector: any = createSelector(
    [getCurrentPath],
    (currentPath) => {
        return currentPath;
    }
);

export const deeplinkRouteSelector: any = createSelector(
    [getDeeplinkRoute],
    (deeplinkRoute) => {
        return deeplinkRoute;
    }
);

export const deeplinkMapSelector: any = createSelector(
    [getDeeplinkMap],
    (getDeeplinkMap) => {
        return getDeeplinkMap;
    }
);

export const deeplinkResolverSelector: any = createSelector(
    [
        getDeeplinkRoute,
        getIsPrivileged,
        getTeams,
        getSignedIn,
        getIsDeeplinkArtistSearchInProgress,
        getArtistsAfterSearch,
        getFeaturePermissionInProgress,
        getFeaturePermissionsList,
        getDeeplinkMap,
        getSelectedArtist,
    ],
    (
        deeplinkRoute,
        isPrivileged,
        teams,
        signedIn,
        isDeeplinkArtistSearchInProgress,
        artists,
        isGetFeaturePermissionInProgress,
        featurePermissionsList,
        deeplinkMap,
        selectedArtistAsin
    ) => {
        return {
            deeplinkRoute: deeplinkRoute,
            isPrivileged: isPrivileged,
            teams: teams,
            signedIn: signedIn,
            isDeeplinkArtistSearchInProgress: isDeeplinkArtistSearchInProgress,
            artists: artists,
            isGetFeaturePermissionInProgress: isGetFeaturePermissionInProgress,
            featurePermissionsList: featurePermissionsList,
            deeplinkMap: deeplinkMap,
            selectedArtistAsin: selectedArtistAsin,
        };
    }
);
