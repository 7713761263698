/* tslint:disable */
import React from "react";

export const terms_ptBR = (
    <div>
        <p className="p1">
            <span className="s1">
                <b>
                    Amazon Music for Artists Termos de Utilização
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b>Última atualização: 19 de fevereiro de 2022</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1">
                Este é um acordo entre si e a Amazon.com Services LLC (incluindo
                as respetivas afiliadas, «Amazon», «nós» ou «-nos»), regendo a
                sua utilização do serviço Amazon Music for Artists («Amazon
                Music for Artists»). Antes de utilizar o Amazon Music for
                Artists, leia estes Termos de Utilização, todas as regras e
                políticas relacionadas (incluindo quaisquer regras específicas,
                restrições à utilização e outras condições ou outros
                procedimentos que nós publiquemos ou atualizemos no ou pelo
                Amazon Music for Artists ou em qualquer página informativa ou de
                ajuda do Amazon Music for Artists), o{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&amp;view-type=content-only">
                    <span className="s3">Aviso de Privacidade</span>
                </a>
                , as{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&amp;nodeId=508088">
                    <span className="s3">Condições de Utilização</span>
                </a>{" "}
                e os avisos de{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050&amp;view-type=content-only">
                    <span className="s3">
                        Publicidade Baseada em Interesses
                    </span>
                </a>{" "}
                (coletivamente, o «Acordo»). O Acordo entra em vigor ao clicar
                no botão «Aceito» ou na caixa de seleção apresentada nestes
                Termos de Utilização. Declara estar legalmente habilitado a
                celebrar contratos (por exemplo, não é menor de idade). Se está
                a celebrar este Acordo em nome de uma entidade ou de um
                indivíduo, tal como a empresa para a qual trabalha ou o artista
                que representa, declara que tem autoridade legal para vincular
                essa entidade ou esse indivíduo.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>{" "}
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">1.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Descrição Geral. </b>O Amazon Music for Artists inclui uma
                variedade de ferramentas e funcionalidades que permitem aos
                artistas e respetivos representantes autorizados obter
                perspetivas sobre as bases de fãs dos artistas e interagir com
                os fãs nos serviços proporcionados pelo Amazon Music (os
                «Serviços»).<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Elegibilidade</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                Para utilizar o Amazon Music for Artists, tem de ter uma conta
                Amazon e ser: (a) um artista ou o representante autorizado de um
                artista cujo conteúdo seja fornecido à Amazon para distribuição
                nos Serviços, ou (b) um representante autorizado de uma entidade
                que tenha um acordo existente com a Amazon para fornecer
                conteúdo à Amazon para distribuição nos Serviços.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                Não pode usar um endereço de e-mail falso, fazer-se passar por
                qualquer pessoa ou entidade nem proporcionar, de outro modo,
                nenhuma informação falsa ou enganosa, incluindo qualquer
                informação fornecida ao estabelecer uma conta ou fornecida
                relativamente à propriedade de qualquer Conteúdo do Artista.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">2.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                Não pode partilhar o nome de utilizador nem a palavra-passe da
                Amazon com outras pessoas nem usar o nome de utilizador nem a
                palavra-passe de outras pessoas. No entanto, pode permitir que
                outras pessoas aprovadas por si visualizem as suas Fan Insights
                nas respetivas contas do Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p8">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.4.</span>
            <span className="s4"> </span>
            <span className="s1">
                É responsável por todas as comunicações e atividades que ocorram
                relativamente ao Amazon Music for Artists que provenham do seu
                computador ou das suas instalações informáticas, incluindo a sua
                conta de e-mail e respetiva palavra-passe.
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.5.</span>
            <span className="s4"> </span>
            <span className="s1">
                Nós podemos partilhar a sua informação de contacto (incluindo o
                nome, o endereço de e-mail e os nomes de utilizador das redes
                sociais) com terceiros, para fins de verificação e
                elegibilidade, assim como com outros indivíduos que acedam às
                suas Fan Insights.
            </span>
            <span className="s5">
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">3.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Fan Insights</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                Podemos dar-lhe acesso a dados relacionados com distribuição do
                conteúdo que é seu nos Serviços («Fan Insights»). Só pode usar
                as Fan Insights para avaliação e análise internas, não podendo
                partilhá-las com terceiros, a menos que estes terceiros
                concordem com estes Termos de Utilização. No entanto, a Amazon
                pode fornecer-lhe a si certa informação (por exemplo, marcos que
                atingiu nos Serviços) usando «cartões de partilha» que o
                utilizador pode disponibilizar publicamente.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                As Fan Insights são fornecidas apenas para sua conveniência, não
                estando garantida a precisão das mesmas. Portanto, estas não
                devem ser consideradas para calcular nenhumas taxas de licença
                nem outros valores que lhe sejam devidos como resultado da
                distribuição do seu conteúdo nos Serviços.{" "}
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.3.</span>
            <span className="s4"> </span>
            <span className="s1">
                Não poderá utilizar as Fan Insights para tentar obter dados mais
                pormenorizados do que os dados fornecidos (por exemplo, para
                determinar informação pessoal sobre um utilizador final dos
                Serviços).
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">4.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>
                    Direitos<span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.1.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Conteúdo do Artista. </b>Pode usar o Amazon Music for Artists
                para enviar materiais (por exemplo, imagens e ilustrações) que
                detenha ou que, de outro modo, tenha autorização para enviar
                («Conteúdo do Artista»). Não pode enviar Conteúdo do Artista que
                seja ilegal, obsceno, ameaçador ou difamatório, ou que, de outro
                modo, contenha qualquer conteúdo questionável ou impróprio;
                também não pode enviar Conteúdo do Artista que: (a) fira ou
                viole os direitos de terceiros, incluindo de privacidade,
                publicidade, direitos de autor, marcas registadas, patentes ou
                outros direitos de propriedade intelectual; ou (b) possa exigir
                que nós paguemos direitos de autor ou quaisquer outras taxas a
                terceiros pelo uso do Conteúdo do Artista conforme permitido nos
                termos do Acordo. Aceita e é responsável por pagar quaisquer
                taxas devidas relativamente ao Conteúdo do Artista. O envio para
                o Amazon Music for Artists não altera o facto de ser do
                utilizador a titularidade do Conteúdo do Artista.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p9">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Concessão de Licença. </b>O utilizador concede-nos um direito
                não exclusivo, sublicenciável, universal, perpétuo, irrevogável
                e livre de direitos de autor, assim como licença para usar,
                reproduzir, distribuir, transmitir, exibir, modificar e
                apresentar todo o Conteúdo do Artista, em quaisquer meios e
                formatos tecnológicos, em ligação com os Serviços e o Amazon
                Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s5">4.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Sugestões. </b>
            </span>
            <span className="s1">
                Se o utilizador apresentar sugestões de melhorias ao Amazon
                Music for Artists («Sugestões»), nós teremos o direito de usar
                as Sugestões sem restrições. Pelo presente, cede-nos
                irrevogavelmente todos os direitos, títulos e interesses nas e
                das sugestões, aceitando proporcionar-nos qualquer ajuda
                necessária para documentar, aperfeiçoar e manter os nossos
                direitos das Sugestões.
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">5.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Segurança e Privacidade dos Dados</b>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Proibição da engenharia reversa e da evasão. </b>
            </span>
            <span className="s1">
                O utilizador não pode nem irá encorajar, auxiliar nem autorizar
                qualquer outra pessoa a modificar, fazer engenharia reversa,
                contornar, descompilar, desmontar, enganar nem, de outra forma,
                interferir com qualquer parte do Amazon Music for Artists.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.2.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Proibição da Recolha de Dados em Massa. </b>O utilizador não
                pode nem irá encorajar, auxiliar nem autorizar qualquer outra
                pessoa a fazer crawl no Amazon Music for Artists nem, de outra
                forma, usar qualquer mineração de dados, extração ou outras
                ferramentas de recolha de dados para recolher informação da
                Amazon.
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">6.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>
                    Alterações e Suspensão
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">6.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Alterações. </b>
            </span>
            <span className="s1">
                Nós podemos alterar, suspender ou descontinuar o Amazon Music
                for Artists, ou qualquer parte do mesmo, em qualquer momento.
                Ocasionalmente, nós podemos adicionar funcionalidades ou
                removê-las do Amazon Music for Artists. Poderá haver
                circunstâncias excecionais em que nós já não possamos armazenar
                nem dar-lhe acesso a determinadas funcionalidades ou Fan
                Insights.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">6.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Suspensão e Rescisão da Nossa Parte. </b>
            </span>
            <span className="s1">
                Os seus direitos ao abrigo do Acordo serão automaticamente alvo
                de rescisão, sem aviso, se não cumprir os respetivos termos. Nós
                podemos rescindir o Acordo ou restringir, suspender ou pôr termo
                à sua utilização do Amazon Music for Artists em qualquer
                momento, inclusive se determinarmos que a sua utilização viola o
                Acordo, é imprópria, excede ou difere substancialmente do uso
                normal por outros utilizadores, envolve fraude ou uso indevido
                do Amazon Music for Artists ou prejudica os nossos interesses ou
                os de outro utilizador do Amazon Music for Artists. Pode
                rescindir o Acordo, por qualquer motivo, fechando a sua conta.
                Se o seu acesso ao Amazon Music for Artists for restringido,
                suspenso ou rescindido, poderá não conseguir aceder às Fan
                Insights. Na sequência de qualquer suspensão ou rescisão, nós
                reteremos os direitos ao Conteúdo do Artista que nos tenham sido
                concedidos nos termos do Acordo.
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">7.</span>
            <span className="s4">  </span>
            <span className="s1">
                <b>Autoridade Competente e Indemnidade. </b>O utilizador declara
                e garante que: (a) tem todos os direitos necessários para nos
                conceder os direitos indicados no Acordo; (b) os materiais que
                enviar, assim como o nosso uso dos mesmos, conforme autorizado
                pelo Acordo, (i) não violarão nem infringirão os direitos de
                privacidade, direitos de publicidade, direitos de propriedade
                intelectual nem outros direitos de terceiros, (ii) não são
                difamatórios, ilegais nem obscenos, e (iii) não exigirão que
                paguemos direitos de autor nem quaisquer outras taxas a
                terceiros pelo uso dos referidos materiais; e (c) todas as
                declarações factuais que apresenta são precisas e não enganosas.
                Aceita indemnizar, defender e isentar de responsabilidade a
                Amazon e respetivos diretores, executivos, funcionários, agentes
                e cessionários de todas as reivindicações, responsabilidades,
                despesas e todos os danos, incluindo taxas e despesas legais
                razoáveis, decorrentes de ou em ligação com os materiais que
                enviar, da violação do Acordo por si ou das suas declarações ou
                garantias, ou do uso impróprio, não autorizado ou ilegal por si
                do Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">8.</span>
            <span className="s4"> </span>
            <span className="s1">
                <b>Geral</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">8.1.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Alterações. </b>
            </span>
            <span className="s1">
                Nós podemos alterar o Acordo em qualquer momento, publicando os
                termos revistos no ou pelo Amazon Music for Artists. A
                continuação da utilização do Amazon Music for Artists por si
                após qualquer alteração evidencia a sua aceitação a ficar
                vinculado ao mesmo.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">8.2.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Notificações. </b>
            </span>
            <span className="s1">
                Nós podemos comunicar consigo, relativamente à utilização do
                Amazon Music for Artists, eletronicamente e por outros meios, e
                aceita receber tais comunicações, independentemente de quaisquer
                preferências ou solicitações de comunicações ao cliente ou
                semelhantes que possa ter indicado no website da Amazon.com ou
                por qualquer outro meio.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.3.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Avisos de Direitos de Autor. </b>
            </span>
            <span className="s1">
                Se acredita que o seu trabalho foi copiado de uma forma que
                constitui violação de direitos de autor, siga o Aviso e
                Procedimento para Fazer Reivindicações de Violações de Direitos
                de Autor especificado nas{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909000&amp;view-type=content-only">
                    <span className="s8">Condições de Utilização</span>
                </a>
                .
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.4.</span>
            <span className="s4"> </span>
            <span className="s5">
                <b>Acordo Integral; Separabilidade. </b>
            </span>
            <span className="s1">
                O Acordo constitui o acordo integral entre si e a Amazon,
                regendo a sua utilização do Amazon Music for Artists,
                substituindo quaisquer acordos anteriores entre si e a Amazon.
                Se qualquer termo ou condição deste Acordo for considerado
                inválido, nulo, ou por qualquer razão não aplicável, essa parte
                será considerada separável, não afetando a validade e a
                aplicabilidade de qualquer termo ou condição restante.
            </span>
        </p>
    </div>
);
