import * as _ from "lodash";
import { Action } from "redux-ts";
import { catalogActions } from "../actions";
import {
    albumRelease,
    artist,
    catalogItem,
    recentlyAddedToPlaylistTrack,
    shareContentResponse,
} from "../../models";

export type CatalogState = Readonly<{
    catalog: Map<string, catalogItem>;
    hydratingAsins: string[];
    failedAsins: string[];
    catalogBuildCompleted: boolean;
    hydrationInProgress: boolean;
    recentlyAddedToPlaylistsInProgress: boolean;
    shareLinkResponse?: shareContentResponse;
    generateShareLinkInProgress: boolean;
    promoCardShareLink?: string;
    generatePromoCardShareLinkInProgress: boolean;
}>;

export const initialCatalogState: CatalogState = {
    catalog: new Map<string, catalogItem>(),
    hydratingAsins: [],
    failedAsins: [],
    catalogBuildCompleted: false,
    hydrationInProgress: false,
    recentlyAddedToPlaylistsInProgress: false,
    shareLinkResponse: undefined,
    generateShareLinkInProgress: false,
    generatePromoCardShareLinkInProgress: false,
};

export const catalogReducer = (
    state: CatalogState = initialCatalogState,
    action: Action
) => {
    let catalog;
    let artistAsin;
    switch (action.type) {
        case catalogActions.buildCatalogCompleted.type: {
            const start = Date.now();
            console.log("Update catalog reducer");

            const newHydratingAsins = _.filter(
                state.hydratingAsins,
                (asin) => !action.payload.get(asin)
            );
            const catalog = _.cloneDeep(state.catalog);
            action.payload.forEach((item: catalogItem, asin: string) => {
                catalog.set(asin, { ...catalog.get(asin), ...item });
            });

            console.log(
                `Finished updating catalog reducer ${Date.now() - start} ms`
            );
            return {
                ...state,
                catalog: catalog,
                hydratingAsins: newHydratingAsins,
                catalogBuildCompleted: true,
            };
        }
        case catalogActions.hydrationInProgress.type: {
            return { ...state, hydrationInProgress: action.payload };
        }
        case catalogActions.hydratingAsins.type: {
            return {
                ...state,
                hydratingAsins:
                    _.concat(state.hydratingAsins, action.payload) || [],
            };
        }
        case catalogActions.failedAsins.type: {
            const failedAsins: string[] = action.payload;
            const newHydratingAsins = state.hydratingAsins.filter(
                (asin) => !failedAsins.includes(asin)
            );
            const newFailedAsins = state.failedAsins.concat(failedAsins);

            return {
                ...state,
                hydratingAsins: newHydratingAsins || [],
                failedAsins: newFailedAsins || [],
            };
        }
        case catalogActions.getRecentlyAddedToPlaylistsInProgress.type:
            return {
                ...state,
                recentlyAddedToPlaylistsInProgress: action.payload,
            };
        case catalogActions.getRecentlyAddedToPlaylistsComplete.type:
            catalog = _.cloneDeep(state.catalog);
            artistAsin = action.payload.artistAsin;
            catalog.set(artistAsin, {
                ...catalog.get(artistAsin),
                newAddsToPlaylistInfo: action.payload.recentlyAdded,
            });
            return { ...state, catalog: catalog };
        case catalogActions.getMarqueePlaylistsComplete.type:
            catalog = _.cloneDeep(state.catalog);
            artistAsin = action.payload.artistAsin;
            catalog.set(artistAsin, {
                ...catalog.get(artistAsin),
                marqueePlaylistInfo: action.payload.marqueePlaylists,
            });
            return { ...state, catalog: catalog };
        case catalogActions.clearFailedAsins.type: {
            return { ...state, failedAsins: [] };
        }
        case catalogActions.clearState.type:
            return initialCatalogState;
        case catalogActions.generateShareLink.type:
            return {
                ...state,
                shareLinkResponse: undefined,
            };
        case catalogActions.generateShareLinkComplete.type:
            return {
                ...state,
                shareLinkResponse: action.payload,
            };
        case catalogActions.generateShareLinkInProgress.type:
            return {
                ...state,
                generateShareLinkInProgress: action.payload,
            };
        case catalogActions.generatePromoCardShareLinkComplete.type:
            return {
                ...state,
                promoCardShareLink: action.payload,
            };
        case catalogActions.generatePromoCardShareLinkInProgress.type:
            return {
                ...state,
                generatePromoCardShareLinkInProgress: action.payload,
            };
        case catalogActions.clearPromoCardShareLink.type:
            return {
                ...state,
                promoCardShareLink: undefined,
            };
        default:
            return state;
    }
};
