import { paths } from "../utils";
import { Feature } from "./featureAccess";

export type deepLinkSchema = {
    version: string;
    artistAsin?: string;
    action?: string;
    entityType?: string;
    entityAsin?: string;
    timeStart?: string;
    timeEnd?: string;
};

export type deeplinkConfig = {
    artistRelated: boolean;
    path: string;
    hasFeaturePermission: boolean;
    featurePermission?: Feature[];
};

export enum Deeplinks {
    Profile = "profile",
    NewReleases = "newReleases",
    Pitch = "pitch",
    Merch = "merch",
    Reports = "reports",
    Songs = "songs",
    Fans = "fans",
    Voice = "voice",
    Programming = "programming",
    Settings = "settings",
    ReportArtistProfile = "reportArtistProfile",
    ArtistSelect = "artistSelect",
    HypeCard = "hypecard",
}

export const deeplinkMap = new Map<string | undefined, deeplinkConfig>([
    [
        Deeplinks.Profile,
        {
            artistRelated: true,
            path: paths.profile,
            hasFeaturePermission: false,
        },
    ],
    [
        Deeplinks.NewReleases,
        {
            artistRelated: true,
            path: paths.newReleases,
            hasFeaturePermission: false,
        },
    ],
    [
        Deeplinks.Pitch,
        {
            artistRelated: true,
            path: paths.newReleases,
            hasFeaturePermission: false,
        },
    ],
    [
        Deeplinks.Merch,
        {
            artistRelated: true,
            path: paths.merch,
            hasFeaturePermission: true,
            featurePermission: [Feature.MerchCuration, Feature.MOD_INGRESS],
        },
    ],
    [
        Deeplinks.Reports,
        {
            artistRelated: true,
            path: paths.reports,
            hasFeaturePermission: false,
        },
    ],
    [
        Deeplinks.Songs,
        { artistRelated: true, path: paths.songs, hasFeaturePermission: false },
    ],
    [
        Deeplinks.Fans,
        { artistRelated: true, path: paths.fans, hasFeaturePermission: false },
    ],
    [
        Deeplinks.Voice,
        { artistRelated: true, path: paths.voice, hasFeaturePermission: false },
    ],
    [
        Deeplinks.Programming,
        {
            artistRelated: true,
            path: paths.programming,
            hasFeaturePermission: false,
        },
    ],
    [
        Deeplinks.Settings,
        {
            artistRelated: false,
            path: paths.settings,
            hasFeaturePermission: false,
        },
    ],
    [
        Deeplinks.ReportArtistProfile,
        {
            artistRelated: false,
            path: paths.reportArtistProfile,
            hasFeaturePermission: false,
        },
    ],
    [
        Deeplinks.ArtistSelect,
        {
            artistRelated: false,
            path: paths.artistSelect,
            hasFeaturePermission: false,
        },
    ],
    [
        Deeplinks.HypeCard,
        {
            artistRelated: true,
            path: paths.promoCard,
            hasFeaturePermission: true,
            featurePermission: [Feature.PROMO_SHARE_CARD],
        },
    ],
]);
