import { createAction } from "redux-ts";
import {
    BatchOpsMetricsPayload,
    EmitOpsMetricsPayload,
    OpsMetricDeviceInfo,
    SingleOpsMetricInfo,
} from "../../models";

export const startTimedEmitter = createAction(
    "OPSMETRICS::START_TIMED_EMITTER"
);

export const stopTimedEmitter = createAction("OPSMETRICS::STOP_TIMED_EMITTER");

export const addOpsMetric = createAction<SingleOpsMetricInfo>(
    "OPSMETRICS::ADD_OPS_METRIC"
);

export const batchMetric = createAction<BatchOpsMetricsPayload>(
    "OPSMETRICS::BATCH_METRIC"
);

export const clearOpsMetricQueue = createAction<number>(
    "OPSMETRICS::CLEAR_OPS_METRIC_QUEUE"
);

export const emitOpsMetrics = createAction<EmitOpsMetricsPayload>(
    "OPSMETRICS::EMIT_OPS_METRICS"
);

export const setOpsMetricsDeviceInfo = createAction<OpsMetricDeviceInfo>(
    "OPSMETRICS::SET_OPS_METRICS_DEVICE_INFO"
);
