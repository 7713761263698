import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
    BoxList,
    Dropdown,
    DROPDOWN_HEIGHT,
    RadioList,
    StepField,
    styledTitle,
    TextField,
} from "../common";
import * as rootStyles from "../../styles";
import {
    genresInfoToGroups,
    getLocalizedString,
    selectedInGroupsToItemPropsList,
    tagListToItemProps,
    getUpdatedGroupsWithItemProperties,
    tagListToGroupList,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import _ from "lodash";
import { BundleMap, genreInfo, group, itemProps, tag } from "../../../models";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { pitchFormActions, RootState, commonSelectors } from "../../../store";

type PitchBasicsProps = {
    genres: genreInfo[];
    kindOfSong: tag[];
    languages: tag[];
};

const testIDPrefix = "PitchBasics";
const SONG_HAS_VOCALS_YES: number = 0;
const SONG_HAS_VOCALS_NO: number = 1;
const COMMON_WIDTH = 450;
const MAX_GENRE_SELECTION = 3;

export const PitchBasics: React.FC<PitchBasicsProps> = ({
    genres,
    kindOfSong,
    languages,
}) => {
    const dispatch: Dispatch<AnyAction> = useDispatch();

    const description = useSelector(
        (state: RootState) => state.pitchForm.description
    );
    const genresProps = useSelector(
        (state: RootState) => state.pitchForm.genresProps
    );

    const compositionsProps = useSelector(
        (state: RootState) => state.pitchForm.compositionsProps
    );
    const songHasVocals = useSelector(
        (state: RootState) => state.pitchForm.songHasVocals
    );
    const languagesProps = useSelector(
        (state: RootState) => state.pitchForm.languagesProps
    );
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const [bottomDropdownOpen, setBottomDropdownOpen] =
        useState<boolean>(false);

    const setDescription = useCallback(
        (description: string) => {
            dispatch(pitchFormActions.setDescription(description));
        },
        [dispatch]
    );

    const setGenresProps = useCallback(
        (genresProps: group[]) => {
            dispatch(pitchFormActions.setGenresProps(genresProps));
        },
        [dispatch]
    );
    const setCompositionsProps = useCallback(
        (compositionProps: itemProps[]) => {
            dispatch(pitchFormActions.setCompositionsProps(compositionProps));
        },
        [dispatch]
    );

    const setSongHasVocals = useCallback(
        (songHasVocals: boolean) => {
            dispatch(pitchFormActions.setSongHasVocals(songHasVocals));
        },
        [dispatch]
    );

    const setLanguagesProps = useCallback(
        (languagesProps: group[]) => {
            dispatch(pitchFormActions.setLanguagesProps(languagesProps));
        },
        [dispatch]
    );

    useEffect(() => {
        if (!genresProps.length) {
            setGenresProps(genresInfoToGroups(genres));
        }

        if (!compositionsProps.length) {
            setCompositionsProps(tagListToItemProps(kindOfSong));
        }

        if (!languagesProps.length) {
            setLanguagesProps(tagListToGroupList(languages));
        }
    }, []);

    useEffect(() => {
        // if song has vocals is set to no it clears the selected languages
        if (songHasVocals === false) {
            setLanguagesProps(tagListToGroupList(languages));
        }
    }, [songHasVocals]);

    const pitchBasicsHeader = () => {
        return (
            <>
                <Row>
                    <styledTitle.h4>
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formBasicsTitle,
                        })}
                    </styledTitle.h4>
                </Row>
                <Row>
                    <span style={rootStyles.textStyles.secondary}>
                        {getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formBasicsSubtitle,
                        })}
                    </span>
                </Row>
            </>
        );
    };

    const pitchBasicsFields = () => {
        const whyPitchThisSongTextArea = () => {
            return (
                <div style={{ maxWidth: 700 }}>
                    <TextField
                        multiLine={true}
                        maxLength={1000}
                        id={`${testIDPrefix}-Description`}
                        onChange={setDescription}
                        placeholder={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId:
                                stringIds.Pitch.formWhyThisSongPlaceholder,
                        })}
                        defaultValue={description}
                        showCharacterCount={true}
                        visibleRows={5}
                    />
                </div>
            );
        };

        const whatsItsGenreSearchField = () => {
            return (
                <>
                    <span
                        style={{
                            ...rootStyles.textStyles.tertiary,
                            textTransform: "uppercase",
                        }}
                    >
                        {getLocalizedString(
                            bundleMap,
                            {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formSelectUpTo,
                            },
                            { "0": MAX_GENRE_SELECTION.toString() }
                        )}
                    </span>
                    <div style={{ maxWidth: COMMON_WIDTH }}>
                        <Dropdown
                            groups={genresProps}
                            onChange={(groups) => {
                                setGenresProps(groups);
                            }}
                            maxSelect={MAX_GENRE_SELECTION}
                            id={`${testIDPrefix}-Genre`}
                            placeholder={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId: stringIds.Pitch.formGenrePlaceholder,
                            })}
                        />
                    </div>
                    <div style={{ marginTop: rootStyles.spacers.base }}>
                        <BoxList
                            showCancelIcon={true}
                            itemsProps={selectedInGroupsToItemPropsList(
                                genresProps
                            )}
                            onChange={(itemsProps: itemProps[]) => {
                                const newGroups =
                                    getUpdatedGroupsWithItemProperties(
                                        itemsProps,
                                        genresProps
                                    );
                                setGenresProps(newGroups);
                            }}
                            id={`${testIDPrefix}-Genre`}
                        />
                    </div>
                </>
            );
        };

        const whatKindOfSongIsIt = () => {
            return (
                <>
                    <Row style={{ marginBottom: rootStyles.spacers.micro }}>
                        <span
                            style={{
                                ...rootStyles.textStyles.tertiary,
                                textTransform: "uppercase",
                            }}
                        >
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId:
                                    stringIds.Pitch.formSelectAllThatApply,
                            })}
                        </span>
                    </Row>
                    <Row>
                        <BoxList
                            onChange={(boxPropertiesList: itemProps[]) =>
                                setCompositionsProps(boxPropertiesList)
                            }
                            showCancelIcon={false}
                            itemsProps={compositionsProps}
                            id={`${testIDPrefix}-KindOfSong`}
                        />
                    </Row>
                </>
            );
        };

        const SongVocalsRadioItems = () => {
            return [
                {
                    primary: getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formYes,
                    }),
                },
                {
                    primary: getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formNo,
                    }),
                },
            ];
        };

        const doesThisSongHaveVocals = () => {
            const songHasVocalsIndex =
                songHasVocals === true
                    ? SONG_HAS_VOCALS_YES
                    : songHasVocals === false
                    ? SONG_HAS_VOCALS_NO
                    : undefined;
            return (
                <Row>
                    <RadioList
                        elements={SongVocalsRadioItems()}
                        onChangeElement={(index: number) =>
                            setSongHasVocals(index === SONG_HAS_VOCALS_YES)
                        }
                        horizontal={true}
                        centeredElements={true}
                        initialSelectedIndex={songHasVocalsIndex}
                        textNoWrap={true}
                        elementSpacing={rootStyles.spacers.huge}
                        containerStyling={{}}
                        id={`${testIDPrefix}_DoesSongHaveVocals`}
                    />
                </Row>
            );
        };

        const lyricsLanguageSearch = () => {
            return (
                <div
                    style={{
                        marginBottom: bottomDropdownOpen ? DROPDOWN_HEIGHT : 0,
                    }}
                >
                    <div style={{ maxWidth: COMMON_WIDTH }}>
                        <Dropdown
                            groups={languagesProps}
                            onChange={(groups) => {
                                setLanguagesProps(groups);
                            }}
                            maxSelect={1}
                            id={`${testIDPrefix}-Language`}
                            placeholder={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PITCH_STRINGS,
                                stringId:
                                    stringIds.Pitch.formLanguagesPlacaholder,
                            })}
                            onOpenDropdown={(open: boolean) =>
                                setBottomDropdownOpen(open)
                            }
                        />
                    </div>
                    <div style={{ marginTop: rootStyles.spacers.base }}>
                        <BoxList
                            showCancelIcon={true}
                            itemsProps={selectedInGroupsToItemPropsList(
                                languagesProps
                            )}
                            onChange={(itemsProps: itemProps[]) => {
                                const newGroups =
                                    getUpdatedGroupsWithItemProperties(
                                        itemsProps,
                                        languagesProps
                                    );
                                setLanguagesProps(newGroups);
                            }}
                            id={`${testIDPrefix}-Language`}
                        />
                    </div>
                </div>
            );
        };

        return (
            <div style={{ marginBottom: "8vh" }}>
                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formWhyThisSongTitle,
                    })}
                    id={`${testIDPrefix}-Description`}
                    bottomElement={whyPitchThisSongTextArea()}
                    bottomElementIndent={false}
                />

                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formGenreTitle,
                    })}
                    id={`${testIDPrefix}-Genre`}
                    bottomElement={whatsItsGenreSearchField()}
                    bottomElementIndent={false}
                />

                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formKindOfSongTitle,
                    })}
                    id={`${testIDPrefix}-KindOfSong`}
                    bottomElement={whatKindOfSongIsIt()}
                    bottomElementIndent={false}
                />

                <StepField
                    title={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PITCH_STRINGS,
                        stringId: stringIds.Pitch.formVocalsTitle,
                    })}
                    id={`${testIDPrefix}-Vocals`}
                    bottomElement={doesThisSongHaveVocals()}
                    bottomElementIndent={false}
                />

                {songHasVocals ? (
                    <StepField
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.PITCH_STRINGS,
                            stringId: stringIds.Pitch.formLanguagesTitle,
                        })}
                        id={`${testIDPrefix}-Languages`}
                        bottomElement={lyricsLanguageSearch()}
                        bottomElementIndent={false}
                    />
                ) : null}
            </div>
        );
    };

    return (
        <Row style={{ marginTop: rootStyles.spacers.medium }}>
            <Col>
                {pitchBasicsHeader()}
                {pitchBasicsFields()}
            </Col>
        </Row>
    );
};

export default PitchBasics;
