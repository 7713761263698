import { ImageSize } from "../view/styles/";

// Hack to resize image
export const imageResizer = (uri: string, size: ImageSize, _info?: string) => {
    // if it isn't a resizable image, return original
    if (!uri.includes("m.media-amazon.com")) {
        // console.log("Cannot resize " + uri + " for " + info);
        return uri;
    }

    const lastDot = uri.lastIndexOf(".");
    let beforeHack = uri.substr(0, lastDot);

    if (beforeHack.slice(-1) !== "_") {
        beforeHack = beforeHack.concat("._");
    }

    const hackedUrl =
        beforeHack + "SX" + size + "_SY" + size + "_" + uri.substr(lastDot);
    return hackedUrl;
};
