import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import React from "react";
import { connect } from "react-redux";
import { BundleMap, MAX_ITEMS_IN_COLLECTION } from "../../../../models";
import { getLocalizedString } from "../../../../utils";
import { FullScreenConfirmationModal, IconsList } from "../../common";
import { RootState } from "../../../../store";
import { bundleMapSelector } from "../../../../store/selectors/commonSelectors";

export type LimitReachedModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = StateProps & LimitReachedModalProps;
export class LimitReachedModalClass extends React.Component<Props> {
    render() {
        return (
            <FullScreenConfirmationModal
                isVisible={this.props.isVisible}
                confirmButtonAction={this.props.onDismiss}
                onDismiss={this.props.onDismiss}
                id={"LimitReachedModal"}
                icon={IconsList.alerts_Information}
                text={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.LimitReachedModalTitle,
                })}
                description={getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation.LimitReachedModalSubtitle,
                    },
                    { "0": MAX_ITEMS_IN_COLLECTION.toString() }
                )}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId: stringIds.Merch.Curation.GotItButtonCTA,
                })}
            />
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

export const LimitReachedModal = connect(mapStateToProps)(
    LimitReachedModalClass
);
