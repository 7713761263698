import { Action } from "redux-ts";
import { takeEvery, put, select } from "redux-saga/effects";
import _ from "lodash";
import * as service from "../../service";
import * as rootStyles from "../../view/styles";
import { stringIds, IconsList, bundleIds } from "../../assets";
import {
    teamManagementActions,
    userActions,
    telemetryActions,
    errorActions,
    globalNotificationsActions,
    opsMetricsActions,
} from "../actions";
import {
    createSuccessOpsMetricsPayload,
    getLocalizedString,
    paths,
} from "../../utils";
import {
    getTeamBackendResponse,
    backendTeamMemberModel,
    METRIC_KEYS,
    backendInvitedTeamMemberModel,
    teamManagementMember,
    promoteToOwnerPayload,
    removeMemberPayload,
    setMemberInfoPayload,
    setPermissionsPayload,
    getTeamPayload,
    acceptTeamInvitePayload,
    inviteTeamMemberPayload,
    cancelInvitePayload,
    listInvitePayload,
    listInviteBackendResponse,
    checkTeamExistsPayload,
    checkTeamExistsResponse,
    BundleMap,
} from "../../models";
import { ModifyMemberType, RootState } from "../reducers";
import moment from "moment";
import { startTimer, publishTimer } from "../../service";
import { getBundleMap } from "./../selectors/commonSelectors";

export const teamManagementSagas = [
    watchSendInvite(),
    watchCancelInvite(),
    watchGetTeam(),
    watchListInvite(),
    watchSetPermissions(),
    watchSetMemberInfo(),
    watchRemoveMember(),
    watchAcceptTeamInvite(),
    watchPromoteToOwner(),
    watchCheckTeamExists(),
];

function* sendInvite(action: Action<inviteTeamMemberPayload>) {
    const start = Date.now();
    const functionName = "sendInvite";
    const timerMetric = startTimer("sendInviteDuration");
    try {
        const bundleMap: BundleMap = yield select(getBundleMap);

        yield put(teamManagementActions.inviteTeamMemberInProgress(true));
        yield put(teamManagementActions.modifyMemberInProgress(true));
        yield put(teamManagementActions.setInviteSent(false));

        yield put(
            telemetryActions.appEvent({
                name: "sendInviteStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield service.inviteTeamMember(action.payload);

        publishTimer(timerMetric);
        yield put(
            telemetryActions.appEvent({
                name: "sendInviteEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(teamManagementActions.inviteTeamMemberInProgress(false));
        yield put(teamManagementActions.modifyMemberInProgress(false));
        yield put(teamManagementActions.inviteTeamMemberCompleted(true));

        yield put(
            teamManagementActions.getInvitedMembers({
                requestPath: action.payload.requestPath,
                teamId: action.payload.teamId,
                paginationToken: "",
            })
        );

        yield put(
            globalNotificationsActions.requestToastView({
                toastText: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                    stringId: action.payload.isResend
                        ? stringIds.ManageCurrentTeam.inviteResent
                        : stringIds.ManageCurrentTeam.inviteSent,
                }),
                icon: IconsList.action_done,
                placement: "bottom",
                toastStyle: {
                    border: "none",
                    boxShadow: "none",
                },
                toastRowStyle: {
                    paddingRight: rootStyles.spacers.large,
                },
                id: "InviteSentToast",
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* cancelInvite(action: Action<cancelInvitePayload>) {
    const start = Date.now();
    const functionName = "cancelInvite";
    const timerMetric = startTimer("cancelInviteDuration");
    try {
        yield put(teamManagementActions.modifyMemberInProgress(true));
        yield put(teamManagementActions.modifyMemberCompleted(false));
        yield put(
            teamManagementActions.setModifyMemberType(
                ModifyMemberType.CancelInvite
            )
        );

        publishTimer(timerMetric);
        yield put(
            telemetryActions.appEvent({
                name: "cancelInviteStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield service.cancelInvite(action.payload);

        publishTimer(timerMetric);
        yield put(
            telemetryActions.appEvent({
                name: "cancelInviteEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(teamManagementActions.modifyMemberInProgress(false));
        yield put(teamManagementActions.modifyMemberCompleted(true));

        yield put(
            teamManagementActions.getInvitedMembers({
                requestPath: action.payload.requestPath,
                teamId: action.payload.teamId,
                paginationToken: "",
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getMembers(action: Action<getTeamPayload>) {
    const start = Date.now();
    const functionName = "getMembers";
    const timerMetric = startTimer("getTeamFetchDuration");
    try {
        yield put(teamManagementActions.getTeamMembersInProgress(true));

        yield put(
            telemetryActions.appEvent({
                name: "getTeamStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        const response: getTeamBackendResponse = yield service.getTeam(
            action.payload
        );

        publishTimer(timerMetric);

        const newResponse = {
            ...response,
            members: transformResponseMembers(response.members),
        };

        yield put(
            telemetryActions.appEvent({
                name: "getTeamEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(teamManagementActions.getTeamMembersInProgress(false));
        yield put(teamManagementActions.getTeamMembersCompleted(newResponse));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);
        yield put(teamManagementActions.getTeamMembersInProgress(false));
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getInvitedMembers(action: Action<listInvitePayload>) {
    const start = Date.now();
    const functionName = "getInvitedMembers";
    const timerMetric = startTimer("getInvitedMembersFetchDuration");
    try {
        yield put(teamManagementActions.getInvitedMembersInProgress(true));
        yield put(
            telemetryActions.appEvent({
                name: "getInvitedMembersStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        const inviteResponse: listInviteBackendResponse =
            yield service.listInvite(action.payload);

        publishTimer(timerMetric);

        const newResponse = {
            ...inviteResponse,
            members: transformResponseInviteMembers(inviteResponse.invites),
        };

        yield put(
            telemetryActions.appEvent({
                name: "getInvitedMembersEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(teamManagementActions.getInvitedMembersInProgress(false));
        yield put(
            teamManagementActions.getInvitedMembersCompleted(newResponse)
        );
        yield put(teamManagementActions.setInvitePaginationToken(newResponse));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(teamManagementActions.getInvitedMembersInProgress(false));

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* setPermissions(action: Action<setPermissionsPayload>) {
    const start = Date.now();
    const functionName = "setPermissions";
    const timerMetric = startTimer("setPermissionsDuration");
    try {
        yield put(teamManagementActions.modifyMemberInProgress(true));
        yield put(teamManagementActions.modifyMemberCompleted(false));
        yield put(
            teamManagementActions.setModifyMemberType(
                ModifyMemberType.SetPermissions
            )
        );

        yield put(
            telemetryActions.appEvent({
                name: "setPermissions",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield service.setMemberPermissions(action.payload);

        yield put(
            telemetryActions.appEvent({
                name: "setPermissionsEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(teamManagementActions.modifyMemberInProgress(false));
        yield put(teamManagementActions.modifyMemberCompleted(true));

        publishTimer(timerMetric);

        yield put(
            teamManagementActions.getTeamMembers({
                requestPath: action.payload.requestPath,
                teamId: action.payload.teamId,
            })
        );

        yield put(userActions.registerUser());

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* setMemberInfo(action: Action<setMemberInfoPayload>) {
    const start = Date.now();
    const functionName = "setMemberInfo";
    const timerMetric = startTimer("setMemberInfoDuration");
    try {
        yield put(teamManagementActions.modifyMemberInProgress(true));
        yield put(teamManagementActions.modifyMemberCompleted(false));
        yield put(
            teamManagementActions.setModifyMemberType(
                ModifyMemberType.SetMemberInfo
            )
        );

        yield put(
            telemetryActions.appEvent({
                name: "setMemberInfoStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield service.setMemberInfo(action.payload);

        yield put(
            telemetryActions.appEvent({
                name: "setMemberInfoEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        publishTimer(timerMetric);

        yield put(teamManagementActions.modifyMemberInProgress(false));
        yield put(teamManagementActions.modifyMemberCompleted(true));

        yield put(
            teamManagementActions.getTeamMembers({
                requestPath: action.payload.requestPath,
                teamId: action.payload.teamId,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* removeMember(action: Action<removeMemberPayload>) {
    const start = Date.now();
    const functionName = "removeMember";
    const timerMetric = startTimer("removeMemberDuration");
    try {
        yield put(teamManagementActions.modifyMemberInProgress(true));
        yield put(teamManagementActions.modifyMemberCompleted(false));
        yield put(
            teamManagementActions.setModifyMemberType(ModifyMemberType.Remove)
        );

        yield put(
            telemetryActions.appEvent({
                name: "removeMemberStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield service.removeTeamMember(action.payload);

        publishTimer(timerMetric);
        yield put(
            telemetryActions.appEvent({
                name: "removeMemberEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(teamManagementActions.modifyMemberInProgress(false));
        yield put(teamManagementActions.modifyMemberCompleted(true));

        yield put(
            teamManagementActions.getTeamMembers({
                requestPath: action.payload.requestPath,
                teamId: action.payload.teamId,
            })
        );

        yield put(userActions.registerUser());

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* acceptInvite(action: Action<acceptTeamInvitePayload>) {
    const start = Date.now();
    const functionName = "acceptInvite";
    const timerMetric = startTimer("acceptInviteDuration");
    try {
        yield put(
            telemetryActions.appEvent({
                name: "acceptInviteStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );
        yield put(teamManagementActions.acceptTeamInviteCompleted(false));
        yield service.acceptTeamInvite(action.payload);

        publishTimer(timerMetric);
        yield put(
            telemetryActions.appEvent({
                name: "acceptInviteEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        yield put(userActions.registerUser());
        yield put(teamManagementActions.acceptTeamInviteCompleted(true));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([]);

        yield put(teamManagementActions.acceptTeamInviteInProgess(false));
        yield put(teamManagementActions.acceptTeamInviteCompleted(false));

        yield put(
            errorActions.handleError({
                requestPath: paths.acceptInvite,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* promoteToOwner(action: Action<promoteToOwnerPayload>) {
    const start = Date.now();
    const functionName = "promoteToOwner";
    const timerMetric = startTimer("promoteToOwnerDuration");
    try {
        const bundleMap: BundleMap = yield select(getBundleMap);
        yield put(teamManagementActions.modifyMemberInProgress(true));
        yield put(teamManagementActions.modifyMemberCompleted(false));
        yield put(
            teamManagementActions.setModifyMemberType(
                ModifyMemberType.PromoteToOwner
            )
        );

        yield put(
            telemetryActions.appEvent({
                name: "promoteToOwnerStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield service.promoteToOwner(action.payload);

        publishTimer(timerMetric);
        yield put(
            telemetryActions.appEvent({
                name: "promoteToOwnerEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                    [METRIC_KEYS.teamId, action.payload.teamId],
                ]),
            })
        );

        yield put(teamManagementActions.modifyMemberInProgress(false));
        yield put(teamManagementActions.modifyMemberCompleted(true));

        yield put(
            teamManagementActions.getTeamMembers({
                requestPath: action.payload.requestPath,
                teamId: action.payload.teamId,
            })
        );

        yield put(userActions.registerUser());

        yield put(
            globalNotificationsActions.requestToastView({
                toastText: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                    stringId: stringIds.ManageCurrentTeam.ownershipTransferred,
                }),
                icon: IconsList.action_done,
                placement: "bottom",
                toastStyle: {
                    border: "none",
                    boxShadow: "none",
                },
                toastRowStyle: {
                    paddingRight: rootStyles.spacers.large,
                },
                id: "OwnershipTransferredToast",
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.teamId, action.payload.teamId],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(teamManagementActions.modifyMemberInProgress(false));
        yield put(
            errorActions.handleError({
                requestPath: action.payload.requestPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function transformResponseMembers(
    members: backendTeamMemberModel[]
): teamManagementMember[] {
    if (!members) {
        return [];
    }

    return _.map(members, (member: backendTeamMemberModel) => {
        return {
            ...member,
            access: member.role,
        };
    });
}

function transformResponseInviteMembers(
    invitedMembers: backendInvitedTeamMemberModel[]
): teamManagementMember[] {
    if (!invitedMembers) {
        return [];
    }

    return _.map(invitedMembers, (member: backendInvitedTeamMemberModel) => {
        return {
            ...member,
            access: member.role,
            memberId: member.teamId,
            invite: {
                email: member.email,
                date: moment(member.createdTime).toDate(),
                inviteUrl: member.inviteUrl,
            },
        };
    });
}

function* checkTeamExists(action: Action<checkTeamExistsPayload>) {
    const start = Date.now();
    const functionName = "checkTeamExists";
    const timerMetric = startTimer("checkTeamExistsDuration");
    try {
        yield put(teamManagementActions.checkTeamExistsInProgress(true));
        yield put(teamManagementActions.checkTeamExistsResponse(false));

        yield put(
            telemetryActions.appEvent({
                name: "checkTeamExistsStart",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                ]),
            })
        );

        const response: checkTeamExistsResponse = yield service.checkTeamExists(
            action.payload
        );

        publishTimer(timerMetric);
        yield put(
            telemetryActions.appEvent({
                name: "checkTeamExistsEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.page, action.payload.requestPath],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        yield put(
            teamManagementActions.checkTeamExistsResponse(response.teamExists)
        );
        yield put(teamManagementActions.checkTeamExistsInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.page, action.payload.requestPath],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
            [METRIC_KEYS.errorAction, action.type],
        ]);

        yield put(teamManagementActions.checkTeamExistsResponse(false));
        yield put(teamManagementActions.checkTeamExistsInProgress(false));

        yield put(
            errorActions.handleError({
                requestPath: action.payload.requestPath,
                retryAction: action,
                silent: false,
                exception: ex,
                shouldRetry: false,
                dataPoints: dataPoints,
                eventName: functionName,
            })
        );
    }
}

function* watchListInvite() {
    yield takeEvery(
        teamManagementActions.getInvitedMembers.type,
        getInvitedMembers
    );
}

function* watchSendInvite() {
    yield takeEvery(teamManagementActions.inviteTeamMember.type, sendInvite);
}

function* watchCancelInvite() {
    yield takeEvery(teamManagementActions.cancelInvite.type, cancelInvite);
}

function* watchGetTeam() {
    yield takeEvery(teamManagementActions.getTeamMembers.type, getMembers);
}

function* watchSetPermissions() {
    yield takeEvery(teamManagementActions.setPermissions.type, setPermissions);
}

function* watchSetMemberInfo() {
    yield takeEvery(teamManagementActions.setMemberInfo.type, setMemberInfo);
}

function* watchRemoveMember() {
    yield takeEvery(teamManagementActions.removeMember.type, removeMember);
}

function* watchAcceptTeamInvite() {
    yield takeEvery(teamManagementActions.acceptTeamInvite.type, acceptInvite);
}

function* watchPromoteToOwner() {
    yield takeEvery(teamManagementActions.promoteToOwner.type, promoteToOwner);
}

function* watchCheckTeamExists() {
    yield takeEvery(
        teamManagementActions.checkTeamExists.type,
        checkTeamExists
    );
}
