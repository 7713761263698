import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { Container, Row, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import {
    AlexaIndex,
    Loading,
    LineGraph,
    LineGraphProps,
    VerticalSpacedTable,
    InlineError,
    CommonHeader,
} from "../../components";
import {
    RequiredReportingProps,
    RequiredReportingDispatchProps,
    RequiredReportingState,
    METRIC_KEYS,
    trackInfo,
    totalVoiceRequestsInfo,
    streamDetails,
    TimeRange,
    reportingOverviewRequestPayload,
    telemetryPayload,
    recentlyAddedToPlaylistPayload,
    BundleMap,
} from "../../../models";
import * as rootStyles from "../../styles";
import {
    RootState,
    voiceScreenSelector,
    reportingActions,
    telemetryActions,
    catalogActions,
    userActions,
} from "../../../store";
import {
    getLocalizedString,
    getReportsHelper,
    paths,
    generateAlexaVoiceRequestDetailRows,
    parseTrackDataPoints,
    timeRangeToTickCount,
    formatNumber,
    formatDatum,
    formatTooltipDatum,
    guessTimeZone,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";

const testIDPrefix = "VoiceScreen";
const metricPrefix = "voicePage";

type ViewProps = {};

type StateProps = RequiredReportingProps & {
    tracks: trackInfo[];
    streamingDetails: streamDetails[];
    alexaIndexInfo: number;
    voiceRequests?: totalVoiceRequestsInfo;
    bundleMap: BundleMap;
};

type DispatchProps = RequiredReportingDispatchProps & {
    getReports: (payload: reportingOverviewRequestPayload) => void;
    updateCurrentPath: (payload: string) => void;
    getRecentlyAddedToPlaylist: (
        payload: recentlyAddedToPlaylistPayload
    ) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {};

class VoiceScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: any) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();
        this.getReports();
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist],
                [METRIC_KEYS.timeRange, this.props.selectedRange],
                [METRIC_KEYS.page, paths.voice],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports();
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.state.loadedReports &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            if (
                this.props.error &&
                this.props.error.requestPath === paths.voice
            ) {
                this.setState({ showError: true });
            } else {
                this.setState({ showError: false });
            }
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
        }
    }

    render() {
        // Loading state
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const alexaScore = this.props.alexaIndexInfo;

        const totalVoiceRequestsCount =
            (this.props.voiceRequests && this.props.voiceRequests.totalCount) ||
            0;
        const totalVoiceRequestsWOW =
            (this.props.voiceRequests && this.props.voiceRequests.delta) ||
            "N/A";

        const userTimeZone = guessTimeZone();

        // const alexaScoreWOW  = 0;
        const totalVoiceRequestsProps: LineGraphProps | undefined = this.props
            .voiceRequests && {
            data: [
                parseTrackDataPoints(
                    this.props.voiceRequests,
                    this.props.selectedRange === TimeRange.AllTime
                ),
            ],
            title: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.ALEXA_STRINGS,
                    stringId: stringIds.Alexa.totalVoiceRequests,
                },
                { "0": formatNumber(totalVoiceRequestsCount) }
            ),
            subtitle: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.weekOverWeek,
                },
                { "0": formatNumber(totalVoiceRequestsWOW) }
            ),
            labels:
                this.props.selectedRange === TimeRange.AllTime
                    ? [
                          getLocalizedString(this.props.bundleMap, {
                              bundleId: bundleIds.REPORTS_STRINGS,
                              stringId: stringIds.Reports.allTimeSubtitle,
                          }),
                      ]
                    : undefined,
            xTickCount: timeRangeToTickCount(this.props.selectedRange),
            tickFormat: (datum: any) =>
                formatDatum(datum, this.props.selectedRange, userTimeZone),
            tooltipFormat: (datum: any) =>
                formatTooltipDatum(
                    datum,
                    this.props.selectedRange,
                    userTimeZone
                ),
            id: testIDPrefix + "_TotalVoiceRequeststLineChart",
            bundleMap: this.props.bundleMap,
        };

        const header = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.requestsTypeLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.requestsLabel,
            }),
        ];

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {/* {this.props.artist &&
                    <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: this.props.artist?.images?.artSmall }}
                    />
                } */}
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.voiceTitle,
                    })}
                    subtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.voiceSubtitle,
                    })}
                    id={`${testIDPrefix}_Header`}
                />
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getReports}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        {/* alexa score */}
                        <AlexaIndex
                            score={alexaScore}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId:
                                    stringIds.Reports.dailyVoiceIndexTitle,
                            })}
                            id={`${testIDPrefix}_AlexaIndexScoreChart`}
                            bundleMap={this.props.bundleMap}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        {/* total voice requests */}
                        {totalVoiceRequestsProps && (
                            <LineGraph
                                key="totalVoiceRequests"
                                {...totalVoiceRequestsProps}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {/* streaming details */}
                        {
                            <VerticalSpacedTable
                                key="streamingDetails"
                                rows={generateAlexaVoiceRequestDetailRows(
                                    this.props.streamingDetails,
                                    this.props.bundleMap,
                                    `${testIDPrefix}_StreamingDetailsTableRows`
                                )}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.ALEXA_STRINGS,
                                        stringId:
                                            stringIds.Alexa
                                                .voiceRequestDetailsTitle,
                                    }
                                )}
                                labels={header}
                                id={testIDPrefix + "_StreamingDetailsTable"}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        );
    }

    private getReports = () => {
        getReportsHelper(
            this.props.getReports,
            this.props.getRecentlyAddedToPlaylist,
            this.props.selectedRange,
            paths.voice,
            this.props.selectedArtist,
            this.props.teamId,
            this.props.startDate,
            this.props.endDate
        );
    };
}

function mapStateToProps(state: RootState) {
    return voiceScreenSelector(state, paths.voice);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReports: (payload: reportingOverviewRequestPayload) =>
            dispatch(reportingActions.getVoiceReports(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        getRecentlyAddedToPlaylist: (payload: recentlyAddedToPlaylistPayload) =>
            dispatch(catalogActions.getRecentlyAddedToPlaylists(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(VoiceScreen)
);
