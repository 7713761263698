import axios, { AxiosResponse } from "axios";
import {
    checkPendingClaimPayload,
    CheckPendingClaimResponse,
    claimRequest,
    claimRequestPayload,
} from "../models";
import { getEndpoints, testHeaders } from "./common";

const getClaimUrl = (baseUrl: string) => `${baseUrl}/usermanagement/claim`;
const baseUrls = getEndpoints();

export async function makeClaim(payload: claimRequestPayload): Promise<void> {
    const request: claimRequest = {
        artistAsin: payload.artistAsin,
        company: payload.company,
        description: payload.description,
        authIds: payload.authIds,
        jobTitle: payload.jobTitle,
    };

    return axios.post(getClaimUrl(baseUrls.userManagement), request, {
        headers: {
            ...testHeaders(),
        },
        withCredentials: true,
    });
}

export async function checkPendingClaimStatus(
    payload: checkPendingClaimPayload
): Promise<CheckPendingClaimResponse> {
    const result: AxiosResponse<CheckPendingClaimResponse> = await axios.post(
        getClaimUrl(baseUrls.userManagement) + "/checkPendingClaim",
        {
            artistAsin: payload.artistAsin,
        },
        {
            headers: {
                ...testHeaders(),
            },
            withCredentials: true,
        }
    );

    return result.data;
}
