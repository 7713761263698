import { OptValue } from "../models";

export const booleanToOpt = (b: boolean) => {
    return b ? OptValue.OPT_IN : OptValue.OPT_OUT;
};

export const optToBoolean = (o?: OptValue) => {
    return o === OptValue.OPT_IN;
};

export const invertOpt = (o: OptValue) => {
    return o === OptValue.OPT_OUT ? OptValue.OPT_IN : OptValue.OPT_OUT;
};
