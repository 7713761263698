import _ from "lodash";
import { Task } from "redux-saga";
import {
    delay,
    put,
    take,
    fork,
    cancel,
    select,
    cancelled,
} from "redux-saga/effects";
import { SingleOpsMetricInfo } from "../../../models";
import { OPS_METRIC_BATCH_LIMIT } from "../../../utils";
import { opsMetricsActions } from "../../actions";
import { getMetricsQueue } from "../../selectors";

const BATCH_TIMEOUT_DURATION = 5000;
export const timedEmitterSagas = [watchTimedEmitter()];

function* timedEmitter() {
    function* checkForMetricsAndEmit() {
        // get metrics queue to determine if metrics need to be emitted
        const metricsQueue: SingleOpsMetricInfo[] = yield select(
            getMetricsQueue
        );

        /**
         * To avoid the timed emitter and batch metric saga emitting duplicate metrics,
         * we only want the timed emitter to emit metrics if the queue has between 1 and
         * one less than the max size of the queue. If the batch metric saga does emit metrics,
         * we can take care of the edge case where the timed emitter is also invoked at the same
         * time and emits those same metrics.
         */
        if (
            metricsQueue.length &&
            metricsQueue.length < OPS_METRIC_BATCH_LIMIT
        ) {
            yield put(opsMetricsActions.emitOpsMetrics(metricsQueue));
            // clear the queue of only the metrics that were sent to be emitted
            yield put(
                opsMetricsActions.clearOpsMetricQueue(metricsQueue.length)
            );
        }
    }
    try {
        while (true) {
            // call opsMetricsSaga every 5 seconds
            yield checkForMetricsAndEmit();
            yield delay(BATCH_TIMEOUT_DURATION);
        }
    } finally {
        // more information on how task cancelation works in redux saga: https://redux-saga.js.org/docs/advanced/TaskCancellation/
        if (cancelled()) {
            yield checkForMetricsAndEmit();
        }
    }
}

function* watchTimedEmitter() {
    while (true) {
        // wait for action to start timer
        yield take(opsMetricsActions.startTimedEmitter.type);
        // fork saga to continue while loop
        const backgroundEmitterTimer: Task = yield fork(timedEmitter);
        // wait on take until stop action is dispatched
        yield take(opsMetricsActions.stopTimedEmitter.type);
        // when stop action is dispatched, then end the task
        yield cancel(backgroundEmitterTimer);
    }
}
