/* tslint:disable */
import React from "react";

export const terms_itIT = (
    <div>
        <p className="p1">
            <span className="s1">
                <b>
                    Termini di utilizzo di Amazon Music for Artists
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b>Ultimo aggiornamento: 19 febbraio 2022</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1">
                Il presente contratto è stipulato tra l'Utente e Amazon.com
                Services LLC (con le sue affiliate, indicata anche come
                "Amazon", "noi" o "ci"), e regola l'utilizzo del servizio Amazon
                Music for Artists ("Amazon Music for Artists") da parte
                dell'Utente medesimo. Prima di utilizzare Amazon Music for
                Artists, l'Utente è tenuto a leggere i presenti Termini di
                utilizzo, tutti i regolamenti e le informative correlate (ivi
                comprese eventuali regole specifiche, limitazioni all'utilizzo e
                altre condizioni o procedure che saranno oggetto di
                pubblicazione e aggiornamento sulla pagina web di Amazon Music
                for Artists, o comunque mediante la stessa, oppure su altre
                pagine di assistenza o informazione correlate ad Amazon Music
                for Artists), nonché l'{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&amp;view-type=content-only">
                    <span className="s3">Informativa sulla privacy</span>
                </a>
                , le{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&amp;nodeId=508088">
                    <span className="s3">Condizioni di utilizzo</span>
                </a>{" "}
                e le informative sugli{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050&amp;view-type=content-only">
                    <span className="s3">Annunci mirati</span>
                </a>{" "}
                (documenti indicati anche, collettivamente, come il
                "Contratto"). Il Contratto entra in vigore nel momento in cui
                l'Utente fa clic su un pulsante "Accetto" o su una casella di
                controllo accompagnata ai presenti Termini di utilizzo. L'Utente
                dichiara di essere legalmente in grado di stipulare contratti
                (ossia, per esempio, di non essere minorenne). L'Utente che
                stipuli il presente Accordo per conto di un'altra persona,
                fisica o giuridica, quali per esempio l'azienda datrice di
                lavoro o l'artista rappresentato, dichiara di avere l'autorità
                legale per vincolare la suddetta persona fisica o giuridica.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">1.</span>
            <span className="s4">   </span>
            <span className="s1">
                <b>Descrizione generale. </b>Amazon Music for Artists include
                una serie di strumenti e funzionalità che consentono agli
                artisti, nonché ai rappresentanti autorizzati degli stessi, di
                ottenere informazioni relative al pubblico di riferimento e di
                interagire con lo stesso in merito ai servizi forniti da Amazon
                Music (indicati anche come i "Servizi").
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">2.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Idoneità</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.1.</span>
            <span className="s4">   </span>
            <span className="s1">
                Sono intitolati all'utilizzo di Amazon Music for Artists, a
                condizione di disporre di un account Amazon, (a) gli artisti i
                cui contenuti siano forniti ad Amazon per la distribuzione sui
                Servizi, nonché i loro rappresentanti autorizzati, o (b) i
                rappresentanti autorizzati di persone giuridiche che abbiano un
                accordo esistente con Amazon allo scopo di fornire alla stessa
                contenuti da distribuire sui Servizi.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.2.</span>
            <span className="s4">   </span>
            <span className="s1">
                L'Utente è tenuto a non utilizzare indirizzi e-mail falsi, a non
                sostituirsi indebitamente a qualsivoglia persona fisica o
                giuridica, e a non fornire altrimenti informazioni false o
                fuorvianti, ivi comprese le informazioni fornite al momento
                della creazione di un account o fornite in merito alla
                titolarità di qualsiasi Contenuto dell'artista.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">2.3.</span>
            <span className="s4">   </span>
            <span className="s1">
                L'Utente è tenuto a non condividere con soggetti terzi il
                proprio nome utente e la relativa password Amazon, e a non
                utilizzare il nome utente e la password Amazon di terzi.
                Tuttavia, l'Utente ha facoltà di consentire ad altre persone,
                dallo stesso approvate, di visualizzare i suoi Approfondimenti
                sui fan nell'ambito dei loro account Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p8">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.4.</span>
            <span className="s4">   </span>
            <span className="s1">
                L'Utente è responsabile di ogni comunicazione o attività,
                afferente Amazon Music for Artists, che provenga dal suo
                computer o dalle strutture riconducibili allo stesso, ivi
                inclusi il suo account e-mail e la password.
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.5.</span>
            <span className="s4">   </span>
            <span className="s1">
                Amazon ha facoltà di condividere le informazioni di contatto
                relative all'Utente (ivi inclusi nome, indirizzo e-mail e nomi
                utente dei social media) con terze parti, per scopi di verifica
                e idoneità, nonché con altri soggetti che accedono agli
                Approfondimenti sui fan.
            </span>
            <span className="s5">
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">3.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Approfondimenti sui fan</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.1.</span>
            <span className="s4">   </span>
            <span className="s1">
                Amazon potrà fornire al'Utente l'accesso ai dati relativi alla
                distribuzione dei suoi contenuti sui Servizi ("Approfondimenti
                sui fan"). L'Utente potrà utilizzare gli Approfondimenti sui fan
                soltanto alla scopo di condurre valutazioni e analisi interne, e
                non potrà condividerli con terze parti, salvo il caso in cui le
                suddette terze parti acconsentano ai presenti Termini di
                utilizzo. Tuttavia, Amazon potrà fornire all'Utente talune
                informazioni (quali, per esempio, i traguardi raggiunti
                nell'ambito dei Servizi) tramite "carte di condivisione" che
                l'Utente potrà rendere pubblicamente disponibili.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.2.</span>
            <span className="s4">   </span>
            <span className="s1">
                Gli Approfondimenti sui fan sono forniti meramente come un'utile
                funzionalità, e non se ne garantisce l'accuratezza. Non è
                opportuno, pertanto, utilizzarli come base di calcolo di
                eventuali costi di licenza o di altri importi dovuti all'utente
                a seguito della distribuzione dei propri contenuti sui Servizi.{" "}
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.3.</span>
            <span className="s4">   </span>
            <span className="s1">
                L'Utente è tenuto a non utilizzare gli Approfondimenti sui fan
                allo scopo di ottenere dati più dettagliati rispetto a quelli
                forniti (ad esempio, per ricavare informazioni personali su un
                utilizzatore finale dei Servizi).
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">4.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    Diritti<span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.1.</span>
            <span className="s4">   </span>
            <span className="s1">
                <b>Contenuto dell'artista. </b>L'Utente potrà utilizzare Amazon
                Music for Artists per inviare materiali (ad esempio immagini e
                opere d'arte), i quali siano di sua proprietà, o che comunque è
                legittimato a inviare ("Contenuto dell'artista"). Non è ammesso
                l'invio, da parte dell'Utente, di Contenuti dell'artista che
                siano illegali, osceni, minacciosi, diffamatori o che contengano
                in altro modo contenuti discutibili o inappropriati, né è
                ammesso l'invio di alcun Contenuto dell'artista che (a) comporti
                lesione o violazione di diritti di terze parti, inclusi i
                diritti relativi alla privacy, alla pubblicità, i diritti
                d'autore, i marchi, i brevetti o altri diritti di proprietà
                intellettuale, oppure (b) richieda il pagamento a terzi, da
                parte di Amazon, di royalty o commissioni di qualsivoglia natura
                per l'utilizzo del Contenuto dell'artista come consentito dal
                Contratto. L'Utente accetta la responsabilità e il pagamento
                delle commissioni dovute in relazione ai Contenuti dell'artista.
                L'invio ad Amazon Music for Artists non produce effetti
                modificativi sulla titolarità, in capo all'Utente, dei Contenuti
                dell'artista.<span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p9">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.2.</span>
            <span className="s4">   </span>
            <span className="s1">
                <b>Concessione di licenza. </b>L'Utente concede ad Amazon un
                diritto e una licenza non esclusivi, concedibili in sublicenza,
                non sottoposti a vincoli geografici o temporali, irrevocabili ed
                esenti da royalty, all'utilizzo, la riproduzione, la
                distribuzione, la trasmissione, l'esecuzione, la modifica nonché
                la visualizzazione in qualsivoglia formato multimediale e
                tecnologico, di tutti i Contenuti dell'artista correlati ai
                Servizi e ad Amazon Music for Artists.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s5">4.3.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>Suggerimenti. </b>
            </span>
            <span className="s1">
                Qualora l'Utente fornisca suggerimenti migliorativi ad Amazon
                Music for Artists (indicati anche come i "Suggerimenti"),
                quest'ultima avrà facoltà di utilizzarli senza limitazioni. Con
                la presente l'Utente cede irrevocabilmente ad Amazon ogni
                diritto, titolo e interesse relativo ai Suggerimenti e accetta
                di fornire ad Amazon ogni assistenza si renda eventualmente
                necessaria al fine di documentare, perfezionare e mantenere i
                diritti di Amazon sui Suggerimenti.
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">5.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Sicurezza e riservatezza dei dati</b>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.1.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>Divieto di ingegneria inversa e di elusione. </b>
            </span>
            <span className="s1">
                L'Utente è tenuto ad astenersi dalla modifica, la decodifica,
                l'aggiramento, la decompilazione, il disassemblaggio,
                l'alterazione e la manomissione, comunque effettuati, di
                qualsivoglia parte di Amazon Music for Artists; l'Utente è
                tenuto altresì a non incoraggiare, assistere o autorizzare
                chicchessia nell'esecuzione delle sopraelencate attività.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.2.</span>
            <span className="s4">   </span>
            <span className="s1">
                <b>Divieto di raccolta dati in blocco. </b>L'Utente è tenuto a
                non "passare al setaccio" Amazon Music for Artists né altrimenti
                utilizzare qualsivoglia strumento di data mining, scraping o
                raccolta di dati allo scopo di raccogliere informazioni da
                Amazon; l'Utente è tenuto altresì a non incoraggiare, assistere
                o autorizzare chicchessia nell'esecuzione delle sopraelencate
                attività.
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">6.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    Modifiche e sospensione
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">6.1.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>Cambiamenti. </b>
            </span>
            <span className="s1">
                Amazon ha facoltà di procedere in qualsiasi momento alla
                modifica, sospensione o interruzione di Amazon Music for
                Artists, in tutto o in parte. Amazon ha facoltà di aggiungere o
                rimuovere, di volta in volta, qualsivoglia funzionalità da
                Amazon Music for Artists. In talune circostanze residuali e
                insolite, Amazon potrà trovarsi nella posizione di non poter
                garantire i caricamenti, oppure l'accesso a particolari
                funzionalità o agli Approfondimenti sui fan.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">6.2.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>Sospensione e recesso da parte di Amazon. </b>
            </span>
            <span className="s1">
                I diritti riconosciuti all'Utente dal presente Contratto si
                estingueranno automaticamente in caso di inadempimento, da parte
                dell'Utente stesso, a qualsivoglia previsione del Contratto.
                Amazon ha facoltà di recedere dal Contratto o limitare,
                sospendere o interrompere l'utilizzo di Amazon Music for Artists
                in qualsiasi momento, per motivazioni che comprendono, tra le
                altre, un utilizzo di Amazon Music for Artists, da parte
                dell'Utente, contrario alle previsioni del Contratto, o comunque
                improprio o sostanzialmente eccedente o differente dal normale
                utilizzo da parte degli altri utenti, oppure implicante truffe o
                abusi, o comunque lesivo di interessi di Amazon o di altri
                utenti di Amazon Music for Artists. L'Utente ha facoltà di
                recedere dal Contratto per qualsivoglia motivazione, mediante
                chiusura del proprio account. All'Utente, qualora sia
                destinatario di limitazione, sospensione o cessazione
                dell'utilizzo di Amazon Music for Artists, potrà essere impedito
                l'accesso agli Approfondimenti dei fan. In caso di sospensione o
                recesso, Amazon manterrà i diritti sui Contenuti dell'artista
                alla stessa riconosciuti in base al Contratto.
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">7.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Poteri di firma e indennizzi </b>L'Utente dichiara e
                garantisce: (a) di essere pienamente legittimato a concedere ad
                Amazon i diritti di cui al Contratto; (b) che i materiali
                inviati dall'Utente, nonché il loro utilizzo da parte di Amazon
                come autorizzato dal Contratto, (i) non costituiscono violazione
                o contraffazione di qualsivoglia diritto alla privacy, diritto
                di pubblicità, diritto di proprietà intellettuale o altro
                diritto di terze parti, (ii) non comportano diffamazione, atto
                illecito o osceno e (iii) non vincolano Amazon al pagamento in
                favore di terzi di royalty o altri oneri per l'utilizzo di tali
                materiali; e (c) che tutte le dichiarazioni fattuali inviate
                dall'Utente sono accurate e veritiere. L'Utente accetta di
                risarcire, difendere e mantenere indenne Amazon e i suoi
                amministratori, funzionari, dipendenti, agenti e cessionari da
                qualsivoglia rivendicazione, responsabilità, danno o spesa, ivi
                comprese le ragionevoli spese legali derivanti dai (o correlate
                ai) materiali inviati, dall'inadempimento da parte dell'Utente
                al Contratto o alle dichiarazioni o garanzie, oppure dall'uso
                improprio, non autorizzato o illecito di Amazon Music for
                Artists da parte dell'Utente.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">8.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>Condizioni Generali</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">8.1.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>Modifiche. </b>
            </span>
            <span className="s1">
                Amazon ha facoltà di apportare modifiche al Contratto in
                qualsivoglia momento, pubblicando su (o tramite) Amazon Music
                for Artists i termini contrattuali così modificati. L'uso
                continuato, da parte dell'Utente, di Amazon Music for Artists a
                seguito di qualsivoglia modifica comporta il consenso, da parte
                dello stesso, ai nuovi termini contrattuali.
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">8.2.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>Comunicazioni. </b>
            </span>
            <span className="s1">
                Le comunicazioni da Amazon verso l'Utente, concernenti
                l'utilizzo di Amazon Music for Artists da parte dello stesso,
                potranno avere luogo in modalità elettronica o in altra
                modalità, e l'Utente acconsente a tali modalità
                indipendentemente da ogni comunicazione o preferenza o richiesta
                che l'Utente abbia eventualmente indicato sul sito web
                Amazon.com o tramite altro mezzo.
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.3.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>Comunicazioni in materia di diritto d'autore. </b>
            </span>
            <span className="s1">
                L'Utente, qualora ritenga che il suo lavoro sia stato copiato in
                una modalità che costituisce violazione del diritto d'autore, è
                tenuto a seguire la Procedura per la notifica e l'avanzamento di
                pretese in materia di violazioni del diritto d'autore, di cui
                alle{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909000&amp;view-type=content-only">
                    <span className="s8">Condizioni di utilizzo</span>
                </a>
                .
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.4.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>Clausola di esclusività e separabilità. </b>
            </span>
            <span className="s1">
                Il Contratto costituisce la totalità delle intese intercorse tra
                l'Utente e Amazon, e disciplina l'utilizzo di Amazon Music for
                Artists da parte dell'Utente, sostituendo qualsiasi accordo
                precedente tra lo stesso e Amazon. Qualora una qualsiasi
                previsione del presente Contratto risulti invalida, nulla o
                comunque inapplicabile, tale previsione sarà da ritenersi
                separabile dalle altre e non pregiudicherà la validità e
                applicabilità delle restanti previsioni.
            </span>
        </p>
    </div>
);
