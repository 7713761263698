import React, { Dispatch, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { RootState, promoCardActions } from "../../../store";
import { CardLayout, PromoCardBackgroundColor } from "../../../models";
import {
    PromoCardBackgroundColorList,
    backgroundColorMapping,
} from "../../../utils";

type ColorBoxProps = {};
const testIDPrefix = "HypeCardCustomizeColor";

export const ColorBox: React.FC<ColorBoxProps> = ({}) => {
    const dispatch: Dispatch<AnyAction> = useDispatch();

    const cardLayout = useSelector(
        (state: RootState) => state.promoCard.selectedCardLayout
    );

    const setCardColor = useCallback(
        (cardColor: PromoCardBackgroundColor) => {
            dispatch(promoCardActions.selectCardColor(cardColor));
        },
        [dispatch]
    );

    const handleGradientClick = (index: number) => {
        setSelectedIndex(index);
        setCardColor(PromoCardBackgroundColorList[index]);
    };

    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    return (
        <div
            id={`${testIDPrefix}_ColorSelector_Row`}
            style={{ marginTop: 20, display: "flex", flexDirection: "row" }}
        >
            {PromoCardBackgroundColorList.map((color, index) => (
                <div
                    id={`${testIDPrefix}_color_${index}`}
                    key={index}
                    style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        backgroundImage: `url(${
                            backgroundColorMapping.get(color)?.portraitGradient
                        })`,
                        backgroundSize: "cover",
                        margin: "0 8px",
                        border:
                            index === selectedIndex
                                ? "2px solid #25D1DA"
                                : "none",
                    }}
                    onClick={() => handleGradientClick(index)}
                ></div>
            ))}
        </div>
    );
};
