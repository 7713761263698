import React, { Dispatch, useCallback, useEffect, useState } from "react";
import * as rootStyles from "../../styles";
import { BundleMap, CardLayout, EntityType } from "../../../models";
import {
    LargeOutlineButton,
    LargeSolidButton,
    RadioList,
    styledTitle,
} from "../../../view/components";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { RootState, promoCardActions } from "../../../store";
import { ColorBox } from "./ColorBox";
import { PromoCardStep, getLocalizedString, languageMap } from "../../../utils";
import { Dropdown } from "react-bootstrap";
import { dropDownStyles } from "../../styles/buttonStyles";
import { bundleIds, stringIds } from "@amzn/ziggy-asset";

type CustomizeCardProps = {
    bundleMap: BundleMap;
};

const testIDPrefix = "CustomizeCard";

const cardLayoutList: CardLayout[] = [
    CardLayout.Portrait,
    CardLayout.Landscape,
    CardLayout.Square,
];

export const CustomizeCard: React.FC<CustomizeCardProps> = ({ bundleMap }) => {
    const LayoutRadioItems = () => {
        return [
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.cardTypePortrait,
                }),
            },
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.cardTypeLandscape,
                }),
            },
            {
                primary: getLocalizedString(bundleMap, {
                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                    stringId: stringIds.PromoCard.cardTypeSquare,
                }),
            },
        ];
    };

    const dispatch: Dispatch<AnyAction> = useDispatch();

    const setStepIndex = useCallback(
        (step: number) => {
            dispatch(promoCardActions.selectStep(step));
        },
        [dispatch]
    );

    const layout = useSelector(
        (state: RootState) => state.promoCard.selectedCardLayout
    );

    const selectedEntity = useSelector(
        (state: RootState) => state.promoCard.selectedEntity
    );

    const selectedCardLocale = useSelector(
        (state: RootState) => state.promoCard.selectedCardLocale
    );

    const setCardLocale = useCallback(
        (cardLocale: string) => {
            dispatch(promoCardActions.selectCardLocale(cardLocale));
        },
        [dispatch]
    );

    const setCardLayout = useCallback(
        (cardLayout: CardLayout) => {
            dispatch(promoCardActions.selectCardLayout(cardLayout));
        },
        [dispatch]
    );

    const onSelectContinue = () => {
        setStepIndex(PromoCardStep.SHARE);
    };

    const onSelectBack = () => {
        setStepIndex(PromoCardStep.SELECT);
    };

    const getLanguageLists = () => {
        const ret: JSX.Element[] = [];
        languageMap.forEach((language: string, locale: string) => {
            ret.push(
                <Dropdown.Item
                    eventKey={language}
                    onSelect={() => setCardLocale(locale)}
                    style={dropDownStyles.dropdownItem}
                >
                    {language}
                </Dropdown.Item>
            );
        });
        return ret;
    };

    return (
        <div
            style={{
                padding: rootStyles.spacers.huge,
            }}
        >
            <div
                id={`${testIDPrefix}_CartTitle`}>
                <styledTitle.h4>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PROMO_CARD_STRINGS,
                        stringId: stringIds.PromoCard.customizeCardTitle,
                    })}
                </styledTitle.h4>
            </div>
            <div style={{ marginTop: rootStyles.spacers.large }}>
                <span
                    style={{
                        ...rootStyles.textStyles.tertiary,
                        textTransform: "uppercase",
                    }}
                >
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PROMO_CARD_STRINGS,
                        stringId: stringIds.PromoCard.cardTypeTitle,
                    })}
                </span>
            </div>
            <div 
                id={`${testIDPrefix}_LayoutSelector_Container`}
            >
                <RadioList
                    elements={LayoutRadioItems()}
                    onChangeElement={(index: number) =>
                        setCardLayout(cardLayoutList[index])
                    }
                    horizontal={true}
                    centeredElements={true}
                    initialSelectedIndex={cardLayoutList.indexOf(layout)}
                    textNoWrap={true}
                    elementSpacing={rootStyles.spacers.huge}
                    containerStyling={{}}
                    id={`${testIDPrefix}_selectEntity`}
                />
            </div>
            <div style={{ marginTop: rootStyles.spacers.mini }}>
                <span
                    style={{
                        ...rootStyles.textStyles.tertiary,
                    }}
                    id={`${testIDPrefix}_LayoutSelector_Text`}
                >
                    {layout === CardLayout.Portrait
                        ? getLocalizedString(bundleMap, {
                              bundleId: bundleIds.PROMO_CARD_STRINGS,
                              stringId:
                                  stringIds.PromoCard.instructionTextPortrait,
                          })
                        : layout === CardLayout.Landscape
                        ? getLocalizedString(bundleMap, {
                              bundleId: bundleIds.PROMO_CARD_STRINGS,
                              stringId:
                                  stringIds.PromoCard.instructionTextLandscape,
                          })
                        : getLocalizedString(bundleMap, {
                              bundleId: bundleIds.PROMO_CARD_STRINGS,
                              stringId:
                                  stringIds.PromoCard.instructionTextSquare,
                          })}
                </span>
            </div>
            {selectedEntity !== EntityType.Playlist ? (
                <div id={`${testIDPrefix}_ColorSelector_Container`}>
                    <div style={{ marginTop: rootStyles.spacers.large }}>
                        <span
                            style={{
                                ...rootStyles.textStyles.tertiary,
                            }}
                        >
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PROMO_CARD_STRINGS,
                                stringId: stringIds.PromoCard.cardColorTitle,
                            })}
                        </span>
                    </div>
                    <ColorBox />
                </div>
            ) : null}
            <div
                id={`${testIDPrefix}_LanguageSelector_Container`}
                style={{
                    marginTop: rootStyles.spacers.large,
                }}
            >
                <span
                    style={{
                        ...rootStyles.textStyles.tertiary,
                    }}
                >
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.PROMO_CARD_STRINGS,
                        stringId: stringIds.PromoCard.cardLanguageTitle,
                    })}
                </span>
                <div
                    style={{
                        marginTop: rootStyles.spacers.mini,
                        width: "178px",
                    }}
                >
                    <Dropdown
                        style={{
                            ...dropDownStyles.dropdown,
                            margin: "auto",
                            width: "100%",
                        }}
                        drop={
                            window.innerHeight <= rootStyles.breakpoints.lg
                                ? "up"
                                : "down"
                        }
                    >
                        <Dropdown.Toggle
                            style={{
                                ...dropDownStyles.dropdownButton,
                                width: "100%",
                            }}
                            id="language"
                        >
                            <span>{languageMap.get(selectedCardLocale)}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={dropDownStyles.dropdownMenu}>
                            {getLanguageLists()}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <div
                style={{ flexDirection: "row", display: "flex", marginTop: 40 }}
            >
                <div>
                    <LargeOutlineButton
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.GENERIC_STRINGS,
                            stringId: stringIds.Generic.goBack,
                        })}
                        onClick={onSelectBack}
                        id={`${testIDPrefix}_Back`}
                        buttonStyle={{ width: 120, height: 40 }}
                    />
                </div>
                <div style={{ marginLeft: 24 }}>
                    <LargeSolidButton
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.GENERIC_STRINGS,
                            stringId: stringIds.Generic.continue,
                        })}
                        onClick={onSelectContinue}
                        id={`${testIDPrefix}_Continue`}
                        buttonStyle={{ width: 120, height: 40 }}
                    />
                </div>
            </div>
        </div>
    );
};
