import { createAction } from "redux-ts";
import { telemetryPayload } from "../../models";

export const appEvent = createAction<telemetryPayload>("TELEMETRY::APP_EVENT");
export const userAction = createAction<telemetryPayload>(
    "TELEMETRY::USER_ACTION"
);
export const errorEvent = createAction<telemetryPayload>(
    "TELEMETRY::ERROR_EVENT"
);
