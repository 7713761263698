import { merchImages } from "../../../assets";
import styled from "styled-components";
import * as rootStyles from "../../styles";

export const AnnouncementToast = styled.div`
    display: flex;
    margin: ${rootStyles.spacers.small}px;
    border-radius: 5px;
    background-color: ${rootStyles.glassColors.accent1};
    border-color: ${rootStyles.colors.accent};
    border-width: 1px;
    border-style: solid;
    height: 48px;
    align-items: center;
    justify-content: center;
`;

export const MerchCurationRibbon = styled.div`
    display: flex;
    margin: ${rootStyles.spacers.small}px;
    border-radius: 5px;
    background: url(${merchImages.backgroundGradient});
    background-size: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

export const MobileAnnouncement = styled.div`
    display: flex;
    border-radius: 5px;
    padding: ${rootStyles.spacers.small}px;
    background-color: ${rootStyles.glassColors.accent2};
    border-style: none;
    align-items: center;
    justify-content: center;
`;
