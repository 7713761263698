import { getLocalizedString } from "./../../utils/stringHelper";
import { webLocale } from "./../../utils/localeType";
import { stringIds, bundleIds } from "./../../assets/strings";
import { CsvType } from "./csvTypes";
import { BundleMap } from "../../models";
const csvFileFormat: string = "data:text/csv;charset=utf-8,\uFEFF";

const dateFormatOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
};

// Data Types
export type Trendline = [
    Date, // Date
    number, //Streams
    number // Listeners
];

export type TopContent = [
    string, // Content Name
    number // Streams
];

export type Playlist = [
    string, // Playlist name
    string, // Territories
    number // streams
];

export type FansTrendLine = [
    Date, // Date
    number, // Fans
    number // Superfans
];

type CsvDataRow = Trendline | TopContent | Playlist | FansTrendLine;

export type FilenameInfo = {
    artistName: string;
    contentName?: string;
    startDate: Date;
    endDate: Date;
};

type CsvMetaData = {
    filename: string;
    headers: string[];
};

const downloadCSV = (
    csvType: CsvType,
    data: CsvDataRow[],
    filenameInfo: FilenameInfo,
    bundleMap: BundleMap
): void => {
    const CsvMetadata = getCsvMetadata(csvType, filenameInfo, bundleMap);
    const csvHeaders = CsvMetadata.headers.join(",") + "\n";
    const csvData = data.map((row) => buildCsvRow(row)).join("\n");

    const csvContent = `${csvHeaders}${csvData}`;
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");
    link.href = `${csvFileFormat}${encodedUri}`;
    link.target = "_blank";
    link.download = CsvMetadata.filename;
    link.click();
};

const buildCsvRow = (row: any[]): string => {
    return row
        .map((column) => {
            if (column instanceof Date) {
                return dateDataFormatter(column);
            }
            const quoteRegEx = new RegExp('"', "gi");
            // additional quotes helps escape any commas in string, which can unintentionally offset row data
            // Also replace one quote with two quotes to avoid closing the quotes applied in below line
            return `"${column.toString().replace(quoteRegEx, '""')}"`;
        })
        .join(",");
};

const dateDataFormatter = (date: Date): string => {
    const locale = webLocale[0].replace("_", "-");
    return date.toLocaleDateString(locale, dateFormatOptions);
};

const dateFilenameFormatter = (date: Date): string => {
    const locale = webLocale[0].replace("_", "-");
    return new Date(date)
        .toLocaleDateString(locale, dateFormatOptions)
        .replace(/\//g, "")
        .replace(/\./g, "");
};

const getCsvMetadata = (
    csvType: CsvType,
    filenameInfo: FilenameInfo,
    bundleMap: BundleMap
): CsvMetaData => {
    const contentName: string = filenameInfo.contentName
        ? `${filenameInfo.contentName}_`.replace(/ /g, "_")
        : "";
    const artistName = filenameInfo.artistName.replace(/ /g, "_");
    const startDate: string = dateFilenameFormatter(filenameInfo.startDate);
    const endDate: string = dateFilenameFormatter(filenameInfo.endDate);

    switch (csvType) {
        case "trendlines":
            return {
                filename: `${artistName}_${contentName}Trendlines_Total_Streams_Listeners_${startDate}_${endDate}.csv`,
                headers: [
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Date,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Streams,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Listeners,
                    }),
                ],
            };
        case "topSongs":
            return {
                filename: `${artistName}_${contentName}Top_Songs_${startDate}_${endDate}.csv`,
                headers: [
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.SongName,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Streams,
                    }),
                ],
            };
        case "topSongsOnAlbum":
            return {
                filename: `${artistName}_${contentName}Top_Songs_on_Album_${startDate}_${endDate}.csv`,
                headers: [
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.SongName,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Streams,
                    }),
                ],
            };
        case "topAlbums":
            return {
                filename: `${artistName}_${contentName}Top_Albums_${startDate}_${endDate}.csv`,
                headers: [
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.AlbumName,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Streams,
                    }),
                ],
            };
        case "featuredInPlaylists":
            return {
                filename: `${artistName}_${contentName}Featured_in_Playlists_${startDate}_${endDate}.csv`,
                headers: [
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.PlaylistName,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.PlaylistTerritory,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Streams,
                    }),
                ],
            };
        case "featuredInStations":
            return {
                filename: `${artistName}_${contentName}Featured_in_Stations_${startDate}_${endDate}.csv`,
                headers: [
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.StationName,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Streams,
                    }),
                ],
            };
        case "fans":
            return {
                filename: `${artistName}_Fans_Superfans_Trendlines_${startDate}_${endDate}.csv`,
                headers: [
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Date,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Fans,
                    }),
                    getLocalizedString(bundleMap, {
                        bundleId: bundleIds.CSV_STRINGS,
                        stringId: stringIds.Csv.Header.Superfans,
                    }),
                ],
            };
    }
};

export const CsvHelper = {
    downloadCSV,
    buildCsvRow,
    dateDataFormatter,
    dateFilenameFormatter,
    getCsvMetadata,
};
