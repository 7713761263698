import * as React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";
import { Table, Loading, InlineError, ProfileHeadProps, ProfileHead } from "..";
import { BundleMap, baseCatalogItem, countryInfo } from "../../../models";
import * as rootStyles from "../../styles";
import {
    getLocalizedString,
    DEMOGRAPHICS_ITEM_COUNT,
    generateCountryRows,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import { RootState } from "../../../store";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

export type CountriesStreamsProps = {
    topCountries: countryInfo[];
    testIDPrefix: string;
    inProgress?: boolean;
    refreshing: boolean;
    showError: boolean;
    catalogItem?: baseCatalogItem;
    entityLabel: string;
    getReports: () => void;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = CountriesStreamsProps & StateProps;

export class CountriesStreamsClass extends React.Component<Props> {
    render() {
        if (this.props.inProgress && !this.props.refreshing) {
            return <Loading />;
        }
        const topCountriesHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.countriesTitle,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        let topCountriesRows = generateCountryRows(
            this.props.topCountries,
            this.props.testIDPrefix + "_TopCountriesRows"
        );
        topCountriesRows.slice(0, DEMOGRAPHICS_ITEM_COUNT);

        let profileHeadProps: ProfileHeadProps = {
            imageSource: "",
            title: "",
            subtitle: this.props.entityLabel,
            id: `${this.props.testIDPrefix}_ProfileHead`,
        };

        const catalogItem = this.props.catalogItem;
        if (catalogItem) {
            profileHeadProps = {
                ...profileHeadProps,
                imageSource:
                    catalogItem && catalogItem?.images?.artMedium
                        ? catalogItem?.images?.artMedium
                        : "",
                title: catalogItem.title || "",
                imageDescription: catalogItem.title,
            };
        }

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    ...rootStyles.containerStyles.tableContainerStyle,
                }}
            >
                <Row>
                    {this.props.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.props.getReports}
                            id={`${this.props.testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {catalogItem && <ProfileHead {...profileHeadProps} />}
                <Row>
                    <Col>
                        <Table
                            rows={topCountriesRows}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId: stringIds.Reports.topCountriesTitle,
                            })}
                            labels={topCountriesHeader}
                            id={this.props.testIDPrefix + "_CountriesTable"}
                            showIndex={true}
                            footer={
                                <div
                                    style={{ height: rootStyles.spacers.huge }}
                                />
                            }
                            emptyMessage={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.EMPTYSTATE_STRINGS,
                                    stringId: stringIds.EmptyState.message,
                                }
                            )}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

export const CountriesStreams = connect(mapStateToProps)(CountriesStreamsClass);
