import * as React from "react";
import { Row, Col, Dropdown, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import styled from "styled-components";
import * as rootStyles from "../../styles";
import { RootState, userActions } from "../../../store";
import {
    UserLanguage,
    getWebLocale,
    translateLocaleStringToUserLanguage,
    translateUserLanguageToLocaleString,
    userLanguages,
    webLocale,
} from "../../../utils";
import { dropDownStyles } from "../../../view/styles/buttonStyles";
import { colors, glassColors } from "../../../view/styles";
import { ImageList, IconsList } from "../../../assets";
import { Icon } from "../../../view/components";

const Logo = require("../../../assets/images/am4a_h_wht.png");

const testIDPrefix = "LanguageSelectionScreen";

type State = {
    userLocale: string;
    onHoveredUserLocale: string;
};

type Props = {
    userLocale: string;
};

type DispatchProps = {
    updateUserLocale: (locale: string) => void;
};

const userLanguageToFlagIconMap = new Map<string, string>([
    [UserLanguage.DE_DE, ImageList.flag_de],
    [UserLanguage.EN_US, ImageList.flag_us],
    [UserLanguage.ES_ES, ImageList.flag_es],
    [UserLanguage.FR_FR, ImageList.flag_fr],
    [UserLanguage.IT_IT, ImageList.flag_it],
    [UserLanguage.JA_JP, ImageList.flag_jp],
    [UserLanguage.PT_BR, ImageList.flag_br],
]);

export class LanguageSelectionScreen extends React.Component<
    Props & DispatchProps,
    State
> {
    constructor(props: any) {
        super(props);
        this.state = {
            userLocale: this.props.userLocale || getWebLocale(),
            onHoveredUserLocale: "",
        };
    }

    render() {
        return (
            <StyledContainer>
                <Col xs={"auto"} style={{ padding: 0 }}>
                    <StyledLogo src={Logo} />
                </Col>
                <Col
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 0,
                    }}
                >
                    <StyledDropDown style={{ ...dropDownStyles.dropdown }}>
                        <div style={{ marginRight: 42 }}>
                            <Dropdown.Toggle
                                style={dropdownButtonStyle}
                                id="userLanguagePicker"
                            >
                                <Row>
                                    <StyledIconDiv>
                                        <Icon
                                            icon={userLanguageToFlagIconMap.get(
                                                translateLocaleStringToUserLanguage(
                                                    this.state.userLocale
                                                )
                                            )}
                                            size={24}
                                            id={`${testIDPrefix}_FlagIcon`}
                                        />
                                    </StyledIconDiv>
                                    <span style={dropdownSubjectTextStyle}>
                                        {translateLocaleStringToUserLanguage(
                                            this.state.userLocale
                                        )}
                                    </span>
                                </Row>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={dropdownMenuStyle}>
                                {this.getUserLanguage()}
                            </Dropdown.Menu>
                        </div>
                    </StyledDropDown>
                </Col>
            </StyledContainer>
        );
    }

    private getUserLanguage = () => {
        return userLanguages.map((language: UserLanguage) => {
            const isSelected =
                this.state.userLocale ===
                translateUserLanguageToLocaleString(language);
            return (
                <Dropdown.Item
                    eventKey={language}
                    onSelect={() => this.selectUserLanguage(language)}
                    style={dropDownStyles.dropdownItem}
                    onMouseEnter={() =>
                        this.setState({
                            onHoveredUserLocale:
                                translateUserLanguageToLocaleString(language),
                        })
                    }
                    onMouseLeave={() =>
                        this.setState({ onHoveredUserLocale: "" })
                    }
                >
                    <Row>
                        <Row style={{ flex: 1 }}>
                            <div
                                style={{
                                    marginRight: rootStyles.spacers.small,
                                    height: "100%",
                                }}
                            >
                                <Icon
                                    icon={userLanguageToFlagIconMap.get(
                                        language
                                    )}
                                    size={24}
                                    id={`${testIDPrefix}_LanguageIcon_${language}`}
                                />
                            </div>
                            <span
                                style={{
                                    ...dropdownItemTextStyle,
                                    fontFamily: isSelected
                                        ? "AmazonEmber-Bold"
                                        : "AmazonEmber",
                                }}
                            >
                                {language.toString()}
                            </span>
                        </Row>
                        {isSelected && (
                            <Row style={{ justifyContent: "flex-end" }}>
                                <div
                                    style={{
                                        marginRight: rootStyles.spacers.small,
                                    }}
                                >
                                    <Icon
                                        icon={IconsList.action_done_black}
                                        size={24}
                                        id={`${testIDPrefix}_DoneIcon`}
                                    />
                                </div>
                            </Row>
                        )}
                    </Row>
                </Dropdown.Item>
            );
        });
    };

    private selectUserLanguage = (language: UserLanguage) => {
        if (
            this.state.userLocale ===
            translateUserLanguageToLocaleString(language)
        ) {
            return;
        }
        this.setState(
            { userLocale: translateUserLanguageToLocaleString(language) },
            () => this.storeState()
        );
    };

    private storeState = () => {
        const locale = this.state.userLocale;
        this.props.updateUserLocale(locale);
        webLocale[0] = locale;
    };
}

function mapStateToProps(state: RootState) {
    return {
        userLocale: state.user.locale,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        updateUserLocale: (locale: string) =>
            dispatch(userActions.updateUserLocale(locale)),
    };
}

const StyledContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
`;

const StyledLogo = styled.img`
    margin-left: ${rootStyles.spacers.huge}px;
    @media (max-width: ${rootStyles.breakpoints.md}px) {
        margin-left: ${rootStyles.spacers.mini}px;
    }
    height: 80%;
    width: 150px;
    object-fit: contain;
`;

const StyledDropDown = styled(Dropdown)`
    @media (max-width: ${rootStyles.breakpoints.md}px) {
        margin-right: -20px;
    }
`;

const StyledIconDiv = styled.div`
    margin-right: ${rootStyles.spacers.small}px;
    @media (max-width: ${rootStyles.breakpoints.md}px) {
        margin-right: ${rootStyles.spacers.micro}px;
    }
    height: 24px;
`;

const dropdownButtonStyle: React.CSSProperties = {
    ...dropDownStyles.dropdownButton,
    width: "100%",
    backgroundColor: "transparent",
    paddingLeft: rootStyles.spacers.micro,
    paddingRight: rootStyles.spacers.micro,
    boxShadow: "none",
};

const dropdownSubjectTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    textTransform: "uppercase",
    lineHeight: 1,
    alignSelf: "center",
    marginTop: 2,
};

const dropdownItemTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    textTransform: "uppercase",
    color: colors.secondary,
};

const dropdownMenuStyle: React.CSSProperties = {
    ...dropDownStyles.dropdownMenu,
    backgroundColor: glassColors.primary7,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageSelectionScreen);
