import * as React from "react";
import * as rootStyles from "../../styles";
import { ChartHeader } from "./ChartHeader";
import { getLocalizedString, testIDSuffixes } from "../../../utils";
import { containerStyles } from "./";
import { stringIds, gradients, bundleIds } from "../../../assets";
import { Card, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { BundleMap } from "../../../models";

const scoreScale = 100;
const minScore = 2.5;

type ViewProps = {
    score: number;
    title: string;
    bundleMap: BundleMap;
    id: string;
};

type State = {
    scoreContainerRef: any;
    scoreContainerWidth: number;
};

export class AlexaIndex extends React.Component<ViewProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            scoreContainerRef: React.createRef(),
            scoreContainerWidth: 500,
        };
    }

    componentDidMount() {
        if (this.state.scoreContainerRef.current?.clientWidth) {
            this.setState({
                scoreContainerWidth:
                    this.state.scoreContainerRef.current.clientWidth,
            });
        }
    }

    componentDidUpdate() {
        if (
            this.state.scoreContainerRef.current?.clientWidth &&
            this.state.scoreContainerRef.current?.clientWidth !=
                this.state.scoreContainerWidth
        ) {
            this.setState({
                scoreContainerWidth:
                    this.state.scoreContainerRef.current.clientWidth,
            });
        }
    }

    render() {
        return (
            <Card id="AlexaIndex.Card" style={containerStyles.chartContainer}>
                <Row>
                    <Row id="AlexaIndex.Header" style={headerContainer}>
                        <ChartHeader
                            title={this.props.title}
                            id={`${this.props.id}${testIDSuffixes.header}`}
                            moreButtonDescription={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.ALEXA_STRINGS,
                                    stringId: stringIds.Alexa.exportCharts,
                                }
                            )}
                        />
                    </Row>

                    <Row id="AlexaIndex.ScoreVisual" style={scoreContainer}>
                        <div style={gradientContainer}>
                            <img
                                src={this.getBackground(this.props.score)}
                                style={{
                                    ...gradientImage,
                                    width:
                                        (this.state.scoreContainerWidth *
                                            (this.props.score < minScore
                                                ? minScore
                                                : this.props.score)) /
                                        scoreScale,
                                }}
                                id={`${this.props.id}${testIDSuffixes.chart}`}
                            />
                        </div>

                        {this.generateBlocks()}

                        <TickContainer>
                            <Tick />
                            <Tick />
                            <Tick />
                        </TickContainer>

                        {this.generateLabels()}
                    </Row>
                </Row>

                <Row
                    id="AlexaIndex.ScoreText"
                    style={{ ...container, alignItems: "center" }}
                >
                    <span
                        style={{
                            ...rootStyles.textStyles.primary,
                            textAlign: "center",
                            marginBottom: rootStyles.spacers.medium,
                        }}
                        id={`${this.props.id}_ScoreInfo${testIDSuffixes.label}`}
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.ALEXA_STRINGS,
                            stringId: this.getCardText(this.props.score),
                        })}
                    </span>
                </Row>

                <Row id="AlexaIndex.SubTitle" key={`label_AlexaSubtitle`}>
                    <span
                        style={{
                            ...rootStyles.textStyles.tertiary,
                            textAlign: "center",
                        }}
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.ALEXA_STRINGS,
                            stringId: stringIds.Alexa.cardSubtitle,
                        })}
                    </span>
                </Row>
            </Card>
        );
    }

    private getBackground = (score: number) => {
        if (score <= scoreScale / 4) {
            return gradients.cool;
        }
        if (score <= scoreScale / 2) {
            return gradients.warm;
        }
        if (score <= (3 * scoreScale) / 4) {
            return gradients.hot;
        }

        return gradients.fire;
    };

    private getCardText = (score: number) => {
        if (score <= scoreScale / 4) {
            return stringIds.Alexa.cardInfoCool;
        }
        if (score <= scoreScale / 2) {
            return stringIds.Alexa.cardInfoWarm;
        }
        if (score <= (3 * scoreScale) / 4) {
            return stringIds.Alexa.cardInfoHot;
        }

        return stringIds.Alexa.cardInfoFire;
    };

    private generateBlocks = () => {
        const ticks = [];
        for (let index = 0; index < 40; index++) {
            ticks.push(<Col key={index} style={block} />);
        }

        return (
            <Row ref={this.state.scoreContainerRef} style={blockContainer}>
                {ticks}
            </Row>
        );
    };

    private generateLabels = () => {
        return (
            <Row style={labelContainer}>
                <Col
                    style={label}
                    id={`${this.props.id}_Cool${testIDSuffixes.label}`}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ALEXA_STRINGS,
                        stringId: stringIds.Alexa.cool,
                    })}
                </Col>
                <Col
                    style={label}
                    id={`${this.props.id}_Warm${testIDSuffixes.label}`}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ALEXA_STRINGS,
                        stringId: stringIds.Alexa.warm,
                    })}
                </Col>
                <Col
                    style={label}
                    id={`${this.props.id}_Hot${testIDSuffixes.label}`}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ALEXA_STRINGS,
                        stringId: stringIds.Alexa.hot,
                    })}
                </Col>
                <Col
                    style={label}
                    id={`${this.props.id}_OnFire${testIDSuffixes.label}`}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ALEXA_STRINGS,
                        stringId: stringIds.Alexa.onFire,
                    })}
                </Col>
            </Row>
        );
    };
}

const headerContainer: React.CSSProperties = {
    paddingBottom: rootStyles.spacers.large,
    width: "100%",
};
const gradientContainer: React.CSSProperties = {
    lineHeight: 1,
    width: "100%",
    overflow: "hidden",
    backgroundColor: rootStyles.glassColors.primary2,
};
const gradientImage: React.CSSProperties = {
    height: rootStyles.spacers.large,
    objectFit: "fill",
};
const container: React.CSSProperties = {
    paddingTop: rootStyles.spacers.mini,
    paddingBottom: rootStyles.spacers.small,
};
const scoreContainer: React.CSSProperties = {
    width: "100%",
};
const blockContainer: React.CSSProperties = {
    height: rootStyles.spacers.large,
    width: "100%",
    marginTop: -rootStyles.spacers.large,
    overflow: "hidden",
};
const block: React.CSSProperties = {
    height: rootStyles.spacers.large,
    margin: 0,
    padding: 0,
    border: 1,
    borderStyle: "solid",
    borderColor: rootStyles.generateColor(
        rootStyles.colors.secondary,
        rootStyles.glass._2
    ),
};

export const TickContainer = styled(Row)`
    margin-top: -${rootStyles.spacers.large}px;
    width: 100%;
`;

export const Tick = styled(Col)`
    height: 30px;
    border-right: 2px solid ${rootStyles.colors.primary};
    padding: 0;
    margin: 0;
    max-width: 25% !important;
`;

const labelContainer: React.CSSProperties = {
    width: "100%",
};
const label: React.CSSProperties = {
    ...rootStyles.textStyles.key,
    textAlign: "center",
};
