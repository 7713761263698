import { Action } from "redux-ts";
import { outageStatusEntry } from "../../models";
import { outageStatusActions } from "../actions";

export type OutageStatusState = Readonly<{
    outageStatuses: outageStatusEntry[];
    getOutageStatusInProgress: boolean;
    lastOutageStatusFetch: number; // timestamp in millis
}>;

export const initialOutageStatusState = {
    outageStatuses: [],
    getOutageStatusInProgress: false,
    lastOutageStatusFetch: 0
};

export const outageStatusReducer = (state: OutageStatusState = initialOutageStatusState, action: Action) => {
    switch (action.type) {
        case outageStatusActions.getOutageStatusCompleted.type:
            return {
                ...state,
                outageStatuses: action.payload,
                lastOutageStatusFetch: Date.now()
            };
        case outageStatusActions.getOutageStatusInProgress.type:
            return {
                ...state,
                getOutageStatusInProgress: action.payload
            };
        default:
            return state;
    }
};
