import React from "react";
import { FullScreenConfirmationModal } from "./";

export type DeferredActionModalProps = {
    isVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;

    id: string;

    icon: string;
    title: string;
    description: string;

    confirmButtonText: string;
    cancelButtonText: string;
};

/**
 * Small wrapper around FullScreenConfirmationModal specifically for confirming
 * actions. Assumes that the dismiss & cancel actions are the same.
 */
export class DeferredActionModal extends React.Component<DeferredActionModalProps> {
    render() {
        return (
            <FullScreenConfirmationModal
                isVisible={this.props.isVisible}
                confirmButtonAction={this.props.onConfirm}
                cancelButtonAction={this.props.onCancel}
                onDismiss={this.props.onCancel}
                id={this.props.id}
                icon={this.props.icon}
                text={this.props.title}
                description={this.props.description}
                confirmButtonText={this.props.confirmButtonText}
                cancelButtonText={this.props.cancelButtonText}
            />
        );
    }
}
