import {
    QueryType,
    catalogItem,
    track,
    album,
    artist,
    playlist,
    station,
    catalog,
    hydrateCatalogPayload,
    CatalogItemType,
} from "../models";
import { checkIfNotEmpty, getHydration, Hydrator } from ".";
import { CatalogState } from "../store";
import moment from "moment";
import _ from "lodash";

const HYDRATION_BATCH_SIZE = 20;

export const MAX_HYDRATION_COUNT = 50;

const getGlobalAsinsToQuery = (
    titleSetAsinToGlobalAsinMap: Map<string, string>,
    failedAsins: string[]
) => {
    const globalAsinToTitleSetAsinsToQuery = new Map<string, string>();
    titleSetAsinToGlobalAsinMap?.forEach((value, key) => {
        if (failedAsins.includes(key)) {
            // inverting the key and value makes this easier to reverse-lookup after service call.
            globalAsinToTitleSetAsinsToQuery.set(value, key);
        }
    });

    return globalAsinToTitleSetAsinsToQuery;
};

const getFailedAsins = (
    asins: string[],
    catalog: catalog,
    type: CatalogItemType
) => {
    return asins.filter((asin) => {
        if (!catalog.has(asin) || !checkIfNotEmpty(catalog.get(asin))) {
            console.log("ASIN failed to hydrate:" + asin + " for type " + type);
            return true;
        }
        return false;
    });
};

const getTitleSetMapFromProxyResponse = (dataMap: any): Map<string, string> => {
    const map = new Map<string, string>();
    Object.values(dataMap).forEach((titleSet: any) => {
        if (
            titleSet &&
            titleSet.titleSetAsin &&
            titleSet.globalAsins &&
            titleSet.globalAsins.length != 0
        ) {
            map.set(titleSet.titleSetAsin, titleSet.globalAsins[0]);
        }
    });
    return map;
};

/**
 * Converts a globalAsin -> catalogItem mapping to a titleSetAsin -> catalogItem mapping, based on a provided globalAsin -> titleSetAsin mapping
 */
const getCatalogForFailedTitleSetAsins = (
    globalAsinCatalog: Map<string, catalogItem>,
    globalAsinToTitleSetAsinMapping: Map<string, string>
) => {
    const newCatalog = new Map<string, catalogItem>();
    globalAsinCatalog.forEach((value, key) => {
        const titleSetAsin = globalAsinToTitleSetAsinMapping.get(key);
        if (titleSetAsin) {
            newCatalog.set(titleSetAsin, value);
        }
    });
    return newCatalog;
};

const getCatalogFromProxyResponse = (dataMap: any, dataParser: any) => {
    const newCatalog = new Map<string, catalogItem>();
    Object.values(dataMap).forEach((catalogItem: any) => {
        if (catalogItem && catalogItem.asin) {
            const parsedItem = dataParser(catalogItem);
            if (parsedItem) {
                newCatalog.set(parsedItem.asin, parsedItem);
            }
        }
    });
    return newCatalog;
};

const getUnhydratedAlbums = (
    catalog: Map<string, catalogItem>,
    catalogState: CatalogState
) => {
    const unhydratedAsins = new Set<string>();
    catalog.forEach((item: catalogItem) => {
        const track = item as track;
        if (
            track &&
            track.albumAsin &&
            !hasAttemptedToHydrate(track.albumAsin, catalogState)
        ) {
            unhydratedAsins.add(track.albumAsin);
        }
    });
    return unhydratedAsins;
};

const getUnhydratedArtists = (catalog: catalog, catalogState: CatalogState) => {
    const unhydratedAsins = new Set<string>();
    catalog.forEach((catalogItem: catalogItem) => {
        const item = catalogItem as album | track;
        if (item && item.artistAsins) {
            item.artistAsins
                .filter(
                    (asin: string) => !hasAttemptedToHydrate(asin, catalogState)
                )
                .forEach((asin: string) => unhydratedAsins.add(asin));
        }
    });
    return unhydratedAsins;
};

// Returns whether the asin has been hydrated, is hydrating, or failed to hydrate.
const hasAttemptedToHydrate = (asin: string, catalogState: CatalogState) => {
    return (
        catalogState.catalog.get(asin)?.asin ||
        catalogState.hydratingAsins.includes(asin) ||
        catalogState.failedAsins.includes(asin)
    );
};

const artistParser = (catalogArtist: any): artist => {
    return {
        ...catalogArtist,
        ttl: moment().add(1, "hour"),
    };
};

const albumParser = (catalogAlbum: any): album => {
    return {
        ...catalogAlbum,
    };
};

const stationParser = (catalogProgramming: any): station => {
    return {
        ...catalogProgramming,
    };
};

const playlistParser = (catalogPlaylist: any): playlist => {
    return {
        ...catalogPlaylist,
    };
};

const trackParser = (catalogTrack: any): track => {
    return {
        ...catalogTrack,
        albumAsin: catalogTrack.albumAsin || catalogTrack.album,
    };
};

const getTitlesetAsinToGlobalAsinMap = (
    mediaInfo: { asin: string; globalAsin?: string }[]
): Map<string, string> => {
    const mediaTitlesetAsinToGlobalAsinMap = new Map<string, string>();
    mediaInfo.forEach((media) => {
        if (media.globalAsin) {
            mediaTitlesetAsinToGlobalAsinMap.set(media.asin, media.globalAsin);
        }
    });
    return mediaTitlesetAsinToGlobalAsinMap;
};

const hydrateAsinsBatch = (
    loadedCount: number,
    mediaInfo: { asin: string; globalAsin?: string }[],
    hydrateCall: (payload: hydrateCatalogPayload) => void,
    catalogItemType: CatalogItemType,
    locale?: string
): number => {
    const currentCount = loadedCount;
    const tracksInfo = mediaInfo;

    if (currentCount >= mediaInfo.length) {
        return currentCount;
    }

    const nextCount = currentCount + HYDRATION_BATCH_SIZE;
    const nextBatch = tracksInfo.slice(currentCount, nextCount);

    const titleSetAsinToGlobalAsinMap: Map<string, string> | undefined =
        catalogItemType === CatalogItemType.Albums ||
        catalogItemType === CatalogItemType.Tracks
            ? hydrationHelpers.getTitlesetAsinToGlobalAsinMap(nextBatch)
            : undefined;

    const filtered = _.filter(nextBatch, (info) => checkIfNotEmpty(info));

    hydrateCall({
        asins: _.map(filtered, (info) => info.asin.toString()),
        type: catalogItemType,
        titleSetAsinToGlobalAsinMap: titleSetAsinToGlobalAsinMap,
        locale: locale,
    });

    return nextCount;
};

export async function iterativeHydrationCall({
    catalogState,
    hydrationType,
    asins,
    teamId,
    locale,
}: {
    catalogState: CatalogState;
    hydrationType: CatalogItemType;
    asins: string[];
    teamId: string;
    locale: string;
}) {
    //chunk asins into groups of 50 for separate hydration calls
    const asinGroups = _.chunk(asins, MAX_HYDRATION_COUNT);
    const hydrater: Hydrator = getHydration(hydrationType);
    const compositeCatalog: catalog = catalogState.catalog;

    for (let i = 0; i < asinGroups.length; i++) {
        const { newCatalog } = await hydrater({
            asins: asinGroups[i],
            teamId: teamId,
            catalogState: catalogState,
            locale: locale,
        });
        newCatalog.forEach((item: catalogItem, asin: string) => {
            compositeCatalog.set(asin, {
                ...compositeCatalog.get(asin),
                ...item,
            });
        });
    }
    return compositeCatalog;
}

export const hydrationHelpers = {
    getGlobalAsinsToQuery,
    getFailedAsins,
    getTitleSetMapFromProxyResponse,
    getCatalogForFailedTitleSetAsins,
    getCatalogFromProxyResponse,
    getUnhydratedAlbums,
    getUnhydratedArtists,
    getTitlesetAsinToGlobalAsinMap,
    hydrateAsinsBatch,
};
export const hydrationParsers = {
    artistParser,
    albumParser,
    stationParser,
    playlistParser,
    trackParser,
};
