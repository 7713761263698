import { createAction } from "redux-ts";
import { artistImagePayload } from "../../models";

export const artistUpload = createAction<artistImagePayload>(
    "UPLOAD::ARTIST_IMAGE"
);
export const artistImageUploadInProgress = createAction<boolean>(
    "UPLOAD::ARTIST_IMAGE_INPROGRESS"
);
export const artistImageUploadComplete = createAction<boolean>(
    "UPLOAD::ARTIST_IMAGE_COMPLETE"
);
