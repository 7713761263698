import { getBundleMap } from "./commonSelectors";
import { createSelector } from "reselect";
import { selectedTeamIdSelector } from "./userSelectors";
import { RootState } from "../reducers";
import { albumRelease, BundleMap, ErrorPayload } from "../../models";
import { getUserPermissionsSelector } from "./teamManagementSelectors";
import _ from "lodash";

const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;

const getCatalog = (state: RootState) => state.catalog.catalog;
const getCatalogBuildCompleted = (state: RootState) =>
    state.catalog.catalogBuildCompleted;
const getHydrationInProgress = (state: RootState) =>
    state.catalog.hydrationInProgress;
const getHydratingAsins = (state: RootState) => state.catalog.hydratingAsins;
const getFailedAsins = (state: RootState) => state.catalog.failedAsins;

const getAlbumsToPitch = (state: RootState) => state.pitch.albumsToPitch;

const getListAlbumsToPitchInProgress = (state: RootState) =>
    state.pitch.listAlbumsToPitchInProgress;

const getUserLocale = (state: RootState) => state.user.locale;

const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);
const getIsPrivileged = (state: RootState) => state.user.isPrivileged;

const catalogSelector = createSelector(
    [
        getCatalog,
        getCatalogBuildCompleted,
        getHydrationInProgress,
        getHydratingAsins,
        getFailedAsins,
    ],
    (
        catalog,
        catalogBuildCompleted,
        hydrationInProgress,
        hydratingAsins,
        failedAsins
    ) => {
        return {
            catalog,
            catalogBuildCompleted,
            hydrationInProgress,
            hydratingAsins,
            failedAsins,
        };
    }
);

export const newReleasesSelector = createSelector(
    [
        selectedTeamIdSelector,
        getSelectedArtistAsin,
        getAlbumsToPitch,
        getListAlbumsToPitchInProgress,
        getUserLocale,
        getErrorFromStore,
        getUserPermissionsSelector,
        catalogSelector,
        getIsPrivileged,
        getBundleMap,
    ],
    (
        teamId: string | undefined,
        selectedArtistAsin: string | undefined,
        albumsToPitch: albumRelease[] | undefined,
        inProgress: boolean,
        userLocale: string,
        error: ErrorPayload | undefined,
        userPermissions: any,
        catalogSelector: any,
        isPrivileged: boolean | undefined,
        bundleMap: BundleMap
    ) => {
        return {
            teamId,
            selectedArtistAsin,
            albumsToPitch,
            inProgress,
            userLocale,
            error,
            userPermissions,
            ...catalogSelector,
            isPrivileged,
            bundleMap: bundleMap,
        };
    }
);
