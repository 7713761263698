import React from "react";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { BundleMap, clientMetricsPayload, EntityType } from "../../../models";
import { MODBanner } from "..";
import { stringIds, bundleIds } from "../../../assets";
import { getLocalizedString, buildUIClickPayload } from "../../../utils";
import { clientMetricsActions } from "../../../store/actions";
import { merchOverviewSelector } from "../../../store/selectors";
import { RootState } from "../../../store/reducers";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

type StateProps = {
    hasMerchIngressPermissions: boolean;
    selectedArtistAsin?: string;
    bundleMap: BundleMap;
};

type DispatchProps = {
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type ViewProps = {};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

export class ModOnlyDisplay extends React.Component<Props> {
    componentDidMount() {}

    private emitButtonClickMetrics = (key: string, pageId: string) => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                key,
                pageId,
                this.props.selectedArtistAsin,
                EntityType.ARTIST
            )
        );
    };

    private launchSellFromOtherSite = () => {
        this.emitButtonClickMetrics(
            buttonIds.Merch.wantToSellOnOtherSite,
            pageIds.artistMerchMoDBanner
        );
        window.open(
            "https://artists.amazonmusic.com/merch#listarticle-2",
            "_blank"
        );
    };

    render() {
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <MODBanner
                    hasMerchIngressPermissions={
                        this.props.hasMerchIngressPermissions
                    }
                />
                <div
                    style={{
                        ...rootStyles.textStyles.quaternary,
                        display: "flex",
                        justifyContent: "center",
                        marginTop: rootStyles.spacers.huge,
                        borderWidth: 2,
                        borderColor: rootStyles.colors.aux2,
                        marginLeft: rootStyles.spacers.small,
                        marginRight: rootStyles.spacers.small,
                    }}
                >
                    <span>
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.MERCH_STRINGS,
                            stringId:
                                stringIds.Merch.merch_mod_banner_footertext,
                        })}
                        <a
                            style={clickableTextStyle}
                            onClick={this.launchSellFromOtherSite}
                        >
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch.merch_mod_banner_footerLink,
                            })}
                        </a>
                    </span>
                </div>
            </Container>
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return {
        ...merchOverviewSelector(state),
        ...bundleMapSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ModOnlyDisplay)
);

const clickableTextStyle: React.CSSProperties = {
    color: rootStyles.colors.accent,
    cursor: "pointer",
    marginLeft: 2,
};
