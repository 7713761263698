import _ from "lodash";
import { Channel } from "redux-saga";
import {
    actionChannel,
    ActionPattern,
    put,
    select,
    takeLeading,
} from "redux-saga/effects";
import { Action } from "redux-ts";
import { SingleOpsMetricInfo } from "../../../models";
import { OPS_METRIC_BATCH_LIMIT } from "../../../utils";
import { opsMetricsActions } from "../../actions";
import { getMetricsQueue } from "../../selectors";

export const batchMetricSaga = [watchBatchMetric()];

function* batchMetric(action: Action<SingleOpsMetricInfo>) {
    // add ops metric to metric queue
    yield put(opsMetricsActions.addOpsMetric(action.payload));
    const metricsQueue: SingleOpsMetricInfo[] = yield select(getMetricsQueue);

    if (metricsQueue.length >= OPS_METRIC_BATCH_LIMIT) {
        // emit the first 25 metrics in the queue if there are more than 25
        const sizedMetricsQueue = _.slice(
            metricsQueue,
            0,
            OPS_METRIC_BATCH_LIMIT
        );
        yield put(opsMetricsActions.emitOpsMetrics(sizedMetricsQueue));
        // clear the queue of only the metrics that were sent to be emitted
        yield put(
            opsMetricsActions.clearOpsMetricQueue(sizedMetricsQueue.length)
        );
    }
}

function* watchBatchMetric() {
    /**
     * Using an action channel allows the metric requests to be done serially,
     * allowing accurate tracking of the size of the queue.
     * https://redux-saga.js.org/docs/advanced/Channels#using-the-actionchannel-effect
     */
    const addOpsMetricsQueue: ActionPattern = yield actionChannel(
        opsMetricsActions.batchMetric.type
    );
    /**
     * takeLeading call takes the first action from the action channel queue
     * and calls the saga, then blocks on calling any more sagas until the
     * initial one completes.
     * https://redux-saga.js.org/docs/api#takeleadingpattern-saga-args
     */
    yield takeLeading(addOpsMetricsQueue, batchMetric);
}
