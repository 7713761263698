import { LocalizedUrl } from "@amzn/ziggy-asset";
import { locale, webLocale } from "../localeType";

export const getLocalizedUrl = (
    url: LocalizedUrl,
    selectedLocale: string = webLocale[0]
): string => {
    switch (selectedLocale) {
        case locale.deDE:
            return url.deDE;
        case locale.enUS:
            return url.enUS;
        case locale.esES:
            return url.esES;
        case locale.frFR:
            return url.frFR;
        case locale.itIT:
            return url.itIT;
        case locale.jaJP:
            return url.jaJP;
        case locale.ptBR:
            return url.ptBR;
        default:
            return url.enUS;
    }
};
