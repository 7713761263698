import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { ImageList, stringIds, bundleIds } from "../../../../assets";
import { RootState } from "../../../../store";
import {
    getBundleMap,
    selectedArtistFromCatalogSelector,
} from "../../../../store/selectors/commonSelectors";
import {
    getLocalizedString,
    isHideNavBarPath,
    isHideHeaderPath,
    paths,
} from "../../../../utils";
import * as rootStyles from "../../../styles";
import ArtistImgDropdown from "../ArtistImgDropdown";
import { MediumGlassButton } from "../input";
import { signOut } from "../../../../service/common";
import { BundleMap } from "../../../../models";

const testIDPrefix = "Header";

export const Header: React.FC<{}> = () => {
    const selectedArtist = useSelector((state: RootState) =>
        selectedArtistFromCatalogSelector(state)
    );
    const currentPath = useSelector(
        (state: RootState) => state.user.currentPath
    );
    const signedIn = useSelector((state: RootState) => state.user.signedIn);
    const bundleMap: BundleMap = useSelector(getBundleMap);

    const showNavBar = isHideNavBarPath(currentPath);
    const hideNavBar = showNavBar ? true : false;
    const hideHeader = isHideHeaderPath(currentPath);
    const showHeader = !hideHeader;

    const showSignOut =
        hideNavBar && !selectedArtist?.images?.artSmall && signedIn
            ? true
            : false;

    return (
        <>
            {(showSignOut || hideNavBar) && showHeader && (
                <Row style={!hideNavBar ? headerStyle : headerStyleWithBorder}>
                    {hideNavBar && (
                        <Col
                            style={{
                                textAlign: "left",
                                paddingRight: 0,
                                margin: "auto 0px auto 0px",
                                paddingLeft: 0,
                            }}
                        >
                            <img
                                src={ImageList.logo_sidebar}
                                onClick={() =>
                                    window.location.replace(paths.home)
                                }
                                height={26}
                                width={183}
                            />
                        </Col>
                    )}
                    {hideNavBar && !showSignOut && <ArtistImgDropdown />}
                    {showSignOut && (
                        <Col
                            style={{
                                textAlign: "right",
                                marginTop: rootStyles.spacers.small,
                                maxWidth: 180,
                            }}
                        >
                            <div>
                                <MediumGlassButton
                                    title={getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.SETTINGS_STRINGS,
                                        stringId: stringIds.Settings.signOut,
                                    })}
                                    containerStyle={{ width: "100%" }}
                                    onClick={() => signOut()}
                                    id={`${testIDPrefix}_SignOut`}
                                />
                            </div>
                        </Col>
                    )}
                </Row>
            )}
        </>
    );
};

const headerStyle: React.CSSProperties = {
    textAlign: "center",
    height: 70,
    width: "100%",
    maxWidth: "1200px",
    margin: "auto",
};

const headerStyleWithBorder: React.CSSProperties = {
    ...headerStyle,
    borderBottomWidth: 1,
    borderBottomColor: rootStyles.glassColors.primary1,
    borderBottomStyle: "solid",
};
