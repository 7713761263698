import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import _ from "lodash";
import { testIDSuffixes, getLocalizedString } from "../../../utils";
import styled from "styled-components";
import * as rootStyles from "../../styles";
import { LargeSolidButton } from "..";
import { styledTitle } from "..";
import { getThirdPartyEndpoints } from "../../../service/common";
import { stringIds, bundleIds } from "../../../assets";
import { commonSelectors } from "../../../store/selectors";
import { BundleMap } from "../../../models";

const testIDPrefix = "MoDLandingFooter";

type MODLandingFooterProps = {
    emitMetrics: (key: string, pageId: string) => void;
};

export const MoDLandingFooter: React.FC<MODLandingFooterProps> = ({
    emitMetrics,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const launchMoD = () => {
        emitMetrics(
            buttonIds.Merch.launchMoDFooterButton,
            pageIds.artistMerchMoDLandingScreen
        );
        const url = getThirdPartyEndpoints().merchOnDemandUrl();
        //_blank options allows to open in new tab
        window.open(url, "_blank");
    };

    return (
        <SectionContainer id={`${testIDPrefix}${testIDSuffixes.item}`}>
            <Row>
                <Col>
                    <StyledBannerTitleContainer>
                        <styledTitle.h4
                            style={{
                                textTransform: "none",
                            }}
                        >
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_fotter_title,
                            })}
                        </styledTitle.h4>
                    </StyledBannerTitleContainer>

                    <Container>
                        <LargeSolidButton
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch.merch_mod_landing_buttonTex,
                            })}
                            id={`${testIDPrefix}_ContinueButton`}
                            onClick={launchMoD}
                        />
                    </Container>
                </Col>
            </Row>
        </SectionContainer>
    );
};

//styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101
const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 878px;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        flex-direction: column;
        width: 568px;
    }
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.small}px) {
        flex-direction: column;
        width: 335px;
    }
    padding-top: ${rootStyles.spacers.small}px;
    border-bottom: 2px solid;
    border-bottom-color: ${rootStyles.glassColors.primary1};
`;
const StyledBannerTitleContainer = styled.div`
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: ${rootStyles.spacers.medium}px;
    margin-bottom: ${rootStyles.spacers.medium}px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-item: center;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        flex-direction: column;
    }
    alignself: center;
    padding-bottom: ${rootStyles.spacers.epic}px;
`;
