import { getWebLocale } from "./localeHelper";
import { de, enUS, es, fr, it, ja, pt } from "date-fns/locale";

/**
 * A pollyfill for Intl.NumberFormat to make it working for Safari and Firefox
 * When adding a new language support, need to import the new locale-data from the library
 * https://formatjs.io/docs/polyfills/intl-numberformat
 */
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/de";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-numberformat/locale-data/es";
import "@formatjs/intl-numberformat/locale-data/fr";
import "@formatjs/intl-numberformat/locale-data/it";
import "@formatjs/intl-numberformat/locale-data/ja";
import "@formatjs/intl-numberformat/locale-data/pt";

export const locale = {
    deDE: "de_DE",
    enUS: "en_US",
    esES: "es_ES",
    frFR: "fr_FR",
    itIT: "it_IT",
    jaJP: "ja_JP",
    ptBR: "pt_BR",
};

export const localeMap = new Map<string, string>([
    ["de", locale.deDE],
    ["en", locale.enUS],
    ["es", locale.esES],
    ["fr", locale.frFR],
    ["it", locale.itIT],
    ["ja", locale.jaJP],
    ["pt", locale.ptBR],
]);

export const defaultLocale = locale.enUS;

export let webLocale = [getWebLocale()];

export enum UserLanguage {
    DE_DE = "Deutsch",
    EN_US = "English",
    ES_ES = "Español",
    FR_FR = "Français",
    IT_IT = "Italiano",
    JA_JP = "日本語",
    PT_BR = "Português",
}

export const userLanguages = Object.values(UserLanguage);

export const languageMap = new Map<string, string>([
    [locale.deDE, "Deutsch"],
    [locale.enUS, "English"],
    [locale.esES, "Español"],
    [locale.frFR, "Français"],
    [locale.itIT, "Italiano"],
    [locale.jaJP, "日本語"],
    [locale.ptBR, "Português"],
]);

export const userLanguagesDeDE = [
    "Deutsch",
    "Englisch",
    "Spanisch",
    "Französisch",
    "Italienisch",
    "Japanisch",
    "Portugiesisch",
];
export const userLanguagesEnUS = [
    "German",
    "English",
    "Spanish",
    "French",
    "Italian",
    "Japanese",
    "Portuguese",
];
export const userLanguagesEsES = [
    "Alemán",
    "Inglés",
    "Español",
    "Francés",
    "Italiano",
    "Japonés",
    "Portugués",
];
export const userLanguagesFrFR = [
    "Allemand",
    "Anglais",
    "Espagnol",
    "Français",
    "Italien",
    "Japonais",
    "Portugais",
];
export const userLanguagesItIT = [
    "Tedesco",
    "Inglese",
    "Spagnolo",
    "Francese",
    "Italiano",
    "Giapponese",
    "Portoghese",
];
export const userLanguagesJaJP = [
    "ドイツ語",
    "英語",
    "スペイン語",
    "フランス語",
    "イタリア語",
    "日本語",
    "ポルトガル語",
];
export const userLanguagesPtBR = [
    "Alemão",
    "Inglês",
    "Espanhol",
    "Francês",
    "Italiano",
    "Japonês",
    "Portuguesa",
];

export const localizedUserLanguagesList = [
    userLanguagesDeDE,
    userLanguagesEnUS,
    userLanguagesEsES,
    userLanguagesFrFR,
    userLanguagesItIT,
    userLanguagesJaJP,
    userLanguagesPtBR,
];

// Complete list of suppored locales for react-date-range. It is directly exported from date-fns/locales
// https://github.com/Adphorus/react-date-range/blob/next/src/locale/index.js
// To import locale from date-fns, we need to manually import each locale when needed.
// https://date-fns.org/docs/I18n
export const dateRangeLocaleMap = new Map<string, object>([
    [locale.deDE, de],
    [locale.enUS, enUS],
    [locale.esES, es],
    [locale.frFR, fr],
    [locale.itIT, it],
    [locale.jaJP, ja],
    [locale.ptBR, pt],
]);
