import axios from "axios";
import { getEndpoints, testHeaders } from "./common";
import { NewDisambiguationRequest } from "../models";

const artistControlUrl = getEndpoints().artistControl;
const newDisambiguationUrl = `${artistControlUrl}/artist/disambiguate/new`;

export async function newDisambiguation(
    payload: NewDisambiguationRequest,
    teamId?: string
) {
    const result = await axios.post(newDisambiguationUrl, payload, {
        headers: {
            "x-team-id": teamId,
            ...testHeaders(),
        },
    });

    return result.data;
}
