import { spacers, webFontSizes } from "./styles";
import { colors, glassColors } from "./colors";

export const buttonSharedStyles = {
    small: {
        borderRadius: 28,
        paddingLeft: spacers.base,
        paddingRight: spacers.base,
        paddingTop: 6,
        paddingBottom: 6,
        justifyContent: "center",
        alignItems: "center",
        flex: 0,
    },
    medium: {
        borderRadius: 32,
        paddingLeft: spacers.base,
        paddingRight: spacers.base,
        paddingTop: 10,
        paddingBottom: 10,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        flex: 0,
    },
    large: {
        borderRadius: 45,
        paddingLeft: spacers.large,
        paddingRight: spacers.large,
        paddingTop: 14,
        paddingBottom: 14,
        justifyContent: "center",
        alignItems: "center",
        flex: 0,
    },
};

export const buttonTitleSharedStyles = {
    small: {
        fontSize: webFontSizes.t3,
        textTransform: "capitalize" as const,
    },
    medium: {
        fontSize: webFontSizes.t2,
        textTransform: "capitalize" as const,
    },
    large: {
        fontSize: webFontSizes.t1,
        textTransform: "capitalize" as const,
    },
};

// These styles will be injected with the sizes based on type of button
export const buttonStyles = {
    // Title Styles
    glassTitle: {
        color: colors.primary,
    },
    solidTitle: {
        color: colors.secondary,
    },
    outLineTitle: {
        color: colors.accent,
    },

    // Icon Styles
    medium: {
        marginRight: spacers.mini,
    },
    large: {
        marginLeft: spacers.base,
    },

    // Button Styles
    solidButton: {
        backgroundColor: colors.accent,
    },
    glassButton: {
        backgroundColor: glassColors.primary2,
    },
    outlineButton: {
        backgroundColor: "transparent",
        borderWidth: 2,
        borderColor: colors.accent,
    },
};

// It'd take a decent bit of work, but standardizing the CSS Properties into a few locations would help a lot
export const dropDownStyles = {
    dropdown: {
        border: 0,
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    dropdownBody: {
        overflow: "hidden",
        borderRadius: 24,
    },
    dropdownButton: {
        ...buttonSharedStyles.medium,
        ...buttonStyles.glassButton,
        flex: 1,
        alignSelf: "stretch",
        outline: 0,
        border: 0,
    },
    dropdownItem: {
        fontFamily: "AmazonEmber",
    },
    dropdownMenu: {
        border: 0,
        justifyContent: "flex-end",
        alignItems: "flex-end",
        width: 300,
    },
};
