import * as React from "react";
import styled, { StyledComponent } from "styled-components";
import * as rootStyles from "../../../styles";
import { Icon } from "../icons/Icon";

export type ButtonProps = {
    title?: string;
    id: string;
    onClick?: () => void;
    disabled?: boolean;
    containerStyle?: React.CSSProperties;
    buttonStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    rightIcon?: string;
    rightIconStyle?: React.CSSProperties;
    leftIcon?: string;
    leftIconStyle?: React.CSSProperties;
    className?: string;
    renderRightSideSpacer?: boolean;
    renderLeftSideSpacer?: boolean;
};

type BaseButtonProps = ButtonProps & {
    buttonType: StyledComponent<"button", any>;
    titleType: StyledComponent<"span", any>;
    defaultContainerStyle: React.CSSProperties;
};

const BaseButton = (props: BaseButtonProps) => {
    return (
        <div
            style={{
                ...props.defaultContainerStyle,
                ...props.containerStyle,
            }}
        >
            <props.buttonType
                {...props}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    // Removes the focus state after being clicked
                    e.currentTarget.blur();
                    if (props.onClick) {
                        props.onClick();
                    }
                }}
                style={props.buttonStyle}
            >
                {!!props.leftIcon ? (
                    <StyledIcons.LeftIcon style={props.leftIconStyle}>
                        <Icon
                            icon={props.leftIcon}
                            size={rootStyles.icons.tiny}
                            color={rootStyles.colors.primary}
                            id={`${props.id}_LeftIcon`}
                        />
                    </StyledIcons.LeftIcon>
                ) : !!props.renderLeftSideSpacer ? (
                    <StyledIcons.LeftIcon style={props.leftIconStyle}>
                        <div style={iconSpacerStyle} />
                    </StyledIcons.LeftIcon>
                ) : null}
                {!!props.title && (
                    <props.titleType style={props.titleStyle}>
                        {props.title}
                    </props.titleType>
                )}
                {!!props.rightIcon ? (
                    <StyledIcons.RightIcon style={props.rightIconStyle}>
                        <Icon
                            icon={props.rightIcon}
                            size={rootStyles.icons.tiny}
                            color={rootStyles.colors.primary}
                            id={`${props.id}_RightIcon`}
                        />
                    </StyledIcons.RightIcon>
                ) : !!props.renderRightSideSpacer ? (
                    <StyledIcons.RightIcon style={props.rightIconStyle}>
                        <div style={iconSpacerStyle} />
                    </StyledIcons.RightIcon>
                ) : null}
            </props.buttonType>
        </div>
    );
};

export const SmallGlassButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.SmallGlass}
            titleType={StyledButtonTitles.SmallGlass}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Small,
                backgroundColor: rootStyles.colors.background,
            }}
        />
    );
};

export const MediumGlassButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.MediumGlass}
            titleType={StyledButtonTitles.MediumGlass}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Medium,
                backgroundColor: rootStyles.colors.background,
            }}
        />
    );
};

export const LargeGlassButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.LargeGlass}
            titleType={StyledButtonTitles.LargeGlass}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Large,
                backgroundColor: rootStyles.colors.background,
            }}
        />
    );
};

export const SmallSolidButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.SmallSolid}
            titleType={StyledButtonTitles.SmallSolid}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Small,
                backgroundColor: rootStyles.colors.accent,
            }}
        />
    );
};

export const MediumSolidButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.MediumSolid}
            titleType={StyledButtonTitles.MediumSolid}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Medium,
                backgroundColor: rootStyles.colors.accent,
            }}
        />
    );
};

export const LargeSolidButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.LargeSolid}
            titleType={StyledButtonTitles.LargeSolid}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Large,
                backgroundColor: rootStyles.colors.accent,
            }}
        />
    );
};

export const SmallOutlineButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.SmallOutline}
            titleType={StyledButtonTitles.SmallOutline}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Small,
            }}
        />
    );
};

export const MediumOutlineButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.MediumOutline}
            titleType={StyledButtonTitles.MediumOutline}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Medium,
            }}
        />
    );
};

export const LargeOutlineButton = (props: ButtonProps) => {
    return (
        <BaseButton
            {...props}
            buttonType={StyledButtons.LargeOutline}
            titleType={StyledButtonTitles.LargeOutline}
            defaultContainerStyle={{
                ...ButtonContainerStyles.Large,
            }}
        />
    );
};

const iconSpacerStyle: React.CSSProperties = {
    width: rootStyles.icons.tiny,
    height: rootStyles.icons.tiny,
};

// This function wraps its children with a button component
export const ButtonWrapper = (props: {
    onClick?: () => void;
    id: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
}) => {
    return (
        <button
            onClick={props.onClick}
            type={"button"}
            className="btn"
            style={props.style}
            {...props}
        >
            {props.children}
        </button>
    );
};

export const StyledCardButton = styled(
    (props: {
        onClick?: () => void;
        id: string;
        children?: React.ReactNode;
    }) => <ButtonWrapper {...props} />
)`
    border: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    border-radius: ${rootStyles.borderRadius.card}px;
    background-color: ${rootStyles.glassColors.primary2};

    &:hover,
    :focus {
        background-color: ${rootStyles.glassColors.primary3};
    }
    &:active {
        background-color: ${rootStyles.glassColors.primary1};
    }
`;

const StyledIcons = {
    RightIcon: styled.div`
        margin-left: ${rootStyles.spacers.mini}px;
        display: flex;
    `,
    LeftIcon: styled.div`
        margin-right: ${rootStyles.spacers.mini}px;
        display: flex;
    `,
};

const StyledBaseButtonTitles = {
    Small: styled.span`
        font-family: AmazonEmber-Bold;
        font-size: ${rootStyles.webFontSizes.t4}px;
        line-height: 20px;
        font-weight: bold;
        text-transform: uppercase;
        justify-content: center;
        margin: 0;
        padding: 0;
        vertical-align: text-top;
        white-space: nowrap;
        display: flex;
        flex: 1;
    `,
    Medium: styled.span`
        font-family: AmazonEmber;
        font-size: ${rootStyles.webFontSizes.t3}px;
        line-height: 20px;
        text-transform: capitalize;
        justify-content: center;
        margin: 0;
        padding: 0;
        vertical-align: text-top;
        white-space: nowrap;
        display: flex;
        flex: 1;
    `,
    Large: styled.span`
        font-family: AmazonEmber;
        font-size: ${rootStyles.webFontSizes.t1}px;
        line-height: 20px;
        text-transform: capitalize;
        justify-content: center;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        white-space: nowrap;
        display: flex;
        flex: 1;
    `,
};

const StyledButtonTitles = {
    SmallGlass: styled(StyledBaseButtonTitles.Small)`
        color: ${rootStyles.colors.primary};
    `,
    MediumGlass: styled(StyledBaseButtonTitles.Medium)`
        color: ${rootStyles.colors.primary};
    `,
    LargeGlass: styled(StyledBaseButtonTitles.Large)`
        color: ${rootStyles.colors.primary};
    `,
    SmallSolid: styled(StyledBaseButtonTitles.Small)`
        color: ${rootStyles.colors.secondary};
    `,
    MediumSolid: styled(StyledBaseButtonTitles.Medium)`
        color: ${rootStyles.colors.secondary};
    `,
    LargeSolid: styled(StyledBaseButtonTitles.Large)`
        color: ${rootStyles.colors.secondary};
    `,
    SmallOutline: styled(StyledBaseButtonTitles.Small)``,
    MediumOutline: styled(StyledBaseButtonTitles.Medium)``,
    LargeOutline: styled(StyledBaseButtonTitles.Large)``,
};

export const ButtonContainerStyles = {
    Small: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 28,
        border: 0,
        outline: "none",
    },
    Medium: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 40,
        border: 0,
        outline: "none",
    },
    Large: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 48,
        border: 0,
        outline: "none",
    },
};

export const StyledSizedButtons = {
    Small: styled.button`
        font-family: AmazonEmber-Bold;
        text-transform: uppercase;
        padding: 0;
        padding-left: ${rootStyles.spacers.base}px;
        padding-right: ${rootStyles.spacers.base}px;
        border: 0;
        border-radius: 28px;
        justify-content: center;
        align-items: center;
        padding-top: ${rootStyles.spacers.micro}px;
        padding-bottom: ${rootStyles.spacers.micro}px;
        outline: none;
        flex: 1;
        display: inline-flex;
        box-shadow: none;
    `,
    Medium: styled.button`
        font-family: AmazonEmber;
        text-transform: capitalize;
        padding: 0;
        padding-left: ${rootStyles.spacers.base}px;
        padding-right: ${rootStyles.spacers.base}px;
        justify-content: center;
        align-items: center;
        padding-top: ${rootStyles.spacers.small - rootStyles.spacers.nano}px;
        padding-bottom: ${rootStyles.spacers.small - rootStyles.spacers.nano}px;
        border: 0;
        border-radius: 40px;
        outline: none;
        flex: 1;
        display: inline-flex;
        box-shadow: none;
    `,
    Large: styled.button`
        font-family: AmazonEmber;
        text-transform: capitalize;
        border: 0;
        border-radius: 48px;
        padding: 0;
        padding-left: ${rootStyles.spacers.large}px;
        padding-right: ${rootStyles.spacers.large}px;
        justify-content: center;
        align-items: center;
        padding-top: ${rootStyles.spacers.base - rootStyles.spacers.nano}px;
        padding-bottom: ${rootStyles.spacers.base - rootStyles.spacers.nano}px;
        outline: none;
        flex: 1;
        display: inline-flex;
        align-items: center;
        box-shadow: none;
    `,
};

export const StyledButtons = {
    SmallGlass: styled(StyledSizedButtons.Small)`
        background-color: ${(props) =>
            props.disabled
                ? `${rootStyles.glassColors.primary1} !important`
                : rootStyles.glassColors.primary2};

        &:hover {
            background: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.primary1
                    : rootStyles.glassColors.primary3};
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.primary1
                        : `${rootStyles.glassColors.primary4} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.primary1
                        : `${rootStyles.glassColors.primary3} !important`};
                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${rootStyles.colors.secondary} !important;
                    outline: ${rootStyles.spacers.nano}px solid ${rootStyles.colors.primary} !important;
                    padding-left: ${rootStyles.spacers.small}px;
                    padding-right: ${rootStyles.spacers.small}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    `
                        : ``}
            }
        }
        &:disabled {
            opacity: 0.7 !important;
        }
    `,
    MediumGlass: styled(StyledSizedButtons.Medium)`
        background-color: ${(props) =>
            props.disabled
                ? `${rootStyles.glassColors.primary1} !important`
                : rootStyles.glassColors.primary2};

        &:hover {
            border: 0;
            background: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.primary1
                    : rootStyles.glassColors.primary3};
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.primary1
                        : `${rootStyles.glassColors.primary4} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.primary1
                        : `${rootStyles.glassColors.primary3} !important`};
                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.secondary
                          } !important;
                    outline: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.primary
                          } !important;
                    padding-left: ${rootStyles.spacers.small}px;
                    padding-right: ${rootStyles.spacers.small}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: ${
                        rootStyles.spacers.mini - rootStyles.spacers.nano
                    }px;
                    padding-bottom: ${
                        rootStyles.spacers.mini - rootStyles.spacers.nano
                    }px;
                    `
                        : ``}
            }
        }
        &:disabled {
            opacity: 0.7 !important;
        }
    `,
    LargeGlass: styled(StyledSizedButtons.Large)`
        background-color: ${(props) =>
            props.disabled
                ? `${rootStyles.glassColors.primary1} !important`
                : rootStyles.glassColors.primary2};

        color: ${(props) =>
            props.disabled
                ? rootStyles.glassColors.primary3
                : rootStyles.colors.primary} !important;

        &:hover {
            border: 0;
            background: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.primary1
                    : rootStyles.glassColors.primary3};
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.primary1
                        : `${rootStyles.glassColors.primary4} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.primary1
                        : `${rootStyles.glassColors.primary3} !important`};

                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.secondary
                          } !important;
                    outline: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.primary
                          } !important;
                    padding-left: ${rootStyles.spacers.medium}px;
                    padding-right: ${rootStyles.spacers.medium}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: ${
                        rootStyles.spacers.small - rootStyles.spacers.nano
                    }px;
                    padding-bottom: ${
                        rootStyles.spacers.small - rootStyles.spacers.nano
                    }px;
                    `
                        : ``}
            }
        }
        &:disabled {
            opacity: 0.7 !important;
        }
    `,
    SmallOutline: styled(StyledSizedButtons.Small)`
        border: ${rootStyles.spacers.nano}px solid;
        padding-top: ${rootStyles.spacers.nano}px;
        padding-bottom: ${rootStyles.spacers.nano}px;
        padding-left: ${rootStyles.spacers.base - 2}px;
        padding-right: ${rootStyles.spacers.base - 2}px;
        background-color: ${(props) =>
            props.disabled ? `transparent !important` : `transparent`};
        border-color: ${(props) =>
            props.style?.borderColor
                ? props.style.borderColor
                : props.disabled
                ? rootStyles.glassColors.accent4
                : rootStyles.colors.accent} !important;
        color: ${(props) =>
            props.disabled
                ? rootStyles.glassColors.accent4
                : rootStyles.colors.accent} !important;

        &:hover {
            border: ${(props) =>
                props.disabled
                    ? `${rootStyles.spacers.nano}px solid ${rootStyles.glassColors.accent4}`
                    : `${rootStyles.spacers.nano}px solid ${rootStyles.colors.accent}`} !important;
            background: ${(props) =>
                props.disabled
                    ? rootStyles.colors.background
                    : rootStyles.colors.accent};
            color: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.accent4
                    : rootStyles.colors.secondary} !important;
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.colors.background
                        : `${rootStyles.glassColors.accent3} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.colors.background
                        : `${rootStyles.combinationColors.primary4onAccent} !important`};

                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${rootStyles.colors.secondary} !important;
                    outline: ${rootStyles.spacers.nano}px solid ${rootStyles.colors.accent} !important;
                    color: ${rootStyles.colors.secondary} !important;
                    padding-left: ${rootStyles.spacers.small}px;
                    padding-right: ${rootStyles.spacers.small}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    `
                        : ``}
            }
        }
        &:disabled {
            opacity: 1 !important;
        }
    `,
    MediumOutline: styled(StyledSizedButtons.Medium)`
        border: ${rootStyles.spacers.nano}px solid;
        padding-top: ${rootStyles.spacers.mini}px;
        padding-bottom: ${rootStyles.spacers.mini}px;
        padding-left: ${rootStyles.spacers.base - rootStyles.spacers.nano}px;
        padding-right: ${rootStyles.spacers.base - rootStyles.spacers.nano}px;
        background-color: ${(props) =>
            props.disabled ? `transparent !important` : `transparent`};
        border-color: ${(props) =>
            props.style?.borderColor
                ? props.style.borderColor
                : props.disabled
                ? rootStyles.glassColors.accent4
                : rootStyles.colors.accent} !important;
        color: ${(props) =>
            props.disabled
                ? rootStyles.glassColors.accent4
                : rootStyles.colors.accent} !important;

        &:hover {
            border: ${(props) =>
                props.disabled
                    ? `${rootStyles.spacers.nano}px solid ${rootStyles.glassColors.accent4}`
                    : `${rootStyles.spacers.nano}px solid ${rootStyles.colors.accent}`} !important;
            background: ${(props) =>
                props.disabled
                    ? rootStyles.colors.background
                    : rootStyles.colors.accent};
            color: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.accent4
                    : rootStyles.colors.secondary} !important;
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.colors.background
                        : `${rootStyles.glassColors.accent3} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.colors.background
                        : `${rootStyles.combinationColors.primary4onAccent} !important`};

                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.secondary
                          } !important;
                    outline: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.accent
                          } !important;
                    color: ${rootStyles.colors.secondary} !important;
                    padding-left: ${rootStyles.spacers.small}px;
                    padding-right: ${rootStyles.spacers.small}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: ${
                        rootStyles.spacers.mini - rootStyles.spacers.nano
                    }px;
                    padding-bottom: ${
                        rootStyles.spacers.mini - rootStyles.spacers.nano
                    }px;
                    `
                        : ``}
            }
        }
        &:disabled {
            opacity: 1 !important;
        }
    `,
    LargeOutline: styled(StyledSizedButtons.Large)`
        border: ${rootStyles.spacers.nano}px solid;
        padding-top: ${rootStyles.spacers.small}px;
        padding-bottom: ${rootStyles.spacers.small}px;
        padding-left: ${rootStyles.spacers.large - rootStyles.spacers.nano}px;
        padding-right: ${rootStyles.spacers.large - rootStyles.spacers.nano}px;
        background-color: ${(props) =>
            props.disabled ? `transparent !important` : `transparent`};
        border-color: ${(props) =>
            props.style?.borderColor
                ? props.style.borderColor
                : props.disabled
                ? rootStyles.glassColors.accent4
                : rootStyles.colors.accent} !important;
        color: ${(props) =>
            props.disabled
                ? rootStyles.glassColors.accent4
                : rootStyles.colors.accent} !important;

        &:hover {
            border: ${(props) =>
                props.disabled
                    ? `${rootStyles.spacers.nano}px solid ${rootStyles.glassColors.accent4}`
                    : `${rootStyles.spacers.nano}px solid ${rootStyles.colors.accent}`} !important;
            background: ${(props) =>
                props.disabled
                    ? rootStyles.colors.background
                    : rootStyles.colors.accent};
            color: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.accent4
                    : rootStyles.colors.secondary} !important;
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.colors.background
                        : `${rootStyles.glassColors.accent3} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.colors.background
                        : `${rootStyles.combinationColors.primary4onAccent} !important`};

                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.secondary
                          } !important;
                    outline: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.accent
                          } !important;
                    color: ${rootStyles.colors.secondary} !important;
                    padding-left: ${rootStyles.spacers.medium}px;
                    padding-right: ${rootStyles.spacers.medium}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: ${
                        rootStyles.spacers.small - rootStyles.spacers.nano
                    }px;
                    padding-bottom: ${
                        rootStyles.spacers.small - rootStyles.spacers.nano
                    }px;
                    `
                        : ``}
            }
        }
        &:disabled {
            opacity: 1 !important;
        }
    `,
    SmallSolid: styled(StyledSizedButtons.Small)`
        background-color: ${(props) =>
            props.disabled
                ? `${rootStyles.glassColors.secondary4} !important`
                : rootStyles.colors.accent};

        &:hover {
            background: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.secondary4
                    : rootStyles.glassColors.primary4};
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.secondary4
                        : `${rootStyles.glassColors.secondary3} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.secondary4
                        : `${rootStyles.glassColors.primary4} !important`};
                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${rootStyles.colors.secondary} !important;
                    padding-left: ${rootStyles.spacers.small}px;
                    padding-right: ${rootStyles.spacers.small}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: 0px;
                    padding-bottom: 0px;
                    `
                        : ``}
            }
        }

        &:disabled {
            opacity: 1 !important;
        }
    `,
    MediumSolid: styled(StyledSizedButtons.Medium)`
        background-color: ${(props) =>
            props.disabled
                ? `${rootStyles.glassColors.secondary4} !important`
                : rootStyles.colors.accent};

        &:hover {
            border: 0;
            background: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.secondary4
                    : rootStyles.glassColors.primary4};
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.secondary4
                        : `${rootStyles.glassColors.secondary3} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.secondary4
                        : `${rootStyles.glassColors.primary4} !important`};
                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.secondary
                          } !important;
                    padding-left: ${rootStyles.spacers.small}px;
                    padding-right: ${rootStyles.spacers.small}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: ${
                        rootStyles.spacers.mini - rootStyles.spacers.nano
                    }px;
                    padding-bottom: ${
                        rootStyles.spacers.mini - rootStyles.spacers.nano
                    }px;
                    `
                        : ``}
            }
        }

        &:disabled {
            opacity: 1 !important;
        }
    `,
    LargeSolid: styled(StyledSizedButtons.Large)`
        background-color: ${(props) =>
            props.disabled
                ? `${rootStyles.glassColors.secondary4} !important`
                : rootStyles.colors.accent};

        &:hover {
            border: 0;
            background: ${(props) =>
                props.disabled
                    ? rootStyles.glassColors.secondary4
                    : rootStyles.glassColors.primary4};
        }

        &:focus {
            box-shadow: none !important;
            outline: none !important;

            &:active {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.secondary4
                        : `${rootStyles.glassColors.secondary3} !important`};
            }

            &:not(:active) {
                background: ${(props) =>
                    props.disabled
                        ? rootStyles.glassColors.secondary4
                        : `${rootStyles.glassColors.primary4} !important`};

                ${(props) =>
                    !props.disabled
                        ? `
                    border: ${rootStyles.spacers.nano}px solid ${
                              rootStyles.colors.secondary
                          } !important;
                    padding-left: ${rootStyles.spacers.medium}px;
                    padding-right: ${rootStyles.spacers.medium}px;
                    margin: ${rootStyles.spacers.nano}px;
                    padding-top: ${
                        rootStyles.spacers.small - rootStyles.spacers.nano
                    }px;
                    padding-bottom: ${
                        rootStyles.spacers.small - rootStyles.spacers.nano
                    }px;`
                        : ``}
            }
        }

        &:disabled {
            opacity: 1 !important;
        }
    `,
};
