import { OAuthPartners } from "../oAuth";

// should match https://tiny.amazon.com/bb9v9or8
export type getCustomerSupportDataRequest = {
    devicePlatform: CustomerSupportSupportedPlatform;
};

export type GetStateTokenRequest = {
    partner: OAuthPartners;
};

export enum CustomerSupportSupportedPlatform {
    WEB = "WEB",
}

export type GetSocialAccountDataRequest = {
    authId: string;
};
