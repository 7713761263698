import { getBundleMap } from "./commonSelectors";
import { createSelector } from "reselect";
import {
    albumRelease,
    ErrorPayload,
    group,
    itemProps,
    pitchFormData,
    track,
    trackInfoForPitch,
    artist,
    BundleMap,
} from "../../models";
import { RootState } from "../reducers";
import { selectedTeamIdSelector } from "./userSelectors";

const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;

const getCatalog = (state: RootState) => state.catalog.catalog;
const getCatalogBuildCompleted = (state: RootState) =>
    state.catalog.catalogBuildCompleted;
const getHydrationInProgress = (state: RootState) =>
    state.catalog.hydrationInProgress;
const getHydratingAsins = (state: RootState) => state.catalog.hydratingAsins;
const getFailedAsins = (state: RootState) => state.catalog.failedAsins;

const getUserLocale = (state: RootState) => state.user.locale;

const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);

const getSelectedAlbumRelease = (state: RootState) =>
    state.pitchForm.selectedAlbumRelease;
const getSelectedTrack = (state: RootState) => state.pitchForm.selectedTrack;

const getDescription = (state: RootState) => state.pitchForm.description;
const getGenresProps = (state: RootState) => state.pitchForm.genresProps;
const getCompositionsProps = (state: RootState) =>
    state.pitchForm.compositionsProps;
const getSongHasVocals = (state: RootState) => state.pitchForm.songHasVocals;
const getLanguagesProps = (state: RootState) => state.pitchForm.languagesProps;
const getSongMoods = (state: RootState) => state.pitchForm.songMoodsProps;
const getSongStyles = (state: RootState) => state.pitchForm.songStylesProps;
const getRelatedArtistsProps = (state: RootState) =>
    state.pitchForm.relatedAritstProps;
const getListenersLocation = (state: RootState) =>
    state.pitchForm.locationProps;
const getSelectedStep = (state: RootState) => state.pitchForm.selectedStep;

const getListTracksToPitchInProgress = (state: RootState) =>
    state.pitch.listTracksToPitchInProgress;
const getTracksToPitch = (state: RootState) => state.pitch.tracksToPitch;

const getListRelatedArtistsInProgress = (state: RootState) =>
    state.pitch.listRelatedArtistsInProgess;
const getRelatedArtists = (state: RootState) => state.pitch.relatedArtists;

const getPitchFormDataInProgress = (state: RootState) =>
    state.pitch.getPitchFormDataInProgress;
const getPitchFormData = (state: RootState) => state.pitch.pitchFormData;

const getIsPrivileged = (state: RootState) => state.user.isPrivileged;

const releatedArtistsFromCatalogSelector = createSelector(
    [getRelatedArtists, getCatalog],
    (relatedArtists, catalog) => {
        if (!relatedArtists) return;
        const relatedArtistsFromCatalog: artist[] = relatedArtists.map(
            (artist) => catalog.get(artist) as artist
        );
        return relatedArtistsFromCatalog.filter(
            (artist: artist) => artist !== undefined
        );
    }
);

const getCreatePitchInProgress = (state: RootState) =>
    state.pitch.createPitchInProgress;
const getCreatedPitchSuccess = (state: RootState) =>
    state.pitch.createdPitchSucess;

const selectedTrackFromCatalogSelector = createSelector(
    [getSelectedTrack, getCatalog],
    (selectedTrack, catalog) => {
        if (!selectedTrack) return;
        return catalog.get(selectedTrack) as track;
    }
);

const catalogSelector = createSelector(
    [
        getCatalog,
        getCatalogBuildCompleted,
        getHydrationInProgress,
        getHydratingAsins,
        getFailedAsins,
    ],
    (
        catalog,
        catalogBuildCompleted,
        hydrationInProgress,
        hydratingAsins,
        failedAsins
    ) => {
        return {
            catalog: catalog,
            catalogBuildCompleted,
            hydrationInProgress,
            hydratingAsins,
            failedAsins,
        };
    }
);

const pitchDataInProgress = createSelector(
    [
        getListTracksToPitchInProgress,
        getListRelatedArtistsInProgress,
        getPitchFormDataInProgress,
    ],
    (
        listTracksToPitchInProgress,
        listRelatedArtistsInProgress,
        pitchFormDataInProgress
    ) => {
        return {
            inProgress:
                listTracksToPitchInProgress ||
                listRelatedArtistsInProgress ||
                pitchFormDataInProgress,
        };
    }
);

const pitchFormSelector = createSelector(
    [
        getDescription,
        getGenresProps,
        getCompositionsProps,
        getSongHasVocals,
        getLanguagesProps,
        getSongMoods,
        getSongStyles,
        getRelatedArtistsProps,
        getListenersLocation,
        getSelectedStep,
        pitchDataInProgress,
    ],
    (
        description: string | undefined,
        genresProps: group[],
        compositionsProps: itemProps[],
        songHasVocals: boolean | undefined,
        languagesProps: group[],
        songMoodsProps: itemProps[],
        songStylesProps: itemProps[],
        relatedArtistsProps: group[],
        locationProps: group[],
        selectedStep: number | undefined,
        pitchDataInProgress: any
    ) => {
        return {
            description,
            genresProps,
            compositionsProps,
            songHasVocals,
            languagesProps,
            songMoodsProps,
            songStylesProps,
            relatedArtistsProps,
            locationProps,
            selectedStep,
            ...pitchDataInProgress,
        };
    }
);

const createPitchSelector = createSelector(
    [getCreatePitchInProgress, getCreatedPitchSuccess],
    (createPitchInProgress, createdPitchSuccess) => {
        return {
            createPitchInProgress,
            createdPitchSuccess,
        };
    }
);

export const pitchSelector = createSelector(
    [
        selectedTeamIdSelector,
        getSelectedArtistAsin,
        getUserLocale,
        getSelectedAlbumRelease,
        selectedTrackFromCatalogSelector,
        catalogSelector,
        pitchFormSelector,
        getErrorFromStore,
        getTracksToPitch,
        releatedArtistsFromCatalogSelector,
        getPitchFormData,
        createPitchSelector,
        getIsPrivileged,
        getBundleMap,
    ],
    (
        teamId: string | undefined,
        selectedArtistAsin: string | undefined,
        userLocale: string,
        selectedAlbumRelease: albumRelease | undefined,
        selectedTrack: track | undefined,
        catalogSelector: any,
        pitchFormSelector: any,
        error: ErrorPayload | undefined,
        tracksToPitch: trackInfoForPitch[] | undefined,
        relatedArtists: artist[] | undefined,
        pitchFormData: pitchFormData | undefined,
        createPitchSelector: any,
        isPrivileged: boolean | undefined,
        bundleMap: BundleMap
    ) => {
        return {
            teamId,
            selectedArtistAsin,
            userLocale,
            selectedAlbumRelease,
            selectedTrack,
            ...pitchFormSelector,
            ...catalogSelector,
            error,
            tracksToPitch,
            relatedArtists,
            pitchFormData,
            ...createPitchSelector,
            isPrivileged,
            bundleMap,
        };
    }
);
