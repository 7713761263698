import axios from "axios";
import {
    createPitchResponse,
    getPitchFormDataResponse,
    listAlbumsToPitchResponse,
    listRelatedArtistsResponse,
    listTracksToPitchResponse,
    PitchType,
} from "../models";
import { createPitchRequest } from "../models/requests/pitchRequests";
import { getEndpoints, testHeaders } from "./common";

const artistControlUrl = getEndpoints().artistControl;
const createPitchUrl = `${artistControlUrl}/artist/pitch`;
const getPitchFormDataUrl = `${artistControlUrl}/artist/pitch/form`;
const listAlbumsToPitchEndpoint = `${artistControlUrl}/artist/pitch/albums`;
const listTracksToPitchEndpoint = `${artistControlUrl}/artist/pitch/tracks`;
const listRelatedArtistsEndpoint = `${artistControlUrl}/artist/artist/related`;

export async function createPitch(
    payload: createPitchRequest,
    teamId: string
): Promise<createPitchResponse> {
    const result = await axios.post(createPitchUrl, payload, {
        headers: {
            "x-team-id": teamId,
            ...testHeaders(),
        },
    });

    return result.data;
}

export async function getPitchFormData(
    pitchType: PitchType,
    locale: string
): Promise<getPitchFormDataResponse> {
    const result = await axios.post(
        getPitchFormDataUrl,
        { pitchType, locale },
        {
            headers: {
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function listAlbumsToPitch(
    artistAsin: string,
    teamId: string,
    paginationToken?: string
): Promise<listAlbumsToPitchResponse> {
    const result = await axios.post(
        listAlbumsToPitchEndpoint,
        {
            artistAsin,
            paginationToken,
        },
        {
            headers: {
                "x-team-id": teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function listTracksToPitch(
    artistAsin: string,
    albumTitleSetAsin: string,
    teamId: string,
    albumGlobalAsin?: string
): Promise<listTracksToPitchResponse> {
    const result = await axios.post(
        listTracksToPitchEndpoint,
        {
            artistAsin,
            albumTitleSetAsin,
            albumGlobalAsin,
        },
        {
            headers: {
                "x-team-id": teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}

export async function listRelatedArtists(
    artistAsin: string,
    teamId: string
): Promise<listRelatedArtistsResponse> {
    const result = await axios.post(
        listRelatedArtistsEndpoint,
        { artistAsin },
        {
            headers: {
                "x-team-id": teamId,
                ...testHeaders(),
            },
        }
    );

    return result.data;
}
