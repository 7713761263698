import { createAction } from "redux-ts";
import { CardLayout, EntityType, PromoCardBackgroundColor } from "../../models";

export const selectStep = createAction<number>("PROMO_CARD::SELECT_STEP");

export const selectEntity = createAction<EntityType>(
    "PROMO_CARD::SELECT_ENTITY"
);

export const selectCardLayout = createAction<CardLayout>(
    "PROMO_CARD::SELECT_CARD_LAYOUT"
);

export const selectCardColor = createAction<PromoCardBackgroundColor>(
    "PROMO_CARD::SELECT_CARD_COLOR"
);

export const selectCardLocale = createAction<string>(
    "PROMO_CARD::SELECT_CARD_LOCALE"
);
