import { Action } from "redux-ts";
import { takeEvery } from "redux-saga/effects";
import { telemetryActions } from "../actions";
import { METRIC_KEYS, telemetryPayload, MetricType } from "../../models";
import { publishCounterWithMetadata } from "../../service";
import { Metric } from "@katal/metrics";
import KatalMetricType from "@katal/metrics/lib/metricObject/KatalMetricType";

export const telemetrySagas = [
    watchAppEvent(),
    watchUserAction(),
    watchErrorEvent(),
];

function* appEvent(action: Action<telemetryPayload>) {
    try {
        const payload = action.payload;
        const dataPoints = payload.dataPoints;
        // dataPoints.set(METRIC_KEYS.userId, userInfo && userInfo.userId);
        dataPoints.set(METRIC_KEYS.timeStamp, new Date().toUTCString());
        console.log(
            payload.name + ": " + payload.dataPoints.get(METRIC_KEYS.loadTime)
        );
        yield publishCounterWithMetadata({
            name: payload.name,
            datapoints: dataPoints,
            metricType: MetricType.AppEvent,
        });
        // tslint:disable-next-line: no-empty
    } catch (ex) {}
}

// -------- DEPRECATED --------- //
function* userAction(action: Action<telemetryPayload>) {
    try {
        const payload = action.payload;
        const dataPoints = payload.dataPoints;
        // dataPoints.set(METRIC_KEYS.userId, userInfo && userInfo.userId);
        dataPoints.set(METRIC_KEYS.timeStamp, new Date().toUTCString());
        console.log(
            payload.name + ": " + payload.dataPoints.get(METRIC_KEYS.loadTime)
        );
        yield publishCounterWithMetadata({
            name: payload.name,
            datapoints: dataPoints,
            metricType: MetricType.UserAction,
        });
        // tslint:disable-next-line: no-empty
    } catch (ex) {}
}
// -------- DEPRECATED --------- //

// -------- DEPRECATED --------- //
function* errorEvent(action: Action<telemetryPayload>) {
    try {
        const payload = action.payload;
        const dataPoints = payload.dataPoints;
        // dataPoints.set(METRIC_KEYS.userId, userInfo && userInfo.userId);
        dataPoints.set(METRIC_KEYS.timeStamp, new Date().toUTCString());
        console.log(
            payload.name + ": " + payload.dataPoints.get(METRIC_KEYS.loadTime)
        );
        yield publishCounterWithMetadata({
            name: payload.name,
            datapoints: dataPoints,
            metricType: MetricType.Error,
        });
        // tslint:disable-next-line: no-empty
    } catch (ex) {}
}
// -------- DEPRECATED --------- //

function* watchAppEvent() {
    yield takeEvery(telemetryActions.appEvent.type, appEvent);
}

function* watchUserAction() {
    yield takeEvery(telemetryActions.userAction.type, userAction);
}

function* watchErrorEvent() {
    yield takeEvery(telemetryActions.errorEvent.type, errorEvent);
}
