import { Action } from "redux-ts";
import { artistIssueActions } from "../actions";
import { baseMediaItem } from "../../models";

export type ArtistIssueState = Readonly<{
    reportIssueSelectedItem?: baseMediaItem;
    inProgress: boolean;
    success?: boolean;
}>;

export const initialArtistIssueState: ArtistIssueState = {
    inProgress: false,
};

export const artistIssueReducer = (
    state: ArtistIssueState = initialArtistIssueState,
    action: Action
) => {
    switch (action.type) {
        case artistIssueActions.selectReportIssueItem.type: {
            return {
                ...state,
                reportIssueSelectedItem: action.payload,
            };
        }
        case artistIssueActions.clearSelectedReportIssueItem.type: {
            return {
                ...state,
                reportIssueSelectedItem: undefined,
            };
        }
        case artistIssueActions.newDisambiguationInProgress.type:
            return { ...state, inProgress: action.payload };
        case artistIssueActions.newDisambiguationSuccess.type:
            return { ...state, success: action.payload };
        default:
            return state;
    }
};
