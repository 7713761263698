/* tslint:disable */
import React from "react";

export const terms_jaJP = (
    <div>
        <p className="p1">
            <span className="s1">
                <b>
                    Amazon Music for Artists 利用規約
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1">
                <b>最終更新日：2022年2月</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p2">
            <span className="s1">
                この利用規約は、貴殿とAmazon Services
                LLC（その関連会社と併せて、「アマゾン」といいます。）との間の契約であり、Amazon
                Music for Artistsサービス（以下、「Amazon Music for
                Artists」といいます。）のご利用に適用されます。Amazon Music for
                Artistsをご利用になる前に、本規約、関連する規則やポリシー（アマゾンがAmazon
                Music for Artists上もしくはAmazon Music for
                Artistsを通じて、またはAmazon Music for
                Artistsのヘルプその他の情報ページに掲載し、更新する特定の規則、利用制限その他の条件または手続きを含みます。）、{" "}
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201909010&view-type=content-only">
                    <span className="s3">Privacy Notice</span>
                </a>
                、
                <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&nodeId=508088">
                    <span className="s3">Conditions of Use</span>
                </a>
                、
                <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=202075050&view-type=content-only">
                    <span className="s3">Interest-Based Ads</span>
                </a>
                に関する通知（以下、総称して「本契約」といいます。）をお読みください。本契約は、「同意します」ボタンまたは本規約に表示されるチェックボックスをクリックされた時点で効力を発生します。貴殿は、アマゾンに対して合法的に契約を締結できること（例えば未成年ではないこと）を表明します。貴殿が（勤務する会社や、代理するアーティスト等）事業体または個人のために本契約を締結する場合、アマゾンに対して、かかる事業体または個人を拘束する法的権限を有することを表明します。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>{" "}
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">1.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>概要</b>Amazon Music for
                Artistsには、アーティストおよび正式な権限を有する代理人が当該アーティストのファン層に対する洞察を得たり、Amazon
                Musicの提供するサービスでファンと関わりあうことのできる様々なツールおよび機能（以下、「本サービス」といいます。）があります。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">2.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>資格</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.1.</span>
            <span className="s4">   </span>
            <span className="s1">
                Amazon Music for
                Artistsを利用するには、Amazonアカウントを有し、(a)本サービスでの配信用にアマゾンにコンテンツを提供するアーティストまたはアーティストの正式な権限を有する代理人であるか、(b)
                本サービスでの配信用にコンテンツをアマゾンに提供する契約をアマゾンと締結している事業体の正式な代表者でなければなりません。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.2.</span>
            <span className="s4">   </span>
            <span className="s1">
                虚偽のEメールアドレスの使用、他者または他社へのなりすまし、その他虚偽または誤解を招く情報の提供（アカウント開設時に提供する情報またはアーティストコンテンツの所有権に関して提供する情報を含みます。）を行ってはなりません。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">2.3.</span>
            <span className="s4">   </span>
            <span className="s1">
                貴殿のAmazonユーザー名およびパスワードを他者と共用してはならず、他者のAmazonユーザー名およびパスワードを利用してもなりません。ただし、貴殿が承認する者に対して、各自のAmazon
                Music for
                Artistsのアカウントでの貴殿のファン・インサイトの閲覧を許可することはできます。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p8">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.4.</span>
            <span className="s4">   </span>
            <span className="s1">
                貴殿は、貴殿のEメールアカウントおよびパスワードを含め、ご自身のコンピュータまたはコンピュータ施設から行われるAmazon
                Music for
                Artistsに関するあらゆる通知および活動に責任を負います。
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">2.5.</span>
            <span className="s4">   </span>
            <span className="s1">
                アマゾンは、貴殿の連絡先情報（氏名、Eメールアドレスおよびソーシャルメディアのユーザー名を含みます。）を身元および適格性確認目的で第三者との間で、またその他貴殿のファン・インサイトを評価する個人との間で共有することができます。
            </span>
            <span className="s5">
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">3.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>ファン・インサイト</b>
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.1.</span>
            <span className="s4">   </span>
            <span className="s1">
                アマゾンは、本サービス上での貴殿のコンテンツ配信に関するデータ（以下「ファン・インサイト」といいます。）へのアクセスを提供することができます。貴殿は内部評価および分析に限りファン・インサイトを利用することができ、第三者との共有はかかる第三者が本規約に同意しない限り行うことはできません。ただし、アマゾンは、貴殿が公開することのできる「共有カード」経由で一定の情報（たとえば、貴殿が本サービスで達成したマイルストーン）を提供することができます。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.2.</span>
            <span className="s4">   </span>
            <span className="s1">
                ファン・インサイトは貴殿の便宜のためにのみ提供され、アマゾンはその正確性を保証しません。したがって、ライセンス料または本サービス上のコンテンツの配信の結果として貴殿に支払われるその他の金額の計算において、ファン・インサイトに依拠すべきではありません。
            </span>
        </p>
        <p className="p5">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">3.3.</span>
            <span className="s4">   </span>
            <span className="s1">
                提供されたデータ以上に詳細なデータを得るために（例えば、本サービスのエンドユーザーの個人情報の特定等）、ファン・インサイトを利用してはなりません。
            </span>
        </p>
        <p className="p5">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">4.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    権利<span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.1.</span>
            <span className="s4">   </span>
            <span className="s1">
                <b>アーティストコンテンツ　</b>貴殿は、Amazon Music for
                Artistsを使用して、自ら所有するか、その他投稿を許可されている資料（たとえば画像やイラスト）（以下「アーティストコンテンツ」といいます。）を投稿することができます。違法、わいせつ、脅迫的、中傷的、その他不愉快または不適切な内容のアーティストコンテンツを投稿してはならず、(a)プライバシー、パブリシティ権、著作権、商標、特許、その他の知的財産権を含む第三者の権利を損ない、侵害し、または(b)アマゾンが本契約で認められたアーティストコンテンツの利用に関して第三者に対するロイヤルティその他の料金の支払いを要求されることになるようなアーティストコンテンツを投稿してはなりません。貴殿は、アーティストコンテンツに関して支払うべき料金の支払いに責任を負い、これに同意します。Amazon
                Music for
                Artistsへの投稿により、貴殿のアーティストコンテンツの所有権に変更はありません。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p9">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">4.2.</span>
            <span className="s4">   </span>
            <span className="s1">
                <b>ライセンスの付与　</b>
                貴殿はアマゾンに対して、本サービスおよびAmazon Music for
                Artistsに関連して、すべてのアーティストコンテンツをあらゆるメディアおよび記述形式において使用、再生、配信、送信、上演、修正および表示する、非独占的、サブライセンス可能、世界的、永久、取消不能、無償の権利およびライセンスを付与します。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s5">4.3.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>提案　</b>
            </span>
            <span className="s1">
                貴殿がアマゾンに対してAmazon Music for
                Artistsの改善を提案した場合（以下「本提案」といいいます。）、アマゾンは制限を受けることなく本提案を利用することができます。貴殿はここに本提案のあらゆる権利、所有権および権益を取消不能のものとしてアマゾンに譲渡し、本提案に対するアマゾンの権利を文書化し、対抗要件を具備し、維持するためにアマゾンが要求する支援を提供することに同意します。
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">5.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>セキュリティおよびデータ機密性</b>
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.1.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>リバースエンジニアリングまたは回避の禁止　</b>
            </span>
            <span className="s1">
                貴殿は、Amazon Music for
                Artistsのいかなる部分についても、修正、リバースエンジニアリング、回避、逆コンパイル、逆アセンブル、複製その他改ざんを行ってはならず、他者に対してこれらを促したり、支援または承認しません。
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">5.2.</span>
            <span className="s4">   </span>
            <span className="s1">
                <b>大量データ収集の禁止　</b>貴殿は、Amazon Music for
                Artistsを「クロール」し、その他アマゾンから情報を収集するためにデータ・マイニング、スクレイピングその他のデータ収集ツールを利用してはならず、他者に対してこれらを促したり、支援または承認しません。
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">6.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>
                    変更および停止
                    <span className="Apple-converted-space"> </span>
                </b>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">6.1.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>変更　</b>
            </span>
            <span className="s1">
                アマゾンは、Amazon Music for
                Artistsまたはその一部をいつでも変更、停止または廃止することができます。アマゾンは随時、Amazon
                Music for
                Artistsに機能を追加または削除することができます。異常事態が生じた場合には、特定の機能もしくはファン・インサイトの保存またはこれらへのアクセス提供ができない場合があります。
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">6.2.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>アマゾンによる停止および終了　</b>
            </span>
            <span className="s1">
                本契約に基づく貴殿の権利は、本契約の条件を遵守されない場合通知なしに自動的に終了します。アマゾンは、貴殿の利用が本契約に違反している、不適切である、他のユーザーの通常の利用を大幅に超え、もしくはそれと異なっている、詐欺もしくはAmazon
                Music for Artistsの不正使用がある、またはアマゾンもしくはAmazon
                Music for
                Artistsの他のユーザーの利益を損なっていると判断する場合を含め、いつでも本契約を終了し、貴殿のAmazon
                Music for
                Artistsの利用を制限、停止または終了することができます。貴殿はアカウントを解約することにより、理由を問わず本契約を終了することができます。貴殿のAmazon
                Music for
                Artistsのへのアクセスが制限、停止または終了された場合、ファン・インサイトにアクセスすることはできません。停止または終了後も、アマゾンは本契約に基づきアマゾンに付与されたアーティストコンテンツに対する権利を留保します。
            </span>
        </p>
        <p className="p4">
            <span className="s1"> </span>
        </p>
        <p className="p3">
            <span className="s1">7.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>正当な権限および補償 </b>
                貴殿は、(a)本契約において付与される権利をアマゾンに付与するのに必要なあらゆる権利を有し、(b)貴殿の投稿する資料およびアマゾンによるその利用は本契約により承認されたものであり、(i)第三者のプライバシー権、パブリシティ権、知的財産権その他の権利を損なわず、侵害せず、(ii)中傷的、違法またはわいせつではなく、かつ(iii)かかる資料の使用によりアマゾンが第三者に対するロイヤルティその他の料金の支払いを要求されることがなく、(c)貴殿の投稿するすべての事実の記載が正確であり、誤解を生じるものではないことを、表明し、保証します。貴殿は、貴殿の投稿する資料、貴殿による本契約または表明保証の違反、または貴殿によるAmazon
                Music for
                Artistsの不適切、不正なもしくは違法な利用から生じ、またはこれらに関連するあらゆる請求、責任、損害および費用（合理的な弁護士費用および経費を含みます。）について、アマゾンならびにその取締役、役員、従業員、代理人および譲受人を補償し、防御し、損害を被らせないことに同意します。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p4">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p3">
            <span className="s1">8.</span>
            <span className="s4">     </span>
            <span className="s1">
                <b>一般条項</b>
            </span>
        </p>
        <p className="p2">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s1">8.1.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>修正　</b>
            </span>
            <span className="s1">
                アマゾンは、Amazon Music for Artists上またはAmazon Music for
                Artistsを通じて修正後の条件を掲載することにより、いつでも本契約を修正することができます。修正後もAmazon
                Music for
                Artistsのご利用を継続された場合、変更後の条件に拘束されることに同意されたことになります。
            </span>
        </p>
        <p className="p7">
            <span className="s1"> </span>
        </p>
        <p className="p6">
            <span className="s5">8.2.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>通知　</b>
            </span>
            <span className="s1">
                アマゾンは、Amazon Music for
                Artistsの利用に関連して、電子的その他の伝達手段で貴殿に連絡することができ、貴殿は、Amazon.comのウェブサイト上でまたはその他の手段で表明した顧客への連絡方法または同様の希望もしくは要望にかかわらず、かかる連絡方法に同意します。
                <span className="Apple-converted-space"> </span>
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.3.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>著作権表示　</b>
            </span>
            <span className="s1">
                貴殿の作品が著作権侵害となる方法で複製されていると確信する場合、
                <a href="https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&nodeId=508088">
                    <span className="s8">Conditions of Use</span>
                </a>
                に定める 「Notice and Procedure for Making Claims of Copyright
                Infringement」に従ってください。
            </span>
        </p>
        <p className="p10">
            <span className="s1">
                <b> </b>
            </span>
        </p>
        <p className="p6">
            <span className="s1">8.4.</span>
            <span className="s4">   </span>
            <span className="s5">
                <b>完全な合意、可分性　</b>
            </span>
            <span className="s1">
                本契約は、貴殿とアマゾンとの間の完全な合意を構成し、貴殿のAmazon
                Music for
                Artistsの利用に適用され、貴殿とアマゾンとの間の従前の合意に優先します。本契約のいずれかの条件が無効または理由を問わず執行不能とされた場合、かかる部分は分離可能とみなされ、残りの条件の有効性および執行可能性には影響しません。
            </span>
        </p>
    </div>
);
