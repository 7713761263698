import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Col, Row, Container, Image } from "react-bootstrap";
import _ from "lodash";
import { bundleIds, buttonIds, pageIds } from "@amzn/ziggy-asset";
import {} from "@amzn/arb-tools";
import {
    RequiredReportingProps,
    RequiredReportingState,
    RequiredReportingDispatchProps,
    TimeRange,
    EntityType,
    BundleMap,
} from "../../../models/common";
import * as rootStyles from "../../styles";
import {
    LineGraph,
    LineGraphProps,
    BarChart,
    BarChartProps,
    VerticalSpacedTable,
    InlineError,
    Loading,
    CommonHeader,
    SmallSolidButton,
    AnnouncementToast,
    DownloadButton,
    MerchCurationRibbon,
} from "../../components";
import {
    parseTrackDataPoints,
    formatDatum,
    generateAlbumRows,
    generateSongRows,
    paths,
    timeRangeToTickCount,
    SUMMARY_TABLE_ITEM_COUNT,
    getReportsHelper,
    formatNumber,
    parseStreamSourceBar,
    formatTooltipDatum,
    guessTimeZone,
    getUniqueNewPlaylists,
    generateNewAddsToPlaylistsRows,
    ADD_TO_PLAYLIST_TABLE_ITEM_COUNT,
    mediaForPitchListHasPitchableItem,
    buildUIClickPayload,
    LARGE_SCREEN_WIDTH_THRESHOLD,
    getLocalizedString,
} from "../../../utils";
import { merchImages, stringIds } from "../../../assets";
import {
    RootState,
    reportingActions,
    overviewScreenSelector,
    telemetryActions,
    catalogActions,
    userActions,
    pitchActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import {
    METRIC_KEYS,
    TableRowProps,
    trackInfo,
    albumInfo,
    trackStreamsInfo,
    trackListenersInfo,
    streamSourceInfo,
    reportingOverviewRequestPayload,
    telemetryPayload,
    recentlyAddedToPlaylistPayload,
    createCsvFileRequestPayload,
    listAlbumsToPitchPayload,
    albumRelease,
    clientMetricsPayload,
} from "../../../models";
import { SmallGlassButton } from "../../components";
import { CsvButtonId, csvButtonLoadingStatusData } from "../../../export";

const testIDPrefix = "ReportsOverviewScreen";
const metricPrefix = "reportsOverviewPage";
const pagePath = paths.reports;

type ViewProps = {};

type StateProps = RequiredReportingProps & {
    topTracks: trackInfo[];
    topAlbums: albumInfo[];
    trackStreams?: trackStreamsInfo;
    trackListeners?: trackListenersInfo;
    streamSources: streamSourceInfo[];
    albumsToPitch: albumRelease[];
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    shouldDisplayMerchCurationBanner: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = RequiredReportingDispatchProps & {
    getReports: (payload: reportingOverviewRequestPayload) => void;
    updateCurrentPath: (payload: string) => void;
    getRecentlyAddedToPlaylist: (
        payload: recentlyAddedToPlaylistPayload
    ) => void;
    getAlbumReleases: (payload: listAlbumsToPitchPayload) => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    showNewReleasesToast: boolean;
};

class ReportsOverviewScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            showNewReleasesToast: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }
    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();
        this.getReports();
        this.getAlbumReleases();

        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist],
                [METRIC_KEYS.timeRange, this.props.selectedRange],
                [METRIC_KEYS.page, pagePath],
            ]),
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.selectedRange !== prevProps.selectedRange) {
            this.getReports();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getReports();
        } else if (this.props.selectedArtist !== prevProps.selectedArtist) {
            this.getReports();
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false, loadedReports: true });
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.state.loadedReports &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (
            this.props.albumsToPitch &&
            prevProps.albumsToPitch !== this.props.albumsToPitch &&
            mediaForPitchListHasPitchableItem(this.props.albumsToPitch)
        ) {
            this.setState({ showNewReleasesToast: true });
        }

        if (this.props.error !== prevProps.error) {
            if (this.props.error && this.props.error.requestPath === pagePath) {
                this.setState({ showError: true });
            } else {
                this.setState({ showError: false });
            }
        }
    }

    render() {
        // Loading state
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const streamSourcesChartProps: BarChartProps = {
            data: parseStreamSourceBar(
                this.props.streamSources,
                this.props.bundleMap
            ),
            title: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.sourceByStreamsTitle,
            }),
            id: testIDPrefix + "_StreamSourcesBarChart",
            paddingLeft: "8px",
            bundleMap: this.props.bundleMap,
        };

        const streamCount = this.props.trackStreams
            ? this.props.trackStreams.totalCount
            : 0;
        const totalStreamsWOW = this.props.trackStreams
            ? this.props.trackStreams.delta
            : "N/A";
        const userTimeZone = guessTimeZone();

        const parsedTrackStreams =
            this.props.trackStreams &&
            parseTrackDataPoints(
                this.props.trackStreams,
                this.props.selectedRange === TimeRange.AllTime
            );
        const streamsLineChartProps: LineGraphProps | undefined =
            parsedTrackStreams && {
                data: [parsedTrackStreams],
                title: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.totalStreamsTitle,
                    },
                    { "0": formatNumber(streamCount) }
                ),
                subtitle: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.weekOverWeek,
                    },
                    { "0": formatNumber(totalStreamsWOW) }
                ),
                labels:
                    this.props.selectedRange === TimeRange.AllTime
                        ? [
                              getLocalizedString(this.props.bundleMap, {
                                  bundleId: bundleIds.REPORTS_STRINGS,
                                  stringId: stringIds.Reports.allTimeSubtitle,
                              }),
                          ]
                        : undefined,
                xTickCount: timeRangeToTickCount(this.props.selectedRange),
                tickFormat: (datum: any) =>
                    formatDatum(datum, this.props.selectedRange, userTimeZone),
                tooltipFormat: (datum: any) =>
                    formatTooltipDatum(
                        datum,
                        this.props.selectedRange,
                        userTimeZone
                    ),
                id: testIDPrefix + "_StreamCountLineChart",
                bundleMap: this.props.bundleMap,
            };

        const listenersCount = this.props.trackListeners
            ? this.props.trackListeners.totalCount
            : 0;
        const totalListenersWOW = this.props.trackListeners
            ? this.props.trackListeners.delta
            : "N/A";
        const parsedTrackListeners =
            this.props.trackListeners &&
            parseTrackDataPoints(
                this.props.trackListeners,
                this.props.selectedRange === TimeRange.AllTime
            );
        const listenersLineChartProps: LineGraphProps | undefined =
            parsedTrackListeners && {
                data: [parsedTrackListeners],
                title: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.totalListenersTitle,
                    },
                    { "0": formatNumber(listenersCount) }
                ),
                subtitle: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.weekOverWeek,
                    },
                    { "0": formatNumber(totalListenersWOW) }
                ),
                labels:
                    this.props.selectedRange === TimeRange.AllTime
                        ? [
                              getLocalizedString(this.props.bundleMap, {
                                  bundleId: bundleIds.REPORTS_STRINGS,
                                  stringId: stringIds.Reports.allTimeSubtitle,
                              }),
                          ]
                        : undefined,
                xTickCount: timeRangeToTickCount(this.props.selectedRange),
                tickFormat: (datum: any) =>
                    formatDatum(datum, this.props.selectedRange, userTimeZone),
                tooltipFormat: (datum: any) =>
                    formatTooltipDatum(
                        datum,
                        this.props.selectedRange,
                        userTimeZone
                    ),
                id: testIDPrefix + "_ListenerCountLineChart",
                bundleMap: this.props.bundleMap,
            };

        const topAlbumsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.albumsTitle,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const topSongsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.songsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const topAlbums: TableRowProps[] = generateAlbumRows(
            this.props.topAlbums,
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            this.props.bundleMap,
            this.props.selectedArtist,
            testIDPrefix + "_TopAlbumsRows"
        );

        const topSongs: TableRowProps[] = generateSongRows(
            this.props.topTracks,
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history?.push,
            this.props.bundleMap,
            testIDPrefix + "_TopSongsRows"
        );

        const uniquePlaylists = getUniqueNewPlaylists(
            this.props.recentlyAddedToPlaylistData
        );

        const newAddsToPlaylistsHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.playlistsLabel,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.dateAdded,
            }),
        ];
        const newAddsToPlaylists: TableRowProps[] =
            generateNewAddsToPlaylistsRows(
                uniquePlaylists.slice(0, ADD_TO_PLAYLIST_TABLE_ITEM_COUNT),
                this.props.catalog,
                this.props.hydratingAsins,
                this.props.history?.push,
                testIDPrefix + "_NewAddsToPlaylistsRows",
                this.props.bundleMap
            );

        const csvBasePayload = {
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: pagePath,
        };
        const trendlinesCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "trendlines",
            csvButtonId: CsvButtonId.OverviewTrendlines,
            streamsTrendlineData: this.props.trackStreams,
            listenersTrendlineData: this.props.trackListeners,
        };

        const topAlbumsCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "topAlbums",
            csvButtonId: CsvButtonId.OverviewTopAlbums,
            albumData: this.props.topAlbums,
        };

        const topSongsCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "topSongs",
            csvButtonId: CsvButtonId.OverviewTopSongs,
            trackData: this.props.topTracks,
        };

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.reportsOverviewTitle,
                    })}
                    subtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.reportsOverviewSubtitle,
                    })}
                    id={`${testIDPrefix}_Header`}
                    showCsvButton={
                        this.props.hasCsvExportCapabilities &&
                        (!!this.props.trackStreams?.totalCount ||
                            !!this.props.trackListeners?.totalCount)
                    }
                    csvDownload={() => {
                        this.props.sendClientMetrics(
                            buildUIClickPayload(
                                buttonIds.CsvExport.downloadTrendlines,
                                pageIds.reports,
                                this.props.selectedArtist,
                                EntityType.ARTIST
                            )
                        );
                        this.props.getCsvFile(trendlinesCsvPayload);
                    }}
                    csvTooltipMessage={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.CSV_STRINGS,
                            stringId:
                                stringIds.Csv.TooltipMessage
                                    .StreamsAndListeners,
                        }
                    )}
                    csvButtonLoading={
                        this.props.csvButtonLoadingStatus["Overview-Trendlines"]
                    }
                />

                {this.state.showNewReleasesToast ? (
                    <AnnouncementToast>
                        <div style={{ marginRight: rootStyles.spacers.small }}>
                            <span style={rootStyles.textStyles.primary}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.PITCH_STRINGS,
                                    stringId: stringIds.Pitch.toastMessage,
                                })}
                            </span>
                        </div>
                        <SmallSolidButton
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.view,
                            })}
                            onClick={() =>
                                this.props.history.push(paths.newReleases)
                            }
                            id={`${testIDPrefix}_ViewNewReleases`}
                        />
                    </AnnouncementToast>
                ) : this.props.shouldDisplayMerchCurationBanner ? (
                    <MerchCurationRibbon>
                        {window.innerWidth > LARGE_SCREEN_WIDTH_THRESHOLD ? (
                            <Image
                                style={{
                                    width: 269, // from UX
                                    position: "relative",
                                    bottom: -70,
                                }}
                                src={merchImages.createMerch}
                            />
                        ) : null}
                        <div style={{ marginRight: rootStyles.spacers.small }}>
                            <span style={rootStyles.textStyles.primary}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                                    stringId:
                                        stringIds.Merch.Curation
                                            .OverviewBannerTitle,
                                })}
                            </span>
                        </div>
                        <SmallSolidButton
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.MERCHCURATION_STRINGS,
                                stringId:
                                    stringIds.Merch.Curation
                                        .OverviewBannerButtonCTA,
                            })}
                            onClick={() => {
                                this.props.sendClientMetrics(
                                    buildUIClickPayload(
                                        buttonIds.Merch.showMerchHomepage,
                                        pageIds.reports,
                                        this.props.selectedArtist,
                                        EntityType.ARTIST
                                    )
                                );
                                this.props.history.push(paths.merch);
                            }}
                            id={`${testIDPrefix}_CurationOverviewBanner`}
                        />
                    </MerchCurationRibbon>
                ) : null}
                <Row>
                    {/* {/* {this.props.artist &&
                    <Image
                        blurRadius={rootStyles.blurAmount}
                        style={rootStyles.imageStyles.backgroundImage}
                        source={{ uri: this.props.artist?.images?.artSmall }}
                    />
                } */}
                </Row>
                {this.state.showError && (
                    <InlineError
                        text={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.ERRORS_STRINGS,
                            stringId: stringIds.Errors.reportsError,
                        })}
                        retryAction={this.getReports}
                        id={`${testIDPrefix}_Error`}
                    />
                )}
                <Row>
                    {streamsLineChartProps && (
                        <Col xs={12} md={6}>
                            <LineGraph
                                key="totalStreams"
                                {...streamsLineChartProps}
                            />
                        </Col>
                    )}

                    {listenersLineChartProps && (
                        <Col xs={12} md={6}>
                            <LineGraph
                                key="totalListeners"
                                {...listenersLineChartProps}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <VerticalSpacedTable
                            rows={topAlbums.slice(0, SUMMARY_TABLE_ITEM_COUNT)}
                            labels={topAlbumsHeader}
                            showIndex={true}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId: stringIds.Reports.topAlbumsTitle,
                            })}
                            id={testIDPrefix + "_TopAlbumsTable"}
                            footer={
                                <Row style={{ alignItems: "center" }}>
                                    {this.props.hasCsvExportCapabilities &&
                                        topAlbums.length && (
                                            <Col>
                                                <DownloadButton
                                                    loading={
                                                        !!this.props
                                                            .csvButtonLoadingStatus[
                                                            "Overview-TopAlbums"
                                                        ]
                                                    }
                                                    size={
                                                        rootStyles.ButtonSize
                                                            .small
                                                    }
                                                    id={
                                                        "CsvButton-OverviewTopAlbums"
                                                    }
                                                    tooltipMessage={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.CSV_STRINGS,
                                                            stringId:
                                                                stringIds.Csv
                                                                    .TooltipMessage
                                                                    .TopAlbums,
                                                        }
                                                    )}
                                                    onClick={() => {
                                                        this.props.sendClientMetrics(
                                                            buildUIClickPayload(
                                                                buttonIds
                                                                    .CsvExport
                                                                    .downloadAlbums,
                                                                pageIds.reports,
                                                                this.props
                                                                    .selectedArtist,
                                                                EntityType.ARTIST
                                                            )
                                                        );
                                                        this.props.getCsvFile(
                                                            topAlbumsCsvPayload
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                    {topAlbums.length >
                                    SUMMARY_TABLE_ITEM_COUNT ? (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={this.seeAllAlbums}
                                            id={testIDPrefix + "_SeeAllAlbums"}
                                        />
                                    ) : null}
                                </Row>
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <VerticalSpacedTable
                            rows={topSongs.slice(0, SUMMARY_TABLE_ITEM_COUNT)}
                            labels={topSongsHeader}
                            showIndex={true}
                            title={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId: stringIds.Reports.topSongsTitle,
                            })}
                            id={testIDPrefix + "_TopSongsTable"}
                            footer={
                                <Row style={{ alignItems: "center" }}>
                                    {this.props.hasCsvExportCapabilities &&
                                        topSongs.length && (
                                            <Col>
                                                <DownloadButton
                                                    loading={
                                                        !!this.props
                                                            .csvButtonLoadingStatus[
                                                            "Overview-TopSongs"
                                                        ]
                                                    }
                                                    size={
                                                        rootStyles.ButtonSize
                                                            .small
                                                    }
                                                    id={
                                                        "CsvButton-OverviewTopSongs"
                                                    }
                                                    tooltipMessage={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.CSV_STRINGS,
                                                            stringId:
                                                                stringIds.Csv
                                                                    .TooltipMessage
                                                                    .TopSongs,
                                                        }
                                                    )}
                                                    onClick={() => {
                                                        this.props.sendClientMetrics(
                                                            buildUIClickPayload(
                                                                buttonIds
                                                                    .CsvExport
                                                                    .downloadSongs,
                                                                pageIds.reports,
                                                                this.props
                                                                    .selectedArtist,
                                                                EntityType.ARTIST
                                                            )
                                                        );
                                                        this.props.getCsvFile(
                                                            topSongsCsvPayload
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                    {topSongs.length >
                                    SUMMARY_TABLE_ITEM_COUNT ? (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={this.seeAllSongs}
                                            id={testIDPrefix + "_SeeAllSongs"}
                                        />
                                    ) : null}
                                </Row>
                            }
                        />
                    </Col>
                </Row>
                {newAddsToPlaylists && newAddsToPlaylists.length > 0 && (
                    <Row
                        style={{
                            backgroundColor: rootStyles.glassColors.secondary3,
                            marginTop: rootStyles.spacers.large,
                            marginBottom: rootStyles.spacers.large,
                        }}
                    >
                        <Col>
                            <VerticalSpacedTable
                                rows={newAddsToPlaylists}
                                labels={newAddsToPlaylistsHeader}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports
                                                .newAddsToPlaylistsTitle,
                                    }
                                )}
                                secondaryText={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports
                                                .newAddsToPlaylistsSecondary,
                                    }
                                )}
                                id={testIDPrefix + "_NewAddsToPlaylists"}
                                footer={
                                    this.props.recentlyAddedToPlaylistData
                                        .length > SUMMARY_TABLE_ITEM_COUNT ? (
                                        <SmallGlassButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.GENERIC_STRINGS,
                                                    stringId:
                                                        stringIds.Generic
                                                            .seeAll,
                                                }
                                            )}
                                            onClick={
                                                this.seeAllNewAddsToPlaylists
                                            }
                                            id={`${testIDPrefix}_SeeAllNewAddsToPlaylists`}
                                        />
                                    ) : null
                                }
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        {streamSourcesChartProps && (
                            <BarChart
                                key="streamSources"
                                {...streamSourcesChartProps}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }

    private seeAllNewAddsToPlaylists = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllNewAddsToPlaylists,
                pageIds.reports,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );

        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllNewAddsToPlaylists,
                pageIds.reports,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );

        this.props.history?.push(paths.newAddsToPlaylists);
    };

    private seeAllAlbums = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllAlbums,
                pageIds.reports,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );

        this.props.history?.push(paths.albums);
    };

    private seeAllSongs = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllSongs,
                pageIds.reports,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );

        this.props.history?.push(paths.songs);
    };

    private getReports = () => {
        getReportsHelper(
            this.props.getReports,
            this.props.getRecentlyAddedToPlaylist,
            this.props.selectedRange,
            pagePath,
            this.props.selectedArtist,
            this.props.teamId,
            this.props.startDate,
            this.props.endDate,
            this.props.userLocale
        );
    };

    private getAlbumReleases = () => {
        if (!this.props.selectedArtist || !this.props.teamId) return;
        const getAlbumsToPitchPayload: listAlbumsToPitchPayload = {
            artistAsin: this.props.selectedArtist,
            teamId: this.props.teamId,
            requestPath: pagePath,
            locale: this.props.userLocale,
        };
        this.props.getAlbumReleases(getAlbumsToPitchPayload);
    };
}

function mapStateToProps(state: RootState) {
    return {
        ...overviewScreenSelector(state, pagePath),
        bundleMap: state.localization.bundleMap,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getReports: (payload: reportingOverviewRequestPayload) =>
            dispatch(reportingActions.getReportsOverview(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        getRecentlyAddedToPlaylist: (payload: recentlyAddedToPlaylistPayload) =>
            dispatch(catalogActions.getRecentlyAddedToPlaylists(payload)),
        getAlbumReleases: (payload: listAlbumsToPitchPayload) =>
            dispatch(pitchActions.listAlbumsToPitch(payload)),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ReportsOverviewScreen)
);
