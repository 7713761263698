import * as React from "react";
import * as rootStyles from "../../styles";
import { Row, Col } from "react-bootstrap";
import { styledTitle } from "../common/Title";

interface ViewProps {
    title: string;
    subtitle?: string;
    moreButtonDescription?: string;
    id: string;
}

export class ChartHeader extends React.Component<ViewProps> {
    render() {
        return (
            <Row style={headerContainer}>
                <Col id={`${this.props.id}-title`} style={textContainer}>
                    {this.props.title && (
                        <styledTitle.h4 style={title} key="title">
                            {this.props.title}
                        </styledTitle.h4>
                    )}
                </Col>
            </Row>
        );
    }
}

const headerContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "flex-start",
};
const textContainer: React.CSSProperties = {
    marginRight: rootStyles.spacers.mini,
    flex: 1,
    padding: 0,
};
const moreButton: React.CSSProperties = {
    width: 48,
    minWidth: 48,
    flex: 1,
    flexGrow: 0,
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "flex-end",
};

const title: React.CSSProperties = {
    paddingBottom: rootStyles.spacers.micro,
};
