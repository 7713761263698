import React from "react";
import { Row, Toast, ToastBody } from "react-bootstrap";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { ToastViewData } from "../../../models";
import { globalNotificationsActions } from "../../../store/actions";
import { RootState } from "../../../store/reducers";
import { globalToastControllerSelector } from "../../../store/selectors";
import * as rootStyles from "../../styles";
import { Icon } from "../common";

type StateProps = {
    toast?: ToastViewData;
};

type DispatchProps = {
    closeActiveToast: () => void;
};

type Props = StateProps & DispatchProps;

type State = {
    showToast: boolean;
};

class GlobalToastController extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showToast: false,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.toast && !this.props.toast) {
            // if it was present and isn't any longer, hide it
            this.hideToast();
        }

        if (prevProps.toast !== this.props.toast) {
            // if it wasn't present and now is, show it
            this.setState({ showToast: true });
        }
    }

    render() {
        const toast = this.props.toast;
        if (!toast) {
            return null;
        }

        return (
            <Toast
                id="toast"
                autohide={true}
                show={this.state.showToast}
                delay={3000}
                onClose={this.hideToast}
                style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "none",
                    top:
                        this.props.toast?.placement == "top"
                            ? rootStyles.spacers.huge
                            : undefined,
                    bottom:
                        this.props.toast?.placement == "bottom"
                            ? rootStyles.spacers.huge
                            : undefined,
                    backgroundColor: "transparent",
                    zIndex: 1000000,
                    display: "flex",
                    ...this.props.toast?.toastStyle,
                    boxShadow: "none",
                    border: "none",
                }}
            >
                <ToastBody
                    style={{
                        backgroundColor: rootStyles.colors.background,
                        padding: 0,
                        margin: "auto",
                        borderRadius: rootStyles.spacers.epic,
                        width: "auto",
                    }}
                >
                    <Row
                        style={{
                            width: "auto",
                            margin: 1,
                            display: "flex",
                            padding: rootStyles.spacers.base,
                            borderRadius: rootStyles.spacers.epic,
                            borderColor: rootStyles.colors.accent,
                            borderWidth: 1,
                            borderStyle: "solid",
                            backgroundColor: rootStyles.glassColors.accent2,
                            ...this.props.toast?.toastRowStyle,
                        }}
                    >
                        {toast.icon && (
                            <Icon
                                icon={toast.icon}
                                size={rootStyles.icons.small}
                                color={rootStyles.colors.primary}
                                style={{
                                    marginRight: rootStyles.spacers.base,
                                    alignSelf: "center",
                                }}
                                id={`${toast.id}_Icon`}
                            />
                        )}
                        <div style={rootStyles.textStyles.primary}>
                            {toast.toastText}
                        </div>
                    </Row>
                </ToastBody>
            </Toast>
        );
    }

    private hideToast = () => {
        this.setState({ showToast: false });
        setTimeout(this.props.closeActiveToast, 200);
    };
}

function mapStateToProps(state: RootState): StateProps {
    return globalToastControllerSelector(state);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        closeActiveToast: () =>
            dispatch(globalNotificationsActions.closeActiveToast()),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalToastController);
