import { LocalizedUrls, stringIds } from "@amzn/ziggy-asset";
import * as React from "react";
import { Modal } from "react-bootstrap";
import { BlurredModal, styledTitle } from "..";
import { getLocalizedString } from "../../../../utils";
import * as rootStyles from "../../../styles";
import { MediumOutlineButton, MediumSolidButton } from "../input/Buttons";
import { CookieTextWithHyperLink } from "../../cookieConsent";
import { cookieModalText } from "../../../../models";

const testIDPrefix = "CookieConsentModal";

type CookieConsentModalProps = {
    onAcceptCookie: () => void;
    onRejectCookie: () => void;
    onCustomizeCookie: () => void;
    cookieModalText?: cookieModalText;
    isVisible: boolean;
};

export class CookieConsentModal extends React.Component<CookieConsentModalProps> {
    render() {
        return (
            <BlurredModal
                id="blurred-modal"
                show={this.props.isVisible}
                centered
                maxwidth={950}
                top="30%"
            >
                <Modal.Body
                    style={{
                        ...rootStyles.containerStyles.modalBody,
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <styledTitle.h4
                            style={{
                                marginTop: rootStyles.spacers.huge,
                            }}
                        >
                            {this.props.cookieModalText?.headerText}
                        </styledTitle.h4>

                        <div
                            style={{
                                marginTop: rootStyles.spacers.base,
                            }}
                        >
                            <CookieTextWithHyperLink
                                inputText={
                                    this.props.cookieModalText?.legaleseText ||
                                    ""
                                }
                                testID={`${testIDPrefix}_CookieLegaleseText`}
                            />
                        </div>

                        <div
                            style={{
                                flex: 1,
                                minHeight: rootStyles.spacers.large,
                            }}
                        />

                        <MediumSolidButton
                            buttonStyle={{
                                width: "100%",
                            }}
                            onClick={this.props.onAcceptCookie}
                            title={this.props.cookieModalText?.acceptButtonText}
                            id={`${testIDPrefix}_AcceptCookieButton`}
                        />
                        <MediumOutlineButton
                            buttonStyle={{
                                width: "100%",
                                marginTop: rootStyles.spacers.medium,
                                alignSelf: "center",
                            }}
                            onClick={this.props.onRejectCookie}
                            title={this.props.cookieModalText?.denyButtonText}
                            id={`${testIDPrefix}_RejectCookieButton`}
                        />
                        <span
                            onClick={this.props.onCustomizeCookie}
                            style={{
                                ...rootStyles.textStyles.label,
                                textTransform: "none",
                                color: rootStyles.colors.primary,
                                marginTop: rootStyles.spacers.medium,
                                alignSelf: "center",
                            }}
                            id={`${testIDPrefix}_CustomizeCookieButton`}
                        >
                            {this.props.cookieModalText?.customizeButtonText}
                        </span>
                    </div>
                </Modal.Body>
            </BlurredModal>
        );
    }
}
