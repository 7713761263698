import { createAction } from "redux-ts";
import {
    getTeamResponse,
    inviteTeamMemberPayload,
    getTeamPayload,
    setPermissionsPayload,
    removeMemberPayload,
    setMemberInfoPayload,
    promoteToOwnerPayload,
    acceptTeamInvitePayload,
    cancelInvitePayload,
    listInvitePayload,
    getInvitedMemberResponse,
    checkTeamExistsRequest,
} from "../../models";

import { ModifyMemberType } from "../reducers";

export const inviteTeamMember = createAction<inviteTeamMemberPayload>(
    "TEAM_MANAGEMENT::INVITE_TEAM_MEMBER"
);
export const inviteTeamMemberInProgress = createAction<boolean>(
    "TEAM_MANAGEMENT::INVITE_TEAM_MEMBER_IN_PROGRESS"
);
export const inviteTeamMemberCompleted = createAction<boolean>(
    "TEAM_MANAGEMENT::INVITE_TEAM_MEMBER_COMPLETED"
);
export const setInviteSent = createAction<boolean>(
    "TEAM_MANAGEMENT::SET_INVITE_SENT"
);

export const cancelInvite = createAction<cancelInvitePayload>(
    "TEAM_MANAGEMENT::CANCEL_INVITE"
);

export const getTeamMembers = createAction<getTeamPayload>(
    "TEAM_MANAGEMENT::GET_TEAM_MEMBERS"
);
export const getTeamMembersInProgress = createAction<boolean>(
    "TEAM_MANAGEMENT::GET_TEAM_MEMBERS_IN_PROGRESS"
);
export const getTeamMembersCompleted = createAction<getTeamResponse>(
    "TEAM_MANAGEMENT::GET_TEAM_MEMBERS_COMPLETED"
);

export const setPermissions = createAction<setPermissionsPayload>(
    "TEAM_MANAGEMENT::SET_PERMISSIONS"
);
export const setMemberInfo = createAction<setMemberInfoPayload>(
    "TEAM_MANAGEMENT::SET_MEMBER_INFO"
);
export const modifyMemberInProgress = createAction<boolean>(
    "TEAM_MANAGEMENT::MODIFY_MEMBER_IN_PROGRESS"
);
export const modifyMemberCompleted = createAction<boolean>(
    "TEAM_MANAGEMENT::MODIFY_MEMBER_COMPLETED"
);
export const setModifyMemberType = createAction<ModifyMemberType>(
    "TEAM_MANAGEMENT::SET_MODIFY_MEMBER_TYPE"
);

export const removeMember = createAction<removeMemberPayload>(
    "TEAM_MANAGEMENT::REMOVE_MEMBER"
);

export const promoteToOwner = createAction<promoteToOwnerPayload>(
    "TEAM_MANAGEMENT::PROMOTE_TO_OWNER"
);

export const clearState = createAction("TEAM_MANAGEMENT::CLEAR_STATE");

export const acceptTeamInvite = createAction<acceptTeamInvitePayload>(
    "TEAM_MANAGEMENT::ACCEPT_TEAM_INVITE"
);
export const acceptTeamInviteInProgess = createAction<boolean>(
    "TEAM_MANAGEMENT::ACCEPT_TEAM_INVITE_IN_PROGGRESS"
);
export const acceptTeamInviteCompleted = createAction<boolean>(
    "TEAM_MANAGEMENT::ACCEPT_TEAM_INVITE_COMPLETED"
);

export const getInvitedMembers = createAction<listInvitePayload>(
    "TEAM_MANAGEMENT::GET_INVITED_MEMBERS"
);
export const getInvitedMembersInProgress = createAction<boolean>(
    "TEAM_MANAGEMENT::GET_INVITED_MEMBERS_IN_PROGRESS"
);
export const getInvitedMembersCompleted = createAction<getInvitedMemberResponse>(
    "TEAM_MANAGEMENT::GET_INVITED_MEMBERS_COMPLETED"
);

export const setInvitePaginationToken = createAction<getInvitedMemberResponse>(
    "TEAM_MANAGEMENT::SET_PAGINATION_TOKEN"
);

export const checkTeamExists = createAction<checkTeamExistsRequest>(
    "TEAM_MANAGEMENT::CHECK_TEAM_EXISTS"
);
export const checkTeamExistsInProgress = createAction<boolean>(
    "TEAM_MANAGEMENT::CHECK_TEAM_EXISTS_IN_PROGRESS"
);
export const checkTeamExistsResponse = createAction<boolean>(
    "TEAM_MANAGEMENT::CHECK_TEAM_EXISTS_RESPONSE"
);
