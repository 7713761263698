import * as React from "react";
import { connect } from "react-redux";
import { Col, Row, Container, Dropdown } from "react-bootstrap";
import { Dispatch, AnyAction } from "redux";
import _ from "lodash";
import {
    teamManagementMember,
    TeamRole,
    TeamPermissions,
    METRIC_KEYS,
    telemetryPayload,
    removeMemberPayload,
    cookieStrings,
    cancelInvitePayload,
    ToastViewData,
    BundleMap,
} from "../../../models";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as rootStyles from "../../styles";
import {
    paths,
    getLocalizedString,
    translateTeamRoleToString,
    testIDSuffixes,
    translateJobTitleToString,
} from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import {
    LargeSolidButton,
    IconsList,
    Loading,
    Search,
    styledTitle,
    Icon,
} from "../../components";
import {
    RootState,
    teamManagementActions,
    currentMembersScreenSelector,
    userActions,
    telemetryActions,
} from "../../../store";
import { getTeamPayload, listInvitePayload } from "../../../models";
import { CustomListItem, CustomListHeader } from "../../components/lists";
import { dropDownStyles } from "../../styles/buttonStyles";
import { globalNotificationsActions } from "../../../store/actions";
import Cookies from "js-cookie";
import styled from "styled-components";

const testIDPrefix = "CurrentMembersScreen";
const metricPrefix = "currentMembersPage";

type ViewProps = {};

type StateProps = {
    teamMembers?: teamManagementMember[];
    invitedMembers?: teamManagementMember[];
    getTeamInProgress: boolean;
    getInvitedMembersInProgress: boolean;
    userId: string;
    teamId: string;
    token: string;
    userPermissions: TeamPermissions[];
    paginationToken: string;
    bundleMap: BundleMap;
};

type DispatchProps = {
    getTeam: (payload: getTeamPayload) => void;
    registerUser: () => void;
    getInvitedMembers: (payload: listInvitePayload) => void;
    submitRemove: (data: removeMemberPayload) => void;
    cancelInvite: (data: cancelInvitePayload) => void;
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
    showInviteCopiedToast: (payload: ToastViewData) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    searchText: string;
    refreshing: boolean;
};

class CurrentMembers extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            searchText: "",
            refreshing: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.currentTeamMembers],
            ]),
        });
        if (!this.props.teamId) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }
        this.loadTeams();
    }

    componentDidUpdate(prevStateProps: Props) {
        if (
            (this.props.teamMembers !== prevStateProps.teamMembers ||
                this.props.invitedMembers !== prevStateProps.invitedMembers) &&
            !this.props.getTeamInProgress &&
            !this.props.getInvitedMembersInProgress
        ) {
            this.setState({ searchText: "", refreshing: false });
        }
    }

    render() {
        const tableLabels = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                stringId: stringIds.ManageCurrentTeam.userHeader,
            }),
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                stringId: stringIds.ManageCurrentTeam.roleHeader,
            }),
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                stringId: stringIds.ManageCurrentTeam.accessHeader,
            }),
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                stringId: stringIds.ManageCurrentTeam.manageHeader,
            }),
        ];

        const state: any = this.props.location.state;

        const sorted = this.getSortedTeamMembers();
        const contentRow = sorted.map(
            (member: teamManagementMember, index: number) =>
                this.renderItem(member, index)
        );
        const showInvite =
            ((this.props.teamMembers && this.props.teamMembers.length > 0) ||
                (this.props.invitedMembers &&
                    this.props.invitedMembers.length > 0)) &&
            ((!this.props.getTeamInProgress &&
                !this.props.getInvitedMembersInProgress) ||
                this.state.refreshing);

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    // height: "100%",
                }}
            >
                {(this.props.getTeamInProgress ||
                    this.props.getInvitedMembersInProgress) &&
                    !this.state.refreshing && <Loading />}
                {showInvite && (
                    <div style={{ flex: 1 }}>
                        <HeaderContainer style={{}}>
                            <Col>
                                <styledTitle.h2>
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.MANAGECURRENTTEAM_STRINGS,
                                        stringId:
                                            stringIds.ManageCurrentTeam
                                                .manageTeamHeader,
                                    })}
                                </styledTitle.h2>
                            </Col>
                            <Col md={12} lg={6}>
                                <Search
                                    value={this.state.searchText}
                                    placeholder={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.MANAGECURRENTTEAM_STRINGS,
                                            stringId:
                                                stringIds.ManageCurrentTeam
                                                    .searchPlaceholderText,
                                        }
                                    )}
                                    onChange={this.onTextChanged}
                                    id={testIDPrefix + testIDSuffixes.searchbar}
                                />
                            </Col>
                        </HeaderContainer>
                        <Row>
                            {this.userHasPermission(
                                TeamPermissions.SendInvite
                            ) && (
                                <Col xs={12} md={4}>
                                    <LargeSolidButton
                                        containerStyle={{ minWidth: 250 }}
                                        title={getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.MANAGECURRENTTEAM_STRINGS,
                                                stringId:
                                                    stringIds.ManageCurrentTeam
                                                        .inviteTeamMember,
                                            }
                                        )}
                                        onClick={this.onClickInvite}
                                        id={
                                            testIDPrefix + "_InviteMemberButton"
                                        }
                                    />
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col style={{ padding: 0 }}>
                                <CustomListHeader
                                    primary={tableLabels[0]}
                                    secondary={tableLabels[1]}
                                    stat2={tableLabels[2]}
                                    stat3={tableLabels[3]}
                                    id={`${testIDPrefix}_TeamMemberHeader`}
                                />
                            </Col>
                        </Row>
                        <Row style={{ flex: 1 }}>
                            <div style={{ flex: 1 }}>{contentRow}</div>
                        </Row>
                    </div>
                )}
            </Container>
        );
    }

    private loadTeams = () => {
        this.props.getTeam({
            teamId: this.props.teamId,
            requestPath: paths.currentTeamMembers,
        });

        this.props.getInvitedMembers({
            teamId: this.props.teamId,
            requestPath: paths.invitedTeamMembers,
            paginationToken: this.props.paginationToken,
        });

        this.setState({ refreshing: true });
    };

    private onClickInvite = () => {
        this.props.userAction({
            name: metricPrefix + "InviteButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.currentTeamMembers],
            ]),
        });
        this.props.history?.push(paths.inviteTeamMember);
    };

    private onTextChanged = (text: any) => {
        this.setState({
            searchText: text.target.value ? text.target.value : "",
        });
    };

    private getSortedTeamMembers = () => {
        if (!this.props.teamMembers && !this.props.invitedMembers) {
            return [];
        }

        let allMembers;
        if (!this.props.teamMembers) {
            allMembers = this.props.invitedMembers;
        } else if (!this.props.invitedMembers) {
            allMembers = this.props.teamMembers;
        } else {
            allMembers = this.props.teamMembers.concat(
                this.props.invitedMembers
            );
        }

        const searchText = this.state.searchText || "";
        const filtered: Array<teamManagementMember> = _.filter(
            allMembers,
            (d) => {
                if (
                    d.name?.toUpperCase().indexOf(searchText.toUpperCase()) !==
                    -1
                ) {
                    return true;
                }

                if (
                    d.invite &&
                    d.invite.email
                        .toUpperCase()
                        .indexOf(searchText.toUpperCase()) !== -1
                ) {
                    return true;
                }

                return false;
            }
        );
        return filtered.sort(this.orderTeamMembers);
    };

    private orderTeamMembers: (
        a: teamManagementMember,
        b: teamManagementMember
    ) => number = (a: teamManagementMember, b: teamManagementMember) => {
        if (a.invite && !b.invite) {
            return -1;
        } else if (!a.invite && b.invite) {
            return 1;
        } else {
            if (a.access === b.access) {
                return a.name < b.name ? -1 : 1;
            }
            return this.compareTeamRole(a.access, b.access);
        }
    };

    private compareTeamRole(a: TeamRole, b: TeamRole) {
        if (a === TeamRole.Owner) {
            return -1;
        } else if (a === TeamRole.Admin) {
            return b === TeamRole.Viewer || b === TeamRole.Custom ? -1 : 1;
        } else if (a === TeamRole.Viewer) {
            return b === TeamRole.Custom ? -1 : 1;
        }
        return 1;
    }

    private renderItem = (item: teamManagementMember, index: number) => {
        const rightView = this.getRightView(item);

        return (
            <CustomListItem
                key={index.toString()}
                primary={item.name}
                secondary={item.company}
                tertiary={item.invite?.email}
                stat2={translateJobTitleToString(
                    item.jobTitle,
                    this.props.bundleMap
                )}
                stat3={this.getAccess(item)}
                stat3sub={
                    item.invite ? this.getDate(item.invite.date) : undefined
                }
                rightElement={rightView}
                id={`${testIDPrefix}_TeamMemberRow_${index}`}
                touchable={false}
            />
        );
    };

    private getAccess(teamMember: teamManagementMember) {
        if (teamMember.invite) {
            return getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                    stringId: stringIds.ManageCurrentTeam.invitedString,
                },
                {
                    "0": translateTeamRoleToString(
                        teamMember.access,
                        this.props.bundleMap
                    ),
                }
            );
        }
        return translateTeamRoleToString(
            teamMember.access,
            this.props.bundleMap
        );
    }

    private onClickModify(member: teamManagementMember) {
        this.props.history?.push(
            `${paths.manageTeamMember}/${member.memberId}`,
            { invitedMember: member }
        );
    }

    private pressSubmitRemove(member: teamManagementMember) {
        this.props.userAction({
            name: metricPrefix + "SubmitRemoveButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.invitedTeamMembers],
            ]),
        });

        if (!member) {
            return;
        }

        if (this.userHasPermission(TeamPermissions.RemoveMember)) {
            this.props.submitRemove({
                teamId: this.props.teamId,
                memberId: member.memberId,
                requestPath: paths.inviteTeamMember,
            });
        }
    }

    private pressCopyInvite = (member: teamManagementMember) => {
        this.props.userAction({
            name: metricPrefix + "CopyInviteButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.invitedTeamMembers],
            ]),
        });

        if (!member || !member.invite || !member.invite.inviteUrl) {
            return;
        }

        navigator.clipboard.writeText(member.invite.inviteUrl);

        this.props.showInviteCopiedToast({
            toastText: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.MANAGECURRENTTEAM_STRINGS,
                stringId: stringIds.ManageCurrentTeam.copiedString,
            }),
            icon: IconsList.action_done,
            placement: "bottom",
            toastStyle: {
                border: "none",
                boxShadow: "none",
            },
            toastRowStyle: {
                paddingRight: rootStyles.spacers.large,
            },
            id: `${testIDPrefix}_InviteLinkCopiedToast`,
        });
    };

    private pressCancelInvite(member: teamManagementMember) {
        this.props.userAction({
            name: metricPrefix + "SubmitCancelInviteButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.teamId, this.props.teamId],
                [METRIC_KEYS.page, paths.invitedTeamMembers],
            ]),
        });

        if (!member) {
            return;
        }
        if (!member.invite) {
            return;
        }

        if (this.userHasPermission(TeamPermissions.RemoveMember)) {
            this.props.cancelInvite({
                teamId: this.props.teamId,
                email: member.invite.email,
                requestPath: paths.inviteTeamMember,
            });
        }
    }

    private hasUpdatePermission = () => {
        return (
            this.userHasPermission(TeamPermissions.SetPermissions) &&
            this.userHasPermission(TeamPermissions.SetMemberInfo)
        );
    };

    private userHasPermission(teamPermission: TeamPermissions) {
        return (
            this.props.userPermissions &&
            this.props.userPermissions.includes(teamPermission)
        );
    }

    private getRightView = (teamMember: teamManagementMember) => {
        return (
            <Col style={{ padding: 0 }}>
                <Dropdown
                    style={{ ...dropDownStyles.dropdown }}
                    alignRight={true}
                >
                    <Dropdown.Toggle
                        id="manage"
                        className="manageMemberDropdown"
                        style={{
                            backgroundColor: rootStyles.colors.background,
                            borderWidth: 0,
                            marginLeft: 13,
                            marginRight: 13,
                        }}
                    >
                        <Icon
                            icon={IconsList.action_more}
                            size={24}
                            id={`${testIDPrefix}_ManageMemberDropdown`}
                        />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        style={{ marginTop: 10, marginRight: -24, width: 300 }}
                    >
                        {(this.hasUpdatePermission() && (
                            <Dropdown.Item
                                key={"Edit"}
                                eventKey={"Edit"}
                                onSelect={() => this.onClickModify(teamMember)}
                                style={dropDownStyles.dropdownItem}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Icon
                                        icon={IconsList.action_edit}
                                        size={rootStyles.icons.medium}
                                        style={{
                                            marginRight:
                                                rootStyles.spacers.small,
                                        }}
                                        id={`${testIDPrefix}_EditMemberIcon`}
                                    />
                                    <span>
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.MANAGECURRENTTEAM_STRINGS,
                                                stringId:
                                                    stringIds.ManageCurrentTeam
                                                        .editMemberTitle,
                                            }
                                        )}
                                    </span>
                                </div>
                            </Dropdown.Item>
                        )) || (
                            <Dropdown.Item
                                key={"View"}
                                eventKey={"View"}
                                onSelect={() => this.onClickModify(teamMember)}
                                style={dropDownStyles.dropdownItem}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Icon
                                        icon={IconsList.action_view}
                                        size={rootStyles.icons.medium}
                                        style={{
                                            marginRight:
                                                rootStyles.spacers.small,
                                        }}
                                        id={`${testIDPrefix}_ViewMemberIcon`}
                                    />
                                    <span>
                                        {getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.MANAGECURRENTTEAM_STRINGS,
                                                stringId:
                                                    stringIds.ManageCurrentTeam
                                                        .viewMemberTitle,
                                            }
                                        )}
                                    </span>
                                </div>
                            </Dropdown.Item>
                        )}
                        {teamMember.access != TeamRole.Owner &&
                            !teamMember.invite &&
                            this.userHasPermission(
                                TeamPermissions.RemoveMember
                            ) &&
                            teamMember.memberId !==
                                Cookies.get(cookieStrings.userId) && (
                                <Dropdown.Item
                                    key={"RevokeAccess"}
                                    eventKey={"RevokeAccess"}
                                    onSelect={() =>
                                        this.pressSubmitRemove(teamMember)
                                    }
                                    style={dropDownStyles.dropdownItem}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Icon
                                            icon={IconsList.action_removeInline}
                                            size={rootStyles.icons.medium}
                                            style={{
                                                marginRight:
                                                    rootStyles.spacers.small,
                                            }}
                                            id={`${testIDPrefix}_RemoveMemberIcon`}
                                        />
                                        <span>
                                            {getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.MANAGECURRENTTEAM_STRINGS,
                                                    stringId:
                                                        stringIds
                                                            .ManageCurrentTeam
                                                            .revokeAccess,
                                                }
                                            )}
                                        </span>
                                    </div>
                                </Dropdown.Item>
                            )}
                        {teamMember.access != TeamRole.Owner &&
                            teamMember.invite &&
                            this.userHasPermission(
                                TeamPermissions.CancelInvite
                            ) &&
                            teamMember.memberId !==
                                Cookies.get(cookieStrings.userId) && (
                                <Dropdown.Item
                                    key={"CancelInvite"}
                                    eventKey={"CancelInvite"}
                                    onSelect={() =>
                                        this.pressCancelInvite(teamMember)
                                    }
                                    style={dropDownStyles.dropdownItem}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Icon
                                            icon={IconsList.action_removeInline}
                                            size={rootStyles.icons.medium}
                                            style={{
                                                marginRight:
                                                    rootStyles.spacers.small,
                                            }}
                                            id={`${testIDPrefix}_RemoveMemberIcon`}
                                        />
                                        <span>
                                            {getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.MANAGECURRENTTEAM_STRINGS,
                                                    stringId:
                                                        stringIds
                                                            .ManageCurrentTeam
                                                            .cancelInvite,
                                                }
                                            )}
                                        </span>
                                    </div>
                                </Dropdown.Item>
                            )}
                        {teamMember.access != TeamRole.Owner &&
                            teamMember.invite &&
                            this.userHasPermission(
                                TeamPermissions.SendInvite
                            ) &&
                            teamMember.memberId !==
                                Cookies.get(cookieStrings.userId) && (
                                <Dropdown.Item
                                    key={"CopyInvite"}
                                    eventKey={"CopyInvite"}
                                    onSelect={() =>
                                        this.pressCopyInvite(teamMember)
                                    }
                                    style={dropDownStyles.dropdownItem}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Icon
                                            icon={IconsList.ic_hyperlink}
                                            size={rootStyles.icons.medium}
                                            style={{
                                                marginRight:
                                                    rootStyles.spacers.small,
                                            }}
                                            id={`${testIDPrefix}_CopyInviteMemberIcon`}
                                        />
                                        <span>
                                            {getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.MANAGECURRENTTEAM_STRINGS,
                                                    stringId:
                                                        stringIds
                                                            .ManageCurrentTeam
                                                            .copyInvite,
                                                }
                                            )}
                                        </span>
                                    </div>
                                </Dropdown.Item>
                            )}
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        );
    };

    private getDate = (date: Date) => {
        return new Date(date).toLocaleDateString();
    };
}

const HeaderContainer = styled(Row)`
    margin-top: 72px; /* Matches the header even though we can't use it here */
    margin-bottom: ${rootStyles.spacers.large}px;
    align-items: center;
    @media (max-width: 992px) {
        margin-top: 80px;
    }
`;

function mapStateToProps(state: RootState) {
    return currentMembersScreenSelector(state);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        getTeam: (request: getTeamPayload) =>
            dispatch(teamManagementActions.getTeamMembers(request)),
        registerUser: () => dispatch(userActions.registerUser()),
        getInvitedMembers: (request: listInvitePayload) =>
            dispatch(teamManagementActions.getInvitedMembers(request)),
        submitRemove: (payload: removeMemberPayload) =>
            dispatch(teamManagementActions.removeMember(payload)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        cancelInvite: (payload: cancelInvitePayload) =>
            dispatch(teamManagementActions.cancelInvite(payload)),
        showInviteCopiedToast: (toastViewData: ToastViewData) =>
            dispatch(
                globalNotificationsActions.requestToastView(toastViewData)
            ),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CurrentMembers)
);
