import React, { Dispatch, useCallback, useRef, useState } from "react";
import * as rootStyles from "../../styles";
import {
    IconsList,
    InlineError,
    LargeGlassButton,
    LargeOutlineButton,
    LargeSolidButton,
    Loading,
    styledTitle,
} from "../../../view/components";
import { RootState, promoCardActions } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import {
    BundleMap,
    EntityType,
    promoCardSelectedContent,
} from "../../../models";
import { PromoCardStep, getLocalizedString } from "../../../utils";
import { bundleIds, stringIds } from "../../../assets";

type ShareCardOptionsProps = {
    promocardShareLink: string;
    onClickDownload?: () => void;
    downloadInProgress: boolean;
    bundleMap: BundleMap;
    selectedContent?: promoCardSelectedContent;
    onDismissNonShareableContentError?: () => void;
};

const testIDPrefix = "ShareCardOptions";

export const ShareCardOptions: React.FC<ShareCardOptionsProps> = ({
    promocardShareLink,
    onClickDownload,
    downloadInProgress,
    bundleMap,
    selectedContent,
    onDismissNonShareableContentError,
}) => {
    const dispatch: Dispatch<AnyAction> = useDispatch();

    // Placeholder for the promoCard share link until backend work is done
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(promocardShareLink);
        setIsCopied(true);
    };

    const generatePromoCardShareLinkInProgress = useSelector(
        (state: RootState) => state.catalog.generatePromoCardShareLinkInProgress
    );

    const setStepIndex = useCallback(
        (step: number) => {
            dispatch(promoCardActions.selectStep(step));
        },
        [dispatch]
    );

    const onSelectBack = () => {
        setStepIndex(PromoCardStep.CUSTOMIZE);
    };

    return (
        <div
            style={{
                padding: rootStyles.spacers.huge,
            }}
        >
            {generatePromoCardShareLinkInProgress ? (
                <Loading />
            ) : (
                <>
                    <div>
                        <styledTitle.h4>
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.PROMO_CARD_STRINGS,
                                stringId: stringIds.PromoCard.cardShareTitle,
                            })}
                        </styledTitle.h4>
                    </div>
                    <div style={{ marginTop: rootStyles.spacers.large }}>
                        <span
                            style={{
                                ...rootStyles.textStyles.tertiary,
                            }}
                        >
                            {selectedContent?.type !== EntityType.Playlist
                                ? getLocalizedString(bundleMap, {
                                      bundleId: bundleIds.PROMO_CARD_STRINGS,
                                      stringId:
                                          stringIds.PromoCard
                                              .cardShareInstructionText,
                                  })
                                : getLocalizedString(bundleMap, {
                                      bundleId: bundleIds.PROMO_CARD_STRINGS,
                                      stringId:
                                          stringIds.PromoCard
                                              .playlistGrabLinkWeb,
                                  })}
                        </span>
                    </div>
                    {selectedContent?.type !== EntityType.Playlist &&
                        promocardShareLink && (
                            <div
                                style={{
                                    display: "flex",
                                    cursor: "pointer",
                                    marginTop: rootStyles.spacers.medium,
                                    width: "100%",
                                }}
                            >
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                    }}
                                >
                                    <input
                                        style={{
                                            backgroundColor:
                                                rootStyles.glassColors.primary1,
                                            ...rootStyles.textStyles.primary,
                                            borderRadius: "4px",
                                            borderColor:
                                                rootStyles.glassColors.primary3,
                                            height: "36px",
                                            width: "250px",
                                        }}
                                        type="text"
                                        value={promocardShareLink}
                                        readOnly
                                    />

                                    <div
                                        style={{
                                            width: rootStyles.spacers.small,
                                        }}
                                    ></div>

                                    <LargeGlassButton
                                        title={
                                            isCopied
                                                ? getLocalizedString(
                                                      bundleMap,
                                                      {
                                                          bundleId:
                                                              bundleIds.CONTENTSHARING_STRINGS,
                                                          stringId:
                                                              stringIds
                                                                  .ContentSharing
                                                                  .copyLinkCopiedText,
                                                      }
                                                  )
                                                : getLocalizedString(
                                                      bundleMap,
                                                      {
                                                          bundleId:
                                                              bundleIds.CONTENTSHARING_STRINGS,
                                                          stringId:
                                                              stringIds
                                                                  .ContentSharing
                                                                  .copyLinkButtonText,
                                                      }
                                                  )
                                        }
                                        onClick={handleCopyClick}
                                        id="CopyLinkButton"
                                        leftIcon={IconsList.ic_hyperlink}
                                    ></LargeGlassButton>
                                </div>
                            </div>
                        )}
                    {selectedContent?.type !== EntityType.Playlist &&
                        !promocardShareLink && (
                            <InlineError
                                text={getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                                    stringId:
                                        stringIds.PromoCard
                                            .nonShareableContentMessage,
                                })}
                                onDismiss={onDismissNonShareableContentError}
                                id={`${testIDPrefix}_Error`}
                            />
                        )}
                    <div
                        style={{
                            marginTop: rootStyles.spacers.giant,
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <LargeOutlineButton
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.GENERIC_STRINGS,
                                stringId: stringIds.Generic.goBack,
                            })}
                            onClick={onSelectBack}
                            id={`${testIDPrefix}_Back`}
                        />
                        <div style={{ width: rootStyles.spacers.large }}></div>
                        {downloadInProgress ? (
                            <LargeSolidButton
                                title={
                                    getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.PROMO_CARD_STRINGS,
                                        stringId:
                                            stringIds.PromoCard.downloading,
                                    }) + "..."
                                }
                                id={`${testIDPrefix}_Downloading`}
                                leftIcon={IconsList.action_download_black}
                                buttonStyle={{
                                    color: rootStyles.colors.secondary,
                                }}
                            />
                        ) : (
                            <LargeSolidButton
                                title={getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.PROMO_CARD_STRINGS,
                                    stringId: stringIds.PromoCard.downloadImage,
                                })}
                                onClick={onClickDownload}
                                id={`${testIDPrefix}_Download`}
                                leftIcon={IconsList.action_download_black}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
