export type CustomerSupportData = {
    intercomData?: IntercomData;
};

export type IntercomData = {
    appId: string;
    userHash: string;
    directedId: string;
};

export enum IntercomEvent {
    // special event to reset redux state, for sending repeating same event.
    NONE = "none",
    SHOW_VENDOR_LABEL_CANNOT_ACCESS_DISTRIBUTOR = "show_vendor_label_cannot_access_distribor",
    SHOW_VENDOR_LABEL_CREATE_A_TEAM = "show_vendor_label_create_a_team",
    SHOW_DEFAULT_BOT = "show_default_bot",
}
