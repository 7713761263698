import React from "react";
import axios from "axios";
import { Provider } from "react-redux";
import { MbmProvider } from "@amzn/react-arb-tools";
import "bootstrap/dist/css/bootstrap.min.css";
import { colors, spacers } from "./view/styles";
import store from "./store/store";
import RootNavigationController from "./view/containers/RootNavigationContainer";
import "./view/styles/global.css";
import { isProd } from "./service/common";
import ErrorBoundary from "./view/components/common/error/ErrorBoundary";
import { SwitchLocaleHandler } from "./view/components/common/SwitchLocaleHandler";
import { LogIn } from "./view/components/authentication";
import { messageBundleManager } from "../src/utils";
import CustomerSupport from "./view/components/customerSupport/CustomerSupport";

axios.defaults.withCredentials = true;

if (isProd) {
    try {
        console.log = function () {};
        console.debug = function () {};
        console.warn = function () {};
        console.info = function () {};
        console.error = function () {};
    } catch (er) {
        // Some browsers dont have windows.console, ignore.
    }
}

function App() {
    return (
        <Provider store={store}>
            <ErrorBoundary>
                <MbmProvider mbm={messageBundleManager}>
                    <SwitchLocaleHandler />
                    <div className="App" style={appStyle}>
                        <LogIn>
                            <RootNavigationController />
                            <CustomerSupport />
                        </LogIn>
                    </div>
                </MbmProvider>
            </ErrorBoundary>
        </Provider>
    );
}

const appStyle: React.CSSProperties = {
    minHeight: "100vh",
    width: "100vw",
    overflow: "hidden",
    backgroundColor: colors.background,
};

export default App;
