import { Action } from "redux-ts";
import { channel } from "redux-saga";
import { takeEvery, put, select, take } from "redux-saga/effects";
import _ from "lodash";
import {
    catalogActions,
    telemetryActions,
    errorActions,
    opsMetricsActions,
} from "../actions";
import * as services from "../../service";
import {
    catalog,
    METRIC_KEYS,
    hydrateCatalogPayload,
    recentlyAddedToPlaylistPayload,
    generateShareLinkPayload,
    shareContentResponse,
    CatalogItemType,
    RecentlyAddedToPlaylistResponse,
    ArtistMarqueePlaylists,
    getMarqueePlaylistsPayload,
    generatePromoCardShareLinkPayload,
} from "../../models";
import {
    checkIfNotEmpty,
    createSuccessOpsMetricsPayload,
    getCatalogItem,
    hydrationHelpers,
    HYDRATION_COUNT,
} from "../../utils";
import { RootState, CatalogState } from "../reducers";
import { selectedTeamIdSelector } from "../selectors";
import { startTimer, publishTimer, publishCounter } from "../../service";
import { getHydration, Hydrator } from "../../utils/hydrationFactory";
import { AnyAction } from "redux";

export const catalogSagas = [
    watchHydrateAsins(),
    watchGetRecentlyAddedToPlaylists(),
    watchGenerateShareLink(),
    watchPublishCatalog(),
    watchGetMarqueePlaylists(),
    watchGenerateSharePromoCardShareLink(),
];

const updateCatalogChannel = channel();

function* hydrateAsins(action: Action<hydrateCatalogPayload>) {
    const start = Date.now();
    const asinsType = _.capitalize(action.payload.type.toString());
    const functionName = `hydrate${asinsType}Asins`;
    const timerMetric = startTimer(`hydrate${asinsType}AsinsDuration`);
    try {
        if (!action.payload.asins || action.payload.asins.length === 0) {
            return;
        }

        yield put(catalogActions.hydrationInProgress(true));
        console.log(
            "Hydrating " +
                action.payload.asins.length +
                " asins for " +
                action.payload.type.toString()
        );
        yield put(
            telemetryActions.appEvent({
                name: `hydrateAsinsStart`,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.type, `${asinsType}`],
                    [METRIC_KEYS.count, `${action.payload.asins.length}`],
                ]),
            })
        );

        const catalogState: CatalogState = yield select(
            (state: RootState) => state.catalog
        );
        const catalog = catalogState.catalog;
        const hydratingAsins = catalogState.hydratingAsins;
        const teamId: string = yield select((state: RootState) =>
            selectedTeamIdSelector(state)
        );

        let asins = [
            ...new Set(
                action.payload.asins.filter((asin) => {
                    // Make sure asin isnt null or undefined && we already don't have the asin hydrated
                    // Exception for artist to get updated artist image
                    return (
                        asin !== "" &&
                        checkIfNotEmpty(asin) &&
                        (action.payload.type === CatalogItemType.Artists ||
                            // Adding asin check here because some internal metadata populates before other metadata
                            !getCatalogItem(asin, catalog)?.asin) &&
                        !hydratingAsins.includes(asin)
                    );
                })
            ),
        ];

        if (asins.length === 0) {
            console.log("Dont need to hydrate");
            yield put(catalogActions.hydrationInProgress(false));
            return;
        }

        asins = _.slice(asins, 0, HYDRATION_COUNT);
        yield put(catalogActions.hydratingAsins(asins));

        let locale: string;
        if (action.payload.locale) {
            locale = action.payload.locale;
        } else {
            locale = yield select((state: RootState) => state.user.locale);
        }
        publishTimer(timerMetric);

        const hydrater: Hydrator = getHydration(action.payload.type);
        const { newCatalog, unhydratedArtists, unhydratedAlbums } =
            yield hydrater({
                asins: asins,
                teamId: teamId,
                titleSetAsinToGlobalAsinMap:
                    action.payload.titleSetAsinToGlobalAsinMap,
                catalogState,
                locale,
                catalogPublish: (updatedCatalog: catalog) => {
                    updateCatalogChannel.put({
                        type: catalogActions.buildCatalogCompleted.type,
                        payload: updatedCatalog,
                    });
                },
            });

        if (unhydratedArtists && unhydratedArtists.size !== 0) {
            yield put(
                catalogActions.hydrateAsins({
                    asins: Array.from(unhydratedArtists),
                    type: CatalogItemType.Artists,
                    locale: locale,
                })
            );
        }

        if (unhydratedAlbums && unhydratedAlbums?.size !== 0) {
            yield put(
                catalogActions.hydrateAsins({
                    asins: Array.from(unhydratedAlbums),
                    type: CatalogItemType.Albums,
                    locale: locale,
                })
            );
        }

        // All the asins we didnt get back or we got bask as null/undefined should be stored as failed asins
        const failedAsins = hydrationHelpers.getFailedAsins(
            asins,
            newCatalog,
            action.payload.type
        );
        failedAsins.filter((asin) => !catalog.get(asin));

        if (failedAsins.length > 0) {
            // Emit failed asins metric
            publishCounter(
                `hydrate${asinsType}AsinsFailure`,
                failedAsins.length
            );
        }

        yield put(catalogActions.hydrationInProgress(false));

        // Emit succeeded asins metric
        publishCounter(
            `hydrate${asinsType}AsinsSuccess`,
            asins.length - failedAsins.length
        );

        const failureRate: number = failedAsins.length
            ? Math.round((failedAsins.length * 100) / asins.length)
            : 0;

        // This failure rate is per batch, this can help identify particular user having a high failure rate
        // not for calculating the overall failure rate.
        publishCounter(`hydrate${asinsType}FailureRate`, failureRate);

        console.log(
            "Hydration Complete with " +
                newCatalog.size +
                " asins for " +
                asinsType
        );

        yield put(
            telemetryActions.appEvent({
                name: `hydrateAsinsEnd`,
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.type, `${asinsType}`],
                    [METRIC_KEYS.count, `${action.payload.asins.length}`],
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.type, `${asinsType}`],
            [METRIC_KEYS.count, `${action.payload.asins.length}`],
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        yield put(catalogActions.failedAsins(action.payload.asins));

        publishCounter(
            `hydrate${asinsType}AsinsFailure`,
            action.payload.asins.length
        );

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* watchPublishCatalog() {
    while (true) {
        const action: AnyAction = yield take(updateCatalogChannel);
        yield put(action);
    }
}

function* getRecentlyAddedToPlaylists(
    action: Action<recentlyAddedToPlaylistPayload>
) {
    const start = Date.now();
    const functionName = "getRecentlyAddedToPlaylists";
    const timerMetric = startTimer("getRecentlyAddedToPlaylistsDuration");
    try {
        if (!action.payload.artistAsin) {
            return;
        }

        yield put(
            telemetryActions.appEvent({
                name: "getRecentlyAddedToPlaylistsStart",
                dataPoints: new Map<string, string | undefined>([]),
            })
        );

        yield put(catalogActions.getRecentlyAddedToPlaylistsInProgress(true));
        yield put(errorActions.clearError(action.payload.requestPath));

        const artistAsin = action.payload.artistAsin;
        const recentlyAddedResponse: RecentlyAddedToPlaylistResponse =
            yield services.fetchRecentlyAddedToPlaylist(
                artistAsin,
                action.payload.teamId
            );

        yield put(
            catalogActions.hydrateAsins({
                asins: _.map(
                    recentlyAddedResponse.recentlyAdded,
                    (track) => track.playlistSeriesAsin
                ),
                locale: action.payload.locale,
                type: CatalogItemType.Playlists,
            })
        );

        yield put(
            catalogActions.hydrateAsins({
                asins: _.map(
                    recentlyAddedResponse.recentlyAdded,
                    (track) => track.trackTitlesetAsin
                ),
                locale: action.payload.locale,
                type: CatalogItemType.Tracks,
            })
        );

        yield put(
            catalogActions.getRecentlyAddedToPlaylistsComplete({
                artistAsin,
                recentlyAdded: recentlyAddedResponse.recentlyAdded,
            })
        );

        yield put(catalogActions.getRecentlyAddedToPlaylistsInProgress(false));

        publishTimer(timerMetric);

        yield put(
            telemetryActions.appEvent({
                name: "getRecentlyAddedToPlaylistsEnd",
                dataPoints: new Map<string, string | undefined>([
                    [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
                ]),
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);
        yield put(catalogActions.getRecentlyAddedToPlaylistsInProgress(false));

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getMarqueePlaylists(action: Action<getMarqueePlaylistsPayload>) {
    const start = Date.now();
    const functionName = "getMarqueePlaylists";
    try {
        if (!action.payload.artistAsin) {
            return;
        }

        const artistAsin = action.payload.artistAsin;

        const marqueePlaylistsResponse: ArtistMarqueePlaylists =
            yield services.getMarqueePlaylists(action.payload);

        yield put(
            catalogActions.hydrateAsins({
                asins: _.map(
                    marqueePlaylistsResponse.tracks,
                    (track) => track.playlistAsin
                ),
                locale: action.payload.locale,
                type: CatalogItemType.Playlists,
            })
        );

        yield put(
            catalogActions.hydrateAsins({
                asins: _.map(
                    marqueePlaylistsResponse.tracks,
                    (track) => track.trackAsin
                ),
                locale: action.payload.locale,
                type: CatalogItemType.Tracks,
            })
        );

        yield put(
            catalogActions.getMarqueePlaylistsComplete({
                artistAsin,
                marqueePlaylists: marqueePlaylistsResponse,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* watchGetMarqueePlaylists() {
    yield takeEvery(
        catalogActions.getMarqueePlaylists.type,
        getMarqueePlaylists
    );
}

const getGlobalAsinsToQuery = (
    titleSetAsinToGlobalAsinMap: Map<string, string>,
    failedAsins: string[]
) => {
    const globalAsinToTitleSetAsinsToQuery = new Map<string, string>();
    titleSetAsinToGlobalAsinMap?.forEach((value, key) => {
        if (failedAsins.includes(key)) {
            // inverting the key and value makes this easier to reverse-lookup after service call.
            globalAsinToTitleSetAsinsToQuery.set(value, key);
        }
    });

    return globalAsinToTitleSetAsinsToQuery;
};

function* watchHydrateAsins() {
    yield takeEvery(catalogActions.hydrateAsins.type, hydrateAsins);
}

function* watchGetRecentlyAddedToPlaylists() {
    yield takeEvery(
        catalogActions.getRecentlyAddedToPlaylists.type,
        getRecentlyAddedToPlaylists
    );
}

function* generateShareLink(action: Action<generateShareLinkPayload>) {
    const functionName = "generateShareLink";
    try {
        if (
            !action.payload.artistAsin ||
            !action.payload.titleSetAsin ||
            !action.payload.teamId ||
            !action.payload.contentType
        ) {
            return;
        }

        yield put(catalogActions.generateShareLinkInProgress(true));
        const response: shareContentResponse = yield services.generateShareLink(
            action.payload
        );
        yield put(catalogActions.generateShareLinkComplete(response));
        yield put(catalogActions.generateShareLinkInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(catalogActions.generateShareLinkInProgress(false));
        publishCounter(`generateShareLinkFailure`, 1);
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
            })
        );
    }
}

function* watchGenerateShareLink() {
    yield takeEvery(catalogActions.generateShareLink.type, generateShareLink);
}

function* generatePromoCardShareLink(
    action: Action<generatePromoCardShareLinkPayload>
) {
    const functionName = "generatePromoCardShareLink";
    try {
        if (
            !action.payload.artistAsin ||
            !action.payload.contentType ||
            !action.payload.landscapeType ||
            !action.payload.language ||
            !action.payload.teamId
        ) {
            return;
        }

        yield put(catalogActions.generatePromoCardShareLinkInProgress(true));

        const response: { link: string } =
            yield services.generatePromoCardShareLink(action.payload);

        yield put(
            catalogActions.generatePromoCardShareLinkComplete(response.link)
        );

        yield put(catalogActions.generatePromoCardShareLinkInProgress(false));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        yield put(catalogActions.generatePromoCardShareLinkInProgress(false));

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
            })
        );
    }
}

function* watchGenerateSharePromoCardShareLink() {
    yield takeEvery(
        catalogActions.generatePromoCardShareLink.type,
        generatePromoCardShareLink
    );
}
