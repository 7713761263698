import * as React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import * as rootStyles from "../../styles";
import _ from "lodash";
import { getLocalizedString } from "../../../utils";
import styled from "styled-components";
import { CollapsibleCard } from "../common/CollapsibleCard";
import { IconsList } from "../common";
import { styledTitle } from "..";
import { stringIds, bundleIds } from "../../../assets";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { commonSelectors } from "../../../store/selectors";
import { BundleMap } from "../../../models";

const testIDPrefix = "MODLandingFAQ";

type MODLandingFAQProps = {
    emitMetrics: (key: string, pageId: string) => void;
};

export const MoDLandingFAQ: React.FC<MODLandingFAQProps> = ({
    emitMetrics,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const faq2Click = () => {
        emitMetrics(buttonIds.Merch.MoDFaq2, pageIds.artistMerchMoDBanner);
        window.open(
            "https://merch.amazon.com/resource/201858580",
            "_blank",
            "noreferrer, noopener"
        );
    };

    const faq6Click = () => {
        emitMetrics(buttonIds.Merch.MoDFaq6, pageIds.artistMerchMoDBanner);
        window.open(
            "https://artists.amazonmusic.com/merch",
            "_blank",
            "noreferrer, noopener"
        );
    };

    const faq9Click = () => {
        emitMetrics(buttonIds.Merch.MoDFaq9, pageIds.artistMerchMoDBanner);
        window.open(
            "https://merch.amazon.com/resource/201846470",
            "_blank",
            "noreferrer, noopener"
        );
    };

    const faqText1 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_1,
            })}
        </p>
    );
    const faqText2 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_2_a,
            })}
            <a style={clickableTextStyle} onClick={faq2Click}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCH_STRINGS,
                    stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_2_b,
                })}
            </a>
        </p>
    );
    const faqText3 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_3,
            })}
        </p>
    );
    const faqText4 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_4,
            })}
        </p>
    );
    const faqText5 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_5,
            })}
        </p>
    );
    const faqText6 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_6_a,
            })}
            <a style={clickableTextStyle} onClick={faq6Click}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCH_STRINGS,
                    stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_6_b,
                })}
            </a>
        </p>
    );
    const faqText7 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_7,
            })}
        </p>
    );
    const faqText8 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_8,
            })}
            <ul>
                <li>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCH_STRINGS,
                        stringId:
                            stringIds.Merch
                                .merch_mod_landing_FAQ_Text_8_bullet_1,
                    })}
                </li>
                <li>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCH_STRINGS,
                        stringId:
                            stringIds.Merch
                                .merch_mod_landing_FAQ_Text_8_bullet_2,
                    })}
                </li>
                <li>
                    {getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCH_STRINGS,
                        stringId:
                            stringIds.Merch
                                .merch_mod_landing_FAQ_Text_8_bullet_3,
                    })}
                </li>
            </ul>
        </p>
    );
    const faqText9 = (
        <p style={subTitle}>
            {getLocalizedString(bundleMap, {
                bundleId: bundleIds.MERCH_STRINGS,
                stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_9_a,
            })}
            <a style={clickableTextStyle} onClick={faq9Click}>
                {getLocalizedString(bundleMap, {
                    bundleId: bundleIds.MERCH_STRINGS,
                    stringId: stringIds.Merch.merch_mod_landing_FAQ_Text_9_b,
                })}
            </a>
        </p>
    );
    return (
        <SectionContainer>
            <Row>
                <Col>
                    <StyledBannerTitleContainer>
                        <styledTitle.h4
                            style={{
                                textTransform: "none",
                            }}
                        >
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch.merch_mod_landing_FAQ_Title,
                            })}
                        </styledTitle.h4>
                    </StyledBannerTitleContainer>
                    <Container>
                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_1,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ1`}
                        >
                            {faqText1}
                        </CollapsibleCard>
                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_2,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ2`}
                        >
                            {faqText2}
                        </CollapsibleCard>
                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_3,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ3`}
                        >
                            {faqText3}
                        </CollapsibleCard>
                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_4,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ4`}
                        >
                            {faqText4}
                        </CollapsibleCard>
                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_5,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ5`}
                        >
                            {faqText5}
                        </CollapsibleCard>
                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_6,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ6`}
                        >
                            {faqText6}
                        </CollapsibleCard>

                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_7,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ7`}
                        >
                            {faqText7}
                        </CollapsibleCard>

                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_8,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ8`}
                        >
                            {faqText8}
                        </CollapsibleCard>
                        <CollapsibleCard
                            cardStyle={itemStyle}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_FAQ_Title_9,
                            })}
                            titleStyle={title}
                            collapseImage={IconsList.action_add}
                            testIDPrefix={`${testIDPrefix}_FAQ9`}
                        >
                            {faqText9}
                        </CollapsibleCard>
                    </Container>
                </Col>
            </Row>
        </SectionContainer>
    );
};

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 878px;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.large}px) {
        flex-direction: column;
        width: 568px;
    }
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        flex-direction: column;
        width: 335px;
    }
    padding-top: ${rootStyles.spacers.small}px;
    border-bottom: 1px solid;
    border-bottom-color: ${rootStyles.glassColors.primary4};
    padding-bottom: ${rootStyles.spacers.epic}px;
`;
const StyledBannerTitleContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: ${rootStyles.spacers.medium}px;
    margin-bottom: ${rootStyles.spacers.medium}px;
`;
const title: React.CSSProperties = {
    ...rootStyles.textStyles.primary,
    marginTop: rootStyles.spacers.micro,
};
const subTitle: React.CSSProperties = {
    ...rootStyles.textStyles.quaternary,
    textAlign: "left",
};

const itemStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    borderRadius: "10px",
    width: "100%",
    paddingLeft: rootStyles.spacers.small,
    paddingRight: rootStyles.spacers.small,
    paddingTop: rootStyles.spacers.medium,
    marginTop: rootStyles.spacers.medium,
    backgroundColor: rootStyles.colors.background,
};

const clickableTextStyle: React.CSSProperties = {
    color: rootStyles.colors.accent,
    cursor: "pointer",
    marginLeft: 2,
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
    alignself: center;
    width: 100%;
`;
