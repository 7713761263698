import { AnyAction } from "redux";
import { FeaturePermission, GlobalFeaturePermission } from "../../models";
import { featureAccessActions } from "../actions";

export type FeatureAccessState = Readonly<{
    featurePermissionsList: FeaturePermission[];
    globalFeaturePermissionsList: GlobalFeaturePermission[];
    isGetFeaturePermissionInProgress?: boolean;
}>;

export const initialFeatureAccessState = {
    featurePermissionsList: [],
    globalFeaturePermissionsList: [],
};

export const featureAccessReducer = (
    state: FeatureAccessState = initialFeatureAccessState,
    action: AnyAction
) => {
    switch (action.type) {
        case featureAccessActions.getFeaturePermissionsSuccess.type:
            return {
                ...state,
                featurePermissionsList: action.payload,
            };
        case featureAccessActions.getGlobalPermissionsSuccess.type:
            return {
                ...state,
                globalFeaturePermissionsList: action.payload,
            };
        case featureAccessActions.isFeaturePermissionInProgress.type:
            return {
                ...state,
                isGetFeaturePermissionInProgress: action.payload,
            };
        default:
            return state;
    }
};
