import { Action } from "redux-ts";
import _ from "lodash";
import * as userActions from "../actions/userActions";
import { privilegedTeamId } from "../../service";
import { settings } from "../../models/settings";
import { artist } from "../../models/media";
import { userData, teamInfo } from "../../models/auth";
import { getWebLocale } from "../../utils/localeHelper";
import { cookieModalText, deeplinkConfig, oAuthClientIds } from "../../models";

export type UserState = Readonly<{
    signedIn: boolean;
    selectedArtistAsin?: string;
    managedArtists?: string[];
    teams?: teamInfo[];
    selectedTeamId?: string;
    isPrivileged?: boolean;
    loginInProgress: boolean;
    refreshInProgress: boolean;
    shouldRefreshToken: boolean;
    recentlyViewedArtists: artist[];
    acceptedTerms?: boolean;
    settings: settings;
    updateSettingsInProgress: boolean;
    currentPath: string;
    prevPath: string;
    locale: string;
    optOutInProgress: boolean;
    optOutCompleted: boolean;
    oAuthClientIds?: oAuthClientIds;
    doRedirectToReports: boolean /* Used to redirect user to their reports on login if they are only part of one artist's team */;
    userData: userData;
    isUserRegistered: boolean;
    isJapanUser: boolean;
    csrfToken: string;
    cookieConsentSetting?: string;
    cookieModalText?: cookieModalText;
    deeplinkRoute?: string;
    deeplinkPath?: string;
    isDeeplinkArtistSearchInProgress?: boolean;
    deeplinkMap?: deeplinkConfig;
}>;

export const initialUserState: UserState = {
    loginInProgress: false,
    refreshInProgress: false,
    shouldRefreshToken: false,
    recentlyViewedArtists: [],
    signedIn: false,
    settings: {},
    updateSettingsInProgress: false,
    currentPath: "",
    prevPath: "",
    locale: getWebLocale(),
    optOutInProgress: false,
    optOutCompleted: false,
    doRedirectToReports: true,
    userData: {
        userName: "",
        emailAddress: "",
    },
    isUserRegistered: false,
    isJapanUser: false,
    csrfToken: "",
};

export const userReducer = (
    state: UserState = initialUserState,
    action: Action
) => {
    switch (action.type) {
        case userActions.updateUserState.type:
            return {
                ...state,
                signedIn: action.payload,
            };
        case userActions.updateOAuthClientIdsCompleted.type:
            return {
                ...state,
                oAuthClientIds: action.payload,
            };
        case userActions.storeCookieConsentSetting.type:
            return {
                ...state,
                cookieConsentSetting: action.payload,
            };
        case userActions.storeCookieModalText.type:
            return {
                ...state,
                cookieModalText: action.payload,
            };
        case userActions.loginCompleted.type: {
            const teams: teamInfo[] = action.payload;
            const isPrivileged = teams.some(
                (team: teamInfo) => team.teamId === privilegedTeamId
            );
            const managedArtists: string[] = [];
            teams.forEach((team: teamInfo) =>
                team.artistAsins.forEach((asin) => managedArtists.push(asin))
            );

            return {
                ...state,
                teams: teams,
                isPrivileged: isPrivileged,
                managedArtists: [...new Set(managedArtists)],
            };
        }
        case userActions.logOutCompleted.type:
            return initialUserState;
        case userActions.loginInProgress.type:
            return {
                ...state,
                loginInProgress: action.payload,
            };
        case userActions.refreshInProgress.type:
            return {
                ...state,
                refreshInProgress: action.payload,
            };
        case userActions.selectArtistCompleted.type:
            return {
                ...state,
                selectedArtistAsin: action.payload.artist.asin,
                selectedTeamId: action.payload.teamId,
            };
        case userActions.shouldRefreshToken.type:
            return {
                ...state,
                shouldRefreshToken: action.payload,
            };
        case userActions.updateArtistRecentlyViewed.type: {
            const recentlyViewedArtists = [
                action.payload,
                ..._.filter(
                    state.recentlyViewedArtists,
                    (artist) => artist?.asin !== action.payload.asin
                ),
            ].slice(0, 25);
            return {
                ...state,
                recentlyViewedArtists: recentlyViewedArtists,
            };
        }
        case userActions.clearArtistRecentlyViewed.type: {
            return {
                ...state,
                recentlyViewedArtists: [],
            };
        }
        case userActions.updateRequesterInUser.type: {
            const index = _.findIndex(
                state.teams,
                (team) => team.teamId === action.payload.teamId
            );
            const newTeams = state.teams;
            if (newTeams && newTeams[index] && newTeams[index].members) {
                const memberIndex = newTeams[index].members.findIndex(
                    (member) =>
                        member.memberId === action.payload.member.memberId
                );
                newTeams[index].members[memberIndex] = action.payload.member;
                return {
                    ...state,
                    teams: newTeams,
                };
            }
            return state;
        }
        case userActions.updateSettingsCompleted.type: {
            return {
                ...state,
                settings: action.payload,
            };
        }
        case userActions.updateSettingsInProgress.type: {
            return {
                ...state,
                updateSettingsInProgress: action.payload,
            };
        }
        case userActions.updateCurrentPath.type: {
            return {
                ...state,
                currentPath: action.payload,
            };
        }
        case userActions.updatePrevPath.type: {
            return {
                ...state,
                prevPath: action.payload,
            };
        }
        case userActions.updateUserLocaleCompleted.type: {
            return {
                ...state,
                locale: action.payload,
            };
        }
        case userActions.optOutInProgress.type: {
            return {
                ...state,
                optOutInProgress: action.payload,
            };
        }
        case userActions.optOutCompleted.type: {
            return {
                ...state,
                optOutCompleted: action.payload,
            };
        }
        case userActions.updateUserRedirectedToReports.type: {
            return {
                ...state,
                doRedirectToReports: action.payload,
            };
        }
        case userActions.updateUserData.type: {
            return {
                ...state,
                userData: action.payload,
            };
        }
        case userActions.isJapanUser.type: {
            return {
                ...state,
                isJapanUser: action.payload,
            };
        }
        case userActions.setCsrfToken.type: {
            return {
                ...state,
                csrfToken: action.payload,
            };
        }
        case userActions.updateDeeplinkMapConfig.type: {
            return {
                ...state,
                deeplinkMap: action.payload,
            };
        }
        case userActions.updateDeeplinkRoute.type: {
            return {
                ...state,
                deeplinkRoute: action.payload,
            };
        }
        case userActions.updateIsDeeplinkArtistSearchInProgress.type: {
            return {
                ...state,
                isDeeplinkArtistSearchInProgress: action.payload,
            };
        }
        case userActions.clearDeeplinkSelectedArtist.type: {
            return {
                ...state,
                selectedArtistAsin: undefined,
                selectedTeamId: undefined
            }
        }
        default:
            return state;
    }
};
