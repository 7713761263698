import _ from "lodash";
import { RootState } from "../reducers";
import { teamInfo } from "../../models";
import { createSelector } from "reselect";
import { getBundleMap, getUserInfo } from "./commonSelectors";
import { selectedTeamSelector } from "./userSelectors";
import { selectedArtistFromCatalogSelector } from "./artistSearchSelectors";

const getUserTeams = (state: RootState) => state.user.teams;
const getInvited = (state: RootState) => state.team.inviteSent;
const getInviteError = (state: RootState) => state.team.error;
const getTeamMembers = (state: RootState) => state.team.teamMembers;
const getTeamInProgress = (state: RootState) => state.team.getTeamInProgress;
const getInvitedMembers = (state: RootState) => state.team.invitedMembers;
const getPaginationToken = (state: RootState) =>
    state.team.invitePaginationToken;
const getModifyMemberInProgress = (state: RootState) =>
    state.team.modifyMemberInProgress;
const getInvitedMembersInProgress = (state: RootState) =>
    state.team.getInvitedMembersInProgress;
const getModifyMemberSuccess = (state: RootState) =>
    state.team.modifyMemberSuccess;
const getModifyMemberType = (state: RootState) => state.team.modifyMemberType;
const getSignedIn = (state: RootState) => state.user.signedIn;
const getUserRefreshInProgress = (state: RootState) =>
    state.user.refreshInProgress;
const getInviteAccepted = (state: RootState) =>
    state.team.acceptTeamInviteCompleted;
const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);

export const getSelectedTeamSelector = createSelector(
    [getUserTeams, selectedTeamSelector],
    (teams, selectedTeamId) => {
        const team = _.filter(
            teams,
            (team: teamInfo) => team.teamId === selectedTeamId
        );
        return team[0];
    }
);

export const getUserPermissionsSelector = createSelector(
    [getSelectedTeamSelector],
    (team) => {
        return team && team.members && team.members.length === 1
            ? team.members[0].permissions
            : [];
    }
);

export const getUserRoleSelector = createSelector(
    [getSelectedTeamSelector],
    (team) => {
        return team && team.members.length === 1
            ? team.members[0].role
            : undefined;
    }
);

export const getUserIdSelector = createSelector(
    [getSelectedTeamSelector],
    (team) => {
        return team && team.members.length === 1
            ? team.members[0].memberId
            : undefined;
    }
);

export const currentMembersScreenSelector = createSelector(
    [
        getTeamMembers,
        getInvitedMembers,
        getPaginationToken,
        getTeamInProgress,
        getInvitedMembersInProgress,
        getSelectedTeamSelector,
        getUserPermissionsSelector,
        getUserInfo,
        getBundleMap,
    ],
    (
        teamMembers,
        invitedMembers,
        paginationToken,
        getTeamInProgress,
        getInvitedMembersInProgress,
        team,
        permissions,
        user,
        bundleMap
    ) => {
        return {
            teamMembers: teamMembers,
            invitedMembers: invitedMembers,
            paginationToken: paginationToken,
            getTeamInProgress: getTeamInProgress,
            getInvitedMembersInProgress: getInvitedMembersInProgress,
            teamId: team?.teamId,
            team: team,
            userPermissions: permissions,
            userInfo: user,
            bundleMap,
        };
    }
);

export const inviteTeamMembersScreenSelector = createSelector(
    [
        getInviteError,
        getInvited,
        getModifyMemberInProgress,
        getModifyMemberSuccess,
        selectedArtistFromCatalogSelector,
        getUserRoleSelector,
        getUserPermissionsSelector,
        getSignedIn,
        getTeamMembers,
        getSelectedTeamSelector,
        getModifyMemberType,
        getInvitedMembers,
    ],
    (
        inviteError,
        invited,
        modifyMemberInProgress,
        modifyMemberSuccess,
        selectedArtist,
        userRole,
        userPermissions,
        signedIn,
        teamMembers,
        selectedTeam,
        modifyMemberType,
        invitedMembers
    ) => {
        return {
            inviteSent: invited,
            teamMembers: teamMembers,
            invitedMembers: invitedMembers,
            error: inviteError,
            modifyMemberInProgress: modifyMemberInProgress,
            modifyMemberSuccess: modifyMemberSuccess,
            userRole: userRole,
            userPermissions: userPermissions,
            signedIn: signedIn,
            team: selectedTeam,
            teamId: selectedTeam && selectedTeam.teamId,
            modifyType: modifyMemberType,
            artist: selectedArtist,
        };
    }
);

export const acceptInviteScreenSelector = createSelector(
    [
        getSignedIn,
        getInviteAccepted,
        getErrorFromStore,
        getUserRefreshInProgress,
        getBundleMap,
    ],
    (
        signedIn,
        inviteAccepted,
        errorFromStore,
        userRefreshInProgress,
        bundleMap
    ) => {
        return {
            signedIn: signedIn,
            inviteAccepted: inviteAccepted,
            error: errorFromStore,
            userRefreshInProgress: userRefreshInProgress,
            bundleMap: bundleMap,
        };
    }
);
