// All of these constants are used in various places around the app -- added for consistency
export const testIDSuffixes = {
    title: "_Title",
    subtitle: "_Subtitle",
    button: "_Button",
    moreButton: "_MoreButton",
    header: "_Header",
    chart: "_Chart",
    item: "_Item",
    dismiss: "_Dismiss",
    submit: "_Submit",
    searchbar: "_SearchBar",
    export: "_ExportOption",
    primary: "_PrimaryLabel",
    secondary: "_SecondaryLabel",
    tertiary: "_TertiaryLabel",
    label: "_Label",
    stat1: "_Stat1",
    stat2: "_Stat2",
    textField: "_TextInputField",
    image: "_Image",
    icon: "_Icon",
    leftIcon: "_LeftIcon",
    rightIcon: "_RightIcon",
    rightElement: "_RightElement",
    profileImage: "_ProfileImage",
    index: "_Index",
};
