import { put, takeEvery } from "redux-saga/effects";
import {
    getCustomerSupportDataResponse,
    GetSocialAccountDataRequest,
    GetSocialAccountDataResponse,
    GetStateTokenPayload,
    GetStateTokenRequest,
    GetStateTokenResponse,
    METRIC_KEYS,
} from "../../models";
import {
    errorActions,
    oAuthActions,
    opsMetricsActions,
    telemetryActions,
} from "../actions";
import * as services from "../../service";
import { createSuccessOpsMetricsPayload } from "../../utils";
import { Action } from "redux-ts";

export const oAuthSagas = [
    watchGetCustomerSupportData(),
    watchGetStateToken(),
    watchGetSocialAccountData(),
];

function* getCustomerSupportData() {
    const start = Date.now();
    const functionName = "getCustomerSupportData";
    try {
        yield put(
            telemetryActions.appEvent({
                name: "getCustomerSupportDataStart",
                dataPoints: new Map<string, string | undefined>([]),
            })
        );

        const result: getCustomerSupportDataResponse =
            yield services.getCustomerSupportData();

        yield put(oAuthActions.getCustomerSupportDataCompleted(result));

        yield put(
            telemetryActions.appEvent({
                name: "getCustomerSupportDataEnd",
                dataPoints: new Map<string, string | undefined>([]),
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* getStateToken(action: Action<GetStateTokenPayload>) {
    const functionName = "getStateToken";
    try {
        yield put(
            oAuthActions.setStateTokenInProgress({
                partner: action.payload.partner,
                status: true,
            })
        );

        const getStateTokenRequest: GetStateTokenRequest = {
            partner: action.payload.partner,
        };
        const response: GetStateTokenResponse = yield services.getStateToken(
            getStateTokenRequest
        );

        yield put(
            oAuthActions.getStateTokenCompleted({
                stateToken: response.stateToken,
                partner: action.payload.partner,
            })
        );

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        console.log(ex);
        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
            })
        );
    } finally {
        yield put(
            oAuthActions.setStateTokenInProgress({
                partner: action.payload.partner,
                status: false,
            })
        );
    }
}

function* getSocialAccountData(action: Action<GetSocialAccountDataRequest>) {
    const start = Date.now();
    const functionName = "getSocialAccountData";
    try {
        const result: GetSocialAccountDataResponse =
            yield services.getSocialAccountData(action.payload);

        yield put(oAuthActions.getSocialAccountDataCompleted(result));

        yield put(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload(functionName)
            )
        );
    } catch (ex) {
        const dataPoints = new Map<string, string | undefined>([
            [METRIC_KEYS.loadTime, `${Date.now() - start} ms`],
        ]);

        yield put(
            errorActions.handleError({
                eventName: functionName,
                exception: ex,
                dataPoints: dataPoints,
            })
        );
    }
}

function* watchGetCustomerSupportData() {
    yield takeEvery(
        oAuthActions.getCustomerSupportData.type,
        getCustomerSupportData
    );
}

function* watchGetStateToken() {
    yield takeEvery(oAuthActions.getStateToken.type, getStateToken);
}

function* watchGetSocialAccountData() {
    yield takeEvery(
        oAuthActions.getSocialAccountData.type,
        getSocialAccountData
    );
}
