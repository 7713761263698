import styled from "styled-components";
import * as rootStyles from "../../../styles";

export const HeightSpacer = styled.div<{
    smallScreenSize: number;
    largeScreenSize: number;
}>`
    height: ${(props) => props.smallScreenSize}px;
    @media (min-width: ${rootStyles.breakpoints.xl3}px) {
        height: ${(props) => props.largeScreenSize}px;
    }
`;
