import * as React from "react";
import * as rootStyles from "../../../styles";
import { Icon, IconsList } from "../icons/Icon";
import { SEARCH_TEXT_FIELD_LIMIT } from "../../../../utils";
import { Row } from "react-bootstrap";
import styled from "styled-components";
import { breakpoints } from "../../../styles";

export type SearchProps = {
    value?: string;
    placeholder?: string;
    onChange?: any;
    id: string;
    textInputStyle?: any;
    dark?: boolean;
    hasMaxWidth?: boolean;
    squared?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    rightButton?: boolean;
    onRightButtonClick?: (active: boolean) => void;
    rightButtonState?: boolean;
    forwardRef?: any;
    containerStyle?: React.CSSProperties;
    searchIcon?: string;
    inputPlaceholderColor?: string;
};

type State = {
    showMenu: boolean;
    isFocused: boolean;
    rightButtonActive: boolean;
};

export class Search extends React.Component<SearchProps, State> {
    constructor(props: SearchProps) {
        super(props);
        this.state = {
            showMenu: false,
            isFocused: false,
            rightButtonActive: this.props.rightButtonState || false,
        };
    }

    componentDidUpdate() {
        if (
            this.props.rightButtonState !== undefined &&
            this.props.rightButtonState !== this.state.rightButtonActive
        ) {
            this.setState({ rightButtonActive: this.props.rightButtonState });
        }
    }

    render() {
        const containerStyle = this.props.dark
            ? this.state.isFocused
                ? darkSearchSelectedContainerStyle
                : darkSearchContainerStyle
            : searchContainerStyle;
        const placeholderInputStyle = `
            .searchInput::placeholder {
                color: ${this.props.inputPlaceholderColor};
            }
        `;
        return (
            <div>
                <style>{placeholderInputStyle}</style>
                <StyledRow
                    hasMaxWidth={this.props.hasMaxWidth}
                    style={{ ...containerStyle, ...this.props.containerStyle }}
                >
                    <div style={iconContainer}>
                        <Icon
                            icon={
                                this.props.searchIcon
                                    ? this.props.searchIcon
                                    : this.props.dark
                                    ? IconsList.navigation_search_dark
                                    : IconsList.navigation_search
                            }
                            size={rootStyles.icons.small}
                            color={rootStyles.glassColors.secondary1}
                            id={`${this.props.id}_LeftIcon`}
                        />
                    </div>
                    <input
                        className="searchInput"
                        type="text"
                        style={{
                            color: this.getTextColor(this.props.dark),
                            ...textInput,
                            ...this.props.textInputStyle,
                            background: this.getBackgroundColor(
                                this.props.dark
                            ),
                        }}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        placeholder={this.props.placeholder}
                        id={this.props.id}
                        maxLength={SEARCH_TEXT_FIELD_LIMIT}
                        autoComplete={"off"}
                    />
                    {this.props.rightButton ? (
                        <div
                            style={rightIconContainer}
                            onClick={() => this.rightButtonClick()}
                        >
                            <Icon
                                icon={
                                    this.state.rightButtonActive
                                        ? IconsList.chevron_caretUp
                                        : IconsList.chevron_caretdown
                                }
                                size={rootStyles.icons.small}
                                color={rootStyles.glassColors.secondary1}
                                id={`${this.props.id}_RightButton`}
                            />
                        </div>
                    ) : null}
                </StyledRow>
            </div>
        );
    }

    private getBackgroundColor = (dark: boolean | undefined) => {
        return dark ? "none" : rootStyles.colors.primary;
    };

    private getTextColor = (dark: boolean | undefined) => {
        return dark ? rootStyles.colors.primary : rootStyles.colors.secondary;
    };

    private onFocus = () => {
        this.setState({ isFocused: true });
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    private onBlur = () => {
        this.setState({ isFocused: false });
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    private rightButtonClick = () => {
        const newRightButtonActive = !this.state.rightButtonActive;
        this.setState({ rightButtonActive: newRightButtonActive });
        if (this.props.onRightButtonClick) {
            this.props.onRightButtonClick(newRightButtonActive);
        }
    };
}

const StyledRow = styled(Row)`
    width: "90%";
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    max-width: ${(props) => (props.hasMaxWidth ? "480px" : "100%")};

    @media (min-width: ${breakpoints.lg}px) {
        width: 100%;
    }
`;

const searchContainerStyle: React.CSSProperties = {
    backgroundColor: rootStyles.colors.primary,
    borderRadius: "32px",
    height: "100%",
};

const darkSearchContainerStyle: React.CSSProperties = {
    borderRadius: "9px",
    borderColor: rootStyles.glassColors.primary3,
    borderWidth: 1,
    borderStyle: "solid",
    height: 50,
};

const darkSearchSelectedContainerStyle: React.CSSProperties = {
    ...darkSearchContainerStyle,
    borderColor: rootStyles.colors.accent,
};

const textInput: React.CSSProperties = {
    paddingLeft: rootStyles.spacers.small,
    marginRight: rootStyles.spacers.base,
    flex: 1,
    border: 0,
    justifyContent: "center",
    height: 36,
    outline: "none",
};

const iconContainer: React.CSSProperties = {
    alignSelf: "center",
    alignItems: "center",
    marginLeft: rootStyles.spacers.small,
    justifyContent: "center",
    height: 36,
    paddingTop: rootStyles.spacers.micro,
    flex: 0,
};

const rightIconContainer: React.CSSProperties = {
    ...iconContainer,
    marginRight: rootStyles.spacers.medium,
    cursor: "pointer",
};
