import styled from "styled-components";
import {
    colors,
    webFontSizes,
    mobileFontSizes,
    breakpoints,
} from "../../styles";

export const titleBreakpoint = breakpoints.xl3;
export const SMALL_TEXT_BREAKPOINT = 30;

export const styledTitle = {
    h1: styled.span`
        font-family: SharpGroteskBold-20;
        font-size: ${mobileFontSizes.h1}px;
        @media (min-width: ${titleBreakpoint}px) {
            font-size: ${webFontSizes.h1}px;
        }
        color: ${colors.primary};
    `,
    h2: styled.span`
        font-family: SharpGroteskBold-20;
        font-size: ${mobileFontSizes.h2}px;
        line-height: 40px;
        @media (min-width: ${titleBreakpoint}px) {
            font-size: ${webFontSizes.h2}px;
            line-height: 80px;
        }
        color: ${colors.primary};
    `,
    h2_xl: styled.span`
        font-family: SharpGroteskBold-20;
        font-size: ${mobileFontSizes.h2_xl}px;
        @media (min-width: ${titleBreakpoint}px) {
            font-size: ${webFontSizes.h2_xl}px;
        }
        color: ${colors.primary};
    `,
    h3: styled.span`
        font-family: SharpGroteskSmBold-20;
        font-size: ${mobileFontSizes.h3}px;
        @media (min-width: ${titleBreakpoint}px) {
            font-size: ${webFontSizes.h3}px;
        }
        color: ${colors.primary};
    `,
    h4: styled.span`
        font-family: SharpGroteskSmBold-20;
        font-size: ${mobileFontSizes.h4}px;
        @media (min-width: ${titleBreakpoint}px) {
            font-size: ${webFontSizes.h4}px;
        }
        color: ${colors.primary};
    `,
};
