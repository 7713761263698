import { AnyAction } from "redux";
import { artistSearchActions, artistClaimActions } from "../actions";
import { artist } from "../../models";

export type ArtistSearchState = Readonly<{
    artists: artist[];
    inProgress: boolean;
    claimSubmitted: boolean;
    claimInProgress: boolean;
    selectedArtist?: artist;
    claimPending?: boolean;
    claimPendingCheckInProgress: boolean;
}>;

export const initialArtistSearchState = {
    artists: [],
    inProgress: false,
    claimSubmitted: false,
    claimInProgress: false,
    claimPendingCheckInProgress: false
};

export const artistSearchReducer = (
    state: ArtistSearchState = initialArtistSearchState,
    action: AnyAction
) => {
    switch (action.type) {
        case artistSearchActions.artistSearchCompleted.type:
            return { ...state, artists: action.payload };
        case artistSearchActions.artistSearchInProgress.type:
            return { ...state, inProgress: action.payload };
        case artistSearchActions.clearArtistSearch.type:
            return { ...state, artists: [] };
        case artistClaimActions.claimArtistCompleted.type:
            return { ...state, claimSubmitted: action.payload };
        case artistClaimActions.claimArtistInProgress.type:
            return { ...state, claimInProgress: action.payload };
        case artistClaimActions.selectArtistToClaim.type:
            return { ...state, selectedArtist: action.payload };
        case artistClaimActions.clearSelectedArtistToClaim.type:
            return { ...state, selectedArtist: undefined };
        case artistClaimActions.checkPendingClaimCompleted.type:
            return { ...state, claimPending: action.payload };
        case artistClaimActions.checkPendingClaimInProgress.type:
            return { ...state, claimPendingCheckInProgress: action.payload };
        default:
            return state;
    }
};
