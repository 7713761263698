import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { IconsList, Icon } from "../icons";
import { LargeSolidButton } from "../input";
import { BlurredModal } from "../..";
import * as rootStyles from "../../../styles";
import { TitleDescriptionList } from "../input/TitleDescriptionList";
import "../modals/modalScrollStyles.css";

export type MultiTitleDescriptionProps = {
    isVisible: boolean;
    onDismiss?: () => void;
    confirmButtonText: string;
    confirmButtonAction?: () => void;
    id: string;
    titleDescriptionPairs: string[][];
};

const testIDPrefix = "MultiTitleDescriptionModalId";

export class MultiTitleDescriptionModal extends React.Component<MultiTitleDescriptionProps> {
    render() {
        return (
            <BlurredModal
                id={`${this.props.id}${testIDPrefix}`}
                show={this.props.isVisible}
                style={{
                    ...rootStyles.containerStyles.modal,
                }}
            >
                <Modal.Body style={modalContainer}>
                    <Row
                        style={cancelButtonStyle}
                        onClick={this.props.onDismiss}
                    >
                        <Icon
                            icon={IconsList.action_cancel}
                            size={rootStyles.spacers.huge}
                            color={rootStyles.colors.primary}
                            id={`${this.props.id}_DismissIcon`}
                        />
                    </Row>
                    <Row id="scroll" style={rowStyle}>
                        <TitleDescriptionList
                            titleDescriptionPairs={
                                this.props.titleDescriptionPairs
                            }
                            id={`${this.props.id}_TitleDescriptionPair`}
                        />
                    </Row>
                    <Row style={buttonRowContainer}>
                        {this.props.confirmButtonText &&
                            this.props.confirmButtonAction && (
                                <Col
                                    id={`${this.props.id}-ConfirmButton`}
                                    style={buttonContainer}
                                >
                                    <LargeSolidButton
                                        containerStyle={buttonStyle}
                                        title={this.props.confirmButtonText}
                                        onClick={this.props.confirmButtonAction}
                                        id={`${this.props.id}_ConfirmButton`}
                                    />
                                </Col>
                            )}
                    </Row>
                </Modal.Body>
            </BlurredModal>
        );
    }
}

const buttonStyle: React.CSSProperties = {
    flex: 1,
    alignSelf: "center",
    marginTop: rootStyles.spacers.medium,
    width: `${rootStyles.spacers.large}vw`,
};

const modalContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: rootStyles.colors.background,
    display: "flex",
    maxWidth: "25vw",
    maxHeight: "90vh",
};

const cancelButtonStyle: React.CSSProperties = {
    flex: 0,
    padding: 4,
    borderRadius: rootStyles.spacers.large,
    alignSelf: "flex-end",
    alignContent: "center",
    justifyContent: "center",
    ...rootStyles.buttonStyles.buttonStyles.glassButton,
};

const buttonRowContainer: React.CSSProperties = {
    flex: 1,
    flexDirection: "row",
    textAlign: "center",
    flexWrap: "nowrap",
    marginTop: rootStyles.spacers.medium,
    marginBottom: rootStyles.spacers.medium,
    paddingRight: 0,
};

const buttonContainer: React.CSSProperties = {
    paddingLeft: rootStyles.spacers.micro,
    paddingRight: rootStyles.spacers.micro,
};

const rowStyle: React.CSSProperties = {
    flex: 1,
    marginTop: rootStyles.spacers.large,
    overflowY: "scroll",
};
