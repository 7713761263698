const viewOnAmazon = (itemAsin: string, artistAsin: string) => {
    return `https://www.amazon.com/dp/${itemAsin}?ref=am4a_mc_v_web_${artistAsin}`;
};

const shareItem = (itemAsin: string, artistAsin: string) => {
    return `https://www.amazon.com/dp/${itemAsin}?ref=am4a_mc_sh_web_${artistAsin}`;
};

const viewOnAmazonMusic = (artistAsin: string) => {
    return `https://music.amazon.com/merch/artists/${artistAsin}?ref=am4a_mc_v_web_${artistAsin}`;
};

const viewItemOnAmazonMusic = (artistAsin: string, itemAsin: string) => {
    return `https://music.amazon.com/merch/artists/${artistAsin}/items/${itemAsin}?ref=am4a_mc_v_web_${artistAsin}`;
};

export const MerchLinkBuilders = {
    viewOnAmazon,
    shareItem,
    viewOnAmazonMusic,
    viewItemOnAmazonMusic,
};
