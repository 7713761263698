import axios from "axios";
import { cookieModalText } from "../models";
import { getEndpoints, testHeaders } from "./common";

const getCookieConsentSettingUrl = (baseUrl: string) =>
    `${baseUrl}/usermanagement/cookieConsent/get`;
const updateCookieConsentSettingUrl = (baseUrl: string) =>
    `${baseUrl}/usermanagement/cookieConsent/update`;
const getCookieModalTextUrl = (baseUrl: string) =>
    `${baseUrl}/usermanagement/cookieConsent/getModalText`;

export async function getCookieConsentSetting(
    deviceId: string,
    deviceType: string
): Promise<string> {
    const result = await axios.post(
        getCookieConsentSettingUrl(getEndpoints().userManagement),
        {
            deviceId: deviceId,
            deviceType: deviceType,
        },
        {
            headers: {
                ...testHeaders,
            },
        }
    );

    return result.data;
}

export async function updateCookieConsentSetting(
    deviceId: string,
    deviceType: string,
    cookiePreferences: string
): Promise<void> {
    const result = await axios.post(
        updateCookieConsentSettingUrl(getEndpoints().userManagement),
        {
            deviceId: deviceId,
            deviceType: deviceType,
            cookiePreferences: cookiePreferences,
        },
        {
            headers: {
                ...testHeaders,
            },
        }
    );
}

export async function getCookieModalText(
    locale: string
): Promise<cookieModalText> {
    const result = await axios.post(
        getCookieModalTextUrl(getEndpoints().userManagement),
        {
            locale: locale,
        },
        {
            headers: {
                ...testHeaders,
            },
        }
    );

    return result.data;
}
