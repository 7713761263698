import * as React from "react";
import { Container } from "react-bootstrap";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import {
    RootState,
    userActions,
    clientMetricsActions,
    modLandingScreenSelector,
} from "../../../store";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as rootStyles from "../../styles";
import { buildUIClickPayload } from "../../../utils";
import { artist, clientMetricsPayload, EntityType } from "../../../models";
import {
    MoDLandingScreenHeader,
    ModLandingWhyItsGreat,
    MoDLandingHowItWorks,
    MoDLandingFAQ,
    MoDLandingFooter,
} from "../../components/";
import { pageIds } from "@amzn/ziggy-asset";
import { ArtistGradientBackgroundImage } from "../../components/";

type ViewProps = {};
type StateProps = {
    artist?: artist;
    selectedArtistAsin?: string;
};
type State = {};
type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

class MoDLandingScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {}

    private emitButtonClickMetrics = (key: string, pageId: string) => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                key,
                pageId,
                this.props.selectedArtistAsin,
                EntityType.ARTIST
            )
        );
    };
    render() {
        return (
            <Container
                fluid={true}
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                }}
            >
                <ArtistGradientBackgroundImage artist={this.props.artist} />
                <MoDLandingScreenHeader
                    props={this.props}
                    emitMetrics={this.emitButtonClickMetrics}
                />
                <SectionContainer>
                    <ModLandingWhyItsGreat
                        emitMetrics={this.emitButtonClickMetrics}
                    />
                </SectionContainer>
                <SectionContainer>
                    <MoDLandingHowItWorks />
                </SectionContainer>
                <SectionContainer>
                    <MoDLandingFAQ emitMetrics={this.emitButtonClickMetrics} />
                </SectionContainer>
                <SectionContainer>
                    <MoDLandingFooter
                        emitMetrics={this.emitButtonClickMetrics}
                    />
                </SectionContainer>
            </Container>
        );
    }
}

const SectionContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

function mapStateToProps(state: RootState): StateProps {
    return modLandingScreenSelector(state);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MoDLandingScreen)
);
