import * as React from "react";
import { connect } from "react-redux";
import { Dispatch, AnyAction } from "redux";
import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import { ErrorModal } from "./ErrorModal";
import { getLocalizedString } from "../../../../../src/utils";
import { RootState, errorActions } from "../../../../../src/store";
import { BundleMap, ErrorPayload } from "../../../../../src/models";

type DispatchProps = {
    handleError: (payload: ErrorPayload) => void;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = DispatchProps & StateProps;

type ErrorState = { hasError: boolean };

class ErrorBoundary extends React.Component<
    React.PropsWithChildren<Props>,
    ErrorState
> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        this.props.handleError({
            eventName: "WebErrorBoundaryError",
            exception: error,
            uncaught: true,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorModal
                    isVisible={true}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ERRORS_STRINGS,
                        stringId: stringIds.Errors.errorTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ERRORS_STRINGS,
                        stringId: stringIds.Errors.errorBoundary,
                    })}
                    onDismiss={() => window.location.replace("/")}
                />
            );
        }

        return this.props.children;
    }
}

function mapStateToProps(state: RootState) {
    return {
        bundleMap: state.localization.bundleMap,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        handleError: (payload: ErrorPayload) =>
            dispatch(errorActions.handleError(payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
