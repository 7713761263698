import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import _ from "lodash";
import { getLocalizedString } from "../../../utils";
import * as rootStyles from "../../styles";
import styled from "styled-components";
import { stringIds, bundleIds } from "../../../assets";
import { LargeSolidButton, styledTitle } from "../../components";
import { getThirdPartyEndpoints } from "../../../service/common";
import { colors } from "../../styles";
import { commonSelectors } from "../../../store/selectors";
import { BundleMap } from "../../../models";

const testIDPrefix = "MoDLandingWhyItsgreat";

type MODLandingHeaderProps = {
    emitMetrics: (key: string, pageId: string) => void;
};

export const ModLandingWhyItsGreat: React.FC<MODLandingHeaderProps> = ({
    emitMetrics,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const launchMoD = () => {
        emitMetrics(
            buttonIds.Merch.launchMoDHeaderButton,
            pageIds.artistMerchMoDLandingScreen
        );
        const url = getThirdPartyEndpoints().merchOnDemandUrl();
        //_blank options allows to open in new tab,
        window.open(url, "_blank", "noreferrer, noopener");
    };

    return (
        <div>
            <SectionContainer id={`${testIDPrefix}_WhyCreate`}>
                <Row>
                    <Col>
                        <StyledBannerTitleContainer>
                            <styledTitle.h2>
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_landing_why_create,
                                })}
                            </styledTitle.h2>
                            <div style={subTitle}>
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_landing_why_weHavePartnered,
                                })}
                            </div>
                        </StyledBannerTitleContainer>
                        <LargeSolidButton
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch.merch_mod_landing_buttonTex,
                            })}
                            id={`${testIDPrefix}_ContinueButton1`}
                            buttonStyle={{
                                paddingLeft: rootStyles.spacers.epic,
                                paddingRight: rootStyles.spacers.epic,
                            }}
                            onClick={launchMoD}
                        />
                        <div
                            style={{
                                ...subTitle,
                            }}
                        >
                            {getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch
                                        .merch_mod_landing_why_RedirectedTo,
                            })}
                        </div>
                    </Col>
                </Row>
            </SectionContainer>
            <SectionContainer id={`${testIDPrefix}_HowToGet`}>
                <Row>
                    <Col>
                        <StyledBannerTitleContainer>
                            <styledTitle.h4
                                style={{
                                    textTransform: "none",
                                }}
                            >
                                {getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.MERCH_STRINGS,
                                    stringId:
                                        stringIds.Merch
                                            .merch_mod_landing_why_howToGet,
                                })}
                            </styledTitle.h4>
                        </StyledBannerTitleContainer>
                        <StyledBulletTitleContainer>
                            <Row style={BulletRow}>
                                <BulletCol xs={2}>
                                    <BullerNumberCircle>
                                        <styledTitle.h4 style={BulletNumber}>
                                            {"1"}
                                        </styledTitle.h4>
                                    </BullerNumberCircle>
                                </BulletCol>
                                <Col
                                    style={{
                                        ...BulletText,
                                    }}
                                >
                                    {getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.MERCH_STRINGS,
                                        stringId:
                                            stringIds.Merch
                                                .merch_mod_landing_why_SignInTo,
                                    })}
                                </Col>
                            </Row>
                            <Row style={BulletRow}>
                                <BulletCol xs={2}>
                                    <BullerNumberCircle>
                                        <styledTitle.h4 style={BulletNumber}>
                                            {"2"}
                                        </styledTitle.h4>
                                    </BullerNumberCircle>
                                </BulletCol>
                                <Col
                                    style={{
                                        ...BulletText,
                                    }}
                                >
                                    {getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.MERCH_STRINGS,
                                        stringId:
                                            stringIds.Merch
                                                .merch_mod_landing_why_reviewAccept,
                                    })}
                                </Col>
                            </Row>
                            <Row style={BulletRow}>
                                <BulletCol xs={2}>
                                    <BullerNumberCircle>
                                        <styledTitle.h4 style={BulletNumber}>
                                            {"3"}
                                        </styledTitle.h4>
                                    </BullerNumberCircle>
                                </BulletCol>
                                <Col
                                    style={{
                                        ...BulletText,
                                    }}
                                >
                                    {getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.MERCH_STRINGS,
                                        stringId:
                                            stringIds.Merch
                                                .merch_mod_landing_why_completeTheMerch,
                                    })}
                                </Col>
                            </Row>
                            <Row style={BulletRow}>
                                <BulletCol xs={2}>
                                    <BullerNumberCircle>
                                        <styledTitle.h4 style={BulletNumber}>
                                            {"4"}
                                        </styledTitle.h4>
                                    </BullerNumberCircle>
                                </BulletCol>
                                <Col
                                    style={{
                                        ...BulletText,
                                    }}
                                >
                                    {getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.MERCH_STRINGS,
                                        stringId:
                                            stringIds.Merch
                                                .merch_mod_landing_youWillRecieve,
                                    })}
                                </Col>
                            </Row>
                            <Row style={BulletRow}>
                                <BulletCol xs={2}>
                                    <BullerNumberCircle>
                                        <styledTitle.h4 style={BulletNumber}>
                                            {"5"}
                                        </styledTitle.h4>
                                    </BullerNumberCircle>
                                </BulletCol>
                                <Col
                                    style={{
                                        ...BulletText,
                                    }}
                                >
                                    {getLocalizedString(bundleMap, {
                                        bundleId: bundleIds.MERCH_STRINGS,
                                        stringId:
                                            stringIds.Merch
                                                .merch_mod_landing_why_startCreating,
                                    })}
                                </Col>
                            </Row>
                        </StyledBulletTitleContainer>
                        <LargeSolidButton
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCH_STRINGS,
                                stringId:
                                    stringIds.Merch.merch_mod_landing_buttonTex,
                            })}
                            id={`${testIDPrefix}_ContinueButton2`}
                            buttonStyle={{
                                paddingLeft: rootStyles.spacers.epic,
                                paddingRight: rootStyles.spacers.epic,
                            }}
                            onClick={launchMoD}
                        />
                    </Col>
                </Row>
            </SectionContainer>
        </div>
    );
};

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 878px;
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.large}px) {
        flex-direction: column;
        width: 568px;
    }
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        flex-direction: column;
        width: 400px;
    }
    padding-top: ${rootStyles.spacers.small}px;
    border-bottom: 1px solid;
    border-bottom-color: ${rootStyles.glassColors.primary4};
    padding-bottom: ${rootStyles.spacers.epic}px;
`;

const StyledBannerTitleContainer = styled.div`
    width: 100%;
    text-align: center;
    margin-top: ${rootStyles.spacers.medium}px;
    margin-bottom: ${rootStyles.spacers.medium}px;
    justify-content: center;
`;

const StyledBulletTitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: ${rootStyles.spacers.medium}px;
    margin-bottom: ${rootStyles.spacers.medium}px;
    justify-content: center;
`;

const subTitle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    marginTop: rootStyles.spacers.small,
    textAlign: "center",
    textTransform: "none",
};

const BulletRow: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
    width: "100%",
    alignSelf: "center",
    margin: rootStyles.spacers.mini,
};

const BulletCol = styled(Col)`
    display: flex;
    justify-content: right;
`;
const BullerNumberCircle = styled.div`
    height: 46px;
    width: 46px;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-content: center;
    border-radius: 50%;
    background-color: ${rootStyles.colors.accent};

    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.large}px) {
        height: 36px;
        width: 36px;
        padding: 4px;
    }
    @media (max-width: ${rootStyles.responsiveViewWidthBreakpoints.medium}px) {
        height: 32px;
        width: 32px;
        padding: 4px;
    }
`;

const BulletNumber: React.CSSProperties = {
    textAlign: "center",
    color: rootStyles.colors.background,
};

const BulletText: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    color: colors.primary,
    paddingLeft: "0px",
    textAlign: "left",
};
