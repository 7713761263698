import { createSelector } from "reselect";
import _ from "lodash";
import { RootState } from "../../reducers";
import {
    getUserInfo,
    getUserLocale,
    selectedArtistFromCatalogSelector,
} from "../commonSelectors";
import { getSelectedTeamSelector } from "../teamManagementSelectors";
import {
    getMerchCatalog,
    getMerchCollection,
    getMerchCollectionsInProgress,
    hasCurateMerchPermission,
    hasMODPermission,
    getHydratingIdentifiers,
    showModSelector,
    showCurationSelector,
} from "./merchCurateCommonSelectors";

export const merchOverviewSelector = createSelector(
    [
        selectedArtistFromCatalogSelector,
        getSelectedTeamSelector,
        getMerchCollection,
        getMerchCollectionsInProgress,
        getMerchCatalog,
        getHydratingIdentifiers,
        getUserInfo,
        getUserLocale,
        hasCurateMerchPermission,
        showCurationSelector,
        hasMODPermission,
        showModSelector,
    ],
    (
        artist,
        teamInfo,
        collection,
        getMerchCollectionsInProgress,
        merchCatalog,
        hydratingIdentifiers,
        signedIn,
        locale,
        merchCuratePermission,
        showMerchCurate,
        hasMerchIngressPermissions,
        showMod
    ) => {
        return {
            artist: artist,
            teamId: teamInfo.teamId,
            collection: collection,
            getMerchCollectionsInProgress: getMerchCollectionsInProgress,
            merchCatalog: merchCatalog,
            hydratingIdentifiers: hydratingIdentifiers,
            signedIn: signedIn,
            locale: locale,
            merchCuratePermission: merchCuratePermission,
            showMerchCurate,
            hasMerchIngressPermissions: hasMerchIngressPermissions,
            showMod,
        };
    }
);
