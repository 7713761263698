import * as React from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import { stringIds, bundleIds } from "../../../../assets";
import { BundleMap, TableRowProps } from "../../../../models";
import { getLocalizedString } from "../../../../utils";
import { RootState } from "../../../../store";
import * as rootStyles from "../../../styles";
import { ImageWithFallback } from "../../common/";
import styled from "styled-components";
import { bundleMapSelector } from "../../../../store/selectors/commonSelectors";

type StateProps = {
    bundleMap: BundleMap;
};

type Props = StateProps & TableRowProps;

export class TableRowClass extends React.PureComponent<Props> {
    render() {
        const rowStyle = this.props.showBottomBorder
            ? {
                  ...this.props.additionalRowStyle,
                  ...rootStyles.tableStyles.containerStyle,
                  ...rootStyles.tableStyles.containerWithBottomBorder,
              }
            : {
                  ...this.props.additionalRowStyle,
                  ...rootStyles.tableStyles.containerStyle,
              };

        if (this.props.isLoading) {
            return (
                <div
                    id={`${this.props.id}-Loading`}
                    key={this.props.index}
                    style={{
                        ...rowStyle,
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    {this.props.index && (
                        <div
                            id={`${this.props.id}-Index`}
                            style={{
                                ...rootStyles.tableStyles.indexContainer,
                                paddingLeft: 15,
                            }}
                        >
                            {this.props.index && (
                                <span style={rootStyles.textStyles.index}>
                                    {this.props.index}
                                </span>
                            )}
                        </div>
                    )}

                    <div
                        id={`${this.props.id}-Image`}
                        style={{ border: 0, paddingLeft: 15 }}
                    >
                        <LoadingPlaceholer
                            style={rootStyles.tableStyles.imageLoading}
                        />
                    </div>

                    <div
                        id={`${this.props.id}-Primary`}
                        style={{
                            ...rootStyles.tableStyles.textContainer,
                            paddingLeft: 15,
                        }}
                    >
                        <div className="truncatedTwoLinesText">
                            <LoadingPlaceholer
                                style={rootStyles.tableStyles.primaryLoading}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div
                key={this.props.index}
                style={{
                    ...rowStyle,
                    cursor: this.props.onSelect ? "pointer" : "auto",
                    display: "flex",
                    flexDirection: "row",
                }}
                onClick={
                    this.props.onSelect === undefined
                        ? undefined
                        : this.props.onSelect
                }
                id={`${this.props.id}-Container`}
            >
                {this.props.index ? (
                    <div
                        id={`${this.props.id}-Index`}
                        style={{
                            ...rootStyles.tableStyles.indexContainer,
                            paddingLeft: 15,
                        }}
                    >
                        <span style={rootStyles.textStyles.index}>
                            {this.props.index}
                        </span>
                    </div>
                ) : null}
                {(this.props.fallbackImage || this.props.image) && (
                    <div
                        id={`${this.props.id}-Image`}
                        style={{ border: 0, paddingLeft: 15 }}
                    >
                        <ImageWithFallback
                            style={rootStyles.imageStyles.miniSquareImage}
                            source={this.props.image}
                            imageDescription={this.props.imageDescription}
                            fallback={this.props.fallbackImage}
                            id={`${this.props.id}_Image`}
                        />
                    </div>
                )}
                {this.props.primary ? (
                    <div
                        id={`${this.props.id}-Primary`}
                        style={{
                            ...rootStyles.tableStyles.textContainer,
                            paddingRight: 15,
                            paddingLeft: 15,
                        }}
                    >
                        <Row
                            className="truncatedTwoLinesText"
                            style={{
                                ...rootStyles.textStyles.primary,
                            }}
                        >
                            {this.props.primary}
                        </Row>
                        {this.props.secondary && (
                            <Row
                                className="truncatedOneLineText"
                                style={{ ...rootStyles.textStyles.secondary }}
                            >
                                {this.props.secondary}
                            </Row>
                        )}
                        {this.props.tertiary && (
                            <Row
                                className="truncatedOneLineText"
                                style={{ ...rootStyles.textStyles.secondary }}
                            >
                                {this.props.tertiary}
                            </Row>
                        )}
                    </div>
                ) : null}
                {this.props.displayNewBadge && (
                    <NewBadgeContainer>
                        <span style={newBadgeTextStyle}>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.REPORTS_STRINGS,
                                stringId: stringIds.Reports.newBadgeLabel,
                            })}
                        </span>
                        {this.props.date && (
                            <DateAddedContainer>
                                <span style={newAddedDateTextStyle}>
                                    {this.props.date}
                                </span>
                            </DateAddedContainer>
                        )}
                    </NewBadgeContainer>
                )}
                {this.props.stat1 ? (
                    <div
                        id={`${this.props.id}-Stat1`}
                        style={{
                            ...rootStyles.tableStyles.statContainer,
                            paddingLeft: rootStyles.spacers.mini,
                            height: "100%",
                            alignItems: "center",
                        }}
                    >
                        {typeof this.props.stat1 === "string" ? (
                            <span
                                style={{
                                    ...rootStyles.textStyles.secondary,
                                }}
                            >
                                {this.props.stat1}
                            </span>
                        ) : (
                            this.props.stat1
                        )}
                    </div>
                ) : null}
                {this.props.stat2 ? (
                    <div
                        id={`${this.props.id}-Stat2`}
                        style={rootStyles.tableStyles.statContainer}
                    >
                        {typeof this.props.stat2 === "string" ? (
                            <span style={rootStyles.textStyles.secondary}>
                                {this.props.stat2}
                            </span>
                        ) : (
                            this.props.stat2
                        )}
                    </div>
                ) : null}
            </div>
        );
    }
}

const LoadingPlaceholer = (props: any) => {
    return <div {...props}>{props.children}</div>;
};

const NewBadgeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    min-width: ${rootStyles.spacers.large}px;
`;

const newBadgeTextStyle: React.CSSProperties = {
    display: "flex",
    ...rootStyles.textStyles.index,
    color: rootStyles.colors.primary,
    margin: rootStyles.spacers.micro,
    padding: 2,
    borderRadius: 2,
    backgroundColor: rootStyles.tierColors.unlimited,
};

const newAddedDateTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    marginLeft: rootStyles.spacers.mini,
    alignSelf: "center",
};

const DateAddedContainer = styled.div`
    display: flex;
    @media (max-width: ${rootStyles.breakpoints.lg}px) {
        display: none;
    }
`;

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

export const TableRow = connect(mapStateToProps)(TableRowClass);
