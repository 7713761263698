import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BundleMap, group } from "../../../../models";
import {
    buildDropdownResultsGroupsMatchingText,
    getLocalizedString,
    groupToItemPropsList,
} from "../../../../utils";
import { DropdownResults } from "../DropdownResults";
import { Search } from "./";
import * as rootStyles from "../../../styles";
import { stringIds, bundleIds } from "../../../../assets";
import { GroupItemsNotFoundProps } from "../helpers";
import { commonSelectors } from "../../../../store/selectors";

export type DropdownProps = {
    placeholder?: string;
    groups: group[];
    hideWhenSelect?: boolean;
    onChange: (groups: group[]) => void;
    onTextChange?: (text: string) => void;
    maxSelect: number;
    id: string;
    filterResults?: boolean;
    groupItemsNotFoundProps?: GroupItemsNotFoundProps;
    onOpenDropdown?: (open: boolean) => void;
};

const DropdownPrefix = "Dropdown";

export const Dropdown: React.FC<DropdownProps> = ({
    placeholder = "",
    groups,
    hideWhenSelect = false,
    onChange,
    onTextChange,
    maxSelect,
    id,
    filterResults = true,
    groupItemsNotFoundProps,
    onOpenDropdown,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const containerRef = useRef<HTMLInputElement>(null);
    const [textInput, setTextInput] = useState<string>("");
    const [mounted, setMounted] = useState<boolean>(false);
    const [showDropdownResults, setShowDropdownResults] =
        useState<boolean>(false);
    const [textInputFocused, setTextInputFocused] = useState<boolean>(false);
    const buildDropdownResultsGroupDebounce = useCallback(
        _.debounce((textInput: string, currentGroups: group[]) => {
            const resultGroup: group[] = filterResults
                ? buildDropdownResultsGroupsMatchingText(
                      textInput,
                      currentGroups
                  )
                : currentGroups;
            onChange(resultGroup);
        }, 1000),
        []
    );
    const [isMaxSelectionReached, setMaxSelectionReached] =
        useState<boolean>(false);

    const onClickOutside = (event: any) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(event.target)
        ) {
            setShowDropdownResults(false);
        }
    };

    // Hide results whenever a tab is pressed
    const onKeyPressed = (event: any) => {
        if (event.key === "Tab") {
            setShowDropdownResults(false);
        }
    };

    useEffect(() => {
        setMounted(true);
        document.addEventListener("mousedown", onClickOutside);
        document.addEventListener("keydown", onKeyPressed);
        return () => {
            document.removeEventListener("mousedown", onClickOutside);
            document.removeEventListener("keydown", onKeyPressed);
        };
    }, []);

    useEffect(() => {
        if (mounted) {
            textInputFocused && setShowDropdownResults(true);
            onOpenDropdown && onOpenDropdown(true);
        }
    }, [textInputFocused]);

    useEffect(() => {
        if (mounted) {
            (textInput || textInput === "") &&
                buildDropdownResultsGroupDebounce(textInput, groups);
        }
    }, [textInput]);

    useEffect(() => {
        if (mounted && isMaxSelectionReached && showDropdownResults) {
            setShowDropdownResults(false);
            onOpenDropdown && onOpenDropdown(false);
        }
    }, [isMaxSelectionReached]);

    const selectedItemQuantities: number[] = groups.map((group) => {
        const selectedItems = group.items.filter((item) => {
            return item.selected;
        });
        return selectedItems.length;
    });

    const selectedItemsTotal: number = selectedItemQuantities.length
        ? selectedItemQuantities.reduce((sum, quantity) => sum + quantity)
        : 0;

    const maxSelectionReached = selectedItemsTotal >= maxSelect;
    maxSelectionReached !== isMaxSelectionReached &&
        setMaxSelectionReached(maxSelectionReached);
    const areThereItemsDisplayed: boolean = _.reduce(
        groupToItemPropsList(groups),
        (itemsDisplayed: boolean, itemProp) => itemsDisplayed || !itemProp.hide,
        false
    );

    useEffect(() => {
        onOpenDropdown &&
            onOpenDropdown(showDropdownResults && areThereItemsDisplayed);
    }, [showDropdownResults, areThereItemsDisplayed]);

    const shouldDropdownBeShown =
        areThereItemsDisplayed ||
        (!!groupItemsNotFoundProps?.message &&
            !!groupItemsNotFoundProps.groupIndex);
    return (
        <div style={{ width: "100%" }} ref={containerRef}>
            <Search
                placeholder={placeholder}
                dark={true}
                onChange={(text: any) => {
                    const currentText = text.target.value;
                    setTextInput(currentText);
                    if (onTextChange) {
                        onTextChange(currentText);
                    }
                }}
                onFocus={() => setTextInputFocused(true)}
                onBlur={() => setTextInputFocused(false)}
                rightButton={true}
                onRightButtonClick={(active: boolean) =>
                    setShowDropdownResults(active)
                }
                rightButtonState={showDropdownResults}
                id={`${id}_Search`}
            />
            {shouldDropdownBeShown && (
                <DropdownResults
                    onChange={(groups: group[]) => {
                        onChange(groups);
                        if (hideWhenSelect) {
                            setShowDropdownResults(false);
                        }
                    }}
                    width={containerRef.current?.clientWidth || 450}
                    groups={groups}
                    hide={!showDropdownResults}
                    maxSelectReached={isMaxSelectionReached}
                    headerElement={
                        isMaxSelectionReached ? (
                            <span style={rootStyles.textStyles.tertiary}>
                                {getLocalizedString(
                                    bundleMap,
                                    {
                                        bundleId: bundleIds.PITCH_STRINGS,
                                        stringId:
                                            stringIds.Pitch
                                                .dropdownMaxSelectionsMessage,
                                    },
                                    { "0": maxSelect.toString() }
                                )}
                            </span>
                        ) : undefined
                    }
                    groupItemsNotFoundProps={{
                        groupIndex: groupItemsNotFoundProps?.groupIndex,
                        message: groupItemsNotFoundProps?.message,
                    }}
                    id={`${id}-${DropdownPrefix}`}
                />
            )}
        </div>
    );
};

export default Dropdown;
