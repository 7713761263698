import * as React from "react";
import { Col } from "react-bootstrap";
import _ from "lodash";
import { useSelector } from "react-redux";
import { commonSelectors } from "../../../store/selectors";
import { ImageCard } from "../../components";
import { getLocalizedString } from "../../../utils";
import * as rootStyles from "../../styles";
import { IconsList, stringIds, bundleIds } from "../../../assets";
import styled from "styled-components";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { BundleMap } from "../../../models";

const testIDPrefix = "MerchFTUFooter";

export type FTUFooterLinksProps = {
    emitMetrics: (key: string, pageId: string) => void;
};

export const FTUFooterLinks: React.FC<FTUFooterLinksProps> = ({
    emitMetrics,
}) => {
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    return (
        <FooterContainer>
            <CardContainer>
                <ImageCard
                    cardStyle={{ flex: 1 }}
                    image={IconsList.ic_important}
                    imageStyle={cardIconStyle}
                    imagePrimaryText={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .LandingPageCurationInfringementCardTitle,
                    })}
                    imageSecondaryText={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .LandingPageCurationInfringementCardSubtitle,
                    })}
                    testIDPrefix={`${testIDPrefix}_ReportInfringementCard`}
                    rightIcon={IconsList.chevron_caretright}
                    rightIconStyle={rightIconStyle}
                    // noreferrer, noopener: revoke granting the untrusted target domain in the link privileges to modify the parent app location.
                    //or more information, see the https://skb.highcastle.a2z.com/implementations/219
                    onClick={() => {
                        emitMetrics(
                            buttonIds.Merch.reportInfringement,
                            pageIds.artistMerchHomePage
                        );
                        window.open(
                            "https://www.amazon.com/report/infringement/signin",
                            "_blank",
                            "noreferrer, noopener"
                        );
                    }}
                />
            </CardContainer>

            <CardContainer>
                <ImageCard
                    cardStyle={{ flex: 1 }}
                    image={IconsList.shield}
                    imageStyle={cardIconStyle}
                    imagePrimaryText={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .LandingPageCurationBrandRegistryCardTitle,
                    })}
                    imageSecondaryText={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .LandingPageCurationBrandRegistryCardSubtitle,
                    })}
                    testIDPrefix={`${testIDPrefix}_BrandRegistryCard`}
                    rightIcon={IconsList.chevron_caretright}
                    rightIconStyle={rightIconStyle}
                    // noreferrer, noopener: revoke granting the untrusted target domain in the link privileges to modify the parent app location.
                    //or more information, see the https://skb.highcastle.a2z.com/implementations/219
                    onClick={() => {
                        emitMetrics(
                            buttonIds.Merch.brandRegistry,
                            pageIds.artistMerchHomePage
                        );
                        // directing users to BR copyright enrollment pilot program. Users not on BR allowlist will be directed to https://brandservices.amazon.com/
                        window.open(
                            "https://brandregistry.amazon.com/brand/enrollment/3c169f90-6263-4c75-8c2b-9d58b9f5a0af/copyright-info",
                            "_blank",
                            "noreferrer, noopener"
                        );
                    }}
                />
            </CardContainer>

            <CardContainer>
                <ImageCard
                    cardStyle={{ flex: 1 }}
                    image={IconsList.alerts_help}
                    imageStyle={cardIconStyle}
                    imagePrimaryText={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .LandingPageCurationFAQCardTitle,
                    })}
                    imageSecondaryText={getLocalizedString(bundleMap, {
                        bundleId: bundleIds.MERCHCURATION_STRINGS,
                        stringId:
                            stringIds.Merch.Curation
                                .LandingPageCurationFAQCardSubtitle,
                    })}
                    rightIcon={IconsList.chevron_caretright}
                    rightIconStyle={rightIconStyle}
                    testIDPrefix={`${testIDPrefix}_FAQCard`}
                    // noreferrer, noopener: revoke granting the untrusted target domain in the link privileges to modify the parent app location.
                    //or more information, see the https://skb.highcastle.a2z.com/implementations/219

                    onClick={() => {
                        emitMetrics(
                            buttonIds.Merch.merchFAQ,
                            pageIds.artistMerchHomePage
                        );
                        window.open(
                            "https://artists.amazonmusic.com/frequently-asked-questions#listarticle-3",
                            "_blank",
                            "noreferrer, noopener"
                        );
                    }}
                />
            </CardContainer>
        </FooterContainer>
    );
};

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${rootStyles.spacers.medium}px;
    margin-bottom: ${rootStyles.spacers.medium}px;
    justify-content: center;
    align-items: stretch;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        flex-direction: column;
    }
`;

const CardContainer = styled(Col)`
    display: flex;
    width: 100%;
    @media (max-width: 767px), (min-width: 992px) and (max-width: 1150px) {
        margin-bottom: ${rootStyles.spacers.mini}px;
    }
`;

const cardIconStyle: React.CSSProperties = {
    width: rootStyles.icons.extraLarge,
    height: rootStyles.icons.extraLarge,
    padding: rootStyles.spacers.mini,
    margin: 0,
    backgroundColor: rootStyles.colors.background,
};

const rightIconStyle: React.CSSProperties = {
    opacity: rootStyles.glass._4,
    alignSelf: "flex-end",
};
