import React, { FunctionComponent, SVGProps } from "react";
import { testIDSuffixes } from "../../../../utils";

export type SvgIconProps = {
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    size: number;
    id: string;
    onClick?: () => void;
} & SVGProps<SVGSVGElement>;

export const SvgIcon: React.FunctionComponent<SvgIconProps> = ({
    Icon,
    size,
    id,
    onClick,
    ...props
}) => {
    const cursor = onClick ? "pointer" : "inherit";
    return (
        <Icon
            id={`${id}${testIDSuffixes.icon}`}
            height={size}
            width={size}
            onClick={onClick}
            style={{ cursor: cursor, userSelect: "none" }}
            {...props}
        />
    );
};
