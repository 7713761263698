import { createAction } from "redux-ts";
import {
    createPitchPayload,
    createPitchResponse,
    getPitchFormDataPayload,
    getPitchFormDataResponse,
    listAlbumsToPitchPayload,
    listAlbumsToPitchResponse,
    listRelatedArtistsPayload,
    listRelatedArtistsResponse,
    listTracksToPitchPayload,
    listTracksToPitchResponse,
} from "../../models";

export const createPitch = createAction<createPitchPayload>(
    "PITCH::CREATE_PITCH"
);

export const createPitchInProgress = createAction<boolean>(
    "PITCH::CREATE_PITCH_IN_PROGRESS"
);

export const createPitchCompleted = createAction<boolean | undefined>(
    "PITCH::CREATE_PITCH_COMPLETED"
);

export const getPitchFormData =
    createAction<getPitchFormDataPayload>("PITCH::GET_PITCH");

export const getPitchFormDataInProgress = createAction<boolean>(
    "PITCH::GET_PITCH_IN_PROGRESS"
);

export const getPitchFormDataCompleted = createAction<getPitchFormDataResponse>(
    "PITCH::GET_PITCH_COMPLETED"
);

export const listAlbumsToPitch = createAction<listAlbumsToPitchPayload>(
    "CATALOG::LIST_ALBUMS_TO_PITCH"
);

export const listAlbumsToPitchInProgress = createAction<boolean>(
    "CATALOG::LIST_ALBUMS_TO_PITCH_IN_PROGRESS"
);

export const listAlbumsToPitchComplete =
    createAction<listAlbumsToPitchResponse>(
        "CATALOG::LIST_ALBUMS_TO_PITCH_COMPLETE"
    );

export const listTracksToPitch = createAction<listTracksToPitchPayload>(
    "CATALOG::LIST_TRACKS_TO_PITCH"
);

export const listTracksToPitchInProgress = createAction<boolean>(
    "CATALOG::LIST_TRACKS_TO_PITCH_IN_PROGRESS"
);

export const listTracksToPitchComplete =
    createAction<listTracksToPitchResponse>(
        "CATALOG::LIST_TRACKS_TO_PITCH_COMPLETE"
    );

export const listRelatedArtists = createAction<listRelatedArtistsPayload>(
    "CATALOG::LIST_RELATED_ARTISTS"
);

export const listRelatedArtistsInProgress = createAction<boolean>(
    "CATALOG::LIST_RELATES_ARTISTS_IN_PROGRESS"
);

export const listRelatedArtistsComplete =
    createAction<listRelatedArtistsResponse>(
        "CATALOG::LIST_RELATES_ARTISTS_COMPLETE"
    );
