import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../store";
import { stringIds, bundleIds } from "../../../../assets";
import {
    getLocalizedString,
    SUMMARY_TABLE_ITEM_COUNT,
} from "../../../../utils";
import * as rootStyles from "../../../styles";
import { SmallGlassButton } from "..";
import { bundleMapSelector } from "../../../../store/selectors/commonSelectors";
import { BundleMap } from "../../../../models";

export type TableSeeAllButtonProps = {
    id: string;
    onClick: () => void;
    totalRows: number;
};

type StateProps = {
    bundleMap: BundleMap;
};

type Props = TableSeeAllButtonProps & StateProps;
export class TableSeeAllButtonClass extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }

    render() {
        if (this.props.totalRows > SUMMARY_TABLE_ITEM_COUNT) {
            return (
                <SmallGlassButton
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.GENERIC_STRINGS,
                        stringId: stringIds.Generic.seeAll,
                    })}
                    onClick={this.props.onClick}
                    id={this.props.id}
                />
            );
        }
        return null;
    }
}

function mapStateToProps(state: RootState): StateProps {
    return bundleMapSelector(state);
}

export const TableSeeAllButton = connect(mapStateToProps)(
    TableSeeAllButtonClass
);
