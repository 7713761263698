import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import _ from "lodash";
import { buttonIds, pageIds, stringIds, bundleIds } from "@amzn/ziggy-asset";
import {
    MerchListItemTable,
    MerchSearchHeader,
    generateListItemFromMerchItem,
    Search,
    Loading,
    MerchListItemProps,
    ArtistGradientBackgroundImage,
    LargeOutlineButton,
} from "../../components";
import {
    buildUIClickPayload,
    getLocalizedString,
    paths,
    US_MARKETPLACE,
} from "../../../utils";
import * as rootStyles from "../../styles";
import {
    clientMetricsActions,
    merchCurateActions,
    merchHydratorActions,
    RootState,
    seeAllMerchScreenSelector,
} from "../../../store";
import {
    artist,
    merchCollection,
    merchDeviceFamily,
    drTeethMerchIdentifiers,
    hydrateMerchPayload,
    merchCatalog,
    merchIdentifiers,
    hydratedMerchItem,
    searchMerchCollectionsPayload,
    clientMetricsPayload,
    EntityType,
    BundleMap,
} from "../../../models";
import { IconsList } from "../../../assets";

const testIDPrefix = "SeeAllMerchScreen";
const pagePath = paths.seeAllMerch;

type ViewProps = {};

type StateProps = {
    collection?: merchCollection;
    merchCatalog: merchCatalog;
    hydratingIdentifiers: merchIdentifiers[];
    getMerchCollectionsInProgress: boolean;
    getMerchCollectionsCompleted: boolean;
    hydrateMerchCompleted: boolean;
    artist?: artist;
    teamId?: string;
    locale: string;
    signedIn: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    searchCollections: (payload: searchMerchCollectionsPayload) => void;
    hydrateMerchIdentifiers: (payload: hydrateMerchPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    inputText: string;
};

export class SeeAllMerchScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            inputText: "",
        };
    }
    componentDidMount() {
        if (!this.props.artist?.asin) {
            //return user to artist select screen if no artist chosen
            this.props.history.push(paths.artistSelect);
            return;
        }

        if (!this.props.collection) {
            const searchCollectionsPayload: searchMerchCollectionsPayload = {
                requestPath: pagePath,
                teamId: this.props.teamId as string,
                request: {
                    artistAsin: this.props.artist?.asin,
                },
            };
            this.props.searchCollections(searchCollectionsPayload);
        } else {
            this.loadAllMerch();
        }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            (!this.props.getMerchCollectionsInProgress &&
                prevProps.getMerchCollectionsInProgress) ||
            this.props.merchCatalog !== prevProps.merchCatalog
        ) {
            //hydration call
            this.loadAllMerch();
        }
    }
    render() {
        if (!this.props.getMerchCollectionsCompleted) {
            return <Loading />;
        }
        const loadedCollection = this.props.collection as merchCollection;
        const searchResultRows: MerchListItemProps[] =
            this.filterAndTransformCollection(
                loadedCollection.items,
                this.props.merchCatalog,
                this.props.hydratingIdentifiers,
                this.state.inputText
            );
        return (
            <>
                <ArtistGradientBackgroundImage artist={this.props.artist} />
                <Container
                    fluid={true}
                    className="rootContainer"
                    style={{
                        ...rootStyles.containerStyles.rootViewContainer,
                        //16px of padding from parent element of rootContainer
                        height: "calc(100vh - 16px)",
                        display: "flex",
                        backgroundColor: "transparent",
                    }}
                >
                    <MerchSearchHeader
                        hideImage={true}
                        imageDescription={getLocalizedString(
                            this.props.bundleMap,
                            {
                                bundleId: bundleIds.MERCHCURATION_STRINGS,
                                stringId:
                                    stringIds.Merch.Curation.YourProductsCount,
                            },
                            { "0": loadedCollection.items.length.toString() }
                        )}
                        showBackButton={true}
                        showArtistImgDropdown={true}
                        backButtonOnClick={this.onBackButtonClick}
                    />
                    <Col
                        style={{
                            marginTop: rootStyles.spacers.giant,
                            marginBottom: rootStyles.spacers.medium,
                            flex: 0,
                        }}
                    >
                        {/* styling based on figma: https://www.figma.com/file/Dmvy5ETVocepzeetGGh1fZ/Merch-Curation---Comps_101 */}
                        <Search
                            value={this.state.inputText}
                            onChange={this.updateText}
                            textInputStyle={{
                                ...rootStyles.textStyles.secondary,
                                color: rootStyles.glassColors.primary4,
                            }}
                            dark={true}
                            placeholder={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                                    stringId:
                                        stringIds.Merch.Curation
                                            .SearchProductsYouHave,
                                }
                            )}
                            containerStyle={{
                                borderColor: rootStyles.glassColors.primary4,
                            }}
                            searchIcon={IconsList.merch_search}
                            inputPlaceholderColor={
                                rootStyles.glassColors.primary4
                            }
                            id={`${testIDPrefix}_Search`}
                        />
                    </Col>
                    {/* only want to display the no results screen if there are no search results AND there are items in the collection */}
                    {!searchResultRows.length &&
                    loadedCollection.items.length ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                alignItems: "center",
                            }}
                        >
                            <Row
                                style={{
                                    ...rootStyles.textStyles.primary,
                                    color: rootStyles.colors.primary,
                                    marginTop: rootStyles.spacers.epic,
                                    marginBottom: rootStyles.spacers.medium,
                                }}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                                    stringId:
                                        stringIds.Merch.Curation
                                            .NoProductsFound,
                                })}
                            </Row>
                            <LargeOutlineButton
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId:
                                            bundleIds.MERCHCURATION_STRINGS,
                                        stringId:
                                            stringIds.Merch.Curation
                                                .ReturnToList,
                                    }
                                )}
                                onClick={() => {
                                    this.setState({
                                        inputText: "",
                                    });
                                }}
                                id={`${testIDPrefix}_ReturnToList`}
                            />
                        </div>
                    ) : (
                        <MerchListItemTable rows={searchResultRows} />
                    )}
                </Container>
            </>
        );
    }

    private filterAndTransformCollection = (
        identifiers: merchIdentifiers[],
        catalog: merchCatalog,
        hydratingIdentifiers: merchIdentifiers[],
        inputText: string
    ): MerchListItemProps[] => {
        if (inputText.length) {
            //if need to filter, then return only hydrated items
            const hydratedItems = _.filter(identifiers, (asin) =>
                catalog.has(asin)
            ).map((asin) => {
                return catalog.get(asin) as hydratedMerchItem;
            });
            return hydratedItems
                .filter((item) => {
                    return this.hydratedMerchKeywordFilter(item, inputText);
                })
                .map((item: hydratedMerchItem) =>
                    generateListItemFromMerchItem({
                        result: item,
                        id: `${testIDPrefix}-${item.itemAsin}`,
                        hideCheckbox: true,
                        showCuratedItemFeatures: true,
                        requestPath: paths.seeAllMerch,
                        pageId: pageIds.artistMerchSeeAllScreen,
                    })
                );
        } else {
            //if no filtering, then return all rows
            const allRows = identifiers.map((asin) => {
                if (catalog.has(asin)) {
                    const hydratedMerchItem = catalog.get(
                        asin
                    ) as hydratedMerchItem;
                    return generateListItemFromMerchItem({
                        result: hydratedMerchItem,
                        id: `${testIDPrefix}-${asin}`,
                        hideCheckbox: true,
                        showCuratedItemFeatures: true,
                        requestPath: paths.seeAllMerch,
                        pageId: pageIds.artistMerchSeeAllScreen,
                    });
                } else if (_.includes(hydratingIdentifiers, asin)) {
                    const loadingRow: MerchListItemProps = {
                        isLoading: true,
                        itemAsin: asin,
                        hideCheckbox: true,
                        showCuratedItemFeatures: true,
                        requestPath: paths.seeAllMerch,
                        pageId: pageIds.artistMerchSeeAllScreen,
                        id: `${testIDPrefix}-${asin}`,
                    };
                    return loadingRow;
                } else {
                    const couldNotLoadRow: MerchListItemProps = {
                        primaryText: getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.GENERIC_STRINGS,
                            stringId: stringIds.Generic.missingCatalogDataTitle,
                        }),
                        itemAsin: asin,
                        hideCheckbox: true,
                        showCuratedItemFeatures: true,
                        requestPath: paths.seeAllMerch,
                        pageId: pageIds.artistMerchSeeAllScreen,
                        id: `${testIDPrefix}-${asin}`,
                    };
                    return couldNotLoadRow;
                }
            });
            return allRows;
        }
    };

    private onBackButtonClick = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Merch.goBack,
                pageIds.artistMerchSeeAllScreen,
                this.props.artist?.asin,
                EntityType.ARTIST
            )
        );
        this.props.history.goBack();
    };

    private loadAllMerch = () => {
        if (!this.props.signedIn || this.props.collection === undefined) {
            return;
        }

        const asinsToHydrate = _.filter(this.props.collection.items, (asin) => {
            //filter items that are not in catalog and not in hydrating asins
            return (
                !_.includes(Array.from(this.props.merchCatalog.keys()), asin) ||
                !_.includes(this.props.hydratingIdentifiers, asin)
            );
        });

        const identifiersToHydrate: drTeethMerchIdentifiers[] =
            asinsToHydrate.map((asin) => {
                return { itemAsin: asin };
            });
        const hydrateMerchPayload: hydrateMerchPayload = {
            requestPath: pagePath,
            request: {
                identifiers: identifiersToHydrate,
                userContext: {
                    //this will always be US marketplace for DrTeethMerch for now
                    marketplaceId: US_MARKETPLACE,
                    locale: this.props.locale,
                    deviceFamily: merchDeviceFamily.AM4A_WEB,
                },
            },
        };
        this.props.hydrateMerchIdentifiers(hydrateMerchPayload);
    };

    private updateText = (textObject: any) => {
        const text = textObject.target.value ? textObject.target.value : "";
        this.setState({
            inputText: text,
        });
    };

    private hydratedMerchKeywordFilter = (
        item: hydratedMerchItem,
        filterString: string
    ): boolean => {
        if (filterString.length > 0) {
            const searchTextToLowercase = filterString.toLowerCase();

            const asinToLowerCase = item.itemAsin.toLowerCase();
            const asinMatch =
                asinToLowerCase.includes(searchTextToLowercase) ||
                searchTextToLowercase.includes(asinToLowerCase);
            if (asinMatch) {
                return true;
            }

            const categoryToLowerCase = item.metadata.category?.toLowerCase();
            if (categoryToLowerCase) {
                const categoryMatch =
                    categoryToLowerCase.includes(searchTextToLowercase) ||
                    searchTextToLowercase.includes(categoryToLowerCase);
                if (categoryMatch) {
                    return true;
                }
            }

            const priceToLowerCase = item.metadata.price?.toLowerCase();
            if (priceToLowerCase) {
                const priceMatch =
                    priceToLowerCase.includes(searchTextToLowercase) ||
                    searchTextToLowercase.includes(priceToLowerCase);
                if (priceMatch) {
                    return true;
                }
            }

            const itemNameToLowercase = item.metadata.itemName.toLowerCase();
            const nameMatch =
                itemNameToLowercase.includes(searchTextToLowercase) ||
                searchTextToLowercase.includes(itemNameToLowercase);
            if (nameMatch) {
                return true;
            }

            return false;
        }

        return true;
    };
}

function mapStateToProps(state: RootState): StateProps {
    return seeAllMerchScreenSelector(state);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        searchCollections: (payload: searchMerchCollectionsPayload) =>
            dispatch(merchCurateActions.getMerchCollections(payload)),
        hydrateMerchIdentifiers: (payload: hydrateMerchPayload) =>
            dispatch(merchHydratorActions.hydrateMerch(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SeeAllMerchScreen)
);
