import _ from "lodash";
import { Action } from "redux-ts";
import { put, select, takeEvery } from "redux-saga/effects";
import { userActions } from "../actions";
import { RootState } from "../reducers";
import { localStorageStrings } from "../../models";

export const deeplinkSagas = [watchGoToDeeplink()];

function* resetDeeplink(action: Action) {
    const deeplinkPath: string = yield select(
        (state: RootState) => state.user.deeplinkMap?.path
    );
    yield put(userActions.updateCurrentPath(deeplinkPath));
    yield put(userActions.updateDeeplinkRoute(undefined));
    localStorage.removeItem(localStorageStrings.deeplinkUrl);
}

function* watchGoToDeeplink() {
    yield takeEvery(userActions.resetDeeplink.type, resetDeeplink);
}
