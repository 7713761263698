import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { useDispatch } from "react-redux";
import { CustomerSupportData, IntercomEvent, userData } from "../../../models";
import { oAuthActions, opsMetricsActions } from "../../../store/actions";
import { createSuccessOpsMetricsPayload } from "../../../utils";

type CustomerSupportHomeProps = {
    showIntercomMessenger: boolean;
    customerSupport?: CustomerSupportData;
    intercomEvent?: IntercomEvent;
    hideIntercomLauncher: boolean;
    locale: string;
    userData: userData;
};

const CustomerSupportHome: React.FC<CustomerSupportHomeProps> = ({
    showIntercomMessenger,
    hideIntercomLauncher,
    customerSupport,
    intercomEvent,
    locale,
    userData,
}) => {
    const userHash =
        customerSupport && customerSupport.intercomData
            ? customerSupport.intercomData.userHash
            : "";

    const directedId =
        customerSupport && customerSupport.intercomData
            ? customerSupport.intercomData.directedId
            : "";

    const trackIntercomEvent = (intercomEvent: string) => {
        trackEvent(intercomEvent);
    };

    const dispatch = useDispatch();
    const { boot, trackEvent, show, update } = useIntercom();

    useEffect(() => {
        const am4aProfile = `https://console.harmony.a2z.com/dr-teeth-operations-portal/teamDetails/userDetails/${directedId}?idType=intercom`;
        boot({
            hideDefaultLauncher: hideIntercomLauncher,
            userHash: userHash,
            userId: directedId,
            name: userData.userName,
            customAttributes: {
                "AM4A Profile": am4aProfile,
                "AM4A Locale": locale,
            },
        });
        dispatch(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload("IntercomUserUpdatedSuccess")
            )
        );

        dispatch(
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload("IntercomInitializationSuccess")
            )
        );
    }, []);

    useEffect(() => {
        if (hideIntercomLauncher) {
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload("IntercomShowLauncherSuccess")
            );
        }
        update({
            hideDefaultLauncher: hideIntercomLauncher,
        });
    }, [hideIntercomLauncher]);

    useEffect(() => {
        if (showIntercomMessenger) {
            show();
            dispatch(oAuthActions.setShowIntercomMessenger(false));
            dispatch(
                opsMetricsActions.batchMetric(
                    createSuccessOpsMetricsPayload(
                        "IntercomShowMessengerSuccess"
                    )
                )
            );
        }
    }, [showIntercomMessenger]);

    // handle intercom event
    useEffect(() => {
        if (intercomEvent && intercomEvent !== IntercomEvent.NONE) {
            trackIntercomEvent(intercomEvent.toString());
            opsMetricsActions.batchMetric(
                createSuccessOpsMetricsPayload("IntercomTriggerEventSuccess")
            );
            dispatch(oAuthActions.setIntercomEvent(IntercomEvent.NONE));
        }
    }, [intercomEvent]);
    return <></>;
};

export default CustomerSupportHome;
