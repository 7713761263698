export enum FeatureName {
    AddToPlaylist = "AddToPlaylist",
    CookieConsent = "CookieConsent",
}

export const SEEN_FEATURE_STORAGE_KEY = "seenFeature";

export function markFeatureAsSeen(feature: FeatureName) {
    const storedSeenFeaturesString = localStorage.getItem(
        SEEN_FEATURE_STORAGE_KEY
    );
    const storedSeenFeatures: FeatureName[] = storedSeenFeaturesString
        ? JSON.parse(storedSeenFeaturesString)
        : [];

    if (
        storedSeenFeatures.length === 0 ||
        !storedSeenFeatures.includes(feature)
    ) {
        storedSeenFeatures.push(feature);
    }
    localStorage.setItem(
        SEEN_FEATURE_STORAGE_KEY,
        JSON.stringify(storedSeenFeatures)
    );
}

export function retrieveSeenFeatures() {
    const storedSeenFeatureSetString = localStorage.getItem(
        SEEN_FEATURE_STORAGE_KEY
    );
    const storedSeenFeatures: FeatureName[] = storedSeenFeatureSetString
        ? JSON.parse(storedSeenFeatureSetString)
        : [];
    return storedSeenFeatures;
}
