import { createAction } from "redux-ts";
import {
    catalog,
    hydrateCatalogPayload,
    recentlyAddedToPlaylistPayload,
    recentlyAddedToPlaylistCompletePayload,
    generateShareLinkPayload,
    shareContentResponse,
    getMarqueePlaylistsPayload,
    getMarqueePlaylistsCompletePayload,
    generatePromoCardShareLinkPayload,
} from "../../models";

export const hydrateAsins = createAction<hydrateCatalogPayload>(
    "CATALOG::HYDRATE_ASINS"
);
export const hydratingAsins = createAction<string[]>(
    "CATALOG::HYDRATING_ASINS"
);
export const hydrationInProgress = createAction<boolean>(
    "CATALOG::HYDRATING_ASINS_IN_PROGRESS"
);

export const failedAsins = createAction<string[]>("CATALOG::FAILED_ASINS");
export const clearFailedAsins = createAction("CATALOG::CLEAR_FAILED_ASINS");

export const buildCatalogCompleted = createAction<catalog>(
    "CATALOG::BUILD_CATALOG_COMPLETED"
);
export const clearState = createAction("CATALOG::CLEAR_STATE");

export const getRecentlyAddedToPlaylists =
    createAction<recentlyAddedToPlaylistPayload>("CATALOG::GET_RECENTLY_ADDED");
export const getRecentlyAddedToPlaylistsInProgress = createAction<boolean>(
    "CATALOG::GET_RECENTLY_ADDED_IN_PROGRESS"
);
export const getRecentlyAddedToPlaylistsComplete =
    createAction<recentlyAddedToPlaylistCompletePayload>(
        "CATALOG::GET_RECENTLY_ADDED_COMPLETE"
    );

export const generateShareLink = createAction<generateShareLinkPayload>(
    "CATALOG::GENERATE_SHARE_LINK"
);

export const generateShareLinkComplete = createAction<shareContentResponse>(
    "CATALOG::GENERATE_SHARE_LINK_COMPLETE"
);

export const generateShareLinkInProgress = createAction<boolean>(
    "CATALOG::GENERATE_SHARE_LINK_IN_PROGRESS"
);

export const getMarqueePlaylists = createAction<getMarqueePlaylistsPayload>(
    "CATALOG::GET_MARQUEE_PLAYLISTS"
);

export const getMarqueePlaylistsComplete =
    createAction<getMarqueePlaylistsCompletePayload>(
        "CATALOG::GET_MARQUEE_PLAYLISTS_COMPLETE"
    );

export const generatePromoCardShareLink =
    createAction<generatePromoCardShareLinkPayload>(
        "CATALOG::GENERATE_PROMO_CARD_SHARE_LINK"
    );

export const generatePromoCardShareLinkComplete = createAction<string>(
    "CATALOG::GENERATE_PROMO_CARD_SHARE_LINK_COMPLETE"
);

export const generatePromoCardShareLinkInProgress = createAction<boolean>(
    "CATALOG::GENERATE_PROMO_CARD_SHARE_LINK_IN_PROGRESS"
);

export const clearPromoCardShareLink = createAction(
    "CATALOG::CLEAR_PROMO_CARD_SHARE_LINK"
);
