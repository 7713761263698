import * as React from "react";
import * as rootStyles from "../../../styles";
import { Icon, IconsList } from "../icons/Icon";
import { SmallOutlineButton } from "./Buttons";

export type RadioElementProps = {
    primary: string;
    secondary?: string;
    selected: boolean;
    onButtonSelected: () => void;
    key: number;
    id: string;
    primaryTextNoWrap?: boolean;
};

type State = {
    selected: boolean;
};

export class RadioElement extends React.Component<RadioElementProps, State> {
    constructor(props: RadioElementProps) {
        super(props);
        this.state = {
            selected: this.props.selected,
        };
    }

    componentDidUpdate(prevProps: RadioElementProps) {
        if (this.props.selected !== prevProps.selected) {
            this.setState({ selected: this.props.selected });
        }
    }

    render() {
        return (
            <div style={radioContainer} onClick={this.props.onButtonSelected}>
                <div style={iconContainer}>{this.getIcon()}</div>
                <div>
                    <div>
                        <span
                            style={{
                                ...rootStyles.textStyles.primary,
                                whiteSpace: this.props.primaryTextNoWrap
                                    ? "nowrap"
                                    : "normal",
                            }}
                        >
                            {this.props.primary}
                        </span>
                    </div>
                    <div>
                        {this.props.secondary && (
                            <span
                                style={{
                                    ...rootStyles.textStyles.secondary,
                                    paddingTop: rootStyles.spacers.small,
                                }}
                            >
                                {this.props.secondary}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private getIcon = () => {
        if (this.state.selected) {
            return (
                <Icon
                    icon={IconsList.list_check_accent}
                    size={rootStyles.icons.small}
                    color={rootStyles.colors.accent}
                    id={`${this.props.id}_RadioButton`}
                />
            );
        } else {
            return (
                <SmallOutlineButton
                    buttonStyle={unselectedCircle}
                    onClick={() => null} // this is handled in parent elements
                    id={`${this.props.id}_RadioButton`}
                />
            );
        }
    };
}

const radioContainer: React.CSSProperties = {
    paddingTop: rootStyles.spacers.mini,
    paddingBottom: rootStyles.spacers.mini,
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center",
};

const iconContainer: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: rootStyles.spacers.base,
    paddingLeft: 0,
};

const unselectedCircle: React.CSSProperties = {
    borderWidth: 2,
    padding: 0,
    borderRadius: rootStyles.icons.small / 2,
    height: rootStyles.icons.small,
    width: rootStyles.icons.small,
    borderColor: rootStyles.glassColors.primary3,
};
