import { Action } from "redux-ts";
import { albumRelease, trackInfoForPitch } from "../../models";
import { pitchFormData, pitchPage as pitchPage } from "../../models/pitch";
import { pitchActions } from "../actions";

export type PitchState = Readonly<{
    createPitchInProgress: boolean;
    createdPitchSucess?: boolean;
    pitchPage?: pitchPage;
    getPitchFormDataInProgress: boolean;
    pitchFormData?: pitchFormData;
    listAlbumsToPitchInProgress: boolean;
    albumsToPitch?: albumRelease[];
    listTracksToPitchInProgress: boolean;
    tracksToPitch?: trackInfoForPitch[];
    listRelatedArtistsInProgess: boolean;
    relatedArtists?: string[];
}>;

export const initialPitchState: PitchState = {
    createPitchInProgress: false,
    getPitchFormDataInProgress: false,
    listAlbumsToPitchInProgress: false,
    listTracksToPitchInProgress: false,
    listRelatedArtistsInProgess: false,
};

export const pitchReducer = (
    state: PitchState = initialPitchState,
    action: Action
) => {
    switch (action.type) {
        case pitchActions.createPitchInProgress.type: {
            return {
                ...state,
                createPitchInProgress: action.payload,
            };
        }
        case pitchActions.createPitchCompleted.type: {
            return {
                ...state,
                createdPitchSucess: action.payload,
            };
        }
        case pitchActions.getPitchFormDataInProgress.type: {
            return {
                ...state,
                getPitchFormDataInProgress: action.payload,
            };
        }
        case pitchActions.getPitchFormDataCompleted.type: {
            return {
                ...state,
                pitchFormData: action.payload,
            };
        }
        case pitchActions.listAlbumsToPitchInProgress.type: {
            return {
                ...state,
                listAlbumsToPitchInProgress: action.payload,
            };
        }
        case pitchActions.listAlbumsToPitchComplete.type: {
            return {
                ...state,
                albumsToPitch: action.payload.releases,
            };
        }
        case pitchActions.listTracksToPitchInProgress.type: {
            return {
                ...state,
                listTracksToPitchInProgress: action.payload,
            };
        }
        case pitchActions.listTracksToPitchComplete.type: {
            return {
                ...state,
                tracksToPitch: action.payload.tracks,
            };
        }
        case pitchActions.listRelatedArtistsInProgress.type: {
            return {
                ...state,
                listRelatedArtistsInProgess: action.payload,
            };
        }
        case pitchActions.listRelatedArtistsComplete.type: {
            return {
                ...state,
                relatedArtists: action.payload.artists,
            };
        }
        default:
            return state;
    }
};
