import { getBundleMap } from "./../commonSelectors";
import { createSelector } from "reselect";
import _ from "lodash";
import { RootState } from "../../reducers";
import {
    getUserLocale,
    selectedArtistFromCatalogSelector,
} from "../commonSelectors";
import { getSelectedTeamSelector } from "../teamManagementSelectors";
import {
    getCurateMerchInProgress,
    getMerchCollection,
    getMerchCollectionsInProgress,
    getMerchSearchInProgressSelector,
    getMerchSearchResultsSelector,
    hasMODPermission,
    showModSelector,
} from "./merchCurateCommonSelectors";

export const autoSearchScreenSelector = createSelector(
    [
        getMerchCollection,
        getMerchCollectionsInProgress,
        getCurateMerchInProgress,
        getMerchSearchInProgressSelector,
        getMerchSearchResultsSelector,
        selectedArtistFromCatalogSelector,
        getSelectedTeamSelector,
        getUserLocale,
        hasMODPermission,
        showModSelector,
        getBundleMap,
    ],
    (
        collection,
        getMerchCollectionsInProgress,
        curateMerchInProgress,
        searchMerchInProgress,
        searchMerchResultItems,
        artist,
        teamInfo,
        locale,
        hasMerchIngressPermissions,
        showMod,
        bundleMap
    ) => {
        return {
            collection: collection,
            getMerchCollectionsInProgress: getMerchCollectionsInProgress,
            curateMerchInProgress,
            searchMerchInProgress: searchMerchInProgress,
            searchMerchResultItems: searchMerchResultItems,
            artist: artist,
            teamId: teamInfo.teamId,
            locale: locale,
            hasMerchIngressPermissions: hasMerchIngressPermissions,
            showMod,
            bundleMap: bundleMap,
        };
    }
);
