import axios from "axios";
import {
    GetFeaturePermissionsRequest,
    GetFeaturePermissionsResponse,
    GetGlobalPermissionsRequest,
    GetGlobalPermissionsResponse,
} from "../models";
import { getEndpoints, testHeaders } from "./common";

const teamManagementUrl = getEndpoints().teamManagement;

const getFeaturePermissionsUrl = `${teamManagementUrl}/teammanagement/featurePermissions/getPermissions`;

export async function getFeaturePermissions(
    query: GetFeaturePermissionsRequest
): Promise<GetFeaturePermissionsResponse> {
    const http = axios.create();

    const result = await http.post(
        getFeaturePermissionsUrl,
        {
            artistAsin: query.artistAsin,
            upToDateFeatures: query.upToDateFeatures,
        },
        {
            headers: {
                "x-team-id": query.teamId,
                ...testHeaders,
            },
        }
    );
    return result.data;
}

export async function getGlobalPermissions(
    query: GetGlobalPermissionsRequest
): Promise<GetGlobalPermissionsResponse> {
    const http = axios.create();

    const result = await http.post(
        getFeaturePermissionsUrl,
        {
            upToDateFeatures: query.upToDateFeatures,
        },
        {
            headers: {
                ...testHeaders(),
            },
        }
    );
    return result.data;
}
