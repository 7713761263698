import React from "react";
import Lottie from "lottie-react";
import { LottieAnimationFiles } from "../../../../assets/animations";
import { ImageWithFallback } from "../../common";
import * as rootStyles from "../../../styles";

export type ArtistImageSpinnerProps = {
    artistImage?: string;
    id: string;
};

const DEFAULT_IMAGE_SIZE = 96; // from UX

export const ArtistImageSpinner: React.FC<ArtistImageSpinnerProps> = ({
    artistImage,
    id,
}) => {
    return (
        <div
            style={{
                width: DEFAULT_IMAGE_SIZE + rootStyles.spacers.large,
                height: DEFAULT_IMAGE_SIZE + rootStyles.spacers.large,
                justifyContent: "center",
                alignItems: "center",
                borderRadius:
                    (DEFAULT_IMAGE_SIZE + rootStyles.spacers.large) / 2,
                display: "flex",
            }}
        >
            <Lottie
                animationData={LottieAnimationFiles.MerchCuration.searchLoading}
                autoPlay={true}
                loop={true}
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                }}
            >
                <ImageWithFallback
                    style={{
                        resizeMode: "contain",
                        height: DEFAULT_IMAGE_SIZE,
                        width: DEFAULT_IMAGE_SIZE,
                        borderRadius: DEFAULT_IMAGE_SIZE / 2,
                        position: "absolute",
                    }}
                    source={artistImage}
                    id={`${id}_Image`}
                />
            </Lottie>
        </div>
    );
};
