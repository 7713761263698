import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container } from "react-bootstrap";
import {
    ArtistGradientBackgroundImage,
    ArtistImageSpinner,
} from "../../components/";
import _ from "lodash";
import { CommonHeader, FTUFooterLinks, ModOnlyDisplay } from "../../components";
import { buildUIClickPayload, getLocalizedString, paths } from "../../../utils";
import * as rootStyles from "../../styles";
import {
    clientMetricsActions,
    merchCurateActions,
    merchHydratorActions,
    merchOverviewSelector,
    RootState,
    userActions,
} from "../../../store";
import {
    artist,
    clientMetricsPayload,
    merchCollection,
    hydrateMerchPayload,
    merchCatalog,
    searchMerchCollectionsPayload,
    merchIdentifiers,
    EntityType,
    BundleMap,
} from "../../../models";
import { pageIds, stringIds, bundleIds } from "@amzn/ziggy-asset";
import ModAndCurateDisplay from "../../components/merch/ModAndCurateDisplay";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";

const testIDPrefix = "MerchOverviewScreen";
const pagePath = paths.merch;

type ViewProps = {};

type StateProps = {
    artist?: artist;
    teamId?: string;
    collection?: merchCollection;
    getMerchCollectionsInProgress: boolean;
    merchCatalog: merchCatalog;
    hydratingIdentifiers: merchIdentifiers[];
    signedIn: boolean;
    locale: string;
    merchCuratePermission: boolean;
    showMerchCurate: boolean;
    hasMerchIngressPermissions: boolean;
    selectedArtistAsin?: string;
    showMod: boolean;
    bundleMap: BundleMap;
};

type DispatchProps = {
    updateCurrentPath: (payload: string) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    searchCollections: (payload: searchMerchCollectionsPayload) => void;
    hydrateMerchIdentifiers: (payload: hydrateMerchPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    finishedInitialLoad: boolean;
};

class MerchOverviewScreen extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            finishedInitialLoad: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (
            !this.state.finishedInitialLoad &&
            !this.props.getMerchCollectionsInProgress &&
            prevProps.getMerchCollectionsInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }
    }

    componentDidMount() {
        this.props.updateCurrentPath(window.location.pathname);
        if (this.props.showMerchCurate) {
            const searchCollectionsPayload: searchMerchCollectionsPayload = {
                teamId: this.props.teamId as string,
                requestPath: pagePath,
                request: {
                    artistAsin: this.props.artist?.asin as string,
                },
            };
            this.props.searchCollections(searchCollectionsPayload);
        }
    }

    private emitButtonClickMetrics = (key: string, pageId: string) => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                key,
                pageId,
                this.props.selectedArtistAsin,
                EntityType.ARTIST
            )
        );
    };

    render() {
        let bodyComponent;
        if (this.props.showMerchCurate) {
            bodyComponent = (
                <ModAndCurateDisplay
                    merchCuratePermission={this.props.merchCuratePermission}
                    hasMerchIngressPermissions={
                        this.props.hasMerchIngressPermissions
                    }
                    showMod={this.props.showMod}
                />
            );
        } else if (this.props.showMod) {
            bodyComponent = <ModOnlyDisplay {...this.props} />;
        }
        const bodyWithFooter =
            this.props.getMerchCollectionsInProgress &&
            !this.state.finishedInitialLoad ? (
                <div
                    className="rootContainer"
                    style={{
                        //16px of padding from parent element of rootContainer
                        height: "calc(100vh - 16px)",
                        display: "flex",
                        width: "100%",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ArtistImageSpinner
                        artistImage={this.props.artist?.images?.artMedium}
                        id={`${testIDPrefix}_LoadingSpinner`}
                    />
                </div>
            ) : (
                <>
                    <CommonHeader
                        title={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.MERCH_STRINGS,
                            stringId: stringIds.Merch.merch_title,
                        })}
                        subtitle={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.NAVIGATION_STRINGS,
                            stringId: stringIds.Navigation.artistToolsTitle,
                        })}
                        id={`${testIDPrefix}_Header`}
                        hideDatePicker={true}
                    />
                    {bodyComponent}
                    <FTUFooterLinks emitMetrics={this.emitButtonClickMetrics} />
                </>
            );
        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                <ArtistGradientBackgroundImage artist={this.props.artist} />
                {bodyWithFooter}
            </Container>
        );
    }
}

function mapStateToProps(state: RootState): StateProps {
    return {
        ...merchOverviewSelector(state),
        ...bundleMapSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        searchCollections: (payload: searchMerchCollectionsPayload) =>
            dispatch(merchCurateActions.getMerchCollections(payload)),
        hydrateMerchIdentifiers: (payload: hydrateMerchPayload) =>
            dispatch(merchHydratorActions.hydrateMerch(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MerchOverviewScreen)
);
