import React from "react";
import { Modal, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { buttonIds, stringIds, bundleIds } from "@amzn/ziggy-asset";
import {
    BlurredModal,
    Icon,
    IconsList,
    ImageWithFallback,
    InlineError,
    LargeSolidButton,
    MediumGlassButton,
    styledTitle,
} from ".";
import { getLocalizedString } from "../../../utils";
import * as rootStyles from "../../styles";
import { BundleMap } from "../../../models";

export type BaseShareModalProps = {
    isVisible: boolean;
    onHide: () => void;
    loadingInProgress: boolean;
    modalTitle: string;
    modalSubtitle: string;
    id: string;
    contentType: string;
    contentTitle: string;
    contentSecondary?: string;
    contentTertiary?: string;
    contentImage?: string;
    fallbackImageSource: string;
    shareLink?: string;
    embedTag?: string;
    messageText: string;
    showError?: boolean;

    sendShareClickMetric?: (buttonId: string) => void;
    bundleMap: BundleMap;
    onCreatePromoCard?: () => void;
    showHypeDeck?: boolean;
};

type State = {
    isCopyLinkSelected: boolean;
    showEmbedTag: boolean;
};

export class BaseShareModal extends React.Component<
    BaseShareModalProps,
    State
> {
    constructor(props: BaseShareModalProps) {
        super(props);
        this.state = {
            isCopyLinkSelected: false,
            showEmbedTag: false,
        };
    }

    render() {
        const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            this.props.shareLink || ""
        )}`;
        // twitter share url persists after X rebrand
        const xLink = `https://twitter.com/intent/tweet?text=${
            this.props.messageText
        }&url=${encodeURIComponent(this.props.shareLink || "")}`;
        const emailLink = `mailto:?subject=${this.props.contentTitle}&body=${
            this.props.messageText
        } ${encodeURIComponent(this.props.shareLink || "")}`;

        return (
            <ModalContainer>
                <BlurredModal
                    id="blurred-modal"
                    show={this.props.isVisible}
                    centered
                    onHide={this.onHideShareContent}
                >
                    {(this.props.loadingInProgress ||
                        !this.props.shareLink) && (
                        <Spinner
                            animation="border"
                            variant="light"
                            style={{ alignSelf: "center" }}
                        />
                    )}

                    {!this.props.loadingInProgress && this.props.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.PROMO_CARD_STRINGS,
                                stringId:
                                    stringIds.PromoCard
                                        .nonShareableContentMessage,
                            })}
                            onDismiss={this.onHideShareContent}
                            id={`${this.props.id}_Error`}
                        />
                    )}

                    {!this.props.loadingInProgress &&
                        this.props.shareLink &&
                        !this.props.showError && (
                            <Modal.Body>
                                <Row
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        marginBottom: rootStyles.spacers.medium,
                                    }}
                                >
                                    <div
                                        onClick={this.onHideShareContent}
                                        style={IconContainerStyle}
                                    >
                                        <Icon
                                            icon={IconsList.action_cancel}
                                            size={rootStyles.icons.small}
                                            id={`${this.props.id}_Cancel`}
                                        />
                                    </div>
                                </Row>
                                <Row
                                    style={{
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: rootStyles.spacers.medium,
                                    }}
                                >
                                    <styledTitle.h4>
                                        {this.props.modalTitle}
                                    </styledTitle.h4>
                                    <p
                                        style={{
                                            ...rootStyles.textStyles.secondary,
                                            margin: 0,
                                        }}
                                    >
                                        {this.props.modalSubtitle}
                                    </p>
                                </Row>
                                <Row
                                    style={{
                                        display: "flex",
                                        flexFlow: "row nowrap",
                                        alignItems: "center",
                                        marginBottom: rootStyles.spacers.medium,
                                        minHeight: "100px",
                                    }}
                                >
                                    <ImageWithFallback
                                        source={this.props.contentImage}
                                        fallback={
                                            this.props.fallbackImageSource
                                        }
                                        imageDescription={
                                            this.props.contentTitle
                                        }
                                        style={{
                                            height: 100,
                                            width: 100,
                                        }}
                                        id={`${this.props.id}_ContentImage`}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            marginLeft:
                                                rootStyles.spacers.small,
                                        }}
                                    >
                                        <p
                                            style={{
                                                ...subtitleStyle,
                                                marginBottom: 0,
                                            }}
                                        >
                                            {this.props.contentType}
                                        </p>
                                        <p
                                            style={{
                                                ...rootStyles.textStyles
                                                    .primary,
                                                marginBottom: 0,
                                            }}
                                        >
                                            {this.props.contentTitle}
                                        </p>
                                        {this.props.contentSecondary && (
                                            <p
                                                style={{
                                                    ...rootStyles.textStyles
                                                        .secondary,
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {this.props.contentSecondary}
                                            </p>
                                        )}
                                        {this.props.contentTertiary && (
                                            <p
                                                style={{
                                                    ...rootStyles.textStyles
                                                        .secondary,
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {this.props.contentTertiary}
                                            </p>
                                        )}
                                    </div>
                                </Row>
                                <Row
                                    style={
                                        rootStyles.containerStyles
                                            .ShareLinkRowStyle
                                    }
                                >
                                    <p style={shareLinkStyle}>
                                        {!this.state.showEmbedTag &&
                                            this.props.shareLink}
                                        {this.state.showEmbedTag &&
                                            this.props.embedTag}
                                    </p>
                                </Row>
                                <Row
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <a
                                            href={emailLink}
                                            style={IconContainerWithMarginStyle}
                                            target={hrefTarget}
                                            rel={hrefRel}
                                            onClick={this.onSelectShareOnEmail}
                                        >
                                            <Icon
                                                icon={
                                                    IconsList.action_shareemail
                                                }
                                                size={rootStyles.icons.small}
                                                id={`${this.props.id}_Email`}
                                            />
                                        </a>
                                        {this.props.embedTag && (
                                            <div
                                                onClick={
                                                    this.onSelectShareOnEmbed
                                                }
                                                style={
                                                    IconContainerWithMarginStyle
                                                }
                                            >
                                                <Icon
                                                    icon={
                                                        IconsList.action_shareembed
                                                    }
                                                    size={
                                                        rootStyles.icons.small
                                                    }
                                                    id={`${this.props.id}_Embed`}
                                                />
                                            </div>
                                        )}
                                        <a
                                            href={facebookLink}
                                            style={IconContainerWithMarginStyle}
                                            target={hrefTarget}
                                            rel={hrefRel}
                                            onClick={
                                                this.onSelectShareOnFacebook
                                            }
                                        >
                                            <Icon
                                                icon={
                                                    IconsList.action_sharefacebook
                                                }
                                                size={rootStyles.icons.small}
                                                id={`${this.props.id}_Facebook`}
                                            />
                                        </a>
                                        <a
                                            href={xLink}
                                            style={IconContainerStyle}
                                            target={hrefTarget}
                                            rel={hrefRel}
                                            onClick={this.onSelectShareOnX}
                                        >
                                            <Icon
                                                icon={IconsList.action_share_x}
                                                size={rootStyles.icons.small}
                                                id={`${this.props.id}_X`}
                                            />
                                        </a>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        {this.state.isCopyLinkSelected && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    marginRight:
                                                        rootStyles.spacers
                                                            .small,
                                                }}
                                            >
                                                <Icon
                                                    icon={
                                                        IconsList.action_done_svg
                                                    }
                                                    size={
                                                        rootStyles.icons.small
                                                    }
                                                    style={{
                                                        marginRight:
                                                            rootStyles.spacers
                                                                .mini,
                                                    }}
                                                    id={`${this.props.id}_CopyConfirmed`}
                                                />
                                                <p
                                                    style={{
                                                        ...rootStyles.textStyles
                                                            .primary,
                                                        margin: 0,
                                                    }}
                                                >
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.CONTENTSHARING_STRINGS,
                                                            stringId:
                                                                stringIds
                                                                    .ContentSharing
                                                                    .copyLinkCopiedText,
                                                        }
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                        <MediumGlassButton
                                            className={"ShareContentButton"}
                                            containerStyle={{
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                            onClick={this.onSelectCopyShareLink}
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.CONTENTSHARING_STRINGS,
                                                    stringId:
                                                        stringIds.ContentSharing
                                                            .copyLinkButtonText,
                                                }
                                            )}
                                            leftIcon={
                                                IconsList.action_sharelink
                                            }
                                            id={`${this.props.id}_CopyLinkButton`}
                                        />
                                    </div>
                                </Row>
                                {this.props.onCreatePromoCard &&
                                this.props.showHypeDeck &&
                                this.props.showHypeDeck == true ? (
                                    <Row
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <LargeSolidButton
                                            title={getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.PROMO_CARD_STRINGS,
                                                    stringId:
                                                        stringIds.PromoCard
                                                            .shareButtonText,
                                                }
                                            )}
                                            onClick={
                                                this.onSelectCreatePromoCard
                                            }
                                            id={`${this.props.id}_createPromoCard`}
                                            leftIcon={
                                                IconsList.action_edit_black
                                            }
                                        />
                                    </Row>
                                ) : null}
                            </Modal.Body>
                        )}
                </BlurredModal>
            </ModalContainer>
        );
    }

    private onSelectCreatePromoCard = () => {
        this.props.onHide();

        if (this.props.onCreatePromoCard) {
            this.props.onCreatePromoCard();
        }
    };

    private onHideShareContent = () => {
        this.setState({ isCopyLinkSelected: false, showEmbedTag: false });
        this.props.onHide();
    };

    private onSelectCopyShareLink = () => {
        if (!this.props.shareLink) {
            return;
        }

        if (this.props.sendShareClickMetric) {
            this.props.sendShareClickMetric(
                buttonIds.ShareMenu.shareOnCopyLink
            );
        }

        navigator.clipboard.writeText(this.props.shareLink);
        this.setState({ isCopyLinkSelected: true, showEmbedTag: false });
    };

    private onSelectShareOnEmbed = () => {
        if (!this.props.embedTag) {
            return;
        }

        if (this.props.sendShareClickMetric) {
            this.props.sendShareClickMetric(
                buttonIds.ShareMenu.shareOnEmbedLink
            );
        }

        navigator.clipboard.writeText(this.props.embedTag);
        this.setState({ isCopyLinkSelected: false, showEmbedTag: true });
    };

    private onSelectShareOnFacebook = () => {
        if (!this.props.shareLink) {
            return;
        }

        if (this.props.sendShareClickMetric) {
            this.props.sendShareClickMetric(
                buttonIds.ShareMenu.shareOnFacebook
            );
        }
    };

    private onSelectShareOnEmail = () => {
        if (!this.props.shareLink) {
            return;
        }

        if (this.props.sendShareClickMetric) {
            this.props.sendShareClickMetric(buttonIds.ShareMenu.shareOnEmail);
        }
    };

    private onSelectShareOnX = () => {
        if (!this.props.shareLink) {
            return;
        }

        if (this.props.sendShareClickMetric) {
            // keeping orignal share twitter buttonId to prevent diverging metrics
            this.props.sendShareClickMetric(buttonIds.ShareMenu.shareOnTwitter);
        }
    };
}

const ModalContainer = styled.div`
    .modal-dialog-centered {
        @media (max-width: ${rootStyles.breakpoints.lg}px) {
            justify-content: center;
        }
    }
`;

const IconContainerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 36,
    width: 36,
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: rootStyles.glassColors.primary2,
};

const IconContainerWithMarginStyle: React.CSSProperties = {
    ...IconContainerStyle,
    marginRight: rootStyles.spacers.mini,
};

const subtitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    textAlign: "left",
    color: rootStyles.colors.accent,
};

const shareLinkStyle: React.CSSProperties = {
    padding: 0,
    outline: 0,
    textAlign: "left",
    width: "100%",
    margin: 0,
    ...rootStyles.textStyles.primary,
    color: rootStyles.colors.primary,
    backgroundColor: "transparent",
    resize: "none",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
};

const hrefTarget = "_blank";

const hrefRel = "noopener noreferrer";
