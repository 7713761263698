import * as React from "react";
import { Row, Col } from "react-bootstrap";
import * as rootStyles from "../../../styles";
import { Icon, IconsList } from "../icons/Icon";

export type CheckItemData = {
    label: JSX.Element;
    accessibilityLabel?: string;
};

export type CheckItemProps = {
    data: CheckItemData;
    selected: boolean;
    onButtonToggle: () => void;
    key: number;
    id: string;
};

type State = {
    selected: boolean;
};

export class CheckItem extends React.Component<CheckItemProps, State> {
    constructor(props: CheckItemProps) {
        super(props);
        this.state = {
            selected: this.props.selected,
        };
    }

    componentDidUpdate(prevProps: CheckItemProps) {
        if (this.props.selected !== prevProps.selected) {
            this.setState({ selected: this.props.selected });
        }
    }

    render() {
        return (
            <Row
                style={radioContainer}
                onClick={this.props.onButtonToggle}
                id={`${this.props.id}_Checkbox`}
            >
                <Col style={iconContainer} xs={1} sm={1} md={1} lg={1}>
                    {this.getIcon()}
                </Col>
                <Col>{this.props.data.label}</Col>
            </Row>
        );
    }

    private getIcon = () => {
        if (this.state.selected) {
            return (
                <Icon
                    id={`${this.props.id}_Checked`}
                    icon={IconsList.ic_action_checkbox}
                    size={rootStyles.icons.small}
                    color={rootStyles.colors.accent}
                />
            );
        } else {
            return (
                <Icon
                    id={`${this.props.id}_Unchecked`}
                    icon={IconsList.ic_action_uncheckbox}
                    size={rootStyles.icons.small}
                    color={rootStyles.glassColors.primary3}
                />
            );
        }
    };
}

const radioContainer: React.CSSProperties = {
    flexDirection: "row",
    width: "100%",
    alignSelf: "flex-end",
    cursor: "pointer",
    padding: rootStyles.spacers.mini,
};
const iconContainer: React.CSSProperties = {
    justifyContent: "center",
    alignSelf: "center",
    paddingRight: rootStyles.spacers.large,
    height: 36,
    width: 36,
};
