import * as React from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import _ from "lodash";
import { IconsList, stringIds, bundleIds } from "../../../assets";
import {
    artist,
    ArtistProfileIssueType,
    artistSearchQueryPayload,
    baseMediaItem,
    BundleMap,
    ErrorPayload,
    METRIC_KEYS,
    newDisambiguationPayload,
    telemetryPayload,
    trackAlbumSearchQueryPayload,
} from "../../../models";
import {
    artistIssueActions,
    artistSearchActions,
    contentSearchActions,
    reportArtistIssuePageSelectors,
    RootState,
    telemetryActions,
    userActions,
} from "../../../store";
import {
    GENERIC_TEXT_FIELD_LIMIT,
    getFilteredMediaItem,
    getLocalizedString,
    getLocalizedUrl,
    MOREINFO_TEXT_FIELD_LIMIT,
    paths,
    translateIssueToReportToString,
} from "../../../utils";
import {
    CommonHeader,
    Footer,
    FullScreenConfirmationModal,
    HelpModal,
    InlineError,
    Loading,
    RadioList,
    styledTitle,
    TextField,
} from "../../components";
import * as rootStyles from "../../styles";
import { RouteComponentProps } from "react-router";
import { breakpoints } from "../../styles";
import { StepField, SearchResults, SearchSelect } from "../../components";
import {
    getHelpTooltip,
    getRadioItems,
    getSearchAndSelectArtist,
    getTitleWithHelp,
    getWarningRow,
} from "../../components/common/ComponentHelper";
import { isProd } from "../../../service/common";
import styled from "styled-components";
import { LocalizedUrls } from "@amzn/ziggy-asset";

const testIDPrefix = "ReportArtistIssueScreen";
const metricPrefix = "ReportArtistIssuePage";
const pagePath = paths.reportArtistProfile;

const removeContentPrefix = "-RemoveContent";
const remainContentPrefix = "-RemainContent";

const QUERY_DELAY_TIME = 2000;
const COMMON_MAX_WIDTH = 480;
const MAX_CONTENT_SELECTED = 50;
const YES_INDEX: number = 0;
const NO_INDEX: number = 1;
const ANOTHER_PROFILE_INDEX = 0;
const INCORRECT_PROFILE_INDEX = 1;

type artistProfileIssueTypeProps = {
    stepOneTitle: string;
    stepTwoTitle: string;
    stepThreeTitle: string;
    showCreateNewProfileText: boolean;
    showSpellingTextField: boolean;
    moveProileStepIcon: any;
    showSelectArtistWithMissingContent: boolean;
};

enum searchSourceType {
    ArtistWithProblemSearch = "ArtistWithProblemSearch",
    ArtistMissingContentSearch = "ArtistMissingContentSearch",
    RemoveContent = "RemoveContent",
    RemainContent = "RemainContent",
}

type ViewProps = {};

type StateProps = {
    signedIn: boolean;
    artistSearchInProgress: boolean;
    artists?: artist[];
    hydrationInProgress: boolean;
    error?: ErrorPayload;
    trackAlbumSearchInProgress: boolean;
    trackAlbums?: baseMediaItem[];
    selectedArtist?: artist;
    selectedMediaItem?: baseMediaItem;
    newDisambiguationInProgress: boolean;
    newDisambiguationSuccess?: boolean;
    selectedTeamId?: string;
    prevPath: string;
    bundleMap: BundleMap;
};

type DispatchProps = {
    artistSearch: (payload: artistSearchQueryPayload) => void;
    clearArtistSearch: () => void;
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
    trackAlbumSearch: (payload: trackAlbumSearchQueryPayload) => void;
    clearTrackAlbumSearch: () => void;
    clearSelectedReportIssueItem: () => void;
    newDisambiguation: (payload: newDisambiguationPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    showError?: boolean;
    artists: artist[];
    isSearchCompleted: boolean;
    isMobile: boolean;
    selectedArtistWithProblem?: artist | undefined;
    selectedArtistWithMissingContent?: artist | undefined;
    trackAlbums: baseMediaItem[];
    selectedRemoveContent: baseMediaItem[];
    selectedRemainContent: baseMediaItem[];
    issueToReport?: ArtistProfileIssueType;
    showHelpModal: boolean;
    showSuccessModal: boolean;
    showFailureModal: boolean;
    spellingCorrectIndex: number | undefined;
    spellingText: string;
    searchSource?: searchSourceType;
    moreInfo: string;
    prepopulationInProgress: boolean;
};

const CLEAN_STATE_AFTER_STEP_1 = {
    selectedRemainContent: [],
    selectedRemoveContent: [],
    moreInfo: "",
    trackAlbums: [],
    artists: [],
    spellingCorrectIndex: undefined,
    spellingText: "",
    selectedArtistWithMissingContent: undefined,
    isSearchCompleted: false,
};

const CLEAN_STATE = {
    artists: [],
    isSearchCompleted: false,
    isMobile: false,
    selectedArtistWithProblem: undefined,
    selectedArtistWithMissingContent: undefined,
    selectedRemoveContent: [],
    selectedRemainContent: [],
    showHelpModal: false,
    spellingCorrectIndex: undefined,
    spellingText: "",
    moreInfo: "",
    trackAlbums: [],
    prepopulationInProgress: false,
    showSuccessModal: false,
    showFailureModal: false,
    issueToReport: undefined,
    showError: undefined,
};

class ReportArtistIssueScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = CLEAN_STATE;

        this.makeSearchQuery = this.makeSearchQuery.bind(this);
        this.makeSearchQuery = _.debounce(
            this.makeSearchQuery,
            QUERY_DELAY_TIME
        );

        this.makeTrackAlbumSearchQuery =
            this.makeTrackAlbumSearchQuery.bind(this);
        this.makeTrackAlbumSearchQuery = _.debounce(
            this.makeTrackAlbumSearchQuery,
            QUERY_DELAY_TIME
        );

        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, pagePath],
            ]),
        });

        this.props.clearArtistSearch();
        this.props.clearTrackAlbumSearch();
        this.setState({
            artists: this.props.artists ? this.props.artists : [],
            trackAlbums: this.props.trackAlbums ? this.props.trackAlbums : [],
        });

        if (this.props.selectedMediaItem) {
            this.getTrackAlbumMetadata();
        }

        window.addEventListener("resize", this.resize.bind(this));
    }

    resize() {
        let currentIsMobile = window.innerWidth <= breakpoints.lg;
        if (currentIsMobile !== this.state.isMobile) {
            this.setState({ isMobile: currentIsMobile });
        }
    }

    componentWillUnmount() {
        this.props.clearArtistSearch();
        window.removeEventListener("resize", this.resize.bind(this));
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }

        if (this.props.artists !== prevProps.artists) {
            this.setState({
                artists: this.props.artists ? this.props.artists : [],
                isSearchCompleted: !(
                    this.props.artists && this.props.artists.length > 0
                )
                    ? true
                    : false,
            });
        }

        if (this.props.trackAlbums !== prevProps.trackAlbums) {
            this.setState({
                trackAlbums: this.props.trackAlbums
                    ? this.props.trackAlbums
                    : [],
            });
        }

        if (
            this.props.selectedMediaItem &&
            this.state.trackAlbums?.length === 1
        ) {
            this.onTrackAlbumSelect(this.state.trackAlbums[0]);
            this.setState({
                selectedArtistWithProblem: this.props.selectedArtist,
                issueToReport: ArtistProfileIssueType.IncorrectContent,
                prepopulationInProgress: false,
            });
            this.props.clearSelectedReportIssueItem();
        }

        if (
            this.props.trackAlbumSearchInProgress === false &&
            prevProps.trackAlbumSearchInProgress === true &&
            this.state.prepopulationInProgress === true
        ) {
            this.setState({ prepopulationInProgress: false });
        }

        if (
            prevProps.newDisambiguationSuccess === undefined &&
            this.props.newDisambiguationSuccess === true
        ) {
            this.setState({ showSuccessModal: true });
        }

        if (
            prevProps.newDisambiguationSuccess === undefined &&
            this.props.newDisambiguationSuccess === false
        ) {
            this.setState({ showFailureModal: true });
        }
    }

    render() {
        if (this.state.prepopulationInProgress) {
            return <Loading />;
        }

        const mediumSpacer = (
            <div style={{ flex: 1, minHeight: rootStyles.spacers.medium }} />
        );

        const trackAlbums = this.getTrackAlbumsNotYetSelected();

        const issueTypeProps: artistProfileIssueTypeProps =
            this.state.issueToReport ===
            ArtistProfileIssueType.ContentOnDifferentArtist
                ? {
                      stepOneTitle: getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                          stringId:
                              stringIds.ArtistDisambiguation
                                  .whichArtistHasYourContentTitle,
                      }),
                      stepTwoTitle: getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                          stringId:
                              stringIds.ArtistDisambiguation
                                  .doYouHaveArtistProfileTitle,
                      }),
                      stepThreeTitle: getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                          stringId:
                              stringIds.ArtistDisambiguation
                                  .whatShouldBeMovedTitle,
                      }),
                      showCreateNewProfileText: true,
                      showSpellingTextField:
                          this.state.spellingCorrectIndex === NO_INDEX,
                      showSelectArtistWithMissingContent:
                          this.state.spellingCorrectIndex === YES_INDEX,
                      moveProileStepIcon: IconsList.ic_step_4,
                  }
                : {
                      stepOneTitle: getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                          stringId:
                              stringIds.ArtistDisambiguation
                                  .whichArtistHasProblemTitle,
                      }),
                      stepTwoTitle: getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                          stringId:
                              stringIds.ArtistDisambiguation
                                  .doYouNeedToUpdateArtistName,
                      }),
                      stepThreeTitle: getLocalizedString(this.props.bundleMap, {
                          bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                          stringId:
                              stringIds.ArtistDisambiguation
                                  .whatShouldBeRemovedTitle,
                      }),
                      showCreateNewProfileText: false,
                      showSpellingTextField:
                          this.state.spellingCorrectIndex === YES_INDEX,
                      showSelectArtistWithMissingContent: false,
                      moveProileStepIcon: IconsList.ic_step_3,
                  };

        const artistWithProblemField = getSearchAndSelectArtist(
            this.state.selectedArtistWithProblem,
            testIDPrefix + "-ArtistWithProblem",
            this.unselectArtistWithProblem,
            this.onArtistSelected,
            this.props.artistSearchInProgress,
            this.shouldLoadResultsForSearch(
                searchSourceType.ArtistWithProblemSearch
            ),
            this.state.artists,
            this.onSearchTextChanged
        );

        const artistWithMissingContentField = getSearchAndSelectArtist(
            this.state.selectedArtistWithMissingContent,
            testIDPrefix + "-ArtistWithMissingContent",
            this.unselectArtistWithMissingContent,
            this.onArtistSelected,
            this.props.artistSearchInProgress,
            this.shouldLoadResultsForSearch(
                searchSourceType.ArtistMissingContentSearch
            ),
            this.state.artists.filter(
                (artist) =>
                    this.state.selectedArtistWithProblem?.asin !== artist?.asin
            ),
            this.onSearchArtistWithMissingContentTextChanged
        );

        const selectIssueTypeRadioButtonItems: string[] = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: translateIssueToReportToString(
                    ArtistProfileIssueType.ContentOnDifferentArtist
                ),
            }),
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: translateIssueToReportToString(
                    ArtistProfileIssueType.IncorrectContent
                ),
            }),
        ];

        const radioButtonItems: string[] = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: stringIds.ArtistDisambiguation.yes,
            }),
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: stringIds.ArtistDisambiguation.no,
            }),
        ];

        const searchArtistHeader = getTitleWithHelp(
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: stringIds.ArtistDisambiguation.searchTitle,
            }),
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: stringIds.ArtistDisambiguation.searchArtistHelp,
            }),
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: stringIds.ArtistDisambiguation.learnMore,
            }),
            this.onSearchArtistHelp,
            `${testIDPrefix}_SearchForContentHelp`
        );

        const searchForContentHeader = getTitleWithHelp(
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: stringIds.ArtistDisambiguation.searchForContentTitle,
            }),
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                stringId: stringIds.ArtistDisambiguation.searchForContentHelp,
            }),
            undefined,
            undefined,
            `${testIDPrefix}_SearchForContentHelp`
        );

        const helpModalBody = () => {
            return (
                <Row style={{ width: "100%" }}>
                    <span
                        style={{
                            ...rootStyles.textStyles.secondary,
                            color: rootStyles.colors.primary,
                        }}
                    >
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist
                                    .helpModalInstructionOneA,
                        }) + " "}
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist.helpModalAmazonMusic,
                        })}
                        {" " +
                            getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                                stringId:
                                    stringIds.SearchForArtist
                                        .helpModalInstructionOneB,
                            })}
                        {mediumSpacer}
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist
                                    .helpModalInstructionTwo,
                        })}
                        {mediumSpacer}
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist
                                    .helpModalInstructionThree,
                        })}
                    </span>
                </Row>
            );
        };

        const correctSpellingRadioList = () => {
            return (
                <Row
                    style={{
                        width: "100%",
                        maxWidth: 250,
                        marginTop: -8,
                        marginBottom: -8,
                    }}
                >
                    <RadioList
                        elements={getRadioItems(radioButtonItems)}
                        onChangeElement={this.onSelectSpellingCorrect}
                        id={testIDPrefix + "-CorrectSpellingRadioInput"}
                        horizontal={true}
                        centeredElements={true}
                    />
                </Row>
            );
        };

        const selectIssueTypeRadioList = () => {
            return (
                <Row style={{ width: "100%", marginTop: -8, marginBottom: -8 }}>
                    <RadioList
                        elements={getRadioItems(
                            selectIssueTypeRadioButtonItems
                        )}
                        onChangeElement={(index) =>
                            this.selectIssueToReport(
                                index === ANOTHER_PROFILE_INDEX
                                    ? ArtistProfileIssueType.ContentOnDifferentArtist
                                    : ArtistProfileIssueType.IncorrectContent
                            )
                        }
                        id={testIDPrefix + "-CorrectSpellingRadioInput"}
                        horizontal={false}
                        centeredElements={true}
                        initialSelectedIndex={
                            this.state.issueToReport !== undefined
                                ? this.state.issueToReport ===
                                  ArtistProfileIssueType.ContentOnDifferentArtist
                                    ? ANOTHER_PROFILE_INDEX
                                    : INCORRECT_PROFILE_INDEX
                                : undefined
                        }
                    />
                </Row>
            );
        };

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                    ...rootStyles.containerStyles.tableContainerStyle,
                }}
            >
                <Row style={{ alignItems: "center" }}>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.artistSearchError,
                            })}
                            onDismiss={this.dismissError}
                            id={`${testIDPrefix}_ArtistSearchError`}
                        />
                    )}
                </Row>

                <CommonHeader
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId:
                            stringIds.ArtistDisambiguation
                                .reportArtistProfileIssueTitle,
                    })}
                    subtitle={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId:
                            stringIds.ArtistDisambiguation
                                .reportArtistProfileIssueSubtitle,
                    })}
                    hideDatePicker={true}
                    hideArtistImgDropdown={true}
                    id={`${testIDPrefix}_Header`}
                />

                {/* Description */}
                <Row style={formFieldRowStyle}>
                    <styledTitle.h4 style={rootStyles.textStyles.primary}>
                        {getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                            stringId:
                                stringIds.ArtistDisambiguation
                                    .helpReportArtistProfileIssueSubtitle,
                        })}
                    </styledTitle.h4>
                </Row>

                {/* Select Issue */}
                <Row
                    style={{
                        ...sidePaddingRowStyle,
                        marginTop: rootStyles.spacers.small,
                    }}
                >
                    <StepField
                        leftIcon={IconsList.ic_step_1}
                        title={getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                            stringId:
                                stringIds.ArtistDisambiguation.selectIssueTitle,
                        })}
                        bottomElement={selectIssueTypeRadioList()}
                        id={testIDPrefix + "-SelectIssueStepField"}
                    />
                    <Row style={helpRowStyle}>
                        <span>
                            {getLocalizedString(this.props.bundleMap, {
                                bundleId:
                                    bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                stringId:
                                    stringIds.ArtistDisambiguation
                                        .updateArtistNameHelpTextA,
                            })}
                            <span
                                onClick={() =>
                                    window.open(
                                        getLocalizedUrl(
                                            LocalizedUrls.ArtistDisambiguationUpdateArtistNameHelpLink
                                        ),
                                        "_blank"
                                    )
                                }
                                style={{ color: rootStyles.colors.accent }}
                            >
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId:
                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                    stringId:
                                        stringIds.ArtistDisambiguation
                                            .updateArtistNameHelpTextB,
                                })}
                            </span>
                        </span>
                    </Row>
                </Row>

                {this.state.issueToReport && (
                    <>
                        {/* Search Artist */}
                        <Row style={formFieldRowStyle}>
                            <StepField
                                leftIcon={IconsList.ic_step_2}
                                title={issueTypeProps.stepOneTitle}
                                id={testIDPrefix + "-SearchByArtist"}
                            />
                        </Row>
                        <div style={sidePaddingInnerStyle}>
                            {searchArtistHeader}
                            <Row>
                                <Col
                                    style={{
                                        padding: 0,
                                        maxWidth: COMMON_MAX_WIDTH,
                                    }}
                                >
                                    {artistWithProblemField}
                                </Col>
                            </Row>
                        </div>

                        {this.state.selectedArtistWithProblem ? (
                            <>
                                {this.state.issueToReport ===
                                    ArtistProfileIssueType.ContentOnDifferentArtist && (
                                    <Row style={formFieldRowStyle}>
                                        <StepField
                                            leftIcon={IconsList.ic_step_3}
                                            title={issueTypeProps.stepTwoTitle}
                                            bottomElement={correctSpellingRadioList()}
                                            id={testIDPrefix + "-Spelling"}
                                        />
                                    </Row>
                                )}

                                {issueTypeProps.showSpellingTextField && (
                                    <div style={sidePaddingInnerStyle}>
                                        {issueTypeProps.showCreateNewProfileText ? (
                                            <Row
                                                style={{
                                                    ...sidePaddingRowStyle,
                                                    alignItems: "center",
                                                    marginBottom:
                                                        rootStyles.spacers.base,
                                                }}
                                            >
                                                <span
                                                    style={
                                                        rootStyles.textStyles
                                                            .tertiary
                                                    }
                                                >
                                                    {getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                            stringId:
                                                                stringIds
                                                                    .ArtistDisambiguation
                                                                    .doYouHaveArtistProfileNote,
                                                        }
                                                    )}
                                                </span>
                                            </Row>
                                        ) : null}
                                        {getTitleWithHelp(
                                            getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                    stringId:
                                                        stringIds
                                                            .ArtistDisambiguation
                                                            .incorrectSpellingSubtitle,
                                                }
                                            ),
                                            getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                    stringId:
                                                        stringIds
                                                            .ArtistDisambiguation
                                                            .incorrectSpellingHelpText,
                                                }
                                            ),
                                            undefined,
                                            undefined,
                                            `${testIDPrefix}_IncorrectSpellingTooltip`
                                        )}
                                        <Row
                                            style={{
                                                ...rowStyle,
                                                paddingRight: 15,
                                                marginTop:
                                                    rootStyles.spacers.mini,
                                            }}
                                        >
                                            <TextField
                                                onChange={this.setSpellingText}
                                                maxLength={
                                                    GENERIC_TEXT_FIELD_LIMIT
                                                }
                                                id={testIDPrefix + "-Spelling"}
                                                defaultValue={
                                                    this.state.spellingText
                                                }
                                            />
                                        </Row>
                                    </div>
                                )}
                                {issueTypeProps.showSelectArtistWithMissingContent ? (
                                    <div
                                        style={{
                                            ...sidePaddingInnerStyle,
                                            maxWidth: COMMON_MAX_WIDTH,
                                        }}
                                    >
                                        {searchArtistHeader}
                                        {artistWithMissingContentField}
                                    </div>
                                ) : null}

                                <Row>
                                    {/* What should be removed */}
                                    <SharedRowStepContainer>
                                        <Row style={sidePaddingRowStyle}>
                                            <StepField
                                                leftIcon={
                                                    issueTypeProps.moveProileStepIcon
                                                }
                                                title={
                                                    issueTypeProps.stepThreeTitle
                                                }
                                                subtitle={getLocalizedString(
                                                    this.props.bundleMap,
                                                    {
                                                        bundleId:
                                                            bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                        stringId:
                                                            stringIds
                                                                .ArtistDisambiguation
                                                                .whatShouldBeMovedSubtitle,
                                                    }
                                                )}
                                                id={
                                                    testIDPrefix +
                                                    removeContentPrefix
                                                }
                                            />
                                        </Row>
                                        <div style={sidePaddingInnerStyle}>
                                            {searchForContentHeader}
                                            <Row style={rowStyle}>
                                                {this.state
                                                    .selectedRemoveContent
                                                    .length <
                                                MAX_CONTENT_SELECTED ? (
                                                    <SearchSelect
                                                        onSelectElement={
                                                            this
                                                                .onTrackAlbumSelect
                                                        }
                                                        inProgress={
                                                            this.props
                                                                .trackAlbumSearchInProgress
                                                        }
                                                        displayResults={this.shouldLoadResultsForSearch(
                                                            searchSourceType.RemoveContent
                                                        )}
                                                        items={trackAlbums}
                                                        onTextChange={
                                                            this
                                                                .onSearchRemoveContentTextChanged
                                                        }
                                                        showAddIcon={true}
                                                        squareImage={true}
                                                        id={
                                                            testIDPrefix +
                                                            removeContentPrefix
                                                        }
                                                    />
                                                ) : (
                                                    getWarningRow(
                                                        getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .ArtistDisambiguation
                                                                        .maxSelectedContent,
                                                            }
                                                        ),
                                                        `${testIDPrefix}_MaxContentSelected`
                                                    )
                                                )}
                                            </Row>
                                            {this.state
                                                .selectedRemoveContent && (
                                                <Row style={rowStyle}>
                                                    <SearchResults
                                                        removeIcon={
                                                            IconsList.action_removeInline
                                                        }
                                                        itemStyle={
                                                            selectedTrackAlbumStyle
                                                        }
                                                        onChangeElement={
                                                            this
                                                                .clickOnRemoveContent
                                                        }
                                                        items={
                                                            this.state
                                                                .selectedRemoveContent
                                                        }
                                                        id={
                                                            testIDPrefix +
                                                            removeContentPrefix
                                                        }
                                                    />
                                                    <Row style={helpRowStyle}>
                                                        <span>
                                                            {getLocalizedString(
                                                                this.props
                                                                    .bundleMap,
                                                                {
                                                                    bundleId:
                                                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                                    stringId:
                                                                        stringIds
                                                                            .ArtistDisambiguation
                                                                            .removeArtistContentHelpTextA,
                                                                }
                                                            )}
                                                            <span
                                                                onClick={() =>
                                                                    window.open(
                                                                        getLocalizedUrl(
                                                                            LocalizedUrls.ArtistDisambiguationRemoveArtistContentHelpLink
                                                                        ),
                                                                        "_blank"
                                                                    )
                                                                }
                                                                style={{
                                                                    color: rootStyles
                                                                        .colors
                                                                        .accent,
                                                                }}
                                                            >
                                                                {getLocalizedString(
                                                                    this.props
                                                                        .bundleMap,
                                                                    {
                                                                        bundleId:
                                                                            bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                                        stringId:
                                                                            stringIds
                                                                                .ArtistDisambiguation
                                                                                .removeArtistContentHelpTextB,
                                                                    }
                                                                )}
                                                            </span>
                                                        </span>
                                                    </Row>
                                                </Row>
                                            )}
                                        </div>
                                    </SharedRowStepContainer>

                                    {/* What should remain */}
                                    {this.state.issueToReport ===
                                    ArtistProfileIssueType.IncorrectContent ? (
                                        <SharedRowStepContainer>
                                            <Row
                                                style={{
                                                    ...sidePaddingRowStyle,
                                                    paddingRight: 0,
                                                }}
                                            >
                                                <StepField
                                                    leftIcon={
                                                        IconsList.ic_step_4
                                                    }
                                                    title={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                            stringId:
                                                                stringIds
                                                                    .ArtistDisambiguation
                                                                    .whatShouldRemainTitle,
                                                        }
                                                    )}
                                                    subtitle={getLocalizedString(
                                                        this.props.bundleMap,
                                                        {
                                                            bundleId:
                                                                bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                            stringId:
                                                                stringIds
                                                                    .ArtistDisambiguation
                                                                    .whatShouldRemainSubtitle,
                                                        }
                                                    )}
                                                    rightIcon={getHelpTooltip(
                                                        getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .ArtistDisambiguation
                                                                        .whatIsCorrectHelpText,
                                                            }
                                                        ),
                                                        undefined,
                                                        undefined,
                                                        `${testIDPrefix}_IncorrectContentTooltip`
                                                    )}
                                                    id={
                                                        testIDPrefix +
                                                        remainContentPrefix
                                                    }
                                                />
                                            </Row>
                                            <div style={sidePaddingInnerStyle}>
                                                {searchForContentHeader}
                                                <Row style={rowStyle}>
                                                    {this.state
                                                        .selectedRemainContent
                                                        .length <
                                                    MAX_CONTENT_SELECTED ? (
                                                        <SearchSelect
                                                            onSelectElement={
                                                                this
                                                                    .onTrackAlbumSelect
                                                            }
                                                            inProgress={
                                                                this.props
                                                                    .trackAlbumSearchInProgress
                                                            }
                                                            displayResults={this.shouldLoadResultsForSearch(
                                                                searchSourceType.RemainContent
                                                            )}
                                                            items={trackAlbums}
                                                            onTextChange={
                                                                this
                                                                    .onSearchRemainContentTextChanged
                                                            }
                                                            showAddIcon={true}
                                                            squareImage={true}
                                                            id={
                                                                testIDPrefix +
                                                                remainContentPrefix
                                                            }
                                                        />
                                                    ) : (
                                                        getWarningRow(
                                                            getLocalizedString(
                                                                this.props
                                                                    .bundleMap,
                                                                {
                                                                    bundleId:
                                                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                                    stringId:
                                                                        stringIds
                                                                            .ArtistDisambiguation
                                                                            .maxSelectedContent,
                                                                }
                                                            ),
                                                            `${testIDPrefix}_MaxContentSelected`
                                                        )
                                                    )}
                                                </Row>
                                                {this.state
                                                    .selectedRemainContent && (
                                                    <Row style={rowStyle}>
                                                        <SearchResults
                                                            removeIcon={
                                                                IconsList.action_removeInline
                                                            }
                                                            itemStyle={
                                                                selectedTrackAlbumStyle
                                                            }
                                                            onChangeElement={
                                                                this
                                                                    .clickOnRemainContent
                                                            }
                                                            items={
                                                                this.state
                                                                    .selectedRemainContent
                                                            }
                                                            id={
                                                                testIDPrefix +
                                                                remainContentPrefix
                                                            }
                                                        />
                                                    </Row>
                                                )}
                                            </div>
                                        </SharedRowStepContainer>
                                    ) : null}
                                </Row>

                                {/* Other Info */}
                                <Row style={formFieldRowStyle}>
                                    <StepField
                                        leftIcon={IconsList.ic_step_5}
                                        title={`
                                            ${getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                    stringId:
                                                        stringIds
                                                            .ArtistDisambiguation
                                                            .otherInfoTitle,
                                                }
                                            )} (${getLocalizedString(
                                            this.props.bundleMap,
                                            {
                                                bundleId:
                                                    bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                stringId:
                                                    stringIds
                                                        .ArtistDisambiguation
                                                        .optional,
                                            }
                                        )})`}
                                        id={testIDPrefix + "-OtherInfo"}
                                    />
                                </Row>
                                <div style={sidePaddingInnerStyle}>
                                    <Row
                                        style={{
                                            alignItems: "center",
                                            ...sidePaddingRowStyle,
                                        }}
                                    >
                                        <span style={fieldTitleStyle}>
                                            {getLocalizedString(
                                                this.props.bundleMap,
                                                {
                                                    bundleId:
                                                        bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                                                    stringId:
                                                        stringIds
                                                            .ArtistDisambiguation
                                                            .otherInfoSubtitle,
                                                }
                                            )}
                                        </span>
                                    </Row>
                                    <Row style={sidePaddingRowStyle}>
                                        <TextField
                                            multiLine={true}
                                            onChange={this.setMoreInfoText}
                                            maxLength={
                                                MOREINFO_TEXT_FIELD_LIMIT
                                            }
                                            id={testIDPrefix + "-MoreInfo"}
                                            defaultValue={this.state.moreInfo}
                                        />
                                    </Row>
                                </div>
                            </>
                        ) : null}
                    </>
                )}

                <Footer
                    show={true}
                    rightButtonDisabled={this.shouldDisableSubmit()}
                    leftButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId:
                            stringIds.ArtistDisambiguation.cancelButtonText,
                    })}
                    rightButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId:
                            stringIds.ArtistDisambiguation.submitButtonText,
                    })}
                    onLeftButtonClick={this.pressCancel}
                    onRightButtonClick={this.pressSubmit}
                    id={`${testIDPrefix}_Footer`}
                />

                <HelpModal
                    isVisible={this.state.showHelpModal}
                    onDismiss={this.dismissModal}
                    accept={this.dismissModal}
                    title={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId: stringIds.SearchForArtist.helpModalTitle,
                    })}
                    body={helpModalBody}
                    acceptbuttonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId: stringIds.SearchForArtist.helpModalGotIt,
                    })}
                    id={`${testIDPrefix}_SearchForArtistHelp`}
                />

                {/* Success modal */}
                <FullScreenConfirmationModal
                    id={`${testIDPrefix}_SuccessModal`}
                    icon={IconsList.action_doneInline}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId: stringIds.ArtistDisambiguation.successTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId:
                            stringIds.ArtistDisambiguation.successDescription,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                            stringId:
                                stringIds.ArtistDisambiguation
                                    .successButtonText,
                        }
                    )}
                    confirmButtonAction={() => {
                        this.setState({
                            showSuccessModal: false,
                            issueToReport: undefined,
                        });
                        this.goBackOrUserSettings();
                    }}
                    cancelButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId:
                            stringIds.ArtistDisambiguation
                                .successCorrectAnotherProfile,
                    })}
                    cancelButtonAction={() => this.setState(CLEAN_STATE)}
                    onDismiss={() => this.setState(CLEAN_STATE)}
                    isVisible={this.state.showSuccessModal}
                    buttonOrientation={"stacked"}
                />

                {/* Failure modal */}
                <FullScreenConfirmationModal
                    id={`${testIDPrefix}_FailureModal`}
                    icon={IconsList.ic_important}
                    text={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId: stringIds.ArtistDisambiguation.failTitle,
                    })}
                    description={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId:
                            stringIds.ArtistDisambiguation.failDescription,
                    })}
                    confirmButtonText={getLocalizedString(
                        this.props.bundleMap,
                        {
                            bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                            stringId:
                                stringIds.ArtistDisambiguation
                                    .failTryAgainButtonText,
                        }
                    )}
                    confirmButtonAction={() => {
                        this.setState({ showFailureModal: false });
                        this.pressSubmit();
                    }}
                    cancelButtonText={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                        stringId:
                            stringIds.ArtistDisambiguation.cancelButtonText,
                    })}
                    cancelButtonAction={() => {
                        this.setState({ showFailureModal: false });
                        this.goBackOrUserSettings();
                    }}
                    onDismiss={() =>
                        this.setState({
                            showFailureModal: false,
                            showError: false,
                        })
                    }
                    isVisible={this.state.showFailureModal}
                    buttonOrientation={"inline"}
                />
            </Container>
        );
    }

    private getSearchText = (): string => {
        if (!this.props.selectedMediaItem) return "";
        if (
            !this.props.selectedMediaItem.globalAsin &&
            !this.props.selectedMediaItem.asin
        ) {
            return "";
        }

        return (
            this.props.selectedMediaItem.globalAsin ||
            this.props.selectedMediaItem.asin ||
            ""
        );
    };

    private getTrackAlbumMetadata = () => {
        const searchText: string = this.getSearchText();

        this.setState(
            {
                searchSource: searchSourceType.RemoveContent,
                prepopulationInProgress: true,
            },
            () => this.trackAlbumSearchQuery(searchText)
        );
    };

    private onSearchTextChanged = (text: string) => {
        if (!this.props.signedIn) {
            return;
        }
        this.props.clearArtistSearch();
        this.setState(
            { searchSource: searchSourceType.ArtistWithProblemSearch },
            () => this.makeSearchQuery(text)
        );
    };

    private makeSearchQuery(artistSearch: string) {
        if (!this.props.signedIn) {
            return;
        }

        this.props.userAction({
            name: metricPrefix + "QueryEntered",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, pagePath],
            ]),
        });

        const payload: artistSearchQueryPayload = {
            query: artistSearch,
            requestPath: pagePath,
            musicTerritory: "US",
        };

        this.props.artistSearch(payload);
    }

    private onArtistSelected = (artist: artist) => {
        if (!this.state.artists) {
            return;
        }
        this.props.userAction({
            name: metricPrefix + "ResultSelected",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, pagePath],
                [
                    METRIC_KEYS.listIndex,
                    this.state.artists.indexOf(artist).toString(),
                ],
            ]),
        });
        switch (this.state.searchSource) {
            case searchSourceType.ArtistWithProblemSearch:
                this.setState({
                    selectedArtistWithProblem: artist,
                    ...CLEAN_STATE_AFTER_STEP_1,
                });
                break;
            case searchSourceType.ArtistMissingContentSearch:
                this.setState({ selectedArtistWithMissingContent: artist });
                break;
        }
        this.props.clearArtistSearch();
    };

    private makeTrackAlbumSearchQuery(trackAlbumSearch: string) {
        if (!this.props.signedIn) {
            return;
        }

        this.props.userAction({
            name: metricPrefix + "TrackAlbumQueryEntered",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, pagePath],
            ]),
        });

        this.trackAlbumSearchQuery(trackAlbumSearch);
    }

    private trackAlbumSearchQuery(trackAlbumSearch: string) {
        const artistAsin = this.state.selectedArtistWithProblem?.asin;
        const payload: trackAlbumSearchQueryPayload = {
            query: trackAlbumSearch,
            requestPath: pagePath,
            artistAsin: artistAsin,
        };
        this.props.trackAlbumSearch(payload);
    }

    private onTrackAlbumSelect = (trackAlbum: baseMediaItem) => {
        if (!this.state.trackAlbums) {
            return;
        }
        this.props.userAction({
            name: metricPrefix + "TrackAlbumResultSelected",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, pagePath],
                [
                    METRIC_KEYS.listIndex,
                    this.state.trackAlbums.indexOf(trackAlbum).toString(),
                ],
            ]),
        });
        switch (this.state.searchSource) {
            case searchSourceType.RemoveContent:
                if (
                    !this.state.selectedRemoveContent.find(
                        (item) => item.asin === trackAlbum.asin
                    )
                ) {
                    this.setState({
                        selectedRemoveContent: [
                            ...this.state.selectedRemoveContent,
                            trackAlbum,
                        ],
                    });
                }
                break;
            case searchSourceType.RemainContent:
                if (
                    !this.state.selectedRemainContent.find(
                        (item) => item.asin === trackAlbum.asin
                    )
                ) {
                    this.setState({
                        selectedRemainContent: [
                            ...this.state.selectedRemainContent,
                            trackAlbum,
                        ],
                    });
                }
                break;
        }
        this.props.clearTrackAlbumSearch();
    };
    private unselectArtistWithProblem = () => {
        this.setState({ selectedArtistWithProblem: undefined });
    };

    private unselectArtistWithMissingContent = () => {
        this.setState({ selectedArtistWithMissingContent: undefined });
    };

    private dismissError = () => {
        this.setState({ showError: false });
    };

    /* Select Issue */
    private selectIssueToReport = (issue: ArtistProfileIssueType) => {
        // Prepopulate artist if artist has incorrect content
        const artist =
            issue === ArtistProfileIssueType.IncorrectContent
                ? this.props.selectedArtist
                : undefined;

        this.setState({
            issueToReport: issue,
            ...CLEAN_STATE_AFTER_STEP_1,
            selectedArtistWithProblem: artist,
        });
    };

    private setSpellingText = (text: string) => {
        this.setState({ spellingText: text });
    };

    /* Artist name spelling  */
    private onSelectSpellingCorrect = (itemIndex: number) => {
        this.setState({
            spellingCorrectIndex: itemIndex,
            spellingText: "",
            selectedArtistWithMissingContent: undefined,
        });
    };

    private onSearchArtistWithMissingContentTextChanged = (text: string) => {
        if (!this.props.signedIn) {
            return;
        }
        this.props.clearTrackAlbumSearch();
        this.setState(
            { searchSource: searchSourceType.ArtistMissingContentSearch },
            () => this.makeSearchQuery(text)
        );
    };

    /* What should be removed */

    private onSearchRemoveContentTextChanged = (text: string) => {
        if (!this.props.signedIn) {
            return;
        }
        this.props.clearTrackAlbumSearch();
        this.setState({ searchSource: searchSourceType.RemoveContent }, () =>
            this.makeTrackAlbumSearchQuery(text)
        );
    };

    /* What should remain */

    private onSearchRemainContentTextChanged = (text: string) => {
        if (!this.props.signedIn) {
            return;
        }
        this.props.clearArtistSearch();
        this.setState({ searchSource: searchSourceType.RemainContent }, () =>
            this.makeTrackAlbumSearchQuery(text)
        );
    };

    /* Cancel-submit buttons */
    private pressCancel = () => {
        this.props.clearSelectedReportIssueItem();
        this.goBackOrUserSettings();
    };

    private pressSubmit = () => {
        if (
            !this.state.selectedArtistWithProblem ||
            !this.state.selectedArtistWithProblem.asin ||
            !this.state.issueToReport
        ) {
            return;
        }

        const payload: newDisambiguationPayload = {
            requestPath: paths.reportArtistProfile,
            teamId: this.props.selectedTeamId,
            request: {
                artistAsinWithIssue: this.state.selectedArtistWithProblem.asin,
                correctAsins:
                    this.state.issueToReport ===
                        ArtistProfileIssueType.IncorrectContent &&
                    this.state.selectedRemainContent
                        ? this.state.selectedRemainContent.map(
                              (item: baseMediaItem) => item.asin || ""
                          )
                        : [],
                incorrectAsins: this.state.selectedRemoveContent
                    ? this.state.selectedRemoveContent.map(
                          (item: baseMediaItem) => item.asin || ""
                      )
                    : [],
                correctArtistAsin:
                    this.state.issueToReport ===
                        ArtistProfileIssueType.ContentOnDifferentArtist &&
                    this.state.selectedArtistWithMissingContent
                        ? this.state.selectedArtistWithMissingContent.asin
                        : undefined,
                artistNameSpelling: this.state.spellingText,
                otherInfo: this.state.moreInfo,
                issueType: this.state.issueToReport,
                test: !isProd,
            },
        };

        this.props.newDisambiguation(payload);

        this.props.clearSelectedReportIssueItem();
    };

    private goBackOrUserSettings = () => {
        if (
            this.props.prevPath === undefined ||
            this.props.prevPath === paths.reportArtistProfile
        ) {
            this.props.history.push(paths.settings);
        } else {
            this.props.history.push(this.props.prevPath);
        }
    };

    private shouldDisableSubmit = () => {
        const conditions: boolean[] = [];
        switch (this.state.issueToReport) {
            case ArtistProfileIssueType.ContentOnDifferentArtist:
                // step 1 complete
                conditions.push(
                    this.state.selectedArtistWithProblem !== undefined
                );
                // step 2 complete
                conditions.push(
                    (this.state.spellingCorrectIndex === YES_INDEX &&
                        this.state.selectedArtistWithMissingContent !==
                            undefined) ||
                        (this.state.spellingCorrectIndex === NO_INDEX &&
                            this.state.spellingText !== undefined &&
                            this.state.spellingText !== "")
                );
                // step 3 complete
                conditions.push(
                    this.state.selectedRemoveContent !== undefined &&
                        this.state.selectedRemoveContent.length > 0
                );
                break;
            case ArtistProfileIssueType.IncorrectContent:
                // step 1 complete
                conditions.push(
                    this.state.selectedArtistWithProblem !== undefined
                );
                // step 2 complete
                conditions.push(
                    this.state.selectedRemoveContent !== undefined &&
                        this.state.selectedRemoveContent.length > 0
                );
                // step 3 complete
                conditions.push(
                    this.state.selectedRemainContent !== undefined &&
                        this.state.selectedRemainContent.length > 0
                );
                break;
            default:
                conditions.push(false);
        }

        return (
            !_.reduce(
                conditions,
                (prev: boolean, curr: boolean) => prev && curr,
                true
            ) || this.props.newDisambiguationInProgress
        );
    };

    /* Help modal */
    private onSearchArtistHelp = () => {
        this.setState({ showHelpModal: true });
    };

    private dismissModal = () => {
        this.setState({ showHelpModal: false });
    };

    private shouldLoadResultsForSearch = (
        searchSourceType: searchSourceType
    ) => {
        return (
            !this.state.showError &&
            this.state.searchSource === searchSourceType
        );
    };

    private clickOnRemoveContent = (index: number) => {
        const itemToRemove = this.state.selectedRemoveContent[index];

        this.setState({
            selectedRemoveContent: _.reject(
                this.state.selectedRemoveContent,
                itemToRemove
            ),
        });
    };

    private clickOnRemainContent = (index: number) => {
        const itemToRemove = this.state.selectedRemainContent[index];
        this.setState({
            selectedRemainContent: _.reject(
                this.state.selectedRemainContent,
                itemToRemove
            ),
        });
    };

    /* Other info */
    private setMoreInfoText = (text: string) => {
        this.setState({ moreInfo: text });
    };

    private getTrackAlbumsNotYetSelected = () => {
        return getFilteredMediaItem(
            getFilteredMediaItem(
                this.state.trackAlbums,
                this.state.selectedRemainContent
            ),
            this.state.selectedRemoveContent
        );
    };
}

const commonWidthStyle: React.CSSProperties = {
    maxWidth: COMMON_MAX_WIDTH,
    width: "100%",
};

const sidePaddingRowStyle: React.CSSProperties = {
    paddingRight: 15,
    paddingLeft: 15,
};

const sidePaddingInnerStyle: React.CSSProperties = {
    marginLeft: 28,
    marginTop: rootStyles.spacers.base,
};

const rowStyle: React.CSSProperties = {
    ...commonWidthStyle,
    ...sidePaddingRowStyle,
};

const formFieldRowStyle: React.CSSProperties = {
    ...sidePaddingRowStyle,
};

const fieldTitleStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    textTransform: "uppercase",
};

const helpRowStyle: React.CSSProperties = {
    ...rootStyles.textStyles.tertiary,
    marginTop: rootStyles.spacers.base,
};

const actionButtonStyle: React.CSSProperties = {
    alignSelf: "stretch",
    width: "100%",
};

const selectedTrackAlbumStyle: React.CSSProperties = {
    maxWidth: COMMON_MAX_WIDTH,
    backgroundColor: rootStyles.glassColors.primary2,
    marginTop: rootStyles.spacers.mini,
    borderRadius: 9,
};

const footerButtonsStyle: React.CSSProperties = {
    maxWidth: COMMON_MAX_WIDTH,
    width: "100%",
    position: "fixed",
    bottom: 16,
    zIndex: 2,
};

const SharedRowStepContainer = styled(Col)`
    padding: 0px;
    width: ${COMMON_MAX_WIDTH}px;
    @media (min-width: ${COMMON_MAX_WIDTH}px) {
        min-width: ${COMMON_MAX_WIDTH}px;
    }
`;

function mapStateToProps(state: RootState) {
    return {
        ...reportArtistIssuePageSelectors(state, pagePath),
        bundleMap: state.localization.bundleMap,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        artistSearch: (payload: artistSearchQueryPayload) =>
            dispatch(artistSearchActions.artistSearch(payload)),
        clearArtistSearch: () =>
            dispatch(artistSearchActions.clearArtistSearch()),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        trackAlbumSearch: (payload: trackAlbumSearchQueryPayload) =>
            dispatch(contentSearchActions.trackAlbumSearch(payload)),
        clearTrackAlbumSearch: () =>
            dispatch(contentSearchActions.clearTrackAlbumSearch()),
        clearSelectedReportIssueItem: () =>
            dispatch(artistIssueActions.clearSelectedReportIssueItem()),
        newDisambiguation: (payload: newDisambiguationPayload) =>
            dispatch(artistIssueActions.newDisambiguation(payload)),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportArtistIssueScreen);
