import * as React from "react";
import { useSelector } from "react-redux";
import { IconsList, ImageList } from "../../../assets";
import { BundleMap, baseMediaItem } from "../../../models";
import { Search } from "./input";
import * as rootStyles from "../../styles";
import { ContentListItem } from "../lists";
import { Row, Col, Spinner } from "react-bootstrap";
import {
    getSelectedContentTypeReleaseDateText,
    getSelectedContentTypeYearText,
} from "../../../utils";
import { commonSelectors } from "../../../store/selectors";

export type SearchSelectProps = {
    onSelectElement: (item: baseMediaItem) => void;
    inProgress: boolean;
    displayResults: boolean;
    items: baseMediaItem[];
    onTextChange: (text: string) => void;
    showAddIcon?: boolean;
    squareImage?: boolean;
    showReleaseDate?: boolean;
    placeholderText?: string;
    id: string;
};

export const SearchSelect: React.FC<SearchSelectProps> = ({
    onSelectElement: onSelectedElement,
    inProgress,
    displayResults,
    items,
    onTextChange,
    showAddIcon,
    squareImage,
    placeholderText,
    id,
    showReleaseDate,
}) => {
    const [searchText, setSearchText] = React.useState("");

    const SearchSelectPrefix = "-SearchSelect";
    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const updateText = (textObject: any) => {
        const text = textObject.target.value ? textObject.target.value : "";
        setSearchText(text);
        onTextChange(text);
    };

    const selectedItem = (item: baseMediaItem) => {
        setSearchText("");
        onTextChange("");
        onSelectedElement(item);
    };

    const renderItem = (item: baseMediaItem, index: number) => {
        const secondary = showReleaseDate
            ? getSelectedContentTypeReleaseDateText(
                  item.localOriginalReleaseDate
              )
            : getSelectedContentTypeYearText(item.type, item.year, bundleMap);
        return (
            <ContentListItem
                key={item.asin}
                primary={item.title}
                secondary={secondary}
                image={item?.images?.artMedium}
                fallbackImage={ImageList.placeholder_artist}
                squareImage={squareImage}
                onClick={() => selectedItem(item)}
                imageDescription={item.title}
                rightIcon={showAddIcon && IconsList.action_add}
                id={`${id}_ResultItem_${index}`}
            />
        );
    };

    return (
        <>
            <Row style={{ marginTop: rootStyles.spacers.micro, width: "100%" }}>
                <Col style={{ padding: 0 }}>
                    <Search
                        value={searchText}
                        onChange={updateText}
                        textInputStyle={{
                            color: rootStyles.colors.primary,
                        }}
                        dark={true}
                        hasMaxWidth={true}
                        placeholder={placeholderText}
                        id={`${id}_Search`}
                    />
                </Col>
            </Row>
            {displayResults && (
                <div style={{ width: "calc(100% - 30px)" }}>
                    <Row
                        style={searchResultsStyle}
                        id={id + SearchSelectPrefix + "-ResultContainer"}
                    >
                        {!inProgress ? (
                            <Col>{items.map(renderItem)}</Col>
                        ) : (
                            <Col
                                style={{
                                    textAlign: "center",
                                    marginTop: rootStyles.spacers.large,
                                    marginBottom: rootStyles.spacers.large,
                                }}
                            >
                                {inProgress && (
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                    />
                                )}
                            </Col>
                        )}
                    </Row>
                </div>
            )}
        </>
    );
};

const searchResultsStyle: React.CSSProperties = {
    flex: 1,
    width: "inherit",
    position: "absolute",
    backgroundColor: rootStyles.colors.secondary,
    backgroundClip: "content-box",
    borderRadius: 24,
    maxHeight: "30vh",
    overflowY: "auto",
    zIndex: 10,
    padding: 0,
    marginTop: rootStyles.spacers.small,
    maxWidth: 450,
};

export default SearchSelect;
