import * as React from "react";
import * as rootStyles from "../../../styles";
import { Col, Row } from "react-bootstrap";
import { styledTitle } from "..";

export type TitleDescriptionElementProps = {
    primary: string;
    secondary: string;
    elementNumber?: number;
    id: string;
};

export class TitleDescriptionElement extends React.Component<TitleDescriptionElementProps> {
    render() {
        return (
            <Row style={elementContainer}>
                <Col style={textContainer} xs={"auto"}>
                    <Row>
                        <styledTitle.h4
                            id={`${this.props.id}_Text${this.props.elementNumber}`}
                            style={{ marginTop: rootStyles.spacers.mini }}
                        >
                            {this.props.primary}
                        </styledTitle.h4>
                    </Row>
                    <Row
                        id={`${this.props.id}-Description`}
                        style={{
                            marginTop: rootStyles.spacers.mini,
                            marginBottom: rootStyles.spacers.mini,
                        }}
                    >
                        <p style={descriptionStyle}>{this.props.secondary}</p>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const elementContainer: React.CSSProperties = {
    padding: rootStyles.spacers.mini,
    borderBottomStyle: "solid",
    paddingBottom: 0,
    borderBottomColor: rootStyles.glassColors.primary3,
    borderBottomWidth: 1,
    flex: 1,
    justifyContent: "space-between",
    marginRight: rootStyles.spacers.small,
};

const descriptionStyle: React.CSSProperties = {
    lineHeight: `${rootStyles.spacers.medium}px`,
    textAlign: "left",
    ...rootStyles.textStyles.primary,
};

const textContainer: React.CSSProperties = {
    alignSelf: "flex-start",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: rootStyles.spacers.micro,
};
