import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";
import * as rootStyles from "../../styles";
import {
    RootState,
    userActions,
    artistSearchActions,
    selectArtistPageSelector,
    telemetryActions,
    clientMetricsActions,
    globalNotificationsActions,
    catalogActions,
} from "../../../store";
import {
    artist,
    teamInfo,
    ErrorPayload,
    artistSearchQueryPayload,
    selectArtistPayload,
    telemetryPayload,
    METRIC_KEYS,
    localStorageStrings,
    EntityType,
    clientMetricsPayload,
    BundleMap,
    ModalRenderFunction,
    deeplinkConfig,
    hydrateCatalogPayload,
    CatalogItemType,
} from "../../../models";
import {
    getLocalizedString,
    paths,
    testIDSuffixes,
    onArtistSelected,
    SelectArtistProps,
    buildUIClickPayload,
} from "../../../utils";
import { stringIds, ImageList, IconsList, bundleIds } from "../../../assets";
import {
    Search,
    Loading,
    InlineError,
    styledTitle,
    MediumGlassButton,
    HelpModal,
    Icon,
} from "../../components";
import { ContentListItem } from "../../components/lists";
import styled from "styled-components";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import { bundleMapSelector } from "../../../store/selectors/commonSelectors";
import { mobileFontSizes } from "../../styles";

const testIDPrefix = "SelectArtistScreen";
const metricPrefix = "selectArtistPage";

const QUERY_DELAY_TIME = 500;

type ViewProps = {
    navigation: any;
};

type StateProps = {
    artists: artist[];
    recentlyViewed: artist[];
    isPrivileged?: boolean;
    doRemoteSearch?: boolean;
    signedIn: boolean;
    teams?: teamInfo[];
    selectedArtist?: string;
    inProgress: boolean;
    error?: ErrorPayload;
    refreshInProgress?: boolean;
    hydrationInProgress?: boolean;
    doRedirectToReports: boolean;
    bundleMap: BundleMap;
    currentPath: string;
    deeplinkMap?: deeplinkConfig;
    deeplinkRoute?: string;
};

type DispatchProps = {
    artistSearch: (payload: artistSearchQueryPayload) => void;
    artistSelect: (payload: selectArtistPayload) => void;
    clearArtistSearch: () => void;
    userAction: (payload: telemetryPayload) => void;
    updateCurrentPath: (payload: string) => void;
    updateRedirectToReports: (payload: boolean) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
    requestModal: (payload: ModalRenderFunction) => void;
    resetDeeplink: () => void;
    hydrateAsin: (payload: hydrateCatalogPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = {
    text: string;
    showError?: boolean;
    artists: artist[];
    refreshing: boolean;
};

class SelectArtistScreen extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            text: "",
            refreshing: false,
            artists: [],
        };
        this.makeSearchQuery = this.makeSearchQuery.bind(this);
        this.makeSearchQuery = _.debounce(
            this.makeSearchQuery,
            QUERY_DELAY_TIME
        );

        this.props.updateCurrentPath(window.location.pathname);
    }

    componentWillUnmount() {
        this.setState({ text: "" });
        this.props.clearArtistSearch();
    }

    componentDidMount() {
        this.props.hydrateAsin({
            asins: _.map(this.props.recentlyViewed, (artist) => {
                return artist.asin ? artist.asin : "";
            }),
            type: CatalogItemType.Artists,
        });
        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.artistSelect],
            ]),
        });

        if (localStorage.getItem(localStorageStrings.inviteId)) {
            this.props.history.replace(paths.acceptInvite);
            return;
        }

        if (!this.props.refreshInProgress) {
            // Hasn't claimed artist
            // Use teams instead of artists in case artist hydration not started or completed
            if (!this.props.doRemoteSearch && this.props.teams?.length === 0) {
                this.props.history.replace(paths.requesterType);
                return;
                /* Redirect user to reports after login */
            } else if (
                !this.props.doRemoteSearch &&
                this.props.artists.length === 1 &&
                this.props.artists[0] &&
                this.props.doRedirectToReports
            ) {
                this.artistSelected(this.props.artists[0]);
            }
        }

        this.props.clearArtistSearch();
        const newArtists = _.filter(
            this.props.artists,
            (artist) => artist !== null
        );
        const sortedArtists = newArtists.sort((artist1, artist2) => {
            if (artist1.asin === this.props.selectedArtist) {
                return -1;
            }
            if (artist2.asin === this.props.selectedArtist) {
                return 1;
            }
            return 0;
        });

        this.setState({ artists: sortedArtists });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        // The list of artists changed (new service search)
        if (this.props.artists && this.props.artists !== prevProps.artists) {
            const newArtists = _.filter(
                this.props.artists,
                (artist) => artist?.hasPermission !== false
            );
            const sortedArtists = newArtists.sort((artist1, artist2) => {
                if (artist1.asin === this.props.selectedArtist) {
                    return -1;
                }
                if (artist2.asin === this.props.selectedArtist) {
                    return 1;
                }
                return 0;
            });

            this.setState({ artists: sortedArtists });

            /* Redirect user to reports after login */
            if (
                this.props.doRedirectToReports &&
                !this.props.doRemoteSearch &&
                this.props.artists.length === 1 &&
                this.props.artists[0]
            ) {
                this.artistSelected(this.props.artists[0]);
            }
        } else if (
            this.props.artists &&
            !this.props.doRemoteSearch &&
            prevState.text !== this.state.text
        ) {
            // The search text changed
            const filteredArtists = _.filter(this.props.artists, (artist) => {
                const filter = artist?.title
                    ?.toLowerCase()
                    .includes(this.state.text.toLowerCase());
                return filter || false;
            });
            this.setState({ artists: filteredArtists });
        }

        if (prevProps.refreshInProgress && !this.props.refreshInProgress) {
            this.setState({ refreshing: false });

            // Hasn't claimed artist
            if (!this.props.doRemoteSearch && this.props.artists.length === 0) {
                this.props.history.replace(paths.claim);
                return;
            }
        }

        if (this.props.error !== prevProps.error) {
            this.setState({ showError: this.props.error !== undefined });
        }
    }

    render() {
        const loading = <Loading />;
        // If can do remote search, then show the recently viewed first, then the search results as soon as user starts typing.
        // If not, show the search results, which only has the user's teams artits and not remote searched artists
        const artists = this.props.doRemoteSearch
            ? this.state.text.length > 0
                ? this.state.artists
                : this.props.recentlyViewed
            : this.state.artists;

        const body = (
            <div style={{ flex: 1 }}>
                <Row
                    style={{
                        alignItems: "center",
                        flexDirection: "row-reverse",
                    }}
                >
                    {!this.props.doRemoteSearch && (
                        <Col xs={12} lg={4}>
                            <ClaimArtistContainer>
                                <MediumGlassButton
                                    leftIcon={IconsList.action_add}
                                    containerStyle={{
                                        width: "100%",
                                        minWidth: 200,
                                    }}
                                    onClick={this.claimArtist}
                                    title={getLocalizedString(
                                        this.props.bundleMap,
                                        {
                                            bundleId:
                                                bundleIds.CLAIMANARTIST_STRINGS,
                                            stringId:
                                                stringIds.ClaimAnArtist.button,
                                        }
                                    )}
                                    titleStyle={{
                                        paddingRight:
                                            rootStyles.spacers.mini + // icon margin size
                                            rootStyles.spacers.base, // icon size
                                    }}
                                    id={
                                        testIDPrefix +
                                        "_ClaimAnArtist" +
                                        testIDSuffixes.button
                                    }
                                />
                            </ClaimArtistContainer>
                        </Col>
                    )}
                    <Col style={{ marginBottom: rootStyles.spacers.medium }}>
                        <div style={helpSectionStyle}>
                            <span>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                                    stringId:
                                        stringIds.SearchForArtist
                                            .helpModalSearchArtistAdvice,
                                })}
                                <Icon
                                    icon={IconsList.ic_help}
                                    size={rootStyles.icons.tiny}
                                    style={{
                                        opacity: rootStyles.glass._4,
                                        marginLeft: rootStyles.spacers.mini,
                                        verticalAlign: "sub",
                                    }}
                                    id={`${testIDPrefix}_HelpIcon`}
                                    onClick={this.onHelpIconClicked}
                                />
                            </span>
                        </div>
                        <Search
                            value={this.state.text}
                            placeholder={getLocalizedString(
                                this.props.bundleMap,
                                {
                                    bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                                    stringId: stringIds.SearchForArtist.search,
                                }
                            )}
                            onChange={this.updateText}
                            id={testIDPrefix + testIDSuffixes.searchbar}
                        />
                    </Col>
                </Row>
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.artistSearchError,
                            })}
                            onDismiss={this.dismissError}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}

                    {this.loadingArtistsInProgress() &&
                        !this.state.showError &&
                        loading}
                </Row>
                <Row>
                    <Col>
                        {this.state.text.length === 0 &&
                            this.props.recentlyViewed.length > 0 && (
                                <styledTitle.h4
                                    style={{
                                        paddingBottom: rootStyles.spacers.micro,
                                    }}
                                >
                                    {getLocalizedString(this.props.bundleMap, {
                                        bundleId:
                                            bundleIds.SEARCHFORARTIST_STRINGS,
                                        stringId:
                                            stringIds.SearchForArtist
                                                .recentlyViewed,
                                    })}
                                </styledTitle.h4>
                            )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(this.loadingArtistsInProgress() ||
                        this.state.showError
                            ? []
                            : artists
                        ).map((artist: artist, index: number) =>
                            this.renderItem(artist, index)
                        )}
                    </Col>
                </Row>
            </div>
        );

        return (
            <PaddedContainer
                fluid={true}
                className="rootContainer"
                style={{
                    ...rootStyles.containerStyles.rootViewContainer,
                }}
            >
                {body}
            </PaddedContainer>
        );
    }

    private loadingArtistsInProgress = () => {
        return this.props.inProgress || this.props.hydrationInProgress;
    };

    private claimArtist = () => {
        this.props.userAction({
            name: metricPrefix + "ClaimArtistButtonClick",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.page, paths.artistSelect],
            ]),
        });
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.SelectArtist.claimArtist,
                pageIds.artistSelect,
                this.props.selectedArtist,
                EntityType.ARTIST
            )
        );
        this.props.history?.push(paths.requesterType);
    };

    private renderItem = (item: artist, index: number) => {
        if (!item) {
            return null;
        }

        const bottomBorderIndex = this.props.doRemoteSearch
            ? this.state.text.length > 0
                ? this.props.artists.length - 1
                : this.props.recentlyViewed.length - 1
            : this.props.artists.length - 1;

        return (
            <ContentListItem
                key={item.title}
                primary={item.title}
                image={item.images && item.images.artMedium}
                showBottomBorder={index !== bottomBorderIndex}
                fallbackImage={ImageList.placeholder_artist}
                imageDescription={item.title}
                isSelected={this.props.selectedArtist === item.asin}
                onClick={() => this.artistSelected(item)}
                id={testIDPrefix + testIDSuffixes.item + item.asin}
            />
        );
    };

    private keyExtractor = (_item: artist, index: number) => index.toString();

    private updateText = (text: any) => {
        this.setState(
            { text: text.target.value ? text.target.value : "" },
            this.onTextChanged
        );
    };

    private onTextChanged = () => {
        if (!this.props.signedIn) {
            return;
        }
        this.makeSearchQuery();
    };

    private dismissError = () => {
        this.setState({ showError: false });
    };

    private makeSearchQuery() {
        if (!this.props.signedIn) {
            return;
        }

        const payload: artistSearchQueryPayload = {
            query: this.state.text,
            requestPath: paths.artistSelect,
            musicTerritory: "US",
        };

        this.props.artistSearch(payload);
    }

    private artistSelected = (artist: artist) => {
        let path = paths.artistSelect;
        if (
            this.props.deeplinkRoute !== undefined &&
            this.props.deeplinkMap !== undefined
        ) {
            path = this.props.deeplinkRoute;
        }
        let request: SelectArtistProps = {
            signedIn: this.props.signedIn,
            selectedArtist: artist,
            teams: this.props.teams,
            path: path,
            metricPrefix: metricPrefix,
            isPrivileged: this.props.isPrivileged,
            historyProps: this.props,
            userAction: this.props.userAction,
            artistSelect: this.props.artistSelect,
            clearArtistSearch: this.props.clearArtistSearch,
        };
        let artistSelectReturn = onArtistSelected(request);
        if (artistSelectReturn) {
            if (this.props.deeplinkRoute !== undefined) {
                if (
                    this.props.deeplinkMap !== undefined &&
                    this.props.deeplinkMap.path !== this.props.currentPath
                ) {
                    this.props.updateRedirectToReports(false);
                }
            } else {
                this.setState({ text: "" });
                this.props.updateRedirectToReports(false);
            }
        }
    };

    private onHelpIconClicked = () => {
        this.props.requestModal(this.showHelpModal);
    };

    private helpModalBody = () => {
        const mediumSpacer = (
            <div style={{ flex: 1, minHeight: rootStyles.spacers.medium }} />
        );

        return (
            <Row style={{ width: "100%" }}>
                <span
                    style={{
                        ...rootStyles.textStyles.secondary,
                        color: rootStyles.colors.primary,
                    }}
                >
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId:
                            stringIds.SearchForArtist.helpModalInstructionOneA,
                    }) + " "}
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId:
                            stringIds.SearchForArtist.helpModalAmazonMusic,
                    })}
                    {" " +
                        getLocalizedString(this.props.bundleMap, {
                            bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                            stringId:
                                stringIds.SearchForArtist
                                    .helpModalInstructionOneB,
                        })}
                    {mediumSpacer}
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId:
                            stringIds.SearchForArtist.helpModalInstructionTwo,
                    })}
                    {mediumSpacer}
                    {getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                        stringId:
                            stringIds.SearchForArtist
                                .helpModalInstructionThreeCommon,
                    })}
                </span>
            </Row>
        );
    };

    private showHelpModal: ModalRenderFunction = (
        onDismiss: () => void,
        isVisible: boolean
    ) => {
        return (
            <HelpModal
                isVisible={isVisible}
                onDismiss={onDismiss}
                accept={onDismiss}
                title={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                    stringId: stringIds.SearchForArtist.helpModalTitle,
                })}
                body={this.helpModalBody}
                acceptbuttonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.SEARCHFORARTIST_STRINGS,
                    stringId: stringIds.SearchForArtist.helpModalGotIt,
                })}
                id={`${testIDPrefix}_SearchForArtistHelpModal`}
            />
        );
    };
}

const PaddedContainer = styled(Container)`
    padding-top: 32px;
    @media (max-width: 992px) {
        padding-top: 80px;
    }
`;

const ClaimArtistContainer = styled.div`
    color: ${rootStyles.glassColors.primary2};
    border-left: 1px solid;
    padding-left: ${rootStyles.spacers.giant}px;
    margin-left: ${rootStyles.spacers.mini}px;
    margin-bottom: ${rootStyles.spacers.medium}px;
    margin-top: ${rootStyles.spacers.giant}px;
    position: relative;

    @media (max-width: 992px) {
        margin-top: ${rootStyles.spacers.large}px;
        border-left: none;
        padding-left: 0;
        margin-left: 0;
    }
`;

const helpSectionStyle: React.CSSProperties = {
    ...rootStyles.textStyles.secondary,
    flexDirection: "row",
    display: "flex",
    marginBottom: rootStyles.spacers.medium,
};

function mapStateToProps(state: RootState): StateProps {
    return {
        ...selectArtistPageSelector(state, paths.artistSelect),
        ...bundleMapSelector(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
    return {
        artistSearch: (payload: artistSearchQueryPayload) =>
            dispatch(artistSearchActions.artistSearch(payload)),
        artistSelect: (payload: selectArtistPayload) =>
            dispatch(userActions.selectArtist(payload)),
        clearArtistSearch: () =>
            dispatch(artistSearchActions.clearArtistSearch()),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        updateRedirectToReports: (payload: boolean) =>
            dispatch(userActions.updateUserRedirectedToReports(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
        requestModal: (payload: ModalRenderFunction) =>
            dispatch(globalNotificationsActions.requestModalDisplay(payload)),
        resetDeeplink: () => dispatch(userActions.resetDeeplink()),
        hydrateAsin: (payload: hydrateCatalogPayload) =>
            dispatch(catalogActions.hydrateAsins(payload)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectArtistScreen);
