import * as React from "react";
import { Row } from "react-bootstrap";
import * as rootStyles from "../../../styles";
import { CheckItem, CheckItemData } from ".";
import { testIDSuffixes } from "../../../../utils";

export type ChecklistProps = {
    label?: string;
    onChangeState: (indices: number[]) => void;
    elements: CheckItemData[];
    initialState?: number[];
    style?: any;
    hideBorder?: boolean;
    id: string;
};

type State = {
    selectedIndexList: number[];
};

export class Checklist extends React.Component<ChecklistProps, State> {
    constructor(props: ChecklistProps) {
        super(props);
        this.state = {
            selectedIndexList: this.props.initialState
                ? this.props.initialState
                : [],
        };
    }

    render() {
        const checklistStyles = {
            ...checklistContainer,
            ...(this.props.hideBorder ? {} : checklistContainerBorder),
            ...this.props.style,
        };

        return (
            <Row style={checklistStyles}>
                {this.props.label && (
                    <span style={labelStyle}>{this.props.label}</span>
                )}
                {this.renderButtonList(this.props.elements)}
            </Row>
        );
    }

    private renderButtonList = (options: CheckItemData[]) => {
        const ret = [];

        for (let i = 0; i < options.length; i++) {
            ret.push(
                <CheckItem
                    data={options[i]}
                    onButtonToggle={() => this.onButtonToggle(i)}
                    selected={this.state.selectedIndexList.indexOf(i) !== -1}
                    key={i}
                    id={this.props.id + testIDSuffixes.item + i}
                />
            );
        }

        return ret;
    };

    private onButtonToggle = (number: number) => {
        if (this.state.selectedIndexList.indexOf(number) === -1) {
            const newList = [...this.state.selectedIndexList, number];
            this.setState({ selectedIndexList: newList });
            this.props.onChangeState(newList);
        } else {
            const newList = this.state.selectedIndexList.filter(
                (value: number) => {
                    return value !== number;
                }
            );

            this.setState({
                selectedIndexList: newList,
            });

            this.props.onChangeState(newList);
        }
    };
}

const checklistContainer: React.CSSProperties = {
    flex: 1,
    width: "100%",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    paddingTop: rootStyles.spacers.large,
};
const checklistContainerBorder: React.CSSProperties = {
    borderTopWidth: 1,
    borderTopColor: rootStyles.glassColors.primary3,
};
const labelStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
    marginBottom: rootStyles.spacers.small,
};
