import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import * as rootStyles from "../../styles";
import { testIDSuffixes } from "../../../utils";
import { ImageWithFallback, Icon } from "..";

export type CustomListItemProps = {
    label?: string;
    primary?: string;
    secondary?: string;
    tertiary?: string;
    index?: number;
    stat1?: string;
    stat2?: string;
    stat3?: string;
    stat3sub?: string;
    onClick?: any;
    image?: string;
    fallbackImage?: string;
    imageDescription?: string;
    leftIcon?: any;
    leftIconDescription?: string;
    rightElement?: JSX.Element;
    rightElementDescription?: string;
    style?: React.CSSProperties;
    touchable?: boolean;
    id: string;
};

export class CustomListItem extends React.PureComponent<CustomListItemProps> {
    render() {
        if (this.props.touchable) {
            return (
                <Row onClick={this.props.onClick} id={this.props.id}>
                    {this.getInnerView()}
                </Row>
            );
        } else {
            return this.getInnerView();
        }
    }

    private getInnerView() {
        return (
            <Row
                id={this.props.id + "-InnerView"}
                style={{ ...containerStyle, ...this.props.style }}
            >
                <Col style={{ padding: 0 }}>
                    <Container fluid={true} style={{ padding: 0, margin: 0 }}>
                        <Row>
                            {this.props.image ? (
                                <Col id={this.props.id + "-Image"} xs={1}>
                                    <ImageWithFallback
                                        style={artistImage}
                                        source={this.props.image}
                                        fallback={this.props.fallbackImage}
                                        id={`${this.props.id}_Image`}
                                    />
                                </Col>
                            ) : null}
                            {this.props.primary && (
                                <Col
                                    id={this.props.id + "-Primary"}
                                    lg={true}
                                    sm={6}
                                    xs={8}
                                    style={textContainer}
                                >
                                    {this.props.label && (
                                        <Row id={this.props.id + "-Label"}>
                                            <span
                                                style={
                                                    rootStyles.textStyles.label
                                                }
                                            >
                                                {this.props.label}
                                            </span>
                                        </Row>
                                    )}
                                    <Row>
                                        <span
                                            style={
                                                rootStyles.textStyles.primary
                                            }
                                        >
                                            {this.props.primary}
                                        </span>
                                    </Row>
                                    {this.props.secondary && (
                                        <Row id={this.props.id + "-Secondary"}>
                                            <span
                                                style={
                                                    rootStyles.textStyles
                                                        .secondary
                                                }
                                            >
                                                {this.props.secondary}
                                            </span>
                                        </Row>
                                    )}
                                    {this.props.tertiary && (
                                        <Row id={this.props.id + "-Tertiary"}>
                                            <span
                                                style={
                                                    rootStyles.textStyles
                                                        .secondary
                                                }
                                            >
                                                {this.props.tertiary}
                                            </span>
                                        </Row>
                                    )}
                                </Col>
                            )}
                            {this.props.index && (
                                <Col
                                    id={this.props.id + "-Index"}
                                    style={indexContainer}
                                >
                                    <Row style={indexStyle}>
                                        <span style={indexTextStyle}>
                                            {this.props.index}
                                        </span>
                                    </Row>
                                </Col>
                            )}

                            {this.props.stat1 && (
                                <Col
                                    id={this.props.id + "-Stat1"}
                                    className="d-none d-sm-block"
                                    style={statTextContainer}
                                >
                                    <span
                                        style={rootStyles.textStyles.secondary}
                                    >
                                        {this.props.stat1}
                                    </span>
                                </Col>
                            )}
                            {this.props.stat2 && (
                                <Col
                                    id={this.props.id + "-Stat2"}
                                    lg={true}
                                    sm={3}
                                    className="d-none d-sm-block"
                                    style={statTextContainer}
                                >
                                    <span
                                        style={rootStyles.textStyles.secondary}
                                    >
                                        {this.props.stat2}
                                    </span>
                                </Col>
                            )}
                            {this.props.stat3 && (
                                <Col
                                    id={this.props.id + "-Stat3"}
                                    lg={true}
                                    sm={3}
                                    xs={4}
                                    style={statTextContainer}
                                >
                                    <Row id={this.props.id + "-Stat3"}>
                                        <span
                                            style={
                                                rootStyles.textStyles.secondary
                                            }
                                        >
                                            {this.props.stat3}
                                        </span>
                                    </Row>

                                    {this.props.stat3sub && (
                                        <Row id={this.props.id + "-Stat3Sub"}>
                                            <span
                                                style={
                                                    rootStyles.textStyles
                                                        .secondary
                                                }
                                            >
                                                {this.props.stat3sub}
                                            </span>
                                        </Row>
                                    )}
                                </Col>
                            )}

                            {this.props.leftIcon && (
                                <Col
                                    id={this.props.id + "-LeftIcon"}
                                    style={iconButtonContainer}
                                >
                                    <Icon
                                        icon={this.props.leftIcon}
                                        size={rootStyles.icons.small}
                                        id={
                                            this.props.id +
                                            testIDSuffixes.leftIcon
                                        }
                                        iconDescription={
                                            this.props.leftIconDescription
                                        }
                                    />
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Col>
                {this.props.rightElement && (
                    <Col
                        id={this.props.id + "-RightElement"}
                        xs="auto"
                        style={rightAlignElement}
                    >
                        {this.props.rightElement}
                    </Col>
                )}
            </Row>
        );
    }
}

const sharedContainerStyle: React.CSSProperties = {
    flex: 1,
    marginTop: rootStyles.spacers.mini,
    paddingBottom: rootStyles.spacers.micro,
    marginBottom: rootStyles.spacers.micro,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
};
const containerStyle: React.CSSProperties = {
    ...sharedContainerStyle,
};
const iconButtonStyle: React.CSSProperties = {
    height: rootStyles.icons.tiny,
    width: rootStyles.icons.tiny,
};
const textContainer: React.CSSProperties = {
    minHeight: 64,
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
    overflow: "hidden",
};
const indexContainer: React.CSSProperties = {
    flex: 1,
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 40,
};
const indexStyle: React.CSSProperties = {
    backgroundColor: rootStyles.colors.accent,
    borderRadius: 32,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-start",
    flex: 1,
    padding: rootStyles.spacers.micro,
    maxHeight: 20,
};
const indexTextStyle: React.CSSProperties = {
    ...rootStyles.textStyles.index,
};
const statTextContainer: React.CSSProperties = {
    textAlign: "left",
};
const leftIconContainer: React.CSSProperties = {
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    height: 64,
    width: 40,
    marginRight: rootStyles.spacers.base,
    flex: 0,
};
const iconButtonContainer: React.CSSProperties = {
    alignSelf: "flex-start",
    alignItems: "center",
    justifyContent: "center",
    height: 64,
    width: 40,
    flex: 0,
};
const artistImage: React.CSSProperties = {
    height: 56,
    width: 56,
    borderRadius: 28,
    flex: 0,
    alignSelf: "center",
    marginLeft: rootStyles.spacers.mini,
    marginRight: rootStyles.spacers.base,
};
const rightAlignElement: React.CSSProperties = {
    minHeight: 64,
    flexDirection: "column",
    alignSelf: "center",
    justifyContent: "center",
    textAlign: "right",
    width: 130,
};
