import React from "react";
import { Row, Col } from "react-bootstrap";
import * as rootStyles from "../../styles";

type SettingsListItemProps = {
    primary: string;
    primaryStyle?: React.CSSProperties;
    primaryJSXElement?: (text: string) => JSX.Element;
    secondary?: string;
    secondaryStyle?: React.CSSProperties;
    id: string;
    onClick?: any;
    rightElement?: JSX.Element;
};

const SettingsListItem: React.FC<SettingsListItemProps> = ({
    primary,
    primaryStyle,
    primaryJSXElement,
    secondary,
    secondaryStyle,
    id,
    onClick,
    rightElement,
}) => {
    const cursor = onClick ? "pointer" : "inherit";
    return (
        <Row
            id={id + "-InnerView"}
            style={{ ...sharedContainerStyle, cursor: cursor }}
            onClick={onClick}
        >
            <Col style={{ padding: 0 }}>
                <Row id={id + "-Primary"}>
                    {primaryJSXElement ? (
                        primaryJSXElement(primary)
                    ) : (
                        <span
                            style={{
                                ...rootStyles.textStyles.primary,
                                ...primaryStyle,
                            }}
                        >
                            {primary}
                        </span>
                    )}
                </Row>
                {secondary && (
                    <Row id={id + "-Secondary"}>
                        <span
                            style={{
                                ...rootStyles.textStyles.secondary,
                                ...secondaryStyle,
                            }}
                        >
                            {secondary}
                        </span>
                    </Row>
                )}
            </Col>
            {rightElement && (
                <Col id={id + "-RightElement"} xs={3} style={rightAlignElement}>
                    {rightElement}
                </Col>
            )}
        </Row>
    );
};

const sharedContainerStyle: React.CSSProperties = {
    flex: 1,
    marginTop: rootStyles.spacers.mini,
    paddingBottom: rootStyles.spacers.micro,
    marginBottom: rootStyles.spacers.micro,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 64,
};

const rightAlignElement: React.CSSProperties = {
    minHeight: 64,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    width: 130,
    display: "flex",
};

export default SettingsListItem;
