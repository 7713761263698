import React from "react";
import * as rootStyles from "../../../styles";

export type SwitchProps = {
    isOn: boolean;
    handleToggle: () => void;
    size: number;
}

export class Switch extends React.Component<SwitchProps> {
    constructor(props: SwitchProps) {
        super(props);
    }
    render() {
        const absolsutePositionValue = this.props.size / 20;
        const switchButtonSize = this.props.size - this.props.size / 10;
        const labelWidth = this.props.size * 2;
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <input
                    checked={this.props.isOn}
                    onChange={this.props.handleToggle}
                    className="react-switch-checkbox"
                    id={`react-switch-new`}
                    type="checkbox"
                    style={{
                        height: 0,
                        width: 0,
                        visibility: "hidden",
                    }}
                />
                <label
                    className="react-switch-label"
                    htmlFor={`react-switch-new`}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        width: labelWidth,
                        height: this.props.size,
                        background: this.props.isOn
                            ? rootStyles.colors.accent
                            : rootStyles.glassColors.primary2,
                        borderRadius: labelWidth,
                        position: "relative",
                        transition: "background-color .2s",
                        margin: 0,
                    }}
                >
                    <span
                        className={`react-switch-button`}
                        style={{
                            content: "",
                            position: "absolute",
                            top: absolsutePositionValue,
                            left: absolsutePositionValue,
                            width: switchButtonSize,
                            height: switchButtonSize,
                            borderRadius: switchButtonSize,
                            transition: "0.2s",
                            background: rootStyles.colors.primary,
                            boxShadow: "0 0 2px 0 rgba(10, 10, 10, 0.29)",
                        }}
                    />
                </label>
            </div>
        );
    }
}
