import React from "react";
import styled, { keyframes } from "styled-components";
import { OverlayTrigger, Row } from "react-bootstrap";
import * as rootStyles from "../../../styles";
import { Icon, IconsList } from "../icons";

export const DownloadButtonPrefix = "DownloadButton";

const progressBarDegrees: number = 120;

export type DownloadButtonTypeProps = {
    loading: boolean;
    size: rootStyles.ButtonSize;
    onClick?: () => void;
    tooltipMessage: string;
    id: string;
    placement?: rootStyles.popupStyles.tooltipPlacement;
};

export const DownloadButton: React.FC<DownloadButtonTypeProps> = ({
    loading,
    size,
    onClick,
    id,
    tooltipMessage,
    placement,
}) => {
    const useMobileTooltip = window.innerWidth < 576;
    const renderTooltip = () => (
        <rootStyles.popupStyles.StyledTooltip
            placement={placement || useMobileTooltip ? "top" : "left"}
            id={`${id}-DownloadButtonTooltip`}
        >
            <Row>
                <span
                    style={{
                        ...rootStyles.textStyles.primary,
                        fontSize: useMobileTooltip
                            ? rootStyles.webFontSizes.t4
                            : rootStyles.webFontSizes.t2,
                        overflowWrap: "break-word",
                    }}
                >
                    {tooltipMessage}
                </span>
            </Row>
        </rootStyles.popupStyles.StyledTooltip>
    );

    return (
        <OverlayTrigger
            placement={placement || useMobileTooltip ? "top" : "left"}
            overlay={renderTooltip()}
            trigger={["hover", "focus"]}
            delay={{
                show: 300,
                hide: 300,
            }}
        >
            <IconButtonWithSpinnerContainer
                id={`${id}_${DownloadButtonPrefix}-ButtonWithSpinnerContainer`}
                style={{ width: size, height: size, padding: loading ? 1 : 0 }}
            >
                <StyledSpinner
                    id={`${id}_${DownloadButtonPrefix}-Spinner`}
                    style={{
                        background: loading
                            ? undefined
                            : rootStyles.colors.background,
                    }}
                />
                <StyledIconContainer
                    id={`${id}_${DownloadButtonPrefix}-StyledIconContainer`}
                >
                    <Icon
                        icon={IconsList.action_download}
                        size={size / 2}
                        onClick={onClick}
                        style={{
                            opacity: loading
                                ? rootStyles.glass._4
                                : rootStyles.glass._8,
                        }}
                        id={`${id}_DownloadIcon`}
                    />
                </StyledIconContainer>
            </IconButtonWithSpinnerContainer>
        </OverlayTrigger>
    );
};

const IconButtonWithSpinnerContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
`;

const SpinnerAnimation = keyframes`
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
`;

const StyledSpinner = styled.div`
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0.5px;
    background: conic-gradient(
        ${rootStyles.colors.accent} ${progressBarDegrees}deg,
        ${rootStyles.glassColors.primary4} ${progressBarDegrees}deg 360deg
    );
    justify-content: center;
    align-items: center;
    display: flex;
    animation: ${SpinnerAnimation} 1.5s linear infinite;
`;

const StyledIconContainer = styled.div`
    position: relative;
    background-color: ${rootStyles.colors.background};
    border-radius: 50%;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export default DownloadButton;
