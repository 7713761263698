import { createAction } from "redux-ts";
import { claimRequest, artist, checkPendingClaimPayload } from "../../models";

export const claimArtist = createAction<claimRequest>("CLAIM::ARTIST_CLAIM");
export const claimArtistCompleted = createAction<boolean>(
    "CLAIM::ARTIST_CLAIM_COMPLETED"
);
export const claimArtistInProgress = createAction<boolean>(
    "CLAIM::ARTIST_CLAIM_INPROGRESS"
);

export const selectArtistToClaim = createAction<artist>("CLAIM::SELECT_ARTIST");
export const clearSelectedArtistToClaim = createAction(
    "CLAIM::CLEAR_SELECTED_ARTIST"
);

export const checkPendingClaim = createAction<checkPendingClaimPayload>(
    "CLAIM::CHECK_PENDING_CLAIM"
);
export const checkPendingClaimCompleted = createAction<undefined | boolean>(
    "CLAIM::CHECK_PENDING_CLAIM_COMPLETED"
);
export const checkPendingClaimInProgress = createAction<boolean>(
    "CLAIM::CHECK_PENDING_CLAIM_INPROGRESS"
);
