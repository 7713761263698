import * as React from "react";
import { Dispatch, AnyAction } from "redux";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { buttonIds, pageIds } from "@amzn/ziggy-asset";
import {
    album,
    RequiredReportingState,
    RequiredReportingProps,
    METRIC_KEYS,
    trackListenersInfo,
    trackStreamsInfo,
    trackInfo,
    TimeRange,
    queryRequest,
    albumPerformanceRequestPayload,
    telemetryPayload,
    timeRangePayload,
    TableRowProps,
    countryInfo,
    EntityType,
    baseMediaItem,
    generateShareLinkPayload,
    shareContentResponse,
    createCsvFileRequestPayload,
    clientMetricsPayload,
    BundleMap,
} from "../../../models";
import {
    RootState,
    reportingActions,
    telemetryActions,
    albumPerformanceScreenSelector,
    catalogActions,
    userActions,
    artistIssueActions,
    csvActions,
    clientMetricsActions,
} from "../../../store";
import * as rootStyles from "../../styles";
import { stringIds, ImageList, bundleIds } from "../../../assets";
import {
    LineGraphProps,
    LineGraph,
    SmallGlassButton,
    VerticalSpacedTable,
    ProfileHead,
    ProfileHeadProps,
    InlineError,
    TableSeeAllButton,
    ShareContentMenu,
    DownloadButton,
} from "../../components";
import {
    getLocalizedString,
    generateQuery,
    timeRangeToTickCount,
    parseTrackDataPoints,
    generateSongRows,
    paths,
    SUMMARY_TABLE_ITEM_COUNT,
    testIDSuffixes,
    formatNumber,
    formatTooltipDatum,
    formatDatum,
    guessTimeZone,
    generateQueryWithoutGranularity,
    generateCountryRows,
    buildUIClickPayload,
} from "../../../utils";
import { Loading } from "../../components/common/Loading";
import { CsvButtonId, csvButtonLoadingStatusData } from "../../../export";
import { PromoCardStep } from "../../../utils/promoCard/promoCardConstants";

const testIDPrefix = "AlbumPerformanceScreen";
const metricPrefix = "albumPerformancePage";
const pagePath = paths.albumPerformance;

type ViewProps = {
    asin: string;
};

type StateProps = RequiredReportingProps & {
    listenersInfo?: trackListenersInfo;
    streamInfo?: trackStreamsInfo;
    topCountries: countryInfo[];
    tracks: trackInfo[];
    selectedAsin: string;
    shareLinkResponse: shareContentResponse;
    generateShareLinkInProgress: boolean;
    csvButtonLoadingStatus: csvButtonLoadingStatusData;
    hasCsvExportCapabilities: boolean;
    bundleMap: BundleMap;
    showHypeDeck: boolean;
};

type DispatchProps = {
    getAlbumPerformance: (request: albumPerformanceRequestPayload) => void;
    userAction: (payload: telemetryPayload) => void;
    selectAsin: (asin: string) => void;
    unselectAsin: (asin: string) => void;
    clearFailedAsins: () => void;
    updateCurrentPath: (payload: string) => void;
    selectReportIssueItem: (payload: baseMediaItem) => void;
    generateShareLink: (payload: generateShareLinkPayload) => void;
    getCsvFile: (payload: createCsvFileRequestPayload) => void;
    sendClientMetrics: (payload: clientMetricsPayload) => void;
};

type Props = DispatchProps & StateProps & RouteComponentProps<ViewProps>;

type State = RequiredReportingState & {
    albumCatalogItem?: album;
    showShareContentMenu: boolean;
};

class AlbumPerformanceScreen extends React.Component<Props, State> {
    private startTime = Date.now();

    constructor(props: Props) {
        super(props);
        this.state = {
            refreshing: false,
            showError: false,
            showShareContentMenu: false,
        };
        this.props.updateCurrentPath(window.location.pathname);
    }

    componentDidMount() {
        // If artist isnt selected, redirect to select artist page
        if (!this.props.selectedArtist) {
            this.props.history?.replace(paths.artistSelect);
            return;
        }

        this.setState({ finishedInitialLoad: false });
        this.startTime = Date.now();

        const asin = this.props.match.params.asin;
        if (!asin) {
            return;
        }

        this.props.userAction({
            name: metricPrefix + "View",
            dataPoints: new Map<string, string | undefined>([
                [METRIC_KEYS.artistAsin, this.props.selectedArtist],
                [METRIC_KEYS.timeRange, this.props.selectedRange],
                [METRIC_KEYS.albumAsin, asin],
                [METRIC_KEYS.page, pagePath],
            ]),
        });

        this.props.selectAsin(asin);

        const albumCatalogItem = this.props.catalog.get(asin) as album;
        if (albumCatalogItem) {
            this.setState({ albumCatalogItem: albumCatalogItem });
        }
        this.getAlbumPerformance();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.selectedRange !== this.props.selectedRange) {
            this.getAlbumPerformance();
        } else if (
            (this.props.selectedRange === TimeRange.Custom &&
                this.props.startDate !== prevProps.startDate) ||
            this.props.endDate !== prevProps.endDate
        ) {
            this.getAlbumPerformance();
        }

        if (prevProps.inProgress && !this.props.inProgress) {
            this.setState({ refreshing: false });
        }

        // Loaded everything for the first time
        if (
            this.state.finishedInitialLoad === false &&
            this.props.catalogBuildCompleted &&
            this.state.loadedReports &&
            this.props.hydrationInProgress &&
            !prevProps.hydrationInProgress
        ) {
            this.setState({ finishedInitialLoad: true });
        }

        if (this.props.error !== prevProps.error) {
            if (this.props.error && this.props.error.requestPath === pagePath) {
                this.setState({ showError: true });
            } else {
                this.setState({ showError: false });
            }
        }
    }

    render() {
        // Loading state
        if (this.props.inProgress && !this.state.refreshing) {
            return <Loading />;
        }

        const totalStreamsWOW =
            (this.props.streamInfo && this.props.streamInfo.delta) || 0;
        const streamCount =
            (this.props.streamInfo && this.props.streamInfo.totalCount) || 0;

        const userTimeZone = guessTimeZone();

        const streamLineGraphProps: LineGraphProps | undefined = this.props
            .streamInfo && {
            data: [
                parseTrackDataPoints(
                    this.props.streamInfo,
                    this.props.selectedRange === TimeRange.AllTime
                ),
            ],
            title: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.albumStreamsTitle,
                },
                { "0": formatNumber(streamCount) }
            ),
            subtitle: getLocalizedString(
                this.props.bundleMap,
                {
                    bundleId: bundleIds.REPORTS_STRINGS,
                    stringId: stringIds.Reports.weekOverWeek,
                },
                { "0": formatNumber(totalStreamsWOW) }
            ),
            labels:
                this.props.selectedRange === TimeRange.AllTime
                    ? [
                          getLocalizedString(this.props.bundleMap, {
                              bundleId: bundleIds.REPORTS_STRINGS,
                              stringId: stringIds.Reports.allTimeSubtitle,
                          }),
                      ]
                    : undefined,
            xTickCount: timeRangeToTickCount(this.props.selectedRange),
            tickFormat: (datum: any) =>
                formatDatum(datum, this.props.selectedRange, userTimeZone),
            tooltipFormat: (datum: any) =>
                formatTooltipDatum(
                    datum,
                    this.props.selectedRange,
                    userTimeZone
                ),
            id: testIDPrefix + "_StreamLineGraph",
            bundleMap: this.props.bundleMap,
        };

        const listenersCount =
            (this.props.listenersInfo && this.props.listenersInfo.totalCount) ||
            0;
        const totalListenersWOW =
            (this.props.listenersInfo && this.props.listenersInfo.delta) ||
            "N/A";

        const listenerLineGraphProps: LineGraphProps | undefined = this.props
            .listenersInfo &&
            this.props.listenersInfo && {
                data: [
                    parseTrackDataPoints(
                        this.props.listenersInfo,
                        this.props.selectedRange === TimeRange.AllTime
                    ),
                ],
                title: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.totalListenersTitle,
                    },
                    { "0": formatNumber(listenersCount) }
                ),
                subtitle: getLocalizedString(
                    this.props.bundleMap,
                    {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.weekOverWeek,
                    },
                    { "0": formatNumber(totalListenersWOW) }
                ),
                labels:
                    this.props.selectedRange === TimeRange.AllTime
                        ? [
                              getLocalizedString(this.props.bundleMap, {
                                  bundleId: bundleIds.REPORTS_STRINGS,
                                  stringId: stringIds.Reports.allTimeSubtitle,
                              }),
                          ]
                        : undefined,
                xTickCount: timeRangeToTickCount(this.props.selectedRange),
                tickFormat: (datum: any) =>
                    formatDatum(datum, this.props.selectedRange, userTimeZone),
                tooltipFormat: (datum: any) =>
                    formatTooltipDatum(
                        datum,
                        this.props.selectedRange,
                        userTimeZone
                    ),
                id: testIDPrefix + "_ListenerLineGraph",
                bundleMap: this.props.bundleMap,
            };

        const album = this.state.albumCatalogItem;

        const csvBasePayload = {
            timeRange: this.props.selectedRange,
            artistName: this.props.artist?.title || "",
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            teamId: this.props.teamId,
            locale: this.props.userLocale,
            requestPath: pagePath,
        };

        const trendlinesCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "trendlines",
            csvButtonId: CsvButtonId.AlbumDetailTrendlines,
            contentName: album?.title || "",
            streamsTrendlineData: this.props.streamInfo,
            listenersTrendlineData: this.props.listenersInfo,
        };

        const topSongsOnAlbumCsvPayload: createCsvFileRequestPayload = {
            ...csvBasePayload,
            csvExportType: "topSongsOnAlbum",
            csvButtonId: CsvButtonId.AlbumDetailTopSongs,
            contentName: album?.title || "",
            trackData: this.props.tracks,
        };

        let profileHeadProps: ProfileHeadProps = {
            imageSource: "",
            title: "",
            subtitle: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.albumsLabel,
            }),
            id: testIDPrefix + testIDSuffixes.profileImage,
            showShareContentButton: true,
            shareContentAction: this.onShareContent,
            showCsvButton:
                this.props.hasCsvExportCapabilities &&
                (!!this.props.streamInfo?.totalCount ||
                    !!this.props.listenersInfo?.totalCount),
            csvDownload: () => {
                this.props.sendClientMetrics(
                    buildUIClickPayload(
                        buttonIds.CsvExport.downloadTrendlines,
                        pageIds.albumPerformance,
                        this.state.albumCatalogItem?.asin,
                        EntityType.Album
                    )
                );
                this.props.getCsvFile(trendlinesCsvPayload);
            },
            csvTooltipMessage: getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.CSV_STRINGS,
                stringId: stringIds.Csv.TooltipMessage.StreamsAndListeners,
            }),
            csvButtonLoading:
                this.props.csvButtonLoadingStatus["AlbumDetail-Trendlines"],
        };

        if (album) {
            profileHeadProps = {
                ...profileHeadProps,
                imageSource:
                    album.images && album.images.artLarge
                        ? album.images.artLarge
                        : "",
                fallbackImage: ImageList.placeholder_album,
                title: album.title || "",
                imageDescription: album.title,
                callback: this.onReportIssue,
                callbackString: getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.ARTISTDISAMBIGUATION_STRINGS,
                    stringId:
                        stringIds.ArtistDisambiguation
                            .reportArtistProfileIssueTitle,
                }),
            };
        }

        const songRows = generateSongRows(
            this.props.tracks,
            this.props.catalog,
            this.props.hydratingAsins,
            this.props.failedAsins,
            this.props.history.push,
            this.props.bundleMap,
            testIDPrefix + "_TopSongs",
            false,
            true,
            true
        );

        // Top countries props
        const topCountriesHeader = [
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.countriesTitle,
            }),
            "",
            getLocalizedString(this.props.bundleMap, {
                bundleId: bundleIds.REPORTS_STRINGS,
                stringId: stringIds.Reports.streamsLabel,
            }),
        ];
        const topCountries: TableRowProps[] = generateCountryRows(
            this.props.topCountries,
            testIDPrefix + "_TopCountriesRows"
        );

        const topCountriesRows = topCountries.slice(
            0,
            SUMMARY_TABLE_ITEM_COUNT
        );

        return (
            <Container
                fluid={true}
                className="rootContainer"
                style={rootStyles.containerStyles.rootViewContainer}
            >
                {/* <Image
                    blurRadius={rootStyles.blurAmount}
                    style={rootStyles.imageStyles.backgroundImage}
                    source={{ uri: profileHeadProps.imageSource || this.props.artist?.images?.artSmall }}
                /> */}
                <Row>
                    {this.state.showError && (
                        <InlineError
                            text={getLocalizedString(this.props.bundleMap, {
                                bundleId: bundleIds.ERRORS_STRINGS,
                                stringId: stringIds.Errors.reportsError,
                            })}
                            retryAction={this.getAlbumPerformance}
                            id={`${testIDPrefix}_Error`}
                        />
                    )}
                </Row>
                {album && <ProfileHead {...profileHeadProps} />}

                {/* album streams */}
                <Row>
                    {streamLineGraphProps && (
                        <Col xs={12} md={6}>
                            <LineGraph
                                key="albumStreams"
                                {...streamLineGraphProps}
                            />
                        </Col>
                    )}

                    {/* album listeners */}
                    {listenerLineGraphProps && (
                        <Col xs={12} md={6}>
                            <LineGraph
                                key="albumListeners"
                                {...listenerLineGraphProps}
                            />
                        </Col>
                    )}
                </Row>

                <Row>
                    <Col>
                        {/* streaming details */}
                        {
                            <VerticalSpacedTable
                                key="streamingDetails"
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports
                                                .streamDetailsTitle,
                                    }
                                )}
                                labels={[
                                    getLocalizedString(this.props.bundleMap, {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId: stringIds.Reports.songsLabel,
                                    }),
                                    "",
                                    getLocalizedString(this.props.bundleMap, {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports.streamsLabel,
                                    }),
                                ]}
                                rows={songRows.slice(0, 5)}
                                id={testIDPrefix + "_AlbumTopTracksInfo"}
                                footer={
                                    <Row style={{ alignItems: "center" }}>
                                        {this.props.hasCsvExportCapabilities &&
                                            this.props.tracks.length && (
                                                <Col>
                                                    <DownloadButton
                                                        loading={
                                                            !!this.props
                                                                .csvButtonLoadingStatus[
                                                                "AlbumDetail-TopSongs"
                                                            ]
                                                        }
                                                        size={
                                                            rootStyles
                                                                .ButtonSize
                                                                .small
                                                        }
                                                        id={
                                                            "CsvButton-AlbumDetail-TopSongs"
                                                        }
                                                        tooltipMessage={getLocalizedString(
                                                            this.props
                                                                .bundleMap,
                                                            {
                                                                bundleId:
                                                                    bundleIds.CSV_STRINGS,
                                                                stringId:
                                                                    stringIds
                                                                        .Csv
                                                                        .TooltipMessage
                                                                        .TopSongs,
                                                            }
                                                        )}
                                                        onClick={() => {
                                                            this.props.sendClientMetrics(
                                                                buildUIClickPayload(
                                                                    buttonIds
                                                                        .CsvExport
                                                                        .downloadSongs,
                                                                    pageIds.albumPerformance,
                                                                    this.state
                                                                        .albumCatalogItem
                                                                        ?.asin,
                                                                    EntityType.Album
                                                                )
                                                            );
                                                            this.props.getCsvFile(
                                                                topSongsOnAlbumCsvPayload
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            )}
                                        {this.props.tracks.length >
                                            SUMMARY_TABLE_ITEM_COUNT && (
                                            <SmallGlassButton
                                                title={getLocalizedString(
                                                    this.props.bundleMap,
                                                    {
                                                        bundleId:
                                                            bundleIds.GENERIC_STRINGS,
                                                        stringId:
                                                            stringIds.Generic
                                                                .seeAll,
                                                    }
                                                )}
                                                onClick={this.seeAllSongs}
                                                id={
                                                    testIDPrefix +
                                                    "_SeeAllSongsButton"
                                                }
                                            />
                                        )}
                                    </Row>
                                }
                                showIndex={true}
                            />
                        }
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {/* Top Countries */}
                        {
                            <VerticalSpacedTable
                                rows={topCountriesRows}
                                labels={topCountriesHeader}
                                showIndex={true}
                                title={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.REPORTS_STRINGS,
                                        stringId:
                                            stringIds.Reports.topCountriesTitle,
                                    }
                                )}
                                id={testIDPrefix + "_TopCountriesTable"}
                                footer={
                                    <TableSeeAllButton
                                        totalRows={topCountries.length}
                                        id={testIDPrefix + "_seeAllCountries"}
                                        onClick={this.seeAllCountries}
                                    />
                                }
                                emptyMessage={getLocalizedString(
                                    this.props.bundleMap,
                                    {
                                        bundleId: bundleIds.EMPTYSTATE_STRINGS,
                                        stringId: stringIds.EmptyState.message,
                                    }
                                )}
                            />
                        }
                    </Col>
                </Row>

                {/* Share Content Menu */}
                <ShareContentMenu
                    fallbackImageSource={ImageList.placeholder_album}
                    isGenerateLinkInProgress={
                        this.props.generateShareLinkInProgress
                    }
                    isVisible={this.state.showShareContentMenu}
                    title={album?.title}
                    imageSource={album?.images?.artSmall}
                    contentType={getLocalizedString(this.props.bundleMap, {
                        bundleId: bundleIds.REPORTS_STRINGS,
                        stringId: stringIds.Reports.albumsLabel,
                    })}
                    artist={this.props.artist?.title}
                    artistAsin={this.props.artist?.asin}
                    albumAsin={album?.asin}
                    id={`${testIDPrefix}_ShareContentMenu`}
                    shareContentResponse={this.props.shareLinkResponse}
                    onHide={() =>
                        this.setState({ showShareContentMenu: false })
                    }
                    userAction={this.props.userAction}
                    sendClientMetrics={this.props.sendClientMetrics}
                    onCreatePromoCard={() => this.onCreatePromoCard()}
                    showHypeDeck={this.props.showHypeDeck}
                />
            </Container>
        );
    }

    private getAlbumPerformance = () => {
        const asin = this.props.match.params.asin;
        if (
            !this.props.signedIn ||
            !this.props.selectedArtist ||
            !this.props.teamId ||
            !asin
        ) {
            return;
        }

        const timeRangePayload: timeRangePayload = {
            timeRange: this.props.selectedRange,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        };

        const query: queryRequest = {
            artistAsin: this.props.selectedArtist,
            topTracksQueryV2: {
                ...generateQuery(timeRangePayload, guessTimeZone()),
                albumAsin: asin,
            },
            trackStreamsQueryV2: {
                ...generateQuery(timeRangePayload, guessTimeZone()),
                albumAsin: asin,
            },
            trackListenersQueryV2: {
                ...generateQuery(timeRangePayload, guessTimeZone()),
                albumAsin: asin,
            },
            topCountriesForEntityStreamsQuery: {
                ...generateQueryWithoutGranularity(
                    timeRangePayload,
                    guessTimeZone()
                ),
                entityType: EntityType.Album,
                entityId: asin,
            },
        };

        // Dispatch request for overview
        const payload: albumPerformanceRequestPayload = {
            timeRange: this.props.selectedRange,
            albumAsin: asin,
            teamId: this.props.teamId,
            query: query,
            requestPath: pagePath,
        };
        this.props.getAlbumPerformance(payload);
    };

    private seeAllSongs = () => {
        const asin = this.props.match.params.asin;
        if (!asin) {
            return;
        }
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllSongs,
                pageIds.albumPerformance,
                asin,
                EntityType.Album
            )
        );
        if (!this.state.albumCatalogItem) {
            return;
        }

        this.props.history?.push(`${paths.albumTracks}/${asin}`);
    };

    private seeAllCountries = () => {
        const asin = this.props.match.params.asin;
        if (!asin) {
            return;
        }
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.Reports.seeAllCountries,
                pageIds.albumPerformance,
                asin,
                EntityType.Album
            )
        );
        this.props.history.push(`${paths.albumCountries}/${asin}`);
    };

    private onReportIssue = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.SettingMenu.reportIssue,
                pageIds.albumPerformance,
                this.state.albumCatalogItem?.asin,
                EntityType.Album
            )
        );

        this.props.selectReportIssueItem(this.state.albumCatalogItem || {});
        this.props.history.push(paths.reportArtistProfile);
    };

    private onShareContent = () => {
        if (
            this.props.artist?.asin &&
            this.props.selectedAsin &&
            this.props?.teamId
        ) {
            this.setState({ showShareContentMenu: true });

            const payload: generateShareLinkPayload = {
                artistAsin: this.props.artist.asin,
                titleSetAsin: this.props.selectedAsin,
                teamId: this.props.teamId,
                contentType: EntityType.Album,
            };

            this.props.generateShareLink(payload);
        }
    };

    private onCreatePromoCard = () => {
        this.props.sendClientMetrics(
            buildUIClickPayload(
                buttonIds.HypeCard.createHypeCard,
                pageIds.albumPerformance,
                this.state.albumCatalogItem?.asin,
                EntityType.Album
            )
        );

        const item: baseMediaItem = {
            title: this.state.albumCatalogItem?.title,
            images: this.state.albumCatalogItem?.images,
            type: EntityType.Album,
            titlesetAsin: this.props.selectedAsin,
        };
        this.props.history.push({
            pathname: paths.promoCard,
            state: {
                stepIndex: PromoCardStep.CUSTOMIZE,
                selectedContent: item,
            },
        });
    };
}

function mapStateToProps(state: RootState) {
    return albumPerformanceScreenSelector(state, pagePath);
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
    return {
        getAlbumPerformance: (request: albumPerformanceRequestPayload) =>
            dispatch(reportingActions.getAlbumPerformance(request)),
        userAction: (payload: telemetryPayload) =>
            dispatch(telemetryActions.userAction(payload)),
        clearFailedAsins: () => dispatch(catalogActions.clearFailedAsins()),
        selectAsin: (asin: string) =>
            dispatch(reportingActions.setSelectedAlbumAsin(asin)),
        unselectAsin: (asin: string) =>
            dispatch(reportingActions.removeSelectedAlbumAsin(asin)),
        updateCurrentPath: (payload: string) =>
            dispatch(userActions.updateCurrentPath(payload)),
        selectReportIssueItem: (payload: baseMediaItem) =>
            dispatch(artistIssueActions.selectReportIssueItem(payload)),
        generateShareLink: (payload: generateShareLinkPayload) =>
            dispatch(catalogActions.generateShareLink(payload)),
        getCsvFile: (payload: createCsvFileRequestPayload) =>
            dispatch(csvActions.createCsvFile(payload)),
        sendClientMetrics: (payload: clientMetricsPayload) =>
            dispatch(clientMetricsActions.sendClientMetrics(payload)),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AlbumPerformanceScreen)
);
