import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const BlurredModal = styled(Modal)`
    @supports not (backdrop-filter: none) {
        background: rgba(0, 0, 0, 1);
    }
    @supports (backdrop-filter: none) {
        background: rgba(0, 0, 0, 0.8);
    }
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    position: fixed;
    z-index: 99999;

    .modal-dialog,
    .modal-content,
    .modal-body {
        background-color: transparent !important;
        width: auto;
        height: auto;
        min-width: ${(props) => props?.minwidth || 0};
        max-width: ${(props) => props?.maxwidth || "none"};
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        padding: 0;
        border: none;
    }

    .modal-content {
        margin: auto;
    }
    .modal-backdrop {
        background: transparent;
    }
`;
