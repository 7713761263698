import { stringIds, bundleIds } from "@amzn/ziggy-asset";
import React from "react";
import { getLocalizedString } from "../../../../utils";
import { FullScreenConfirmationModal, IconsList } from "../../common";
import { BundleMap } from "../../../../models";

export type ViewOnAmazonMusicUSModalProps = {
    isVisible: boolean;
    onDismiss: () => void;
    onConfirm: () => void;
    bundleMap: BundleMap;
};

export class ViewOnAmazonMusicUSModal extends React.Component<ViewOnAmazonMusicUSModalProps> {
    render() {
        return (
            <FullScreenConfirmationModal
                isVisible={this.props.isVisible}
                onDismiss={this.props.onDismiss}
                id={"ViewOnAmazonMusicUSModal"}
                icon={IconsList.alerts_Information}
                text={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId:
                        stringIds.Merch.Curation.ViewOnAmazonMusicUSModalTitle,
                })}
                description={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.MERCHCURATION_STRINGS,
                    stringId:
                        stringIds.Merch.Curation
                            .ViewOnAmazonMusicUSModalSubtitle,
                })}
                confirmButtonAction={this.props.onConfirm}
                confirmButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.continue,
                })}
                cancelButtonAction={this.props.onDismiss}
                cancelButtonText={getLocalizedString(this.props.bundleMap, {
                    bundleId: bundleIds.GENERIC_STRINGS,
                    stringId: stringIds.Generic.goBack,
                })}
            />
        );
    }
}
