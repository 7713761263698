import React from "react";
import { Card, Row } from "react-bootstrap";
import _ from "lodash";
import {
    VictoryBar,
    VictoryChart,
    VictoryLabel,
    VictoryAxis,
    VictoryStack,
} from "victory";
import * as chartStyles from "./styles";
import * as rootStyles from "../../../view/styles";
import { ChartProps, DataSet } from "./common";
import { getLocalizedString } from "../../../utils";
import { stringIds, bundleIds } from "../../../assets";
import { ChartHeader } from "./ChartHeader";
import { containerStyles } from ".";
import { BundleMap } from "../../../models";

export type BarChartProps = ChartProps & {
    data: DataSet;
    paddingLeft?: string;
    bundleMap: BundleMap;
};

type State = {
    graphContainerRef: any;
    graphContainerWidth: number;
};

export class BarChart extends React.Component<BarChartProps, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            graphContainerRef: React.createRef(),
            graphContainerWidth: 0,
        };
    }

    shouldComponentUpdate(prevProps: BarChartProps) {
        if (prevProps.data.length === 0 && this.props.data.length > 0) {
            return true;
        }
        if (
            this.state.graphContainerRef.current?.clientWidth &&
            this.state.graphContainerRef.current?.clientWidth !=
                this.state.graphContainerWidth
        ) {
            return true;
        }
        return false;
    }

    componentDidMount() {
        if (this.state.graphContainerRef.current?.clientWidth) {
            this.setState({
                graphContainerWidth:
                    this.state.graphContainerRef.current.clientWidth,
            });
        }
    }

    componentDidUpdate() {
        if (
            this.state.graphContainerRef.current?.clientWidth &&
            this.state.graphContainerRef.current?.clientWidth !=
                this.state.graphContainerWidth
        ) {
            this.setState({
                graphContainerWidth:
                    this.state.graphContainerRef.current.clientWidth,
            });
        }
    }

    render() {
        let inverseData: DataSet = [];
        this.props.data.forEach((datapoint) => {
            if (typeof datapoint.y === "number") {
                inverseData.push({ x: datapoint.x, y: 100 - datapoint.y });
            }
        });
        return (
            <Card
                id={this.props.id + "-Container"}
                style={{ ...containerStyles.chartContainer, width: "100%" }}
            >
                <Row id={this.props.id + "-Header"}>
                    <ChartHeader {...this.props} />
                </Row>
                <Row
                    ref={this.state.graphContainerRef}
                    style={{
                        ...stackedChart,
                        paddingLeft:
                            this.props.paddingLeft || defaultPaddingLeft,
                    }}
                >
                    {this.props.data.length > 0 ? (
                        <VictoryChart
                            height={280}
                            width={this.state.graphContainerWidth}
                            padding={{
                                left: 0,
                                top: rootStyles.spacers.giant,
                                bottom: rootStyles.spacers.epic,
                                right: 60,
                            }}
                        >
                            <VictoryAxis
                                offsetX={rootStyles.spacers.small}
                                style={{
                                    axis: { stroke: "transparent" },
                                    ticks: { stroke: "transparent" },
                                }}
                                tickLabelComponent={
                                    <VictoryLabel
                                        dx={0}
                                        textAnchor="start"
                                        dy={
                                            -chartStyles.chartStyles.barChart
                                                .barWidth -
                                            rootStyles.spacers.micro
                                        }
                                        style={{
                                            alignSelf: "flex-start",
                                            textAlign: "left",
                                            ...rootStyles.textStyles.key,
                                            fontSize:
                                                rootStyles.webFontSizes.t3,
                                            textTransform: "uppercase",
                                            fill: rootStyles.colors.primary,
                                        }}
                                    />
                                }
                            />
                            <VictoryStack>
                                <VictoryBar
                                    horizontal={true}
                                    style={columnStyle}
                                    domain={{ y: [0, 100] }}
                                    data={this.props.data}
                                    cornerRadius={{ top: 2, bottom: 2 }}
                                    labels={({ datum }) => `${datum.y}%`}
                                    barWidth={
                                        chartStyles.chartStyles.barChart
                                            .barWidth
                                    }
                                    labelComponent={
                                        <VictoryLabel
                                            style={{
                                                alignSelf: "flex-end",
                                                textAlign: "right",
                                                ...rootStyles.textStyles
                                                    .primary,
                                                fill: rootStyles.colors.primary,
                                            }}
                                            textAnchor="end"
                                            x={
                                                this.state.graphContainerWidth -
                                                rootStyles.spacers.base
                                            }
                                        />
                                    }
                                />

                                <VictoryBar
                                    horizontal={true}
                                    style={remainingBarStyle}
                                    domain={{ y: [0, 100] }}
                                    data={inverseData}
                                    cornerRadius={{ top: 2, bottom: 2 }}
                                    barWidth={
                                        chartStyles.chartStyles.barChart
                                            .barWidth
                                    }
                                />
                            </VictoryStack>
                        </VictoryChart>
                    ) : (
                        <Row
                            id={this.props.id + "-MessageOnly"}
                            style={{ flex: 1 }}
                        >
                            <span style={textStyle}>
                                {getLocalizedString(this.props.bundleMap, {
                                    bundleId: bundleIds.EMPTYSTATE_STRINGS,
                                    stringId: stringIds.EmptyState.message,
                                })}
                            </span>
                        </Row>
                    )}
                </Row>
            </Card>
        );
    }
}

const defaultPaddingLeft = "0";
const columnStyle = {
    data: {
        fill: rootStyles.colors.accent,
    },
};
const remainingBarStyle = {
    data: {
        fill: rootStyles.glassColors.primary2,
    },
};
const sharedChartStyle: React.CSSProperties = {
    flex: 1,
    flexDirection: "row",
    padding: 0,
    alignSelf: "flex-start",
    marginTop: rootStyles.spacers.large,
};
const stackedChart: React.CSSProperties = {
    ...sharedChartStyle,
    width: "100%",
    height: "100%",
    marginBottom: rootStyles.spacers.large,
};
const textStyle: React.CSSProperties = {
    flex: 1,
    ...rootStyles.textStyles.primary,
    marginTop: rootStyles.spacers.large,
    textAlign: "left",
    color: rootStyles.glassColors.primary3,
    justifyContent: "flex-start",
};
