import _ from "lodash";
import React from "react";
import styled from "styled-components";
import * as rootStyles from "../../styles";
import { Col, Row } from "react-bootstrap";
import { MerchListItem, MerchListItemProps } from "./MerchListItem";
import { useVirtualizer, VirtualItem } from "@tanstack/react-virtual";

const testIDPrefix = "MerchListItemTable";

//image height(100) + marginTop(12) + marginBottom(12) + horizontal line(2)
const MERCH_LIST_ITEM_ROW_HEIGHT = 126;

export type MerchListItemTableProps = {
    rows: MerchListItemProps[];
    tableHeader?: string;
    trailingRowHeight?: number;
};

//This table uses a virtualizer from TanStack, relevant doc here:
//https://tanstack.com/virtual/v3/docs/adapters/react-virtual
export const MerchListItemTable: React.FC<MerchListItemTableProps> = ({
    rows,
    tableHeader,
    trailingRowHeight,
}) => {
    const tableParentRef = React.useRef<HTMLInputElement>(null);

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => tableParentRef.current,
        estimateSize: () => MERCH_LIST_ITEM_ROW_HEIGHT,
        paddingEnd: trailingRowHeight || 0,
        overscan: 3,
    });

    return (
        <TableContainer style={{ overflow: "auto" }} ref={tableParentRef}>
            {tableHeader && (
                <Row style={tableHeaderStyle}>
                    <span
                        style={{
                            ...rootStyles.textStyles.primary,
                            paddingLeft: 15,
                        }}
                    >
                        {tableHeader}
                    </span>
                </Row>
            )}
            <div
                style={{
                    height: rowVirtualizer.getTotalSize(),
                    position: "relative",
                }}
            >
                {rowVirtualizer
                    .getVirtualItems()
                    .map((virtualItem: VirtualItem) => {
                        const index = virtualItem.index;
                        const rowItem = rows[index];
                        return (
                            <div
                                key={index}
                                style={{
                                    height: virtualItem.size,
                                    position: "absolute",
                                    width: "100%",
                                    transform: `translateY(${virtualItem.start}px)`,
                                }}
                            >
                                <MerchListItem
                                    {...rowItem}
                                    id={`${testIDPrefix}-${rowItem.id}`}
                                    hideCheckbox={rowItem.hideCheckbox}
                                />
                            </div>
                        );
                    })}
            </div>
        </TableContainer>
    );
};

const TableContainer = styled(Col)`
    background: transparent;
`;

const tableHeaderStyle: React.CSSProperties = {
    paddingTop: rootStyles.spacers.medium,
    paddingBottom: rootStyles.spacers.small,
    borderBottomWidth: "2px",
    borderBottomColor: rootStyles.glassColors.primary2,
    borderBottomStyle: "solid",
};
