import * as KatalMetrics from "@katal/metrics";
import KatalMetricsDriverSushi from "@katal/metricsDriverSushi";
import { TimerStopwatch } from "@katal/metrics/lib/metricObject";
import { telemetryEvent, MetricType } from "../models";
import { isProd, reactAppEnv, stage } from "./common";

const makePublisher = () => {
    const metricsErrorHandler = (err: any) => {
        console.error(err);
    };
    if (reactAppEnv === "local") {
        return;
    }
    const dataSet = isProd ? "prod" : "preprod";
    const metricsDriver = new KatalMetricsDriverSushi.Builder()
        .withDomainRealm(dataSet, "USAmazon")
        .withErrorHandler(metricsErrorHandler)
        .build();

    const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite("AmazonMusicForArtists")
        .withServiceName("Web")
        .build();
    return new KatalMetrics.Publisher(
        metricsDriver,
        metricsErrorHandler,
        initialMetricsContext
    );
};

const initialMetricsPublisher = makePublisher();

export function publishCounterWithMetadata(request: telemetryEvent) {
    if (initialMetricsPublisher) {
        const actionMetricPubliisher = initialMetricsPublisher.newChildActionPublisherForMethod(
            "ALL"
        );
        actionMetricPubliisher.publishString(
            "EventType",
            request.metricType === MetricType.AppEvent || MetricType.Error
                ? "Operational"
                : "Engagement"
        );
        request.datapoints.forEach((value?: string, key?: string) => {
            if (key && value) {
                actionMetricPubliisher.publishString(
                    key,
                    value && value.toString()
                );
            }
        });
        actionMetricPubliisher.publishCounterMonitor(request.name, 1);
    }
}

export function publishCounter(name: string, count: number) {
    if (initialMetricsPublisher) {
        const actionMetricPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(
            "ALL"
        );
        actionMetricPublisher.publishCounterMonitor(name, count);
    }
}

export function startTimer(name: string): TimerStopwatch {
    return new KatalMetrics.Metric.TimerStopwatch(name).withMonitor(true);
}

export function publishTimer(timer: TimerStopwatch) {
    if (initialMetricsPublisher) {
        const actionMetricPubliisher = initialMetricsPublisher.newChildActionPublisherForMethod(
            "ALL"
        );
        actionMetricPubliisher.publish(timer);
    }
}
