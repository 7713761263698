import React from "react";
import { Dropdown } from "react-bootstrap";
import { Icon, IconsList } from "..";
import { webFontSizes } from "../../../styles";
import { dropDownStyles, buttonStyles } from "../../../styles/buttonStyles";

export type GenericDropdownProps = {
    items: any;
    buttonText?: string;
    menuStyle?: React.CSSProperties;
    className?: string;
    buttonStyle?: React.CSSProperties;
    dropdownStyle?: React.CSSProperties;
    alignRight?: boolean;
    iconSize?: number;
    direction?: "up" | "down" | "left" | "right";
    id: string;
};

export const GenericDropdown: React.FC<GenericDropdownProps> = ({
    items,
    buttonText,
    menuStyle,
    className,
    buttonStyle,
    dropdownStyle,
    alignRight,
    iconSize,
    direction,
    id,
}) => {
    return (
        <Dropdown
            style={{
                ...dropDownStyles.dropdown,
                margin: "auto",
                ...dropdownStyle,
            }}
            className="rightPointer"
            drop={direction}
        >
            <Dropdown.Toggle
                style={{
                    ...dropDownStyles.dropdownButton,
                    width: "100%",
                    boxShadow: "none",
                    ...buttonStyle,
                }}
                id={id + "-DropdownToggle"}
                className={className}
            >
                {buttonText ? (
                    <span style={dropdownButtonTextStyle}>{buttonText}</span>
                ) : (
                    <Icon
                        size={iconSize ? iconSize : 20}
                        icon={IconsList.action_more}
                        id={`${id}_More`}
                    />
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu
                style={{ ...dropDownStyles.dropdownMenu, ...menuStyle }}
                alignRight={alignRight}
            >
                {items}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const dropdownButtonTextStyle: React.CSSProperties = {
    fontSize: webFontSizes.t2,
    margin: 0,
    padding: 0,
    ...buttonStyles.glassTitle,
};

export default GenericDropdown;
