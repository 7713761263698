import { createAction } from "redux-ts";
import {
    GetFeaturePermissionsPayload,
    GetGlobalPermissionsPayload,
    GlobalFeaturePermission,
} from "../../models";

export const getFeaturePermissions = createAction<GetFeaturePermissionsPayload>(
    "FEATURE_PERMISSIONS::GET_FEATURE_PERMISSIONS"
);

export const getFeaturePermissionsSuccess = createAction<any>(
    "FEATURE_PERMISSIONS::GET_FEATURE_PERMISSIONS_SUCCESS"
);

export const isFeaturePermissionInProgress = createAction<boolean>(
    "FEATURE_PERMISSIONS::GET_FEATURE_PERMISSIONS_INPROGRESS"
);

export const getGlobalPermissions = createAction<GetGlobalPermissionsPayload>(
    "GLOBAL_PERMISSIONS::GET_GLOBAL_PERMISSIONS"
);

export const getGlobalPermissionsSuccess = createAction<
    GlobalFeaturePermission[]
>("GLOBAL_PERMISSIONS::GET_GLOBAL_PERMISSIONS_SUCCESS");
