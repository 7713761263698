import { RootState } from "../reducers";
import { createSelector } from "reselect";
import { Feature, artist } from "../../models";
import { bundleIds } from "../../assets";
import _ from "lodash";

// Reports
export const getReports = (state: RootState) => state.reporting.reports;
export const getSelectedTimeRange = (state: RootState) =>
    state.reporting.selectedRange;

// User
export const getUserState = (state: RootState) => state.user;
export const getUserInfo = (state: RootState) => state.user.signedIn;
export const getIsPrivileged = (state: RootState) => state.user.isPrivileged;
export const shouldRefreshToken = (state: RootState) =>
    state.user.shouldRefreshToken;
export const getTokenRefreshInProgress = (state: RootState) =>
    state.user.refreshInProgress;
export const getRecentlyViewedArtists = (state: RootState) =>
    state.user.recentlyViewedArtists;
export const getUserLocale = (state: RootState) => state.user.locale;
export const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;
export const getSignedIn = (state: RootState) => state.user.signedIn;
export const getCurrentPath = (state: RootState) => state.user.currentPath;
export const getUserSetting = (state: RootState) => state.user.settings;
const loginInProgress = (state: RootState) => state.user.loginInProgress;
export const getCsrfToken = (state: RootState) => state.user.csrfToken;

// Catalog
export const getCatalog = (state: RootState) => state.catalog.catalog;
export const getHydratingAsins = (state: RootState) =>
    state.catalog.hydratingAsins;
export const getFailedAsins = (state: RootState) => state.catalog.failedAsins;
export const getCatalogBuildCompleted = (state: RootState) =>
    state.catalog.catalogBuildCompleted;
export const getHydrationInProgress = (state: RootState) =>
    state.catalog.hydrationInProgress;

// Localization
export const getBundleMap = (state: RootState) => state.localization.bundleMap;
export const bundleMapSelector = createSelector(getBundleMap, (bundleMap) => {
    return {
        bundleMap,
    };
});
export const bundleMapLoadInProgress = (state: RootState) =>
    state.localization.bundleMap.size !== Object.values(bundleIds).length;

// Error
export const getErrorFromStore = (state: RootState, path: string) =>
    state.error.errorStore.get(path);

// ArtistSearch
export const getArtistSearchInProgress = (state: RootState) =>
    state.artistSearch.inProgress;
export const getArtistSearchResults = (state: RootState) =>
    state.artistSearch.artists;

export const homeScreenSelectors = createSelector(
    [
        getErrorFromStore,
        getUserInfo,
        getIsPrivileged,
        shouldRefreshToken,
        loginInProgress,
        getUserLocale,
    ],
    (
        error,
        userInfo,
        isPrivileged,
        shouldRefreshToken,
        loginInProgress,
        userLocale
    ) => {
        return {
            signedIn: userInfo,
            isPrivileged: isPrivileged,
            loginInProgress: loginInProgress,
            shouldRefreshToken: shouldRefreshToken,
            error: error,
            locale: userLocale,
        };
    }
);

export const catalogSelector = createSelector(
    [
        getCatalog,
        getCatalogBuildCompleted,
        getHydrationInProgress,
        getHydratingAsins,
        getFailedAsins,
    ],
    (
        catalog,
        catalogBuildCompleted,
        hydrationInProgress,
        hydratingAsins,
        failedAsins
    ) => {
        return {
            catalog: catalog,
            catalogBuildCompleted,
            hydrationInProgress,
            hydratingAsins,
            failedAsins,
        };
    }
);

// Gets the currently selected artist from catalog
export const selectedArtistFromCatalogSelector = createSelector(
    [getSelectedArtistAsin, getCatalog],
    (selectedArtistAsin, catalog) => {
        if (!selectedArtistAsin) {
            return;
        }
        return catalog.get(selectedArtistAsin) as artist;
    }
);

// Get recently added to playlists state for current artist
export const getRecentlyAddedToPlaylistsData = createSelector(
    [selectedArtistFromCatalogSelector],
    (artist) => {
        return artist?.newAddsToPlaylistInfo;
    }
);
