import { arbManifest } from "@amzn/ziggy-asset";
import {
    LocalizationContext,
    LocalizationContextBuilder,
    createAsyncMessageBundleManager,
} from "@amzn/arb-tools";
import { webLocale } from ".";

// Define a localization context builder
export const localizationContextBuilder =
    new LocalizationContextBuilder().withDefaultLocale("en-US");

const localizationContextProvider = () =>
    localizationContextBuilder
        .withLocale(webLocale[0].replace("_", "-"))
        .build();

// Create a message bundle manager to retrieve compiled PUFF-J resources in run time.
// resolveBundleUrl will return translation file in certain locale. Eg. translation-en.json
// resolveResourcePack will require the bundle
// https://code.amazon.com/packages/ArbJsToolsRepo/blobs/mainline/--/packages/arb-tools/README.md
export const messageBundleManager = createAsyncMessageBundleManager({
    arbManifest,
    localizationContextProvider,
    resolveBundleUrl: (url: string | undefined) => (url ? `${url}` : undefined),
    resolveResourcePack: async (url?: string) => {
        return await require(`@amzn/ziggy-asset/dist/panther-translations/${url}`);
    },
});

// Create a message bundle manager to retrieve compiled PUFF-J resources in run time.
// resolveBundleUrl will return translation file in certain locale. Eg. translation-en.json
// resolveResourcePack will require the bundle
// https://code.amazon.com/packages/ArbJsToolsRepo/blobs/mainline/--/packages/arb-tools/README.md
export const customizedMessageBundleManager = (locale: string) =>
    createAsyncMessageBundleManager({
        arbManifest,
        localizationContextProvider: () =>
            localizationContextBuilder
                .withLocale(locale.replace("_", "-"))
                .build(),
        resolveBundleUrl: (url: string | undefined) =>
            url ? `${url}` : undefined,
        resolveResourcePack: async (url?: string) => {
            return await require(`@amzn/ziggy-asset/dist/panther-translations/${url}`);
        },
    });
