import * as colorStyles from "./colors";
import * as React from "react";
import { spacers, ImageSize } from "./styles";

export const blur: React.CSSProperties = {
    backgroundColor: colorStyles.colors.secondary,
};
export const roundImage: React.CSSProperties = {
    width: ImageSize.Large,
    height: ImageSize.Large,
    marginBottom: spacers.medium,
    borderRadius: ImageSize.Large / 2,
};
export const squareImage: React.CSSProperties = {
    width: ImageSize.Large,
    height: ImageSize.Large,
    borderRadius: 8,
    marginBottom: spacers.medium,
};
export const mediumRoundImage: React.CSSProperties = {
    width: ImageSize.Medium,
    height: ImageSize.Medium,
    marginRight: spacers.base,
    marginTop: spacers.mini,
    marginBottom: spacers.mini,
    marginLeft: spacers.base,
    borderRadius: ImageSize.Medium / 2,
};

export const mediumSquareImage: React.CSSProperties = {
    width: ImageSize.Medium,
    height: ImageSize.Medium,
    borderRadius: 8,
    marginTop: spacers.medium,
    marginBottom: spacers.medium,
};
export const miniRoundImage: React.CSSProperties = {
    width: ImageSize.Mini,
    height: ImageSize.Mini,
    marginRight: spacers.base,
    marginTop: spacers.mini,
    marginBottom: spacers.mini,
    marginLeft: spacers.base,
    borderRadius: ImageSize.Mini / 2,
};
export const miniSquareImage: React.CSSProperties = {
    width: ImageSize.Mini,
    height: ImageSize.Mini,
    borderRadius: 4,
    marginTop: spacers.mini,
    marginBottom: spacers.mini,
};
export const microRoundImage: React.CSSProperties = {
    width: ImageSize.Micro,
    height: ImageSize.Micro,
    marginRight: spacers.base,
    marginTop: spacers.mini,
    marginBottom: spacers.mini,
    borderRadius: ImageSize.Micro / 2,
};
export const logoImage: React.CSSProperties = {
    marginLeft: spacers.large,
    marginRight: spacers.large,
    width: "100%",
};
export const backgroundImage: React.CSSProperties = {
    pointerEvents: "none",
    flex: 1,
    objectFit: "cover",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    color: colorStyles.colors.accent,
    opacity: colorStyles.glass._3,
};
