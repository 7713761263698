import { createSelector } from "reselect";
import {
    BundleMap,
    ErrorPayload,
    FeaturePermission,
    TeamPermissions,
    catalogItem,
    oAuthClientIds,
} from "../../models";
import { RootState } from "../reducers";
import { getBundleMap, getCatalog, getErrorFromStore } from "./commonSelectors";
import { getUserPermissionsSelector } from "./teamManagementSelectors";
import { selectedTeamIdSelector } from "./userSelectors";

const getSignedIn = (state: RootState) => state.user.signedIn;
const getSelectedArtistAsin = (state: RootState) =>
    state.user.selectedArtistAsin;
const getOAuthClientIds = (state: RootState) => state.user.oAuthClientIds;
const getImageUploadInProgress = (state: RootState) =>
    state.imageUpload.inProgress;
const getImageUploadComplete = (state: RootState) =>
    state.imageUpload.uploadComplete;
const getFeaturePermissionsList = (state: RootState) =>
    state.featureAccess.featurePermissionsList;

export const profilePageSelector: any = createSelector<
    RootState,
    string,
    any,
    any
>(
    [
        getSignedIn,
        selectedTeamIdSelector,
        getSelectedArtistAsin,
        getCatalog,
        getOAuthClientIds,
        getErrorFromStore,
        getUserPermissionsSelector,

        getImageUploadInProgress,
        getImageUploadComplete,
        getFeaturePermissionsList,
        getBundleMap,
    ],
    (
        signedIn: boolean,
        teamId: string | undefined,
        selectedArtistAsin: string | undefined,
        catalog: Map<string, catalogItem>,
        oAuthClientIds: oAuthClientIds | undefined,
        error: ErrorPayload | undefined,
        userPermissions: TeamPermissions[],
        getImageUploadInProgress: boolean,
        getImageUploadComplete: boolean,
        getFeaturePermissionsList: FeaturePermission[],
        bundleMap: BundleMap
    ) => {
        return {
            signedIn: signedIn,
            teamId: teamId,
            selectedArtistAsin: selectedArtistAsin,
            catalog: catalog,
            error: error,
            userPermissions: userPermissions,
            oAuthClientIds: oAuthClientIds,
            inProgress: getImageUploadInProgress,
            uploadComplete: getImageUploadComplete,
            featurePermissionsList: getFeaturePermissionsList,
            bundleMap: bundleMap,
        };
    }
);
