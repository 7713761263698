import * as React from "react";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import { buttonIds } from "@amzn/ziggy-asset";
import { useDispatch, useSelector } from "react-redux";
import {
    buildUIClickPayload,
    getLocalizedString,
    MerchLinkBuilders,
    paths,
} from "../../../utils";
import * as rootStyles from "../../styles";
import { IconsList, stringIds, bundleIds } from "../../../assets";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
    Icon,
    LargeGlassButton,
    LargeSolidButton,
    MediumSolidButton,
    MerchItemCard,
    merchItemCardProps,
    merchItemCardWidth,
    SmallGlassButton,
    styledTitle,
    ViewOnAmazonMusicUSModal,
} from "../../components";
import { useEffect, useRef, useState } from "react";
import {
    clientMetricsActions,
    globalNotificationsActions,
} from "../../../store/actions";
import {
    hasCurateMerchPermission,
    commonSelectors,
} from "../../../store/selectors";
import { BundleMap, EntityType, GlobalModal } from "../../../models";

const SEE_ALL_BUTTON_RENDER_THRESHOLD = 6;

type MerchCarouselProps = {
    merchItemCards: merchItemCardProps[];
    totalNumberMerchItems: number;
    pageId: string;
    id: string;
};

export const MerchCarousel: React.FC<MerchCarouselProps> = ({
    merchItemCards,
    totalNumberMerchItems,
    pageId,
    id,
}) => {
    const history = useHistory();
    const scrollRef = useRef<HTMLInputElement>(null);

    const canCurate = useSelector(hasCurateMerchPermission);
    const artistAsin = useSelector(commonSelectors.getSelectedArtistAsin);

    const bundleMap: BundleMap = useSelector(commonSelectors.getBundleMap);

    const dispatch = useDispatch();

    const getScrollbarPresent = () => {
        if (!scrollRef.current) {
            return;
        }
        return scrollRef.current?.scrollWidth > scrollRef.current?.clientWidth;
    };

    //initial value is unconsequential as it gets set on the useEffect hook below
    const [scrollbarPresent, setScrollbarPresent] = useState(false);

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const handleWindowSizeChange = () => {
        if (!scrollRef.current) {
            return;
        }
        if (getScrollbarPresent()) {
            setScrollbarPresent(true);
        } else {
            setScrollbarPresent(false);
        }
    };

    const getSnappedItemIndex = (): number => {
        const ref = scrollRef.current;
        if (!ref) {
            return 0;
        }

        const currLeftScroll = ref.scrollLeft;
        return Math.ceil(currLeftScroll / merchItemCardWidth);
    };

    const getActiveVisibleElements = (): number => {
        const ref = scrollRef.current;
        if (!ref) {
            return 0;
        }

        const containerWidth = ref.offsetWidth;
        return Math.floor(containerWidth / merchItemCardWidth);
    };

    const scrollTo = (scrollTarget: number) => {
        if (!scrollRef.current) {
            return;
        }

        scrollRef.current.scroll({
            behavior: "smooth",
            left: scrollTarget,
        });
    };

    const scrollToIndex = (index: number) => {
        if (index === 0) {
            scrollTo(0);
            return;
        }

        scrollTo(index * merchItemCardWidth);
    };

    const onScrollLeft = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.merchCarouselLeft,
                    pageId,
                    artistAsin,
                    EntityType.ARTIST
                )
            )
        );
        scrollToIndex(getSnappedItemIndex() - getActiveVisibleElements());
    };

    const onScrollRight = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.merchCarouselRight,
                    pageId,
                    artistAsin,
                    EntityType.ARTIST
                )
            )
        );
        scrollToIndex(getSnappedItemIndex() + getActiveVisibleElements());
    };

    const toManualSearch = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.searchManually,
                    pageId,
                    artistAsin,
                    EntityType.ARTIST
                )
            )
        );
        history.push(paths.manualSearchMerch);
    };

    const showViewOnAmazonMusicUSModal = (
        dismiss: () => void,
        isVisible: boolean
    ): GlobalModal => {
        return (
            <ViewOnAmazonMusicUSModal
                onDismiss={dismiss}
                isVisible={isVisible}
                onConfirm={() => {
                    dismiss();
                    //give modal time to disappear before opening new window
                    setTimeout(viewOnAmazonMusic, 300);
                }}
                bundleMap={bundleMap}
            />
        );
    };

    const onViewOnAmazonMusicButtonClick = () => {
        dispatch(
            globalNotificationsActions.requestModalDisplay(
                showViewOnAmazonMusicUSModal
            )
        );
    };

    const viewOnAmazonMusic = () => {
        if (!artistAsin) {
            return;
        }

        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.viewOnAmazonMusic,
                    pageId,
                    artistAsin,
                    EntityType.ARTIST
                )
            )
        );
        window.open(
            MerchLinkBuilders.viewOnAmazonMusic(artistAsin),
            "_blank",
            "noreferrer, noopener"
        );
    };

    const toSeeAllMerch = () => {
        dispatch(
            clientMetricsActions.sendClientMetrics(
                buildUIClickPayload(
                    buttonIds.Merch.seeAllMerch,
                    pageId,
                    artistAsin,
                    EntityType.ARTIST
                )
            )
        );
        history.push(paths.seeAllMerch);
    };

    const generateItemCards = () => {
        return (
            <Row
                style={{
                    marginBottom: rootStyles.spacers.medium,
                    marginRight: rootStyles.spacers.base,
                    overflowX: "hidden",
                }}
                ref={scrollRef}
            >
                <Col
                    xs={{ span: "auto" }}
                    style={{
                        padding: 0,
                        display: "flex",
                    }}
                >
                    {merchItemCards.map((props: merchItemCardProps) => {
                        return <MerchItemCard {...props} />;
                    })}
                </Col>
            </Row>
        );
    };
    return (
        <>
            <ButtonContainer>
                {canCurate && (
                    <AddProductsButtonContainer>
                        <LargeSolidButton
                            leftIcon={IconsList.action_add_black}
                            title={getLocalizedString(bundleMap, {
                                bundleId: bundleIds.MERCHCURATION_STRINGS,
                                stringId: stringIds.Merch.Curation.AddProducts,
                            })}
                            onClick={toManualSearch}
                            id={`${id}_AddProducts`}
                        />
                    </AddProductsButtonContainer>
                )}
                <div>
                    <LargeGlassButton
                        title={getLocalizedString(bundleMap, {
                            bundleId: bundleIds.MERCHCURATION_STRINGS,
                            stringId:
                                stringIds.Merch.Curation
                                    .LandingPageViewOnAmazonMusicCTA,
                        })}
                        onClick={onViewOnAmazonMusicButtonClick}
                        id={`${id}_ViewOnAmazonMusic`}
                    />
                </div>
            </ButtonContainer>
            <Row
                style={{
                    marginBottom: rootStyles.spacers.large,
                    display: "flex",
                }}
            >
                <Col>
                    <styledTitle.h4>
                        {getLocalizedString(
                            bundleMap,
                            {
                                bundleId: bundleIds.MERCHCURATION_STRINGS,
                                stringId:
                                    stringIds.Merch.Curation.YourProductsCount,
                            },
                            { "0": totalNumberMerchItems.toString() }
                        )}
                    </styledTitle.h4>
                </Col>
                <Row style={{ display: "flex" }}>
                    {scrollbarPresent && (
                        <>
                            <Icon
                                icon={IconsList.chevron_caretleft}
                                size={rootStyles.icons.large}
                                color={rootStyles.colors.primary}
                                id={`${id}_backButton`}
                                style={{ marginRight: rootStyles.spacers.mini }}
                                onClick={onScrollLeft}
                            />
                            <Icon
                                icon={IconsList.chevron_caretright}
                                size={rootStyles.icons.large}
                                color={rootStyles.colors.primary}
                                id={`${id}-backButton`}
                                style={{
                                    marginRight: rootStyles.spacers.small,
                                    marginLeft: rootStyles.spacers.mini,
                                }}
                                onClick={onScrollRight}
                            />
                        </>
                    )}

                    {totalNumberMerchItems >=
                        SEE_ALL_BUTTON_RENDER_THRESHOLD && (
                        <Col>
                            <SmallGlassButton
                                containerStyle={{
                                    ...rootStyles.buttonStyles
                                        .buttonSharedStyles,
                                    marginLeft: rootStyles.spacers.small,
                                }}
                                title={getLocalizedString(bundleMap, {
                                    bundleId: bundleIds.GENERIC_STRINGS,
                                    stringId: stringIds.Generic.seeAll,
                                })}
                                id={id + "_SeeAllAlbums"}
                                onClick={toSeeAllMerch}
                            />
                        </Col>
                    )}
                </Row>
            </Row>
            {generateItemCards()}
        </>
    );
};

const ButtonContainer = styled(Col)`
    margin-bottom: ${rootStyles.spacers.giant}px;
    margin-top: ${rootStyles.spacers.large}px;
    display: flex;
    @media (max-width: 576px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const AddProductsButtonContainer = styled.div`
    margin-right: ${rootStyles.spacers.small}px;
    margin-bottom: 0px;
    @media (max-width: 576px) {
        margin-right: 0px;
        margin-bottom: ${rootStyles.spacers.small}px;
    }
`;
